import React from 'react';
import { observer } from 'mobx-react';

import './technician-style.scss';


const Technician = ({ pharmaciesCaption }) => (
  <div className='technician'>
    <div className='technician-location'>
      {
        pharmaciesCaption
      }
    </div>
  </div>
);

export default observer(Technician);
