import { types } from 'mobx-state-tree';
import { set as _set } from 'lodash';

import stores from 'bootstrap/store';

export const PrescriptionFillsMailOrderRouteStore = types.model({
  activePrescriptionFillId: types.maybeNull(types.string),
  prescriptionFillsFilterValue: types.optional(types.string, ''),

  currentPage: types.optional(types.model({
    prescriptionFills: types.optional(types.number, 1),
  }), {}),
  http: types.optional(types.model({
    listingMailOrderedPrescriptionFills: types.optional(types.number, 0),
  }), {}),
})

.actions(self => ({
  setValue: (path, value) => _set(self, path, value),
  setInitialState: () => {
    self.activePrescriptionFillId = null;
    self.prescriptionFillsFilterValue = '';
    self.currentPage = {};
    self.http = {};
  },
  incrementHttp: (http) => ++self.http[http],
  decrementHttp: (http) => --self.http[http],
}))
.views(self => ({
  get activePrescriptionFill() {
    return self.activePrescriptionFillId && stores.global.prescriptionFills.mailOrdered.get(self.activePrescriptionFillId);
  },
}));

export const prescriptionFillsMailOrderRouteStore = PrescriptionFillsMailOrderRouteStore.create({});
