import React from 'react';
import { observer } from 'mobx-react';

import CaptionValueInput from 'components/caption-value-input';
import CaptionValueSelect from 'components/caption-value-input-with-select';
import DeleteConfirmationModal from 'components/delete-confirmation-modal';
import Modal from 'components/modal';
import ModalEditingAreas from 'components/modal/layout/editing-areas';
import ModalEditingArea from 'components/modal/layout/editing-areas/components/editing-area';
import ModalRow from 'components/modal/layout/row';
import ModalButton from 'components/modal/layout/button';
import PharmacyHealthcareSites from './components/pharmacyHealthcareSites';

const PharmacyUpsertModal = ({
  pharmacyUpsertModalRouteStore,
  pharmaciesRouteStore,
  locationsStore,
  healthcareSitesStore,
  createPharmacy,
  updatePharmacy,
  deletePharmacy,
  closePharmacyUpsertModal,
  showPharmacyDeleteConfirmationModal
}) => {
  const activePharmacy = pharmaciesRouteStore.activePharmacy;
  const activelyModifiedPharmacy = pharmacyUpsertModalRouteStore.activelyModifiedPharmacy || {};
  const canBeUpdated = activePharmacy && pharmacyUpsertModalRouteStore.readyForUpdate;
  const canBeCreated = !activePharmacy && activelyModifiedPharmacy && pharmacyUpsertModalRouteStore.readyForCreation;

  return (
    <Modal
      modalStyles={{ height: '40%', width: '80%' }}
      isOpen={pharmaciesRouteStore.showingPharmacyUpsertModal}
      shouldCloseOnOverlayClick={true}
      close={closePharmacyUpsertModal}
      title={activePharmacy ? `${activePharmacy.name}` : 'New Pharmacy'}
    >
      {
        activePharmacy &&
      <DeleteConfirmationModal
        showing={pharmacyUpsertModalRouteStore.showingDeleteConfirmationModal}
        deletedItemName={activePharmacy.name}
        deleteItem={deletePharmacy}
        closeModal={() => showPharmacyDeleteConfirmationModal(false)}
      />
      }
      <ModalEditingAreas>
        <ModalEditingArea>
          <ModalRow>
            <CaptionValueInput
              inputFieldClassNames={['max-width']}
              caption='Name'
              value={activelyModifiedPharmacy.name}
              onChange={(value) => pharmacyUpsertModalRouteStore.setValue('activelyModifiedPharmacy.name', value)}
            />
          </ModalRow>
          <ModalRow>
            <CaptionValueSelect
              items={locationsStore.autoCompleteDataForSelect}
              inputFieldClassNames={['max-width']}
              caption='Location'
              value={pharmacyUpsertModalRouteStore.locationSelector}
              onChange={data => {
                if (!data) data = {label: '', value: ''};
                pharmacyUpsertModalRouteStore.setValue('locationSelector', data);
              }}
              onInputChange={data => {
                if (!data) return;
                pharmacyUpsertModalRouteStore.setValue('locationSelectorFilter', data);
                locationsStore.autocompleteLocationDebounce({query: {title: data}});
              }}
            />
          </ModalRow>
          <ModalRow shouldBeSpaceBetween={true} shouldBoundToBottom={true}>
            <ModalButton
              color='blue'
              isDisabled={!canBeUpdated && !canBeCreated}
              text='Save'
              onClick={activePharmacy ? updatePharmacy : createPharmacy}
            />
          </ModalRow>
        </ModalEditingArea>
        <ModalEditingArea>
          <ModalRow>
            <CaptionValueInput
              inputFieldClassNames={['max-width']}
              type='phone'
              caption='Phone number'
              value={activelyModifiedPharmacy.phoneNumber}
              onChange={(value) => pharmacyUpsertModalRouteStore.setValue('activelyModifiedPharmacy.phoneNumber', value)}
            />
          </ModalRow>
          <ModalRow>
            <PharmacyHealthcareSites
              onChange={(data) => {
                if (!data) return;
                pharmacyUpsertModalRouteStore.addHealthcareSiteToPharmacy(data);
              }}
              onClickDelete={pharmacyUpsertModalRouteStore.deleteHealthcareSiteFromPharmacy}
              healthcareSitesStore={healthcareSitesStore}
              pharmacyUpsertModalRouteStore={pharmacyUpsertModalRouteStore}
              pharmaciesRouteStore={pharmaciesRouteStore}
            />
          </ModalRow>
        </ModalEditingArea>
      </ModalEditingAreas>
    </Modal>
  );
};

export default observer(PharmacyUpsertModal);
