import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { observer } from 'mobx-react';

import CaptionValue from './components/caption-value';
import ContentScreen from 'components/content-screen';

import constructChartOptions from './helpers/constructChartOptions';
import constructDisplayingRules from './helpers/constructDisplayingRules';

import { CHART_TITLE } from './constants';

import './kiosk-statuses-view-styles.scss';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const KioskStatusesView = ({
  activeKiosk,
  isFetchingKioskStatuses,
  chartDataByKioskStatusField,
}) => {
  const displayingRules = constructDisplayingRules(activeKiosk);

  return <div className='c-KioskStatuses'>
    <ContentScreen active={isFetchingKioskStatuses} />
    <div className='content'>
      <div className='section'>
        <div className='row'>
          <CaptionValue
            value={displayingRules.createdAt.value}
            caption={displayingRules.createdAt.caption}
            color={displayingRules.createdAt.color}
          />
          <CaptionValue
            value={displayingRules.isInFaultedState.value}
            caption={displayingRules.isInFaultedState.caption}
            color={displayingRules.isInFaultedState.color}
          />
          <CaptionValue
            value={displayingRules.isPrinterOnline.value}
            caption={displayingRules.isPrinterOnline.caption}
            color={displayingRules.isPrinterOnline.color}
          />
          <CaptionValue
            value={displayingRules.isPaperInStock.value}
            caption={displayingRules.isPaperInStock.caption}
            color={displayingRules.isPaperInStock.color}
          />
        </div>
      </div>
      <div className='section'>
        <div className='row'>
          <CaptionValue
            value={displayingRules.humidity.value}
            caption={displayingRules.humidity.caption}
            color={displayingRules.humidity.color}
          />
          <CaptionValue
            value={displayingRules.temperature.value}
            caption={displayingRules.temperature.caption}
            color={displayingRules.temperature.color}
          />
          <CaptionValue
            value={displayingRules.cpuUsage.value}
            caption={displayingRules.cpuUsage.caption}
            color={displayingRules.cpuUsage.color}
          />
          <CaptionValue
            value={displayingRules.memoryUsage.value}
            caption={displayingRules.memoryUsage.caption}
            color={displayingRules.memoryUsage.color}
          />
        </div>
      </div>
      <div className='section'>
        <div className='row'>
          <Line
            options={constructChartOptions(CHART_TITLE['humidity'])}
            data={chartDataByKioskStatusField.humidity}
          />
        </div>
      </div>
      <div className='section'>
        <div className='row'>
          <Line
            options={constructChartOptions(CHART_TITLE['temperature'])}
            data={chartDataByKioskStatusField.temperature}
          />
        </div>
      </div>
      <div className='section'>
        <div className='row'>
          <Line
            options={constructChartOptions(CHART_TITLE['cpuUsage'])}
            data={chartDataByKioskStatusField.cpuUsage}
          />
        </div>
      </div>
      <div className='section'>
        <div className='row'>
          <Line
            options={constructChartOptions(CHART_TITLE['memoryUsage'])}
            data={chartDataByKioskStatusField.memoryUsage}
          />
        </div>
      </div>
    </div>
  </div>;
};

export default observer(KioskStatusesView);
