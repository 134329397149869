import { get as _get, keyBy as _keyBy } from 'lodash';

// Constants
import * as ROUTE from 'constants/routes';
import { MAX_ENTRIES_PER_PAGE } from '../constants';

// Stores
import stores from 'bootstrap/store';
import { stockingBatchesRouteStore } from '../domain/store';
const router = stores.router;

const listStockingBatches = async () => {
  const config = {
    query: {
      offset: MAX_ENTRIES_PER_PAGE * (stockingBatchesRouteStore.currentPage - 1),
      limit: MAX_ENTRIES_PER_PAGE,
    }
  };

  stockingBatchesRouteStore.incrementHttp('listingStockingBatches');
  return stores.global.stockingBatches.listStockingBatches(config)
  .catch(() => router.history.push(ROUTE.STOCKING_BATCHES.VALUE))
  .then((response = {}) => {
    const stockingBatches = response.data || [];
    stockingBatchesRouteStore.mergeStockingBatchesById(_keyBy(stockingBatches, 'id'));

    const responseLength = _get(response, 'data.length', 0);
    if (responseLength > MAX_ENTRIES_PER_PAGE - 1) {
      stockingBatchesRouteStore.setValue('currentPage', stockingBatchesRouteStore.currentPage + 1);
    }
  })
  .finally(() => stockingBatchesRouteStore.decrementHttp('listingStockingBatches'));
};

export default listStockingBatches;
