import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';

// Views
import HealthcareSitesView from './healthcare-sites-view';

// Helpers
import * as helpers from './helpers';

// Stores
import stores from 'bootstrap/store';
import { healthcareSitesRouteStore } from './domain/store';
const healthcareSitesStore = stores.global.healthcareSites;
const locationsStore = stores.global.locations;
const pharmaciesStore = stores.global.pharmacies;


const HealthcareSitesContainer = ({ rootRoute }) => {
  const navigate = useNavigate();
  const { healthcareSiteId } = useParams();

  useEffect(() => {
    healthcareSitesRouteStore.setValue('activeHealthcareSiteId', null);
    healthcareSitesRouteStore.setValue('routeHealthcareSiteId', null);
    helpers.listHealthcareSites();

    return () => {
      healthcareSitesRouteStore.setValue('currentPage', 1);
    };
  }, []);

  useEffect(() => {
    if (!healthcareSitesRouteStore.http.listingHealthcareSites) {
      helpers.determineActiveHealthcareSite(healthcareSiteId, navigate);
    }
  }, [healthcareSiteId, healthcareSitesRouteStore.http.listingHealthcareSites]);

  return <HealthcareSitesView
    healthcareSitesRouteStore={healthcareSitesRouteStore}
    pharmaciesStore={pharmaciesStore}
    healthcareSitesStore={healthcareSitesStore}
    setFilterValue={helpers.setFilterValue}
    onListBottomThreshold={helpers.onListBottomThreshold}
    locationsStore={locationsStore}
    createHealthcareSite={helpers.createHealthcareSite}
    showHealthcareSiteUpsertModal={helpers.showHealthcareSiteUpsertModal}
    closeHealthcareSiteUpsertModal={helpers.closeHealthcareSiteUpsertModal}
    rootRoute={rootRoute}
  />;
};

export default observer(HealthcareSitesContainer);
