import stores from 'bootstrap/store';

// Constants
import * as ROUTE from 'constants/routes';
import { EVENT } from '../../constants';

// Helpers
import {
  listForPharmacistCautiously as listDispenseAttemptsForPharmacistCautiously,
} from 'models/dispense-attempts/helpers';
import { isActiveRoute } from '../../../store/router';

import notifications from 'bootstrap/notifications';

export default ({ apiWebSocket }) => {
  // const HANDLER = {
  //   [DISPENSE_ATTEMPT_STATUS.DISPENSED]: (payload) => {
  //     const conference = stores.global.videoConferences.conferences.get(PURPOSE.PRESCRIPTION_CONSULTATION) || {};
  //     if (!conference) return;

  //     const videoConference = stores.global.videoConferences.activePendingConference;
  //     const conferenceDispenseAttemptId = _get(videoConference, 'payload.dispenseAttempt.id');
  //     if (!conferenceDispenseAttemptId) return;

  //     const { dispenseAttempt } = payload;

  //     if (conferenceDispenseAttemptId === dispenseAttempt.id) {
  //       // TODO maybe see if rather than having this be set imperatively, we can have it set declaratively using
  //       // a computed property on the store.
  //       stores.global.dispenseAttempts.setValue('remoteDispense.containerDispensingStage', CONTAINER_DISPENSING_STAGE.DISPENSED);
  //     }
  //   },
  //   [DISPENSE_ATTEMPT_STATUS.APPROVED]: ({ dispenseAttempt }) => {
  //     if (stores.global.dispenseAttempts.pending.get(dispenseAttempt.id)) stores.global.dispenseAttempts.listPendingForNotificationsPane();
  //   },
  //   [DISPENSE_ATTEMPT_STATUS.REJECTED]: ({ dispenseAttempt }) => {
  //     if (stores.global.dispenseAttempts.pending.get(dispenseAttempt.id)) stores.global.dispenseAttempts.listPendingForNotificationsPane();
  //   },
  //   [DISPENSE_ATTEMPT_STATUS.PENDING_REVIEW]: () => {
  //     stores.global.dispenseAttempts.listPendingForNotificationsPane();
  //   },
  // };

  apiWebSocket.on(EVENT.RECEIVED.CONTAINER_LABELLED, async (payload) => {
    const { container } = payload;
    if (!container) return;

    // const handler = HANDLER[dispenseAttempt.status];

    // if (!handler) return;

    await Promise.all([
      stores.global.dispenseAttempts.listPendingForNotificationsPane(),
      listDispenseAttemptsForPharmacistCautiously({ shouldFilter: true }),
    ]);
    if (!isActiveRoute(ROUTE.DISPENSE_ATTEMPTS.VALUE)) {
      const dispenseAttempt = stores.global.dispenseAttempts.forPharmacistToArray.find(({ containerId }) => containerId === container.id);
      if (dispenseAttempt) notifications.create('New dispense attempt needs review', { toUri: `${ROUTE.DISPENSE_ATTEMPTS.VALUE}/${dispenseAttempt.id}` });
    }
  });
};
