import { types } from 'mobx-state-tree';
import { set as _set } from 'lodash';

import stores from 'bootstrap/store';

export const UsersRouteStore = types.model({
})
.props({
  filterValue: types.optional(types.string, ''),
  activeUserId: types.maybeNull(types.string),
  sortingColumn: types.optional(types.model({
    fieldName: types.optional(types.string, 'name'),
    sortingOrder: types.optional(types.string, 'asc')
  }), {}),
  http: types.optional(types.model({
    listingForPharmacist: types.optional(types.number, 0),
    countingForPharmacist: types.optional(types.number, 0),
  }), {}),
  currentPage: types.optional(types.number, 1),
  showingUserUpsertModal: types.optional(types.boolean, false)
})
.actions(self => ({
  setValue: (path, value) => _set(self, path, value),
  incrementHttp: (http) => ++self.http[http],
  decrementHttp: (http) => --self.http[http],
}))
.views(self => ({
  get loading() {
    return !!(self.http.listingForPharmacist && self.http.countingForPharmacist);
  },
  get activeUser() {
    const usersStore = stores.global.users;
    return self.activeUserId && usersStore.dataForPharmacist.get(self.activeUserId);
  },
  get forTable() {
    const usersStore = stores.global.users;
    return usersStore.dataAsArray.map(user => ({
      id: user.id,
      name: `${user.firstName} ${user.lastName}`,
      email: user.email,
      primaryRole: user.primaryRole || 'None',
      isAdmin: user.isAdmin,
    }));
  },
}));

export const usersRouteStore = UsersRouteStore.create({});
