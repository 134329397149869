import React from 'react';
import { observer } from 'mobx-react';

// Components
import SlotsTable from './components/slots-table';

// Styles
import './kiosk-storage-view.scss';

const KioskStorageView = ({
  kioskStorageRouteStore,
}) => (
  <div className='c-KioskStorage'>
    <div className='content'>
      <SlotsTable
        rows={kioskStorageRouteStore.forSlotsTable}
      />
    </div>
  </div>
);

export default observer(KioskStorageView);
