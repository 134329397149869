import React, { useCallback, useState } from 'react';
import _debounce from 'lodash/debounce';

import AutocompleteView from './autocomplete-view';

function AutocompleteContainer({
  onSearch = () => {},
  onChange = () => {},
  onSuggestionSelect = () => {},
  suggestions,
  debounce = {},
  getSuggestionKey = (suggestion) => suggestion.id,
  renderSuggestion,
  placeholder = 'Search...',
}) {
  const [term, setTerm] = useState('');

  const { maxWait = 500, leading = false, trailing = true, wait = 200 } = debounce;
  const onSearchDebounced  = useCallback(_debounce(onSearch, wait, { leading, trailing, maxWait }), [onSearch, maxWait, leading, trailing, wait]);
  const onChangeWrapper = useCallback((value) => {
    setTerm(value);
    onChange(value);
    onSearchDebounced(value);
  }, [onChange, onSearchDebounced]);

  const onSuggestionSelectWrapper = useCallback((suggestion) => {
    setTerm('');
    onSuggestionSelect(suggestion);
  }, [onSuggestionSelect]);

  return (
    <AutocompleteView
      term={term}
      onChange={onChangeWrapper}
      onSuggestionSelect={onSuggestionSelectWrapper}
      suggestions={suggestions}
      getSuggestionKey={getSuggestionKey}
      renderSuggestion={renderSuggestion}
      placeholder={placeholder}
    />
  );
}

export default AutocompleteContainer;
