import React from 'react';
import { observer } from 'mobx-react';

// Components
import List from 'components/drill-down-layout/components/list';
import ListItem from 'components/drill-down-layout/components/list/components/list-item';
import Header from './components/header';
import Technician from './components/technician';

// Icons
import PatientIcon from 'assets/icons/patient.svg';


const MachineTechniciansList = ({
  machineTechniciansRouteStore,
  machineTechniciansStore,
  setFilterValue,
  listMachineTechnicians,
  rootRoute,
}) => (
  <List
    header={<Header
      machineTechniciansRouteStore={machineTechniciansRouteStore}
      setFilterValue={setFilterValue}
    />}
    isReady={!machineTechniciansRouteStore.http.listingTechnicians || machineTechniciansStore.machineTechnicians.size}
    onBottomThreshold={listMachineTechnicians}
  >
    {
      machineTechniciansStore.dataList.map(technician => (
        <ListItem
          key={technician.id}
          title={`${technician.firstName} ${technician.lastName}`}
          isActive={machineTechniciansRouteStore.activeTechnicianId === technician.id}
          iconStyle={{width: '50px', height: '50px'}}
          icon={
            <img
              alt='technician'
              className='technician-view-icon-preview'
              src={PatientIcon}
            />
          }
          url={`${rootRoute}/${technician.id}`}
        >
          <Technician
            kiosksCaption={machineTechniciansRouteStore.getTechnicianKiosksCountCaption(technician)}
          />
        </ListItem>
      ))
    }
  </List>
);

export default observer(MachineTechniciansList);
