import stores from 'bootstrap/store';
import { lotInfoRouteStore } from '../domain/store';

const listingDrugsByLot = async (lotId) => {
  if (!lotId) {
    return;
  }

  lotInfoRouteStore.incrementHttp('listingDrugsByLot');
  return stores.global.drugs.listDrugsByLot(lotId)
  .then((response) => lotInfoRouteStore.setValue('drugsByLotById', response))
  .finally(() => lotInfoRouteStore.decrementHttp('listingDrugsByLot'));
};

export default listingDrugsByLot;
