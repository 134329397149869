import React from 'react';
import { observer } from 'mobx-react';

import ListHeader from 'components/drill-down-layout/components/list/components/list-header';

import {HEALTHCARE_SITE_MANIPULATION_OPTIONS} from '../../../../constants';

import './style.scss';

const HealthcareSitesListHeader = ({
  filter,
  setFilter,
  showHealthcareSiteUpsertModal,
}) => (
  <ListHeader
    title={
      <div className='c-HealthcareSiteHeaderTitle'>
        <div className='title'>Healthcare Sites</div>
        <button className='add-new-button' onClick={e => showHealthcareSiteUpsertModal(e, HEALTHCARE_SITE_MANIPULATION_OPTIONS.CREATE)}>New +</button>
      </div>
    }
    searchValue={filter}
    onSearch={(e) => setFilter(e)}
  />
);

export default observer(HealthcareSitesListHeader);
