import React from 'react';
import {observer} from 'mobx-react';

import './style.scss';

import ContentScreen from 'components/content-screen';
import InfinityScroll from 'components/infinite-scroll';

const AppCommands = ({
  appCommands = [],
  isAppCommandsLoadActive = false,
  buildAppCommand,
  appCommandIcons,
  fetchMoreAppCommands,
}) => (
  <div className='c-appCommandsLog'>
    <ContentScreen active={!!isAppCommandsLoadActive} />
    {
      !isAppCommandsLoadActive && appCommands.length === 0
        ? (
          <div className='no-data-text'>
            <div className='text'>
              No available appCommands for current filter.
            </div>
          </div>
        )
        : <InfinityScroll
          onBottomThreshold={fetchMoreAppCommands}
        >
          {
            appCommands.map(appCommandsByDate => (
              <React.Fragment key={appCommandsByDate.date}>
                <div className='date-header'>
                  <div className='date'>{new Date(appCommandsByDate.date).toLocaleDateString([], {month: 'long', day: '2-digit', year: 'numeric'})}</div>
                  <div className='line'/>
                </div>
                {
                  appCommandsByDate.data.map(appCommand => (
                    <div className='data' key={appCommand.id}>
                      <div className='time'>
                        {new Date(appCommand.createdAt).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'})}
                      </div>
                      <div className='icon'>
                        <div className='image-wrapper'>
                          <img src={appCommandIcons[appCommand.type]} className='image' />
                        </div>
                        <div className='bottom-line' />
                      </div>
                      <div className='main-data'>
                        {
                          buildAppCommand(appCommand)
                        }
                        <div className='bottom-line' />
                      </div>
                    </div>
                  ))
                }
              </React.Fragment>
            ))
          }
        </InfinityScroll>
    }
  </div>
);

export default observer(AppCommands);
