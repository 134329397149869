import React from 'react';
import { observer } from 'mobx-react';

import CaptionValueInput from 'components/caption-value-input';

import { PRESCRIPTION_FILL_CONFIG, RESULT_CLASS_NAMES } from './constants';
import { DEFAULT_VALUES } from './domain/store';

import './prescription-fill-upsert-style.scss';

const PrescriptionFillUpsertView = ({
  prescriptionFillsListRouteStore,
  upsertPrescriptionFill,
}) => {
  return (
    <div className='c-PrescriptionFillUpsert'>
      <div className='header'>
        <div className='title'>
          Prescription Info
        </div>
        <div className='buttons'>
          {
            prescriptionFillsListRouteStore.resultStatus && prescriptionFillsListRouteStore.resultStatus.message &&
            <div className={`status ${RESULT_CLASS_NAMES[prescriptionFillsListRouteStore.resultStatus.type]}`}>
              {prescriptionFillsListRouteStore.resultStatus.message}
            </div>
          }
          <button className='create-button' onClick={upsertPrescriptionFill} disabled={!!prescriptionFillsListRouteStore.http.upsertPrescriptionFill}>
            Create
          </button>
        </div>
      </div>
      <div className='parts'>
        {
          PRESCRIPTION_FILL_CONFIG.map((config) => (
            <div key={config.modelName} className='part-wrapper'>
              <div className='title'>
                <div className='header'>
                  {config.modelTitle}
                </div>
                <button
                  className='clear-button'
                  onClick={() => prescriptionFillsListRouteStore.setValue(config.modelName, DEFAULT_VALUES[config.modelName])}
                >
                  Clear
                </button>
              </div>
              {
                config.fields.map((fieldConfig) => (
                  <CaptionValueInput
                    type={fieldConfig.type || 'text'}
                    caption={fieldConfig.name}
                    key={fieldConfig.name}
                    value={prescriptionFillsListRouteStore[config.modelName][fieldConfig.path]}
                    required={!!fieldConfig.required}
                    onChange={
                      (value) => {
                        prescriptionFillsListRouteStore.setValue(
                          `${config.modelName}.${fieldConfig.path}`,
                          value
                        );
                      }
                    }
                    {...(fieldConfig.specProps || {})}
                  />
                ))
              }
            </div>
          ))
        }
      </div>
    </div>
  );
};

export default observer(PrescriptionFillUpsertView);
