import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import InfoView from './info-view';

import { lotInfoRouteStore } from './domain/store';

import fetchLotById from './helpers/fetchLotById';
import listDrugsByLot from './helpers/listDrugsByLot';

const InfoContainer = ({ activeLotId }) => {
  useEffect(() => {
    fetchLotById(activeLotId);
    listDrugsByLot(activeLotId);

    lotInfoRouteStore.clearDrugIdsToBeRecalled();

    return () => {
      lotInfoRouteStore.setInitialState();
    };
  }, [activeLotId]);

  return <InfoView
    lotInfoRouteStore={lotInfoRouteStore}
  />;
};

export default observer(InfoContainer);
