import deepFreeze from 'deep-freeze';

export const MODEL_NAME = Object.freeze({
  SINGULAR: 'videoConference',
  PLURAL: 'video-conferences',
});

export const FIELD_NAME = Object.freeze({
  ID: 'id',
  PURPOSE: 'purpose',
  NAME: 'name',
  COMPUTED_ACTIVE: 'computed_active',
  VIDEO_CONFERENCE_PURPOSE_ID: 'videoConferencePurposeId',
  CREATED_AT: 'createdAt',
  UPDATED_AT: 'updatedAt'
});

export const SCHEMA = deepFreeze({
  title: MODEL_NAME.SINGULAR,
  type: 'object',
  properties: {
    [FIELD_NAME.ID]: {
      type: 'string'
    },
    [FIELD_NAME.PURPOSE]: {
      type: 'object',
      properties: {
        [FIELD_NAME.ID]: {
          type: 'string'
        },
        [FIELD_NAME.NAME]: {
          type: 'string'
        },
      },
    },
    [FIELD_NAME.COMPUTED_ACTIVE]: {
      type: 'boolean'
    },
    [FIELD_NAME.VIDEO_CONFERENCE_PURPOSE_ID]: {
      type: 'string'
    },
    [FIELD_NAME.CREATED_AT]: {
      type: 'string'
    },
    [FIELD_NAME.UPDATED_AT]: {
      type: 'string'
    },
  },
  required: [
    FIELD_NAME.ID
  ]
});
