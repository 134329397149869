import { types } from 'mobx-state-tree';

import stores from 'bootstrap/store';


export const LotPrescriptionFillsRouteStore = types.model({
  filterValue: types.optional(types.string, ''),
  http: types.optional(types.model({
    listingPrescriptionFills: types.optional(types.number, 0),
  }), {}),
})
.actions((self) => ({
  setValue: (prop, value) => self[prop] = value,
  setInitialState: () => {
    self.filterValue = '';
    self.http = {
      listingPrescriptionFills: 0,
    };
  },
  incrementHttp: (http) => ++self.http[http],
  decrementHttp: (http) => --self.http[http]
}))
.views((self) => ({
  get forTable() {
    const { forPharmacistToArray } = stores.global.prescriptionFills;
    return forPharmacistToArray.map((prescriptionFill) => {
      const { drug, patient } = prescriptionFill.prescription;
      return {
        name: `${patient.firstName} ${patient.lastName}`,
        externalPharmacyId: patient.externalPharmacyId,
        phoneNumber: patient.phoneNumber || 'N/A',
        drug: {
          name: drug.name,
          NDC: drug.NDC,
        },
        prescription: {
          rxId: prescriptionFill.prescription.rxId,
        },
        creationDate: new Date(prescriptionFill.createdAt).toLocaleDateString(),
      };
    });
  },
  get isBusy() {
    return Boolean(self.http.listingPrescriptionFills);
  },
}));

export const lotPrescriptionFillsRouteStore = LotPrescriptionFillsRouteStore.create({});
