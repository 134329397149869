import deepFreeze from 'deep-freeze';

export const MODEL_NAME = Object.freeze({
  SINGULAR: 'stockingBatch',
  PLURAL: 'stocking-batches',
});

export const FIELD_NAME = Object.freeze({
  ID: 'id',
  IS_SCANNING_DEFERRED: 'isScanningDeferred',
  KIOSK_ID: 'kioskId',
  STOCKING_USER_ID: 'stockingUserId',
  CREATED_AT: 'createdAt',
  UPDATED_AT: 'updatedAt',
});

export const SCHEMA = deepFreeze({
  title: MODEL_NAME.SINGULAR,
  type: 'object',
  properties: {
    [FIELD_NAME.ID]: {
      type: 'string',
    },
    [FIELD_NAME.IS_SCANNING_DEFERRED]: {
      type: 'boolean',
    },
    [FIELD_NAME.KIOSK_ID]: {
      type: 'string',
    },
    [FIELD_NAME.STOCKING_USER_ID]: {
      type: 'string',
    },
    [FIELD_NAME.CREATED_AT]: {
      type: 'string'
    },
    [FIELD_NAME.UPDATED_AT]: {
      type: 'string'
    },
  },
});
