import React from 'react';
import { observer } from 'mobx-react';

import ListHeader from 'components/drill-down-layout/components/list/components/list-header';


const PharmacyTechniciansListHeader = ({
  pharmacyTechniciansRouteStore,
  setFilterValue,
}) => (
  <ListHeader
    title='Pharmacy Technicians'
    // Search
    searchValue={pharmacyTechniciansRouteStore.filterValue}
    onSearch={(e) => setFilterValue(e)}
    searchPlaceholder='Technician Name...'
  />
);

export default observer(PharmacyTechniciansListHeader);
