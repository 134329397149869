import React from 'react';
import { observer } from 'mobx-react';

import ListHeader from 'components/drill-down-layout/components/list/components/list-header';


const MachineTechniciansListHeader = ({
  machineTechniciansRouteStore,
  setFilterValue,
}) => (
  <ListHeader
    title='Machine Technicians'
    // Search
    searchValue={machineTechniciansRouteStore.filterValue}
    onSearch={(e) => setFilterValue(e)}
    searchPlaceholder='Technician Name...'
  />
);

export default observer(MachineTechniciansListHeader);
