import { debounce as _debounce } from 'lodash';

// Helpers
import listPharmacyTechnicians from './listPharmacyTechnicians';

// Stores
import stores from 'bootstrap/store';
const pharmacyTechniciansStore = stores.global.pharmacyTechnicians;

export default _debounce(() => {
  pharmacyTechniciansStore.setValue('pharmacyTechnicians', {});
  return listPharmacyTechnicians();
}, 350);
