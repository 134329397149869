import React from 'react';
import { observer } from 'mobx-react';

import sortAscIcon from '../../assets/icons/sort-asc.svg';
import sortDescIcon from '../../assets/icons/sort-desc.svg';
import neutralSortIcon from '../../assets/icons/sort-neutral.svg';

import {
  DEFAULT,
  PROP_TYPES,
  SORTING_ORDER_OPTION,
} from '../../constants';
const { ASC, DESC } = SORTING_ORDER_OPTION;

const SmartTableHeader = ({
  sortingFieldName = undefined,
  sortingOrder = undefined,
  onSortIconClick = undefined,
  columns
}) => (
  <div className="SmartTableHeader">
    {
      columns.map(column => (
        <div
          className="header-cell"
          style={{width: column.width || DEFAULT.COLUMN_WIDTH}}
          key={column.name}
        >
          <div className='title'>
            {
              column.display !== undefined
                ? column.display
                : column.name
            }
          </div>
          {
            column.fieldName && column.isSortable && sortingFieldName && sortingOrder && onSortIconClick &&
            <img
              style={{ cursor: 'pointer' }}
              className='icon'
              src={setSortIcon(column.fieldName, sortingFieldName, sortingOrder)}
              onClick={() => setSortIconAction(column.fieldName, sortingFieldName, sortingOrder, onSortIconClick)}
            />
          }
        </div>
      ))
    }
  </div>
);

export default observer(SmartTableHeader);

SmartTableHeader.propTypes = {
  columns: PROP_TYPES.COLUMNS
};

function setSortIcon(columnFieldName, sortingFieldName, sortingOrder) {
  // if content is being sorted by column with fieldName = columnFieldName
  if (columnFieldName === sortingFieldName) {
    if (!sortingOrder || sortingOrder === DESC) return sortDescIcon;
    else return sortAscIcon;
  } else return neutralSortIcon;
}

function setSortIconAction(columnFieldName, sortingFieldName, sortingOrder, onClick) {
  // if content is being sorted by column with fieldName = columnFieldName
  if (columnFieldName === sortingFieldName) {
    if (sortingOrder === DESC) return onClick(columnFieldName, ASC);
    else return onClick(columnFieldName, DESC);
  } else return onClick(columnFieldName, ASC);
}
