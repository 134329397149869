import React from 'react';
import { observer } from 'mobx-react';

import ContactIcon from 'assets/icons/contact.svg';
import HospitalIcon from 'assets/icons/hospital-active.svg';
import LocationIcon from 'assets/icons/location-active.svg';

import './healthcare-site-style.scss';

const EntityHealthcareSite = ({ healthcareSite = {} }) => {
  const location = healthcareSite.location || {};
  const address = `${location.street1}, ${location.city}, ${location.state}, ${location.zip}`;

  return <div className='c-EntityHealthcareSite'>
    <div className='healthcareSite-info'>
      <img
        alt='Healthcare Site Name'
        className='icon'
        src={HospitalIcon}
      />
      <div className='value'>
        {
          healthcareSite.name
        }
      </div>
    </div>
    <div className='healthcareSite-info'>
      <img
        alt='Healthcare Site Location'
        className='icon'
        src={LocationIcon}
      />
      <div className='value'>
        {
          address
        }
      </div>
    </div>
    <div className='healthcareSite-info'>
      <img
        alt='Healthcare Site Phone Number'
        className='icon'
        src={ContactIcon}
      />
      <div className='value'>
        {
          healthcareSite.phoneNumber || 'N/A'
        }
      </div>
    </div>
  </div>;
};

export default observer(EntityHealthcareSite);
