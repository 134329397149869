import { get as _get, keyBy as _keyBy } from 'lodash';

import * as ROUTES from 'constants/routes';
import { MAX_ENTRIES_PER_PAGE } from '../constants';

import stores from 'bootstrap/store';
import { kiosksRouteStore } from '../domain/store';
const kiosksStore = stores.global.kiosks;
const history = stores.router;


export default () => {
  const query = {
    offset: MAX_ENTRIES_PER_PAGE * (kiosksRouteStore.currentPage - 1),
    limit: MAX_ENTRIES_PER_PAGE,
  };

  const { filterValue } = kiosksRouteStore;
  if (filterValue) {
    query.filter = {
      name: filterValue,
    };
  }
  const config = { query };

  kiosksRouteStore.incrementHttp('listingKiosk');
  return kiosksStore.listKiosks(config)
  .then((response) => {
    kiosksRouteStore.setValue('kiosks', _keyBy(response, 'id'));
    const responseLength = _get(response, 'length', 0);
    if (responseLength > MAX_ENTRIES_PER_PAGE - 1) {
      kiosksRouteStore.setValue('currentPage', kiosksRouteStore.currentPage + 1);
    }
  })
  .catch(() => history.push(ROUTES.KIOSKS.VALUE))
  .finally(() => kiosksRouteStore.decrementHttp('listingKiosk'));
};
