import { flow, types } from 'mobx-state-tree';
import {
  keyBy as _keyBy,
  debounce as _debounce,
} from 'lodash';

import base from 'models/base';

import { MODEL_NAME, SCHEMA } from './constants/schema';


const PharmaciesModel = base({
  names: {
    singular: MODEL_NAME.SINGULAR,
    plural: MODEL_NAME.PLURAL
  },
  JSONSchema: SCHEMA,
  httpConfig: {
    root: MODEL_NAME.plural,
    methods: {}
  }
});

PharmaciesModel.configureStore((store) => {
  return store
  .props({
    data: types.optional(types.map(types.frozen()), {}),
    entriesCount: types.optional(types.number, 0),
    dataForAutocomplete: types.optional(types.array(types.frozen()), []),
  })
  .actions(self => ({
    setValue: (prop, value) => self[prop] = value,
    setInitialState: () => {
      self.data = {};
      self.entriesCount = 0;
    },
    listPharmacies: flow(function* (config = {}) {
      config.urlFragment = () => '/';
      const response = yield self.list(config);
      const data = response.data || response || [];
      self.data = {};
      self.data = _keyBy(data, 'id');

      return response;
    }),
    listForPharmacist: flow(function* (config = {}) {
      config.urlFragment = () => '/for-pharmacist';
      const response = yield self.list(config);
      const data = response.data || response || [];

      self.data = {};
      self.data = _keyBy(data, 'id');

      return response;
    }),
    autocompleteForPharmacist: flow(function* (config = {}) {
      config.query = config.query || {};
      config.query.offset = 0;
      config.urlFragment = () => '/for-pharmacist';
      const response = yield self.list(config);
      const data = response.data || response || [];

      self.dataForAutocomplete = [];
      self.dataForAutocomplete = data;

      return response;
    }),
    countPharmacies: flow(function* (config = {}) {
      config.urlFragment = () => '/count';

      const response = yield self.list(config);
      self.entriesCount = response || 0;

      return response;
    }),
    upsertPharmacy: flow(function* (config = {}) {
      config.urlFragment = () => '/';

      const response = yield self.put(config);

      return response;
    }),
    deletePharmacy: (config = {}) => {
      return self.delete(config);
    },
    addHealthcareSite: (config = {}) => {
      config.urlFragment = (params) => `/${params.pharmacyId}/healthcare-sites/${params.healthcareSiteId}`;

      return self.post(config);
    },
    deleteHealthcareSite: (config = {}) => {
      config.urlFragment = (params) => `/${params.pharmacyId}/healthcare-sites/${params.healthcareSiteId}`;

      return self.delete(config);
    }
  }))
  .actions(self => ({
    autocompleteForPharmacistDebounce: _debounce(self.autocompleteForPharmacist, 350),
  }))
  .views(self => ({
    get forPharmacistAsArray() {
      return Array.from(self.data.values());
    },
    get autoCompleteDataForSelect() {
      return self.dataForAutocomplete.map((pharmacy) => ({
        label: pharmacy.name,
        value: pharmacy.id,
      }));
    }
  }));
});


export default PharmaciesModel;
