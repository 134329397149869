import Promise from 'bluebird';
import { types } from 'mobx-state-tree';

import recallDrug from '../helpers/recallDrug';
import listDrugsByLot from '../helpers/listDrugsByLot';

export const LotInfoRouteStore = types.model({
  activeLot: types.frozen(),
  drugsByLotById: types.optional(types.map(types.frozen()), {}),
  drugIdsToRecall: types.optional(types.frozen(), new Set()),
  http: types.optional(types.model({
    fetchingLotById: types.optional(types.number, 0),
    listingDrugsByLot: types.optional(types.number, 0),
    creatingLotDrugRecall: types.optional(types.number, 0),
  }), {}),
})
.views((self) => ({
  get isBusy() {
    return Boolean(self.http.fetchingLotById || self.http.listingDrugsByLot || self.http.listingDrugsByLot);
  },
  get drugIdsToRecallList() {
    return Array.from(self.drugIdsToRecall);
  },
  get drugsList() {
    return Array.from(this.drugsByLotById.values());
  },
  get recalledDrugsCount() {
    return self.drugsList.reduce((count, drug) => count + ~~!!drug.lotDrugRecall, 0);
  },
  get unrecalledDrugsCount() {
    return self.drugsList.length - self.recalledDrugsCount;
  },
}))
.actions((self) => ({
  setValue: (prop, value) => self[prop] = value,
  setInitialState: () => {
    self.activeLot = null;
    self.drugsByLotById = {};
    self.drugIdsToRecall = {};
    self.http = {
      fetchingLotById: 0,
      listingDrugsByLot: 0,
      creatingLotDrugRecall: 0,
    };
  },
  incrementHttp: (http) => ++self.http[http],
  decrementHttp: (http) => --self.http[http],
  addDrugIdsToBeRecalled: (drugIds) => {
    drugIds = Array.isArray(drugIds) ? drugIds : [drugIds];
    drugIds.forEach(drugId => self.drugIdsToRecall.add(drugId));
    self.drugIdsToRecall = new Set(self.drugIdsToRecall);
  },
  removeDrugIdsToBeRecalled: (drugIds) => {
    drugIds = Array.isArray(drugIds) ? drugIds : [drugIds];
    drugIds.forEach(drugId => self.drugIdsToRecall.remove(drugId));
  },
  clearDrugIdsToBeRecalled: () => self.drugIdsToRecall = new Set(),
  executeDrugRecalls: async () => {
    const recallList = self.drugIdsToRecallList;

    self.clearDrugIdsToBeRecalled();

    await Promise.map(recallList, async (drugId) => {
      return recallDrug(drugId);
    });

    await listDrugsByLot();
  },
}))
.actions((self) => ({
  addAllDrugIdsToBeRecalled: () => {
    self.addDrugIdsToBeRecalled(self.drugsList.reduce((list, drug) => {
      !drug.lotDrugRecall && list.push(drug.id);
      return list;
    }, []));
  },
}));

export const lotInfoRouteStore = LotInfoRouteStore.create({});
