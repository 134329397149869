import { types } from 'mobx-state-tree';


export const ChangePinRouteStore = types.model({
  pin: types.optional(types.string, ''),
  pinConfirm: types.optional(types.string, ''),
  errorMessage: types.optional(types.string, ''),
})
.actions(self => ({
  setValue: (prop, value) => {
    if (self.errorMessage) self.errorMessage = '';
    self[prop] = value;
  },
  setInitialState: () => {
    self.pin = '';
    self.pinConfirm = '';
    self.errorMessage = '';
  }
}));

export const changePinRouteStore = ChangePinRouteStore.create({});
