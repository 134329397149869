// Constants
import * as ROUTE from 'constants/routes';

// Stores
import stores from 'bootstrap/store';
import { stockingBatchesRouteStore } from '../domain/store';
const router = stores.router;

const fetchActiveStockingBatchById = async () => {
  const { activeStockingBatchId } = stockingBatchesRouteStore;
  if (!activeStockingBatchId) {
    return;
  }

  stockingBatchesRouteStore.incrementHttp('fetchingStockingBatchById');
  return stores.global.stockingBatches.getStockingBatchById(activeStockingBatchId)
  .catch(() => router.history.push(ROUTE.STOCKING_BATCHES.VALUE))
  .then((stockingBatch) => stockingBatchesRouteStore.setValue('activeStockingBatch', stockingBatch))
  .finally(() => stockingBatchesRouteStore.decrementHttp('fetchingStockingBatchById'));
};

export default fetchActiveStockingBatchById;
