import { types } from 'mobx-state-tree';
import {
  difference as _difference,
  get as _get,
  set as _set,
} from 'lodash';

import MedicalAssistantModel from 'models/medical-assistants';

import stores from 'bootstrap/store';
import { medicalAssistantsRouteStore } from './store';
const healthcareSitesStore = stores.global.healthcareSites;

export const MedicalAssistantsUpsertModalRouteStore = types.model({
})
.props({
  activelyModifiedMedicalAssistant: types.maybeNull(types.frozen()),
  workPlaceFilter: types.optional(types.string, ''),
  showingDeleteConfirmationModal: types.optional(types.boolean, false)
})
.actions(self => ({
  setValue: (path, value) => _set(self, path, value),
  setInitialState() {
    self.activelyModifiedMedicalAssistant = null;
    self.workPlaceFilter = '';
    self.showingDeleteConfirmationModal = false;
  },
  setActivelyModifiedMedicalAssistantUser: (path, value) => {
    if (!_get(self, 'activelyModifiedMedicalAssistant.maUser.user')) return;
    self.setValue(`activelyModifiedMedicalAssistant.maUser.user.${path}`, value);
  },
  addHealthcareSite: (kiosk) => {
    if (!self.activelyModifiedMedicalAssistant) return;
    self.activelyModifiedMedicalAssistant.healthcareSites = self.activelyModifiedMedicalAssistant.healthcareSites || [];
    self.activelyModifiedMedicalAssistant.healthcareSites.push(kiosk);
  },
  deleteHealthcareSite: (kiosk) => {
    self.activelyModifiedMedicalAssistant.healthcareSites = self.activelyModifiedMedicalAssistant.healthcareSites || [];
    const removalIndex = self.activelyModifiedMedicalAssistant.healthcareSites.findIndex((technicianKiosk) => {
      return technicianKiosk.id === kiosk.id;
    });
    if (removalIndex !== -1) self.activelyModifiedMedicalAssistant.healthcareSites.splice(removalIndex, 1);
  },
}))
.views(self => ({
  get readyForCreation() {
    const { activelyModifiedMedicalAssistant } = self;
    return (
      activelyModifiedMedicalAssistant &&
      activelyModifiedMedicalAssistant.firstName &&
      activelyModifiedMedicalAssistant.lastName
    );
  },
  get readyForUpdate() {
    const { activeMedicalAssistant } = medicalAssistantsRouteStore;
    const { activelyModifiedMedicalAssistant } = self;
    if (!activeMedicalAssistant || !activelyModifiedMedicalAssistant) return false;

    const kioskIds = (activeMedicalAssistant.healthcareSites || []).map((kiosk) => kiosk.id);
    const modifiedHealthcareSiteIds = (activelyModifiedMedicalAssistant.healthcareSites || []).map((kiosk) => kiosk.id);
    return (
      activeMedicalAssistant.firstName !== activelyModifiedMedicalAssistant.firstName ||
        activeMedicalAssistant.lastName !== activelyModifiedMedicalAssistant.lastName ||
        _difference(kioskIds, modifiedHealthcareSiteIds).length ||
        _difference(modifiedHealthcareSiteIds, kioskIds).length ||
        _get(activeMedicalAssistant, 'maUser.user.email') !== _get(activelyModifiedMedicalAssistant, 'maUser.user.email') ||
        _get(activeMedicalAssistant, 'maUser.user.phoneNumber') !== _get(activelyModifiedMedicalAssistant, 'maUser.user.phoneNumber')
    );
  },
  get suggestedWorkPlaces() {
    const { activelyModifiedMedicalAssistant } = self;
    if (!activelyModifiedMedicalAssistant) return [];
    const { healthcareSites } = activelyModifiedMedicalAssistant;
    const modifiedHealthcareSiteIds = healthcareSites.map((kiosk) => kiosk.id);

    const { forAdminList } = healthcareSitesStore;
    return forAdminList.filter((healthcareSite) => !modifiedHealthcareSiteIds.includes(healthcareSite.id));
  },
  get activelyModifiedMedicalAssistantUser() {
    return _get(self, 'activelyModifiedMedicalAssistant.maUser.user');
  },
  get activeMedicalAssistantWorkPlaces() {
    const { activelyModifiedMedicalAssistant } = self;
    if (!activelyModifiedMedicalAssistant) return undefined;
    return activelyModifiedMedicalAssistant.healthcareSites;
  },
}));

export const medicalAssistantsUpsertModalRouteStore = MedicalAssistantsUpsertModalRouteStore.create({});
