import { toJS } from 'mobx';

import stores from 'bootstrap/store';
import { sendReceiptModalRouteStore } from '../domain/store';

const updatePatientById = async () => {
  const shouldSaveContactDetails = sendReceiptModalRouteStore.shouldSaveEmail || sendReceiptModalRouteStore.shouldSavePhoneNumber;

  if (!sendReceiptModalRouteStore.patient || !shouldSaveContactDetails) {
    return;
  }

  sendReceiptModalRouteStore.incrementHttp('updatingPatientById');
  return stores.global.patients.updatePatient({
    params: {
      id: sendReceiptModalRouteStore.patient.id,
    },
    body: {
      ...toJS(sendReceiptModalRouteStore.patient),
      email: sendReceiptModalRouteStore.shouldSaveEmail && sendReceiptModalRouteStore.email ? sendReceiptModalRouteStore.email : sendReceiptModalRouteStore.patient.email,
      phoneNumber: sendReceiptModalRouteStore.shouldSavePhoneNumber && sendReceiptModalRouteStore.phoneNumber ? sendReceiptModalRouteStore.phoneNumber : sendReceiptModalRouteStore.patient.phoneNumber,
    },
  })
  .finally(() => sendReceiptModalRouteStore.decrementHttp('updatingPatientById'));
};

export default updatePatientById;
