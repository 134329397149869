import React from 'react';
import { observer } from 'mobx-react';

// Helpers
import { getProps } from './helpers';

// Styles
import './status-display-style.scss';


const DispenseAttemptStatusDisplay = ({
  dispenseAttemptsAvailable,
  activeDispenseAttempt,
  source,
}) => {
  if (!dispenseAttemptsAvailable) return null;

  const {
    title,
    className,
    icon,
  } = getProps(activeDispenseAttempt);

  return <div className='c-StatusDisplay'>
    <div className='row'>
      <div className='status-container'>
        <div className={className}>
          {
            title
          }
        </div>
      </div>
      {
        !!icon &&
        <div className='icon-container'>
          <img
            alt='Status'
            className='icon'
            src={icon}
          />
        </div>
      }
    </div>
    {
      source &&
      <div className='row'>
        <div className='source'>
          {
            `${source.role}: ${source.name}`
          }
        </div>
      </div>
    }
  </div>;
};


export default observer(DispenseAttemptStatusDisplay);
