import stores from 'bootstrap/store';
import { keycardUpsertModalRouteStore } from '../components/keycard-upsert-modal/domain/store';
const keycardsStore = stores.global.keycards;

export default () => {
  return keycardsStore.getUniqueValue()
  .then(({ value } = {}) => {
    if (!value) return;
    keycardUpsertModalRouteStore.setValue('activelyModifiedKeycard.value', value);
  });
};
