import { get as _get } from 'lodash';

// Constants
import { PURPOSE } from 'models/video-conferences/constants';

// Utils
import getCallerName from 'utils/interpolateTemplateString';


export default (videoConference) => {
  const purpose = _get(videoConference, 'purpose.name');
  const caller = _get(videoConference, 'payload.caller', {});
  const callerType = _get(videoConference, 'payload.callerType');

  switch (purpose) {
    case PURPOSE.PRESCRIPTION_CONSULTATION: return getCallerName({
      template: '{0} {1}',
      items: [caller.firstName, caller.lastName],
      defaultValue: undefined,
    });

    case PURPOSE.GENERAL_PURPOSE: return getCallerName({
      template: '{0} {1}, {2}',
      items: [caller.firstName, caller.lastName, callerType || 'KIOSK'],
      defaultValue: undefined,
    });
  }
};
