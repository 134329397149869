import React from 'react';
import { observer } from 'mobx-react';

import formatPhoneNumber from 'utils/formatPhoneNumber';

import './call-request-style.scss';


const CallRequest = ({ callRequest }) => {
  return <div className='c-CallRequestListItemDetails'>
    <div className='key-value'>
      <div className='label'>
        Phone Number:
      </div>
      <div className='value'>
        {
          formatPhoneNumber(callRequest.phoneNumber)
        }
      </div>
    </div>
  </div>;
};

export default observer(CallRequest);
