import { types } from 'mobx-state-tree';
import { get as _get } from 'lodash';

import { NDC_REG_EXP, NDC_11_DIGIT_BILLING } from 'models/drugs/constants';

import doesValueMatchRegex from 'utils/doesValueMatchRegex';

export const DrugUpsertModalRouteStore = types.model({
})
.props({
  activelyModifiedDrug: types.maybeNull(types.frozen()),
  httpError: types.maybeNull(types.string),
})
.actions(self => ({
  setHttpError: (value) => self.httpError = value,
}))
.actions(self => ({
  setActivelyModifiedDrug: (values = {}) => {
    self.activelyModifiedDrug = { ...self.activelyModifiedDrug, ...values };
    self.setHttpError(null);
  },
}))
.actions(self => ({
  setInitialState: () => {
    self.activelyModifiedDrug = null;
    self.httpError = null;
  },
}))
.views(self => ({
  get isNDCValid() {
    const NDC = _get(self, 'activelyModifiedDrug.NDC');
    if (!NDC) {
      return false;
    }

    return doesValueMatchRegex([NDC_REG_EXP, NDC_11_DIGIT_BILLING], NDC);
  },
}))
.views(self => ({
  canBeSubmitted(isUpdateMode) {
    if (isUpdateMode) {
      return self.activelyModifiedDrug && self.activelyModifiedDrug.name;  
    } else {
      return self.activelyModifiedDrug && self.activelyModifiedDrug.name && self.isNDCValid;
    }
  },
}));

export const drugUsertModalRouteStore = DrugUpsertModalRouteStore.create({});
