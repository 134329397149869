export const ORDER_OPTIONS = {
  name: order => [['firstName', order], ['lastName', order ]],
};

export const TECHNICIAN_MANIPULATION_OPTIONS = {
  CREATE: 'create',
  UPDATE: 'update'
};

export const MAX_TABLE_ENTRIES_PER_PAGE = 10;
