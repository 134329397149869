import { debounce as _debounce } from 'lodash';

import { listPatients } from './index';

import stores from 'bootstrap/store';
const patientsStore = stores.global.patients;

export default _debounce(() => {
  patientsStore.setValue('dataForPharmacist', {});
  return listPatients();
}, 350);
