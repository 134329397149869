import stores from 'bootstrap/store';
import { keycardsRouteStore } from '../domain/store';
const keycardsStore = stores.global.keycards;

export default () => {
  return keycardsStore.resetKeycardPin({
    params: {
      keycardId: keycardsRouteStore.activeKeycardId,
    },
  })
  .then(() => {
    keycardsRouteStore.setValue('emailSent', true);

    setTimeout(() => {
      keycardsRouteStore.setValue('emailSent', false);
    }, 3000);
  });
};
