import React from 'react';
import { observer } from 'mobx-react';

import './kiosk-styles.scss';

const KioskNotification = ({ name, errors }) => (
  <div className='c-ErroredKioskNotification'>
    <div className='title'>
      {
        name
      }
    </div>
    <ul>
      {
        errors.map((error) => (
          <li key={error}>
            {
              error
            }
          </li>
        ))
      }
    </ul>
  </div>
);

export default observer(KioskNotification);
