import { get as _get } from 'lodash';

// Constants
import { HEALTHCARE_SITES as HEALTHCARE_SITES_ROUTE } from 'constants/routes';

// Stores
import stores from 'bootstrap/store';
import { healthcareSitesRouteStore } from '../domain/store';
const healthcareSitesStore = stores.global.healthcareSites;

export default (healthcareSiteId, navigate) => {
  const isRequestedHealthcareSiteAvailable = !!healthcareSitesStore.dataForPharmacist.get(healthcareSiteId);
  if (isRequestedHealthcareSiteAvailable) {
    healthcareSitesRouteStore.setValue('activeHealthcareSiteId', healthcareSiteId);
  } else {
    const firstId = _get(healthcareSitesStore, 'forPharmacistByCity[0].data[0].id');
    if (firstId) {
      navigate(`${HEALTHCARE_SITES_ROUTE.VALUE}/${firstId}`);
    } else {
      healthcareSitesRouteStore.setValue('activeHealthcareSiteId', null);
    }
  }
};
