import { types } from 'mobx-state-tree';
import { set as _set } from 'lodash';

import { RESULT_MESSAGES } from '../constants';

export const DEFAULT_VALUES = {
  prescriptionFill: {
    copay: '',
    adjudicated: '',
    fillNumber: '',
  },
  prescription: {
    rxId: '',
    directions: '',
    receivedTime: undefined,
    writtenTime: undefined,
    daysSupply: '',
    potencyDescription: '',
    nextRefill: undefined,
    physicianNotes: '',
  },
  drug: {
    NDC: '',
    UPC: '',
    name: '',
    genericName: '',
    quantity: '',
    strength: '',
    manufacturer: '',
    administrationType: '',
    form: '',
    imprint: '',
    color: '',
    flavor: '',
    shape: '',
    description: '',
  },
  patient: {
    firstName: '',
    lastName: '',
    DOB: undefined,
    gender: {value: '', label: ''},
    allergies: [],
  },
  physician: {
    firstName: '',
    lastName: '',
    NPI: '',
    SPI: '',
    stateLicenseNumber: '',
  },
};

export const PrescriptionFillsRouteStore = types.model({
  http: types.optional(types.model({
    upsertPrescriptionFill: types.optional(types.number, 0),
  }), {}),
  resultStatus: types.optional(types.model({
    message: types.optional(types.string, ''),
    type: types.optional(types.string, ''),
  }), {}),
  prescriptionFill: types.optional(
    types.model({
      copay: types.optional(types.string, ''),
      adjudicated: types.optional(types.string, ''),
      fillNumber: types.optional(types.string, ''),
    }),
    DEFAULT_VALUES.prescriptionFill
  ),
  prescription: types.optional(
    types.model({
      rxId: types.optional(types.string, ''),
      directions: types.optional(types.string, ''),
      receivedTime: types.maybeNull(types.Date),
      writtenTime: types.maybeNull(types.Date),
      daysSupply: types.optional(types.string, ''),
      potencyDescription: types.optional(types.string, ''),
      nextRefill: types.maybeNull(types.Date),
      physicianNotes: types.optional(types.string, ''),
    }),
    DEFAULT_VALUES.prescription
  ),
  drug: types.optional(
    types.model({
      NDC: types.optional(types.string, ''),
      UPC: types.optional(types.string, ''),
      name: types.optional(types.string, ''),
      genericName: types.optional(types.string, ''),
      quantity: types.optional(types.string, ''),
      strength: types.optional(types.string, ''),
      manufacturer: types.optional(types.string, ''),
      administrationType: types.optional(types.string, ''),
      form: types.optional(types.string, ''),
      imprint: types.optional(types.string, ''),
      color: types.optional(types.string, ''),
      flavor: types.optional(types.string, ''),
      shape: types.optional(types.string, ''),
      description: types.optional(types.string, ''),
    }),
    DEFAULT_VALUES.drug
  ),
  patient: types.optional(
    types.model({
      firstName: types.optional(types.string, ''),
      lastName: types.optional(types.string, ''),
      DOB: types.maybeNull(types.Date),
      gender: types.optional(types.model({
        value: types.string,
        label: types.string
      }), {value: '', label: ''}),
      allergies: types.optional(types.array(types.string), []),
    }),
    DEFAULT_VALUES.patient
  ),
  physician: types.optional(
    types.model({
      firstName: types.optional(types.string, ''),
      lastName: types.optional(types.string, ''),
      NPI: types.optional(types.string, ''),
      SPI: types.optional(types.string, ''),
      stateLicenseNumber: types.optional(types.string, ''),
    }),
    DEFAULT_VALUES.physician
  ),
})
.actions(self => ({
  setValue: (path, value) => _set(self, path, value),
  setInitialState: () => {
    self.http = {
      upsertPrescriptionFill: 0
    };

    self.prescriptionFill = DEFAULT_VALUES.prescriptionFill;
    self.prescription = DEFAULT_VALUES.prescription;
    self.drug = DEFAULT_VALUES.drug;
    self.patient = DEFAULT_VALUES.patient;
    self.physician = DEFAULT_VALUES.physician;
  },
  incrementHttp: (http) => ++self.http[http],
  decrementHttp: (http) => --self.http[http],
  setResultStatus: (type) => {
    self.resultStatus = {
      message: RESULT_MESSAGES[type],
      type,
    };

    setTimeout(() => {
      prescriptionFillsListRouteStore.setValue('resultStatus', {});
    }, 5000);
  },
}))
.views(() => ({
}));

export const prescriptionFillsListRouteStore = PrescriptionFillsRouteStore.create({});
