import deepFreeze from 'deep-freeze';

export const MODEL_NAME = Object.freeze({
  SINGULAR: 'prescriptionFill',
  PLURAL: 'prescription-fills',
});

export const FIELD_NAME = Object.freeze({
  COPAY: 'copay',
  ADJUDICATED: 'adjudicated',
  COMPUTED_FILLED: 'computed_filled',
  PRESCRIPTION_ID: 'prescriptionId',
  CREATED_AT: 'createdAt',
  UPDATED_AT: 'updatedAt',

  // Prescription
  CITY: 'city',
  HEALTHCARE_SITES: 'healthcareSites',
  CONTAINER: 'container',
  DIRECTIONS: 'directions',
  DOB: 'DOB',
  DRUG: 'drug',
  DRUG_NAME: 'drugName',
  EMAIL: 'email',
  EXPIRATION_DATE: 'expirationDate',
  EXTERNAL_PHARMACY_ID: 'externalPharmacyId',
  FIRST_NAME: 'firstName',
  ID: 'id',
  LAST_NAME: 'lastName',
  LOCATION: 'location',
  NAME: 'name',
  NDC: 'NDC',
  NPI: 'NPI',
  PATIENT: 'patient',
  PATIENT_USER: 'patientUser',
  PHYSICIAN: 'physician',
  PHYSICIAN_USER: 'physicianUser',
  PRESCRIPTION: 'prescription',
  PHONE_NUMBER: 'phoneNumber',
  KIOSK: 'kiosk',
  KIOSK_POSITION: 'kioskPosition',
  REFILLS: 'refills',
  RX_ID: 'rxId',
  SPI: 'SPI',
  STATE_LICENSE_NUMBER: 'stateLicenseNumber',
  STATE: 'state',
  STATUS: 'status',
  STREET: 'street1',
  UPC: 'UPC',
  USER: 'user',
  VERSION: 'version',
  ZIP: 'zip',

  // Dispense Attempts
  DISPENSE_ATTEMPTS: 'dispenseAttempts',
  REVIEW: 'review',
  APPROVE_CONTAINER: 'approveContainer',
  APPROVE_PRESCRIPTION: 'approvePrescription',

  // Consultation Request
  CONSULTATION_REQUEST: 'consultReq',
  COMPUTED_ACTIVE: 'computed_active',
});

export const SCHEMA = deepFreeze({
  title: MODEL_NAME.SINGULAR,
  type: 'object',
  properties: {
    [FIELD_NAME.ID]: {
      type: 'string',
    },
    [FIELD_NAME.COPAY]: {
      type: 'string',
    },
    [FIELD_NAME.ADJUDICATED]: {
      type: 'string',
    },
    [FIELD_NAME.COMPUTED_FILLED]: {
      type: 'boolean',
    },
    [FIELD_NAME.CREATED_AT]: {
      type: 'string',
    },
    [FIELD_NAME.UPDATED_AT]: {
      type: 'string',
    },
    [FIELD_NAME.PRESCRIPTION_ID]: {
      type: 'string',
    },
    [FIELD_NAME.PRESCRIPTION]: {
      type: 'object',
      properties: {
        [FIELD_NAME.ID]: {
          type: 'string'
        },
        [FIELD_NAME.DIRECTIONS]: {
          type: 'string'
        },
        [FIELD_NAME.REFILLS]: {
          type: 'number'
        },
        [FIELD_NAME.RX_ID]: {
          type: 'string'
        },
        [FIELD_NAME.CREATED_AT]: {
          type: 'string'
        },
        [FIELD_NAME.UPDATED_AT]: {
          type: 'string'
        },
        [FIELD_NAME.DRUG]: {
          type: 'object',
          properties: {
            [FIELD_NAME.ID]: {
              type: 'string',
            },
            [FIELD_NAME.NDC]: {
              type: 'string',
            },
            [FIELD_NAME.UPC]: {
              type: 'string',
            },
            [FIELD_NAME.NAME]: {
              type: 'string',
            },
          },
          required:[
            FIELD_NAME.ID,
            FIELD_NAME.NDC,
            FIELD_NAME.UPC,
            FIELD_NAME.NAME,
          ]
        },
        [FIELD_NAME.PATIENT]: {
          type: 'object',
          properties: {
            [FIELD_NAME.ID]: {
              type: 'string',
            },
            [FIELD_NAME.LAST_NAME]: {
              type: 'string',
            },
            [FIELD_NAME.FIRST_NAME]: {
              type: 'string',
            },
            [FIELD_NAME.PHONE_NUMBER]: {
              type: 'string',
            },
            [FIELD_NAME.PATIENT_USER]: {
              type: 'object',
              properties: {
                [FIELD_NAME.USER]: {
                  type: 'object',
                  properties: {
                    [FIELD_NAME.PHONE_NUMBER]: {
                      type: 'string',
                    },
                    [FIELD_NAME.EMAIL]: {
                      type: 'string',
                    }
                  }
                }
              }
            }
          },
          required:[
            FIELD_NAME.ID,
            FIELD_NAME.LAST_NAME,
            FIELD_NAME.FIRST_NAME,
          ]
        },
        [FIELD_NAME.PHYSICIAN]: {
          type: 'object',
          properties: {
            [FIELD_NAME.ID]: {
              type: 'string',
            },
            [FIELD_NAME.LAST_NAME]: {
              type: 'string',
            },
            [FIELD_NAME.FIRST_NAME]: {
              type: 'string',
            },
            [FIELD_NAME.PHYSICIAN_USER]: {
              type: 'object',
              properties: {
                [FIELD_NAME.USER]: {
                  type: 'object',
                  properties: {
                    [FIELD_NAME.PHONE_NUMBER]: {
                      type: 'string',
                    },
                    [FIELD_NAME.EMAIL]: {
                      type: 'string',
                    }
                  }
                }
              }
            },
            [FIELD_NAME.HEALTHCARE_SITES]: {
              type: 'array',
              items: {
                type: 'object',
                properties: {
                  [FIELD_NAME.NAME]: {
                    type: 'string',
                  },
                  [FIELD_NAME.PHONE_NUMBER]: {
                    type: 'string',
                  },
                  [FIELD_NAME.LOCATION]: {
                    type: 'object',
                    properties: {
                      [FIELD_NAME.STATE]: {
                        type: 'string',
                      },
                      [FIELD_NAME.STREET]: {
                        type: 'string',
                      },
                      [FIELD_NAME.CITY]: {
                        type: 'string',
                      },
                      [FIELD_NAME.ZIP]: {
                        type: 'string',
                      }
                    }
                  }
                }
              }
            }
          },
          required:[
            FIELD_NAME.ID,
            // FIELD_NAME.NPI,
            // FIELD_NAME.SPI,
            FIELD_NAME.LAST_NAME,
            FIELD_NAME.FIRST_NAME,
            // FIELD_NAME.STATE_LICENSE_NUMBER,
            // FIELD_NAME.PHYSICIAN_USER,
            // FIELD_NAME.HEALTHCARE_SITES,
          ]
        },
      }
    },
    [FIELD_NAME.DISPENSE_ATTEMPTS]: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          [FIELD_NAME.ID]: {
            type: 'string',
          },
          [FIELD_NAME.REVIEW]: {
            type: 'object',
            properties: {
              [FIELD_NAME.APPROVE_CONTAINER]: {
                type: 'boolean',
              },
              [FIELD_NAME.APPROVE_PRESCRIPTION]: {
                type: 'boolean',
              },
            },
          },
        },
      },
    },
    [FIELD_NAME.CONSULTATION_REQUEST]: {
      type: 'object',
      properties: {
        [FIELD_NAME.ID]: {
          type: 'string',
        },
        [FIELD_NAME.COMPUTED_ACTIVE]: {
          type: 'boolean',
        },
      },
    },
  },
  required: [
    FIELD_NAME.ID,
  ]
});
