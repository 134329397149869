import React from 'react';
import { observer } from 'mobx-react';

import Header from './components/header';
import InfoRoute from '../../routes/info';
import Content from 'components/drill-down-layout/components/content';
import ContentTab from 'components/drill-down-layout/components/content/components/content-tab';

import { PATIENTS } from 'constants/routes';

const PatientsContent = ({
  patientsListRouteStore,
  listPatients,
}) => (
  <Content
    header={<Header
      patientsListRouteStore={patientsListRouteStore}
      isReady={!!patientsListRouteStore.activePatient}
    />}
    isReady={!!patientsListRouteStore.activePatient}
  >
    <ContentTab title={PATIENTS.SUB_ROUTE.LIST_OVERVIEW.CONTENT_TAB.INFO.TITLE} route={PATIENTS.SUB_ROUTE.LIST_OVERVIEW.CONTENT_TAB.INFO.VALUE}>
      <InfoRoute
        activePatient={patientsListRouteStore.activePatient}
        patientAddress={patientsListRouteStore.patientPrimaryAddress}
        patientImages={patientsListRouteStore.patientImages}
        isEditMode={patientsListRouteStore.isEditMode}
        exitEditMode={async () => {
          await listPatients();
          patientsListRouteStore.setValue('isEditMode', false);
        }}
      />
    </ContentTab>
  </Content>
);

export default observer(PatientsContent);
