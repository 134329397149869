import { flow, types } from 'mobx-state-tree';
import {
  get as _get,
  isEmpty as _isEmpty,
  keyBy as _keyBy,
  set as _set,
  orderBy as _orderBy,
  transform as _transform,
  xor as _xor,
} from 'lodash';

import base from 'models/base';
import { REJECTION_REASONS } from './constants';
import { MODEL_NAME, SCHEMA } from './constants/schema';
import { CONTAINER_DISPENSING_STAGE } from './constants';


const DispenseAttemptModel = base({
  names: {
    singular: MODEL_NAME.SINGULAR,
    plural: MODEL_NAME.PLURAL
  },
  JSONSchema: SCHEMA,
  httpConfig: {
    root: MODEL_NAME.plural,
    methods: {}
  }
});

DispenseAttemptModel.configureStore((store) => {
  return store
  .props({
    dataForPharmacist: types.optional(types.map(types.frozen()), {}),
    pending: types.optional(types.map(types.frozen()), {}),

    // Rejection reason modal
    rejectionModal: types.optional(types.model({
      showing: types.optional(types.boolean, false),
      dispenseAttemptId: types.maybeNull(types.string),
      rejectContainer: types.optional(types.boolean, false),
      rejectPrescription: types.optional(types.boolean, false),
      reason: types.optional(types.model({
        label: types.optional(types.string, ''),
        value: types.optional(types.string, ''),
      }), REJECTION_REASONS[0]),
    }), {}),

    reviewConflictMessage: types.maybeNull(types.string),
  })
  .actions(self => ({
    setValue: (path, value) => _set(self, path, value),
    deleteDispenseAttempt: (id) => self.dataForPharmacist.delete(id),
    resetRejectionModal: () => self.rejectionModal = {},
    revertRejectionModalField: (prop) => self.setValue(`rejectionModal.${prop}`, !self.rejectionModal[prop]),
    fetchById: (config = {}) => {
      return self.get(config);
    },
    deleteOneForPharmacist: (dispenseAttemptId) => self.dataForPharmacist.delete(dispenseAttemptId),
    listForPharmacist: flow(function* (config = {}) {
      const offset = _get(config, 'query.offset', 0);
      const limit = _get(config, 'query.limit', 0);
      if (!offset && self.dataForPharmacist.size > limit) self.dataForPharmacist = {};

      config.urlFragment = () => '/for-pharmacist';
      const response = yield self.list(config);
      const data = response.data || response || [];
      self.dataForPharmacist.merge(_keyBy(data, 'id'));

      return response;
    }),
    listPendingReviewForPharmacist: flow(function* (config = {}) {
      const offset = _get(config, 'query.offset', 0);
      const limit = _get(config, 'query.limit', 0);
      if (!offset && self.dataForPharmacist.size > limit) self.dataForPharmacist = {};

      config.urlFragment = () => '/pending-review/for-pharmacist';
      const response = yield self.list(config);
      const data = response.data || response || [];
      self.dataForPharmacist.merge(_keyBy(data, 'id'));

      return response;
    }),
    listReviewedForPharmacist: flow(function* (config = {}) {
      const offset = _get(config, 'query.offset', 0);
      const limit = _get(config, 'query.limit', 0);
      if (!offset && self.dataForPharmacist.size > limit) self.dataForPharmacist = {};

      config.urlFragment = () => '/reviewed/for-pharmacist';
      const response = yield self.list(config);
      const data = response.data || response || [];
      self.dataForPharmacist.merge(_keyBy(data, 'id'));

      return response;
    }),
    // For notifications pane
    listPendingForNotificationsPane: flow(function* (config = {}) {
      config.urlFragment = () => '/pending-review/for-pharmacist';
      const response = yield self.list(config);
      const data = response.data || response || [];

      self.pending = _keyBy(data, 'id');

      return response;
    }),
    approve: flow(function* (config = {}) {
      config.urlFragment = () => `/${config.params.dispenseAttemptId}/approve`;
      return yield self.put(config)
      .then(() => self.deleteDispenseAttempt(config.params.dispenseAttemptId))
      .catch((err) => {
        if (err.status === 409) {
          self.setValue('reviewConflictMessage', err.res.text);
        }
      });
    }),
    reject: flow(function* (config = {}) {
      config.urlFragment = () => `/${config.params.dispenseAttemptId}/reject`;
      return yield self.put(config)
      .then(() => self.deleteDispenseAttempt(config.params.dispenseAttemptId))
      .catch((err) => {
        if (err.status === 409) {
          self.setValue('reviewConflictMessage', err.res.text);
        }
      });
    }),
    dispenseRemote: flow(function* (config = {}) {
      config.urlFragment = () => `/${config.params.dispenseAttemptId}/dispense-remote`;
      return yield self.put(config);
    }),
  }))
  .views(self => ({
    get readyToReject() {
      return self.rejectionModal.rejectContainer || self.rejectionModal.rejectPrescription;
    },
    groupedByDate(asc) {
      const forPharmacist = _orderBy(Array.from(self.dataForPharmacist.values()), ['updatedAt'], [asc ? 'asc' : 'desc']);
      const dateFormat = { year: 'numeric', month: 'long', day: 'numeric'};

      const groupedByDate = _transform(forPharmacist, (groupedByDate, dispenseAttempt) => {
        const date = new Date(dispenseAttempt.updatedAt);
        const dateKey = date.toISOString().match(/^[0-9]{4}-[0-9]{2}-[0-9]{2}/)[0];
        const dateName = (date).toLocaleDateString('en-EN', dateFormat);
        if (!groupedByDate[dateKey]) {
          // use this weird structure due to previous implementation
          groupedByDate[dateKey] = {
            id: dispenseAttempt.id,
            title: dateName,
            dispenseAttempts: [],
          };
        }
        groupedByDate[dateKey].dispenseAttempts.push(dispenseAttempt);
      }, {});

      return _orderBy(Object.keys(groupedByDate), [], [asc ? 'asc' : 'desc']).map(key => groupedByDate[key]);
    },
    get forPharmacistByDate() {
      const list = Array.from(self.dataForPharmacist.values());

      const byDate = [];
      const grouped = {};
      list.forEach(item => {
        const dateFormat = { year: 'numeric', month: 'long', day: 'numeric'};
        const date = (new Date(item.updatedAt)).toLocaleDateString('en-EN', dateFormat);
        if (!grouped[date]) {
          grouped[date] = {date, data: []};
          byDate.push(grouped[date]);
        }
        grouped[date].data.push(item);
      });

      return _orderBy(byDate, ['date'], ['desc']);
    },
    get forPharmacistToArray() {
      return Array.from(self.dataForPharmacist.values());
    },
    get pendingArray() {
      return Array.from(self.pending.values());
    },
    getPendingByPrescriptionFillId: (prescriptionFillId) => {
      if (!prescriptionFillId) return;
      return self.pendingArray.find((dispenseAttempt) => dispenseAttempt.prescriptionFill.id === prescriptionFillId);
    },
    get allPharmacistDataIsPending() {
      if (self.dataForPharmacist.size !== self.pending.size) return false;
      const forPharmacistIds = self.forPharmacistToArray.map((dispenseAttempt) => dispenseAttempt.id);
      const pendingIds = self.pendingArray.map((dispenseAttempt) => dispenseAttempt.id);
      return _isEmpty(_xor(forPharmacistIds, pendingIds));
    },
    isReviewed: (dispenseAttempt) => {
      if (!dispenseAttempt || !dispenseAttempt.review) return false;
      return dispenseAttempt.review.approveContainer && dispenseAttempt.review.approvePrescription;
    },
  }));
});

// Can't use module.exports because there is a generator function in this file,
// and that causes module.exports to fail for some reason.
export default DispenseAttemptModel;
