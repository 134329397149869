import React from 'react';
import { observer } from 'mobx-react';

import chevronLeftIcon from './icons/chevron-left.svg';
import chevronLeftDisabledIcon from './icons/chevron-left-disabled.svg';

import './index.scss';

const ArrowBack = ({ currentPage, changePage }) => {
  const enabled = currentPage !== 1;

  return (
    <img
      className={`${enabled ? 'c-ArrowBack' : 'c-ArrowBackDisabled'}`}
      src={enabled ? chevronLeftIcon : chevronLeftDisabledIcon}
      onClick={() => changePage(currentPage - 1)}
    />
  );
};

export default observer(ArrowBack);