import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import PatientsView from './patients-view';

import * as helpers from './helpers';

import stores from 'bootstrap/store';
import { patientsRouteStore } from './domain/store';
import { patientUpsertModalRouteStore } from './domain/upsert-modal-store';
const patientsStore = stores.global.patients;


const PatientsContainer = () => {
  useEffect(() => {
    helpers.listPatients();
  }, []);

  return (
    <PatientsView
      patientsStore={patientsStore}
      patientsRouteStore={patientsRouteStore}
      patientUpsertModalRouteStore={patientUpsertModalRouteStore}
      sortPatients={helpers.sortPatients}
      changePaginationPage={helpers.changePaginationPage}
      onPatientsSearch={helpers.onPatientsSearch}
      showPatientUpsertModal={helpers.showPatientUpsertModal}
      closePatientUpsertModal={helpers.closePatientUpsertModal}
      onHealthcareSitesSearch={helpers.onHealthcareSitesSearch}
      addHealthcareSiteLocally={helpers.addHealthcareSiteLocally}
      removeHealthcareSiteLocally={helpers.removeHealthcareSiteLocally}
      showPatientDeleteConfirmationModal={helpers.showPatientDeleteConfirmationModal}
      createPatient={helpers.createPatient}
      updatePatient={helpers.updatePatient}
      deletePatient={helpers.deletePatient}
    />
  );
};

export default observer(PatientsContainer);
