import React from 'react';
import { observer } from 'mobx-react';

import ContentScreen from 'components/content-screen';
import ListSearch from 'components/drill-down-layout/components/list/components/list-header/components/list-search';
import SmartTable from 'components/smart-table';

import { COLUMNS } from './constants';

import './prescription-fills-styles.scss';

const PrescriptionFillsView = ({
  lotPrescriptionFillsRouteStore,
  activeLotId,
  onPrescriptionFillSearch,
}) => (
  <div className='c-LotPrescrptionFills'>
    <ContentScreen active={lotPrescriptionFillsRouteStore.isBusy} />
    <div className='search'>
      <ListSearch
        searchValue={lotPrescriptionFillsRouteStore.filterValue}
        onSearch={(e) => onPrescriptionFillSearch(e, activeLotId)}
      />
    </div>
    {
      lotPrescriptionFillsRouteStore.forTable.length
        ? <SmartTable
          loading={lotPrescriptionFillsRouteStore.isBusy}
          rows={lotPrescriptionFillsRouteStore.forTable}
          columns={COLUMNS}
        />
        : <div className='no-prescription-fills-text'>
          No prescriptions found.
        </div>
    }
  </div>
);

export default observer(PrescriptionFillsView);
