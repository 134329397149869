import React from 'react';
import { observer } from 'mobx-react';

// Components
import DrillDownLayout from 'components/drill-down-layout';
import List from './components/list';
import Content from './components/content';

//Styles
import './patients-list-view.scss';


const PatientsListView = ({
  patientsStore,
  patientsListRouteStore,
  setFilterValue,
  setActivePatientListTab,
  listPatients,
  rootRoute,
}) => (
  <div className='r-PatientInsurancesView'>
    <DrillDownLayout>
      <List
        patientsStore={patientsStore}
        patientsListRouteStore={patientsListRouteStore}
        setFilterValue={setFilterValue}
        setActivePatientListTab={setActivePatientListTab}
        listPatients={listPatients}
        rootRoute={rootRoute}
      />
      <Content
        patientsListRouteStore={patientsListRouteStore}
        listPatients={listPatients}
      />
    </DrillDownLayout>
  </div>
);


export default observer(PatientsListView);
