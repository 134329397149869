import React from 'react';
import { observer } from 'mobx-react';
import { get as _get } from 'lodash';

import CaptionValueInput from 'components/caption-value-input';
import ModalRow from 'components/modal/layout/row';

const Data = ({
  upsertModalRouteStore,
  activelyModifiedTechnician,
}) => (
  <React.Fragment>
    <ModalRow>
      <CaptionValueInput
        caption='First Name'
        value={activelyModifiedTechnician.firstName}
        onChange={(value) => upsertModalRouteStore.setValue('activelyModifiedTechnician.firstName', value)}
      />
      <CaptionValueInput
        caption='Last Name'
        value={activelyModifiedTechnician.lastName}
        onChange={(value) => upsertModalRouteStore.setValue('activelyModifiedTechnician.lastName', value)}
      />
    </ModalRow>
    {
      _get(upsertModalRouteStore, 'activelyModifiedTechnicianUser.email') !== undefined &&
      <ModalRow>
        <CaptionValueInput
          inputFieldClassNames={['max-width']}
          caption='Email'
          value={upsertModalRouteStore.activelyModifiedTechnicianUser.email}
          onChange={(value) => upsertModalRouteStore.setActivelyModifiedTechnicianUser('email', value)}
        />
      </ModalRow>
    }
    {
      _get(upsertModalRouteStore, 'activelyModifiedTechnicianUser.phoneNumber') !== undefined &&
      <ModalRow>
        <CaptionValueInput
          inputFieldClassNames={['max-width']}
          caption='Phone Number'
          value={upsertModalRouteStore.activelyModifiedTechnicianUser.phoneNumber}
          onChange={(value) => upsertModalRouteStore.setActivelyModifiedTechnicianUser('phoneNumber', value)}
        />
      </ModalRow>
    }
  </React.Fragment>
);

export default observer(Data);
