import { get as _get } from 'lodash';

import * as ROUTES from 'constants/routes';
import { MAX_ENTRIES_PER_PAGE } from '../constants';

import stores from 'bootstrap/store';
import { healthcareSitesRouteStore } from '../domain/store';
const healthcareSitesStore = stores.global.healthcareSites;
const history = stores.router;


export default () => {
  const query = {};

  const filter = healthcareSitesRouteStore.filterValue;
  if (filter) {
    const wildcard = `${filter}%`;
    query.name = {'$iLike': wildcard};
  }
  const config = {
    query: {
      offset: MAX_ENTRIES_PER_PAGE * (healthcareSitesRouteStore.currentPage - 1),
      limit: MAX_ENTRIES_PER_PAGE,
      query,
    },
  };

  healthcareSitesRouteStore.incrementHttp('listingHealthcareSites');
  return healthcareSitesStore.listForPharmacist(config)
  .then((response) => {
    const responseLength = _get(response, 'data.length', 0);
    if (responseLength > MAX_ENTRIES_PER_PAGE - 1) {
      healthcareSitesRouteStore.setValue('currentPage', healthcareSitesRouteStore.currentPage + 1);
    }
  })
  .catch(() => history.push(ROUTES.HEALTHCARE_SITES.VALUE))
  .finally(() => healthcareSitesRouteStore.decrementHttp('listingHealthcareSites'));
};
