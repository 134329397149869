import stores from 'bootstrap/store';
const kiosksStore = stores.global.kiosks;
const kiosksRouteStore = stores.route.kiosks.domain;

const fetchComputedInventory = async () => {
  const { activeKioskId } = kiosksRouteStore;
  if (!activeKioskId) return;

  return kiosksStore.getComputedInventory({
    query: { kioskId: activeKioskId },
  });
};

export default fetchComputedInventory;
