import store from 'bootstrap/store';
import { prescriptionFillsStatsRouteStore } from '../domain/store';
const prescriptionFillsStore = store.global.prescriptionFills;


export default ({ startDate, endDate, invert = false } = {}) => {
  const query = {
    startDate,
    endDate,
    invert,
  };

  prescriptionFillsStatsRouteStore.incrementHttp('count');
  return prescriptionFillsStore.count({ query })
  .then((response) => {
    prescriptionFillsStatsRouteStore.setValue('reference', response.reference);
    prescriptionFillsStatsRouteStore.setValue('count', response.count);
    // const prescriptionFillsById = _keyBy(prescriptionFills, 'id');
    // if (merge) {
    //   prescriptionFillsStatsRouteStore.mergePrescriptionFillsById(prescriptionFillsById);
    // } else {
    //   prescriptionFillsStatsRouteStore.setPrescriptionFillsById(prescriptionFillsById);
    // }

    prescriptionFillsStatsRouteStore.decrementHttp('count');
  });
};
