import React from 'react';
import { observer } from 'mobx-react';

import HealthcareSite from './healthcare-site';

import './healthcare-sites-style.scss';

const EntityHealthcareSites = ({ healthcareSites = [] }) => (
  <div className='c-EntityHealthcareSites'>
    {
      healthcareSites.map((healthcareSite) => (
        <HealthcareSite
          key={healthcareSite.id}
          healthcareSite={healthcareSite}
        />
      ))
    }
  </div>
);

export default observer(EntityHealthcareSites);
