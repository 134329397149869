import React from 'react';
import { observer } from 'mobx-react';

import Header from './components/header';
import InfoRoute from '../../routes/info';
// import MergeRoute from '../../routes/merge';
import Content from 'components/drill-down-layout/components/content';
import ContentTab from 'components/drill-down-layout/components/content/components/content-tab';

import { PATIENTS } from 'constants/routes';

const PatientsContent = ({
  patientsIncompleteInfoRouteStore,
  markActivePatientAsCaptured,
  requestActivePatientDataCapture,
  // listPatients,
}) => (
  <Content
    header={<Header
      patientsIncompleteInfoRouteStore={patientsIncompleteInfoRouteStore}
      isReady={!!patientsIncompleteInfoRouteStore.activePatient}
      markActivePatientAsCaptured={markActivePatientAsCaptured}
      requestActivePatientDataCapture={requestActivePatientDataCapture}
    />}
    isReady={!!patientsIncompleteInfoRouteStore.activePatient}
  >
    <ContentTab title={PATIENTS.SUB_ROUTE.INCOMPLETE.CONTENT_TAB.INFO.TITLE} route={PATIENTS.SUB_ROUTE.INCOMPLETE.CONTENT_TAB.INFO.VALUE}>
      <InfoRoute
        activePatient={patientsIncompleteInfoRouteStore.activePatient}
        activePatientAddress={patientsIncompleteInfoRouteStore.patientPrimaryAddress}
        activePatientImageGallery={patientsIncompleteInfoRouteStore.activePatientImageGallery}
      />
    </ContentTab>
    {/* <ContentTab title={PATIENTS.SUB_ROUTE.INCOMPLETE.CONTENT_TAB.MERGE.TITLE} route={PATIENTS.SUB_ROUTE.INCOMPLETE.CONTENT_TAB.MERGE.VALUE}>
      <MergeRoute
        activePatient={patientsIncompleteInfoRouteStore.activePatient}
        listPatients={listPatients}
      />
    </ContentTab> */}
  </Content>
);

export default observer(PatientsContent);
