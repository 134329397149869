import stores from 'bootstrap/store';
import { sendReceiptModalRouteStore } from '../domain/store';

import updatePatientById from './updatePatientById';

const sendReceipt = async (prescriptionFillsFulfillmentId) => {
  await updatePatientById();

  return stores.global.userContactEvents.sendReceipt({
    params: { prescriptionFillsFulfillmentId },
    body: {
      email: sendReceiptModalRouteStore.email || null,
      shouldEmail: sendReceiptModalRouteStore.shouldEmail,
      phoneNumber: sendReceiptModalRouteStore.phoneNumber || null,
      shouldText: sendReceiptModalRouteStore.shouldText,
    },
  });
};

export default sendReceipt;
