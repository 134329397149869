import stores from 'bootstrap/store';
import { lotPrescriptionFillsRouteStore } from '../domain/store';
const prescriptionFillsStore = stores.global.prescriptionFills;

const listPrescriptionFills = (lotId) => {
  const params = { lotId };
  const query = {};

  if (lotPrescriptionFillsRouteStore.filterValue) {
    query.filter = lotPrescriptionFillsRouteStore.filterValue;
  }

  lotPrescriptionFillsRouteStore.incrementHttp('listingPrescriptionFills');
  return prescriptionFillsStore.listByLotForPharmacist({ params, query })
  .finally(() => lotPrescriptionFillsRouteStore.decrementHttp('listingPrescriptionFills'));
};

export default listPrescriptionFills;
