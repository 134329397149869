import { NEW_NOTIFICATION_ANIMATION } from '../components/notification-previews/constants';

export default (notificationType) => {
  const previewContainer = document.getElementById(notificationType);
  if (!previewContainer || !previewContainer.childNodes.length) return;

  const previewImages = previewContainer.getElementsByTagName('img');
  if (!previewImages.length) return;

  const preview = previewImages[0];
  preview.classList.add(NEW_NOTIFICATION_ANIMATION.CLASS);
  // Remove animated class after animation is complete.
  setTimeout(() => preview.classList.remove(NEW_NOTIFICATION_ANIMATION.CLASS), NEW_NOTIFICATION_ANIMATION.DURATION);
};
