import React from 'react';
import { observer } from 'mobx-react';

import Header from './components/header';
import ContactRoute from '../../routes/contact';
import Content from 'components/drill-down-layout/components/content';
import ContentTab from 'components/drill-down-layout/components/content/components/content-tab';

import { MEDICAL_ASSISTANTS } from 'constants/routes';

const MedicalAssistantsContent = ({
  medicalAssistantsRouteStore,
}) => (
  <Content
    header={<Header
      medicalAssistantsRouteStore={medicalAssistantsRouteStore}
      isReady={!!medicalAssistantsRouteStore.activeMedicalAssistant}
    />}
    isReady={!!medicalAssistantsRouteStore.activeMedicalAssistant}
  >
    <ContentTab title={MEDICAL_ASSISTANTS.CONTENT_TAB.CONTACT.TITLE} route={MEDICAL_ASSISTANTS.CONTENT_TAB.CONTACT.VALUE}>
      <ContactRoute activeMedicalAssistant={medicalAssistantsRouteStore.activeMedicalAssistant} />
    </ContentTab>
  </Content>
);

export default observer(MedicalAssistantsContent);
