import React from 'react';

import ListHeader from 'components/drill-down-layout/components/list/components/list-header';

const StockingBatchesListHeader = () => (
  <ListHeader
    title='Stocking Batches'
  />
);

export default StockingBatchesListHeader;
