import React from 'react';
import { observer } from 'mobx-react';

import parseDbDateOfBirth from 'utils/parseDbDateOfBirth';

import './patient-suggestion-styles.scss';

const PatientSuggestion = ({ suggestion }) => {
  const { firstName, lastName, externalPharmacyId, DOB } = suggestion;

  return <div className='c-PatientSuggestion'>
    <div className='name'>
      {
        `${firstName} ${lastName}`
      }
    </div>
    <div className='detail'>
      <span className='key'>
        Unique Identifier:
      </span>
      {
        externalPharmacyId
      }
    </div>
    <div className='detail'>
      <span className='key'>
        Date of Birth:
      </span>
      {
        DOB ? parseDbDateOfBirth(DOB) : 'N/A'
      }
    </div>
  </div>;
};

export default observer(PatientSuggestion);
