import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import PrescriptionFillsStatsView from './prescription-fills-stats-view';

import * as helpers from './helpers';

import { prescriptionFillsStatsRouteStore } from './domain/store';


const PrescriptionFillsStatsContainer = () => {


  useEffect(() => {
    prescriptionFillsStatsRouteStore.setInitialState();

    return () => {
      prescriptionFillsStatsRouteStore.setInitialState();
    };
  }, []);

  useEffect(() => {
    helpers.countPrescriptionFills({
      startDate: prescriptionFillsStatsRouteStore.dateRange.start.toISOString(),
      endDate: prescriptionFillsStatsRouteStore.dateRange.end.toISOString(),
      invert: prescriptionFillsStatsRouteStore.invert,
    });
  }, [
    prescriptionFillsStatsRouteStore.dateRange.start,
    prescriptionFillsStatsRouteStore.dateRange.end,
    prescriptionFillsStatsRouteStore.invert,
  ]);

  return (
    <PrescriptionFillsStatsView
      prescriptionFillsStatsRouteStore={prescriptionFillsStatsRouteStore}
    />
  );
};

export default observer(PrescriptionFillsStatsContainer);
