import stores from 'bootstrap/store';

import { KIOSKS as KIOSKS_ROUTE } from 'constants/routes';
import { EVENT } from '../../constants';

import { isActivePathname } from 'bootstrap/store/router';

const kioskStatusesCreateHandler = ({ apiWebSocket }) => {
  apiWebSocket.on(EVENT.RECEIVED.KIOSK_STATUSES_CREATE, () => {
    stores.global.kiosks.listErroredForPharmacist();

    const { activeKioskId } = stores.route.kiosks.domain;
    const isKioskStatusesViewActive = isActivePathname(`${KIOSKS_ROUTE.VALUE}/${activeKioskId}${KIOSKS_ROUTE.CONTENT_TAB.STATUSES.VALUE}`);
    if (isKioskStatusesViewActive) {
      return stores.global.kiosks.getStatuses({
        params: {
          kioskId: activeKioskId,
        },
      });
    }
  });
};

export default kioskStatusesCreateHandler;
