import { get as _get } from 'lodash';

export function getFormattedDataFromObject(object, keys, format) {
  let values = keys.map(key => _get(object, key));
  const length = values.length;

  values = values.filter(value => (value !== null && value !== undefined && value !== ''));

  if (length !== values.length) return 'N/A';

  return format? format(values): values.join(' ');
}
