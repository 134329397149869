import React from 'react';
import { observer } from 'mobx-react';

// Components
import ContentGate from 'components/content-gate';
import ExportInventoryModal from './components/export-inventory-modal';
import InventoryTable from './components/inventory-table';
import Search from 'components/drill-down-layout/components/list/components/list-header/components/list-search';

// Styles
import './kiosk-inventory-view.scss';

const KioskInventoryView = ({
  kioskInventoryRouteStore,
  inventoryLoaded,
  filterInventory,
  sortInventory,
  setInventoryThreshold,
  isExportInventoryModalOpen,
  closeExportInventoryModal,
  fetchDrugs,
  fetchInventory,
  activeKioskName,
}) => (
  <>
    <ExportInventoryModal
      isOpen={isExportInventoryModalOpen}
      closeModal={closeExportInventoryModal}
      fetchDrugs={fetchDrugs}
      fetchInventory={fetchInventory}
      kioskName={activeKioskName}
    />
    <ContentGate className='c-KioskInventory' active={!inventoryLoaded}>
      <Search
        placeholder='Drug or NDC'
        searchValue={kioskInventoryRouteStore.drugFilter}
        onSearch={filterInventory}
      />
      <InventoryTable
        kioskInventoryRouteStore={kioskInventoryRouteStore}
        rows={kioskInventoryRouteStore.forTable}
        sortInventory={sortInventory}
        setInventoryThreshold={setInventoryThreshold}
      />
    </ContentGate>
  </>
);

export default observer(KioskInventoryView);
