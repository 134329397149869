import { get as _get} from 'lodash';

// Constants
import { PATIENT_LIST_TAB, PATIENTS_LIST_OVERVIEW_ROUTE, MAX_ENTRIES_PER_PAGE } from '../constants';

// Stores
import stores from 'bootstrap/store';
import { patientsListRouteStore} from '../domain/store';
const patientsStore = stores.global.patients;
const { router } = stores;

// Null is replaced with an empty string in query strings.
// True stands for "not null", false stands for "null".
const EXTERNAL_PHARMACY_ID_BASE_QUERY = {
  [PATIENT_LIST_TAB.ACTIVE]: true,
  [PATIENT_LIST_TAB.NEW]: false,
};


export default () => {
  if (patientsListRouteStore.http.listingPatients) return;

  const { activePatientListTab, filterValue } = patientsListRouteStore;
  const query = {
    offset: MAX_ENTRIES_PER_PAGE * (patientsListRouteStore.currentPage - 1),
    limit: MAX_ENTRIES_PER_PAGE,
    externalPharmacyId: EXTERNAL_PHARMACY_ID_BASE_QUERY[activePatientListTab],
  };

  if (filterValue) {
    query.filter = {
      firstName: filterValue,
      lastName: filterValue,
      externalPharmacyId: filterValue,
    };
  }

  const config = { query };

  patientsListRouteStore.incrementHttp('listingPatients');
  return patientsStore.listForPharmacist(config)
  .catch(() => router.history.push(PATIENTS_LIST_OVERVIEW_ROUTE))
  .then((response) => {
    const responseLength = _get(response, 'data.length', 0);
    if (responseLength > MAX_ENTRIES_PER_PAGE - 1) {
      patientsListRouteStore.setValue('currentPage', patientsListRouteStore.currentPage + 1);
    }
  })
  .finally(() => patientsListRouteStore.decrementHttp('listingPatients'));
};
