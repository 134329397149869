import React from 'react';
import { observer } from 'mobx-react';

// Components
import Header from './components/header';
import List from 'components/drill-down-layout/components/list';
import ListItemGroup from 'components/drill-down-layout/components/list/components/list-item-group';
import ListItem from 'components/drill-down-layout/components/list/components/list-item';
import PrescriptionFill from './components/prescriptionFill';

// Icons
import ActivePatientIcon from 'assets/icons/patient-active.svg';
import PatientIcon from 'assets/icons/patient.svg';


const PrescriptionFillsList = ({
  isLoading,
  activePrescriptionFillId,
  groupedByDate,
  listPrescriptionFills,
  filter,
  onPrescriptionFillsSearch,
  rootRoute,
}) => (
  <List
    header={<Header
      filter={filter}
      onPrescriptionFillsSearch={onPrescriptionFillsSearch}
    />}
    isReady={!isLoading}
    onBottomThreshold={listPrescriptionFills}
  >
    {
      groupedByDate.map((group) => (
        <ListItemGroup key={group.id} title={group.title}>
          {
            group.prescriptionFills.map((prescriptionFill) => {
              const isActiveFill = activePrescriptionFillId === prescriptionFill.id;
              const itemKey = new Date(prescriptionFill.updatedAt).toLocaleTimeString([], { hour: 'numeric', minute: 'numeric' });
              const patientName = `${prescriptionFill.prescription.patient.firstName} ${prescriptionFill.prescription.patient.lastName}`;

              return <ListItem
                key={prescriptionFill.id}
                itemKey={itemKey}
                title={patientName}
                isActive={isActiveFill}
                icon={
                  <img
                    alt='Patient'
                    src={isActiveFill ? ActivePatientIcon : PatientIcon}
                  />
                }
                url={`${rootRoute}/${prescriptionFill.id}`}
              >
                <PrescriptionFill
                  prescriptionFill={prescriptionFill}
                  isSelected={isActiveFill}
                />
              </ListItem>;
            })
          }
        </ListItemGroup>
      ))
    }
  </List>
);

export default observer(PrescriptionFillsList);
