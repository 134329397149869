import stores from 'bootstrap/store';

import { EVENT } from '../../constants';

const containersAddPrelabelledImageHandler = ({ apiWebSocket }) => {
  apiWebSocket.on(EVENT.RECEIVED.CONTAINER_ADD_PRELABELLED_IMAGE, async () => {
    return stores.global.dispenseAttempts.listPendingForNotificationsPane();
  });
};

export default containersAddPrelabelledImageHandler;
