import React from 'react';
import { observer } from 'mobx-react';

// Components
import PatientInfo from '../../../../components/patient-info';

// Styles
import './info-style.scss';

const InfoView = ({
  activePatient = {},
  patientAddress,
  patientImages = [],
  isEditMode,
  setEditablePatient,
  updatePatient,
}) => (
  <div className='c-PatientDetails'>
    <div className='content'>
      <PatientInfo
        patient={activePatient}
        patientAddress={patientAddress}
        patientImages={patientImages}
        isEditMode={isEditMode}
        setEditablePatient={setEditablePatient}
        updatePatient={updatePatient}
      />
    </div>
  </div>
);

export default observer(InfoView);
