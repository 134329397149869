import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

// Views
import PrescriptionInfoView from './prescription-info-view';

// Hooks
import usePrevious from 'hooks/usePrevious';
import useSocketEvent from 'hooks/useSocketEvent';

// Helpers
import {
  doesPhysicianHaveFacility,
  listContainerImages,
  fetchContainerLabel,
} from './helpers';

// Constants
import { EVENT as SOCKET_EVENT } from 'bootstrap/api-websocket/constants';

// Stores
import { prescriptionInfoRouteStore } from './domain/store';


const PrescriptionInfoContainer = ({ activeDispenseAttemptId, activeDispenseAttempt, isExpanded, toggleExpanded }) => {
  const previousActiveDispenseAttemptId = usePrevious(activeDispenseAttemptId);

  useEffect(() => {
    listContainerImages();
    fetchContainerLabel();

    return () => {
      prescriptionInfoRouteStore.clearImages();
    };
  }, []);

  useEffect(() => {
    if (activeDispenseAttemptId !== previousActiveDispenseAttemptId) {
      prescriptionInfoRouteStore.clearImages();
      listContainerImages();
      fetchContainerLabel();
    }
  }, [activeDispenseAttemptId]);

  useSocketEvent(SOCKET_EVENT.RECEIVED.CONTAINER_ADD_LABELLED_IMAGE, () => {
    return listContainerImages();
  });

  useSocketEvent(SOCKET_EVENT.RECEIVED.CONTAINER_ADD_PRELABELLED_IMAGE, () => {
    return listContainerImages();
  });

  return (
    <PrescriptionInfoView
      prescriptionInfoRouteStore={prescriptionInfoRouteStore}
      activeDispenseAttempt={activeDispenseAttempt}
      containerImagesFetched={!prescriptionInfoRouteStore.http.listingContainerImages}
      doesPhysicianHaveFacility={doesPhysicianHaveFacility}
      isExpanded={isExpanded}
      toggleExpanded={toggleExpanded}
    />
  );
};

export default observer(PrescriptionInfoContainer);
