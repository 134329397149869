import { types } from 'mobx-state-tree';


export const SignUpRouteStore = types.model({
  email: types.optional(types.string, ''),
  password: types.optional(types.string, ''),
  firstName: types.optional(types.string, ''),
  lastName: types.optional(types.string, ''),
  errorMessage: types.optional(types.string, ''),
})
.actions(self => ({
  setValue: (prop, value) => {
    if (self.errorMessage) self.errorMessage = '';
    self[prop] = value;
  },
  setInitialState: () => {
    self.email = '';
    self.password = '';
    self.firstName = '';
    self.lastName = '';
    self.errorMessage = '';
  }
}));

export const signUpRouteStore = SignUpRouteStore.create({});
