import React from 'react';
import { observer } from 'mobx-react';

import './labelled-checkbox-styles.scss';

const LabelledCheckbox = ({
  label,
  isChecked,
  onChange,
}) => (
  <label className='c-LabelledCheckbox'>
    {
      label
    }
    <input
      type='checkbox'
      name={label}
      checked={isChecked}
      onChange={() => onChange(!isChecked)}
    />
    <span className='checkmark'/>
  </label>
);

export default observer(LabelledCheckbox);
