import { get as _get } from 'lodash';

import { STATUS, PROPS } from './constants';


export default (activeDispenseAttempt) => {
  const isApproved = _get(activeDispenseAttempt, 'review.approveContainer') && _get(activeDispenseAttempt, 'review.approvePrescription');
  const isConsultationRequested = _get(activeDispenseAttempt, 'prescriptionFill.consultReq');

  if (isApproved) return PROPS[STATUS.APPROVED];
  else if (isApproved === false) return PROPS[STATUS.REJECTED];
  else if (isConsultationRequested) return PROPS[STATUS.CONSULTATION_REQUESTED];
  else return PROPS[STATUS.UNIDENTIFIABLE];
};
