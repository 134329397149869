import React from 'react';
import { observer } from 'mobx-react';

const InfoItem = ({ caption, value, showWithoutValue = true }) => (
  <React.Fragment>
    {
      !showWithoutValue && !value
        ? <React.Fragment/>
        : <div className='item'>
          <div className={'value'}>
            {
              value || 'N/A'
            }
          </div>
          <div className='key'>
            {
              caption
            }
          </div>
        </div>
    }
  </React.Fragment>
);

export default observer(InfoItem);
