import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import PrescriptionFillsView from './prescription-fills-view';

import listPrescriptionFills from './helpers/listPrescriptionFills';
import onPrescriptionFillSearch from './helpers/onPrescriptionFillSearch';

import stores from 'bootstrap/store';
import { lotPrescriptionFillsRouteStore } from './domain/store';

const PrescriptionFillsContainer = ({ activeLotId }) => {
  useEffect(() => {
    listPrescriptionFills(activeLotId);

    return () => {
      stores.global.prescriptionFills.setValue('dataForPharmacist', {});
    };
  }, []);

  return <PrescriptionFillsView
    lotPrescriptionFillsRouteStore={lotPrescriptionFillsRouteStore}
    activeLotId={activeLotId}
    onPrescriptionFillSearch={onPrescriptionFillSearch}
  />;
};

export default observer(PrescriptionFillsContainer);
