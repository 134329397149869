import { types } from 'mobx-state-tree';
import { set as _set } from 'lodash';

import stores from 'bootstrap/store';

import { STATUS } from '../constants';

export const PrescriptionFillsListRouteStore = types.model({
  activePrescriptionFillId: types.maybeNull(types.string),
  prescriptionFillsFilterValue: types.optional(types.string, ''),
  currentPage: types.optional(types.model({
    prescriptionFills: types.optional(types.number, 1),
  }), {}),
  http: types.optional(types.model({
    listingPrescriptionFillsForPharmacist: types.optional(types.number, 0),
  }), {}),
})

.actions(self => ({
  setValue: (path, value) => _set(self, path, value),
  setInitialState: () => {
    self.activePrescriptionFillId = null;
    self.prescriptionFillsFilterValue = '';
    self.currentPage = {};
    self.http = {};
  },
  incrementHttp: (http) => ++self.http[http],
  decrementHttp: (http) => --self.http[http],
}))
.views(self => ({
  get activePrescriptionFill() {
    return self.activePrescriptionFillId && stores.global.prescriptionFills.dataForPharmacist.get(self.activePrescriptionFillId);
  },
  get activeFillStatus() {
    const { activePrescriptionFill } = self;
    if (!activePrescriptionFill) return undefined;

    if (activePrescriptionFill.declined) return STATUS.DECLINED;
    if (activePrescriptionFill.computed_filled) return STATUS.DISPENSED;

    const { dispenseAttempts, prescriptionFillKioskRoutings } = activePrescriptionFill;
    if (!prescriptionFillKioskRoutings || !prescriptionFillKioskRoutings.length) return STATUS.UNROUTED;
    if (!dispenseAttempts || !dispenseAttempts.length) return STATUS.ROUTED_WITHOUT_DISPENSE_ATTEMPT;

    const latestDispenseAttempt = dispenseAttempts[0];
    const { review } = latestDispenseAttempt;
    if (!review) return STATUS.AWAITING_REVIEW;
    else if (!review.approveContainer && !review.approvePrescription) return STATUS.REJECTED;
    else if (!review.approveContainer) return STATUS.REJECTED_CONTAINER;
    else if (!review.approvePrescription) return STATUS.REJECTED_PRESCRIPTION;
    else return STATUS.APPROVED;
  },
}));

export const prescriptionFillsListRouteStore = PrescriptionFillsListRouteStore.create({});
