import React from 'react';
import { observer } from 'mobx-react';

// Components
import DrillDownLayout from 'components/drill-down-layout';
import List from 'components/drill-down-layout/components/list';
import ListHeader from 'components/drill-down-layout/components/list/components/list-header';
import ListItem from 'components/drill-down-layout/components/list/components/list-item';
import Content from 'components/drill-down-layout/components/content';
import ContentHeader from 'components/drill-down-layout/components/content/components/content-header';

import ModuleTemplate from './components/module-template';
import ActiveModuleTemplate from './components/active-module-template';

// Icons
import kioskIcon from 'assets/icons/kiosk.svg';
import activeKioskIcon from 'assets/icons/kiosk-active.svg';

// Styles
import './module-templates-view.scss';


const ModuleTemplatesView  = ({
  moduleTemplatesRouteStore,
  moduleTemplatesStore,
  setFilterValue,
  upsertModuleTemplate,
  rootRoute,
}) => {

  const listHeader = <ListHeader
    title='Module Templates'
    searchValue={moduleTemplatesRouteStore.filterValue}
    onSearch={e => setFilterValue(e)}
  />;

  const contentHeader = <ContentHeader
    title={moduleTemplatesRouteStore.activeModuleTemplate && moduleTemplatesRouteStore.activeModuleTemplate.id}
    subHeader={
      <div className='active-module-template-view-header'>
        <div className='module-template-control-buttons'>
          <input
            className='module-template-control-buttons-submit-button'
            type='button'
            value='Save Module'
            onClick={upsertModuleTemplate}
          />
        </div>
      </div>
    }
  />;

  return (
    <div className='c-ModulesTemplateView'>
      <DrillDownLayout>
        <List header={listHeader}>
          {
            moduleTemplatesStore.dataAsArray().map((moduleTemplate, index) => {
              return (
                <ListItem key={index}
                  title={ <div className='title'>{moduleTemplate.id}</div> }
                  isActive={moduleTemplatesRouteStore.activeModuleTemplateId === moduleTemplate.id}
                  icon={
                    <img
                      src={
                        moduleTemplatesRouteStore.activeModuleTemplateId === moduleTemplate.id
                          ? activeKioskIcon
                          : kioskIcon
                      }
                    />
                  }
                  url={`${rootRoute}/${moduleTemplate.id}`} >
                  <ModuleTemplate
                    moduleTemplate={moduleTemplate}
                  />
                </ListItem>
              );
            })
          }
        </List>
        <Content header={contentHeader}
          parentRoute={`${rootRoute}/${moduleTemplatesRouteStore.activeModuleTemplate?.id}`}
          isReady={!!moduleTemplatesRouteStore.activeModuleTemplate}>
          <ActiveModuleTemplate
            moduleTemplatesRouteStore={moduleTemplatesRouteStore}
            upsertModuleTemplate={upsertModuleTemplate}
          />
        </Content>
      </DrillDownLayout>
    </div>
  );
};

export default observer(ModuleTemplatesView);
