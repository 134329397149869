import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import { get as _get } from 'lodash';

import stores from 'bootstrap/store';

// Views
import HealthcareSiteActivityView from './healthcare-site-activity-view';

// Constants
import { MAX_ENTRIES_PER_PAGE } from 'components/app-commands/constants';
import { APP_COMMAND_TYPE_BASE_LIST } from 'models/app-commands/constants/constants';

// Stores
import { healthcareSiteActivityRouteStore } from './domain/store';
const appCommandsStore = stores.global.appCommands;


const HealthcareSiteActivityContainer = ({ activeHealthcareSite }) => {
  const listAppCommands = useCallback(async (config = {}) => {
    if (healthcareSiteActivityRouteStore.http.listingAppCommands) {
      return;
    }

    const { currentPage } = healthcareSiteActivityRouteStore;
    config = {
      ...config,
      params: {
        healthcareSiteId: activeHealthcareSite.id
      },
      query: {
        ...config.query,
        order: [['updatedAt', 'desc']],
        offset: MAX_ENTRIES_PER_PAGE * (currentPage - 1),
        limit: MAX_ENTRIES_PER_PAGE,
      },
    };

    const { appCommandFilter } = healthcareSiteActivityRouteStore;
    if (appCommandFilter) {
      const replacedFilter = appCommandFilter.replace(/(Create)|(Update)/, 'Upsert');
      if (
        (appCommandFilter.includes('Create') || appCommandFilter.includes('Update'))
        && APP_COMMAND_TYPE_BASE_LIST.includes(replacedFilter)
      ) {
        config.query.query = {
          type: replacedFilter
        };

        config.query.isNew = appCommandFilter.includes('Create');
      }
      else {
        config.query.query = {
          type: appCommandFilter,
        };
      }
    }

    healthcareSiteActivityRouteStore.incrementHttp('listingAppCommands');
    return appCommandsStore.listForHealthcareSite(config)
    .then((response) => {
      const responseLength = _get(response, 'data.length', 0);
      if (responseLength > MAX_ENTRIES_PER_PAGE - 1) {
        healthcareSiteActivityRouteStore.setValue('currentPage', healthcareSiteActivityRouteStore.currentPage + 1);
      }
    })
    .finally(() => healthcareSiteActivityRouteStore.decrementHttp('listingAppCommands'));
  }, [activeHealthcareSite]);

  const setFilter = useCallback(async (data) => {
    const value = data ? data.value : '';

    healthcareSiteActivityRouteStore.setValue('currentPage', 1);
    healthcareSiteActivityRouteStore.setValue('appCommandFilter', value);
    return listAppCommands({ resetData: true });
  }, [listAppCommands]);

  useEffect(() => {
    listAppCommands();

    return () => {
      healthcareSiteActivityRouteStore.setInitialState();
      appCommandsStore.setValue('dataForHealthcareSite', {});
    };
  }, []);

  return (
    <HealthcareSiteActivityView
      healthcareSiteActivityRouteStore={healthcareSiteActivityRouteStore}
      appCommandsStore={appCommandsStore}
      activeHealthcareSite={activeHealthcareSite}
      setFilter={setFilter}
      listAppCommands={listAppCommands}
    />
  );
};

export default observer(HealthcareSiteActivityContainer);
