import React, { useCallback, useEffect } from 'react';
import JsPDF from 'jspdf';
import moment from 'moment';
import { observer } from 'mobx-react';
import { keyBy as _keyBy } from 'lodash';

import ExportInventoryModalView from './export-inventory-modal-view';

import { exportInventoryModalRouteStore } from './domain/store';

const ExportInventoryModalContainer = ({
  isOpen,
  closeModal,
  fetchDrugs,
  fetchInventory,
  kioskName,
}) => {
  const fetchDrugsPrivate = useCallback(async () => {
    if (!fetchDrugs) {
      return;
    }

    exportInventoryModalRouteStore.incrementHttp('fetchingDrugs');
    const response = await fetchDrugs();
    const drugs = response.data || [];
    exportInventoryModalRouteStore.setValue('drugsById', _keyBy(drugs, 'id'));
    exportInventoryModalRouteStore.decrementHttp('fetchingDrugs');
  }, [fetchDrugs]);

  const fetchInventoryPrivate = useCallback(async () => {
    if (!fetchInventory) {
      return;
    }

    exportInventoryModalRouteStore.incrementHttp('fetchingComputedInventory');
    const response = await fetchInventory();
    exportInventoryModalRouteStore.setValue('computedInventory', response);
    exportInventoryModalRouteStore.decrementHttp('fetchingComputedInventory');
  }, [fetchInventory]);

  useEffect(() => {
    if (isOpen) {
      fetchDrugsPrivate();
      fetchInventoryPrivate();
    } else {
      exportInventoryModalRouteStore.reset();
    }
  }, [isOpen]);

  const generatePDF = async () => {
    const pdf = new JsPDF({
      format: 'a4',
      orientation: 'portrait',
      unit: 'pt',
    });
    await pdf.html(document.querySelector('#simplified-inventory-report'), {
      html2canvas: {
        scale: 0.9,
      }
    });
    const kiosk = kioskName?.toLowerCase().replace(' ', '-');
    const filename = `inventory-${kiosk}-${moment().format('MM-DD-YYYY')}`;
    return pdf.save(`${filename}.pdf`);
  };

  return <ExportInventoryModalView
    isOpen={isOpen}
    closeModal={closeModal}
    isBusy={exportInventoryModalRouteStore.isBusy}
    drugs={exportInventoryModalRouteStore.forTable}
    kioskName={kioskName}
    generatePDF={generatePDF}
  />;
};

export default observer(ExportInventoryModalContainer);
