import { types, isAlive } from 'mobx-state-tree';

import stores from 'bootstrap/store';

export const HealthcareSitesRouteStore = types.model({
})
.props({
  http: types.optional(types.model({
    listingHealthcareSites: types.optional(types.number, 0),
    healthcareSiteUpsert: types.optional(types.number, 0),
  }), {}),
  activeHealthcareSiteId: types.maybeNull(types.string),
  routeHealthcareSiteId: types.maybeNull(types.string),
  filterValue: types.optional(types.string, ''),
  currentPage: types.optional(types.number, 1),
  showingHealthcareSiteUpsertModal: types.optional(types.boolean, false),
})
.actions(self => ({
  setValue: (prop, value) => self[prop] = value,
  incrementHttp: (http) => ++self.http[http],
  decrementHttp: (http) => --self.http[http]
}))
.views(self => ({
  activeHealthcareSiteExists() {
    return self.activeHealthcareSite && isAlive(self.activeHealthcareSite);
  },
  get activeHealthcareSite() {
    return self.activeHealthcareSiteId && stores.global.healthcareSites.dataForPharmacist.get(self.activeHealthcareSiteId);
  },
}));


export const healthcareSitesRouteStore = HealthcareSitesRouteStore.create({});
