import { toJS } from 'mobx';
import { types } from 'mobx-state-tree';
import { scroller } from 'react-scroll';
import { uuid } from 'uuidv4';
import _ from 'lodash';

import stores from 'bootstrap/store';

import ModuleTemplateModel from 'models/module-templates';

export const ModuleTemplatesRouteStore = types.model({
})
.props({
  activeModuleTemplateId: types.maybeNull(types.string),
  modifiedModuleTemplates: types.optional(types.array(types.frozen()), []),
  modifiedSlotIndex: types.optional(types.number, -1),
  filterValue: types.optional(types.string, ''),
})
.actions(self => ({
  setValue: (prop, value) => self[prop] = value,
  setModifiedModuleTemplateName: ({name}) => {
    self.activeModifiedModuleTemplate.id = name;
  },
  setModifiedModuleTemplateSizeParam: ({sizeParam, value}) => {
    self.activeModifiedModuleTemplate[sizeParam] = value;
  },
  setActiveModifiedSlotIndex: ({slotIndex}) => {
    self.setValue('modifiedSlotIndex', slotIndex);
    scrollToSlot({slotIndex});
  },
  setModifiedSlotName: ({slot, name}) => {
    let editSlot = _.find(self.activeModifiedModuleTemplate.moduleSlotTemplates, {id: slot.id});
    editSlot.name = name;
  },
  setModifiedSlotRadius: ({slot, value}) => {
    let editSlot = _.find(self.activeModifiedModuleTemplate.moduleSlotTemplates, {id: slot.id});
    editSlot.slot_template.containerConstraintRadiusMax = value.toString();
  },
  setModifiedSlotWidth: ({slot, value}) => {
    let editSlot = _.find(self.activeModifiedModuleTemplate.moduleSlotTemplates, {id: slot.id});
    editSlot.slot_template.containerConstraintWidthMax = value.toString();
  },
  setModifiedSlotHeight: ({slot, value}) => {
    let editSlot = _.find(self.activeModifiedModuleTemplate.moduleSlotTemplates, {id: slot.id});
    editSlot.slot_template.containerConstraintHeightMax = value.toString();
  },
  setModifiedSlotPosition: ({slot, coordinate, value}) => {
    let editSlot = _.find(self.activeModifiedModuleTemplate.moduleSlotTemplates, {id: slot.id});
    editSlot[coordinate] = value.toString();
  },
  setDraggedSlotPositionCircle: ({slot, coordinates}) => {
    let editSlot = _.find(self.activeModifiedModuleTemplate.moduleSlotTemplates, {id: slot.id});
    editSlot.x = (coordinates.x > -1 ? coordinates.x : 10).toString();
    editSlot.y = (coordinates.y > -1 ? coordinates.y : 10).toString();
  },
  setDraggedSlotPositionRect: ({slot, coordinates}) => {
    let editSlot = _.find(self.activeModifiedModuleTemplate.moduleSlotTemplates, {id: slot.id});
    editSlot.x = (coordinates.x > -1 ? coordinates.x : 0).toString();
    editSlot.y = (coordinates.y > -1 ? coordinates.y : 0).toString();
  },
  deleteSlot: ({slot}) => {
    let slotIndex = _.findIndex(self.activeModifiedModuleTemplate.moduleSlotTemplates, {id: slot.id});
    self.activeModifiedModuleTemplate.moduleSlotTemplates.splice(slotIndex, 1);
  },
  addSlot: () => {
    const slots = self.activeModifiedModuleTemplate.moduleSlotTemplates;
    slots.push({
      id: uuid(),
      name: '',
      x: '10',
      y: '10',
      slot_template: {
        id: uuid(),
        description: '',
        containerConstraintRadiusMax: '0',
        containerConstraintRadiusMin: '0',
      }
    });

    return Promise.resolve(self.setValue('modifiedSlotIndex', slots.length - 1))
    .then(() => scrollToSlot({slotIndex: slots.length - 1}));
  },
  addModuleTemplate: () => {
    self.modifiedModuleTemplates.push({
      id: uuid(),
      name: '',
      width: '100',
      height: '100',
      moduleSlotTemplates: [],
    });

    return Promise.resolve(self.setValue('activeModifiedModuleTemplate', toJS(self.modifiedModuleTemplates[self.modifiedModuleTemplates.length - 1])))
    .then(() => scrollToModuleTemplate({moduleTemplate: self.activeModifiedModuleTemplate}));
  },
  removeModuleTemplate: (moduleTemplate) => {
    return self.modifiedModuleTemplates.remove(moduleTemplate);
  }
}))
.views(self => ({
  get activeModuleTemplate() {
    return self.activeModuleTemplateId && stores.global.moduleTemplates.data.get(self.activeModuleTemplateId);
  },
  get activeModifiedModuleTemplate() {
    return _.find(self.modifiedModuleTemplates, {id: self.activeModuleTemplateId});
  },
}));

function scrollToSlot({slotIndex}) {
  scroller.scrollTo((slotIndex).toString(), {
    duration: 400,
    offset: -400,
    smooth: true,
    containerId: 'slotsList'
  });
}

function scrollToModuleTemplate({moduleTemplate}) {
  scroller.scrollTo(moduleTemplate.id, {
    duration: 400,
    offset: -10,
    smooth: true,
    containerId: 'module-templates-wrapper'
  });
}

export const moduleTemplatesRouteStore = ModuleTemplatesRouteStore.create({});
