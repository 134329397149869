import { CONFERENCE_STATUS } from 'models/video-conferences/constants';

export const getConferenceStatusCaption = (conferenceStatus) => {
  switch (conferenceStatus) {
    case CONFERENCE_STATUS.CONNECTING_API_VIDEO_CONFERENCE:
    case CONFERENCE_STATUS.CONNECTING_TWILIO:
      return 'Connecting...';
    case CONFERENCE_STATUS.FAILED_CONNECTING_API_VIDEO_CONFERENCE: return 'Unable To Create Video Conference';
    case CONFERENCE_STATUS.FAILED_CONNECTING_TWILIO: return 'Failed To Establish Connection';
    default: return null;
  }
};
