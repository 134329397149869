import deepFreeze from 'deep-freeze';

export const MODEL_NAME = Object.freeze({
  SINGULAR: 'prescriptionFillUserAction',
  PLURAL: 'prescription-fill-user-actions',
});

export const FIELD_NAME = Object.freeze({
  ID: 'id',
});

export const SCHEMA = deepFreeze({
  title: MODEL_NAME.SINGULAR,
  type: 'object',
  properties: {
    [FIELD_NAME.ID]: {
      type: 'string',
    },
  },
});
