import React from 'react';
import { observer } from 'mobx-react';

import './index.scss';

const DrillDownLayout = ({
  children
}) => {
  return <div className='c-DrillDownLayout'>
    {
      children
    }
  </div>;
};

export default observer(DrillDownLayout);
