import stores from 'bootstrap/store';

import { NOTIFICATIONS_DATA, NOTIFICATION_TYPES } from '../constants';

// Load initial data for all notifications.
const fetchNotificationsRelatedData = (notificationType) => {
  if (!stores.global.users.activeUser) return setTimeout(() => fetchNotificationsRelatedData(notificationType), 1000);

  const types = notificationType ? [notificationType] : NOTIFICATION_TYPES;
  return types.forEach((type) => {
    const notification = NOTIFICATIONS_DATA[type];
    if (notification && notification.loadData) notification.loadData();
  });
};

export default fetchNotificationsRelatedData;
