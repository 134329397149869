import UserModel from 'models/users';
import { types } from 'mobx-state-tree';
import {
  get as _get,
  set as _set
} from 'lodash';


export const UserUpsertModalRouteStore = types.model({
})
.props({
  activelyModifiedUser: types.maybeNull(types.frozen()),
  showingDeleteConfirmationModal: types.optional(types.boolean, false)
})
.actions(self => ({
  setValue: (path, value) => _set(self, path, value),
  setInitialState() {
    self.setValue('activelyModifiedUser', null);
    self.setValue('showingDeleteConfirmationModal', false);
  }
}))
.views(self => ({
  get readyForCreation() {
    return !!(
      _get(self, 'activelyModifiedUser.firstName') &&
      _get(self, 'activelyModifiedUser.lastName') &&
      _get(self, 'activelyModifiedUser.email') &&
      _get(self, 'activelyModifiedUser.password')
    );
  }
}));

export const userUpsertModalRouteStore = UserUpsertModalRouteStore.create({});
