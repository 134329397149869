import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import ArrowBack from './components/arrow-back';
import Pages from './components/pages';
import ArrowForward from './components/arrow-forward';

import './index.scss';

const Paginator = ({
  currentPage = 1,
  entriesPerPage = 10,
  entriesCount,
  changePage,
  maxPagesVisible = 4,
}) => {
  const [isShowingDotsSeparator, setIsShowingDotsSeparator] = useState(false);
  const pagesCount = Math.ceil(entriesCount / entriesPerPage);

  useEffect(() => {
    const pagesCount = Math.ceil(entriesCount / entriesPerPage);

    if (pagesCount - currentPage > maxPagesVisible - 1) {
      setIsShowingDotsSeparator(true);
    } else {
      setIsShowingDotsSeparator(false);
    }
  }, [currentPage, entriesCount, entriesPerPage, maxPagesVisible]);

  return <div className='c-Paginator'>
    <div className='title'>
      Page
    </div>
    <ArrowBack
      currentPage={currentPage}
      changePage={changePage}
    />
    <Pages
      showingDots={isShowingDotsSeparator}
      currentPage={currentPage}
      pagesCount={pagesCount}
      changePage={changePage}
      maxPagesVisible={maxPagesVisible}
    />
    <ArrowForward
      currentPage={currentPage}
      pagesCount={pagesCount}
      changePage={changePage}
    />
  </div>;
};

export default observer(Paginator);
