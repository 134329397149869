import base from 'models/base';
import { MODEL_NAME, SCHEMA } from './constants/schema';


const PrescriptionFillPharmacistConsultationRequestModel = base({
  names: {
    singular: MODEL_NAME.SINGULAR,
    plural: MODEL_NAME.PLURAL
  },
  JSONSchema: SCHEMA,
  httpConfig: {
    root: MODEL_NAME.plural,
    methods: {}
  }
});

PrescriptionFillPharmacistConsultationRequestModel.configureStore((store) => {
  return store
  .props({
  })
  .actions(self => ({
    create: (config = {}) => {
      if (!config.params || !config.params.prescriptionFillId) {
        return Promise.reject('prescriptionFillId must be provided in params in order to create a consultation request.');
      }

      config.urlFragment = (params) => `/prescription-fills/${params.prescriptionFillId}`;
      return self.post(config);
    },
  }));
});


export default PrescriptionFillPharmacistConsultationRequestModel;
