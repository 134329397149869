import _ from 'lodash';
import { types, flow } from 'mobx-state-tree';

import base from 'models/base';
import { MODEL_NAME, SCHEMA } from './constants/schema';


const ModuleTemplatesModel = base({
  names: {
    singular: MODEL_NAME.SINGULAR,
    plural: MODEL_NAME.PLURAL
  },
  JSONSchema: SCHEMA,
  httpConfig: {
    root: MODEL_NAME.plural,
    methods: {
    }
  }
});

ModuleTemplatesModel.configureStore((store) => {
  return store
  .props({
    data: types.optional(types.map(types.frozen()), {})
  })
  .actions(self => ({
    setValue: (prop, value) => self[prop] = value,
    getSlotsCountByRadius: (moduleTemplate) => {
      return _.sortBy(_.reduce(moduleTemplate.slots, (slotsCountByRadius, slot) => {
        if (!slotsCountByRadius.find(item => item.radius === slot.radius)) {
          slotsCountByRadius.push({radius: slot.radius, count: 1});
        } else {
          slotsCountByRadius[_.findIndex(slotsCountByRadius, item => item.radius === slot.radius)].count += 1;
        }

        return slotsCountByRadius;
      }, []), 'radius');
    },
    getSlotsCountByType: (moduleTemplate) => {
      return _.reduce(moduleTemplate.moduleSlotTemplates, (slotsCountByType, moduleSlotTemplate) => {
        slotsCountByType[moduleSlotTemplate.id] || (slotsCountByType[moduleSlotTemplate.id] = 0);
        slotsCountByType[moduleSlotTemplate.id]++;
        return slotsCountByType;
      }, {});
    },
    fetch: flow(function* (config = {}) {
      const page = _.get(config, 'query.page', 0);
      if (!page) self.data = {};

      const response = yield self.list(config);
      const data = response.data || response || [];
      self.data.merge(_.keyBy(data, 'id'));

      return response;
    }),
    upsert: flow(function* (config = {}) {
      config.urlFragment = () => '/';
      return yield self.put(config);
    }),
  }))
  .views(self => ({
    dataAsArray() {
      return Array.from(self.data.values());
    },
  }));
});


export default ModuleTemplatesModel;
