import React from 'react';
import { observer } from 'mobx-react';

import ChevronBottom from 'assets/icons/chevron-bottom.svg';
import ChevronTop from 'assets/icons/chevron-top.svg';

import './route-styles.scss';

const NavbarRoute = ({
  isActiveRoute,
  route,
  hasSubRoutes = false,
  isShowingRouteDropdown = false,
  onClick,
}) => (
  <div className={`c-NavbarRoute ${isActiveRoute && 'c-NavbarRouteActive'}`} onClick={() => onClick && onClick(route)}>
    <div className='route'>
      <div className='icon'>
        <img alt='tab-icon' src={isActiveRoute ? route.ICON.ACTIVE : route.ICON.DISABLED} />
      </div>
      <div className={`name ${isActiveRoute && 'name-active'}`}>
        {
          route.NAME
        }
      </div>
    </div>
    {
      hasSubRoutes &&
      <div className='sub-routes-dropdown'>
        <div className='icon'>
          <img
            alt='Dropdown'
            src={isShowingRouteDropdown ? ChevronTop : ChevronBottom}
          />
        </div>
      </div>
    }
  </div>
);

export default observer(NavbarRoute);
