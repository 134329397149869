import * as ROUTES from 'constants/routes';

export const NAVBAR_TAB = {
  USER: 'User',
  ADMIN: 'Admin'
};
export const NAVBAR_TABS = Object.values(NAVBAR_TAB);

export const NAVBAR_ROUTES = {
  [NAVBAR_TAB.USER]: [
    ROUTES.DISPENSE_ATTEMPTS,
    ROUTES.DRUGS,
    ROUTES.PATIENTS,
    ROUTES.PRESCRIPTION_FILLS,
    ROUTES.KIOSKS,
    // ROUTES.HEALTHCARE_SITES,
    // ROUTES.VIDEO_CONFERENCES,
    // ROUTES.KEYCARDS,
    ROUTES.PHARMACY_TECHNICIANS,
    ROUTES.MEDICAL_ASSISTANTS,
    // ROUTES.PRESCRIPTION_FILL_UPSERT,
    ROUTES.LOTS,
    ROUTES.STOCKING_BATCHES,
  ],
  [NAVBAR_TAB.ADMIN]: [
    ROUTES.ADMIN_DRUGS,
    ROUTES.ADMIN_MACHINE_TECHNICIANS,
    ROUTES.ADMIN_MEDICAL_ASSISTANTS,
    ROUTES.ADMIN_MODULE_TEMPLATES,
    ROUTES.ADMIN_PATIENTS,
    ROUTES.ADMIN_PHARMACY_TECHNICIANS,
    ROUTES.ADMIN_PHYSICIANS,
    ROUTES.ADMIN_USERS,
    ROUTES.ADMIN_PHARMACIES,
  ],
};
export const NAVBAR_ROUTE_VALUES = Object.keys(NAVBAR_ROUTES).reduce((routeValues, tab) => {
  const routes = NAVBAR_ROUTES[tab];
  routeValues[tab] = routeValues[tab] || [];
  routeValues[tab] = routes.map((route) => route.VALUE);
  return routeValues;
}, {});

export const DEFAULT_REDIRECT_LINK = {
  [NAVBAR_TAB.USER]: ROUTES.DISPENSE_ATTEMPTS.VALUE,
  [NAVBAR_TAB.ADMIN]: ROUTES.ADMIN_DRUGS.VALUE,
};

