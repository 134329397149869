import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import DrugsView from './drugs-view';

import * as helpers from './helpers';

import { drugsRouteStore } from './domain/store';


const DrugsContainer = () => {
  useEffect(() => {
    helpers.listDrugs();

    return () => {
      drugsRouteStore.setInitialState();
    };
  }, []);

  return (
    <DrugsView
      drugsRouteStore={drugsRouteStore}
      listDrugs={helpers.listDrugs}
      sortDrugs={helpers.sortDrugs}
      changePaginationPage={helpers.changePaginationPage}
      onDrugsSearch={helpers.onDrugsSearch}
      showDrugUpsertModal={helpers.showDrugUpsertModal}
      closeDrugUpsertModal={helpers.closeDrugUpsertModal}
    />
  );
};

export default observer(DrugsContainer);
