import React from 'react';
import { observer } from 'mobx-react';

import UpsertModal from './components/upsert-modal';

import AdminTableLayout from 'components/admin-table-layout';
import Title from 'components/admin-table-layout/components/title';
import TableHeader from 'components/admin-table-layout/components/table-header';
import EntriesCount from 'components/admin-table-layout/components/table-header/components/entries-count';
import Paginator from 'components/paginator';
import NewEntryButton from 'components/admin-table-layout/components/table-header/components/new-entry-button';
import ListSearch from 'components/drill-down-layout/components/list/components/list-header/components/list-search';
import TableContent from 'components/admin-table-layout/components/table-content';
import SmartTable from 'components/smart-table';

import {
  MAX_TABLE_ENTRIES_PER_PAGE,
  MEDICAL_ASSISTANT_MANIPULATION_OPTIONS,
} from './constants';

import './smart-table-work-places-styles.scss';

const MedicalAssistantsView = ({
  store,
  routeStore,
  upsertModalRouteStore,
  smartTableColumns,
  sortMedicalAssistants,
  changePaginationPage,
  onMedicalAssistantsSearch,
  showUpsertModal,
  closeUpsertModal,
  addWorkPlaceLocally,
  removeWorkPlaceLocally,
  onWorkPlacesSearch,
  createMedicalAssistant,
  updateMedicalAssistant,
  deleteMedicalAssistant,
  showDeleteConfirmationModal
}) => (
  <React.Fragment>
    <UpsertModal
      upsertModalRouteStore={upsertModalRouteStore}
      routeStore={routeStore}
      addWorkPlaceLocally={addWorkPlaceLocally}
      removeWorkPlaceLocally={removeWorkPlaceLocally}
      onWorkPlacesSearch={onWorkPlacesSearch}
      createMedicalAssistant={createMedicalAssistant}
      updateMedicalAssistant={updateMedicalAssistant}
      deleteMedicalAssistant={deleteMedicalAssistant}
      closeUpsertModal={closeUpsertModal}
      showDeleteConfirmationModal={showDeleteConfirmationModal}
    />
    <AdminTableLayout>
      <Title
        text='Medical Assistants'
      />
      <TableHeader>
        <div className='left-area'>
          <EntriesCount
            count={store.entriesCount}
            caption='Medical Assistants'
          />
          <Paginator
            currentPage={routeStore.currentPage}
            entriesCount={store.entriesCount}
            entriesPerPage={MAX_TABLE_ENTRIES_PER_PAGE}
            changePage={changePaginationPage}
          />
        </div>
        <div className='right-area'>
          <NewEntryButton
            caption='New Medical Assistant'
            onClick={e => showUpsertModal(e, MEDICAL_ASSISTANT_MANIPULATION_OPTIONS.CREATE)}
          />
          <ListSearch
            searchValue={routeStore.filterValue}
            onSearch={onMedicalAssistantsSearch}
          />
        </div>
      </TableHeader>
      <TableContent>
        <SmartTable
          sortingFieldName={routeStore.sortingColumn.fieldName}
          sortingOrder={routeStore.sortingColumn.sortingOrder}
          onSortIconClick={sortMedicalAssistants}
          rows={routeStore.forTable}
          onRowClick={(e) => showUpsertModal(e, MEDICAL_ASSISTANT_MANIPULATION_OPTIONS.UPDATE)}
          columns={smartTableColumns}
          loading={routeStore.loading}
        />
      </TableContent>
    </AdminTableLayout>
  </React.Fragment>
);

export default observer(MedicalAssistantsView);
