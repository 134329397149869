import { types } from 'mobx-state-tree';

import { hasRoutePermissions } from 'utils/permissions';

import {
  DEFAULT_REDIRECT_LINK, NAVBAR_ROUTES,
  NAVBAR_TAB, NAVBAR_TABS,
} from '../views/navbar/constants';
import { READ, WRITE } from 'constants/permissionTypes';

import stores from 'bootstrap/store';
const usersStore = stores.global.users;


export const AppFrameRouteStore = types.model({
  activeUserTypeTab: types.optional(types.enumeration('UserTypeTab', Object.values(NAVBAR_TAB)), NAVBAR_TAB.USER)
})
.actions(self => ({
  setValue: (prop, value) => self[prop] = value
}))
.views(self => ({
  get activeUserAccessibleRoutes() {
    return NAVBAR_TABS.reduce((routesByTab, tab) => {
      const { activeUserRoles } = usersStore;

      const routes = NAVBAR_ROUTES[tab];
      const accessibleRoutes = routes.filter((route) => (
        hasRoutePermissions(READ, route.VALUE, activeUserRoles) ||
        hasRoutePermissions(WRITE, route.VALUE, activeUserRoles)
      ));
      if (!accessibleRoutes.length) return routesByTab;

      routesByTab[tab] = routesByTab[tab] || [];
      routesByTab[tab] = accessibleRoutes;
      return routesByTab;
    }, {});
  },
  get activeUserAccessibleRouteValues() {
    return Object.entries(self.activeUserAccessibleRoutes).reduce((routeValues, [tab, routes]) => {
      routeValues[tab] = routeValues[tab] || [];
      routeValues[tab] = routes.map((route) => route.VALUE);
      return routeValues;
    }, {});
  },
  get activeUserAccessibleRouteByValue() {
    return Object.values(self.activeUserAccessibleRoutes).reduce((byValue, routes) => {
      routes.forEach((route) => byValue[route.VALUE] = route);
      return byValue;
    }, {});
  },
  get defaultRedirectLink() {
    const { activeUserAccessibleRouteValues } = self;
    const accessibleTabs = Object.keys(activeUserAccessibleRouteValues);
    if (!accessibleTabs.length) return undefined;

    const routes = activeUserAccessibleRouteValues[self.activeUserTypeTab];
    if (!routes) return undefined;

    const defaultLink = DEFAULT_REDIRECT_LINK[self.activeUserTypeTab];
    const canBeRedirectedToDefault = routes.includes(defaultLink);
    if (canBeRedirectedToDefault) return defaultLink;
    else {
      const tab = accessibleTabs[0];
      return activeUserAccessibleRouteValues[tab][0];
    }
  },
}));

export const appFrameRouteStore = AppFrameRouteStore.create({});

