import React from 'react';
import { observer } from 'mobx-react';

// Components
import Header from './components/header';
import ListItemTitle from './components/list-item-title';
import List from 'components/drill-down-layout/components/list';
import ListItemGroup from 'components/drill-down-layout/components/list/components/list-item-group';
import ListItem from 'components/drill-down-layout/components/list/components/list-item';
import Kiosk from './components/kiosk';

// Icons
import ActiveKioskIcon from 'assets/icons/kiosk-active.svg';
import KioskIcon from 'assets/icons/kiosk.svg';

const KiosksList = ({
  kiosksRouteStore,
  setFilterValue,
  onListBottomThreshold,
  rootRoute,
}) => (
  <List
    header={<Header
      filter={kiosksRouteStore.filterValue}
      setFilter={(e) => setFilterValue(e)}
    />}
    isReady={!kiosksRouteStore.http.listingKiosk}
    onBottomThreshold={onListBottomThreshold}
  >
    {
      kiosksRouteStore.groupedKiosksList.map(({ locationId, title, kiosks }) => (
        <ListItemGroup key={locationId}
          title={title}>
          {
            kiosks.map((kiosk) => {
              const isActive = kiosksRouteStore.activeKioskId === kiosk.id;
              return <ListItem
                key={kiosk.id}
                title={<ListItemTitle kiosk={kiosk}/>}
                isActive={isActive}
                icon={
                  <img
                    alt='KIOSK'
                    src={isActive ? ActiveKioskIcon : KioskIcon}
                  />
                }
                url={`${rootRoute}/${kiosk?.id}`} >
                <Kiosk
                  kiosk={kiosk}
                />
              </ListItem>;
            })
          }
        </ListItemGroup>
      ))
    }
  </List>
);

export default observer(KiosksList);
