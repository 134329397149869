import {
  closePharmacyUpsertModal,
  listPharmacies,
} from './index';

import stores from 'bootstrap/store';
import { pharmaciesRouteStore } from '../domain/store';
const pharmaciesStore = stores.global.pharmacies;

export default () => {
  return pharmaciesStore.deletePharmacy({
    params: {
      id: pharmaciesRouteStore.activePharmacyId,
    },
  })
  .then(() => {
    closePharmacyUpsertModal();
    return listPharmacies();
  });
};
