import React from 'react';
import { observer } from 'mobx-react';

const Resolution = ({ resolution }) => (
  <div className='item'>
    <div className={'value'}>
      <div className={`${resolution === 'SUCCESS' ? 'success-resolution' : 'error-resolution'}`}>
        {
          resolution
        }
      </div>
    </div>
    <div className='key'>STATUS</div>
  </div>
);

export default observer(Resolution);
