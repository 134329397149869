import React from 'react';
import { observer } from 'mobx-react';

import KeyValuePair from '../keyValuePair';

import '../../prescriptionInfoBlockStyles.scss';

const DrugInfoBlock = ({
  drug,
}) => (
  <div className='c-PrescriptionInfoBlock'>
    <div className='title'>
      Drug
    </div>
    <div className='data'>
      <KeyValuePair
        title='Name'
        data={drug}
        keys={['name']}
      />
      <KeyValuePair
        title='NDC'
        data={drug}
        keys={['UPC']}
      />
      <KeyValuePair
        title='UPC'
        data={drug}
        keys={['NDC']}
      />
    </div>
  </div>
);

export default observer(DrugInfoBlock);
