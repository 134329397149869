import stores from 'bootstrap/store';
import { keycardUpsertModalRouteStore } from '../components/keycard-upsert-modal/domain/store';
const pharmaciesStore = stores.global.pharmacies;

export default () => {
  const { pharmacySelectFilter } = keycardUpsertModalRouteStore;
  let subQuery = {};
  if (pharmacySelectFilter) {
    const wildcard = `${pharmacySelectFilter}%`;
    subQuery = {
      name: { $iLike: wildcard },
    };
  }

  return pharmaciesStore.autocompleteForPharmacist({ query: { query: subQuery } });
};
