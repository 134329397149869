import deepFreeze from 'deep-freeze';

export const MODEL_NAME = Object.freeze({
  SINGULAR: 'lot',
  PLURAL: 'lots',
});

export const FIELD_NAME = Object.freeze({
  ID: 'id',
  NUMBER: 'number',
  PHARMACY_ID: 'pharmacyId',
  CREATED_AT: 'createdAt',
  UPDATED_AT: 'updatedAt',
  TOTAL_CONTAINERS_COUNT: 'totalContainersCount',
  DISPENSED_CONTAINERS_COUNT: 'dispensedContainersCount',
  FIRST_STOCKING_DATE: 'firstStockingDate',
  LATEST_STOCKING_DATE: 'latestStockingDate',
});

export const SCHEMA = deepFreeze({
  title: MODEL_NAME.SINGULAR,
  type: 'object',
  properties: {
    [FIELD_NAME.ID]: {
      type: 'string',
    },
    [FIELD_NAME.NUMBER]: {
      type: 'string'
    },
    [FIELD_NAME.PHARMACY_ID]: {
      type: 'string'
    },
    [FIELD_NAME.CREATED_AT]: {
      type: 'string'
    },
    [FIELD_NAME.UPDATED_AT]: {
      type: 'string'
    },
    [FIELD_NAME.TOTAL_CONTAINERS_COUNT]: {
      type: 'number',
    },
    [FIELD_NAME.DISPENSED_CONTAINERS_COUNT]: {
      type: 'number',
    },
    [FIELD_NAME.FIRST_STOCKING_DATE]: {
      type: 'string',
      nullable: true,
    },
    [FIELD_NAME.LATEST_STOCKING_DATE]: {
      type: 'string',
      nullable: true,
    },
  },
});
