import stores from 'bootstrap/store';

import { EVENT } from '../../constants';
import { STATUS_BY_TAB } from 'app/root/routes/app-frame/routes/user/patients/routes/call-requests/helpers/listCallRequests';
import { listCallRequests } from 'app/root/routes/app-frame/routes/user/patients/routes/call-requests/helpers';

const patientCallRequestsCreateHandler = ({ apiWebSocket }) => {
  const callRequestsStore = stores.global.callRequests;
  const callRequestsRouteStore = stores.route.callRequests.domain;

  apiWebSocket.on(EVENT.RECEIVED.CALL_REQUEST_PHARMACIST_ACTIONS_CREATE, ({ action, callRequestId, userId }) => {
    callRequestsStore.listForNotificationsPane();
    
    // Refetch call requests only if a pharmacist action was not created by the active user
    // in order to not mess up the tab transitions.
    const { activeUser } = stores.global.users;
    if (activeUser && activeUser.id !== userId) {
      const isCallRequestBeingDisplayed = !!callRequestsStore.dataForPharmacist.get(callRequestId);
      const shouldCallRequestBeDisplayed = STATUS_BY_TAB[callRequestsRouteStore.activeListTab] === action;
      if (isCallRequestBeingDisplayed || shouldCallRequestBeDisplayed) {
        listCallRequests();
      }
    }
  });
};

export default patientCallRequestsCreateHandler;
