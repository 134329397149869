import stores from 'bootstrap/store';

import getIsPendingDispenseAttemptBeingDisplayed from '../../helpers/getIsPendingDispenseAttemptBeingDisplayed';

import { EVENT } from '../../constants';

const dispenseAttemptsInactiveHandler = ({ apiWebSocket }) => {
  apiWebSocket.on(EVENT.RECEIVED.DISPENSE_ATTEMPT_INACTIVE, ({ dispenseAttemptId }) => {
    const isPendingDispenseAttemptBeingDisplayed = getIsPendingDispenseAttemptBeingDisplayed(dispenseAttemptId);
    if (isPendingDispenseAttemptBeingDisplayed) {
      stores.global.dispenseAttempts.deleteDispenseAttempt(dispenseAttemptId);
    }
  });
};

export default dispenseAttemptsInactiveHandler;
