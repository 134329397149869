import React from 'react';
import { observer } from 'mobx-react';
import { get as _get } from 'lodash';

// Components
import InfoCard from './components/info-card';

// Helpers
import formatLocation from './helpers/formatLocation';
import formatPhoneNumber from 'utils/formatPhoneNumber';

// Icons
import activePatientIcon from 'assets/icons/patient-active.svg';
import drugIcon from 'assets/icons/drug-active.svg';
import physicianIcon from 'assets/icons/physician-active.svg';
import phoneSvg from 'assets/icons/blue-phone.svg';
import locationSvg from 'assets/icons/location-active.svg';
import nameIcon from 'assets/icons/name-icon-active.svg';
import moneyActiveIcon from 'assets/icons/money-active.svg';

import './prescription-info-cards-style.scss';

const PrescriptionStatusManipulation = ({
  activePrescriptionFill,
  getLocation,
  shouldDisplayPayment,
}) => {
  if (!getLocation) return null;

  const location = getLocation(activePrescriptionFill);
  const patientPhoneNumber = _get(activePrescriptionFill, 'prescription.patient.phoneNumber') || _get(activePrescriptionFill, 'prescription.patient.patientUser.user.phoneNumber') || 'N/A';

  const locationName = _get(location, 'title');
  const pharmacyPhoneNumber = _get(location, 'raw.formatted_phone_number');

  const prescriptionFillQuantity = _get(activePrescriptionFill, 'quantity') || _get(activePrescriptionFill, 'prescription.drug.quantity') || 'N/A';

  const isPaidFor = !Number(_get(activePrescriptionFill, 'prescriptionFillsFulfillment.computed_remainingCopay', 0));

  return (
    <div className='c-PrescriptionFillInfoCards'>
      <div className='row'>
        <InfoCard
          mainIcon={activePatientIcon}
          title='Address'
          data={[
            {
              key: <img alt='Address' src={locationSvg} />,
              value: `${locationName ? `${ locationName } ` : ''}${formatLocation(location)}`
            },
            {
              key: <img alt='Phone' src={phoneSvg} />,
              value: pharmacyPhoneNumber ? formatPhoneNumber(pharmacyPhoneNumber) : 'N/A',
            }
          ]}
        />
      </div>
      <div className='row'>
        <InfoCard
          mainIcon={activePatientIcon}
          title='Patient'
          data={[
            {
              key: <img alt='Name' src={nameIcon} />,
              value: `${activePrescriptionFill.prescription.patient.firstName} ${activePrescriptionFill.prescription.patient.lastName}`
            },
            {
              key: <img alt='Phone Number' src={phoneSvg} />,
              value: patientPhoneNumber ? formatPhoneNumber(patientPhoneNumber) : 'N/A',
            },
            {
              key: <img alt='Patient Address' src={locationSvg} />,
              value: _get(activePrescriptionFill, 'mailOrder.patientAddress.location')
                ? formatLocation(_get(activePrescriptionFill, 'mailOrder.patientAddress.location'))
                : 'N/A'
            }
          ]}
        />
        <InfoCard
          mainIcon={drugIcon}
          title='Drug'
          data={[
            {
              key: 'Name',
              value: activePrescriptionFill.prescription.drug.name
            },
            {
              key: 'NDC',
              value: activePrescriptionFill.prescription.drug.NDC
            },
            {
              key: 'Quantity',
              value: prescriptionFillQuantity
            },
          ]}
        />
        <InfoCard
          mainIcon={physicianIcon}
          title='Physician'
          data={[
            {
              key: <img alt='Name' src={nameIcon} />,
              value: `${activePrescriptionFill.prescription.physician.firstName} ${activePrescriptionFill.prescription.physician.lastName}`,
            },
            {
              key: <img alt='Phone Number' src={phoneSvg} />,
              value: _get(activePrescriptionFill.prescription, 'physician.physicianUser.user.phoneNumber')
                ? formatPhoneNumber(_get(activePrescriptionFill.prescription, 'physician.physicianUser.user.phoneNumber'))
                : 'N/A',
            },
            {
              key: <img alt='Address' src={locationSvg} />,
              value: _get(activePrescriptionFill.prescription, 'physician.healthcareSites[0].location')
                ? formatLocation(_get(activePrescriptionFill.prescription, 'physician.healthcareSites[0].location'))
                : 'N/A'
            }
          ]}
        />
      </div>
      {
        shouldDisplayPayment &&
        <div className='row'>
          <InfoCard
            mainIcon={moneyActiveIcon}
            title='Payment'
            data={[
              {
                key: 'copay',
                value: `$${activePrescriptionFill.copay}`
              },
              {
                key: 'paid',
                color: isPaidFor ? 'green' : 'red',
                value: isPaidFor ? 'Yes' : 'No',
              }
            ]}
          />
        </div>
      }
    </div>
  );
};


export default observer(PrescriptionStatusManipulation);
