import { prescriptionFillsManualEntryRouteStore } from '../domain/store';

const onPhysicianSuggestionClick = (physician, filteredField) => {
  const { firstName, lastName, NPI, SPI, stateLicenseNumber, externalPharmacyId } = physician;

  if (physician[filteredField]) {
    prescriptionFillsManualEntryRouteStore.setValue(`physician.${filteredField}`, physician[filteredField]);
  }

  if (!prescriptionFillsManualEntryRouteStore.physician.firstName) {
    prescriptionFillsManualEntryRouteStore.setValue('physician.firstName', firstName);
  }

  if (!prescriptionFillsManualEntryRouteStore.physician.lastName) {
    prescriptionFillsManualEntryRouteStore.setValue('physician.lastName', lastName);
  }

  if (!prescriptionFillsManualEntryRouteStore.physician.externalPharmacyId && externalPharmacyId) {
    prescriptionFillsManualEntryRouteStore.setValue('physician.externalPharmacyId', externalPharmacyId);
  }

  if (!prescriptionFillsManualEntryRouteStore.physician.NPI && NPI) {
    prescriptionFillsManualEntryRouteStore.setValue('physician.NPI', NPI);
  }

  if (!prescriptionFillsManualEntryRouteStore.physician.SPI && SPI) {
    prescriptionFillsManualEntryRouteStore.setValue('physician.SPI', SPI);
  }

  if (!prescriptionFillsManualEntryRouteStore.physician.stateLicenseNumber && stateLicenseNumber) {
    prescriptionFillsManualEntryRouteStore.setValue('physician.stateLicenseNumber', stateLicenseNumber);
  }

  prescriptionFillsManualEntryRouteStore.setValue('suggestions.physician', {});
};

export default onPhysicianSuggestionClick;
