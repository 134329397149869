import React from 'react';
import { observer } from 'mobx-react';


const HealthcareSitesListHeader = ({
  healthcareSite,
}) => {
  return <React.Fragment>
    <div className='title'>
      {
        healthcareSite.name
      }
    </div>
  </React.Fragment>;
};

export default observer(HealthcareSitesListHeader);
