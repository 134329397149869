import React from 'react';
import { observer } from 'mobx-react';

// Components
import Content from './components/content';
import DrillDownLayout from 'components/drill-down-layout';
import List from './components/list';

// Styles
import './kiosks-view.scss';

const KiosksView  = ({
  kiosksRouteStore,
  onListBottomThreshold,
  setFilterValue,
  rootRoute,
}) => {
  return <div className='r-KiosksView'>
    <DrillDownLayout>
      <List
        kiosksRouteStore={kiosksRouteStore}
        setFilterValue={setFilterValue}
        onListBottomThreshold={onListBottomThreshold}
        rootRoute={rootRoute}
      />
      <Content
        activeKiosk={kiosksRouteStore.activeKiosk}
        activeTab={kiosksRouteStore.activeTab}
      />
    </DrillDownLayout>
  </div>;
};


export default observer(KiosksView);
