import deepFreeze from 'deep-freeze';

export const MODEL_NAME = Object.freeze({
  SINGULAR: 'physician',
  PLURAL: 'physicians',
});

export const FIELD_NAME = Object.freeze({
  ID: 'id',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  NPI: 'NPI',
  SPI: 'SPI',
  STATE_LICENSE_NUMBER: 'stateLicenseNumber',
  CREATED_AT: 'createdAt',
  UPDATED_AT: 'updatedAt',
  PHYSICIAN_USER: 'physicianUser',
  USER_ID: 'userId',
  USER: 'user',
  EMAIL: 'email',
  PHONE_NUMBER: 'phoneNumber',
  PHARMACY_USER: 'pharmacyUser',
  PHARMACY: 'pharmacy',
  HEALTHCARE_SITES: 'healthcareSites',
  LOCATION: 'location',
  TITLE: 'title',
  CITY: 'city',
  STREET1: 'street1',
  STATE: 'state',
  COUNTRY: 'country',
  ZIP: 'zip',
  NAME: 'name'
});

export const SCHEMA = deepFreeze({
  title: MODEL_NAME.SINGULAR,
  type: 'object',
  properties: {
    [FIELD_NAME.ID]: {
      type: 'string'
    },
    [FIELD_NAME.FIRST_NAME]: {
      type: 'string'
    },
    [FIELD_NAME.LAST_NAME]: {
      type: 'string'
    },
    [FIELD_NAME.NPI]: {
      type: 'string'
    },
    [FIELD_NAME.SPI]: {
      type: 'string'
    },
    [FIELD_NAME.STATE_LICENSE_NUMBER]: {
      type: 'string'
    },
    [FIELD_NAME.USER_ID]: {
      type: 'string'
    },
    [FIELD_NAME.PHYSICIAN_USER]: {
      type: 'object',
      properties: {
        [FIELD_NAME.ID]: {
          type: 'string'
        },
        [FIELD_NAME.USER_ID]: {
          type: 'string'
        },
        [FIELD_NAME.USER]: {
          type: 'object',
          properties: {
            [FIELD_NAME.ID]: {
              type: 'string'
            },
            [FIELD_NAME.FIRST_NAME]: {
              type: 'string'
            },
            [FIELD_NAME.LAST_NAME]: {
              type: 'string'
            },
            [FIELD_NAME.EMAIL]: {
              type: 'string'
            },
            [FIELD_NAME.PHONE_NUMBER]: {
              type: 'string'
            },
            [FIELD_NAME.PHARMACY_USER]: {
              type: 'object',
              properties: {
                [FIELD_NAME.PHARMACY]: {
                  type: 'object',
                  properties: {
                    [FIELD_NAME.ID]: {
                      type: 'string'
                    },
                    [FIELD_NAME.NAME]: {
                      type:'string'
                    },
                    [FIELD_NAME.PHONE_NUMBER]: {
                      type: 'string'
                    },
                    [FIELD_NAME.HEALTHCARE_SITES]: {
                      type: 'array',
                      items: {
                        type: 'object',
                        properties: {
                          [FIELD_NAME.ID]: {
                            type: 'string'
                          },
                          [FIELD_NAME.NAME]: {
                            type:'string'
                          },
                          [FIELD_NAME.PHONE_NUMBER]: {
                            type: 'string'
                          },
                          [FIELD_NAME.LOCATION]: {
                            type: 'object',
                            properties: {
                              [FIELD_NAME.TITLE]: {
                                type: 'string'
                              },
                              [FIELD_NAME.STREET1]: {
                                type: 'string'
                              },
                              [FIELD_NAME.STATE]: {
                                type: 'string'
                              },
                              [FIELD_NAME.COUNTRY]: {
                                type: 'string'
                              },
                              [FIELD_NAME.ZIP]: {
                                type: 'string'
                              },
                              [FIELD_NAME.CITY]: {
                                type: 'string'
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
  }
});
