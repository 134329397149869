import React from 'react';
import { observer } from 'mobx-react';

// Icons
import OnlineImage from 'assets/icons/online.svg';
import OfflineImage from 'assets/icons/offline.svg';

// Styles
import './status-styles.scss';

const Status = ({
  isOnline,
}) => (
  <div className='c-KioskListItemStatus'>
    {
      isOnline
        ? <img alt='Online' className='status-image' src={OnlineImage}/>
        : <img alt='Offline' className='status-image' src={OfflineImage}/>
    }
  </div>
);

export default observer(Status);
