import { keyBy as _keyBy } from 'lodash';

import { MAX_ENTRIES_PER_PAGE } from 'models/prescription-fills/constants';

import stores from 'bootstrap/store';
import { prescriptionFillsRouteStore } from '../domain/store';
const prescriptionFillsStore = stores.global.prescriptionFills;


export default ({ filter, refetch = false, merge = false, signal, controlledAbort = false } = {}) => {
  const query = {};
  if (refetch) {
    query.offset = 0;
  } else {
    query.offset = prescriptionFillsRouteStore.prescriptionFillsById.size;
    query.limit = MAX_ENTRIES_PER_PAGE;
  }

  if (filter) {
    query.filter = filter;
  }
  if (prescriptionFillsRouteStore.dateRange.start) {
    query.startDate = new Date(prescriptionFillsRouteStore.dateRange.start).toISOString();
  }
  if (prescriptionFillsRouteStore.dateRange.end) {
    query.endDate = new Date(prescriptionFillsRouteStore.dateRange.end).toISOString();
  }

  return prescriptionFillsStore.listPrescriptionFills({ query, signal, controlledAbort })
  .then(({ data: prescriptionFills }) => {
    const prescriptionFillsById = _keyBy(prescriptionFills, 'id');
    if (merge) {
      prescriptionFillsRouteStore.mergePrescriptionFillsById(prescriptionFillsById);
    } else {
      prescriptionFillsRouteStore.setPrescriptionFillsById(prescriptionFillsById);
    }

    return prescriptionFills;
  });
};
