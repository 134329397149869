import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import ErroredKioskNotificationsView from './errored-kiosk-notifications-view';

import { NOTIFICATION_TYPE } from '../../../../constants';

import goToKioskStatusesTab from './helpers/goToKioskStatusesTab';
import { fetchNotificationsRelatedData } from '../../../../helpers';

import stores from 'bootstrap/store';


const ErroredKioskNotificationsContainer = () => {
  useEffect(() => {
    fetchNotificationsRelatedData(NOTIFICATION_TYPE.ERRORED_KIOSKS);
  }, []);

  return <ErroredKioskNotificationsView
    erroredKiosks={stores.global.kiosks.erroredList}
    goToKioskStatusesTab={goToKioskStatusesTab}
  />;
};

export default observer(ErroredKioskNotificationsContainer);
