import React from 'react';
import { observer } from 'mobx-react';

import CallInfo from '../call-info';

import './active-video-conference-style.scss';

const ActiveVideoConferenceNotification = ({ videoConference }) => (
  <div key={videoConference.id} className='c-ActiveVideoConferenceNotification'>
    <CallInfo
      videoConference={videoConference}
    />
    <div className='active-sign' />
  </div>
);

export default observer(ActiveVideoConferenceNotification);
