import React from 'react';
import { observer } from 'mobx-react';

import SpecialComponents from './specialComponents';

import doesValueMatchRegex from 'utils/doesValueMatchRegex';

import './index.scss';

const CaptionValueInput = ({
  type = 'text',
  className,
  inputFieldClassNames = [],
  placeholder,
  caption,
  value,
  validationRegex,
  validationError,
  onChange,
  onBlur,
  required,
  ...specProps
}) => {
  let SpecialComponent;
  const props = {
    type,
    placeholder,
    className,
    inputFieldClassName: inputFieldClassNames.join(' '),
    value: value !== null && value !== undefined ? value : '',
    onChange,
    onBlur,
    autoComplete: 'new-password',
    required,
    ...specProps,
  };

  const isCurrentValueValid = doesValueMatchRegex(validationRegex, value);
  const isRequired = !isCurrentValueValid || Boolean(props.required && !value);

  if (SpecialComponents[type]) SpecialComponent = SpecialComponents[type].component;

  return (
    <div className={`c-CaptionValueInput ${props.className && props.className}`}>
      {
        SpecialComponent
          ? <SpecialComponent
            {...props}
            {
              ...(
                SpecialComponents[type].specialProps
                  ? SpecialComponents[type].specialProps({ ...props, isRequired })
                  : {}
              )
            }
          />
          : <input
            {...props}
            className={`${isRequired ? 'input-required': 'input'} ${props.inputFieldClassName}`}
            onChange={(e) => props.onChange(e.target.value)}
          />
      }
      {
        !isCurrentValueValid && validationError &&
        <div className='validation-error'>
          {
            validationError
          }
        </div>
      }
      <div className='caption'>
        {
          caption
        }
      </div>
    </div>
  );
};

export default observer(CaptionValueInput);
