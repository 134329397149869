import { toJS } from 'mobx';
import { get as _get } from 'lodash';

import { healthcareSitesRouteStore } from '../domain/store';
import { healthcareSiteUpsertModalRouteStore } from '../components/healthcare-site-upsert-modal/domain/store';

import {
  HEALTHCARE_SITE_MANIPULATION_OPTIONS,
  HEALTHCARE_SITE_SKELETON,
} from '../constants';

const { CREATE, UPDATE } = HEALTHCARE_SITE_MANIPULATION_OPTIONS;

export default (e, purpose = UPDATE) => {
  if (purpose === UPDATE) {
    const activeHealthcareSiteCopy = toJS(healthcareSitesRouteStore.activeHealthcareSite);
    const locationLabel = `${_get(activeHealthcareSiteCopy, 'location.street1', '')} ${_get(activeHealthcareSiteCopy, 'location.city', '')} ${_get(activeHealthcareSiteCopy, 'location.state', '')}`;
    healthcareSiteUpsertModalRouteStore.setValue('activelyModifiedHealthcareSite', activeHealthcareSiteCopy);
    healthcareSiteUpsertModalRouteStore.setValue('locationSelector', {
      label: locationLabel,
      value: activeHealthcareSiteCopy.locationId,
    });
    healthcareSiteUpsertModalRouteStore.setValue('locationSelectorFilter', locationLabel);
    healthcareSitesRouteStore.setValue('showingHealthcareSiteUpsertModal', true);
  } else if (purpose === CREATE) {
    healthcareSitesRouteStore.setValue('showingHealthcareSiteUpsertModal', true);
    healthcareSiteUpsertModalRouteStore.setValue('activelyModifiedHealthcareSite', HEALTHCARE_SITE_SKELETON);
  }
};
