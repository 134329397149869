// Constants
import { CALL_REQUESTS_ROUTE } from '../constants';

// Stores
import stores from 'bootstrap/store';
import { callRequestsRouteStore} from '../domain/store';
const callRequestsStore = stores.global.callRequests;
const { router } = stores;

const fetchActivePatientCallRequestById = () => {
  if (callRequestsRouteStore.http.fetchingCallRequestById) {
    return;
  }

  stores.global.callRequests.setValue('activeCallRequest', null);

  const { activeCallRequestId } = callRequestsRouteStore;
  if (!activeCallRequestId) {
    return;
  }

  const config = {
    params: {
      callRequestId: activeCallRequestId,
    },
  };

  callRequestsRouteStore.incrementHttp('fetchingCallRequestById');
  return callRequestsStore.fetchById(config)
  .catch(() => router.history.push(CALL_REQUESTS_ROUTE))
  .finally(() => callRequestsRouteStore.decrementHttp('fetchingCallRequestById'));
};

export default fetchActivePatientCallRequestById;
