import stores from 'bootstrap/store';

import { EVENT } from '../../constants';

import { fetchComputedInventoryForModification } from 'models/kiosks/helpers';

export default ({ apiWebSocket }) => {
  const kiosksRouteStore = stores.route.kiosks.domain;

  apiWebSocket.on(EVENT.RECEIVED.KIOSK_DRUG_THRESHOLDS_UPSERT, (payload = {}) => {
    const { kioskId } = payload;
    if (!kioskId) return;

    const { activeKioskId } = kiosksRouteStore;
    if (!activeKioskId || activeKioskId !== kioskId) return;

    return fetchComputedInventoryForModification();
  });
};
