import React from 'react';

export const COLUMNS = [
  {
    name: 'Drug',
    fieldName: 'drug',
    isSortable: false,
    width: '80%',
    render: ({ content }) => (
      <div>
        <div title={content.drugName} className='drug-name'>
          {
            content.drugName
          }
        </div>
        <div>
          <span className='NDC'>
              Label NDC:
          </span>
          {
            content.labelNDC
          }
          <span className='quantity'>
              QTY:
          </span>
          {
            content.quantity
          }
        </div>
      </div>
    )
  },
  {
    name: 'Stocked Containers',
    fieldName: 'count',
    isSortable: false,
    width: '20%',
    render: ({ content }) => (
      <div title={content.count}>
        {
          content.count
        }  
      </div>
    )
  },
];
