import React from 'react';
import { observer } from 'mobx-react';

// Helpers
import { isTemperatureSafe, formatTemperature } from 'models/kiosks/helpers';

// Icons
import TemperatureIcon from 'assets/icons/temperature.svg';

// Styles
import './temperature-styles.scss';


const Temperature = ({
  temperature,
}) => {
  const isSafe = isTemperatureSafe(temperature);
  const formattedTemperature = formatTemperature(temperature);

  return <div className={`c-KioskListItemTemperature ${!isSafe && 'c-KioskListItemTemperature-red'}`}>
    {
      <img alt='tmp' className='temperature-image' src={TemperatureIcon}/>
    }
    {
      formattedTemperature ? formattedTemperature : 'N/A'
    }
  </div>;
};

export default observer(Temperature);
