import React from 'react';
import { observer } from 'mobx-react';

import ListHeader from 'components/drill-down-layout/components/list/components/list-header';

import { DISPENSE_ATTEMPTS_TABS_ARRAY } from '../../../../constants';

const DispenseAttemptsListHeader = ({
  dispenseAttemptsRouteStore,
  setFilterValue,
  setActiveDispenseAttemptTab,
}) => (
  <ListHeader
    title='Dispense Attempts'
    // Search
    searchValue={dispenseAttemptsRouteStore.filterValue}
    onSearch={e => setFilterValue(e)}
    // Tabs
    tabValues={DISPENSE_ATTEMPTS_TABS_ARRAY}
    activeTab={dispenseAttemptsRouteStore.dispenseAttemptsTab}
    onTabSelect={setActiveDispenseAttemptTab}
  />
);

export default observer(DispenseAttemptsListHeader);
