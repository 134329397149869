import { types } from 'mobx-state-tree';


export const ChangePasswordRouteStore = types.model({
  password: types.optional(types.string, ''),
  passwordConfirm: types.optional(types.string, ''),
  errorMessage: types.optional(types.string, ''),
})
.actions(self => ({
  setValue: (prop, value) => {
    if (self.errorMessage) self.errorMessage = '';
    self[prop] = value;
  },
  setInitialState: () => {
    self.password = '';
    self.passwordConfirm = '';
    self.errorMessage = '';
  }
}));

export const changePasswordRouteStore = ChangePasswordRouteStore.create({});
