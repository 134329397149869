import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';

// Views
import CallRequestsView from './call-requests-view';

// Helpers
import {
  createPharmacistAction,
  determineActivePatientCallRequest,
  fetchActivePatientCallRequestById,
  listCallRequests,
  setFilterValue,
  setActiveListTab,
} from './helpers';

// View Store
import { callRequestsRouteStore } from './domain/store';

// Global Stores
import stores from 'bootstrap/store';
const callRequestsStore = stores.global.callRequests;


const CallRequestsContainer = ({ rootRoute }) => {
  const navigate = useNavigate();
  const { callRequestId } = useParams();

  useEffect(() => {
    listCallRequests();

    return () => {
      callRequestsRouteStore.setInitialState();
      callRequestsStore.setValue('activeCallRequest', null);
      callRequestsStore.setValue('dataForPharmacist', {});
    };
  }, []);

  useEffect(() => {
    if (!callRequestsRouteStore.http.listingCallRequests) {
      determineActivePatientCallRequest(callRequestId, navigate);
    }
  }, [callRequestId, callRequestsRouteStore.http.listingCallRequests]);

  useEffect(() => {
    if (callRequestsRouteStore.activeCallRequestId) {
      fetchActivePatientCallRequestById();
    }
  }, [callRequestsRouteStore.activeCallRequestId]);

  return (
    <CallRequestsView
      callRequestsStore={callRequestsStore}
      callRequestsRouteStore={callRequestsRouteStore}
      setFilterValue={setFilterValue}
      setActiveListTab={setActiveListTab}
      listCallRequests={listCallRequests}
      createPharmacistAction={createPharmacistAction}
      rootRoute={rootRoute}
    />
  );
};


export default observer(CallRequestsContainer);
