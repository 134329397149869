import React from 'react';
import * as PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import Select from 'react-select';

import './index.scss';

const CaptionValueInputWithSelect = ({
  inputFieldClassNames,
  clearable,
  placeholder,
  caption,
  value,
  onChange,
  items,
  onFocus,
  onInputChange,
  loadMore,
  wrapperCustomStyles,
}) => (
  <div className='c-CaptionValueInputWithSelect' style={wrapperCustomStyles}>
    <Select
      className={inputFieldClassNames.join(' ')}
      isClearable={clearable}
      placeholder={placeholder}
      value={value}
      options={items}
      onChange={onChange}
      onFocus={onFocus}
      onInputChange={onInputChange}
      onMenuScrollToBottom={loadMore}
      scrollMenuIntoView={false}
    />
    {
      caption &&
      <div className='caption'>
        {
          caption
        }
      </div>
    }
  </div>
);

CaptionValueInputWithSelect.propTypes = {
  inputFieldClassNames: PropTypes.arrayOf(PropTypes.string),
  clearable: PropTypes.bool,
  caption: PropTypes.string,
  value: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  items: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  onFocus: PropTypes.func,
  onInputChange: PropTypes.func,
  loadMore: PropTypes.func,
  wrapperCustomStyles: PropTypes.object,
};

CaptionValueInputWithSelect.defaultProps = {
  clearable: true,
  caption: '',
  inputFieldClassNames: [],
};

export default observer(CaptionValueInputWithSelect);
