// Helpers
import listMachineTechniciansDebounce from './listMachineTechniciansDebounce';

// Stores
import stores from 'bootstrap/store';
import { machineTechniciansRouteStore } from '../domain/store';
const machineTechniciansStore = stores.global.machineTechnicians;

export default (e) => {
  machineTechniciansRouteStore.setValue('filterValue', e.target.value);
  machineTechniciansRouteStore.setValue('currentPage', 1);
  machineTechniciansStore.setValue('machineTechnicians', {});

  listMachineTechniciansDebounce();
};
