import React from 'react';
import { observer } from 'mobx-react';

// Icons
import ChevronTopIcon from 'assets/icons/chevron-top.svg';
import ChevronBottomIcon from 'assets/icons/chevron-bottom.svg';

// Styles
import './threshold-modifiers-styles.scss';

const Threshold = ({
  type,
  threshold,
  drugId,
  setInventoryThreshold,
}) => (
  <div className='c-Threshold'>
    <div className='threshold'>
      {
        threshold
      }
    </div>
    <div className='modifiers'>
      <img
        alt='Increase'
        className='modifier-icon'
        src={ChevronTopIcon}
        onClick={() => setInventoryThreshold({ drugId, type, threshold: threshold + 1 })}
      />
      <img
        alt='Decrease'
        className='modifier-icon'
        src={ChevronBottomIcon}
        onClick={() => setInventoryThreshold({ drugId, type, threshold: threshold - 1 })}
      />
    </div>
  </div>
);

export default observer(Threshold);
