import React from 'react';
import { observer } from 'mobx-react';

import Content from 'components/drill-down-layout/components/content';
import ContentTab from 'components/drill-down-layout/components/content/components/content-tab';
import Header from './components/header';

import InfoRoute from '../../routes/info';
import DrugsRoute from '../../routes/drugs';

import { STOCKING_BATCHES } from 'constants/routes';

const StockingBatchesContent = ({ stockingBatchesRouteStore }) => {
  const { activeStockingBatchId, activeStockingBatch, isBusy } = stockingBatchesRouteStore;

  return <Content
    header={<Header
      activeStockingBatch={activeStockingBatch}
    />}
    isReady={activeStockingBatchId && !isBusy}
  >
    <ContentTab title={STOCKING_BATCHES.CONTENT_TAB.INFO.TITLE} route={STOCKING_BATCHES.CONTENT_TAB.INFO.VALUE}>
      <InfoRoute
        activeStockingBatch={activeStockingBatch}
      />
    </ContentTab>
    <ContentTab title={STOCKING_BATCHES.CONTENT_TAB.DRUGS.TITLE} route={STOCKING_BATCHES.CONTENT_TAB.DRUGS.VALUE}>
      <DrugsRoute
        activeStockingBatch={activeStockingBatch}
      />
    </ContentTab>
  </Content>;
};

export default observer(StockingBatchesContent);
