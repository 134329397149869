import React from 'react';
import _startCase from 'lodash/startCase';
import { observer } from 'mobx-react';
import { ArcherContainer, ArcherElement } from 'react-archer';

import ContentGate from 'components/content-gate';

import { PRESCRIPTION_STAT_INVERTED_ENTITIES, PRESCRIPTION_STAT_ORDERED_ENTITIES } from './constants';

import './stats-category-styles.scss';

const StatsCategory = ({ invert = false, type, unpacked }) => {
  const capitalized = _startCase(type);
  const entities = invert ? PRESCRIPTION_STAT_INVERTED_ENTITIES : PRESCRIPTION_STAT_ORDERED_ENTITIES;

  return <ContentGate className='c-PrescriptionFillStatsCategory' active={!unpacked}>
    <div key={type} className='unpacked-category'>
      <div className='stats-container'>
        <ArcherContainer lineStyle='angle' strokeColor='black' endMarker={false} startMarker={false}>
          {
            unpacked[type][entities[0].fieldName].map((firstEntityObj) => {
              const {
                getName: getFirstEntityObjName,
                icon: firstEntityIcon,
              } = entities[0];

              return <div key={firstEntityObj.data.id} className='parent-entity-container'>
                <div className='first-entity-container'>
                  <div className='first-entity-details'>
                    <ArcherElement
                      id={firstEntityObj.data.id}
                      relations={firstEntityObj[entities[1].fieldName].map((secondEntityObj) => ({
                        targetId: `${firstEntityObj.data.id}/${secondEntityObj.data.id}`,
                        targetAnchor: 'left',
                        sourceAnchor: 'right',
                      }))}
                    >
                      <div className='first-entity-name'>
                        <img alt={firstEntityIcon.alt} src={firstEntityIcon.src} />
                        {
                          getFirstEntityObjName(firstEntityObj.data)
                        }
                      </div>
                    </ArcherElement>
                    <div className='first-entity-count'>
                      {firstEntityObj.count} {capitalized} (${firstEntityObj.copay})
                    </div>
                  </div>
                </div>
                <div className='second-entities-container'>
                  {
                    firstEntityObj[entities[1].fieldName].map((secondEntityObj) => {
                      const {
                        getName: getSecondEntityObjName,
                        icon: secondEntityIcon,
                      } = entities[1];
                      const secondEntityKey = `${firstEntityObj.data.id}/${secondEntityObj.data.id}-container`;

                      return <ArcherElement key={secondEntityKey} id={secondEntityKey}>
                        <div key={secondEntityKey} className='unpacked-second-entity'>
                          <div className='second-entity-container'>
                            <div className='second-entity-details'>
                              <ArcherElement
                                id={`${firstEntityObj.data.id}/${secondEntityObj.data.id}`}
                                relations={secondEntityObj[entities[2].fieldName].map((thirdEntityObj) => ({
                                  targetId: `${firstEntityObj.data.id}/${secondEntityObj.data.id}/${thirdEntityObj.data.id}`,
                                  targetAnchor: 'left',
                                  sourceAnchor: 'right',
                                }))}>
                                <div className='second-entity-name'>
                                  <img alt={secondEntityIcon.alt} src={secondEntityIcon.src} />
                                  {
                                    getSecondEntityObjName(secondEntityObj.data)
                                  }
                                </div>
                              </ArcherElement>
                              <div className='second-entity-count'>{secondEntityObj.count} {capitalized} (${secondEntityObj.copay})</div>
                            </div>
                            
                          </div>
                          <div className='third-entities-container'>
                            {
                              secondEntityObj[entities[2].fieldName].map((thirdEntityObj) => {
                                const {
                                  getName: getThirdEntityObjName,
                                  icon: thirdEntityIcon,
                                } = entities[2];
                                const thirdEntityKey = `${firstEntityObj.data.id}/${secondEntityObj.data.id}/${thirdEntityObj.data.id}`;
      
                                return <div key={thirdEntityKey} className='unpacked-third-entity'>
                                  <div className='third-entity-container'>
                                    <div className='third-entity-details'>
                                      <ArcherElement id={thirdEntityKey}>
                                        <div className='third-entity-name'>
                                          <img alt={thirdEntityIcon.alt} src={thirdEntityIcon.src} />
                                          {
                                            getThirdEntityObjName(thirdEntityObj.data)
                                          }
                                        </div>
                                      </ArcherElement>
                                      <div className='third-entity-count'>{thirdEntityObj.count} {capitalized} (${thirdEntityObj.copay})</div>
                                    </div>
                                  </div>
                                </div>;
                              })
                            }
                          </div>
                        </div>
                      </ArcherElement>;
                    })
                  }
                </div>
                  
              </div>;
            })
          }
        </ArcherContainer>
      </div>
    </div>
  </ContentGate>;
};

export default observer(StatsCategory);
