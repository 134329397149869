import React from 'react';
import { observer } from 'mobx-react';
import { get as _get } from 'lodash';

import MailIcon from 'assets/icons/mail.svg';
import ContactIcon from 'assets/icons/contact.svg';
import KioskIcon from 'assets/icons/kiosk-active.svg';

import './contact-style.scss';

const ContactView = ({
  activeTechnician,
}) => {
  if (!activeTechnician) return null;
  const user = _get(activeTechnician, 'mtUser.user', {});
  const kiosks = _get(activeTechnician, 'kiosks', []);

  return (
    <div className='c-TechnicianContact'>
      <div className='data'>
        <div className='data-row'>
          <div className='name'>
            <img alt='phone' src={ContactIcon} className='icon'/>
            <div className='text'>Phone</div>
          </div>
          <div className='value'>
            {user.phoneNumber || 'N/A'}
          </div>
        </div>
        <div className='data-row'>
          <div className='name'>
            <img alt='email' src={MailIcon} className='icon'/>
            <div className='text'>Email</div>
          </div>
          <div className='value'>
            {user.email || 'N/A'}
          </div>
        </div>
        {
          !!kiosks.length &&
          <div className='data-row'>
            <div className='name'>
              <img alt='kiosk' src={KioskIcon} className='icon'/>
              <div className='text'>Kiosks</div>
            </div>
            <div className='value-column'>
              {
                kiosks.map((kiosk) => (
                  <div key={kiosk.id} className='value'>
                    {
                      kiosk.name
                    }
                  </div>
                ))
              }
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default observer(ContactView);
