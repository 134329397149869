import { listPatients } from './index';

import stores from 'bootstrap/store';
import { patientsIncompleteInfoRouteStore } from '../domain/store';
const patientsStore = stores.global.patients;

export default () => {
  const { activePatientId } = patientsIncompleteInfoRouteStore;
  if (!activePatientId) return;

  return patientsStore.updatePharmacyDataCaptured({
    params: {
      patientId: activePatientId,
    },
    body: {
      pharmacyDataCaptured: true,
    },
  })
  .then(() => {
    patientsStore.setValue('dataForPharmacist', {});
    return listPatients();
  });
};
