// Utils
import retrieveUuid from 'utils/retrieveUuid';

// Stores
import stores from 'bootstrap/store';
const dispenseAttemptsStore = stores.global.dispenseAttempts;
const router = stores.router;

const fetchRequestedDispenseAttempt = () => {
  const { location } = router;
  const dispenseAttemptId = retrieveUuid(location.pathname);
  if (!dispenseAttemptId) return Promise.resolve();

  return dispenseAttemptsStore.fetchById({
    params: { id: dispenseAttemptId },
  });
};

export default fetchRequestedDispenseAttempt;
