import {
  closePharmacyTechnicianUpsertModal,
  listPharmacyTechnicians,
} from './index';

import stores from 'bootstrap/store';
import { pharmacyTechniciansRouteStore } from '../domain/store';
const pharmacyTechniciansStore = stores.global.pharmacyTechnicians;

export default () => {
  return pharmacyTechniciansStore.deletePharmacyTechnician({
    params: {
      pharmacyTechnicianId: pharmacyTechniciansRouteStore.activeTechnicianId,
    },
  })
  .then(() => {
    closePharmacyTechnicianUpsertModal();
    return listPharmacyTechnicians();
  });
};
