// Constants
import {
  MAX_TABLE_ENTRIES_PER_PAGE,
  ORDER_OPTIONS,
} from 'components/admin-technicians-view/constants';

// Stores
import stores from 'bootstrap/store';
import { machineTechniciansRouteStore } from '../domain/store';
const machineTechniciansStore = stores.global.machineTechnicians;


export default () => {
  const query = {
    offset: MAX_TABLE_ENTRIES_PER_PAGE * (machineTechniciansRouteStore.currentPage - 1),
    limit: MAX_TABLE_ENTRIES_PER_PAGE,
  };

  if (machineTechniciansRouteStore.filterValue) {
    query.filter = {
      firstName: machineTechniciansRouteStore.filterValue,
      lastName: machineTechniciansRouteStore.filterValue,
    };
  }

  if (machineTechniciansRouteStore.sortingColumn.fieldName && machineTechniciansRouteStore.sortingColumn.sortingOrder) {
    query.order = ORDER_OPTIONS[machineTechniciansRouteStore.sortingColumn.fieldName](machineTechniciansRouteStore.sortingColumn.sortingOrder);
  }

  const listTechnicians = () => {
    machineTechniciansRouteStore.incrementHttp('listingTechnicians');
    machineTechniciansStore.listMachineTechniciansForAdmin({ query })
    .then(() => machineTechniciansRouteStore.decrementHttp('listingTechnicians'));
  };

  const countTechnicians = () => {
    machineTechniciansRouteStore.incrementHttp('countingTechnicians');
    machineTechniciansStore.countMachineTechnicians({ query })
    .then(() => machineTechniciansRouteStore.decrementHttp('countingTechnicians'));
  };

  return Promise.all([
    listTechnicians(),
    countTechnicians(),
  ]);
};
