import React from 'react';
import { observer } from 'mobx-react';

// View
import ActionsHistoryView from './actions-history-view';

const ActionsHistoryContainer = ({ activeCallRequest, fetchingActiveCallRequest }) => {
  return <ActionsHistoryView
    activeCallRequest={activeCallRequest}
    fetchingActiveCallRequest={fetchingActiveCallRequest}
  />;
};

export default observer(ActionsHistoryContainer);
