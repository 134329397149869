import { types } from 'mobx-state-tree';
import * as _ from 'lodash';
import moment from 'moment';

import { TABLE_COLUMNS_NAMES, TABLE_COLUMNS_INFO, DIRECTIONS } from '../constants';
const VideoCallLogTableHeaders = _.values(TABLE_COLUMNS_NAMES).filter(col => TABLE_COLUMNS_INFO[col].isSortable);

export const VideoCallLogRouteStore = types.model({
  dateFilter: types.optional(types.frozen(), moment()),
  tableActiveHeader: types.optional(types.string, VideoCallLogTableHeaders[0]),
  tableSortDirection: types.optional(types.string, DIRECTIONS.ASC),
  currentPage: types.optional(types.number, 0),
  maxPages: types.optional(types.number, 0),
  paginatorDisplayPage: types.optional(types.number, 1),

})
.actions(self => ({
  setValue: (prop, value) => self[prop] = value
}))
.views(() => ({

}));



export const videoCallLogRouteStore = VideoCallLogRouteStore.create({});
