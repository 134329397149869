import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

// Views
import KioskStatusesView from './kiosk-statuses-view';

// Stores
import stores from 'bootstrap/store';
import { kioskStatusesRouteStore } from './domain/store';

// Hooks
import useInterval from 'hooks/useInterval';

// Helpers
import fetchActiveKioskStatuses from './helpers/fetchActiveKioskStatuses';

// Constants
import { REFRESH_KIOSK_STATUSES_INTERVAL } from './constants';


const KioskStatusesContainer = ({ activeKiosk }) => {
  useEffect(() => {
    fetchActiveKioskStatuses(activeKiosk);

    return () => {
      stores.global.kiosks.setValue('activeKioskStatuses', null);
      kioskStatusesRouteStore.reset();
    };
  }, []);

  useInterval(() => {
    fetchActiveKioskStatuses(activeKiosk);
  }, REFRESH_KIOSK_STATUSES_INTERVAL);

  return <KioskStatusesView
    activeKiosk={stores.global.kiosks.activeKioskStatuses}
    isFetchingKioskStatuses={Boolean(kioskStatusesRouteStore.http.fetchingKioskStatuses)}
    chartDataByKioskStatusField={kioskStatusesRouteStore.chartDataByKioskStatusField}
  />;
};

export default observer(KioskStatusesContainer);
