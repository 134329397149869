import React from 'react';
import { observer } from 'mobx-react';

import './modal-editing-areas-styles.scss';

const ModalEditingAreas = ({ children }) => {
  return (
    <div className='c-ModalEditingAreas'>
      {
        children
      }
    </div>
  );
};

export default observer(ModalEditingAreas);
