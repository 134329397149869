import { isBoolean as _isBoolean, isNumber as _isNumber, get as _get } from 'lodash';

import { formatTemperature, isKioskOnline, isTemperatureSafe } from 'models/kiosks/helpers';

import {
  MAX_SAFE_CPU_USAGE,
  MAX_SAFE_HUMIDITY,
  MAX_SAFE_MEMORY_USAGE,
} from 'models/kiosks/constants';

const COLOR = {
  GREEN: 'green',
  GREY: 'grey',
  RED: 'red',
};

const DEFAULT_DISPLAY_VALUES = {
  value: 'N/A',
  color: COLOR.GREY,
};

const DISPLAYING_RULES_GETTER = {
  createdAt: (value) => {
    const caption = 'Internet Connection';

    if (!value) {
      return { ...DEFAULT_DISPLAY_VALUES, caption };
    }

    if (isKioskOnline(value)) {
      return { value: 'Online', color: COLOR.GREEN, caption };
    } else {
      return { value: 'Offline', color: COLOR.RED, caption };
    }
  },
  isPrinterOnline: (value) => {
    const caption = 'Printer';

    if (!_isBoolean(value)) {
      return { ...DEFAULT_DISPLAY_VALUES, caption };
    }

    if (value) {
      return { value: 'Online', color: COLOR.GREEN, caption };
    } else {
      return { value: 'Offline', color: COLOR.RED, caption };
    }
  },
  isPaperInStock: (value) => {
    const caption = 'Label Roll';

    if (!_isBoolean(value)) {
      return { ...DEFAULT_DISPLAY_VALUES, caption };
    }

    if (value) {
      return { value: 'Ok', color: COLOR.GREEN, caption };
    } else {
      return { value: 'Empty', color: COLOR.RED, caption };
    }
  },
  isInFaultedState: (value) => {
    const caption = 'Faulted';

    if (!_isBoolean(value)) {
      return { ...DEFAULT_DISPLAY_VALUES, caption };
    }

    if (value) {
      return { value: 'Yes', color: COLOR.RED, caption };
    } else {
      return { value: 'No', color: COLOR.GREEN, caption };
    }
  },
  humidity: (value) => {
    const caption = 'Humidity';
  
    if (!_isNumber(value)) {
      return { ...DEFAULT_DISPLAY_VALUES, caption };
    }

    let color;
    if (value > MAX_SAFE_HUMIDITY) {
      color = COLOR.RED;
    } else {
      color = COLOR.GREEN;
    }
    return { value: value.toFixed(2), color, caption };
  },
  temperature: (value) => {
    const caption = 'Temperature';

    if (!_isNumber(value)) {
      return { ...DEFAULT_DISPLAY_VALUES, caption };
    }

    let color;
    if (isTemperatureSafe(value)) {
      color = COLOR.GREEN;
    } else {
      color = COLOR.RED;
    }
    return { value: formatTemperature(value), color, caption };
  },
  cpuUsage: (value) => {
    const caption = 'CPU Usage';

    if (!_isNumber(value)) {
      return { ...DEFAULT_DISPLAY_VALUES, caption };
    }

    let color;
    if (value > MAX_SAFE_CPU_USAGE) {
      color = COLOR.RED;
    } else {
      color = COLOR.GREEN;
    }
    return { value: `${value}%`, color, caption };
  },
  memoryUsage: (value) => {
    const caption = 'Memory Usage';

    if (!_isNumber(value)) {
      return { ...DEFAULT_DISPLAY_VALUES, caption };
    }

    let color;
    if (value > MAX_SAFE_MEMORY_USAGE) {
      color = COLOR.RED;
    } else {
      color = COLOR.GREEN;
    }
    return { value: `${value}%`, color, caption };
  },
};

const constructDisplayingRules = (kiosk) => {
  const displayingRules = {};

  Object.entries(DISPLAYING_RULES_GETTER).forEach(([field, getDisplayingRules]) => {
    const kioskValue = _get(kiosk, `latestStatus.${field}`);
    displayingRules[field] = getDisplayingRules(kioskValue);
  });

  return displayingRules;
};

export default constructDisplayingRules;
