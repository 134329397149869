export const PURPOSE = {
  PRESCRIPTION_CONSULTATION: 'prescription_consultation',
  GENERAL_PURPOSE: 'general_purpose',
  KIOSK_MEDIA_FEED: 'kiosk_media_feed',
};
export const ALLOWED_PURPOSES = Object.values(PURPOSE);

export const CONFERENCE_STATUS = {
  CONNECTING_API_VIDEO_CONFERENCE: 'connectingApiVideoConference',
  CONNECTED_API_VIDEO_CONFERENCE: 'connectedApiVideoConference',
  FAILED_CONNECTING_API_VIDEO_CONFERENCE: 'failedConnectingApiVideoConference',
  CONNECTING_TWILIO: 'connectingTwilio',
  CONNECTED_TWILIO: 'connectedTwilio',
  FAILED_CONNECTING_TWILIO: 'failedConnectingTwilio',
};

export const SETTING_UP_CONFERENCE_STATUSES = [
  CONFERENCE_STATUS.CONNECTING_API_VIDEO_CONFERENCE,
  CONFERENCE_STATUS.CONNECTED_API_VIDEO_CONFERENCE,
  CONFERENCE_STATUS.CONNECTING_TWILIO,
];

export const PING_INTERVAL = 30000; // 30 seconds

export const TWILIO_CONNECTION_TIMEOUT = 10000; // 10 seconds
