import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

// Views
import KiosksStorageView from './kiosk-storage-view';

// Helpers
import fetchComputedInventory from './helpers/fetchComputedInventory';

// Route store
import { kioskStorageRouteStore } from './domain/store';

// Global Stores
import stores from 'bootstrap/store';
const kiosksStore = stores.global.kiosks;


const KioskStorageContainer = () => {
  useEffect(() => {
    fetchComputedInventory();

    return () => {
      kiosksStore.setValue('computedInventory', null);
    };
  }, []);

  return <KiosksStorageView
    kioskStorageRouteStore={kioskStorageRouteStore}
  />;
};

export default observer(KioskStorageContainer);
