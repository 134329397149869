import { flow } from 'mobx-state-tree';
import { get as _get } from 'lodash';

import base from 'models/base';

import { MODEL_NAME, SCHEMA } from './constants/schema';


const ContainerModel = base({
  names: {
    singular: MODEL_NAME.SINGULAR,
    plural: MODEL_NAME.PLURAL
  },
  JSONSchema: SCHEMA,
  httpConfig: {
    root: MODEL_NAME.plural,
    methods: {
  
    }
  }
});

ContainerModel.configureStore((store) => {
  return store
  .props({
  })
  .actions(self => ({
    listImagesById: flow(function* (config = {}) {
      config.urlFragment = params => `/${params.containerId}/images`;
      const response = yield self.get(config);

      return response;
    }),
    listActiveForPharmacistByKiosk: flow(function* (config = {}) {
      const kioskId = _get(config, 'params.kioskId');
      if (!kioskId) {
        return Promise.reject('"kioskId" param is required in order to list active containers for pharmacist by kiosk.');
      }

      config.urlFragment = () => `/kiosks/${kioskId}/active`;
      const response = yield self.get(config);

      return response;
    }),
    invalidate: flow(function* (config = {}) {
      const containerId = _get(config, 'params.containerId');
      if (!containerId) {
        return Promise.reject('"containerId" param is required in order to invalidate a container.');
      }

      config.urlFragment = () => `/${containerId}/invalidate`;
      const response = yield self.put(config);

      return response;
    }),
  }));
});



export default ContainerModel;
