import React from 'react';
import { observer } from 'mobx-react';

import Modal from 'components/modal';
import ModalRow from 'components/modal/layout/row';
import ModalButton from 'components/modal/layout/button';

import './review-conflict-modal-style.scss';

const ReviewConflictModal = ({
  conflictMessage,
  refreshDispenseAttempts,
}) => (
  <Modal
    modalClassName='c-ReviewConflictModal'
    modalStyles={{ height: '40%', width: '35%' }}
    isOpen={Boolean(conflictMessage)}
    shouldCloseOnOverlayClick={false}
    close={() => refreshDispenseAttempts()}
    title='Review Conflict'
  >
    <div className='conflict-message'>
      {
        conflictMessage
      }
    </div>
    <ModalRow shouldBoundToBottom={true} shouldBeCenteredHorizontally={true}>
      <ModalButton
        color='blue'
        text='Refresh'
        onClick={() => refreshDispenseAttempts()}
      />
    </ModalRow>
  </Modal>
);


export default observer(ReviewConflictModal);
