import React from 'react';
import { observer } from 'mobx-react';

import NotificationPreviews from './components/notification-previews';
import Notifications from './components/notifications';

import './notifications-pane-view-style.scss';

const NotificationPaneView = ({ activeTab, setActiveTab }) => (
  <div className='c-NotificationsPane'>
    <NotificationPreviews
      activeTab={activeTab}
      setActiveTab={setActiveTab}
    />
    {
      activeTab &&
      <Notifications />
    }
  </div>
);

export default observer(NotificationPaneView);
