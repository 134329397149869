import React from 'react';
import { observer } from 'mobx-react';

import Preview from './components/preview';

import { NOTIFICATIONS_DATA, NOTIFICATION_TYPES } from '../../constants';


const NotificationPreviews = ({ activeTab, setActiveTab }) => {
  return NOTIFICATION_TYPES.map((type) => {
    const data = NOTIFICATIONS_DATA[type] || {};
    const { preview } = data;
    if (!preview) return null;

    return <Preview
      key={type}
      type={type}
      isActiveTab={activeTab === type}
      icon={preview.icon}
      activeIcon={preview.activeIcon}
      notificationsCount={preview.getNotificationsCount()}
      displayNotificationsCount={preview.displayNotificationsCount}
      onClick={() => setActiveTab(type)}
    />;
  });
};

export default observer(NotificationPreviews);
