import React from 'react';
import { observer } from 'mobx-react';

import CrossIcon from 'assets/icons/cross.svg';

import './clear-section-button-styles.scss';

const ClearSectionButton = ({ onClick }) => (
  <div className='c-ClearSectionButton' onClick={onClick}>
    <div className='text'>
      Clear
    </div>
    <img
      className='icon'
      alt='Clear Section'
      src={CrossIcon}
    />
  </div>
);

export default observer(ClearSectionButton);
