import React from 'react';
import {
  get as _get,
  keys as _keys,
  isBoolean as _isBoolean,
} from 'lodash';

// Components
import AppCommand from '../../components/app-command';
import InfoItem from '../../components/app-command/components/info-item';

// Constants
import baseConfig from './app-commands-config';

// Utils
import getCompositeValueCaption from 'utils/interpolateTemplateString';

let preLoadConfig = (config) => {
  return _keys(config).reduce((res, appCommandType) => {
    const items = config[appCommandType];

    res[appCommandType] = (appCommand) => items.map((item, index) => {
      let values = item.values;
      let value;

      if (!Array.isArray(values)) values = [values];

      values = values.map(value => _get(appCommand, value));

      if (item.template) value = getCompositeValueCaption({template: item.template, items: values});
      else value = values.join(' ');

      if (item.format) value = item.format(value);

      return (
        <InfoItem
          key={index}
          caption={item.caption}
          value={value}
          showWithoutValue={_isBoolean(item.showWithoutValue)? item.showWithoutValue: true}
        />
      );
    });

    return res;
  }, {});
};

const config = preLoadConfig(baseConfig);

export const buildAppCommand = (appCommand, actionConfig) => {
  const props = {
    createdAt: appCommand.createdAt,
    resolution: _get(appCommand, 'resolution.resolution'),
    type: appCommand.type,
    typeCaption: '',
    items: [],
    key: appCommand.id,
    actionConfig
  };

  if (!config[appCommand.type]) return null;

  props.items = config[appCommand.type](appCommand);

  return props.items.length ? <AppCommand {...props} /> : null;
};
