// Helpers
import fetchDispenseAttempts from './fetchDispenseAttempts';

// Stores
import { dispenseAttemptsRouteStore } from '../domain/store';

export default () => {
  if (dispenseAttemptsRouteStore.http.listingForPharmacist) return;

  return fetchDispenseAttempts(false);
};
