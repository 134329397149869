import { types } from 'mobx-state-tree';
import { set as _set } from 'lodash';

import stores from 'bootstrap/store';

export const DrugsRouteStore = types.model({
})
.props({
  filterValue: types.optional(types.string, ''),
  activeDrugId: types.maybeNull(types.string),
  sortingColumn: types.optional(types.model({
    fieldName: types.optional(types.string, 'name'),
    sortingOrder: types.optional(types.string, 'asc')
  }), {}),
  http: types.optional(types.model({
    listingForPharmacist: types.optional(types.number, 0),
    countingForPharmacist: types.optional(types.number, 0),
  }), {}),
  currentPage: types.optional(types.number, 1),
  showingDrugUpdateModal: types.optional(types.boolean, false)
})
.actions(self => ({
  setValue: (path, value) => _set(self, path, value),
  incrementHttp: (http) => ++self.http[http],
  decrementHttp: (http) => --self.http[http],
  setInitialState: () => {
    self.setValue('filterValue', '');
    self.setValue('activeDrugId', '');
    self.setValue('currentPage', 1);
    self.setValue('sortingColumn', {});
  }
}))
.views(self => ({
  get loading() {
    return !!(self.http.listingForPharmacist && self.http.countingForPharmacist);
  },
  get activeDrug() {
    const drugsStore = stores.global.drugs;
    return self.activeDrugId && drugsStore.dataForPharmacist.get(self.activeDrugId);
  },
  get forTable() {
    const drugsStore = stores.global.drugs;
    return drugsStore.toArray.map((drug) => ({
      id: drug.id,
      name: drug.name || 'N/A',
      NDC: drug.NDC || 'N/A',
      UPC: drug.UPC || 'N/A',
      strength: drug.strength || 'N/A',
      quantity: drug.quantity || 'N/A'
    }));
  },
}));

export const drugsRouteStore = DrugsRouteStore.create({});
