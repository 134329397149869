import React from 'react';
import { observer } from 'mobx-react';

import InterruptedSequence from './components/interrupted-sequence';
import ContinuousSequence from './components/continuous-sequence';

import { detectRanges } from './helpers';

import './index.scss';

const Pages = ({
  showingDots,
  currentPage,
  pagesCount,
  changePage,
  maxPagesVisible
}) => {
  const { rangeFrom, rangeTo } = detectRanges({ showingDots, currentPage, pagesCount, maxPagesVisible });

  return (
    <div className='pagination-numbers'>
      {
        showingDots
          ? <InterruptedSequence
            rangeFrom={rangeFrom}
            rangeTo={rangeTo}
            currentPage={currentPage}
            pagesCount={pagesCount}
            changePage={changePage}
          />
          : <ContinuousSequence
            rangeFrom={rangeFrom}
            rangeTo={rangeTo}
            currentPage={currentPage}
            changePage={changePage}
          />
      }
    </div>
  );
};

export default observer(Pages);