import React from 'react';
import { observer } from 'mobx-react';


const ModuleTemplateInfo = ({ moduleTemplatesRouteStore, moduleTemplate }) => {
  return (
    <div className='module-template-main-info'>
      <div className='name-info'>
        <input
          className='name-input'
          value={moduleTemplate.id}
          onChange={evt => moduleTemplatesRouteStore.setModifiedModuleTemplateName({name: evt.target.value})}
        />
        <div className='name-info-label'>
          MODULE NAME
        </div>
      </div>

      <div className='height-width-info'>
        <div className='height-info'>
          <input
            className='height-input'
            value={Number(moduleTemplate.height)}
            type='number'
            min='0'
            onChange={evt => {
              evt.target.value = Number(evt.target.value.replace(/^0+/, ''));
              moduleTemplatesRouteStore.setModifiedModuleTemplateSizeParam({
                sizeParam: 'height',
                value: evt.target.value
              });
            }}
          />
          <div className='height-info-label'>
            HEIGHT
          </div>
        </div>
        <div className='width-info'>
          <input
            className='width-input'
            value={Number(moduleTemplate.width)}
            type='number'
            min='0'
            onChange={evt => {
              evt.target.value = Number(evt.target.value.replace(/^0+/, ''));
              moduleTemplatesRouteStore.setModifiedModuleTemplateSizeParam({
                sizeParam: 'width',
                value: evt.target.value
              });
            }}
          />
          <div className='width-info-label'>
            WIDTH
          </div>
        </div>
      </div>
    </div>
  );
};


export default observer(ModuleTemplateInfo);