import { toJS } from 'mobx';

import { closeKeycardUpsertModal, listKeycards } from './index';

import stores from 'bootstrap/store';
import { keycardsRouteStore } from '../domain/store';
import { keycardUpsertModalRouteStore } from '../components/keycard-upsert-modal/domain/store';
const keycardsStore = stores.global.keycards;

export default () => {
  return keycardsStore.updateKeycard({
    params: {
      id: keycardsRouteStore.activeKeycardId,
    },
    body: toJS(keycardUpsertModalRouteStore.activelyModifiedKeycard),
  })
  .then(() => {
    closeKeycardUpsertModal();
    return listKeycards();
  });
};
