export const MAX_ENTRIES_PER_PAGE = {
  PRESCRIPTION_FILLS: 10,
};

export const STATUS = {
  UNROUTED: 'UNROUTED',
  ROUTED_WITHOUT_DISPENSE_ATTEMPT: 'ROUTED_WITHOUT_DISPENSE_ATTEMPT',
  AWAITING_REVIEW: 'AWAITING_REVIEW',
  APPROVED: 'APPROVED',
  REJECTED_CONTAINER: 'REJECTED_CONTAINER',
  REJECTED_PRESCRIPTION: 'REJECTED_PRESCRIPTION',
  REJECTED: 'REJECTED',
  DECLINED: 'DECLINED',
  DISPENSED: 'DISPENSED',
};

export const ABLE_TO_ROUTE_STATUSES = new Set([STATUS.UNROUTED, STATUS.ROUTED_WITHOUT_DISPENSE_ATTEMPT]);

export const STATUS_INTERPRETATION = {
  [STATUS.UNROUTED]: 'Not Routed Yet',
  [STATUS.ROUTED_WITHOUT_DISPENSE_ATTEMPT]: 'Routed To a Pharmacy Where Drug Is Out Of Stock',
  [STATUS.AWAITING_REVIEW]: 'Awaiting Pharmacist Review',
  [STATUS.APPROVED]: 'Approved',
  [STATUS.REJECTED_CONTAINER]: 'Rejected Container',
  [STATUS.REJECTED_PRESCRIPTION]: 'Rejected Prescription',
  [STATUS.REJECTED]: 'Rejected Container and Prescription',
  [STATUS.DECLINED]: 'Declined by Patient',
  [STATUS.DISPENSED]: 'Dispensed',
};
