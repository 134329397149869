import React from 'react';
import { observer } from 'mobx-react';

// Components
import DrillDownLayout from 'components/drill-down-layout';
import List from './components/list';
import Content from './components/content';
import ContentScreen from 'components/content-screen';

//Styles
import './call-requests-view.scss';


const CallRequestsView = ({
  callRequestsStore,
  callRequestsRouteStore,
  setFilterValue,
  setActiveListTab,
  listCallRequests,
  createPharmacistAction,
  rootRoute,
}) => (
  <div className='r-CallRequestsView'>
    <DrillDownLayout>
      <ContentScreen active={Boolean(callRequestsRouteStore.http.creatingPharmacistAction)} />
      <List
        callRequestsStore={callRequestsStore}
        callRequestsRouteStore={callRequestsRouteStore}
        setFilterValue={setFilterValue}
        setActiveListTab={setActiveListTab}
        listCallRequests={listCallRequests}
        rootRoute={rootRoute}
      />
      <Content
        callRequestsStore={callRequestsStore}
        callRequestsRouteStore={callRequestsRouteStore}
        createPharmacistAction={createPharmacistAction}
      />
    </DrillDownLayout>
  </div>
);


export default observer(CallRequestsView);
