import moment from 'moment';
import { isNumber as _isNumber, get as _get } from 'lodash';

import {
  MAX_SAFE_CPU_USAGE,
  MAX_SAFE_HUMIDITY,
  MAX_SAFE_MEMORY_USAGE,
  MAX_SAFE_TEMPERATURE,
  MIN_SAFE_TEMPERATURE,
  OFFLINE_TIMEOUT,
} from 'models/kiosks/constants';

const ERRORED_KIOSK_STATUS_RULES = {
  createdAt: {
    isValueErrored: (value) => {
      if (!value) {
        return false;
      }

      const considerOfflineDate = moment().subtract(OFFLINE_TIMEOUT, 'ms');
      return moment(value).isBefore(considerOfflineDate);
    },
    getErrorMessage: () => 'Kiosk is offline. It either lost Internet connectivity, or a fatal error occurred.',
  },
  isPaperInStock: {
    isValueErrored: (value) => value === false,
    getErrorMessage: () => 'Label roll ended.',
  },
  isPrinterOnline: {
    isValueErrored: (value) => value === false,
    getErrorMessage: () => 'Printer is offline.',
  },
  isInFaultedState: {
    isValueErrored: (value) => value === true,
    getErrorMessage: () => 'Kiosk is in faulted state.',
  },
  temperature: {
    isValueErrored: (value) => _isNumber(value) && (value > MAX_SAFE_TEMPERATURE || value < MIN_SAFE_TEMPERATURE),
    getErrorMessage: (value) => `Temperature is at unsafe level: ${value.toFixed(1)}°C. Minimum and maximum safe temperature values are ${MIN_SAFE_TEMPERATURE}°C and ${MAX_SAFE_TEMPERATURE}°C respectively.`,
  },
  humidity: {
    isValueErrored: (value) => _isNumber(value) && value > MAX_SAFE_HUMIDITY,
    getErrorMessage: (value) => `Humidity is at unsafe level: ${value.toFixed(1)}. Maximum safe humidity level is ${MAX_SAFE_HUMIDITY}.`,
  },
  cpuUsage: {
    isValueErrored: (value) => _isNumber(value) && value > MAX_SAFE_CPU_USAGE,
    getErrorMessage: (value) => `CPU usage is at unsafe level: ${value}%. Maximum safe CPU usage level is ${MAX_SAFE_CPU_USAGE}%.`,
  },
  memoryUsage: {
    isValueErrored: (value) => _isNumber(value) && value > MAX_SAFE_MEMORY_USAGE,
    getErrorMessage: (value) => `Memory usage is at unsafe level: ${value}%. Maximum safe memory usage level is ${MAX_SAFE_MEMORY_USAGE}%.`,
  },
};


const constructReadableErrorKiosks = (erroredKiosks = []) => {
  const readableErrorKiosks = [];

  erroredKiosks.forEach((erroredKiosk) => {
    const readableErrors = [];

    Object.entries(ERRORED_KIOSK_STATUS_RULES).forEach(([kioskStatusField, { isValueErrored, getErrorMessage }]) => {
      const kioskStatusValue = _get(erroredKiosk, `latestStatus.${kioskStatusField}`);
      if (kioskStatusValue !== undefined && isValueErrored(kioskStatusValue)) {
        const errorMessage = getErrorMessage(kioskStatusValue);
        readableErrors.push(errorMessage);
      }
    });

    if (readableErrors.length) {
      readableErrorKiosks.push({
        id: erroredKiosk.id,
        name: erroredKiosk.name,
        errors: readableErrors,
      });
    }
  });

  return readableErrorKiosks;
};

export default constructReadableErrorKiosks;
