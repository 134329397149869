import React from 'react';
import { observer } from 'mobx-react';

// Components
import List from 'components/drill-down-layout/components/list';
import ListItem from 'components/drill-down-layout/components/list/components/list-item';
import Header from './components/header';

import LnIcon from 'assets/icons/ln.svg';


const LotsList = ({
  lotsRouteStore,
  lotsStore,
  setFilterValue,
  listLots,
  rootRoute,
}) => (
  <List
    header={<Header
      lotsRouteStore={lotsRouteStore}
      setFilterValue={setFilterValue}
    />}
    isReady={!lotsRouteStore.http.listingLots || lotsStore.dataForPharmacist.size}
    onBottomThreshold={listLots}
  >
    {
      lotsStore.dataForPharmacistList.map((lot) => (
        <ListItem
          key={lot.id}
          title={lot.number}
          isActive={lotsRouteStore.activeLotId === lot.id}
          icon={
            <img
              alt='Lot'
              src={LnIcon}
            />
          }
          url={`${rootRoute}/${lot.id}`}
        />
      ))
    }
  </List>
);

export default observer(LotsList);
