// Helpers
import listLotsDebounce from './listLotsDebounce';

// Stores
import stores from 'bootstrap/store';
import { lotsRouteStore } from '../domain/store';
const lotsStore = stores.global.lots;

const setFilterValue = (e) => {
  lotsRouteStore.setValue('filterValue', e.target.value);
  lotsRouteStore.setValue('currentPage', 1);
  lotsStore.setValue('dataForPharmacist', {});

  listLotsDebounce();
};

export default setFilterValue;
