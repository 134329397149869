import React from 'react';
import { observer } from 'mobx-react';

// Components
import WorkPlaces from './components/work-places';
import TechnicianData from './components/technician-data';
import DeleteConfirmationModal from 'components/delete-confirmation-modal';
import Modal from 'components/modal';
import ModalEditingAreas from 'components/modal/layout/editing-areas';
import ModalEditingArea from 'components/modal/layout/editing-areas/components/editing-area';

const TechnicianUpsertModal = ({
  technicianType,
  upsertModalRouteStore,
  routeStore,
  addWorkPlaceLocally,
  removeWorkPlaceLocally,
  onWorkPlacesSearch,
  createTechnician,
  updateTechnician,
  deleteTechnician,
  closeUpsertModal,
  showDeleteConfirmationModal,
}) => {
  const { activeTechnician } = routeStore;
  const activelyModifiedTechnician = upsertModalRouteStore.activelyModifiedTechnician || {};

  return (
    <Modal
      modalStyles={{ height: '44%', width: '80%' }}
      isOpen={routeStore.showingUpsertModal}
      shouldCloseOnOverlayClick={true}
      close={closeUpsertModal}
      title={activeTechnician ? `${activeTechnician.firstName} ${activeTechnician.lastName}` : `New ${technicianType} Technician`}>
      {
        activeTechnician &&
        <DeleteConfirmationModal
          showing={upsertModalRouteStore.showingDeleteConfirmationModal}
          deletedItemName={`${activeTechnician.firstName} ${activeTechnician.lastName}`}
          deleteItem={deleteTechnician}
          closeModal={() => showDeleteConfirmationModal(false)}
        />
      }
      <ModalEditingAreas>
        <ModalEditingArea>
          <TechnicianData
            upsertModalRouteStore={upsertModalRouteStore}
            activeTechnician={activeTechnician}
            activelyModifiedTechnician={activelyModifiedTechnician}
            createTechnician={createTechnician}
            updateTechnician={updateTechnician}
            showDeleteConfirmationModal={showDeleteConfirmationModal}
          />
        </ModalEditingArea>
        <ModalEditingArea>
          <WorkPlaces
            technicianType={technicianType}
            filter={upsertModalRouteStore.workPlaceFilter}
            onSearch={onWorkPlacesSearch}
            onWorkPlaceSuggestionClick={addWorkPlaceLocally}
            autosuggestedWorkPlaces={upsertModalRouteStore.suggestedWorkPlaces}
            modifiedWorkPlaces={upsertModalRouteStore.activeTechnicianWorkPlaces}
            removeWorkPlace={removeWorkPlaceLocally}
          />
        </ModalEditingArea>
      </ModalEditingAreas>
    </Modal>
  );
};

export default observer(TechnicianUpsertModal);
