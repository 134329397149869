import React from 'react';
import { observer } from 'mobx-react';
import { get as _get } from 'lodash';

import './kiosk-style.scss';

const Kiosk = ({ kiosk }) => {
  return <div className='c-Kiosk'>
    <div className='kiosk-data'>
      <div className='healthcare-site-name'>{_get(kiosk, 'kioskHealthcareSite.healthcareSite.name', 'N/A')}</div>
      <div className='location'>{`${kiosk.location.street1}, ${kiosk.location.city}, ${kiosk.location.state}`}</div>
    </div>
  </div>;
};

export default observer(Kiosk);
