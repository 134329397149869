import io from 'socket.io-client';
import { API_BASE } from 'constants/index';

export const apiWebSocket = io.connect(API_BASE, {
  reconnection: true,
  reconnectionDelay: 1000,
  reconnectionDelayMax : 5000,
  reconnectionAttempts: Infinity
});

export const apiWebSocketEmit = (event, payload) => apiWebSocket.emit(event, payload);

export const apiWebSocketCallback = (event, callback) => apiWebSocket.on(event, data => callback(data));
