import { listPatients } from './index';

import { patientsRouteStore } from '../domain/store';

export default (fieldName, sortingOrder) => {
  patientsRouteStore.setValue('sortingColumn', {
    ...patientsRouteStore.sortingColumn,
    fieldName,
    sortingOrder,
  });

  return listPatients();
};
