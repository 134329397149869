import { types } from 'mobx-state-tree';
import { startCase as _startCase } from 'lodash';

import stores from 'bootstrap/store';

import { PATIENT_LIST_TAB, PATIENT_LIST_TABS } from '../constants';

export const PatientsListRouteStore = types.model({
  activePatientId: types.maybeNull(types.string),
  activePatientListTab: types.optional(types.enumeration(PATIENT_LIST_TABS), PATIENT_LIST_TAB.ACTIVE),
  isEditMode: types.optional(types.boolean, false),
  filterValue: types.optional(types.string, ''),
  currentPage: types.optional(types.number, 1),
  http: types.optional(types.model({
    listingPatients: types.optional(types.number, 0)
  }), {}),
})
.actions(self => ({
  setValue: (prop, value) => self[prop] = value,
  setInitialState: () => {
    self.activePatientId = null;
    self.isEditMode = false;
    self.filterValue = '';
    self.currentPage = 1;
    self.http = {
      listingPatientInsurances: 0,
    };
  },
  incrementHttp: (http) => ++self.http[http],
  decrementHttp: (http) => --self.http[http]
}))
.views(self => ({
  get activePatient() {
    return self.activePatientId && stores.global.patients.dataForPharmacist.get(self.activePatientId);
  },
  get patientPrimaryAddress() {
    if (!self.activePatient || !self.activePatient.patientsAddresses || !self.activePatient.patientsAddresses.length) {
      return null;
    }

    const { location } = self.activePatient.patientsAddresses[0];
    return `${location.street1}, ${location.city}, ${location.state}, ${location.zip}`;
  },
  get patientImages() {
    const { activePatient } = self;
    if (!activePatient) return [];

    return (activePatient.imageUrls || []).map((image) => ({
      src: image.url,
      thumbnail: image.url,
      caption: _startCase(image.type.replace('_', ' ').toLowerCase()),
    }));
  },
}));

export const patientsListRouteStore = PatientsListRouteStore.create({});
