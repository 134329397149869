import stores from 'bootstrap/store';

const listDrugsAutocomplete = async (filter) => {
  const response = await stores.global.drugs.list({
    query: {
      filter: {
        name: filter,
        NDC: filter,
      },
      limit: 10,
    },
  });

  return response.data || [];
};

export default listDrugsAutocomplete;
