import { machineTechniciansRouteStore } from '../domain/store';

import { listMachineTechnicians } from './index';


export default (fieldName, sortingOrder) => {
  machineTechniciansRouteStore.setValue('sortingColumn', {
    ...machineTechniciansRouteStore.sortingColumn,
    fieldName,
    sortingOrder
  });

  return listMachineTechnicians();
};
