import { types } from 'mobx-state-tree';

import doesValueMatchRegex from 'utils/doesValueMatchRegex';
import { EMAIL_RE, PHONE_NUMBER_RE } from '../constants';

export const SendReceiptModalRouteStore = types.model({
})
.props({
  patient: types.maybeNull(types.frozen()),
  email: types.optional(types.string, ''),
  phoneNumber: types.optional(types.string, ''),
  shouldEmail: types.optional(types.boolean, false),
  shouldText: types.optional(types.boolean, false),
  shouldSaveEmail: types.optional(types.boolean, false),
  shouldSavePhoneNumber: types.optional(types.boolean, false),
  http: types.optional(types.model({
    fetchingPatientById: types.optional(types.number, 0),
    updatingPatientById: types.optional(types.number, 0),
    sendingReceipt: types.optional(types.number, 0),
  }), {}),
})
.actions(self => ({
  setValue: (prop, value) => self[prop] = value,
  incrementHttp: (http) => ++self.http[http],
  decrementHttp: (http) => --self.http[http],
  setInitialState: () => {
    self.patient = null;
    self.email = '';
    self.phoneNumber = '';
    self.shouldEmail = false;
    self.shouldText = false;
    self.shouldSaveEmail = false;
    self.shouldSavePhoneNumber = false;
    self.http = {};
  },
}))
.views(self => ({
  get canBeSubmitted() {
    if (self.shouldEmail) {
      if (!self.email || self.email && !doesValueMatchRegex(EMAIL_RE, self.email)) {
        return false;
      }
    }

    if (self.shouldText) {
      if (!self.phoneNumber || self.phoneNumber && (!doesValueMatchRegex(PHONE_NUMBER_RE, self.phoneNumber) || self.phoneNumber.length !== 10)) {
        return false;
      }
    }
  
    return self.shouldEmail || self.shouldText;
  },
  get isBusy() {
    return Boolean(self.http.fetchingPatientById || self.http.updatingPatientById || self.http.sendingReceipt);
  },
}));

export const sendReceiptModalRouteStore = SendReceiptModalRouteStore.create({});
