import React from 'react';
import { observer } from 'mobx-react';

import './detail-key-styles.scss';

const PatientDetailKey = ({
  icon,
  caption,
}) => (
  <div className='c-PatientDetailItemKey'>
    <img
      alt={caption}
      className='icon'
      src={icon}
    />
    <div className='text'>
      {
        caption
      }
    </div>
  </div>
);

export default observer(PatientDetailKey);
