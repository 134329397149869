import { flow } from 'mobx-state-tree';

import base from 'models/base';
import { MODEL_NAME, SCHEMA } from './constants/schema';


const PrescriptionFillTransferModel = base({
  names: {
    singular: MODEL_NAME.SINGULAR,
    plural: MODEL_NAME.PLURAL
  },
  JSONSchema: SCHEMA,
  httpConfig: {
    root: MODEL_NAME.plural,
    methods: {}
  }
});

PrescriptionFillTransferModel.configureStore((store) => {
  return store
  .props({
  })
  .actions(self => ({
    setValue: (prop, value) => self[prop] = value,
    setInitialState: () => {
    },
    process: flow(function* (config = {}) {
      config.urlFragment = () => '/process';

      yield self.put(config);
    }),
  }));
});

// Can't use module.exports because there is a generator function in this file,
// and that causes module.exports to fail for some reason.
export default PrescriptionFillTransferModel;
