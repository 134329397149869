import {
  isNil as _isNil,
  get as _get,
} from 'lodash';


/**
 * Interpolate values into a template string.
 *
 * @param template - template to insert items into (for instance, '{0} {1}, {2}')
 * @param items - array of values to insert into a template string
 * @param defaultValue - value which will be returned if unable to interpolate string
 * @returns interpolated string
 */
export default ({ template, items, defaultValue = 'N/A' }) => {
  const isValid = !items.filter(_isNil).length;
  return isValid
    ? template.replace(new RegExp('{\\d+}', 'g'), (match) => _get(items, `[${match.slice(1, -1)}]`, ''))
    : defaultValue;
};
