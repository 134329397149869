import React from 'react';
import { observer } from 'mobx-react';

import Data from './components/data';
import Footer from './components/footer';

const MedicalAssistantUpsertModalMedicalAssistantData = ({
  upsertModalRouteStore,
  activeMedicalAssistant,
  activelyModifiedMedicalAssistant,
  createMedicalAssistant,
  updateMedicalAssistant,
  showDeleteConfirmationModal,
}) => (
  <React.Fragment>
    <Data
      upsertModalRouteStore={upsertModalRouteStore}
      activelyModifiedMedicalAssistant={activelyModifiedMedicalAssistant}
    />
    <Footer
      upsertModalRouteStore={upsertModalRouteStore}
      activeMedicalAssistant={activeMedicalAssistant}
      createMedicalAssistant={createMedicalAssistant}
      updateMedicalAssistant={updateMedicalAssistant}
      showDeleteConfirmationModal={showDeleteConfirmationModal}
    />
  </React.Fragment>
);

export default observer(MedicalAssistantUpsertModalMedicalAssistantData);
