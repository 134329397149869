import { get as _get} from 'lodash';

// Constants
import { MAX_ENTRIES_PER_PAGE, PATIENTS_INCOMPLETE_INFO_ROUTE } from '../constants';

// Stores
import stores from 'bootstrap/store';
import { patientsIncompleteInfoRouteStore} from '../domain/store';
const patientsStore = stores.global.patients;
const { router } = stores;


export default () => {
  if (patientsIncompleteInfoRouteStore.http.listingPatients) return;

  const { filterValue } = patientsIncompleteInfoRouteStore;
  const query = {
    offset: MAX_ENTRIES_PER_PAGE * (patientsIncompleteInfoRouteStore.currentPage - 1),
    limit: MAX_ENTRIES_PER_PAGE,
  };

  if (filterValue) {
    query.filter = {
      firstName: filterValue,
      lastName: filterValue,
    };
  }

  const config = { query };

  patientsIncompleteInfoRouteStore.incrementHttp('listingPatients');
  return patientsStore.listIncompleteForPharmacist(config)
  .catch(() => router.history.push(PATIENTS_INCOMPLETE_INFO_ROUTE))
  .then((response) => {
    const responseLength = _get(response, 'data.length', 0);
    if (responseLength > MAX_ENTRIES_PER_PAGE - 1) {
      patientsIncompleteInfoRouteStore.setValue('currentPage', patientsIncompleteInfoRouteStore.currentPage + 1);
    }
  })
  .finally(() => patientsIncompleteInfoRouteStore.decrementHttp('listingPatients'));
};
