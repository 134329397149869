import React from 'react';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';

import { getConferenceStatusCaption } from './helpers';

import './video-feed-view.scss';

const VideoFeedView  = ({
  remoteTracksByParticipant,
  conferenceStatus,
}) => {
  remoteTracksByParticipant = toJS(remoteTracksByParticipant) || {};
  const participantIds = Array.from(remoteTracksByParticipant.keys ? remoteTracksByParticipant.keys() : []);
  const conferenceStatusCaption = getConferenceStatusCaption(conferenceStatus);

  return <div className='r-VideoFeedView'>
    <div className='video-conference'>
      <div id='remote-access-video-container' className='remote-access-video-container'>
        {
          conferenceStatusCaption
            ? <div className='connection-status'>
              {
                conferenceStatusCaption
              }
            </div>
            : participantIds.map((participantId) => {
              const tracks = Array.from(remoteTracksByParticipant.get(participantId).values());
              if (!tracks) return null;

              return <div key={participantId} className='remote-access-video' ref={(el) => {
                if (!el) return;
                tracks.forEach((track) => el.appendChild(track.htmlRef));
              }}/>;
            })
        }
      </div>
    </div>
  </div>;
};


export default observer(VideoFeedView);
