import React from 'react';
import { observer } from 'mobx-react';

// Components
import Content from 'components/drill-down-layout/components/content';
import ContentTab from 'components/drill-down-layout/components/content/components/content-tab';
import Header from './components/header';

// Routes
import ContactRoute from '../../routes/contact';
// import ActivityRoute from '../../routes/activity';
// import HealthcareSitesRoute from '../../routes/healthcare-sites';

// Constants
import { PHARMACY_TECHNICIANS } from 'constants/routes';


const PharmacyTechniciansContent = ({
  pharmacyTechniciansRouteStore,
}) => (
  <Content
    header={<Header
      pharmacyTechniciansRouteStore={pharmacyTechniciansRouteStore}
    />}
    isReady={!!pharmacyTechniciansRouteStore.activeTechnician}
  >
    <ContentTab title={PHARMACY_TECHNICIANS.CONTENT_TAB.CONTACT.TITLE} route={PHARMACY_TECHNICIANS.CONTENT_TAB.CONTACT.VALUE}>
      <ContactRoute activeTechnician={pharmacyTechniciansRouteStore.activeTechnician} />
    </ContentTab>
    {/* <ContentTab title={PHARMACY_TECHNICIANS.CONTENT_TAB.ACTIVITY.TITLE} route={PHARMACY_TECHNICIANS.CONTENT_TAB.ACTIVITY.VALUE}>
      <ActivityRoute activePharmacyTechnician={pharmacyTechniciansRouteStore.activeTechnician} />
    </ContentTab> */}
    {/* <ContentTab title={PHARMACY_TECHNICIANS.CONTENT_TAB.HEALTHCARE_SITES.TITLE} route={PHARMACY_TECHNICIANS.CONTENT_TAB.HEALTHCARE_SITES.VALUE}>
      <HealthcareSitesRoute activePharmacyTechnician={pharmacyTechniciansRouteStore.activeTechnician} />
    </ContentTab> */}
  </Content>
);

export default observer(PharmacyTechniciansContent);
