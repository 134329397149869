import { toJS } from 'mobx';

import stores from 'bootstrap/store';
import { machineTechnicianUpsertModalRouteStore } from '../domain/upsert-modal-store';
const kiosksStore = stores.global.kiosks;

export default (pharmacy) => {
  machineTechnicianUpsertModalRouteStore.addKiosk(toJS(pharmacy));
  machineTechnicianUpsertModalRouteStore.setValue('workPlaceFilter', '');
  kiosksStore.setValue('dataForAdmin', {});
};
