import React, { useLayoutEffect, useState } from 'react';
import {
  Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import { observer, Provider } from 'mobx-react';

import './root-container.scss';

// Hooks
import useDebounce from 'hooks/useDebounce';
import useRefreshOnInactivity from './hooks/useRefreshOnInactivity';

// Components
import ContentGate from 'components/content-gate';
import VideoConferenceModal from './routes/video-conference';
import DispenseAttemptRejectionReasonModal from './routes/dispense-attempt-rejection-reason-modal';
import CameraConfigurationModal from './routes/camera-configuration-modal';

// Route Containers
import AppFrameContainer from './routes/app-frame/app-frame-container';
import LoginContainer from './routes/login/login-container';
import SignUpContainer from './routes/signup/signup-container';
import ResetPasswordContainer from './routes/reset-password/reset-password-container';
import ChangePasswordContainer from './routes/change-password/change-password-container';
import ChangePinContainer from './routes/change-pin/change-pin-container';

// Helpers
import { rejectCautiously } from 'models/dispense-attempts/helpers';

// Stores
import stores from 'bootstrap/store';



const store = {
  auth: stores.global.auth,
  users: stores.global.users,
  dispenseAttempts: stores.global.dispenseAttempts,
  notifications: stores.global.notifications,
  videoConferences: stores.global.videoConferences,
  cameras: stores.global.cameras,
};

const RootContainer = () => {
  const [routerState, setRouterState] = useState({
    action: stores.router.history.action,
    location: stores.router.history.location,
  });

  const ping = useRefreshOnInactivity();

  const onMouseActivity = useDebounce(ping, { wait: 1000, maxWait: 1000 * 10 });

  useLayoutEffect(() => stores.router.subscribe(setRouterState), []);

  return <Provider store={stores}>
    <Router location={routerState.location} navigationType={routerState.action} navigator={stores.router.history}>
      <ContentGate
        className='c-RootView'
        active={!store.auth.hasCheckedLocalStorage}
        fadeDuration={500}
        onMouseMove={onMouseActivity}
      >
        {
          store.videoConferences.showingVideoConferenceModal &&
          <VideoConferenceModal />
        }
        {
          store.cameras.showingCameraConfigurationModal &&
          <CameraConfigurationModal />
        }
        {
          store.dispenseAttempts.rejectionModal.showing &&
          <DispenseAttemptRejectionReasonModal
            store={store.dispenseAttempts}
            showing={store.dispenseAttempts.rejectionModal.showing}
            close={() => store.dispenseAttempts.resetRejectionModal()}
            reject={rejectCautiously}
          />
        }
        <Routes>
          <Route path='/login' element={<LoginContainer/>} />
          <Route path='/signup' element={<SignUpContainer/>} />
          <Route path='/reset-password' element={<ResetPasswordContainer/>} />
          <Route path='/change-password' element={<ChangePasswordContainer/>} />
          <Route path='/change-pin' element={<ChangePinContainer/>} />
          <Route path='*' element={store.auth.activeToken ? <AppFrameContainer/> : <Navigate to='/login'/>} />
        </Routes>
      </ContentGate>
    </Router>
  </Provider>;
};

export default observer(RootContainer);
