import React from 'react';
import { observer } from 'mobx-react';
import { get as _get } from 'lodash';

import ContentGate from 'components/content-gate';

// Constants
import { FRIENDLY_TEXT_BY_ACTION } from '../../constants';

// Styles
import './actions-history-style.scss';

const ActionsHistoryView = ({
  activeCallRequest,
  fetchingActiveCallRequest,
}) => {
  return (
    <div className='c-PatientDetails'>
      <div className='content'>
        <ContentGate
          active={!activeCallRequest || fetchingActiveCallRequest}
          fadeDuration={500}
          color='none'
        >
          {
            _get(activeCallRequest, 'pharmacistActions', []).length
              ? _get(activeCallRequest, 'pharmacistActions', []).map((pharmacistAction) => {
                const { id, action, createdAt, pharmacist } = pharmacistAction;
                return <div key={id} className='pharmacist-action-container'>
                  <div className='title'>
                    {
                      `${new Date(createdAt).toLocaleDateString()} ${new Date(createdAt).toLocaleTimeString()}`
                    }
                  </div>
                  <div className='action-description'>
                    <span className='action'>
                      {
                        FRIENDLY_TEXT_BY_ACTION[action]
                      }
                    </span>
                    {
                      ` by ${pharmacist.firstName} ${pharmacist.lastName}`
                    }
                  </div>
                </div>;
              })
              : <div className='no-pharmacist-actions'>
          No actions have been taken on this call request.
              </div>
          }
        </ContentGate>
      </div>
    </div>
  );
};

export default observer(ActionsHistoryView);
