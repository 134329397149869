import { isString as _isString } from 'lodash';

import { prescriptionFillsManualEntryRouteStore } from '../domain/store';

import listDrugsAutocomplete from './listDrugsAutocomplete';

const onDrugAutocompleteInputChange = async (field, value) => {
  prescriptionFillsManualEntryRouteStore.setValue(`drug.${field}`, value);
  
  const currentValue = prescriptionFillsManualEntryRouteStore.drug[field];
  if (_isString(currentValue) && currentValue.length > 2) {
    return listDrugsAutocomplete(field);
  } else {
    return prescriptionFillsManualEntryRouteStore.setValue(`suggestions.drug.${field}`, []);
  }
};

export default onDrugAutocompleteInputChange;
