import deepFreeze from 'deep-freeze';

export const MODEL_NAME = Object.freeze({
  SINGULAR: 'container',
  PLURAL: 'containers'
});

export const FIELD_NAME = Object.freeze({
  ID: 'id',
  EXPIRATION_DATE: 'expirationDate',
  LOCAL_CONTAINER_ID: 'localContainerId',
  DRUG_UPC: 'drug_UPC'
});

export const SCHEMA = deepFreeze({
  title: MODEL_NAME.SINGULAR,
  type: 'object',
  properties: {
    [FIELD_NAME.ID]: {
      type: 'number'
    },
    [FIELD_NAME.EXPIRATION_DATE]: {
      type: 'string',
      format: 'datetime'
    },
    [FIELD_NAME.LOCAL_CONTAINER_ID]: {
      type: 'string'
    },
    [FIELD_NAME.DRUG_UPC]: {
      type: 'string'
    },
   
  },
  required: [
    FIELD_NAME.ID
  ]
});
