import React from 'react';

import UserIcon from 'assets/icons/patient.svg';

import './drop-down-menu-view-style.scss';


const DropDownMenuView = ({
  showMenu,
  reverseMenuState,
  menuIcon,
  items
}) => (
  <div className='dropdown'>
    <img
      id='show-menu'
      className='menu-icon'
      src={menuIcon || UserIcon}
      onClick={reverseMenuState}
    />
    {
      showMenu &&
      <React.Fragment>
        <div
          className='overlay'
          onClick={reverseMenuState}
        />
        <ul>
          {
            items.map((item, index) =>
              <li key={index} onClick={reverseMenuState}>
                {
                  item
                }
              </li>
            )
          }
        </ul>
      </React.Fragment>
    }
  </div>
);


export default DropDownMenuView;