import React from 'react';
import { observer } from 'mobx-react';

import AppCommands from 'components/app-commands';

import { ACTION_NAME } from '../../constants';

// Styles
import './kiosk-activity-view.scss';

const KioskActivityView  = ({
  kioskActivityRouteStore,
  appCommandsStore,
  setFilter,
  listAppCommands,
}) => {
  return <div className='r-KioskActivityView'>
    <AppCommands
      appCommandsByDate={appCommandsStore.appCommandsByDateByType({
        type: kioskActivityRouteStore.appCommandFilter,
        displayableOnly: true,
      })}
      setFilter={setFilter}
      listAppCommands={listAppCommands}
      isListingAppCommands={!!kioskActivityRouteStore.http.listingAppCommands}
      appCommandFilter={kioskActivityRouteStore.appCommandFilter}
      actionConfig={ACTION_NAME}
    />
  </div>;
};


export default observer(KioskActivityView);
