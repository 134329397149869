import React from 'react';
import { observer } from 'mobx-react';

import FulfillmentInfo from './components/fulfillment-info';
import EmployeeInfo from './components/employee-info';

import { PURPOSE } from 'models/video-conferences/constants';


const CallerInfo = ({
  videoConferencePurpose,
  notificationPayload,
  prescriptionFillsForDisplay,
  approveDispenseAttempt,
  rejectDispenseAttempt,
}) => {
  return videoConferencePurpose === PURPOSE.PRESCRIPTION_CONSULTATION
    ? <FulfillmentInfo
      prescriptionFillsForDisplay={prescriptionFillsForDisplay}
      approveDispenseAttempt={approveDispenseAttempt}
      rejectDispenseAttempt={rejectDispenseAttempt}
    />
    : videoConferencePurpose === PURPOSE.GENERAL_PURPOSE
      ? <EmployeeInfo notificationPayload={notificationPayload} />
      : null;
};


export default observer(CallerInfo);
