import React from 'react';
import ApprovedIcon from 'assets/icons/approved.svg';
import NoneIcon from 'assets/icons/none.svg';

export const ORDER_OPTIONS = {
  name: order => [['firstName', order], ['lastName', order ]],
  NPI: order => [['NPI', order]],
  SPI: order => [['SPI', order]]
};

export const USER_MANIPULATION_OPTIONS = {
  CREATE: 'create',
  UPDATE: 'update'
};

export const USER_SKELETON = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  isAdmin: false
};

export const COLUMNS = [
  {
    name: 'Name',
    fieldName: 'name',
    isSortable: true,
    width: '35%',
    render: ({ content }) => (
      <div title={content.name}>{content.name}</div>
    )
  },
  {
    name: 'Email',
    fieldName: 'email',
    isSortable: false,
    width: '25%',
    render: ({ content }) => (
      <div title={content.email}>{content.email}</div>
    )
  },
  {
    name: 'Primary Role',
    fieldName: 'primaryRole',
    isSortable: false,
    width: '20%',
    render: ({ content }) => (
      <div title={content.primaryRole}>{content.primaryRole}</div>
    )
  },
  {
    name: 'Admin Status',
    fieldName: 'isAdmin',
    isSortable: false,
    width: '20%',
    render: ({ content }) => (
      <div title={content.id}>
        <img
          src={content.isAdmin ? ApprovedIcon : NoneIcon}
        />
      </div>
    )
  }
];
