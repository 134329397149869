import React from 'react';
import {
  Navigate,
  Routes,
  Route,
} from 'react-router-dom';
import { observer } from 'mobx-react';

import ContentGate from 'components/content-gate';

import './app-frame-view.scss';


// Constants
import * as ROUTE from 'constants/routes';

// Views
import NavbarContainer from './views/navbar/navbar-container';
import HeaderContainer from './views/header/header-container';
import NotificationsPaneContainer from './views/notifications-pane';

// Routes
// User
import DispenseAttempts from './routes/user/dispense-attempts';
import Drugs from './routes/user/drugs';
import Lots from './routes/user/lots';
import MachineTechniciansContainer from './routes/user/machine-technicians';
import MedicalAssistantContainer from './routes/user/medical-assistants';
import PatientCallRequestsContainer from './routes/user/patients/routes/call-requests';
import PatientsListContainer from './routes/user/patients/routes/list';
import PatientsIncompleteInfoContainer from './routes/user/patients/routes/incomplete-info';
import PrescriptionFillsManage from './routes/user/prescription-fills/routes/manage';
import PrescriptionFillsStats from './routes/user/prescription-fills/routes/stats';
// import PrescriptionFillsListContainer from './routes/user/prescription-fills/routes/list';
import PrescriptionFillsMailOrderContainer from './routes/user/prescription-fills/routes/mailOrder';
import PrescriptionFillsManualOrderContainer from './routes/user/prescription-fills/routes/manual-entry';
// import PrescriptionFillsTransfersContainer from './routes/user/prescription-fills/routes/transfers';
// import PrescriptionFillsTableContainer from './routes/user/prescription-fills/routes/table';
// import PrescriptionFillsUnroutedContainer from './routes/user/prescription-fills/routes/unrouted';
import KiosksContainer from './routes/user/kiosks';
import PharmacyTechniciansContainer from './routes/user/pharmacy-technicians';
import KeycardsContainer from './routes/user/keycards';
import PrescriptionFillUpsert from './routes/user/prescription-fill-upsert';
import HealthcareSitesContainer from './routes/user/healthcare-sites';
import StockingBatches from './routes/user/stocking-batches';
// import VideoConferences from './routes/user/video-conferences';

// Admin
import DrugsAdminContainer from './routes/admin/drugs';
import MachineTechniciansAdminContainer from './routes/admin/machine-technicians';
import MedicalAssistantsAdminContainer from './routes/admin/medical-assistants';
import ModuleTemplatesAdminContainer from './routes/admin/module-templates';
import PatientsAdminContainer from './routes/admin/patients';
import PharmacyTechniciansAdminContainer from './routes/admin/pharmacy-technicians';
import PhysiciansAdminContainer from './routes/admin/physicians';
import UsersAdminContainer from './routes/admin/users';
import PharmaciesAdminContainer from './routes/admin/pharmacies';


const AppFrameView = ({
  isActiveUserAvailable,
  activeUserAccessibleRoutes,
}) => {
  return <div className='c-AppFrameView' style={{display: 'flex', flex: '1 0 100%'}}>
    <HeaderContainer />
    <div className='sub-header-content'>
      <NavbarContainer
        activeUserAccessibleRoutes={activeUserAccessibleRoutes}
      />
      <div className='primary-route-content-container'>
        <ContentGate className='primary-route-content' active={!isActiveUserAvailable} fadeDuration={500}>
          <Routes>
            {/* User */}
            <Route
              exact path={ROUTE.DISPENSE_ATTEMPTS.VALUE}
              element={
                <DispenseAttempts
                  rootRoute={ROUTE.DISPENSE_ATTEMPTS.VALUE}
                />
              }
            />
            <Route
              path={`${ROUTE.DISPENSE_ATTEMPTS.VALUE}/:${ROUTE.DISPENSE_ATTEMPTS.PARAM}/*`}
              element={
                <DispenseAttempts
                  rootRoute={ROUTE.DISPENSE_ATTEMPTS.VALUE}
                />
              }
            />

            <Route
              exact path={ROUTE.DRUGS.VALUE}
              element={
                <Drugs
                  rootRoute={ROUTE.DRUGS.VALUE}
                />
              }
            />

            {/*
            <Route
              exact path={`${ROUTE.PRESCRIPTION_FILLS.VALUE}${ROUTE.PRESCRIPTION_FILLS.SUB_ROUTE.TABLE_OVERVIEW.VALUE}`}
              element={
                <PrescriptionFillsTableContainer
                  rootRoute={`${ROUTE.PRESCRIPTION_FILLS.VALUE}${ROUTE.PRESCRIPTION_FILLS.SUB_ROUTE.TABLE_OVERVIEW.VALUE}`}
                />
              }
            />
            <Route
              exact path={`${ROUTE.PRESCRIPTION_FILLS.VALUE}${ROUTE.PRESCRIPTION_FILLS.SUB_ROUTE.LIST_OVERVIEW.VALUE}`}
              element={
                <PrescriptionFillsListContainer
                  rootRoute={`${ROUTE.PRESCRIPTION_FILLS.VALUE}${ROUTE.PRESCRIPTION_FILLS.SUB_ROUTE.LIST_OVERVIEW.VALUE}`}
                />
              }
            />
            <Route
              path={`${ROUTE.PRESCRIPTION_FILLS.VALUE}${ROUTE.PRESCRIPTION_FILLS.SUB_ROUTE.LIST_OVERVIEW.VALUE}/:prescriptionFillId/*`}
              element={
                <PrescriptionFillsListContainer
                  rootRoute={`${ROUTE.PRESCRIPTION_FILLS.VALUE}${ROUTE.PRESCRIPTION_FILLS.SUB_ROUTE.LIST_OVERVIEW.VALUE}`}
                />
              }
            />
            <Route
              exact path={`${ROUTE.PRESCRIPTION_FILLS.VALUE}${ROUTE.PRESCRIPTION_FILLS.SUB_ROUTE.UNROUTED.VALUE}`}
              element={
                <PrescriptionFillsUnroutedContainer
                  rootRoute={`${ROUTE.PRESCRIPTION_FILLS.VALUE}${ROUTE.PRESCRIPTION_FILLS.SUB_ROUTE.UNROUTED.VALUE}`}
                />
              }
            />
            <Route
              path={`${ROUTE.PRESCRIPTION_FILLS.VALUE}${ROUTE.PRESCRIPTION_FILLS.SUB_ROUTE.UNROUTED.VALUE}/:prescriptionFillId/*`}
              element={
                <PrescriptionFillsUnroutedContainer
                  rootRoute={`${ROUTE.PRESCRIPTION_FILLS.VALUE}${ROUTE.PRESCRIPTION_FILLS.SUB_ROUTE.UNROUTED.VALUE}`}
                />
              }
            />
           */}
            <Route
              exact path={`${ROUTE.PRESCRIPTION_FILLS.VALUE}${ROUTE.PRESCRIPTION_FILLS.SUB_ROUTE.MAIL_ORDERED.VALUE}`}
              element={
                <PrescriptionFillsMailOrderContainer
                  rootRoute={`${ROUTE.PRESCRIPTION_FILLS.VALUE}${ROUTE.PRESCRIPTION_FILLS.SUB_ROUTE.MAIL_ORDERED.VALUE}`}
                />
              }
            />
            <Route
              path={`${ROUTE.PRESCRIPTION_FILLS.VALUE}${ROUTE.PRESCRIPTION_FILLS.SUB_ROUTE.MAIL_ORDERED.VALUE}/:prescriptionFillId/*`}
              element={
                <PrescriptionFillsMailOrderContainer
                  rootRoute={`${ROUTE.PRESCRIPTION_FILLS.VALUE}${ROUTE.PRESCRIPTION_FILLS.SUB_ROUTE.MAIL_ORDERED.VALUE}`}
                />
              }
            />
           
            <Route
              exact path={`${ROUTE.PRESCRIPTION_FILLS.VALUE}${ROUTE.PRESCRIPTION_FILLS.SUB_ROUTE.MANAGE.VALUE}`}
              element={
                <PrescriptionFillsManage
                  rootRoute={`${ROUTE.PRESCRIPTION_FILLS.VALUE}${ROUTE.PRESCRIPTION_FILLS.SUB_ROUTE.MANAGE.VALUE}`}
                />
              }
            />
            <Route
              exact path={`${ROUTE.PRESCRIPTION_FILLS.VALUE}${ROUTE.PRESCRIPTION_FILLS.SUB_ROUTE.STATS.VALUE}`}
              element={
                <PrescriptionFillsStats
                  rootRoute={`${ROUTE.PRESCRIPTION_FILLS.VALUE}${ROUTE.PRESCRIPTION_FILLS.SUB_ROUTE.STATS.VALUE}`}
                />
              }
            />
            <Route
              path={`${ROUTE.PRESCRIPTION_FILLS.VALUE}${ROUTE.PRESCRIPTION_FILLS.SUB_ROUTE.STATS.VALUE}/*`}
              element={
                <PrescriptionFillsStats
                  rootRoute={`${ROUTE.PRESCRIPTION_FILLS.VALUE}${ROUTE.PRESCRIPTION_FILLS.SUB_ROUTE.STATS.VALUE}`}
                />
              }
            />
            <Route
              exact path={`${ROUTE.PRESCRIPTION_FILLS.VALUE}${ROUTE.PRESCRIPTION_FILLS.SUB_ROUTE.MANUAL_ORDER.VALUE}`}
              element={
                <PrescriptionFillsManualOrderContainer
                  rootRoute={`${ROUTE.PRESCRIPTION_FILLS.VALUE}${ROUTE.PRESCRIPTION_FILLS.SUB_ROUTE.MANUAL_ORDER.VALUE}`}
                />
              }
            />
            {/*
            <Route
              exact path={`${ROUTE.PRESCRIPTION_FILLS.VALUE}${ROUTE.PRESCRIPTION_FILLS.SUB_ROUTE.TRANSFERS.VALUE}`}
              element={
                <PrescriptionFillsTransfersContainer
                  rootRoute={`${ROUTE.PRESCRIPTION_FILLS.VALUE}${ROUTE.PRESCRIPTION_FILLS.SUB_ROUTE.TRANSFERS.VALUE}`}
                />
              }
            />
            <Route
              path={`${ROUTE.PRESCRIPTION_FILLS.VALUE}${ROUTE.PRESCRIPTION_FILLS.SUB_ROUTE.TRANSFERS.VALUE}/:prescriptionFillId/*`}
              element={
                <PrescriptionFillsTransfersContainer
                  rootRoute={`${ROUTE.PRESCRIPTION_FILLS.VALUE}${ROUTE.PRESCRIPTION_FILLS.SUB_ROUTE.TRANSFERS.VALUE}`}
                />
              }
            />
            */}

            <Route
              exact path={ROUTE.KIOSKS.VALUE}
              element={
                <KiosksContainer
                  rootRoute={ROUTE.KIOSKS.VALUE}
                />
              }
            />
            <Route
              path={`${ROUTE.KIOSKS.VALUE}/:${ROUTE.KIOSKS.PARAM}/*`}
              element={
                <KiosksContainer
                  rootRoute={ROUTE.KIOSKS.VALUE}
                />
              }
            />

            <Route
              exact path={ROUTE.HEALTHCARE_SITES.VALUE}
              element={
                <HealthcareSitesContainer
                  rootRoute={ROUTE.HEALTHCARE_SITES.VALUE}
                />
              }
            />
            <Route
              path={`${ROUTE.HEALTHCARE_SITES.VALUE}/:${ROUTE.HEALTHCARE_SITES.PARAM}/*`}
              element={
                <HealthcareSitesContainer
                  rootRoute={ROUTE.HEALTHCARE_SITES.VALUE}
                />
              }
            />

            <Route
              exact path={ROUTE.MEDICAL_ASSISTANTS.VALUE}
              element={
                <MedicalAssistantContainer
                  rootRoute={ROUTE.MEDICAL_ASSISTANTS.VALUE}
                />
              }
            />
            <Route
              path={`${ROUTE.MEDICAL_ASSISTANTS.VALUE}/:${ROUTE.MEDICAL_ASSISTANTS.PARAM}/*`}
              element={
                <MedicalAssistantContainer
                  rootRoute={ROUTE.MEDICAL_ASSISTANTS.VALUE}
                />
              }
            />

            <Route
              exact path={ROUTE.MACHINE_TECHNICIANS.VALUE}
              element={
                <MachineTechniciansContainer
                  rootRoute={ROUTE.MACHINE_TECHNICIANS.VALUE}
                />
              }
            />
            <Route
              path={`${ROUTE.MACHINE_TECHNICIANS.VALUE}/:${ROUTE.MACHINE_TECHNICIANS.PARAM}/*`}
              element={
                <MachineTechniciansContainer
                  rootRoute={ROUTE.MACHINE_TECHNICIANS.VALUE}
                />
              }
            />

            <Route
              exact path={`${ROUTE.PATIENTS.VALUE}${ROUTE.PATIENTS.SUB_ROUTE.LIST_OVERVIEW.VALUE}`}
              element={
                <PatientsListContainer
                  rootRoute={`${ROUTE.PATIENTS.VALUE}${ROUTE.PATIENTS.SUB_ROUTE.LIST_OVERVIEW.VALUE}`}
                />
              }
            />
            <Route
              path={`${ROUTE.PATIENTS.VALUE}${ROUTE.PATIENTS.SUB_ROUTE.LIST_OVERVIEW.VALUE}/:${ROUTE.PATIENTS.SUB_ROUTE.LIST_OVERVIEW.PARAM}/*`}
              element={
                <PatientsListContainer
                  rootRoute={`${ROUTE.PATIENTS.VALUE}${ROUTE.PATIENTS.SUB_ROUTE.LIST_OVERVIEW.VALUE}`}
                />
              }
            />
            <Route
              exact path={`${ROUTE.PATIENTS.VALUE}${ROUTE.PATIENTS.SUB_ROUTE.CALL_REQUESTS.VALUE}`}
              element={
                <PatientCallRequestsContainer
                  rootRoute={`${ROUTE.PATIENTS.VALUE}${ROUTE.PATIENTS.SUB_ROUTE.CALL_REQUESTS.VALUE}`}
                />
              }
            />
            <Route
              path={`${ROUTE.PATIENTS.VALUE}${ROUTE.PATIENTS.SUB_ROUTE.CALL_REQUESTS.VALUE}/:${ROUTE.PATIENTS.SUB_ROUTE.CALL_REQUESTS.PARAM}/*`}
              element={
                <PatientCallRequestsContainer
                  rootRoute={`${ROUTE.PATIENTS.VALUE}${ROUTE.PATIENTS.SUB_ROUTE.CALL_REQUESTS.VALUE}`}
                />
              }
            />
            <Route
              exact path={`${ROUTE.PATIENTS.VALUE}${ROUTE.PATIENTS.SUB_ROUTE.INCOMPLETE.VALUE}`}
              element={
                <PatientsIncompleteInfoContainer
                  rootRoute={`${ROUTE.PATIENTS.VALUE}${ROUTE.PATIENTS.SUB_ROUTE.INCOMPLETE.VALUE}`}
                />
              }
            />
            <Route
              path={`${ROUTE.PATIENTS.VALUE}${ROUTE.PATIENTS.SUB_ROUTE.INCOMPLETE.VALUE}/:${ROUTE.PATIENTS.SUB_ROUTE.INCOMPLETE.PARAM}/*`}
              element={
                <PatientsIncompleteInfoContainer
                  rootRoute={`${ROUTE.PATIENTS.VALUE}${ROUTE.PATIENTS.SUB_ROUTE.INCOMPLETE.VALUE}`}
                />
              }
            />

            <Route
              exact path={ROUTE.PHARMACY_TECHNICIANS.VALUE}
              element={
                <PharmacyTechniciansContainer
                  rootRoute={ROUTE.PHARMACY_TECHNICIANS.VALUE}
                />
              }
            />
            <Route
              path={`${ROUTE.PHARMACY_TECHNICIANS.VALUE}/:${ROUTE.PHARMACY_TECHNICIANS.PARAM}/*`}
              element={
                <PharmacyTechniciansContainer
                  rootRoute={ROUTE.PHARMACY_TECHNICIANS.VALUE}
                />
              }
            />

            <Route
              exact path={ROUTE.KEYCARDS.VALUE}
              element={
                <KeycardsContainer
                  rootRoute={ROUTE.KEYCARDS.VALUE}
                />
              }
            />

            <Route
              exact path={ROUTE.PRESCRIPTION_FILL_UPSERT.VALUE}
              element={
                <PrescriptionFillUpsert
                  rootRoute={ROUTE.PRESCRIPTION_FILL_UPSERT.VALUE}
                />
              }
            />

            <Route
              exact path={ROUTE.LOTS.VALUE}
              element={
                <Lots
                  rootRoute={ROUTE.LOTS.VALUE}
                />
              }
            />
            <Route
              path={`${ROUTE.LOTS.VALUE}/:${ROUTE.LOTS.PARAM}/*`}
              element={
                <Lots
                  rootRoute={ROUTE.LOTS.VALUE}
                />
              }
            />

            <Route
              exact path={ROUTE.STOCKING_BATCHES.VALUE}
              element={
                <StockingBatches
                  rootRoute={ROUTE.STOCKING_BATCHES.VALUE}
                />
              }
            />
            <Route
              path={`${ROUTE.STOCKING_BATCHES.VALUE}/:${ROUTE.STOCKING_BATCHES.PARAM}/*`}
              element={
                <StockingBatches
                  rootRoute={ROUTE.STOCKING_BATCHES.VALUE}
                />
              }
            />

            {/*
              <Route
                exact path={ROUTE.VIDEO_CONFERENCES.VALUE}
                element={
                  <VideoConferences
                    rootRoute={ROUTE.VIDEO_CONFERENCES.VALUE}
                  />
                }
              />
            /}

            {/* Admin */}
            <Route
              exact path={ROUTE.ADMIN_MODULE_TEMPLATES.VALUE}
              element={
                <ModuleTemplatesAdminContainer
                  rootRoute={ROUTE.ADMIN_MODULE_TEMPLATES.VALUE}
                />
              }
            />
            <Route
              path={`${ROUTE.ADMIN_MODULE_TEMPLATES.VALUE}/:${ROUTE.ADMIN_MODULE_TEMPLATES.PARAM}/*`}
              element={
                <ModuleTemplatesAdminContainer
                  rootRoute={ROUTE.ADMIN_MODULE_TEMPLATES.VALUE}
                />
              }
            />

            <Route
              exact path={ROUTE.ADMIN_PATIENTS.VALUE}
              element={
                <PatientsAdminContainer
                  rootRoute={ROUTE.ADMIN_PATIENTS.VALUE}
                />
              }
            />

            <Route
              exact path={ROUTE.ADMIN_DRUGS.VALUE}
              element={
                <DrugsAdminContainer
                  rootRoute={ROUTE.ADMIN_DRUGS.VALUE}
                />
              }
            />
            <Route
              path={`${ROUTE.ADMIN_DRUGS.VALUE}/:${ROUTE.ADMIN_DRUGS.PARAM}/*`}
              element={
                <DrugsAdminContainer
                  rootRoute={ROUTE.ADMIN_DRUGS.VALUE}
                />
              }
            />

            <Route
              exact path={ROUTE.ADMIN_MEDICAL_ASSISTANTS.VALUE}
              element={
                <MedicalAssistantsAdminContainer
                  rootRoute={ROUTE.ADMIN_MEDICAL_ASSISTANTS.VALUE}
                />
              }
            />

            <Route
              exact path={ROUTE.ADMIN_MACHINE_TECHNICIANS.VALUE}
              element={
                <MachineTechniciansAdminContainer
                  rootRoute={ROUTE.ADMIN_MACHINE_TECHNICIANS.VALUE}
                />
              }
            />

            <Route
              exact path={ROUTE.ADMIN_PHARMACY_TECHNICIANS.VALUE}
              element={
                <PharmacyTechniciansAdminContainer
                  rootRoute={ROUTE.ADMIN_PHARMACY_TECHNICIANS.VALUE}
                />
              }
            />

            <Route
              exact path={ROUTE.ADMIN_PHYSICIANS.VALUE}
              element={
                <PhysiciansAdminContainer
                  rootRoute={ROUTE.ADMIN_PHYSICIANS.VALUE}
                />
              }
            />

            <Route
              exact path={ROUTE.ADMIN_USERS.VALUE}
              element={
                <UsersAdminContainer
                  rootRoute={ROUTE.ADMIN_USERS.VALUE}
                />
              }
            />

            <Route
              exact path={ROUTE.ADMIN_PHARMACIES.VALUE}
              element={
                <PharmaciesAdminContainer
                  rootRoute={ROUTE.ADMIN_PHARMACIES.VALUE}
                />
              }
            />

            {/* Default Redirect */}
            <Route
              path='*'
              element={
                <Navigate
                  to={ROUTE.DISPENSE_ATTEMPTS.VALUE}
                />
              }
            />
          </Routes>
        </ContentGate>
      </div>
      <NotificationsPaneContainer />
    </div>
  </div>;
};

export default observer(AppFrameView);
