import React from 'react';
import { observer } from 'mobx-react';
import { get as _get } from'lodash';

import PhysicianIcon from 'assets/icons/physician-active.svg';

import './employee-info-style.scss';

const EmployeeInfo = ({ notificationPayload = {} }) => {
  const callerName = _get(notificationPayload, 'caller')
    ? `${notificationPayload.caller.firstName} ${notificationPayload.caller.lastName}`
    : _get(notificationPayload, 'kiosk.name', 'N/A');
  const title = _get(notificationPayload, 'callerType') || 'KIOSK';

  const pharmacy = {
    name: _get(notificationPayload, 'kiosk.pharmacy.name', 'N/A'),
    street: _get(notificationPayload, 'kiosk.pharmacy.location.street1', 'N/A'),
    address: `${_get(notificationPayload, 'kiosk.pharmacy.location.city')}, ${_get(notificationPayload, 'kiosk.pharmacy.location.state')} ${_get(notificationPayload, 'kiosk.pharmacy.location.zip')}`
  };

  return (
    <div className='c-EmployeeInfo'>
      <div className='header'>
        <img
          className='icon'
          src={PhysicianIcon}
        />
        <div className='title'>
          Personnel
        </div>
      </div>
      <div className='content'>
        <div className='caller'>
          <div className='key-value-container'>
            <div className='key'>
              Name
            </div>
            <div className='value'>
              {
                callerName
              }
            </div>
          </div>
          <div className='key-value-container'>
            <div className='key'>
              Title
            </div>
            <div className='value'>
              {
                title
              }
            </div>
          </div>
        </div>
        <div className='location'>
          <div className='key-value-container'>
            <div className='key'>
              Location
            </div>
            <div className='value'>
              {
                pharmacy.name
              }
              <br/>
              {
                pharmacy.street
              }
              <br/>
              {
                pharmacy.address
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(EmployeeInfo);
