import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';

// View
import ActivityView from './activity-view';

import { ALL_TYPES_FILTER, APP_COMMAND_TYPES } from './constants';

// Global Stores
import stores from 'bootstrap/store';
const machineTechniciansStore = stores.global.machineTechnicians;
const machineTechniciansRouteStore = stores.route.machineTechnicians.domain;
const appCommandsStore = stores.global.appCommands;

const ActivityContainer = ({ activeMachineTechnician }) => {
  const fetchAppCommandsForMachineTechnician = useCallback(async () => {
    let config = {
      params: { machineTechnicianId: activeMachineTechnician.id },
    };

    if (machineTechniciansRouteStore.machineTechnicianLogsFilter !== ALL_TYPES_FILTER.KEY) {
      config.query = {
        query: {
          type: machineTechniciansRouteStore.machineTechnicianLogsFilter,
        }
      };
    } else {
      config.query = {
        query: {
          type: {
            $in: Object.values(APP_COMMAND_TYPES)
          }
        }
      };
    }

    config.query.offset = appCommandsStore.dataForMachineTechnician.size;

    machineTechniciansRouteStore.incrementHttp('listingMachineTechnicianAppCommands');
    return appCommandsStore.listForMachineTechnician(config)
    .finally(() => {
      machineTechniciansRouteStore.decrementHttp('listingMachineTechnicianAppCommands');
    });
  }, [activeMachineTechnician]);

  const fetchMachineTechnicianStatistic = useCallback(async () => {
    return machineTechniciansStore.fetchMachineTechnicianStatistic({
      params: {
        machineTechnicianId: activeMachineTechnician.id,
      },
    });
  }, [activeMachineTechnician]);

  const setMachineTechnicianLogFilter = useCallback(async (data) => {
    const { value } = data || { value: ALL_TYPES_FILTER.KEY };

    if (value === machineTechniciansRouteStore.machineTechnicianLogsFilter) {
      return;
    }

    machineTechniciansRouteStore.setValue('machineTechnicianLogsFilter', value);
    appCommandsStore.setValue('dataForMachineTechnician', {});

    return fetchAppCommandsForMachineTechnician();
  }, [fetchAppCommandsForMachineTechnician]);

  useEffect(() => {
    fetchAppCommandsForMachineTechnician();
    fetchMachineTechnicianStatistic();

    return () => {
      appCommandsStore.setValue('dataForMachineTechnician', {});
      machineTechniciansStore.setValue('machineTechnicianStatistic', {});
    };
  }, []);

  return <ActivityView
    machineTechniciansStore={machineTechniciansStore}
    machineTechniciansRouteStore={machineTechniciansRouteStore}
    activeMachineTechnician={activeMachineTechnician}
    setMachineTechnicianLogFilter={setMachineTechnicianLogFilter}
    eventLogs={appCommandsStore.appCommandsForMachineTechnicianByDate}
    statistic={machineTechniciansStore.machineTechnicianStatistic}
    fetchMachineTechnicianAppCommands={fetchAppCommandsForMachineTechnician}
    activeFilter={machineTechniciansRouteStore.machineTechnicianLogsFilter}
  />;
};

export default observer(ActivityContainer);
