import React from 'react';
import { observer } from 'mobx-react';

import ListHeader from 'components/drill-down-layout/components/list/components/list-header';

const PatientsListHeader = ({
  filterValue,
  setFilterValue,
}) => (
  <ListHeader
    title='New Patient Info Submission'
    // Search
    searchValue={filterValue}
    onSearch={(e) => setFilterValue(e)}
    searchPlaceholder='Patient Name...'
  />
);

export default observer(PatientsListHeader);
