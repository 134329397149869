import React from 'react';
import { observer } from 'mobx-react';

import './index.scss';


const AdminTableView = ({
  customClassName,
  children
}) => (
  <div className={`c-AdminTableView ${customClassName || ''}`}>
    {
      children
    }
  </div>
);

export default observer(AdminTableView);
