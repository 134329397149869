import React from 'react';
import { observer } from 'mobx-react';

import Data from './components/data';
import Footer from './components/footer';

const TechnicianUpsertModalTechnicianData = ({
  upsertModalRouteStore,
  activeTechnician,
  activelyModifiedTechnician,
  createTechnician,
  updateTechnician,
  showDeleteConfirmationModal,
}) => (
  <React.Fragment>
    <Data
      upsertModalRouteStore={upsertModalRouteStore}
      activelyModifiedTechnician={activelyModifiedTechnician}
    />
    <Footer
      upsertModalRouteStore={upsertModalRouteStore}
      activeTechnician={activeTechnician}
      createTechnician={createTechnician}
      updateTechnician={updateTechnician}
      showDeleteConfirmationModal={showDeleteConfirmationModal}
    />
  </React.Fragment>
);

export default observer(TechnicianUpsertModalTechnicianData);
