import React from 'react';
import ReactSelect from 'react-select';
import { observer } from 'mobx-react';
import { values as _values } from 'lodash';

import { CAMERA_TYPES, CAMERA_HEADERS } from 'models/cameras/constants';

import Modal from 'components/modal';
import WebCamPreview from './components/webCamPreview';

import './style.scss';

const CameraConfigurationModalView = ({
  camerasStore,
  closeModal,
}) => {
  const options = camerasStore.machineWebCamDevices.map(device => ({label: device.label, value: device.deviceId}));

  return (
    <Modal
      modalClassName='c-selectWebCamModal'
      modalStyles={{ height: 'max-content', width: '80%' }}
      isOpen={camerasStore.showingCameraConfigurationModal}
      shouldCloseOnOverlayClick={true}
      close={closeModal}
    >
      <div className='close-icon' onClick={closeModal}>
        x
      </div>
      <div className='scroll-wrapper'>
        {
          _values(CAMERA_TYPES).map(type => (
            <div className='camera-selection-block' key={type}>
              <div className='header'>
                Camera for {CAMERA_HEADERS[type]}
              </div>
              <ReactSelect
                className='camera-select'
                isSearchable={false}
                isClearable={false}
                options={options}
                onChange={(value) => {
                  if (value && !Array.isArray(value)) camerasStore.setDeviceByType(type, value.value);
                }}
                value={options.find(option => option.value === camerasStore.deviceByTypes[type])}
              />
              <WebCamPreview deviceId={camerasStore.deviceByTypes[type]}/>
            </div>
          ))
        }
      </div>
    </Modal>
  );
};

export default observer(CameraConfigurationModalView);
