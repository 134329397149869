import { types } from 'mobx-state-tree';
import { sortBy as _sortBy } from 'lodash';

import { KIOSKS } from 'constants/routes';

const KIOSK_TAB_OPTIONS = Object.values(KIOSKS.CONTENT_TAB).map(({ TITLE }) => TITLE);

export const KiosksRouteStore = types.model({
})
.props({
  kiosks: types.optional(types.map(types.frozen()), {}),
  activeTab: types.optional(types.enumeration(KIOSK_TAB_OPTIONS), KIOSKS.CONTENT_TAB.ACTIVITY.TITLE),
  http: types.optional(types.model({
    listingKiosk: types.optional(types.number, 0)
  }), {}),
  activeKioskId: types.maybeNull(types.string),
  filterValue: types.optional(types.string, ''),
  currentPage: types.optional(types.number, 1),
})
.actions(self => ({
  setValue: (prop, value) => self[prop] = value,
  incrementHttp: (http) => ++self.http[http],
  decrementHttp: (http) => --self.http[http],
  reset: () => {
    self.kiosks = {};
    self.http = {};
    self.activeKioskId = null;
    self.filterValue = '';
    self.currentPage = 1;
  },
}))
.views(self => ({
  get kiosksList() {
    return Array.from(self.kiosks.values());
  },
  get activeKiosk() {
    return self.activeKioskId && self.kiosks.get(self.activeKioskId);
  },
}))
.views(self => ({
  get groupedKiosksList() {
    const kiosksByCity = {};
    self.kiosksList.forEach((kiosk) => {
      const { city } = kiosk.location;
      kiosksByCity[city] = kiosksByCity[city] || { locationId: kiosk.locationId, title: city, kiosks: [] };
      kiosksByCity[city].kiosks.push(kiosk);
    });

    const groupedKiosksList = Object.values(kiosksByCity);
    return _sortBy(groupedKiosksList, 'title');
  },
}));


export const kiosksRouteStore = KiosksRouteStore.create({});
