import { types } from 'mobx-state-tree';
import { get as _get, set as _set } from 'lodash';

import stores from 'bootstrap/store';

export const PhysiciansRouteStore = types.model({
})
.props({
  filterValue: types.optional(types.string, ''),
  activePhysicianId: types.maybeNull(types.string),
  sortingColumn: types.optional(types.model({
    fieldName: types.optional(types.string, 'name'),
    sortingOrder: types.optional(types.string, 'asc')
  }), {}),
  http: types.optional(types.model({
    listingForPharmacist: types.optional(types.number, 0),
    countingForPharmacist: types.optional(types.number, 0),
  }), {}),
  currentPage: types.optional(types.number, 1),
  showingPhysicianUpsertModal: types.optional(types.boolean, false)
})
.actions(self => ({
  setValue: (path, value) => _set(self, path, value),
  incrementHttp: (http) => ++self.http[http],
  decrementHttp: (http) => --self.http[http],
}))
.views(self => ({
  get loading() {
    return !!(self.http.listingForPharmacist && self.http.countingForPharmacist);
  },
  get activePhysician() {
    const physiciansStore = stores.global.physicians;
    return self.activePhysicianId && physiciansStore.dataForPharmacist.get(self.activePhysicianId);
  },
  get forTable() {
    const physiciansStore = stores.global.physicians;
    return physiciansStore.dataAsArray.map((physician) => ({
      id: physician.id,
      name: `${physician.firstName} ${physician.lastName}`,
      NPI: physician.NPI,
      SPI: physician.SPI,
      stateLicenseNumber: physician.stateLicenseNumber,
      phoneNumber: _get(physician, 'physicianUser.user.phoneNumber') || 'N/A',
    }));
  },
}));

export const physiciansRouteStore = PhysiciansRouteStore.create({});
