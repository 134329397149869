// Helpers
import listPharmacyTechniciansDebounce from './listPharmacyTechniciansDebounce';

// Stores
import stores from 'bootstrap/store';
import { pharmacyTechniciansRouteStore } from '../domain/store';
const pharmacyTechniciansStore = stores.global.pharmacyTechnicians;

export default (e) => {
  pharmacyTechniciansRouteStore.setValue('filterValue', e.target.value);
  pharmacyTechniciansRouteStore.setValue('currentPage', 1);
  pharmacyTechniciansStore.setValue('pharmacyTechnicians', {});

  listPharmacyTechniciansDebounce();
};
