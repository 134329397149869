import React, { useCallback, useRef } from 'react';

import './infinite-scroll-styles.scss';

const InfiniteScroll = ({ children, className, onBottomThreshold, onTopThreshold }) => {
  const containerRef = useRef();

  const onScrollEvent = useCallback((event) => {
    if (onBottomThreshold && containerRef.current) {
      const { scrollHeight, scrollTop, clientHeight } = containerRef.current;
      const isBottom = Math.ceil(scrollTop) + clientHeight >= scrollHeight;
      if (isBottom) {
        onBottomThreshold(event);
      }
    }

    if (onTopThreshold && event.target.scrollTop === 0) {
      onTopThreshold(event);
    }
  }, [onBottomThreshold, onTopThreshold]);

  return (
    <div
      className={`c-InfiniteScroll ${className}`}
      ref={containerRef}
      onScroll={onScrollEvent}
    >
      {
        children
      }
    </div>
  );
};

export default InfiniteScroll;
