import stores from 'bootstrap/store';
import base from 'models/base';
import { MODEL_NAME, SCHEMA } from './constants/schema';
import { CONFERENCE_STATUS } from '../video-conferences/constants';
import videoConferenceHelpers from '../video-conferences/helpers';


const VideoConferenceInvitationModel = base({
  names: {
    singular: MODEL_NAME.SINGULAR,
    plural: MODEL_NAME.PLURAL,
  },
  JSONSchema: SCHEMA,
  httpConfig: {
    root: MODEL_NAME.plural,
    methods: {},
  },
});


VideoConferenceInvitationModel.configureStore((store) => {
  return store
  .actions(self => ({
    acceptInvitation: ({ label, purpose, videoConferenceInvitationId }, { twilioOpts } = {}, config = {}) => {
      if (!videoConferenceInvitationId) {
        return Promise.reject('videoConferenceInvitationId must be passed in order to accept invitation');
      }

      const videoConferencesStore = stores.global.videoConferences;

      const key = purpose + (label ? `_${label}` : '');

      // Determine if there is an existing conference currently connecting.
      const existing = videoConferencesStore.conferences.get(key);
      if (existing && videoConferencesStore.isSettingUpConference(key)) return existing;

      // Delete existing.
      videoConferenceHelpers.cleanUp(videoConferencesStore, key);

      const initialRoute = stores.router.location.pathname;

      videoConferencesStore.setConferenceStatus(key, CONFERENCE_STATUS.CONNECTING_API_VIDEO_CONFERENCE);

      config.urlFragment = () => `/${videoConferenceInvitationId}/accept`;
      return self.put(config)
      .catch(() => videoConferenceHelpers.handleVideoConferenceApiConnectFailure(videoConferencesStore, key))
      .then((response) => videoConferenceHelpers.handleVideoConferenceApiConnect(videoConferencesStore, response, {
        key,
        label,
        purpose,
        initialRoute,
      }, { twilioOpts }));
    },
    rejectInvitation: (config = {}) => {
      config.urlFragment = (params) => `/${params.videoConferenceInvitationId}/reject`;
      return self.put(config)
      .then((response) => {
        const videoConferencesStore = stores.global.videoConferences;
        videoConferencesStore.listActive();
        return response;
      });
    },
  }));
});


export default VideoConferenceInvitationModel;
