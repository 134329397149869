import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import CallRequestsNotificationsView from './call-requests-notifications-view';

import { NOTIFICATION_TYPE } from '../../../../constants';

import { fetchNotificationsRelatedData } from '../../../../helpers';

import stores from 'bootstrap/store';
import { goToReview } from './helpers';


const CallRequestsNotificationsContainer = () => {
  useEffect(() => {
    fetchNotificationsRelatedData(NOTIFICATION_TYPE.CALL_REQUESTS);
  }, []);

  return <CallRequestsNotificationsView
    callRequests={stores.global.callRequests.queuedList}
    onClick={goToReview}
  />;
};

export default observer(CallRequestsNotificationsContainer);
