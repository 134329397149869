import { types } from 'mobx-state-tree';
import {
  difference as _difference,
  get as _get,
  set as _set,
} from 'lodash';

import MachineTechnicianModel from 'models/machine-technicians';

import stores from 'bootstrap/store';
import { machineTechniciansRouteStore } from './store';
const kiosksStore = stores.global.kiosks;

export const MachineTechnicianUpsertModalRouteStore = types.model({
})
.props({
  activelyModifiedTechnician: types.maybeNull(types.frozen()),
  workPlaceFilter: types.optional(types.string, ''),
  showingDeleteConfirmationModal: types.optional(types.boolean, false)
})
.actions(self => ({
  setValue: (path, value) => _set(self, path, value),
  setInitialState() {
    self.activelyModifiedTechnician = null;
    self.workPlaceFilter = '';
    self.showingDeleteConfirmationModal = false;
  },
  setActivelyModifiedTechnicianUser: (path, value) => {
    if (!_get(self, 'activelyModifiedTechnician.mtUser.user')) return;
    self.setValue(`activelyModifiedTechnician.mtUser.user.${path}`, value);
  },
  addKiosk: (kiosk) => {
    if (!self.activelyModifiedTechnician) return;
    self.activelyModifiedTechnician.kiosks = self.activelyModifiedTechnician.kiosks || [];
    self.activelyModifiedTechnician.kiosks.push(kiosk);
  },
  deleteKiosk: (kiosk) => {
    self.activelyModifiedTechnician.kiosks = self.activelyModifiedTechnician.kiosks || [];
    const removalIndex = self.activelyModifiedTechnician.kiosks.findIndex((technicianKiosk) => {
      return technicianKiosk.id === kiosk.id;
    });
    if (removalIndex !== -1) self.activelyModifiedTechnician.kiosks.splice(removalIndex, 1);
  },
}))
.views(self => ({
  get readyForCreation() {
    const { activelyModifiedTechnician } = self;
    return (
      activelyModifiedTechnician &&
      activelyModifiedTechnician.firstName &&
      activelyModifiedTechnician.lastName
    );
  },
  get readyForUpdate() {
    const { activeTechnician } = machineTechniciansRouteStore;
    const { activelyModifiedTechnician } = self;
    if (!activeTechnician || !activelyModifiedTechnician) return false;

    const kioskIds = (activeTechnician.kiosks || []).map((kiosk) => kiosk.id);
    const modifiedKioskIds = (activelyModifiedTechnician.kiosks || []).map((kiosk) => kiosk.id);
    return (
      activeTechnician.firstName !== activelyModifiedTechnician.firstName ||
        activeTechnician.lastName !== activelyModifiedTechnician.lastName ||
        _difference(kioskIds, modifiedKioskIds).length ||
        _difference(modifiedKioskIds, kioskIds).length ||
        _get(activeTechnician, 'mtUser.user.email') !== _get(activelyModifiedTechnician, 'mtUser.user.email') ||
        _get(activeTechnician, 'mtUser.user.phoneNumber') !== _get(activelyModifiedTechnician, 'mtUser.user.phoneNumber')
    );
  },
  get suggestedWorkPlaces() {
    const { activelyModifiedTechnician } = self;
    if (!activelyModifiedTechnician) return [];
    const { kiosks } = activelyModifiedTechnician;
    const modifiedKioskIds = kiosks.map((kiosk) => kiosk.id);

    const { forAdminList } = kiosksStore;
    return forAdminList.filter((kiosk) => !modifiedKioskIds.includes(kiosk.id));
  },
  get activelyModifiedTechnicianUser() {
    return _get(self, 'activelyModifiedTechnician.mtUser.user');
  },
  get activeTechnicianWorkPlaces() {
    const { activelyModifiedTechnician } = self;
    if (!activelyModifiedTechnician) return undefined;
    return activelyModifiedTechnician.kiosks;
  },
}));

export const machineTechnicianUpsertModalRouteStore = MachineTechnicianUpsertModalRouteStore.create({});
