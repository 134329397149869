import React from 'react';
import { observer } from 'mobx-react';

import NotificationWrapper from '../notification-wrapper';
import PrescriptionInfo from './components/prescription-info';

import './dispense-attempts-notifications-view-style.scss';

const DispenseAttemptsNotificationsView = ({
  pending = [],
  goToReview,
}) => (
  <div className='c-DispenseAttemptsNotifications'>
    {
      !pending.length &&
      <div className='no-data-caption'>
        No Dispense Attempts To Review
      </div>
    }
    {
      pending.map((dispenseAttempt) => (
        <NotificationWrapper
          key={dispenseAttempt.id}
          onClick={() => goToReview(dispenseAttempt.id)}
        >
          <PrescriptionInfo
            dispenseAttempt={dispenseAttempt}
          />
        </NotificationWrapper>
      ))
    }
  </div>
);

export default observer(DispenseAttemptsNotificationsView);
