import stores from 'bootstrap/store';
import Promise from 'bluebird';

const {
  dispenseAttempts: dispenseAttemptsStore,
  videoConferences: videoConferencesStore,
} = stores.global;


export default (dispenseAttempt) => {
  if (!dispenseAttempt) return;

  return dispenseAttemptsStore.approve({
    params: {
      dispenseAttemptId: dispenseAttempt.id,
    },
  })
  .then(() => {
    return Promise.join(
      dispenseAttemptsStore.listPendingForNotificationsPane(),
      videoConferencesStore.listActive()
    );
  });
};
