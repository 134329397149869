import deepFreeze from 'deep-freeze';

export const MODEL_NAME = Object.freeze({
  SINGULAR: 'kiosk',
  PLURAL: 'kiosks'
});

export const FIELD_NAME = Object.freeze({
  ID: 'id',
  NAME: 'name',
  PHARMACY_ID: 'pharmacyId',
  LOCATION_ID: 'locationId',
  LOCATION: 'location',
  KIOSK_STATUS: 'kioskStatus',
  LATEST_STATUS: 'latestStatus',
  VERSION: 'version',
  CREATED_AT: 'createdAt',
  UPDATED_AT: 'updatedAt',
  COMPUTED_INVENTORY: 'computed_inventory',
  PHARMACY: 'pharmacy',
  KIOSK_STORAGE_AREAS: 'kioskStorageAreas',
  X: 'x',
  Y: 'y',
  ORIENTATION_Y: 'orientationY',
  KIOSK_MODULES: 'kioskModules',
  MODULE_TEMPLATE_ID: 'moduleTemplateId',
  KIOSK_STORAGE_AREA_ID: 'kioskStorageAreaId',
  MODULE_TEMPLATE: 'moduleTemplate',
  WIDTH: 'width',
  HEIGHT: 'height',
  SLOTS: 'slots',
  RADIUS: 'radius',
  POSITION: 'position',
  STREET_1: 'street1',
  CITY: 'city',
  STATE: 'state',
  ZIP: 'zip',
  KIOSK_ID: 'kioskId',
  MODULE_SLOT_TEMPLATES: 'moduleSlotTemplates',
  SLOT_TEMPLATE: 'slot_template',
  DESCRIPTION: 'description',
  CONTAINER_CONSTRAINT_HEIGHT_MAX: 'containerConstraintHeightMax',
  CONTAINER_CONSTRAINT_HEIGHT_MIN: 'containerConstraintHeightMin',
  CONTAINER_CONSTRAINT_LENGTH_MAX: 'containerConstraintLengthMax',
  CONTAINER_CONSTRAINT_LENGTH_MIN: 'containerConstraintLengthMin',
  CONTAINER_CONSTRAINT_RADIUS_MAX: 'containerConstraintRadiusMax',
  CONTAINER_CONSTRAINT_RADIUS_MIN: 'containerConstraintRadiusMin',
  CONTAINER_CONSTRAINT_WIDTH_MAX: 'containerConstraintWidthMax',
  CONTAINER_CONSTRAINT_WIDTH_MIN: 'containerConstraintWidthMin',

  IS_PRINTER_ONLINE: 'isPrinterOnline',
  IS_PAPER_IN_STOCK: 'isPaperInStock',
  IS_IN_FAULTED_STATE: 'isInFaultedState',
  HUMIDITY: 'humidity',
  TEMPERATURE: 'temperature',
  CPU_USAGE: 'cpuUsage',
  MEMORY_USAGE: 'memoryUsage',
});

export const SCHEMA = deepFreeze({
  title: MODEL_NAME.SINGULAR,
  type: 'object',
  properties: {
    [FIELD_NAME.ID]: {
      type: 'string'
    },
    [FIELD_NAME.NAME]: {
      type: 'string'
    },
    [FIELD_NAME.PHARMACY_ID]: {
      type: 'string'
    },
    [FIELD_NAME.LOCATION_ID]: {
      type: 'string',
    },
    [FIELD_NAME.LOCATION]: {
      type: 'object',
      properties: {
        [FIELD_NAME.STREET_1]: {
          type: 'string'
        },
        [FIELD_NAME.CITY]: {
          type: 'string'
        },
        [FIELD_NAME.STATE]: {
          type: 'string'
        },
        [FIELD_NAME.ZIP]: {
          type: 'string'
        }
      }
    },
    [FIELD_NAME.KIOSK_STATUS]: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          [FIELD_NAME.ID]: {
            type: 'string'
          },
          [FIELD_NAME.KIOSK_ID]: {
            type: 'string'
          },
          [FIELD_NAME.CREATED_AT]: {
            type: 'string'
          },
          [FIELD_NAME.UPDATED_AT]: {
            type: 'string'
          }
        }
      }
    },
    [FIELD_NAME.LATEST_STATUS]: {
      type: 'object',
      properties: {
        [FIELD_NAME.ID]: {
          type: 'string',
        },
        [FIELD_NAME.IS_PAPER_IN_STOCK]: {
          type: 'boolean',
        },
        [FIELD_NAME.IS_PRINTER_ONLINE]: {
          type: 'boolean',
        },
        [FIELD_NAME.IS_IN_FAULTED_STATE]: {
          type: 'boolean',
        },
        [FIELD_NAME.HUMIDITY]: {
          type: 'number',
          nullable: true,
        },
        [FIELD_NAME.TEMPERATURE]: {
          type: 'number',
          nullable: true,
        },
        [FIELD_NAME.CPU_USAGE]: {
          type: 'number',
          nullable: true,
        },
        [FIELD_NAME.MEMORY_USAGE]: {
          type: 'number',
          nullable: true,
        },
        [FIELD_NAME.CREATED_AT]: {
          type: 'string',
        },
        [FIELD_NAME.UPDATED_AT]: {
          type: 'string',
        },
      },
    },
    [FIELD_NAME.VERSION]: {
      type: 'number'
    },
    [FIELD_NAME.CREATED_AT]: {
      type: 'string'
    },
    [FIELD_NAME.UPDATED_AT]: {
      type: 'string'
    },
    // [FIELD_NAME.COMPUTED_INVENTORY]: {
    //   type: 'object'
    // },
    [FIELD_NAME.PHARMACY]: {
      type: 'object',
      properties: {
        [FIELD_NAME.NAME]: {
          type: 'string'
        }
      }
    },
    [FIELD_NAME.KIOSK_STORAGE_AREAS]: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          [FIELD_NAME.ID]: {
            type: 'string'
          },
          [FIELD_NAME.NAME]: {
            type: 'string'
          },
          [FIELD_NAME.X]: {
            type: 'string'
          },
          [FIELD_NAME.Y]: {
            type: 'string'
          },
          [FIELD_NAME.ORIENTATION_Y]: {
            type: 'string'
          },
          [FIELD_NAME.KIOSK_ID]: {
            type: 'string'
          },
          [FIELD_NAME.KIOSK_MODULES]: {
            type: 'array',
            items: {
              type: 'object',
              properties: {
                [FIELD_NAME.ID]: {
                  type: 'string'
                },
                [FIELD_NAME.NAME]: {
                  type: 'string'
                },
                [FIELD_NAME.X]: {
                  type: 'string'
                },
                [FIELD_NAME.Y]: {
                  type: 'string'
                },
                [FIELD_NAME.MODULE_TEMPLATE_ID]: {
                  type: 'string'
                },
                [FIELD_NAME.KIOSK_STORAGE_AREA_ID]: {
                  type: 'string'
                },
                [FIELD_NAME.MODULE_TEMPLATE]: {
                  type: 'object',
                  properties: {
                    [FIELD_NAME.ID]: {
                      type: 'string'
                    },
                    [FIELD_NAME.WIDTH]: {
                      type: 'string'
                    },
                    [FIELD_NAME.HEIGHT]: {
                      type: 'string'
                    },
                    [FIELD_NAME.MODULE_SLOT_TEMPLATES]: {
                      type: 'array',
                      items: {
                        type: 'object',
                        properties: {
                          [FIELD_NAME.ID]: {
                            type: 'string'
                          },
                          [FIELD_NAME.NAME]: {
                            type: 'string'
                          },
                          [FIELD_NAME.X]: {
                            type: 'string'
                          },
                          [FIELD_NAME.Y]: {
                            type: 'string'
                          },
                          [FIELD_NAME.SLOT_TEMPLATE]: {
                            type: 'object',
                            properties: {
                              [FIELD_NAME.ID]: {
                                type: 'string'
                              },
                              [FIELD_NAME.CONTAINER_CONSTRAINT_HEIGHT_MAX]: {
                                type: 'string'
                              },
                              [FIELD_NAME.CONTAINER_CONSTRAINT_HEIGHT_MIN]: {
                                type: 'string'
                              },
                              [FIELD_NAME.CONTAINER_CONSTRAINT_LENGTH_MAX]: {
                                type: 'string'
                              },
                              [FIELD_NAME.CONTAINER_CONSTRAINT_LENGTH_MIN]: {
                                type: 'string'
                              },
                              [FIELD_NAME.CONTAINER_CONSTRAINT_RADIUS_MAX]: {
                                type: 'string'
                              },
                              [FIELD_NAME.CONTAINER_CONSTRAINT_RADIUS_MIN]: {
                                type: 'string'
                              },
                              [FIELD_NAME.CONTAINER_CONSTRAINT_WIDTH_MAX]: {
                                type: 'string'
                              },
                              [FIELD_NAME.CONTAINER_CONSTRAINT_WIDTH_MIN]: {
                                type: 'string'
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
  },
  required: [
    FIELD_NAME.ID,
    FIELD_NAME.NAME,
    FIELD_NAME.PHARMACY_ID,
    FIELD_NAME.LOCATION_ID,
    FIELD_NAME.LOCATION,
    FIELD_NAME.VERSION,
    FIELD_NAME.CREATED_AT,
    FIELD_NAME.UPDATED_AT,
    // FIELD_NAME.COMPUTED_INVENTORY,
  ]
});
