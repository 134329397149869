import { toJS } from 'mobx';

import { physiciansRouteStore } from '../domain/store';
import { physicianUpsertModalRouteStore } from '../components/physician-upsert-modal/domain/store';

import {
  PHYSICIAN_MANIPULATION_OPTIONS,
  PHYSICIAN_SKELETON,
} from '../constants';
const { CREATE, UPDATE } = PHYSICIAN_MANIPULATION_OPTIONS;

export default (e, purpose = UPDATE) => {
  switch (purpose) {
    case UPDATE:
      physiciansRouteStore.setValue('activePhysicianId', e.content.id);
      physiciansRouteStore.setValue('showingPhysicianUpsertModal', true);
      physicianUpsertModalRouteStore.setValue('activelyModifiedPhysician', toJS(physiciansRouteStore.activePhysician));
      break;
    case CREATE:
      physiciansRouteStore.setValue('showingPhysicianUpsertModal', true);
      physicianUpsertModalRouteStore.setValue('activelyModifiedPhysician', PHYSICIAN_SKELETON);
  }
};
