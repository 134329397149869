import * as ROUTE from 'constants/routes';

import stores from 'bootstrap/store';
import { history } from 'bootstrap/store/router';
const dispenseAttemptsRouteStore = stores.route.dispenseAttempts.domain;
const prescriptionInfoRouteStore = stores.route.dispenseAttempts.routes.prescriptionInfo;

const DISPENSE_ATTEMPTS_ROUTE = ROUTE.DISPENSE_ATTEMPTS.VALUE;

export default (dispenseAttempt) => {
  if (dispenseAttempt && dispenseAttemptsRouteStore.activeDispenseAttempt === dispenseAttempt) return;

  dispenseAttemptsRouteStore.setValue('activeDispenseAttemptId', dispenseAttempt.id);
  prescriptionInfoRouteStore.clearImages();

  const redirectUrl = dispenseAttempt ? `${DISPENSE_ATTEMPTS_ROUTE}/${dispenseAttempt.id}` : DISPENSE_ATTEMPTS_ROUTE;
  history.push(redirectUrl);
};
