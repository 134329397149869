import { get as _get } from 'lodash';

// Utils
import retrieveUuid from 'utils/retrieveUuid';

// Constants
import { RE_UUID } from 'constants/regex';

// Stores
import stores from 'bootstrap/store';
import { prescriptionFillsMailOrderRouteStore } from '../domain/store';
const prescriptionFillsStore = stores.global.prescriptionFills;
const { router } = stores;

export default (nextProps = {}) => {
  const history = nextProps.history || router.history;
  const location = nextProps.location || router.location;

  const prescriptionFillId = _get(nextProps, 'match.params.prescriptionFillId') || retrieveUuid(location.pathname);
  const requestedPrescriptionFillIsAvailable = (
    !!prescriptionFillId &&
    prescriptionFillId.match(RE_UUID) &&
    !!prescriptionFillsStore.mailOrdered.get(prescriptionFillId)
  );

  if (requestedPrescriptionFillIsAvailable) {
    prescriptionFillsMailOrderRouteStore.setValue('activePrescriptionFillId', prescriptionFillId);
  } else {
    const firstId = _get(prescriptionFillsStore, 'mailOrderedByDate[0].data[0].id');
    if (!firstId) return prescriptionFillsMailOrderRouteStore.setValue('activePrescriptionFillId', null);

    prescriptionFillsMailOrderRouteStore.setValue('activePrescriptionFillId', firstId);
    const url = prescriptionFillId
      ? location.pathname.replace(prescriptionFillId, firstId)
      : `${location.pathname}/${firstId}`;
    history.push(url);
  }
};
