import { reverse as _reverse } from 'lodash';

import KioskIcon from 'assets/icons/kiosk-active.svg';
import DrugIcon from 'assets/icons/drug-active.svg';
import PhysicianIcon from 'assets/icons/physician-active.svg';

export const PRESCRIPTION_STAT_ORDERED_ENTITIES = [
  {
    fieldName: 'kiosks',
    getName: (data = {}) => data.name,
    icon: {
      src: KioskIcon,
      alt: 'Kiosk',
    },
  },
  {
    fieldName: 'drugs',
    getName: (data = {}) => `${data.name} (${data.NDC})`,
    icon: {
      src: DrugIcon,
      alt: 'Drug',
    },
  },
  {
    fieldName: 'physicians',
    getName: (data = {}) => `${data.firstName} ${data.lastName}`,
    icon: {
      src: PhysicianIcon,
      alt: 'Physician',
    },
  },
];

// _reverse mutates the source array
export const PRESCRIPTION_STAT_INVERTED_ENTITIES = _reverse([...PRESCRIPTION_STAT_ORDERED_ENTITIES]);
