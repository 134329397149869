import { types } from 'mobx-state-tree';

import stores from 'bootstrap/store';

import { CALL_REQUESTS_TAB, CALL_REQUESTS_TABS } from '../constants';

export const CallRequestsRouteStore = types.model({
  activeCallRequestId: types.maybeNull(types.string),
  activeListTab: types.optional(types.enumeration(CALL_REQUESTS_TABS), CALL_REQUESTS_TAB.QUEUED),
  filterValue: types.optional(types.string, ''),
  currentPage: types.optional(types.number, 1),
  http: types.optional(types.model({
    listingCallRequests: types.optional(types.number, 0),
    fetchingCallRequestById: types.optional(types.number, 0),
    creatingPharmacistAction: types.optional(types.number, 0),
  }), {}),
})
.actions(self => ({
  setValue: (prop, value) => self[prop] = value,
  setInitialState: () => {
    self.activeCallRequestId = null;
    self.activeListTab = CALL_REQUESTS_TAB.QUEUED;
    self.filterValue = '';
    self.currentPage = 1;
    self.http = {
      listingCallRequests: 0,
      fetchingCallRequestById: 0,
      creatingPharmacistAction: 0,
    };
  },
  incrementHttp: (http) => ++self.http[http],
  decrementHttp: (http) => --self.http[http]
}))
.views(self => ({
  get activeCallRequest() {
    return self.activeCallRequestId && stores.global.callRequests.dataForPharmacist.get(self.activeCallRequestId);
  },
  get isBusy() {
    return !!self.http.listingCallRequests || !!self.http.creatingPharmacistAction;
  },
}));

export const callRequestsRouteStore = CallRequestsRouteStore.create({});
