import { types } from 'mobx-state-tree';

import stores from 'bootstrap/store';

export const LotsRouteStore = types.model({
  activeLotId: types.maybeNull(types.string),
  filterValue: types.optional(types.string, ''),
  currentPage: types.optional(types.number, 1),
  http: types.optional(types.model({
    listingLots: types.optional(types.number, 0),
  }), {}),
})
.actions(self => ({
  setValue: (prop, value) => self[prop] = value,
  setInitialState: () => {
    self.activeLotId = null;
    self.filterValue = '';
    self.currentPage = 1;
    self.http = {
      listingLots: 0,
    };
  },
  incrementHttp: (http) => ++self.http[http],
  decrementHttp: (http) => --self.http[http]
}))
.views(self => ({
  get activeLot() {
    return (
      self.activeLotId &&
      stores.global.lots.dataForPharmacist.get(self.activeLotId)
    );
  },
  get isBusy() {
    return Boolean(self.http.listingLots);
  },
}));

export const lotsRouteStore = LotsRouteStore.create({});
