import React from 'react';
import { observer } from 'mobx-react';

import ContentHeader from 'components/drill-down-layout/components/content/components/content-header';

const HealthcareSitesContentHeader = ({
  activeHealthcareSite,
}) => {
  if (!activeHealthcareSite) return null;

  const location = activeHealthcareSite.location
    ? `${activeHealthcareSite.location.street1}, ${activeHealthcareSite.location.city}, ${activeHealthcareSite.location.state} ${activeHealthcareSite.location.zip}`
    : 'N/A';

  return <ContentHeader
    title={activeHealthcareSite.name}
    subtitle={location}
  />;
};

export default observer(HealthcareSitesContentHeader);
