import { get as _get } from 'lodash';

import ApprovedIcon from 'assets/icons/online.svg';
import RejectedIcon from 'assets/icons/offline.svg';
import VideoIcon from 'assets/icons/video.svg';

export default (dispenseAttempt = {}) => {
  const consultReq = _get(dispenseAttempt, 'prescriptionFill.consultReq');
  const review = _get(dispenseAttempt, 'review');
  if (!consultReq && !review) return null;

  if (consultReq) return VideoIcon;
  else if (review.approveContainer && review.approvePrescription) return ApprovedIcon;
  else return RejectedIcon;
};
