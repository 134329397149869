import React from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';

import Route from '../route';

const SingleRouteLink = ({
  isRootRouteActive,
  route,
}) => (
  <Link to={route.VALUE}>
    <Route
      isActiveRoute={isRootRouteActive}
      route={route}
    />
  </Link>
);

export default observer(SingleRouteLink);
