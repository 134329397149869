import React, { useCallback } from 'react';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { get as _get } from 'lodash';

import DrugUpsertModalView from './drug-upsert-modal-view';

import formatNDC from 'utils/formatNDC';

import stores from 'bootstrap/store';
import { drugUsertModalRouteStore } from './domain/store';

import { READABLE_ERRORS } from './constants';

const DrugUpsertModalContainer = ({
  isOpen,
  activeDrug,
  onClose = () => {},
}) => {
  if (isOpen) {
    drugUsertModalRouteStore.setActivelyModifiedDrug(activeDrug ? toJS(activeDrug) : {});
  }

  const isUpdateMode = Boolean(activeDrug);
  const title = activeDrug ? activeDrug.name : 'New Drug';

  const tryFormatNDC = useCallback(() => {
    const activelyModifiedDrug = { ...drugUsertModalRouteStore.activelyModifiedDrug };
    if (!isUpdateMode) {
      const NDC = _get(drugUsertModalRouteStore, 'activelyModifiedDrug.NDC');
      if (NDC) {
        const formattedNDC = formatNDC(NDC);
        activelyModifiedDrug.NDC = formattedNDC;
      }
    }
    
    if (activelyModifiedDrug.NDC) {
      drugUsertModalRouteStore.setActivelyModifiedDrug(activelyModifiedDrug);
    }
  }, [isUpdateMode]);

  const closeModal = useCallback(() => {
    drugUsertModalRouteStore.setInitialState();

    if (onClose) {
      return onClose();
    }
  }, [onClose]);

  const upsertDrug = useCallback(async () => {
    const body = isUpdateMode
      ? { id: drugUsertModalRouteStore.activelyModifiedDrug.id, name: drugUsertModalRouteStore.activelyModifiedDrug.name }
      : {
        id: drugUsertModalRouteStore.activelyModifiedDrug.id || null,
        name: drugUsertModalRouteStore.activelyModifiedDrug.name,
        NDC: drugUsertModalRouteStore.activelyModifiedDrug.NDC,
        quantity: drugUsertModalRouteStore.activelyModifiedDrug.quantity,
        strength: drugUsertModalRouteStore.activelyModifiedDrug.strength,
      };

    return stores.global.drugs.upsertDrug({ body })
    .then(() => closeModal())
    .catch((err) => {
      const { readableError } = READABLE_ERRORS.find(({ regex }) => err.res && err.res.text && err.res.text.match(regex) && err.res.text.match(regex).length) || {};
      return drugUsertModalRouteStore.setHttpError(readableError || err.message || null);
    });
  }, [closeModal, isUpdateMode]);

  const deleteDrug = useCallback(async () => {
    if (activeDrug) {
      return stores.global.drugs.deleteDrug(activeDrug.id)
      .then(() => closeModal())
      .catch(() => {
        return drugUsertModalRouteStore.setHttpError('Drug is already associated with a container or prescription. Deletion is unavailable.');
      });
    }
  }, [activeDrug, closeModal]);

  return <DrugUpsertModalView
    drugUsertModalRouteStore={drugUsertModalRouteStore}
    isOpen={isOpen}
    title={title}
    isUpdateMode={isUpdateMode}
    tryFormatNDC={tryFormatNDC}
    onClose={closeModal}
    upsertDrug={upsertDrug}
    deleteDrug={deleteDrug}
  />;
};

export default observer(DrugUpsertModalContainer);
