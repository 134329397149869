// Helpers
import fetchDispenseAttempts from './fetchDispenseAttempts';

// Stores
import { dispenseAttemptsRouteStore } from '../domain/store';

export default (value) => {
  if (value === dispenseAttemptsRouteStore.dispenseAttemptsTab) return;
  dispenseAttemptsRouteStore.setValue('dispenseAttemptsTab', value);
  
  return fetchDispenseAttempts();
};
