import stores from 'bootstrap/store';
import { prescriptionFillsRouteStore } from '../domain/store';

import listPrescriptionFills from './listPrescriptionFills';

import { ACTION } from 'models/prescription-fill-user-actions/constants';

const cancelPrescriptionFill = async (prescriptionFillId) => {
  if (!prescriptionFillId) {
    return;
  }

  prescriptionFillsRouteStore.incrementHttp('cancelling');
  return stores.global.prescriptionFillUserActions.createUserAction({
    prescriptionFillId,
    action: ACTION.CANCEL,
  })
  .finally(() => {
    prescriptionFillsRouteStore.decrementHttp('cancelling');
  
    return listPrescriptionFills({ refetch: true });
  });
};

export default cancelPrescriptionFill;
