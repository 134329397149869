import { types } from 'mobx-state-tree';

import { flatMap as _flatMap, keyBy as _keyBy, orderBy as _orderBy } from 'lodash';

import stores from 'bootstrap/store';

export const KioskInventoryRouteStore = types.model({
  isMounted: types.optional(types.boolean, false),
  drugFilter: types.optional(types.string, ''),
  sortingColumn: types.optional(types.model({
    fieldName: types.optional(types.string, 'name'),
    sortingOrder: types.optional(types.string, 'asc'),
  }), {}),
  modifiedInventory: types.frozen(),
})
.actions(self => ({
  setValue: (prop, value) => self[prop] = value,
  setSortingColumn: (fieldName, sortingOrder) => self.sortingColumn = { fieldName, sortingOrder },
  setInventoryThreshold: ({ drugId, type, threshold }) => {
    const updatedInventory = Object.assign({}, self.modifiedInventory);
    const drug = updatedInventory.drugs.find((drug) => drug.id === drugId);
    if (drug) Object.assign(drug.kioskDrugThreshold, { [type]: threshold });

    self.modifiedInventory = updatedInventory;
  },
}))
.views(self => ({
  getDrugThresholds(drugId) {
    const { modifiedInventory } = self;
    if (!modifiedInventory || !modifiedInventory.drugs) return;

    const drug = modifiedInventory.drugs.find((drug) => drug.id === drugId);
    if (!drug) return;

    return {
      low: drug.kioskDrugThreshold.lowContainersCount,
      high: drug.kioskDrugThreshold.highContainersCount,
    };
  },
  get forTable() {
    const drugsStore = stores.global.drugs;
    const kiosksStore = stores.global.kiosks;
    const kiosksRouteStore = stores.route.kiosks.domain;

    const { modifiedInventory } = self;
    const { computedInventory } = kiosksStore;
    const { activeKiosk } = kiosksRouteStore;
    if (!modifiedInventory || !computedInventory || !activeKiosk) return [];

    const { isDrugStockLow, isDrugStockHigh } = drugsStore;

    const drugIds = Object.keys(activeKiosk.computed_inventory.byQuantity.byDrugId);
    const drugsById = _keyBy(modifiedInventory.drugs, 'id');

    let forTable = _flatMap(drugIds, (drugId) => {
      const drug = drugsById[drugId];

      const drugQuantityVariants = activeKiosk.computed_inventory.byQuantity.byDrugId[drugId];

      return Object.keys(drugQuantityVariants)
      .map((quantity) => {
        const detailed = drugsStore.dataForPharmacist.get(drug.id) || {};
        const drugQuantityVariant = drugQuantityVariants[quantity];
        const { count } = drugQuantityVariant;
        const { low, high } = self.getDrugThresholds(drug.id);
        return {
          id: drug.id,
          name: drug.name,
          NDC: detailed.NDC || 'N/A',
          count: {
            ...count,
            unassignable: count.total - count.assignable,
          },
          quantity,
          lowContainersCount: low,
          isLowStock: isDrugStockLow({ drugId: drug.id, kioskId: activeKiosk.id }),
          highContainersCount: high,
          isHighStock: isDrugStockHigh({ drugId: drug.id, kioskId: activeKiosk.id }),
          strength: drug.strength || drug.activeNumeratorStrength,
        };
      });
    });

    if (self.drugFilter) {
      const regex = new RegExp(self.drugFilter, 'i');
      forTable = forTable.filter((drug) => regex.test(drug.name) || regex.test(drug.NDC));
    }

    return _orderBy(forTable, [self.sortingColumn.fieldName], [self.sortingColumn.sortingOrder]);
  },
}));



export const kioskInventoryRouteStore = KioskInventoryRouteStore.create({});
