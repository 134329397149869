import { MAX_TABLE_ENTRIES_PER_PAGE } from 'models/drugs/constants';

import stores from 'bootstrap/store';
import { drugsRouteStore } from '../domain/store';
const drugsStore = stores.global.drugs;


export default () => {
  const query = {
    offset: MAX_TABLE_ENTRIES_PER_PAGE * (drugsRouteStore.currentPage - 1),
    limit: MAX_TABLE_ENTRIES_PER_PAGE,
  };

  if (drugsRouteStore.filterValue) {
    query.filter = {
      name: drugsRouteStore.filterValue,
      NDC: drugsRouteStore.filterValue,
      UPC: drugsRouteStore.filterValue,
    };
  }

  if (drugsRouteStore.sortingColumn.fieldName && drugsRouteStore.sortingColumn.sortingOrder) {
    query.order = [[drugsRouteStore.sortingColumn.fieldName, drugsRouteStore.sortingColumn.sortingOrder]];
  }

  const listDrugs = () => {
    drugsRouteStore.incrementHttp('listingForPharmacist');
    drugsStore.listDrugs({ query })
    .then(() => drugsRouteStore.decrementHttp('listingForPharmacist'));
  };

  const countDrugs = () => {
    drugsRouteStore.incrementHttp('countingForPharmacist');
    drugsStore.countDrugs({ query })
    .then(() => drugsRouteStore.decrementHttp('countingForPharmacist'));
  };

  return Promise.all([
    listDrugs(),
    countDrugs(),
  ]);
};
