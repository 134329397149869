import React from 'react';
import { observer } from 'mobx-react';
import Select from 'react-select';


// Icons
import filterImage from 'assets/icons/filter.svg';

import './filter-style.scss';

const Filter = ({
  setFilter,
  activeFilter,
  filterList,
}) => (
  <div className='c-appCommandFilter'>
    <img className='filter-image' src={filterImage}/>
    <Select
      className='filter-select'
      onChange={setFilter}
      value={activeFilter}
      options={filterList.map(filter => ({label: filter.NAME, value: filter.KEY}))}
    />
  </div>
);

export default observer(Filter);
