import React from 'react';
import { observer } from 'mobx-react';

import DrillDownLayout from 'components/drill-down-layout';
import List from './components/list';
import Content from './components/content';

import './stocking-batches-view-styles.scss';

const LotsView = ({
  stockingBatchesRouteStore,
  listStockingBatches,
  rootRoute,
}) => (
  <div className='r-LotsView'>
    <DrillDownLayout>
      <List
        stockingBatchesRouteStore={stockingBatchesRouteStore}
        listStockingBatches={listStockingBatches}
        rootRoute={rootRoute}
      />
      <Content
        activeStockingBatchId={stockingBatchesRouteStore.activeStockingBatchId}
        stockingBatchesRouteStore={stockingBatchesRouteStore}
      />
    </DrillDownLayout>
  </div>
);


export default observer(LotsView);
