import { get as _get } from 'lodash';

// Constants
import { STOCKING_BATCHES as STOCKING_BATCHES_ROUTE } from 'constants/routes';

// Stores
import { stockingBatchesRouteStore } from '../domain/store';

const determineActiveLot = (stockingBatchId, navigate) => {
  const isRequestedLotAvailable = !!stockingBatchesRouteStore.stockingBatchesById.get(stockingBatchId);
  if (isRequestedLotAvailable) {
    stockingBatchesRouteStore.setValue('activeStockingBatchId', stockingBatchId);
  } else {
    const firstId = _get(stockingBatchesRouteStore, 'stockingBatchesList[0].id');
    if (firstId) {
      navigate(`${STOCKING_BATCHES_ROUTE.VALUE}/${firstId}`);
    } else {
      stockingBatchesRouteStore.setValue('activeStockingBatchId', null);
    }
  }
};

export default determineActiveLot;
