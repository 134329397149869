import { get as _get } from 'lodash';

export default (data) => {
  if (!data) return [];

  return data.map(appCommand => {
    if (!appCommand.type.includes('Upsert')) return appCommand;

    const isNew = _get(appCommand, 'resolution.indexed.isNew');
    if (typeof isNew !== 'boolean') return appCommand;

    appCommand.type = appCommand.type.replace('Upsert', isNew ? 'Create' : 'Update');

    return appCommand;
  });
};
