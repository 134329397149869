import { flow, types } from 'mobx-state-tree';
import { toJS } from 'mobx';
import {
  get as _get,
  isEqual as _isEqual,
  keyBy as _keyBy,
  orderBy as _orderBy,
} from 'lodash';

import stores from 'bootstrap/store';
import base from 'models/base';

import { MODEL_NAME, SCHEMA } from './constants/schema';

const DrugModel = base({
  names: {
    singular: MODEL_NAME.SINGULAR,
    plural: MODEL_NAME.PLURAL
  },
  JSONSchema: SCHEMA,
  httpConfig: {
    root: MODEL_NAME.plural,
    methods: {
    }
  }
});

DrugModel.configureStore((store) => {
  return store
  .props({
    dataForPharmacist: types.optional(types.map(types.frozen()), {}),
    entriesCount: types.optional(types.number, 0)
  })
  .actions(self => ({
    setValue: (prop, value) => self[prop] = value,
    setInitialState: () => {
      self.setValue('dataForPharmacist', {});
      self.setValue('entriesCount', 0);
    },
    listDrugs: flow(function* (config = {}) {
      const response = yield self.list(config);
      return response.data || response || [];
    }),
    listDrugsByLot: flow(function* (lotId) {
      const response = yield self.list({
        urlFragment: () => `/lots/${lotId}`,
      });
      const data = response.data || response || [];

      return _keyBy(data, 'id');
    }),
    listForPharmacist: flow(function* (config = {}) {
      config.urlFragment = () => '/for-pharmacist';

      const response = yield self.list(config);

      return response;
    }),
    listForPharmacistByKiosk: flow(function* (config = {}) {
      const kioskId = _get(config, 'params.kioskId');
      if (!kioskId) {
        return Promise.reject('"kioskId" param is required in order to list drugs for pharmacist by kiosk.');
      }

      config.urlFragment = () => `/for-pharmacist/kiosks/${kioskId}`;

      const response = yield self.list(config);
      const data = response.data || response || [];

      // If data contains the same elements as dataForPharmacist but in reverse order,
      // mobx-state-tree's map treats it as the same set of elements, so views won't rerender.
      const sameData = _isEqual(self.toArray.map(i => i.id).sort(), data.map(i => i.id).sort());
      if (sameData) self.dataForPharmacist = {};
      self.dataForPharmacist = _keyBy(data, 'id');

      return response;
    }),
    countDrugs: flow(function* (config = {}) {
      config.urlFragment = () => '/count';

      const response = yield self.list(config);

      return response;
    }),
    upsertDrug: flow(function*(config={}) {
      config.urlFragment = () => '/';

      return yield self.put(config);
    }),
    deleteDrug: flow(function*(drugId) {
      return yield self.delete({
        params: { id: drugId },
        urlFragment: () => `/${drugId}`,
      });
    }),
  }))
  .views(self => ({
    get toArray() {
      return Array.from(self.dataForPharmacist.values());
    },
    get thresholdsByDrugId() {
      const kiosksStore = stores.global.kiosks;
      if (!kiosksStore.computedInventory) return {};
      const thresholds = kiosksStore.computedInventory.drugs.map((drug) => ({
        drugId: drug.id,
        lowContainersCount: drug.kioskDrugThreshold.lowContainersCount,
        highContainersCount: drug.kioskDrugThreshold.highContainersCount,
      }));
      return _keyBy(thresholds, 'drugId');
    },
    getContainersTotalCount: ({ drugId, kioskId }) => {
      const kiosksStore = stores.global.kiosks;
      if (!drugId || !kioskId) return;
      const kiosk = kiosksStore.data.get(kioskId);
      if (!kiosk) return;

      const { computed_inventory } = kiosk;
      const drugInventoryInfo = computed_inventory.byDrugId[drugId];
      if (!drugInventoryInfo) return;
      return drugInventoryInfo.count.actionable;
    },
    isDrugStockLow: ({ drugId, kioskId }) => {
      const totalCount = self.getContainersTotalCount({ drugId, kioskId });
      const thresholds = self.thresholdsByDrugId[drugId];
      if (!thresholds) return;
      const { lowContainersCount } = thresholds;

      return totalCount <= lowContainersCount;
    },
    isDrugStockHigh: ({ drugId, kioskId }) => {
      const totalCount = self.getContainersTotalCount({ drugId, kioskId });
      const thresholds = self.thresholdsByDrugId[drugId];
      if (!thresholds) return;
      const { highContainersCount } = thresholds;

      return totalCount >= highContainersCount;
    },
    forInventory(kioskId, inventoryByDrugId, filter, sort) {
      const drugIds = Object.keys(toJS(self.dataForPharmacist));
      let drugs = drugIds.reduce((inventoryDrugs, drugId) => {
        const inventory = inventoryByDrugId[drugId];
        if (!inventory) return inventoryDrugs;

        const decoratedDrug = Object.assign({}, inventory, {
          ...self.dataForPharmacist.get(drugId),
          isStockLow: self.isDrugStockLow({ drugId, kioskId }),
          isStockHigh: self.isDrugStockHigh({ drugId, kioskId }),
        });
        inventoryDrugs.push(decoratedDrug);
        return inventoryDrugs;
      }, []);

      if (filter) {
        let regex = new RegExp(filter, 'i');
        drugs = drugs.filter(e => regex.test(e.name) || regex.test(e.NDC));
      }

      if (sort) drugs = _orderBy(drugs, [sort.path], [sort.direction]);

      return drugs;
    },
  }));
});


export default DrugModel;
