import { types } from 'mobx-state-tree';
import { set as _set } from 'lodash';

import { DISPENSE_ATTEMPTS_TAB, REJECTION_REASONS } from 'models/dispense-attempts/constants';
import { CONSULTATION_REASONS } from 'models/prescription-fills/constants';
import { DISPENSE_ATTEMPTS } from 'constants/routes';

import stores from 'bootstrap/store';


export const DispenseAttemptsRouteStore = types.model({
})
.props({
  http: types.optional(types.model({
    listingForPharmacist: types.optional(types.number, 0)
  }), {}),
  filterValue: types.optional(types.string, ''),
  dispenseAttemptsTab: types.optional(types.string, DISPENSE_ATTEMPTS_TAB.PENDING),
  activeDispenseAttemptId: types.maybeNull(types.string),
  activePrescriptionTab: types.optional(types.string, DISPENSE_ATTEMPTS.CONTENT_TAB.PRESCRIPTION_INFO.TITLE),

  showingRejectionReasonModal: types.optional(types.boolean, false),
  rejectContainer: types.optional(types.boolean, false),
  rejectPrescription: types.optional(types.boolean, false),
  rejectionReason: types.optional(types.model({
    label: types.optional(types.string, ''),
    value: types.optional(types.string, ''),
  }), REJECTION_REASONS[0]),

  showingConsultationRequestModal: types.optional(types.boolean, false),
  showingExpandedPrescriptionInfoModal: types.optional(types.boolean, false),
  consultationReason: types.optional(types.model({
    label: types.optional(types.string, ''),
    value: types.optional(types.string, ''),
  }), CONSULTATION_REASONS[0]),
  isExpanded: types.optional(types.boolean, false),
})
.actions(self => ({
  setValue: (path, value) => _set(self, path, value),
  revertField: (field) => self[field] = !self[field],
  incrementHttp: (http) => ++self.http[http],
  decrementHttp: (http) => --self.http[http],
  resetConsultationRequestModal: () => {
    self.showingConsultationRequestModal = false;
    self.consultationReason = CONSULTATION_REASONS[0];
  },
  reset: () => {
    self.http = {};
    self.filterValue = '';
    self.dispenseAttemptsTab = DISPENSE_ATTEMPTS_TAB.PENDING;
    self.activeDispenseAttemptId = '';
    self.activePrescriptionTab = DISPENSE_ATTEMPTS.CONTENT_TAB.PRESCRIPTION_INFO.TITLE;

    self.showingRejectionReasonModal = false;
    self.rejectContainer = false;
    self.rejectPrescription = false;
    self.rejectionReason = REJECTION_REASONS[0];

    self.showingConsultationRequestModal = false;
    self.showingExpandedPrescriptionInfoModal = false;
    self.consultationReason = CONSULTATION_REASONS[0];
  },
}))
.views(self => ({
  get activeDispenseAttempt() {
    return self.activeDispenseAttemptId && stores.global.dispenseAttempts.dataForPharmacist.get(self.activeDispenseAttemptId);
  },
  get readyToReject() {
    return self.rejectContainer || self.rejectPrescription;
  },
  get readyToRequestConsultation() {
    return !!self.consultationReason && !!self.consultationReason.value;
  },
  get dispenseAttemptsGroupedByDate() {
    const asc = self.dispenseAttemptsTab === DISPENSE_ATTEMPTS_TAB.PENDING;
    return stores.global.dispenseAttempts.groupedByDate(asc);
  }
}));

export const dispenseAttemptsRouteStore = DispenseAttemptsRouteStore.create({});
