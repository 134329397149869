import stores from 'bootstrap/store';

import { fetchDispenseAttempts } from './index';

const refreshDispenseAttemptsOnReviewConflict = async () => {
  stores.global.dispenseAttempts.setValue('reviewConflictMessage', null);
  return fetchDispenseAttempts(true);
};

export default refreshDispenseAttemptsOnReviewConflict;
