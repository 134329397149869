import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import MedicalAssistantsView from './medical-assistants-view';

// Helpers
import * as helpers from './helpers';

// Constants
import { COLUMNS } from './constants';

// Stores
import stores from 'bootstrap/store';
import { medicalAssistantsRouteStore } from './domain/store';
import { medicalAssistantsUpsertModalRouteStore } from './domain/upsert-modal-store';
const medicalAssistantsStore = stores.global.medicalAssistants;


const MedicalAssistantsContainer = () => {
  useEffect(() => {
    helpers.listMedicalAssistants();
  }, []);

  return (
    <MedicalAssistantsView
      store={medicalAssistantsStore}
      routeStore={medicalAssistantsRouteStore}
      upsertModalRouteStore={medicalAssistantsUpsertModalRouteStore}
      smartTableColumns={COLUMNS}
      sortMedicalAssistants={helpers.sortMedicalAssistants()}
      changePaginationPage={helpers.changePaginationPage}
      onMedicalAssistantsSearch={helpers.onMedicalAssistantsSearch}
      showUpsertModal={helpers.showMedicalAssistantUpsertModal}
      closeUpsertModal={helpers.closeMedicalAssistantUpsertModal}
      showDeleteConfirmationModal={helpers.showMedicalAssistantDeleteConfirmationModal}
      addWorkPlaceLocally={helpers.addHealthcareSiteLocally}
      removeWorkPlaceLocally={helpers.removeHealthcareSiteLocally}
      onWorkPlacesSearch={helpers.onHealthcareSitesSearch}
      createMedicalAssistant={helpers.createMedicalAssistant}
      updateMedicalAssistant={helpers.updateMedicalAssistant}
      deleteMedicalAssistant={helpers.deleteMedicalAssistant}
    />
  );
};

export default observer(MedicalAssistantsContainer);
