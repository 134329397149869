import React from 'react';
import { observer } from 'mobx-react';

import Header from './components/header';
import MedicalAssistant from './medical-assistant';
import List from 'components/drill-down-layout/components/list';
import ListItem from 'components/drill-down-layout/components/list/components/list-item';

import PatientIcon from 'assets/icons/patient.svg';

import './medical-assistant-icon.scss';


const MedicalAssistantsList = ({
  medicalAssistantsStore,
  medicalAssistantsRouteStore,
  setFilterValue,
  listMedicalAssistants,
  rootRoute,
}) => (
  <List
    header={<Header
      filterValue={medicalAssistantsRouteStore.filterValue}
      setFilterValue={setFilterValue}
    />}
    isReady={!medicalAssistantsRouteStore.http.listingMedicalAssistants || medicalAssistantsStore.medicalAssistants.size}
    onBottomThreshold={listMedicalAssistants}
  >
    {
      medicalAssistantsStore.dataAsArray.map(medicalAssistant => (
        <ListItem
          key={medicalAssistant.id}
          title={`${medicalAssistant.firstName} ${medicalAssistant.lastName}`}
          isActive={medicalAssistantsRouteStore.activeMedicalAssistantId === medicalAssistant.id}
          iconStyle={{width: '50px', height: '50px'}}
          icon={
            <img
              alt='Medical Assistant'
              className='medical-assistant-icon-preview'
              src={PatientIcon}
            />
          }
          url={`${rootRoute}/${medicalAssistant.id}`}
        >
          <MedicalAssistant
            pharmaciesCaption={medicalAssistantsRouteStore.getMedicalAssistantHealthcareSitesCountCaption(medicalAssistant)}
          />
        </ListItem>
      ))
    }
  </List>
);

export default observer(MedicalAssistantsList);
