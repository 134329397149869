import React from 'react';
import { observer } from 'mobx-react';

// View
import InfoView from './info-view';

const InfoContainer = ({ activeStockingBatch }) => {
  return <InfoView
    activeStockingBatch={activeStockingBatch}
  />;
};

export default observer(InfoContainer);
