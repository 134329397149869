import stores from 'bootstrap/store';

import { isActiveRoute } from 'bootstrap/store/router';

import { DISPENSE_ATTEMPTS as DISPENSE_ATTEMPTS_ROUTE } from 'constants/routes';
import { DISPENSE_ATTEMPTS_TAB } from 'models/dispense-attempts/constants';

const getIsPendingDispenseAttemptBeingDisplayed = (dispenseAttemptId) => {
  const isDispenseAttemptsRouteActive = isActiveRoute(DISPENSE_ATTEMPTS_ROUTE.VALUE);
  const isPendingTabActive = stores.route.dispenseAttempts.domain.dispenseAttemptsTab === DISPENSE_ATTEMPTS_TAB.PENDING;
  const isRequestedDispenseAttemptStored = Boolean(stores.global.dispenseAttempts.dataForPharmacist.get(dispenseAttemptId));

  return isDispenseAttemptsRouteActive && isPendingTabActive && isRequestedDispenseAttemptStored;
};

export default getIsPendingDispenseAttemptBeingDisplayed;
