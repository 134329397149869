import deepFreeze from 'deep-freeze';

export const MODEL_NAME = Object.freeze({
  SINGULAR: 'machineTechnician',
  PLURAL: 'machine-technicians',
});

export const FIELD_NAME = Object.freeze({
  ID: 'id',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  MACHINE_TECHNICIAN_USER: 'mtUser',
  USER: 'user',
  EMAIL: 'email',
  PHONE_NUMBER: 'phoneNumber',
  KIOSKS: 'kiosks',
  NAME: 'name',
});

export const SCHEMA = deepFreeze({
  title: MODEL_NAME.SINGULAR,
  type: 'object',
  properties: {
    [FIELD_NAME.ID]: {
      type: 'string',
    },
    [FIELD_NAME.FIRST_NAME]: {
      type: 'string'
    },
    [FIELD_NAME.LAST_NAME]: {
      type: 'string'
    },
    [FIELD_NAME.MACHINE_TECHNICIAN_USER]: {
      type: 'object',
      properties: {
        [FIELD_NAME.USER]: {
          type: 'object',
          properties: {
            [FIELD_NAME.ID]: {
              type: 'string'
            },
            [FIELD_NAME.FIRST_NAME]: {
              type: 'string'
            },
            [FIELD_NAME.LAST_NAME]: {
              type: 'string'
            },
            [FIELD_NAME.EMAIL]: {
              type: 'string'
            },
            [FIELD_NAME.PHONE_NUMBER]: {
              type: 'string'
            },
          }
        }
      }
    },
    [FIELD_NAME.KIOSKS]: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          [FIELD_NAME.ID]: {
            type: 'string'
          },
          [FIELD_NAME.NAME]: {
            type: 'string'
          }
        }
      }
    }
  }
});
