import React from 'react';
import { observer } from 'mobx-react';

import KeyValuePair from '../keyValuePair';

import '../../prescriptionInfoBlockStyles.scss';

const PhysicianInfoBlock = ({
  physician,
}) => (
  <div className='c-PrescriptionInfoBlock'>
    <div className='title'>
      Physician
    </div>
    <div className='data'>
      <KeyValuePair
        title='Name'
        data={physician}
        keys={['firstName', 'lastName']}
      />
      <KeyValuePair
        title='Email'
        data={physician}
        keys={['physicianUser.user.email']}
      />
      <KeyValuePair
        title='Phone'
        data={physician}
        keys={['physicianUser.user.phoneNumber']}
      />
      <KeyValuePair
        title='SPI'
        data={physician}
        keys={['SPI']}
      />
      <KeyValuePair
        title='NPI'
        data={physician}
        keys={['NPI']}
      />
      <KeyValuePair
        title='State license'
        data={physician}
        keys={['stateLicenseNumber']}
      />
      <KeyValuePair
        title='Facility'
        data={physician}
        keys={['healthcareSites[0].name', 'healthcareSites[0].location.street1', 'healthcareSites[0].location.city', 'healthcareSites[0].location.state', 'healthcareSites[0].location.zip']}
        format={([name, street1, city, state, zip]) => (
          <div className='complex-value'>
            <div className='value'>{name}</div>
            <div className='value'>{street1}</div>
            <div className='value'>{`${city}, ${state}, ${zip}`}</div>
          </div>
        )}
      />
    </div>
  </div>
);

export default observer(PhysicianInfoBlock);
