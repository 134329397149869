import stores from 'bootstrap/store';
import { machineTechnicianUpsertModalRouteStore } from '../domain/upsert-modal-store';

const kiosksStores = stores.global.kiosks;

export default (e) => {
  machineTechnicianUpsertModalRouteStore.setValue('workPlaceFilter', e.target.value);

  return kiosksStores.listForAdmin({
    query: {
      filter: {
        name: machineTechnicianUpsertModalRouteStore.workPlaceFilter,
      },
    },
  });
};
