import { types } from 'mobx-state-tree';
import { set as _set } from 'lodash';

import DrugModel from 'models/drugs';

export const DrugUpdateModalRouteStore = types.model({
})
.props({
  activelyModifiedDrug: types.maybeNull(types.frozen()),
  showingDeleteConfirmationModal: types.optional(types.boolean, false)
})
.actions(self => ({
  setValue: (path, value) => _set(self, path, value),
  setInitialState() {
    self.setValue('activelyModifiedDrug', null);
    self.setValue('showingDeleteConfirmationModal', false);
  }
}));

export const drugUpdateModalRouteStore = DrugUpdateModalRouteStore.create({});
