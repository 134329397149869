import { flow } from 'mobx-state-tree';
import { isString as _isString } from 'lodash';

import base from 'models/base';
import { MODEL_NAME, SCHEMA } from './constants/schema';
import { ACTIONS } from './constants';

const PrescriptionFillUserActionsModel = base({
  names: {
    singular: MODEL_NAME.SINGULAR,
    plural: MODEL_NAME.PLURAL
  },
  JSONSchema: SCHEMA,
  httpConfig: {
    root: MODEL_NAME.plural,
    methods: {}
  }
});

PrescriptionFillUserActionsModel.configureStore((store) => {
  return store
  .props({
  })
  .actions(self => ({
    createUserAction: flow(function* ({ prescriptionFillId, action }) {
      if (!prescriptionFillId || !_isString(prescriptionFillId)) {
        return Promise.reject('"prescriptionFillId" is required in order to create a user action.');
      }

      if (!action || !ACTIONS.includes(action)) {
        return Promise.reject(`"action" must be provided and be one of ${ACTIONS} in order to create a user action.`);
      }

      const response = yield self.post({
        body: {
          prescriptionFillId,
          action,
        },
      });
      return response;
    }),
  }))
  .views(() => ({
  }));
});

export default PrescriptionFillUserActionsModel;
