import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import * as CONSTANTS from './constants';

import ContentScreen from '../content-screen';

import InfiniteScroll from 'components/infinite-scroll';
import SmartTableHeader from './components/smart-table-header';
import SmartTableContent from './components/smart-table-content';

const { PROP_TYPES, EVENT } = CONSTANTS;


const EVENT_HANDLER = {
  [EVENT.ROW_CLICK]: () => {}
};


class SmartTable extends React.Component {
  /**
   *
   */
  handleAction(action) {
    // Call internal event handlers if available for action type.
    const internalEventHandler = EVENT_HANDLER[action.type];
    internalEventHandler && internalEventHandler(this, action);

    // Call generic `on` handler if available on props.
    this.props.on && this.props.on(action);

    // Call specific event handlers like onRowClick, onCellClick
    const formattedActionName = capsCamelCase(action.type);
    const specificHandler = this.props[`on${formattedActionName}`];
    specificHandler && specificHandler(action);
  }

  /**
   *
   */
  getRowId(row = {}) {
    return this.props.getRowId ? this.props.getRowId(row) : row.id;
  }

  /**
   *
   */
  render () {
    const {activeRow, activeRows = {}} = this.props;

    return (
      <div className={`SmartTable ${this.props.customClassName || ''}`}>
        <div className="smart-table-scroll-x">
          {
            !this.props.columnsInvisible &&
            <SmartTableHeader
              sortingFieldName={this.props.sortingFieldName}
              sortingOrder={this.props.sortingOrder}
              onSortIconClick={this.props.onSortIconClick}
              columns={this.props.columns}
            />
          }
          <InfiniteScroll onBottomThreshold={this.props.loadMoreData}>
            <div className='smart-table-content'>
              <ContentScreen active={this.props.loading} />
              {
                this.props.rows.map(row => {
                  const rowId = this.getRowId(row);
                  return <SmartTableContent key={rowId}
                    rowId={rowId}
                    active={(rowId === activeRow) || activeRows[rowId]}
                    columns={this.props.columns}
                    content={row}
                    on={this.handleAction.bind(this)}
                    before={this.props.before}
                    after={this.props.after}
                    columnsInvisible={this.props.columnsInvisible}
                    onRowSelect={this.props.onRowSelect}
                    onRowDeselect={this.props.onRowDeselect}
                    selectUnavailableCaption={this.props.selectUnavailableCaption}
                    hasClickable={this.props.onRowClick}/>;
                })
              }
            </div>
          </InfiniteScroll>
        </div>
      </div>
    );
  }
}

export default observer(SmartTable);

function capsCamelCase(str) {
  return str.split('_').map(word => `${word[0].toUpperCase()}${word.slice(1)}`).join('');
}



// Set Read-Only CONSTANTS on SmartTable.
Object.keys(CONSTANTS).forEach(key => {
  Object.defineProperty(SmartTable, key, {value: CONSTANTS[key]});
});


SmartTable.propTypes = {
  columns: PROP_TYPES.COLUMNS,
  rows: PROP_TYPES.ROWS,
  // Configuration of optional, toggleable content which can appear before rows.
  before: PROP_TYPES.BEFORE,
  // Configuration of optional, toggleable content which can appear after rows.
  after: PROP_TYPES.AFTER,
  on: PROP_TYPES.ON,
  onRowClick: PropTypes.func,
  onCellClick: PropTypes.func,

  // Allow to sort content by chosen column via sortingFieldName.
  // Column won't be sortable if fieldName isn't passed to an object in columns prop.
  sortingFieldName: PropTypes.string,
  sortingOrder: PROP_TYPES.SORTING_ORDER_OPTION,
  onSortIconClick: PROP_TYPES.ON,

  // Allows caller to pass in a function to get the row id from the row, otherwise
  // it is assumed that the .id property is to identify the row uniquely.
  getRowId: PropTypes.func,

  // Active row
  activeRow: PropTypes.object,
  activeRows: PropTypes.object,

  // Checkbox in the end of each row
  onRowSelect: PropTypes.func,
  onRowDeselect: PropTypes.func,
  selectUnavailableCaption: PropTypes.string,

  loadMoreData: PropTypes.func,
  loading: PropTypes.bool,

  columnsInvisible: PropTypes.bool,

  customClassName: PropTypes.string,
};
