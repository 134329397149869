import React from 'react';
import { observer } from 'mobx-react';

import { HEADER_STYLES } from './constants';

import './style.scss';

const Statistic = ({
  statistic,
  blockConfig,
}) => {
  const lastActivityDate = statistic.lastActivityDate? new Date(statistic.lastActivityDate).toLocaleDateString(['en-US'], {
    weekday: 'long',
    month: 'long',
    day: '2-digit',
    year: 'numeric',
  }): undefined;

  const data = {
    ...statistic,
    lastActivityDate,
  };

  return (
    <div className='c-Statistic'>
      {
        blockConfig.blocks.map((config) => {
          return (
            <div className='statistic-block' key={config.key}>
              {
                config.header
                  ? <div className='header'>
                    <div className={`data data-${config.header.style || HEADER_STYLES.GREEN}`}>
                      {config.header.getText(data)}
                    </div>
                  </div>
                  : <div className='header-none' />
              }
              <div className='content'>
                {config.getData(data)}
              </div>
              <div className='footer'>
                <div className='name'>
                  {config.subText}
                </div>
              </div>
            </div>
          );
        })
      }
    </div>
  );
};

export default observer(Statistic);
export { HEADER_STYLES };
