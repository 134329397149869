import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

export default {
  component: ReactPhoneInput,
  specialProps: (props = {}) => ({
    country: 'us',
    onlyCountries: ['us'],
    disableCountryCode: true,
    disableDropdown: true,
    placeholder: '(___) - ___ - ____',
    masks: {
      us: '(...) - ... - ....',
    },
    inputStyle: {
      width: '100%',
      borderColor: props.isRequired ? '#CF6059' : '#CACACA',
      borderWidth: '2px',
    },
    buttonStyle: {
      borderColor: props.isRequired ? '#CF6059' : '#CACACA',
      borderWidth: '2px',
    },
  })
};
