import { keyBy as _keyBy } from 'lodash';

import { MAX_TABLE_ENTRIES_PER_PAGE } from 'models/drugs/constants';

import stores from 'bootstrap/store';
import { drugsRouteStore } from '../domain/store';
const drugsStore = stores.global.drugs;


export default () => {
  const query = {
    offset: MAX_TABLE_ENTRIES_PER_PAGE * (drugsRouteStore.currentPage - 1),
    limit: MAX_TABLE_ENTRIES_PER_PAGE,
  };

  if (drugsRouteStore.filterValue) {
    query.filter = {
      name: drugsRouteStore.filterValue,
      NDC: drugsRouteStore.filterValue,
      UPC: drugsRouteStore.filterValue,
    };
  }

  if (drugsRouteStore.sortingColumn.fieldName && drugsRouteStore.sortingColumn.sortingOrder) {
    query.order = {
      field: drugsRouteStore.sortingColumn.fieldName,
      direction: drugsRouteStore.sortingColumn.sortingOrder,
    };
  }

  const listDrugs = () => {
    drugsRouteStore.incrementHttp('listingForPharmacist');
    drugsStore.listDrugs({ query })
    .then((drugs) => {
      // If data contains the same elements as dataForPharmacist but in reverse order,
      // mobx-state-tree's map treats it as the same set of elements, so views won't rerender.
      drugsRouteStore.setValue('drugsById', {});
      drugsRouteStore.setValue('drugsById', _keyBy(drugs, 'id'));

      drugsRouteStore.decrementHttp('listingForPharmacist');
    });
  };

  const countDrugs = () => {
    drugsRouteStore.incrementHttp('countingForPharmacist');
    drugsStore.countDrugs({ query })
    .then((count) => {
      drugsRouteStore.setValue('entriesCount', count);

      drugsRouteStore.decrementHttp('countingForPharmacist');
    });
  };

  return Promise.all([
    listDrugs(),
    countDrugs(),
  ]);
};
