import { get as _get } from 'lodash';

// Constants
import { CALL_REQUESTS_ROUTE } from '../constants';

// Stores
import stores from 'bootstrap/store';
import { callRequestsRouteStore } from '../domain/store';
const callRequestsStore = stores.global.callRequests;

const determineActivePatientCallRequest = (callRequestId, navigate) => { 
  const requestedPatientCallRequestIsAvailable = !!callRequestsStore.dataForPharmacist.get(callRequestId);
  if (requestedPatientCallRequestIsAvailable) {
    callRequestsRouteStore.setValue('activeCallRequestId', callRequestId);
  } else {
    // TODO fetch info of the requested entry and show it as active once user scrolls to it
    const firstId = _get(callRequestsStore, 'dataForPharmacistList[0].id');
    if (firstId) {
      navigate(`${CALL_REQUESTS_ROUTE}/${firstId}`);
    } else {
      callRequestsRouteStore.setValue('activeCallRequestId', null);
    }
  }
};

export default determineActivePatientCallRequest;
