import React from 'react';
import { observer } from 'mobx-react';

import DrugIcon from 'assets/icons/drug-active.svg';

import './drugs-style.scss';


const Drugs = ({
  isFulfillmentPaid,
  prescriptionFills = [],
  approveDispenseAttempt,
  rejectDispenseAttempt,
}) => (
  <div className='c-FulfillmentDrugs'>
    {
      prescriptionFills.map((prescriptionFill) => {
        const {
          dispenseAttempt,
          drug,
        } = prescriptionFill;

        return <div key={prescriptionFill.id} className='drug'>
          <div className='header'>
            <div className='title'>
              <img alt='drug' src={DrugIcon} />
              <div className='text'>
                {
                  drug.name
                }
              </div>
            </div>
            {
              !prescriptionFill.declined && prescriptionFill.consultReq && dispenseAttempt && !dispenseAttempt.review &&
              <div className='approve-reject-controls'>
                <button
                  className='button approve'
                  onClick={() => approveDispenseAttempt(dispenseAttempt)}
                >
                  Approve
                </button>
                {
                  !isFulfillmentPaid &&
                  <button
                    className='button reject'
                    onClick={() => rejectDispenseAttempt(dispenseAttempt)}
                  >
                    Reject
                  </button>
                }
              </div>
            }
          </div>
          <div className='data'>
            <div className='info'>
              <div className='dosage'>
                <div className='label'>
                    DOSAGE
                </div>
                <div className='info'>
                  {
                    drug.dosage
                  }
                </div>
              </div>
              <div className='rx-id'>
                <div className='label'>
                    RxID
                </div>
                <div className='info'>
                  {
                    drug.rxId
                  }
                </div>
              </div>
            </div>
            <div className='instructions'>
              <div className='label'>
                  INSTRUCTIONS
              </div>
              <div className='info'>
                {
                  drug.directions
                }
              </div>
            </div>
            {
              prescriptionFill.consultReq && prescriptionFill.consultReq.notes &&
              <div className='consultation-request-reason'>
                <div className='label'>
                  CONSULTATION REQUEST REASON
                </div>
                <div className='info'>
                  {
                    prescriptionFill.consultReq.notes
                  }
                </div>
              </div>
            }
          </div>
        </div>;
      })
    }
  </div>
);

export default observer(Drugs);
