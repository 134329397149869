import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import { get as _get } from 'lodash';

import stores from 'bootstrap/store';
import { PURPOSE } from 'models/video-conferences/constants';

// Views
import VideoFeedView from './video-feed-view';

const store = {
  videoConferences: stores.global.videoConferences,
  videoConferenceInvitations: stores.global.videoConferenceInvitations,
  videoConferenceParticipantConnections: stores.global.videoConferenceParticipantConnections,
};


const VideoFeedContainer = ({ activeKiosk }) => {
  const disconnect = useCallback(() => {
    const purpose = PURPOSE.KIOSK_MEDIA_FEED;
    const kioskId = activeKiosk.id;
    const key = `${purpose}_${kioskId}`;

    const conference = store.videoConferences.conferences.get(key) || {};
    const twilioToken = _get(conference, 'participantConnection.twilioToken');

    return store.videoConferences.leaveTotally({ key, twilioToken });
  }, [activeKiosk]);

  useEffect(() => {
    const kioskId = activeKiosk.id;

    store.videoConferences.create({
      label: kioskId,
      purpose: PURPOSE.KIOSK_MEDIA_FEED,
      auxiliaryData: { kioskId },
    }, { twilioOpts: { audio: false, video: false } });

    return () => {
      disconnect();
    };
  }, []);

  const key = `${PURPOSE.KIOSK_MEDIA_FEED}_${activeKiosk.id}`;
  const twilio = store.videoConferences.getTwilioConnection(key) || {};
  const conferenceStatus = store.videoConferences.conferenceStatuses.get(key);

  return (
    <VideoFeedView
      remoteTracksByParticipant={twilio.remoteTracksByParticipant}
      conferenceStatus={conferenceStatus}
    />
  );
};

export default observer(VideoFeedContainer);
