import React from 'react';
import { observer } from 'mobx-react';

// View
import ContactView from './contact-view';

const ContactContainer = ({ activeTechnician }) => {
  return <ContactView
    activeTechnician={activeTechnician}
  />;
};

export default observer(ContactContainer);
