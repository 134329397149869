import { ORDER_OPTIONS } from '../constants';
import { MAX_TABLE_ENTRIES_PER_PAGE } from 'models/keycards/constants';

import stores from 'bootstrap/store';
import { keycardsRouteStore } from '../domain/store';
const keycardsStore = stores.global.keycards;


export default () => {
  const subQuery = {};
  if (keycardsRouteStore.filterValue) {
    const wildcard = `${keycardsRouteStore.filterValue}%`;
    subQuery.$or = [
      { 'value': { '$iLike': wildcard } },
      { '$user.lastName$': { '$iLike': wildcard } },
      { '$user.firstName$': { '$iLike': wildcard } },
    ];
  }

  const query = {
    offset: MAX_TABLE_ENTRIES_PER_PAGE * (keycardsRouteStore.currentPage - 1),
    limit: MAX_TABLE_ENTRIES_PER_PAGE + 1,
    query: subQuery
  };

  if (keycardsRouteStore.sortingColumn.fieldName && keycardsRouteStore.sortingColumn.sortingOrder) {
    query.order = ORDER_OPTIONS[keycardsRouteStore.sortingColumn.fieldName](keycardsRouteStore.sortingColumn.sortingOrder);
  }

  const list = () => {
    keycardsRouteStore.incrementHttp('listingForKeycard');
    return keycardsStore.listKeycards({ query })
    .then(() => keycardsRouteStore.decrementHttp('listingForKeycard'));
  };

  const count = () => {
    keycardsRouteStore.incrementHttp('countingForKeycard');
    return keycardsStore.countKeycards({ query })
    .then(() => keycardsRouteStore.decrementHttp('countingForKeycard'));
  };

  return Promise.all([list(), count()]);
};
