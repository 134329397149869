import React from 'react';
import { observer } from 'mobx-react';
import { get as _get } from 'lodash';

import ContentGate from 'components/content-gate';

// Icons
import DrugIcon from 'assets/icons/drug-active.svg';
import PrescriptionIcon from 'assets/icons/prescriptions-active.svg';

// Styles
import './prescriptions-history-style.scss';

const PrescriptionsHistoryView = ({
  activeCallRequest,
  fetchingActiveCallRequest,
}) => {
  return (
    <div className='c-PrescriptionsHistory'>
      <div className='content'>
        <ContentGate
          active={!activeCallRequest || fetchingActiveCallRequest}
          fadeDuration={500}
          color='none'
        >
          {
            _get(activeCallRequest, 'patientCallRequest.patient.prescriptions', []).length
              ? _get(activeCallRequest, 'patientCallRequest.patient.prescriptions', []).map((prescription) => {
                const prescriptionCreationDate = `${new Date(prescription.createdAt).toLocaleDateString()} ${new Date(prescription.createdAt).toLocaleTimeString()}`;
                const drug = prescription.drug || {};
                const prescriptionFill = _get(prescription, 'prescriptionFills[0]', {});

                return <div key={prescription.createdAt} className='prescription-container'>
                  <div className='title'>
                    {
                      prescriptionCreationDate
                    }
                  </div>
                  <div className='row'>
                    <div className='column'>
                      <div className='title-container'>
                        <img
                          alt='Prescription'
                          className='icon'
                          src={PrescriptionIcon}
                        />
                        <div className='title'>
                          Prescription
                        </div>
                      </div>
                      <div className='key-value-container'>
                        <div className='key'>
                          Rx ID
                        </div>
                        <div className='value'>
                          {
                            prescription.rxId
                          }
                        </div>
                      </div>
                      <div className='key-value-container'>
                        <div className='key'>
                          Quantity
                        </div>
                        <div className='value'>
                          {
                            prescriptionFill.quantity || 'N/A'
                          }
                        </div>
                      </div>
                      <div className='key-value-container'>
                        <div className='key'>
                          Filled
                        </div>
                        <div className='value'>
                          {
                            prescriptionFill.computed_filled ? 'Yes' : 'No'
                          }
                        </div>
                      </div>
                    </div>
                    <div className='column'>
                      <div className='title-container'>
                        <img
                          alt='Drug'
                          className='icon'
                          src={DrugIcon}
                        />
                        <div className='title'>
                          Drug
                        </div>
                      </div>
                      <div className='key-value-container'>
                        <div className='key'>
                          Name
                        </div>
                        <div className='value'>
                          {
                            drug.name
                          }
                        </div>
                      </div>
                      <div className='key-value-container'>
                        <div className='key'>
                          NDC
                        </div>
                        <div className='value'>
                          {
                            drug.NDC
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>;
              })
              : <div className='no-prescriptions-history'>
                Patient has not yet had any prescriptions.
              </div>
          }
        </ContentGate>
      </div>
    </div>
  );
};

export default observer(PrescriptionsHistoryView);
