import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react';

import './style.scss';

const ListInput = ({ className, onChange, required, value }) => {
  const [inputValue, setInputValue] = useState('');

  const onInputChange = useCallback((e) => {
    setInputValue(e.target.value);
  }, []);

  const onAddedButtonClick = useCallback(() => {
    if (!inputValue) {
      return;
    }

    onChange([...value, inputValue]);
    setInputValue('');
  }, [inputValue, onChange, value]);

  const onRemoveButtonClick = useCallback((element, index) => {
    return onChange([...value.slice(0, index), ...value.slice(index + 1)]);
  }, [onChange, value]);

  return (
    <div className={`c-ListInput ${className}`}>
      <div className='input-wrapper'>
        <input
          className={`${required? 'input-required': 'input'}`}
          value={inputValue}
          onChange={onInputChange}
          onKeyUp={(e) => {
            if (e.key === 'Enter' || e.charCode === 13)
              onAddedButtonClick();
          }}
        />
        <button
          className='add-button'
          onClick={onAddedButtonClick}
        >
            Add
        </button>
      </div>
      <div className='value-wrapper'>
        {
          value.map((element, index) => (
            <div
              className='element'
              key={element + index.toString()}
            >
              <div className='name'>
                {element}
              </div>
              <button
                className='remove-button'
                onClick={() => onRemoveButtonClick(element, index)}
              >
                  x
              </button>
            </div>
          ))
        }
      </div>
    </div>
  );
};

export default observer(ListInput);
