import { prescriptionFillsRouteStore } from '../domain/store';

import listPrescriptionFills from './listPrescriptionFills';

export default async () => {
  prescriptionFillsRouteStore.setValue('activePrescriptionFillId', null);
  
  await listPrescriptionFills({ refetch: true, merge: false });

  prescriptionFillsRouteStore.setValue('showingSendReceiptModal', false);
};
