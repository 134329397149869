import React from 'react';
import { observer } from 'mobx-react';

import Modal from 'components/modal';
import ModalButton from 'components/modal/layout/button';

import './delete-confirmation-modal-styles.scss';

const DeleteConfirmationModal = ({
  showing,
  deletedItemName,
  deleteItem,
  closeModal,
}) => (
  <Modal
    modalClassName='c-DeleteConfirmationModal'
    modalStyles={{ height: '30%', width: '50%' }}
    isOpen={showing}
    shouldCloseOnOverlayClick={true}
    close={closeModal}
    title={`Are you sure you want to delete ${deletedItemName}?`}
  >
    <div className='content'>
      <ModalButton
        color='red'
        text='Delete'
        onClick={deleteItem}
      />
      <ModalButton
        color='blue'
        text='Cancel'
        onClick={closeModal}
      />
    </div>
  </Modal>
);

export default observer(DeleteConfirmationModal);
