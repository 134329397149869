import React, { useCallback } from 'react';
import { observer } from 'mobx-react';

import stores from 'bootstrap/store';

import CameraConfigurationModalView from './cameraConfigurationModalView';

const camerasStore = stores.global.cameras;

const CameraConfigurationModalContainer = () => {
  const closeModal = useCallback(() => {
    camerasStore.setValue('showingCameraConfigurationModal', false);
  }, []);

  return (
    <CameraConfigurationModalView
      camerasStore={camerasStore}
      closeModal={closeModal}
    />
  );
};

export default observer(CameraConfigurationModalContainer);
