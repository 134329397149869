import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';

import StockingBatchesView from './stocking-batches-view';

import {
  determineActiveStockingBatch,
  fetchActiveStockingBatchById,
  listStockingBatches,
} from './helpers';

import { stockingBatchesRouteStore } from './domain/store';

const StockingBatchesContainer = ({ rootRoute }) => {
  const navigate = useNavigate();
  const { stockingBatchId } = useParams();

  useEffect(() => {
    listStockingBatches();

    return () => {
      stockingBatchesRouteStore.setInitialState();
    };
  }, []);
  
  useEffect(() => {
    if (!stockingBatchesRouteStore.http.listingStockingBatches) {
      determineActiveStockingBatch(stockingBatchId, navigate);
    }
  }, [stockingBatchId, stockingBatchesRouteStore.http.listingStockingBatches]);

  useEffect(() => {
    if (stockingBatchesRouteStore.activeStockingBatchId) {
      fetchActiveStockingBatchById();
    }
  }, [stockingBatchesRouteStore.activeStockingBatchId]);

  return <StockingBatchesView
    stockingBatchesRouteStore={stockingBatchesRouteStore}
    listStockingBatches={listStockingBatches}
    rootRoute={rootRoute}
  />;
};

export default observer(StockingBatchesContainer);
