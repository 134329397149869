import stores from 'bootstrap/store';
import { prescriptionInfoRouteStore } from '../domain/store';
const containersStore = stores.global.containers;
const dispenseAttemptsRouteStore = stores.route.dispenseAttempts.domain;

export default () => {
  const { activeDispenseAttempt } = dispenseAttemptsRouteStore;
  if (!activeDispenseAttempt) return;

  prescriptionInfoRouteStore.incrementHttp('listingContainerImages');
  return containersStore.listImagesById({
    params: {
      containerId: activeDispenseAttempt.container.id,
    },
  })
  .then((images) => {
    prescriptionInfoRouteStore.setValue('preLabelImages', images.preLabel);
    prescriptionInfoRouteStore.setValue('postLabelImages', images.postLabel);
  })
  .finally(() => prescriptionInfoRouteStore.decrementHttp('listingContainerImages'));
};
