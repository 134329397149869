import base from 'models/base';
import { MODEL_NAME, SCHEMA } from './constants/schema';


const PrescriptionFillKioskRoutingModel = base({
  names: {
    singular: MODEL_NAME.SINGULAR,
    plural: MODEL_NAME.PLURAL
  },
  JSONSchema: SCHEMA,
  httpConfig: {
    root: MODEL_NAME.plural,
    methods: {}
  }
});

PrescriptionFillKioskRoutingModel.configureStore((store) => {
  return store
  .props({
  })
  .actions(self => ({
    create: (config = {}) => {
      return self.post(config);
    }
  }));
});

export default PrescriptionFillKioskRoutingModel;
