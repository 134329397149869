import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react';

// Views
import KioskInventoryView from './kiosk-inventory-view';

// Hooks
import useInterval from 'hooks/useInterval';

// Helpers
import {
  fetchDrugsForActiveKiosk,
  filterInventory,
  setInventoryThreshold,
  sortInventory,
} from './helpers';
import { fetchComputedInventoryForModification } from 'models/kiosks/helpers';

// Constants
import { INVENTORY_REFETCH_INTERVAL } from './constants';

// Route store
import { kioskInventoryRouteStore } from './domain/store';

// Global Stores
import stores from 'bootstrap/store';
const kiosksStore = stores.global.kiosks;


const KioskInventory = ({ activeKiosk, isExportInventoryModalOpen, closeExportInventoryModal }) => {
  useEffect(() => {
    kioskInventoryRouteStore.setValue('isMounted', true);

    // TODO could feasibly have a way that we didn't have to reload inventory from clear every time
    // but it was causing problems to try to not have this wiped every time, so for now we clear
    kiosksStore.setValue('computedInventory', null);

    return () => {
      kioskInventoryRouteStore.setValue('isMounted', false);
      kiosksStore.setValue('computedInventory', null);
    };
  }, []);

  useEffect(() => {
    fetchComputedInventoryForModification();
    fetchDrugsForActiveKiosk();
  }, []);

  useInterval(() => {
    fetchComputedInventoryForModification();
    fetchDrugsForActiveKiosk();
  }, INVENTORY_REFETCH_INTERVAL);

  if (!kioskInventoryRouteStore.isMounted || !kiosksStore.computedInventory) return <></>;

  return <KioskInventoryView
    kioskInventoryRouteStore={kioskInventoryRouteStore}
    inventoryLoaded={!!kiosksStore.computedInventory}
    filterInventory={filterInventory}
    sortInventory={sortInventory}
    setInventoryThreshold={setInventoryThreshold}
    isExportInventoryModalOpen={isExportInventoryModalOpen}
    closeExportInventoryModal={closeExportInventoryModal}
    fetchDrugs={() => stores.global.drugs.listForPharmacistByKiosk({ params: { kioskId: activeKiosk.id } })}
    fetchInventory={() => stores.global.kiosks.getComputedInventory({ query: { kioskId: activeKiosk.id } })}
    activeKioskName={activeKiosk?.name}
  />;
};

export default observer(KioskInventory);
