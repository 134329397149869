import React from 'react';
import { observer } from 'mobx-react';

import './index.scss';

const ListTabs = ({
  tabValues = [],
  activeTab,
  isActiveTab = (tabValue, activeTab) => tabValue === activeTab,
  onTabSelect = () => {}
}) => {
  return <div className='c-ListTabs'>
    {
      tabValues.map(value => {
        return (
          <div
            key={value}
            className={`tab ${isActiveTab(value, activeTab) ? 'active' : 'not-active'}`}
            onClick={ () => onTabSelect(value) }
          >
            {
              value
            }
          </div>
        );
      })
    }
  </div>;
};

export default observer(ListTabs);
