import React from 'react';
import Select from 'react-select';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';

import LabelledCheckbox from 'components/labelled-checkbox';
import Modal from 'components/modal';
import ModalRow from 'components/modal/layout/row';
import ModalButton from 'components/modal/layout/button';

import { REJECTION_REASONS } from 'models/dispense-attempts/constants';

import './dispense-attempt-rejection-reason-modal-style.scss';

const DispenseAttemptRejectionReasonModal = ({
  showing,
  store,
  close,
  reject,
}) => (
  <Modal
    modalClassName='c-DispenseAttemptRejectionReasonModal'
    modalStyles={{ height: '60%', width: '50%' }}
    isOpen={showing}
    shouldCloseOnOverlayClick={true}
    close={close}
    title='Please, specify rejection reason'
  >
    <div className='rejection-options'>
      <LabelledCheckbox
        label='Reject Container'
        isChecked={store.rejectContainer}
        onChange={() => store.revertRejectionModalField('rejectContainer')}
      />
      <LabelledCheckbox
        label='Reject Prescription'
        isChecked={store.rejectPrescription}
        onChange={() => store.revertRejectionModalField('rejectPrescription')}
      />
    </div>
    <Select
      className='reason-select'
      value={toJS(store.rejectionModal.reason)}
      clearable={false}
      searchable={false}
      options={REJECTION_REASONS}
      onChange={(selectedOption) => store.setValue('rejectionModal.reason', selectedOption)}
    />
    <textarea
      className='text-input'
      placeholder='Any notes?'
      value={store.rejectionModal.reason.value}
      autoFocus={true}
      onChange={(e) => store.setValue('rejectionModal.reason.value', e.target.value)}
    />
    <ModalRow shouldBoundToBottom={true} shouldBeCenteredHorizontally={true}>
      <ModalButton
        isDisabled={!store.readyToReject}
        color='red'
        text='Reject'
        onClick={() => reject()}
      />
    </ModalRow>
  </Modal>
);


export default observer(DispenseAttemptRejectionReasonModal);
