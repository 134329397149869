import { get as _get } from 'lodash';

// Constants
import { LOTS as LOTS_ROUTE } from 'constants/routes';

// Stores
import stores from 'bootstrap/store';
import { lotsRouteStore } from '../domain/store';
const lotsStore = stores.global.lots;

const determineActiveLot = (lotId, navigate) => {
  const isRequestedLotAvailable = !!lotsStore.dataForPharmacist.get(lotId);
  if (isRequestedLotAvailable) {
    lotsRouteStore.setValue('activeLotId', lotId);
  } else {
    // TODO fetch info of the requested entry and show it as active once user scrolls to it
    const firstId = _get(lotsStore, 'dataForPharmacistList[0].id');
    if (firstId) {
      navigate(`${LOTS_ROUTE.VALUE}/${firstId}`);
    } else {
      lotsRouteStore.setValue('activeLotId', null);
    }
  }
};

export default determineActiveLot;
