import { debounce as _debounce } from 'lodash';

import stores from 'bootstrap/store';
import { prescriptionFillsManualEntryRouteStore } from '../domain/store';

const listDrugsAutocomplete = async (field) => {
  const suggestionsKey = `suggestions.drug.${field}`;

  prescriptionFillsManualEntryRouteStore.setValue(suggestionsKey, []);

  const currentValue = prescriptionFillsManualEntryRouteStore.drug[field];
  const response = await stores.global.drugs.listForPharmacist({
    query: {
      filter: {
        [field]: currentValue,
      },
    },
  });

  prescriptionFillsManualEntryRouteStore.setValue(suggestionsKey, response.data || []);
};

export default _debounce(listDrugsAutocomplete, 300);
