import React from 'react';
import { observer } from 'mobx-react';

import NotificationWrapper from '../notification-wrapper';
import Kiosk from './components/kiosk';

import constructReadableErrorKiosks from './helpers/constructReadableErrorKiosks';

import './errored-kiosk-notifications-view-style.scss';


const KioskErrorsNotificationsView = ({
  erroredKiosks,
  goToKioskStatusesTab,
}) => {
  const readableErrorKiosks = constructReadableErrorKiosks(erroredKiosks);

  return <div className='c-ErroredKioskNotifications'>
    {
      !readableErrorKiosks.length &&
      <div className='no-data-caption'>
        No Kiosk Errors
      </div>
    }
    {
      readableErrorKiosks.map(({ id, name, errors }) => <NotificationWrapper
        key={id}
        onClick={() => goToKioskStatusesTab(id)}
      >
        <Kiosk
          name={name}
          errors={errors}
        />
      </NotificationWrapper>)
    }
  </div>;
};

export default observer(KioskErrorsNotificationsView);
