import React from 'react';
import { observer } from 'mobx-react';

// Components
import DrillDownLayout from 'components/drill-down-layout';
import List from './components/list';
import Content from './components/content';

//Styles
import './prescription-fills-mail-ordered-view.scss';

const PrescriptionsFillsMailOrderedView = ({
  prescriptionFillsStore,
  prescriptionFillsMailOrderRouteStore,
  listPrescriptionFills,
  onPrescriptionFillsSearch,
  processMailOrder,
  rootRoute,
}) => {
  return <div className='r-PrescriptionFillsMailOrderedView'>
    <DrillDownLayout>
      <List
        isLoading={!!prescriptionFillsMailOrderRouteStore.http.listingPrescriptionFillsForPharmacist}
        activePrescriptionFillId={prescriptionFillsMailOrderRouteStore.activePrescriptionFillId}
        groupedByDate={prescriptionFillsStore.groupedMailOrderByDate}
        listPrescriptionFills={listPrescriptionFills}
        filter={prescriptionFillsMailOrderRouteStore.filterValue}
        onPrescriptionFillsSearch={onPrescriptionFillsSearch}
        rootRoute={rootRoute}
      />
      <Content
        prescriptionFillsMailOrderRouteStore={prescriptionFillsMailOrderRouteStore}
        groupedByDate={prescriptionFillsStore.groupedMailOrderByDate}
        processMailOrder={processMailOrder}
      />
    </DrillDownLayout>
  </div>;
};


export default observer(PrescriptionsFillsMailOrderedView);
