import { toJS } from 'mobx';

import { usersRouteStore } from '../domain/store';
import { userUpsertModalRouteStore } from '../components/user-upsert-modal/domain/store';

import {
  USER_MANIPULATION_OPTIONS,
  USER_SKELETON,
} from '../constants';
const { CREATE, UPDATE } = USER_MANIPULATION_OPTIONS;

export default (e, purpose = UPDATE) => {
  switch (purpose) {
    case UPDATE:
      usersRouteStore.setValue('activeUserId', e.content.id);
      usersRouteStore.setValue('showingUserUpsertModal', true);
      userUpsertModalRouteStore.setValue('activelyModifiedUser', toJS(usersRouteStore.activeUser));
      break;
    case CREATE:
      usersRouteStore.setValue('showingUserUpsertModal', true);
      userUpsertModalRouteStore.setValue('activelyModifiedUser', USER_SKELETON);
  }
};
