import {
  closePhysicianUpsertModal,
  listPhysicians,
} from './index';

import stores from 'bootstrap/store';
import { physiciansRouteStore } from '../domain/store';
const physiciansStore = stores.global.physicians;

export default () => {
  return physiciansStore.deletePhysician({
    params: {
      id: physiciansRouteStore.activePhysicianId,
    },
  })
  .then(() => {
    closePhysicianUpsertModal();
    return listPhysicians();
  });
};
