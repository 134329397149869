import React from 'react';
import { observer } from 'mobx-react';

import PhysicianUpsertModal from './components/physician-upsert-modal';

import AdminTableLayout from 'components/admin-table-layout';
import Title from 'components/admin-table-layout/components/title';
import TableHeader from 'components/admin-table-layout/components/table-header';
import EntriesCount from 'components/admin-table-layout/components/table-header/components/entries-count';
import Paginator from 'components/paginator';
import NewEntryButton from 'components/admin-table-layout/components/table-header/components/new-entry-button';
import ListSearch from 'components/drill-down-layout/components/list/components/list-header/components/list-search';
import TableContent from 'components/admin-table-layout/components/table-content';
import SmartTable from 'components/smart-table';

import {
  COLUMNS,
  PHYSICIAN_MANIPULATION_OPTIONS
} from './constants';
import { MAX_TABLE_ENTRIES_PER_PAGE } from 'models/physicians/constants';

import './physicians-style.scss';

const { CREATE, UPDATE } = PHYSICIAN_MANIPULATION_OPTIONS;

const PhysiciansView = ({
  physiciansStore,
  physiciansRouteStore,
  physicianUpsertModalRouteStore,
  sortPhysicians,
  changePaginationPage,
  onPhysiciansSearch,
  showPhysicianUpsertModal,
  closePhysicianUpsertModal,
  showPhysicianDeleteConfirmationModal,
  createPhysician,
  updatePhysician,
  deletePhysician
}) => (
  <React.Fragment>
    <PhysicianUpsertModal
      physicianUpsertModalRouteStore={physicianUpsertModalRouteStore}
      physiciansRouteStore={physiciansRouteStore}
      createPhysician={createPhysician}
      updatePhysician={updatePhysician}
      deletePhysician={deletePhysician}
      closePhysicianUpsertModal={closePhysicianUpsertModal}
      showPhysicianDeleteConfirmationModal={showPhysicianDeleteConfirmationModal}
    />
    <AdminTableLayout>
      <Title
        text='Physicians'
      />
      <TableHeader>
        <div className='left-area'>
          <EntriesCount
            count={physiciansStore.entriesCount}
            caption='Physicians'
          />
          <Paginator
            currentPage={physiciansRouteStore.currentPage}
            entriesCount={physiciansStore.entriesCount}
            entriesPerPage={MAX_TABLE_ENTRIES_PER_PAGE}
            changePage={changePaginationPage}
          />
        </div>
        <div className='right-area'>
          <NewEntryButton
            caption='New Physician'
            onClick={e => showPhysicianUpsertModal(e, CREATE)}
          />
          <ListSearch
            searchValue={physiciansRouteStore.filterValue}
            onSearch={onPhysiciansSearch}
          />
        </div>
      </TableHeader>
      <TableContent>
        <SmartTable
          sortingFieldName={physiciansRouteStore.sortingColumn.fieldName}
          sortingOrder={physiciansRouteStore.sortingColumn.sortingOrder}
          onSortIconClick={sortPhysicians}
          rows={physiciansRouteStore.forTable}
          onRowClick={content => showPhysicianUpsertModal(content, UPDATE)}
          columns={COLUMNS}
          loading={physiciansRouteStore.loading}
        />
      </TableContent>
    </AdminTableLayout>
  </React.Fragment>
);

export default observer(PhysiciansView);
