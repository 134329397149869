import * as ROUTE from 'constants/routes';
import { ACTION } from 'models/call-request-pharmacist-actions/constants';

export const CALL_REQUESTS_ROUTE = `${ROUTE.PATIENTS.VALUE}${ROUTE.PATIENTS.SUB_ROUTE.CALL_REQUESTS.VALUE}`;

// Declaration order is the order these tabs are displayed in the UI.
export const CALL_REQUESTS_TAB = {
  QUEUED: 'Queued',
  CONTACTED: 'Contacted',
  HANDLED: 'Handled',
};
export const CALL_REQUESTS_TABS = Object.values(CALL_REQUESTS_TAB);

export const FRIENDLY_TEXT_BY_ACTION = {
  [ACTION.CONTACT_ATTEMPTED]: 'Contact Attempted',
  [ACTION.DISMISSED]: 'Request Dismissed',
  [ACTION.RESOLVED]: 'Request Resolved',
};

export const MAX_ENTRIES_PER_PAGE = 10;
