import ReactDateTimePicker from 'react-datetime-picker/dist/entry.nostyle';
import './style.scss';

export default {
  component: ReactDateTimePicker,
  specialProps: (props = {}) => {
    const required = !!props.required && (props.value === undefined || props.value === null || props.value === '');

    return ({
      disableClock: true,
      className: `${required ? 'c-ReactDateTimePickerRequired': 'c-ReactDateTimePicker'} ${props.className || ''}`,
      required: false,
    });
  },
};
