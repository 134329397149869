import { isNil as _isNil } from 'lodash';

import { NOTIFICATION_TYPES } from '../constants';

import { animateNotificationPreview, playNotificationSound } from './index';

// Ring the icon whenever new notification comes in.
export default (previousNotificationsCount = {}, currentNotificationsCount = {}) => {
  NOTIFICATION_TYPES.forEach((notificationType) => {
    const previousNotificationsCountForField = previousNotificationsCount[notificationType];
    const currentNotificationsCountForField = currentNotificationsCount[notificationType];
    if (_isNil(previousNotificationsCountForField) || _isNil(currentNotificationsCountForField)) {
      return;
    }

    if (previousNotificationsCountForField < currentNotificationsCountForField) {
      animateNotificationPreview(notificationType);
      playNotificationSound(notificationType);
    }
  });
};
