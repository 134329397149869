import stores from 'bootstrap/store';
import { prescriptionFillsManualEntryRouteStore } from '../domain/store';

const listKioskOptions = async () => {
  prescriptionFillsManualEntryRouteStore.incrementHttp('listingKioskOptions');
  
  const kiosks = await stores.global.kiosks.listKiosks();
  prescriptionFillsManualEntryRouteStore.setValue('kiosks', kiosks);
  
  prescriptionFillsManualEntryRouteStore.decrementHttp('listingKioskOptions');
  
  return kiosks;
};

export default listKioskOptions;
