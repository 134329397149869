import React from 'react';
import { observer } from 'mobx-react';

import './patient-style.scss';


const Patient = ({ patient }) => {

  const dob = patient.DOB && new Date(patient.DOB).toLocaleDateString();
  const id = patient.externalPharmacyId;

  return <div className='c-PatientListItemDetails'>
    <React.Fragment>
      {
        dob && <div className='key-value'>
          <div className='label'>DOB:</div>
          <div className='value'>{ dob }</div>
        </div>
      }
    </React.Fragment>
    {
      id && <div className='key-value'>
        <div className='label'>Patient ID:</div>
        <div className='value'>{ id }</div>
      </div>
    }
  </div>;
};

export default observer(Patient);
