import React, { useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

// Views
import KiosksView from './kiosks-view';

// Helpers
import * as helpers from './helpers';

// Constants
import { KIOSKS } from 'constants/routes';

// Stores
import stores from 'bootstrap/store';
import { kiosksRouteStore } from './domain/store';
const kioskStatusesStore = stores.global.kioskStatuses;


const KiosksContainer = ({ rootRoute }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { kioskId } = useParams();

  useEffect(() => {
    kiosksRouteStore.setValue('activeKioskId', null);
    helpers.listKiosks();

    return () => {
      kioskStatusesStore.setValue('dataForPharmacist', {});
      kiosksRouteStore.reset();
    };
  }, []);

  useEffect(() => {
    if (!kiosksRouteStore.http.listingKiosk) {
      helpers.determineActiveKiosk(kioskId, navigate);
    }
  }, [kioskId, kiosksRouteStore.http.listingKiosk]);

  useEffect(() => {
    const activeTab = Object.values(KIOSKS.CONTENT_TAB).find(({ VALUE }) => pathname.startsWith(`${KIOSKS.VALUE}/${kioskId}${VALUE}`));
    if (activeTab) {
      kiosksRouteStore.setValue('activeTab', activeTab.TITLE);
    }
  }, [kioskId, pathname]);

  return (
    <KiosksView
      kiosksRouteStore={kiosksRouteStore}
      setFilterValue={helpers.setFilterValue}
      onListBottomThreshold={helpers.onListBottomThreshold}
      fetchLayoutInfo={helpers.fetchLayoutInfo}
      submitLayoutChanges={helpers.submitLayoutChanges}
      rootRoute={rootRoute}
    />
  );
};


export default inject(({ store }) => ({
  dataForPharmacistList: store.global.kiosks.forPharmacistFormatted,
}))(observer(KiosksContainer));
