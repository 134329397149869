import {
  closeUserUpsertModal,
  listUsers,
} from './index';

import stores from 'bootstrap/store';
import { usersRouteStore } from '../domain/store';
import { userUpsertModalRouteStore } from '../components/user-upsert-modal/domain/store';
const usersStore = stores.global.users;

export default () => {
  return usersStore.updateUser({
    params: {
      id: usersRouteStore.activeUserId,
    },
    body: userUpsertModalRouteStore.activelyModifiedUser,
  })
  .then(() => {
    closeUserUpsertModal();
    return listUsers();
  });
};
