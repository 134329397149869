import deepFreeze from 'deep-freeze';

export const MODEL_NAME = Object.freeze({
  SINGULAR: 'videoConferenceInvitation',
  PLURAL: 'video-conference-invitations',
});

export const FIELD_NAME = Object.freeze({
  ID: 'id',
  STATUS: 'status',
  USER_ID: 'userId',
  VIDEO_CONFERENCE_ID: 'videoConferenceId',
  CREATED_AT: 'createdAt',
  UPDATED_AT: 'updatedAt',
});

export const SCHEMA = deepFreeze({
  title: MODEL_NAME.SINGULAR,
  type: 'object',
  properties: {
    [FIELD_NAME.ID]: {
      type: 'string',
    },
    [FIELD_NAME.STATUS]: {
      type: 'string',
    },
    [FIELD_NAME.USER_ID]: {
      type: 'string',
    },
    [FIELD_NAME.VIDEO_CONFERENCE_ID]: {
      type: 'string',
    },
    [FIELD_NAME.CREATED_AT]: {
      type: 'string',
    },
    [FIELD_NAME.UPDATED_AT]: {
      type: 'string',
    },
  },
  required: [],
});
