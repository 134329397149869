import { types } from 'mobx-state-tree';


export const HealthcareSiteActivityRouteStore = types.model({
  appCommandFilter: types.optional(types.string, ''),
  currentPage: types.optional(types.number, 1),
  http: types.optional(types.model({
    listingAppCommands: types.optional(types.number, 0),
  }), {}),
})
.actions(self => ({
  setValue: (prop, value) => self[prop] = value,
  setInitialState: () => {
    self.appCommandFilter = '';
    self.currentPage = 1;
    self.http = {
      listingAppCommands: 0,
    };
  },
  incrementHttp: (http) => ++self.http[http],
  decrementHttp: (http) => self.http[http] && --self.http[http],
}));

export const healthcareSiteActivityRouteStore = HealthcareSiteActivityRouteStore.create({});
