import React from 'react';
import { observer } from 'mobx-react';

import { isActiveRoute } from 'bootstrap/store/router';

import SingleRouteLink from './components/single-route-link';
import MultipleRoutesLink from './components/multiple-routes-link';

import './links-styles.scss';

const NavbarLinks = ({
  routeStore,
  routes,
  constructFullRoute,
}) => <div className='c-NavbarLinks'>
  {
    routes.map((route) => {
      const isRootRouteActive = isActiveRoute(route.VALUE);
      const subRoutes = Object.values(route.SUB_ROUTE || {});
      return <div key={route.VALUE} className='link-container'>
        {
          subRoutes.length
            ? <MultipleRoutesLink
              routeStore={routeStore}
              isRootRouteActive={isRootRouteActive}
              route={route}
              constructFullRoute={constructFullRoute}
            />
            : <SingleRouteLink
              isRootRouteActive={isRootRouteActive}
              route={route}
            />
        }
      </div>;
    })
  }
</div>;

export default observer(NavbarLinks);
