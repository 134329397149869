import deepFreeze from 'deep-freeze';

export const MODEL_NAME = Object.freeze({
  SINGULAR: 'drug',
  PLURAL: 'drugs'
});

export const FIELD_NAME = Object.freeze({
  NAME: 'name',
  NDC: 'NDC',
  ID: 'id',
  UPC: 'UPC',
  ACTIVE_INGREDIENT_UNIT: 'activeIngredientUnit',
  ACTIVE_NUMERATOR_STRENGTH: 'activeNumeratorStrength',
  ADMINISTRATION_TYPE: 'administrationType',
  APPLICATION_NUMBER: 'applicationNumber',
  BRAND_NAME: 'brandName',
  BRAND_NAME_SUFFIX: 'brandNameSuffix',
  COLOR: 'color',
  CREATED_AT: 'createdAt',
  DEA_SCHEDULE: 'deaSchedule',
  START_MARKETING_DATE: 'startMarketingDate',
  END_MARKETING_DATE: 'endMarketingDate',
  FLAVOR: 'flavor',
  FORM: 'form',
  GENERAL_NDC: 'generalNDC',
  GENERIC_NAME: 'genericName',
  HEIGHT: 'height',
  IMPRINT: 'imprint',
  LABELER_NUMBER: 'labelerName',
  LISTING_RECORD_CERTIFIED_THROUGH: 'listingRecordCertifiedThrough',
  MANUFACTURER: 'manufacturer',
  MARKETING_CATEGORY_NAME: 'marketingCategoryName',
  NDC_EXCLUDE_FLAG: 'ndcExcludeFlag',
  PACKAGE_DESCRIPTION: 'packageDescription',
  PHARM_CLASSES: 'pharmClasses',
  PRODUCT_TYPENAME: 'productTypename',
  QUANTITY: 'quantity',
  ROUTE_NAME: 'routeName',
  SAMPLE_PACKAGE: 'samplePackage',
  SHAPE: 'shape',
  STRENGTH: 'strength',
  SUBSTANCE_NAME: 'substanceName',
  UPDATED_AT: 'updatedAt',
  WIDTH: 'width',
});

export const SCHEMA = deepFreeze({
  title: MODEL_NAME.SINGULAR,
  type: 'object',
  properties: {
    [FIELD_NAME.ID]: {
      type: 'string'
    },
    [FIELD_NAME.NAME]: {
      type: 'string'
    },
    [FIELD_NAME.NDC]: {
      type: 'string'
    },
    [FIELD_NAME.UPC]: {
      type: 'string'
    },
    [FIELD_NAME.ACTIVE_INGREDIENT_UNIT]: {
      type: 'string'
    },
    [FIELD_NAME.ACTIVE_NUMERATOR_STRENGTH]: {
      type: 'string'
    },
    [FIELD_NAME.ADMINISTRATION_TYPE]: {
      type: 'string'
    },
    [FIELD_NAME.APPLICATION_NUMBER]: {
      type: 'string'
    },
    [FIELD_NAME.BRAND_NAME]: {
      type: 'string'
    },
    [FIELD_NAME.BRAND_NAME_SUFFIX]: {
      type: 'string'
    },
    [FIELD_NAME.COLOR]: {
      type: 'string'
    },
    [FIELD_NAME.CREATED_AT]: {
      type: 'string'
    },
    [FIELD_NAME.DEA_SCHEDULE]: {
      type: 'string'
    },
    [FIELD_NAME.START_MARKETING_DATE]: {
      type: 'string'
    },
    [FIELD_NAME.END_MARKETING_DATE]: {
      type: 'string'
    },
    [FIELD_NAME.FLAVOR]: {
      type: 'string'
    },
    [FIELD_NAME.FORM]: {
      type: 'string'
    },
    [FIELD_NAME.GENERAL_NDC]: {
      type: 'string'
    },
    [FIELD_NAME.GENERIC_NAME]: {
      type: 'string'
    },
    [FIELD_NAME.IMPRINT]: {
      type: 'string'
    },
    [FIELD_NAME.LABELER_NUMBER]: {
      type: 'string'
    },
    [FIELD_NAME.LISTING_RECORD_CERTIFIED_THROUGH]: {
      type: 'string'
    },
    [FIELD_NAME.MANUFACTURER]: {
      type: 'string'
    },
    [FIELD_NAME.MARKETING_CATEGORY_NAME]: {
      type: 'string'
    },
    [FIELD_NAME.NDC_EXCLUDE_FLAG]: {
      type: 'string'
    },
    [FIELD_NAME.PACKAGE_DESCRIPTION]: {
      type: 'string'
    },
    [FIELD_NAME.PHARM_CLASSES]: {
      type: 'string'
    },
    [FIELD_NAME.PRODUCT_TYPENAME]: {
      type: 'string'
    },
    [FIELD_NAME.QUANTITY]: {
      type: 'string'
    },
    [FIELD_NAME.ROUTE_NAME]: {
      type: 'string'
    },
    [FIELD_NAME.SAMPLE_PACKAGE]: {
      type: 'string'
    },
    [FIELD_NAME.SHAPE]: {
      type: 'string'
    },
    [FIELD_NAME.STRENGTH]: {
      type: 'string'
    },
    [FIELD_NAME.SUBSTANCE_NAME]: {
      type: 'string'
    },
    [FIELD_NAME.UPDATED_AT]: {
      type: 'string'
    },
    [FIELD_NAME.WIDTH]: {
      type: 'number'
    },
    [FIELD_NAME.HEIGHT]: {
      type: 'number'
    },
  },
  required: [
    FIELD_NAME.ID,
    // FIELD_NAME.NAME,
    FIELD_NAME.NDC,
    // FIELD_NAME.UPC,
  ]
});
