import React from 'react';
import { observer } from 'mobx-react';
import { lowerCase as _lowerCase, startCase as _startCase, compact as _compact } from 'lodash';

import Gallery from 'react-grid-gallery';

// Components
import Detail from './components/detail';
import DetailKey from './components/detail/components/detail-key';

// Utils
import parseDbDateOfBirth from 'utils/parseDbDateOfBirth';

// Icons
import AllergyIcon from 'assets/icons/allergy-active.svg';
import CalendarIcon from 'assets/icons/calendar-blue.svg';
import ClockIcon from 'assets/icons/clock-active.svg';
import ContactIcon from 'assets/icons/contact.svg';
import GenderIcon from 'assets/icons/gender-active.svg';
import LocationIcon from 'assets/icons/location-active.svg';
import MailIcon from 'assets/icons/mail.svg';
import NameIcon from 'assets/icons/name-icon-active.svg';
import CameraIcon from 'assets/icons/camera-active.svg';


// Styles
import './patient-info-style.scss';


const PatientInfo = ({ patient, patientAddress, patientImages = [], isEditMode, setEditablePatient, updatePatient }) => {
  const [isAddingNewAllergy, setIsAddingNewAllergy] = React.useState(false);

  if (!patient) {
    return null;
  }

  

  const allergiesJoined = (patient.allergies || []).map(({ name }) => name).join(', ');
  const allergies = isAddingNewAllergy ? allergiesJoined + ', ' : allergiesJoined;
  console.log(isAddingNewAllergy, allergies);

  return <div className='c-PatientsInfo'>
    {
      isEditMode &&
      <div className='edit-controls-container'>
        <button className='btn-edit-control btn-blue' disabled={!patient || !patient.firstName || !patient.lastName} onClick={() => updatePatient()}>
          Save
        </button>
      </div>
    }
    <Detail
      icon={NameIcon}
      caption='First Name'
      value={patient.firstName}
      editable={isEditMode}
      onChange={(e) => setEditablePatient({ firstName: e.target.value })}
    />
    <Detail
      icon={NameIcon}
      caption='Last Name'
      value={patient.lastName}
      editable={isEditMode}
      onChange={(e) => setEditablePatient({ lastName: e.target.value })}
    />
    <Detail
      icon={CalendarIcon}
      caption='Date Of Birth'
      value={isEditMode ? parseDbDateOfBirth(patient.DOB) : parseDbDateOfBirth(patient.DOB) || 'N/A'}
      editable={isEditMode}
      placeholder='MM/DD/YYYY'
      onChange={(e) => setEditablePatient({ DOB: e.target.value || null })}
    />
    <Detail
      icon={ContactIcon}
      caption='Phone'
      value={patient.phoneNumber}
      editable={isEditMode}
      onChange={(e) => setEditablePatient({ phoneNumber: e.target.value || null })}
    />
    <Detail
      icon={MailIcon}
      caption='Email'
      value={patient.email}
      editable={isEditMode}
      onChange={(e) => setEditablePatient({ email: e.target.value || null })}
    />
    <Detail
      icon={LocationIcon}
      caption='Address'
      value={patientAddress}
      editable={false}
    />
    <Detail
      icon={GenderIcon}
      caption='Gender'
      value={_startCase(_lowerCase(patient.gender))}
      editable={isEditMode}
      placeholder='Male | Female | Indeterminate'
      onChange={(e) => setEditablePatient({ gender: e.target.value || null })}
    />
    <Detail
      icon={AllergyIcon}
      caption='Allergies'
      value={`${isEditMode ? allergies : allergies || 'None'}`}
      editable={isEditMode}
      placeholder='Example 1, Example 2'
      onChange={(e) => {
        let raw = e.target.value;
        
        // If last two characters are spaces, ignore.
        // This is to prevent confusing behavior when user presses spacebar
        // after ", " has automatically been added upon hitting a comma.
        if (raw.slice(-2) === '  ') return;

        const lastChar = raw[raw.length - 1];

        // If last character is a comma
        if (lastChar === ',') {
          if (isAddingNewAllergy) setIsAddingNewAllergy(false);
          else setIsAddingNewAllergy(true);
        }
        else setIsAddingNewAllergy(false);

        const split = raw.split(/\s*,+\s*/);
        const cleaned = _compact(
          split.map(allergy => {
            return allergy
            .trimStart()
            // Remove all non-alphabetical characters (and space)
            .replace(/[^A-Za-z\s]/g, '')
            // Capitalize first letter of each word
            .split(/\s+/)
            .map(word => word ? word[0].toUpperCase() + word.slice(1) : word)
            .join(' ');
          })
        )
        .filter((allergy) => allergy);
        setEditablePatient({ allergies: cleaned.map((allergy) => ({ name: allergy })) });
      }}
    />
    <Detail
      icon={ClockIcon}
      caption='Creation Date'
      value={new Date(patient.createdAt).toLocaleString()}
      editable={false}
    />
    {
      !!patientImages && !!patientImages.length &&
      <div className='photos-container'>
        <DetailKey
          icon={CameraIcon}
          caption='Images'
        />
        <div className='photos'>
          <Gallery
            enableImageSelection={false}
            images={patientImages}
          />
        </div>
      </div>
    }
  </div>;
};

export default observer(PatientInfo);
