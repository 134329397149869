import React from 'react';
import { observer } from 'mobx-react';

import './caption-value-styles.scss';

const KioskStatusCaptionValue = ({ caption, value, color }) => (
  <div className='c-KioskStatusCaptionValue'>
    <div className={`value ${color}`}>
      {
        value
      }
    </div>
    <div className='caption'>
      {
        caption
      }
    </div>
  </div>
);

export default observer(KioskStatusCaptionValue);
