import { closeKeycardUpsertModal, listKeycards } from './index';

import stores from 'bootstrap/store';
import { keycardUpsertModalRouteStore } from '../components/keycard-upsert-modal/domain/store';
const keycardsStore = stores.global.keycards;

export default () => {
  const { activelyModifiedKeycard, userSelect, pharmacySelect } = keycardUpsertModalRouteStore;
  const keycard = {
    ...activelyModifiedKeycard,
    userId: userSelect.value,
    pharmacyId: pharmacySelect.value,
  };

  return keycardsStore.createKeycard({ body: keycard })
  .then(() => {
    closeKeycardUpsertModal();
    return listKeycards();
  });
};
