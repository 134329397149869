import {
  isNumber as _isNumber,
  get as _get,
  omit as _omit,
} from 'lodash';

import { READABLE_ERRORS } from '../constants';

import stores from 'bootstrap/store';
import { prescriptionFillsManualEntryRouteStore } from '../domain/store';
const prescriptionFillsStore = stores.global.prescriptionFills;

const upsertPrescriptionFillWithReferences = async () => {
  prescriptionFillsManualEntryRouteStore.incrementHttp('creatingPrescriptionFill');

  const patientDOB = _get(prescriptionFillsManualEntryRouteStore, 'patient.DOB');
  if (!patientDOB) {
    return;
  }
  const [month, date, year] = patientDOB.split('/');
  const patient = {
    ...prescriptionFillsManualEntryRouteStore.patient,
    DOB: `${year}-${month}-${date}`,
  };

  const prescription = _isNumber(prescriptionFillsManualEntryRouteStore.prescription.refills)
    ? prescriptionFillsManualEntryRouteStore.prescription
    : _omit(prescriptionFillsManualEntryRouteStore.prescription, ['refills']);

  const body = {
    prescriptionFill: prescriptionFillsManualEntryRouteStore.prescriptionFill,
    prescription,
    drug: prescriptionFillsManualEntryRouteStore.drug,
    patient,
    physician: prescriptionFillsManualEntryRouteStore.physician,
  };
  if (prescriptionFillsManualEntryRouteStore.kioskOption) {
    body.kioskId = prescriptionFillsManualEntryRouteStore.kioskOption.value;
  }
  return prescriptionFillsStore.createWithReferences({ body })
  .then(() => prescriptionFillsManualEntryRouteStore.setValue('wasManualOrderFulfilled', true))
  .catch((err) => {
    const { readableError } = READABLE_ERRORS.find(({ regex }) => err.res && err.res.text && err.res.text.match(regex) && err.res.text.match(regex).length) || {};
    prescriptionFillsManualEntryRouteStore.setValue('httpError', readableError || err.message || null);
    return prescriptionFillsManualEntryRouteStore.setValue('wasManualOrderFulfilled', false);
  })
  .finally(() => prescriptionFillsManualEntryRouteStore.decrementHttp('creatingPrescriptionFill'));
};

export default upsertPrescriptionFillWithReferences;
