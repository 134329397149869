import _ from 'lodash';
import { types, flow } from 'mobx-state-tree';

import base from 'models/base';

import { MODEL_NAME, SCHEMA } from './constants/schema';

const KioskStatusesModel = base({
  names: {
    singular: MODEL_NAME.SINGULAR,
    plural: MODEL_NAME.PLURAL
  },
  JSONSchema: SCHEMA,
  httpConfig: {
    root: MODEL_NAME.plural,
    methods: {
    }
  }
});

KioskStatusesModel.configureStore((store) => {
  return store
  .props({
    dataForPharmacist: types.optional(types.map(types.frozen()), {}),
  })
  .actions(self => ({
    setValue: (prop, value) => self[prop] = value,
    listForKiosk: flow(function* (config = {}) {
      const page = _.get(config, 'query.page', 0);

      if (!page) self.dataForPharmacist = {};

      config.urlFragment = () => `/kiosks/${config.params.kioskId}`;
      const response = yield self.list(config);
      const data = response.data || response || [];
      self.dataForPharmacist.merge(_.keyBy(data, 'id'));

      return response;
    })
  }))
  .views(() => ({
    
  }));
});


export default KioskStatusesModel;
