import React from 'react';
import { observer } from 'mobx-react';

import DetailKey from './components/detail-key';

import './detail-styles.scss';

const PatientDetail = ({
  icon,
  caption,
  value,
  defaultValue = 'N/A',
  editable = false,
  placeholder,
  onChange,
}) => (
  <div className='c-PatientDetailItem'>
    <DetailKey
      icon={icon}
      caption={caption}
    />
    {
      editable
        ? <div className='input-container'>
          <input className='input' type='text' placeholder={placeholder} value={value} onChange={onChange} />
        </div>
        : <div className='value'>
          {
            value || defaultValue
          }
        </div>
    }
  </div>
);

export default observer(PatientDetail);
