import { listUsers } from './index';

import { usersRouteStore } from '../domain/store';

export default (fieldName, sortingOrder) => {
  usersRouteStore.setValue('sortingColumn', {
    ...usersRouteStore.sortingColumn,
    fieldName,
    sortingOrder,
  });

  return listUsers();
};
