import { prescriptionFillsManualEntryRouteStore } from '../domain/store';

import parseDbDateOfBirth from 'utils/parseDbDateOfBirth';

const onPatientAutocompleteInputChange = (patient, filteredField) => {
  const { firstName, lastName, externalPharmacyId, DOB, email, phoneNumber } = patient;

  if (patient[filteredField]) {
    prescriptionFillsManualEntryRouteStore.setValue(`patient.${filteredField}`, patient[filteredField]);
  }

  if (!prescriptionFillsManualEntryRouteStore.patient.firstName) {
    prescriptionFillsManualEntryRouteStore.setValue('patient.firstName', firstName);
  }

  if (!prescriptionFillsManualEntryRouteStore.patient.lastName) {
    prescriptionFillsManualEntryRouteStore.setValue('patient.lastName', lastName);
  }

  if (!prescriptionFillsManualEntryRouteStore.patient.externalPharmacyId && externalPharmacyId) {
    prescriptionFillsManualEntryRouteStore.setValue('patient.externalPharmacyId', externalPharmacyId);
  }

  if (!prescriptionFillsManualEntryRouteStore.patient.DOB && DOB) {
    prescriptionFillsManualEntryRouteStore.setValue('patient.DOB', parseDbDateOfBirth(DOB));
  }

  if (!prescriptionFillsManualEntryRouteStore.patient.email && email) {
    prescriptionFillsManualEntryRouteStore.setValue('patient.email', email);
  }

  if (!prescriptionFillsManualEntryRouteStore.patient.phoneNumber && phoneNumber) {
    prescriptionFillsManualEntryRouteStore.setValue('patient.phoneNumber', phoneNumber);
  }

  prescriptionFillsManualEntryRouteStore.setValue('suggestions.patient', {});
};

export default onPatientAutocompleteInputChange;
