import { medicalAssistantsRouteStore } from '../domain/store';

import { listMedicalAssistants } from './index';


export default (fieldName, sortingOrder) => {
  medicalAssistantsRouteStore.setValue('sortingColumn', {
    ...medicalAssistantsRouteStore.sortingColumn,
    fieldName,
    sortingOrder
  });

  return listMedicalAssistants();
};
