import { startCase as _startCase } from 'lodash';

export const BASE_ITEMS = {
  KIOSK_NOT_SHOW_WITHOUT_VALUE: {
    caption: 'KIOSK',
    values: 'kiosk.name',
    showWithoutValue: false,
  },
  DISPENSE_ATTEMPT_DRUG: {
    caption: 'DRUG',
    values: 'dispenseAttempt.prescriptionFill.prescription.drug.name',
  },
  CONTAINER_DRUG: {
    caption: 'DRUG',
    values: 'container.drug.name',
  },
  DISPENSE_ATTEMPT_PATIENT: {
    caption: 'PATIENT',
    values: [
      'dispenseAttempt.prescriptionFill.prescription.patient.firstName',
      'dispenseAttempt.prescriptionFill.prescription.patient.lastName',
    ],
  },
  USER: {
    caption: 'USER',
    values: ['user.firstName', 'user.lastName'],
  },
  SUB_USER: {
    caption: 'SUB USER',
    values: 'subUser.type',
    format: value => _startCase(value)
  },
  UPC: {
    caption: 'UPC',
    values: 'container.UPC',
  },
  EXPIRATION_DATE: {
    caption: 'EXPIRATION DATE',
    values: 'container.expirationDate',
    format: date => new Date(date).toLocaleString()
  },
  CARD_NUMBER: {
    caption: 'CARD NUMBER',
    values: 'indexed.cardNumber',
  },
  PHARMACY_NAME: {
    caption: 'PHARMACY',
    values: 'pharmacy.name',
  },
  LOCATION: {
    caption: 'LOCATION',
    values: [
      'location.country',
      'location.state',
      'location.city',
      'location.street1',
    ],
    template: '{0}, {1}, {2}, {3}',
  },
  PHONE: {
    caption: 'PHONE',
    values: 'indexed.phoneNumber',
  },
  PATIENT: {
    caption: 'PATIENT',
    values: ['patient.firstName', 'patient.lastName'],
  },
  TYPE: {
    caption: 'TYPE',
    values: 'indexed.type',
    format: value => _startCase(value)
  },
};

export const BASE_BUNDLES = {
  DISPENSE_ATTEMPT: [
    BASE_ITEMS.DISPENSE_ATTEMPT_DRUG,
    BASE_ITEMS.DISPENSE_ATTEMPT_PATIENT,
    BASE_ITEMS.USER,
    BASE_ITEMS.KIOSK_NOT_SHOW_WITHOUT_VALUE,
  ],
  CONTAINER: [
    BASE_ITEMS.CONTAINER_DRUG,
    BASE_ITEMS.UPC,
    BASE_ITEMS.KIOSK_NOT_SHOW_WITHOUT_VALUE,
  ],
};
