import React from 'react';
import { observer } from 'mobx-react';

import './transfer-prescription-fill-style.scss';


const TransferPrescriptionFillNotification = ({ prescriptionFill, onClick }) => (
  <div key={prescriptionFill.id} className='c-TransferPrescriptionFillNotification' onClick={() => onClick(prescriptionFill.id)}>
    <div className='patient-name'>
      {`${prescriptionFill.prescription.patient.firstName} ${prescriptionFill.prescription.patient.lastName}`}
    </div>
    <div className='drug-name'>
      {`${prescriptionFill.prescription.drug.name}`}
    </div>
  </div>
);

export default observer(TransferPrescriptionFillNotification);
