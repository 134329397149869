import React from 'react';
import { observer } from 'mobx-react';

// View
import ContactView from './contact-view';

const ContactContainer = ({ activeMedicalAssistant }) => {
  return <ContactView
    activeMedicalAssistant={activeMedicalAssistant}
  />;
};

export default observer(ContactContainer);
