import { get as _get, isEqual as _isEqual, keyBy as _keyBy } from 'lodash';
import { flow, types } from 'mobx-state-tree';
import { values } from 'mobx';

import base from 'models/base';
import { MODEL_NAME, SCHEMA } from './constants/schema';

const PatientModel = base({
  names: {
    singular: MODEL_NAME.SINGULAR,
    plural: MODEL_NAME.PLURAL
  },
  JSONSchema: SCHEMA,
  httpConfig: {
    root: MODEL_NAME.plural,
    methods: {}
  }
});

PatientModel.configureStore((store) => {
  return store
  .props({
    dataForPharmacist: types.optional(types.map(types.frozen()), {}),
    dataForAdmin: types.optional(types.map(types.frozen()), {}),
    dataForMerge: types.optional(types.map(types.frozen()), {}),
    dataPending: types.optional(types.map(types.frozen()), {}),
    entriesCount: types.optional(types.number, 0)
  })
  .actions(self => ({
    setValue: (prop, value) => self[prop] = value,
    deletePatientFromMap: (mapName, patientId) => {
      return self[mapName] && self[mapName].delete(patientId);
    },
    listForPharmacist: flow(function* (config = {}) {
      config.urlFragment = () => '/for-pharmacist';
      const response = yield self.list(config);
      const data = response.data || response || [];
      self.dataForPharmacist.merge(_keyBy(data, 'id'));

      return response;
    }),
    listIncompleteForPharmacist: flow(function* (config = {}) {
      config.urlFragment = () => '/for-pharmacist/incomplete-info';
      const response = yield self.list(config);
      const data = response.data || response || [];
      self.dataForPharmacist.merge(_keyBy(data, 'id'));

      return response;
    }),
    listForAdmin: flow(function* (config = {}) {
      const response = yield self.list(config);
      const data = response.data || response || [];

      // If data contains the same elements as dataForPharmacist but in reverse order,
      // mobx-state-tree's map treats it as the same set of elements, so views won't rerender.
      const sameData = _isEqual(self.dataForAdminList.map(i => i.id).sort(), data.map(i => i.id).sort());
      if (sameData) self.dataForPharmacist = {};
      self.dataForAdmin = _keyBy(data, 'id');

      return response;
    }),
    listForMerge: flow(function* (config = {}) {
      const patientId = _get(config, 'params.patientId');
      if (!patientId) {
        return Promise.reject('"patientId" is required in order to list contenders for merge for a particular patient.');
      }

      config.urlFragment = () => `/${patientId}/for-merge`;
      const response = yield self.list(config);
      const data = response.data || response || [];
      self.dataForMerge.merge(_keyBy(data, 'id'));

      return response;
    }),
    listPendingForNotificationsPane: flow(function* (config = {}) {
      config.urlFragment = () => '/for-pharmacist';
      const response = yield self.list({
        ...config,
        query: {
          ...(config.query || {}),
          externalPharmacyId: false,
          offset: 0,
        },
      });
      const data = response.data || response || [];

      self.dataPending = _keyBy(data, 'id');

      return response;
    }),
    fetchById: (patientId) => {
      if (!patientId) {
        return Promise.reject('patientId must be provided in order to fetch patient by ID.');
      }

      return self.get({
        urlFragment: () => `/${patientId}`,
      });
    },
    countPatients: flow(function* (config = {}) {
      config.urlFragment = () => '/count';

      const response = yield self.list(config);
      self.entriesCount = response || 0;

      return response;
    }),
    createPatient: (config = {}) => {
      return self.post(config);
    },
    updatePatient: (config = {}) => {
      return self.put(config);
    },
    updatePharmacyDataCaptured: (config = {}) => {
      const patientId = _get(config, 'params.patientId');
      if (!patientId) {
        return Promise.reject('"patientId" is required in order to update patient\'s pharmacyDataCaptured-related fields.');
      }

      config.urlFragment = () => `/${patientId}/pharmacy-data-captured`;
      return self.put(config);
    },
    mergePatients: (config = {}) => {
      config.urlFragment = () => '/merge';
      return self.put(config);
    },
    deletePatient: (config = {}) => {
      return self.delete(config);
    },
    addHealthcareSite: (config = {}) => {
      if (!_get(config, 'params.patientId')) {
        return Promise.reject('patientId is required in order to associate patient with a healthcareSite.');
      }
      if (!_get(config, 'params.healthcareSiteId')) {
        return Promise.reject('healthcareSiteId is required in order to associate patient with a healthcareSite.');
      }

      config.urlFragment = (params) => `/${params.patientId}/healthcare-sites/${params.healthcareSiteId}`;
      return self.post(config);
    },
    deleteHealthcareSite: (config = {}) => {
      if (!_get(config, 'params.patientId')) {
        return Promise.reject('patientId is required in order to delete healthcareSite from patient.');
      }
      if (!_get(config, 'params.healthcareSiteId')) {
        return Promise.reject('healthcareSiteId is required in order to delete healthcareSite to patient.');
      }

      config.urlFragment = (params) => `/${params.patientId}/healthcare-sites/${params.healthcareSiteId}`;
      return self.delete(config);
    },
  }))
  .views(self => ({
    get dataForPharmacistList() {
      return values(self.dataForPharmacist);
    },
    get dataForAdminList() {
      return values(self.dataForAdmin);
    },
    get dataForMergeList() {
      return values(self.dataForMerge);
    },
    get dataPendingList() {
      return values(self.dataPending);
    },
  }));
});

// Can't use module.exports because there is a generator function in this file,
// and that causes module.exports to fail for some reason.
export default PatientModel;
