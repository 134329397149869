import React from 'react';
import { observer } from 'mobx-react';

import ContentHeader from 'components/drill-down-layout/components/content/components/content-header';
import EditModeControls from './components/EditModeControls';

import PatientIcon from 'assets/icons/patient.svg';

import './patient-icon.scss';


const PatientsContentHeader = ({
  patientsListRouteStore,
  isReady,
}) => {
  if (!isReady) return null;

  const { activePatient } = patientsListRouteStore;

  return <ContentHeader
    title={activePatient && `${activePatient.firstName} ${activePatient.lastName}`}
    leftLabel={
      <img
        alt='Patient'
        src={PatientIcon}
        className='patient-icon-preview-large'
      />
    }
    subHeader={
      <EditModeControls
        isEditMode={patientsListRouteStore.isEditMode}
        enterEditMode={() => patientsListRouteStore.setValue('isEditMode', true)}
        exitEditMode={() => patientsListRouteStore.setValue('isEditMode', false)}
      />
    }
  />;
};

export default observer(PatientsContentHeader);
