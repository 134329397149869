import React from 'react';

import './prescription-element-style.scss';

const PrescriptionElement = ({
  header,
  icon,
  children,
  className,
  headerClassName = 'blue-header',
}) => (
  <div className={`prescription-element ${className}`}>
    <div className={`${headerClassName} title-container`}>
      <img className='icon' src={icon} />
      <div className='title'>{header}</div>
    </div>
    <div className='data'>{children}</div>
  </div>
);


export default PrescriptionElement;
