import { flow, types } from 'mobx-state-tree';
import {
  isEqual as _isEqual,
  get as _get,
  keyBy as _keyBy, reduce as _reduce, sortBy as _sortBy,
} from 'lodash';

import { ELEMENTS_PER_PAGE_FOR_AUTOCOMPLETE } from './constants';

import base from 'models/base';

import { MODEL_NAME, SCHEMA } from './constants/schema';


const HealthcareSiteModel = base({
  names: {
    singular: MODEL_NAME.SINGULAR,
    plural: MODEL_NAME.PLURAL
  },
  JSONSchema: SCHEMA,
  httpConfig: {
    root: MODEL_NAME.plural,
    methods: {
    }
  }
});

HealthcareSiteModel.configureStore((store) => {
  return store
  .props({
    dataForAdmin: types.optional(types.map(types.frozen()), {}),
    dataForPharmacist: types.optional(types.map(types.frozen()), {}),
    dataForAutocomplete: types.optional(types.array(types.frozen()), []),
    isMoreDataForAutocomplete: types.optional(types.boolean, true),
    healthcareSiteInventory: types.frozen(),
  })
  .actions(self => ({
    setValue: (prop, value) => self[prop] = value,
    listForPharmacist: flow(function* (config = {}) {
      const page = _get(config, 'query.page', 0);
      // If we are beginning pagination fresh, clear previous results.
      if (!page || page === 1) self.dataForPharmacist = {};

      config.urlFragment = () => '/for-pharmacist';
      const response = yield self.list(config);
      const data = response.data || response || [];
      self.dataForPharmacist.merge(_keyBy(data, 'id'));

      return response;
    }),
    listForAdmin: flow(function* (config = {}) {
      const offset = _get(config, 'query.offset', 0);
      const limit = _get(config, 'query.limit', 0);
      if (!offset && self.dataForAdmin.size > limit) self.dataForAdmin = {};

      config.urlFragment = () => '/';
      const response = yield self.list(config);
      const data = response.data || response || [];

      // If data contains the same elements as dataForAdmin but in reverse order,
      // mobx-state-tree's map treats it as the same set of elements, so views won't rerender.
      const sameData = _isEqual(self.forAdminList.map(i => i.id).sort(), data.map(i => i.id).sort());
      if (sameData) self.dataForAdmin = {};
      self.dataForAdmin = _keyBy(data, 'id');

      return response;
    }),
    listForAutocomplete: flow(function* (config = {}) {
      config.query = config.query || {};
      config.query.limit = ELEMENTS_PER_PAGE_FOR_AUTOCOMPLETE;

      config.urlFragment = () => '/';

      const response = yield self.list(config);

      const data = response.data || response || [];

      if (_get(config, 'query.offset', 0) === 0) {
        self.dataForAutocomplete = data;
      }
      else self.dataForAutocomplete.push(data);

      self.isMoreDataForAutocomplete = data.length < ELEMENTS_PER_PAGE_FOR_AUTOCOMPLETE;

      return response;
    }),
    listForAutocompleteForPharmacist: flow(function* (config = {}) {
      config.query = config.query || {};
      config.query.limit = ELEMENTS_PER_PAGE_FOR_AUTOCOMPLETE;

      config.urlFragment = () => '/for-pharmacist';

      const response = yield self.list(config);

      const data = response.data || response || [];

      if (_get(config, 'query.offset', 0) === 0) {
        self.dataForAutocomplete = data;
      }
      else self.dataForAutocomplete.push(data);

      self.isMoreDataForAutocomplete = data.length < ELEMENTS_PER_PAGE_FOR_AUTOCOMPLETE;

      return response;
    }),
    listHealthcareSiteInventory: flow(function* (config = {}) {
      config.urlFragment = (params) => `/${params.healthcareSiteId}/inventory`;

      const response = yield self.get(config);

      self.healthcareSiteInventory = response;

      return response;
    }),
    upsertHealthcareSite: flow(function* (config = {}) {
      config.urlFragment = () => '/';

      const response = yield self.put(config);

      return response;
    }),
  }))
  .views(self => ({
    get forPharmacistAsArray() {
      return Array.from(self.dataForPharmacist.values());
    },
    get forAdminList() {
      return Array.from(self.dataForAdmin.values());
    },
    get forAutocompleteAsArray() {
      return Array.from(self.dataForAutocomplete.values());
    },
    forAutocomplete(alreadyUsedHealthcareSites = []) {
      const alreadyUsedHealthcareSiteIds = alreadyUsedHealthcareSites.map(healthcareSite => healthcareSite.id);

      return self.forAutocompleteAsArray.filter(healthcareSite => !alreadyUsedHealthcareSiteIds.includes(healthcareSite.id)).map(healthcareSite => ({
        label: healthcareSite.name,
        value: healthcareSite.id,
      }));
    },
    get forPharmacistGrouped() {
      const forPharmacist = Array.from(self.dataForPharmacist.values());
      return _reduce(forPharmacist, (groupedByCity, healthcareSite) => {
        if (!groupedByCity.find(item => item.title === healthcareSite.location.city)) {
          groupedByCity.push({
            id: healthcareSite.locationId,
            title: healthcareSite.location.city,
            healthcareSites: []
          });
          groupedByCity[groupedByCity.length - 1].healthcareSites.push(healthcareSite);
        } else {
          const byCityIndex = groupedByCity.findIndex(item => item.title === healthcareSite.location.city);
          groupedByCity[byCityIndex].healthcareSites.push(healthcareSite);
        }

        return _sortBy(groupedByCity, 'title');
      }, []);
    },
    get forPharmacistByCity() {
      const list = Array.from(self.dataForPharmacist.values());

      const byCity = [];
      const grouped = {};
      list.forEach(item => {
        if (!grouped[item.location.city]) {
          grouped[item.location.city] = {city: item.location.city, data: []};
          byCity.push(grouped[item.location.city]);
        }
        grouped[item.location.city].data.push(item);
      });

      return _sortBy(byCity, 'city');
    },
  }));
});


export default HealthcareSiteModel;
