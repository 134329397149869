import {
  get as _get,
  isEqual as _isEqual,
  keyBy as _keyBy,
  orderBy as _orderBy,
  reduce as _reduce,
  set as _set,
  sortBy as _sortBy,
} from 'lodash';

import { types, flow } from 'mobx-state-tree';
import base from 'models/base';
import _ from 'lodash';

import { MODEL_NAME, SCHEMA } from './constants/schema';


const KioskModel = base({
  names: {
    singular: MODEL_NAME.SINGULAR,
    plural: MODEL_NAME.PLURAL
  },
  JSONSchema: SCHEMA,
  httpConfig: {
    root: MODEL_NAME.plural,
    methods: {
    }
  }
});

KioskModel.configureStore((store) => {
  return store
  .props({
    // Replace with typed model
    dataForAdmin: types.optional(types.map(types.frozen()), {}),
    dataForPharmacist: types.optional(types.map(types.frozen()), {}),
    computedInventory: types.frozen(),
    kioskLogs: types.optional(types.map(types.frozen()), {}),
    data: types.optional(types.map(types.frozen()), {}),
    errored: types.optional(types.map(types.frozen()), {}),
    entriesCount: types.optional(types.number, 0),
    activeKioskStatuses: types.frozen(),
  })
  .actions(self => ({
    setValue: (path, value) => _set(self, path, value),
    setInitialState: () => {
      self.data = {};
      self.dataForPharmacist = {};
      self.kioskLogs = {};
      self.entriesCount = 0;
    },
    listLogs: flow(function* (config = {}) {
      config.rootRoute = `/kiosk-logs/kiosks/${config.params.kioskId}`;
      config.storeResult = false;
      const response = yield self.list(config);
      self.kioskLogs.merge(_.keyBy(response.data, 'id'));
      return response;
    }),
    listKiosks: flow(function* (config = {}) {
      config.urlFragment = () => '/';
      const response = yield self.list(config);
      const data = response.data || response || [];
      return data;
    }),
    listForAdmin: flow(function* (config = {}) {
      const offset = _get(config, 'query.offset', 0);
      const limit = _get(config, 'query.limit', 0);
      if (!offset && self.dataForPharmacist.size > limit) self.dataForPharmacist = {};

      config.urlFragment = () => '/';
      const response = yield self.list(config);
      const data = response.data || response || [];
      // If data contains the same elements as dataForPharmacist but in reverse order,
      // mobx-state-tree's map treats it as the same set of elements, so views won't rerender.
      const isSameData = _isEqual(self.forAdminList.map(i => i.id).sort(), data.map(i => i.id).sort());
      if (isSameData) self.dataForAdmin = {};
      self.dataForAdmin = _keyBy(data, 'id');

      return response;
    }),
    listForPharmacist: flow(function* (config = {}) {
      const offset = _get(config, 'query.offset', 0);
      const limit = _get(config, 'query.limit', 0);
      if (!offset && self.dataForPharmacist.size > limit) self.dataForPharmacist = {};

      config.urlFragment = () => '/for-pharmacist';
      const response = yield self.list(config);
      const data = response.data || response || [];
      self.dataForPharmacist.merge(_keyBy(data, 'id'));

      return response;
    }),
    listForPharmacistForRouting: flow(function* (config = {}) {
      config.urlFragment = () => '/for-pharmacist';
      const response = yield self.list(config);
      const data = response.data || response || [];

      // If data contains the same elements as self.data but in reverse order,
      // mobx-state-tree's map treats it as the same set of elements, so views won't rerender.
      const sameData = _isEqual(self.forPharmacistAsArray.map(i => i.id).sort(), data.map(i => i.id).sort());
      if (sameData) self.data = {};

      self.data = _keyBy(data, 'id');

      return response;
    }),
    listErroredForPharmacist: flow(function* (config = {}) {
      config.urlFragment = () => '/errored/for-pharmacist';
      config.storeResult = false;
      const response = yield self.list(config);
      const data = response.data || response || [];

      self.errored = _keyBy(data, 'id');

      return response;
    }),
    countKiosks: flow(function* (config = {}) {
      config.urlFragment = () => '/count';

      const response = yield self.list(config);
      self.entriesCount = response || 0;

      return response;
    }),
    getLayoutInfo: flow(function* (config = {}) {
      config.urlFragment = () => `/${config.params.kioskId}/layout`;
      const response = yield self.get(config);
      const kioskLayout = response.data || response || [];

      const currentKioskInfo = self.dataForPharmacist.get(kioskLayout.id);
      currentKioskInfo && Object.assign(currentKioskInfo, kioskLayout);

      return response;
    }),
    getComputedInventory: flow(function* (config = {}) {
      if (!_get(config, 'query.kioskId')) {
        return Promise.reject('"kioskId" must be passed in query in order to get KIOSK\'s computed inventory.');
      }

      config.urlFragment = () => '/computed-inventory';
      const response = yield self.get(config);

      self.computedInventory = response;

      return response;
    }),
    getStatuses: flow(function* (config = {}) {
      config.urlFragment = () => `/${config.params.kioskId}/statuses`;
      const response = yield self.get(config);
      
      self.activeKioskStatuses = response;

      return response;
    }),
    submitLayoutChanges: flow(function* (config = {}) {
      config.urlFragment = () => `/${config.params.kioskId}/layout`;
      return yield self.put(config);
    }),
  }))
  .views(self => ({
    get logsDescByDate() {
      const logs = Array.from(self.kioskLogs.values());
      return _orderBy(logs, ['timestamp'], ['desc']);
    },
    get forPharmacistFormatted() {
      return Array.from(self.dataForPharmacist.values());
    },
    get erroredList() {
      return Array.from(self.errored.values());
    },
    get forAdminList() {
      return Array.from(self.dataForAdmin.values());
    },
    get forPharmacistAsArray() {
      return Array.from(self.data.values());
    },
  }));
});


export default KioskModel;
