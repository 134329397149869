import React from 'react';
import { observer } from 'mobx-react';

import './index.scss';

const NewEntryButton = ({
  caption,
  onClick
}) => (
  <div className='c-NewEntryButton' onClick={onClick}>
    <div className='plus-sign'>
      +
    </div>
    <input
      type='button'
      className='new-entry-btn'
      value={caption}
    />
  </div>
);

export default observer(NewEntryButton);