import React from 'react';
import { observer } from 'mobx-react';
import { get as _get } from 'lodash';

import CaptionValueInput from 'components/caption-value-input';
import DeleteConfirmationModal from 'components/delete-confirmation-modal';
import Modal from 'components/modal';
import ModalEditingAreas from 'components/modal/layout/editing-areas';
import ModalEditingArea from 'components/modal/layout/editing-areas/components/editing-area';
import ModalRow from 'components/modal/layout/row';
import ModalButton from 'components/modal/layout/button';

const PhysicianUpsertModal = ({
  physicianUpsertModalRouteStore,
  physiciansRouteStore,
  createPhysician,
  updatePhysician,
  deletePhysician,
  closePhysicianUpsertModal,
  showPhysicianDeleteConfirmationModal
}) => {
  const activePhysician = physiciansRouteStore.activePhysician;
  const activelyModifiedPhysician = physicianUpsertModalRouteStore.activelyModifiedPhysician || {};

  return (
    <Modal
      modalStyles={{ height: '40%', width: '80%' }}
      isOpen={physiciansRouteStore.showingPhysicianUpsertModal}
      shouldCloseOnOverlayClick={true}
      close={closePhysicianUpsertModal}
      title={activePhysician ? `${activePhysician.firstName} ${activePhysician.lastName}` : 'New Physician'}
    >
      {
        activePhysician &&
        <DeleteConfirmationModal
          showing={physicianUpsertModalRouteStore.showingDeleteConfirmationModal}
          deletedItemName={`${activePhysician.firstName} ${activePhysician.lastName}`}
          deleteItem={deletePhysician}
          closeModal={() => showPhysicianDeleteConfirmationModal(false)}
        />
      }
      <ModalEditingAreas>
        <ModalEditingArea>
          <ModalRow>
            <CaptionValueInput
              caption='First Name'
              value={activelyModifiedPhysician.firstName}
              onChange={(value) => physicianUpsertModalRouteStore.setValue('activelyModifiedPhysician.firstName', value)}
            />
            <CaptionValueInput
              caption='Last Name'
              value={activelyModifiedPhysician.lastName}
              onChange={(value) => physicianUpsertModalRouteStore.setValue('activelyModifiedPhysician.lastName', value)}
            />
            <CaptionValueInput
              caption='State License'
              value={activelyModifiedPhysician.stateLicenseNumber}
              onChange={(value) => physicianUpsertModalRouteStore.setValue('activelyModifiedPhysician.stateLicenseNumber', value)}
            />
          </ModalRow>
          {
            _get(activelyModifiedPhysician, 'physicianUser.user.email') !== undefined &&
            <ModalRow>
              <CaptionValueInput
                inputFieldClassNames={['max-width']}
                caption='Email'
                value={activelyModifiedPhysician.physicianUser.user.email}
                onChange={(value) => physicianUpsertModalRouteStore.setValue('activelyModifiedPhysician.physicianUser.user.email', value)}
              />
            </ModalRow>
          }
          <ModalRow shouldBoundToBottom={true}>
            <ModalButton
              isDisabled={!activePhysician && !physicianUpsertModalRouteStore.readyForCreation}
              color='blue'
              text='Save'
              onClick={activePhysician ? updatePhysician : createPhysician}
            />
            {
              activePhysician &&
              <ModalButton
                color='red'
                text='Delete'
                onClick={() => showPhysicianDeleteConfirmationModal()}
              />
            }
          </ModalRow>
        </ModalEditingArea>
        <ModalEditingArea>
          <ModalRow>
            <CaptionValueInput
              caption='NPI'
              value={activelyModifiedPhysician.NPI}
              onChange={(value) => physicianUpsertModalRouteStore.setValue('activelyModifiedPhysician.NPI', value)}
            />
            <CaptionValueInput
              caption='SPI'
              value={activelyModifiedPhysician.SPI}
              onChange={(value) => physicianUpsertModalRouteStore.setValue('activelyModifiedPhysician.SPI', value)}
            />
          </ModalRow>
          {
            _get(activelyModifiedPhysician, 'physicianUser.user.phoneNumber') !== undefined &&
            <ModalRow>
              <CaptionValueInput
                inputFieldClassNames={['max-width']}
                caption='Phone Number'
                value={activelyModifiedPhysician.physicianUser.user.phoneNumber}
                onChange={(value) => physicianUpsertModalRouteStore.setValue('activelyModifiedPhysician.physicianUser.user.phoneNumber', value)}
              />
            </ModalRow>
          }
        </ModalEditingArea>
      </ModalEditingAreas>
    </Modal>
  );
};

export default observer(PhysicianUpsertModal);
