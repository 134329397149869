import { types } from 'mobx-state-tree';
import {
  get as _get,
  set as _set
} from 'lodash';

import PhysicianModel from 'models/physicians';

export const PhysicianUpsertModalRouteStore = types.model({
})
.props({
  activelyModifiedPhysician: types.maybeNull(types.frozen()),
  showingDeleteConfirmationModal: types.optional(types.boolean, false)
})
.actions(self => ({
  setValue: (path, value) => _set(self, path, value),
  setInitialState() {
    self.setValue('activelyModifiedPhysician', null);
    self.setValue('showingDeleteConfirmationModal', false);
  }
}))
.views(self => ({
  get readyForCreation() {
    return !!(
      _get(self, 'activelyModifiedPhysician.firstName') &&
      _get(self, 'activelyModifiedPhysician.lastName') &&
      _get(self, 'activelyModifiedPhysician.NPI') &&
      _get(self, 'activelyModifiedPhysician.SPI') &&
      _get(self, 'activelyModifiedPhysician.stateLicenseNumber')
    );
  }
}));

export const physicianUpsertModalRouteStore = PhysicianUpsertModalRouteStore.create({});
