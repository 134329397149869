import stores from 'bootstrap/store';

import { EVENT } from '../../constants';


export default ({ apiWebSocket }) => {
  apiWebSocket.on(EVENT.RECEIVED.PRESCRIPTION_FILL_PHARMACIST_CONSULTATION_REQUESTS_CREATE, () => {
    // const { dispenseAttemptReview } = payload;

    stores.global.dispenseAttempts.listPendingForNotificationsPane();
  });
};
