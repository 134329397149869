import { toJS } from 'mobx';

import stores from 'bootstrap/store';
import { medicalAssistantsUpsertModalRouteStore } from '../domain/upsert-modal-store';
const healthcareSitesStore = stores.global.healthcareSites;

export default (healthcareSite) => {
  medicalAssistantsUpsertModalRouteStore.addHealthcareSite(toJS(healthcareSite));
  medicalAssistantsUpsertModalRouteStore.setValue('workPlaceFilter', '');
  healthcareSitesStore.setValue('dataForAdmin', {});
};
