import { toJS } from 'mobx';

import { medicalAssistantsRouteStore } from '../domain/store';
import { medicalAssistantsUpsertModalRouteStore } from '../domain/upsert-modal-store';

import {
  MEDICAL_ASSISTANT_MANIPULATION_OPTIONS,
  MEDICAL_ASSISTANT_SKELETON,
} from '../constants';
const { CREATE, UPDATE } = MEDICAL_ASSISTANT_MANIPULATION_OPTIONS;

export default (e, purpose = UPDATE) => {
  switch (purpose) {
    case UPDATE:
      medicalAssistantsRouteStore.setValue('activeMedicalAssistantId', e.content.id);
      medicalAssistantsRouteStore.setValue('showingUpsertModal', true);
      medicalAssistantsUpsertModalRouteStore.setValue('activelyModifiedMedicalAssistant', toJS(medicalAssistantsRouteStore.activeMedicalAssistant));
      break;
    case CREATE:
      medicalAssistantsRouteStore.setValue('showingUpsertModal', true);
      medicalAssistantsUpsertModalRouteStore.setValue('activelyModifiedMedicalAssistant', MEDICAL_ASSISTANT_SKELETON);
  }
};
