import stores from 'bootstrap/store';
import { prescriptionInfoRouteStore } from '../domain/store';
const prescriptionStore = stores.global.prescriptions;
const dispenseAttemptsRouteStore = stores.route.dispenseAttempts.domain;

export default () => {
  const { activeDispenseAttempt } = dispenseAttemptsRouteStore;
  if (!activeDispenseAttempt || !activeDispenseAttempt.prescriptionFill) return;

  prescriptionStore.fetchPrescriptionLabelImage({
    params: {
      prescriptionId: activeDispenseAttempt.prescriptionFill.prescriptionId,
    },
  })
  .then((data) => {
    const base64String = btoa(String.fromCharCode(...new Uint8Array(data)));
    prescriptionInfoRouteStore.setValue('labelImage', `data:image/png;base64, ${base64String}`);
  });
};
