import React from 'react';
import { observer } from 'mobx-react';

import './patient-data-captured-manipulation-style.scss';

const PatientDataCapturedManipulation = ({
  activePatient,
  // markActivePatientAsCaptured,
  // requestActivePatientDataCapture,
}) => {
  if (!activePatient || activePatient.pharmacyDataCaptured) return null;

  return <div className='c-PatientDataCapturedManipulation'>
    <div className='data-capture-buttons'>
      {/* <input
        className='button blue'
        type='button'
        value='Mark as Captured'
        onClick={markActivePatientAsCaptured}
      /> */}
    </div>
  </div>;
};


export default observer(PatientDataCapturedManipulation);
