import React from 'react';
import Gallery from 'components/image-gallery';
import { observer } from 'mobx-react';

import ContentGate from 'components/content-gate';

import '../../prescriptionInfoBlockStyles.scss';

const ContainerPreLabelImages = ({
  containerImagesFetched,
  preLabelGallery = [],
}) => (
  <div className='c-PrescriptionInfoBlock'>
    <div className='title'>
      Pre-Label Container Images
    </div>
    <ContentGate
      active={!containerImagesFetched}
      fadeDuration={500}
      color='none'
    >
      {
        preLabelGallery.length
          ? <Gallery
            enableImageSelection={false}
            images={preLabelGallery}
          />
          : <div className='no-data-available'>No available pre-label images available.</div>
      }
    </ContentGate>
  </div>
);

export default observer(ContainerPreLabelImages);
