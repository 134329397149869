import React from 'react';
import { observer } from 'mobx-react';

import './modal-editing-area-styles.scss';

const ModalEditingArea = ({ children }) => {
  return (
    <div className='c-ModalEditingArea'>
      {
        children
      }
    </div>
  );
};

export default observer(ModalEditingArea);
