import orderBy from 'lodash/orderBy';
import transform from 'lodash/transform';

export default function unpack(count, reference, type, invert = false) {
  if (invert) {
    return {
      count: count[type].total,
      copay: count[type].totalCopay,
      physicians: orderBy(transform(count[type].byPhysicianId, (physicians, physician, physicianId) => {
        physicians.push({
          data: reference.physicians[physicianId],
          count: physician.total,
          copay: physician.totalCopay,
          drugs: orderBy(transform(physician.byDrugId, (drugs, drug, drugId) => {
            drugs.push({
              data: reference.drugs[drugId],
              count: drug.total,
              copay: drug.totalCopay,
              kiosks: orderBy(transform(drug.byKioskId, (kiosks, kiosk, kioskId) => {
                kiosks.push({
                  data: reference.kiosks[kioskId],
                  count: kiosk.total,
                  copay: kiosk.totalCopay,
                });
              }, []), ['data.name'], ['asc']),
            });
          }, []), ['data.name'], ['asc']),
        });
      }, []), ['data.lastName'], ['asc']),
    };
  }

  return {
    count: count[type].total,
    copay: count[type].totalCopay,
    kiosks: orderBy(transform(count[type].byKioskId, (kiosks, kiosk, kioskId) => {
      kiosks.push({
        data: reference.kiosks[kioskId],
        count: kiosk.total,
        copay: kiosk.totalCopay,
        drugs: orderBy(transform(kiosk.byDrugId, (drugs, drug, drugId) => {
          drugs.push({
            data: reference.drugs[drugId],
            count: drug.total,
            copay: drug.totalCopay,
            physicians: orderBy(transform(drug.byPhysicianId, (physicians, physician, physicianId) => {
              physicians.push({
                data: reference.physicians[physicianId],
                count: physician.total,
                copay: physician.totalCopay,
              });
            }, []), ['data.lastName'], ['asc']),
          });
        }, []), ['data.name'], ['asc']),
      });
    }, []), ['data.name'], ['asc']),
  };
}
