import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import MailOrderedPrescriptionsNotificationsView from './mail-ordered-prescriptions-notifications-view';

import { NOTIFICATION_TYPE } from '../../../../constants';

import { fetchNotificationsRelatedData } from '../../../../helpers';

import stores from 'bootstrap/store';
import { goToReview } from './helpers';

const prescriptionFillsStore = stores.global.prescriptionFills;


const MailOrderedPrescriptionsNotificationsContainer = () => {
  useEffect(() => {
    fetchNotificationsRelatedData(NOTIFICATION_TYPE.MAIL_ORDERED_PRESCRIPTIONS);
  }, []);

  return <MailOrderedPrescriptionsNotificationsView
    mailOrders={prescriptionFillsStore.mailOrderedForNotificationsPaneArray}
    onClick={goToReview}
  />;
};

export default observer(MailOrderedPrescriptionsNotificationsContainer);
