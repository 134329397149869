import React from 'react';
import { observer } from 'mobx-react';

// View
import PrescriptionsHistoryView from './prescriptions-history-view';

const PrescriptionsHistoryContainer = ({ activeCallRequest, fetchingActiveCallRequest }) => {
  return <PrescriptionsHistoryView
    activeCallRequest={activeCallRequest}
    fetchingActiveCallRequest={fetchingActiveCallRequest}
  />;
};

export default observer(PrescriptionsHistoryContainer);
