import { types } from 'mobx-state-tree';
import { get as _get, set as _set } from 'lodash';

import stores from 'bootstrap/store';

export const PatientsRouteStore = types.model({
})
.props({
  filterValue: types.optional(types.string, ''),
  activePatientId: types.maybeNull(types.string),
  sortingColumn: types.optional(types.model({
    fieldName: types.optional(types.string, 'name'),
    sortingOrder: types.optional(types.string, 'asc')
  }), {}),
  http: types.optional(types.model({
    listingPatients: types.optional(types.number, 0),
    countingPatients: types.optional(types.number, 0),
  }), {}),
  currentPage: types.optional(types.number, 1),
  showingPatientUpsertModal: types.optional(types.boolean, false)
})
.actions(self => ({
  setValue: (path, value) => _set(self, path, value),
  incrementHttp: (http) => ++self.http[http],
  decrementHttp: (http) => --self.http[http],
}))
.views(self => ({
  get loading() {
    return !!(self.http.listingPatients && self.http.countingPatients);
  },
  get activePatient() {
    const patientsStore = stores.global.patients;
    return self.activePatientId && patientsStore.dataForAdmin.get(self.activePatientId);
  },
  get forTable() {
    const patientsStore = stores.global.patients;
    return patientsStore.dataForAdminList.map(patient => ({
      id: patient.id,
      name: `${patient.firstName} ${patient.lastName}`,
      externalPharmacyId: patient.externalPharmacyId,
      email: _get(patient, 'patientUser.user.email') || 'N/A',
      phoneNumber: _get(patient, 'patientUser.user.phoneNumber') || 'N/A',
      allergies: patient.allergies
    }));
  },
}));

export const patientsRouteStore = PatientsRouteStore.create({});
