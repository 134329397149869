import { toJS } from 'mobx';

import stores from 'bootstrap/store';
import { pharmacyTechnicianUpsertModalRouteStore } from '../domain/upsert-modal-store';
const pharmaciesStore = stores.global.pharmacies;

export default (pharmacy) => {
  pharmacyTechnicianUpsertModalRouteStore.addPharmacy(toJS(pharmacy));
  pharmacyTechnicianUpsertModalRouteStore.setValue('workPlaceFilter', '');
  pharmaciesStore.setValue('data', {});
};
