import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { get as _get } from 'lodash';

import PrescriptionFillEditModalView from './prescription-fills-edit-modal-view';

import stores from 'bootstrap/store';
import { prescriptionFillEditModalRouteStore } from './domain/store';
import listDrugsAutocomplete from './helpers/listDrugsAutocomplete';

const PrescriptionFillEditModalContainer = ({
  isOpen,
  activePrescriptionFill,
  isFulfillmentLocked,
  isPrescriptionFillActiveWithoutDispenseAttempt,
  onClose = () => {},
}) => {
  const [drugSuggestions, setDrugSuggestions] = useState([]);

  const searchDrugs = useCallback(async (search) => {
    if (!search) return;
    prescriptionFillEditModalRouteStore.incrementHttp('listingDrugs');
    const drugs = await listDrugsAutocomplete(search);
    setDrugSuggestions(drugs);
    prescriptionFillEditModalRouteStore.decrementHttp('listingDrugs');
  }, [setDrugSuggestions]);

  const onDrugSuggestionSelect = useCallback((suggestion) => {
    setDrugSuggestions([]);
    prescriptionFillEditModalRouteStore.setValue('drug', suggestion);
  }, []);


  useEffect(() => {
    if (isOpen) {
      if (isPrescriptionFillActiveWithoutDispenseAttempt) {
        prescriptionFillEditModalRouteStore.setValue('drug', { ..._get(activePrescriptionFill, 'prescription.drug') });
        prescriptionFillEditModalRouteStore.setValue('quantity', _get(activePrescriptionFill, 'quantity'));
      }

      if (!isFulfillmentLocked) {
        prescriptionFillEditModalRouteStore.setValue('copay', activePrescriptionFill?.copay || null);
      }
    } else {
      prescriptionFillEditModalRouteStore.setValue('drug', null);
      prescriptionFillEditModalRouteStore.setValue('quantity', null);
      prescriptionFillEditModalRouteStore.setValue('copay', null);
    }
  }, [isOpen, isPrescriptionFillActiveWithoutDispenseAttempt, isFulfillmentLocked]);

  const closeModal = useCallback(() => {
    prescriptionFillEditModalRouteStore.setInitialState();

    if (onClose) {
      return onClose();
    }
  }, [onClose]);

  const editPrescriptionFill = useCallback(async () => {
    const prescriptionFillId = activePrescriptionFill?.id;
    
    const body = {};
    if (isPrescriptionFillActiveWithoutDispenseAttempt) {
      body.drugId = _get(prescriptionFillEditModalRouteStore, 'drug.id');
      body.quantity = prescriptionFillEditModalRouteStore.quantity;
    }
    if (!isFulfillmentLocked) {
      body.copay = prescriptionFillEditModalRouteStore.copay;
    }

    if (prescriptionFillId && Object.keys(body).length) {
      prescriptionFillEditModalRouteStore.incrementHttp('updatingPrescriptionFill');
      await stores.global.prescriptionFills.updatePrescriptionFill(prescriptionFillId, body);
      prescriptionFillEditModalRouteStore.decrementHttp('updatingPrescriptionFill');
      return closeModal();
    }
  }, [closeModal, isPrescriptionFillActiveWithoutDispenseAttempt, isFulfillmentLocked]);

  // if (!prescriptionFillEditModalRouteStore.drug) return <></>;

  return <PrescriptionFillEditModalView
    prescriptionFillEditModalRouteStore={prescriptionFillEditModalRouteStore}
    isOpen={isOpen}
    isFulfillmentLocked={isFulfillmentLocked}
    isPrescriptionFillActiveWithoutDispenseAttempt={isPrescriptionFillActiveWithoutDispenseAttempt}
    title='Edit Prescription'
    onClose={closeModal}
    editPrescriptionFill={editPrescriptionFill}
    drugSuggestions={drugSuggestions}
    searchDrugs={searchDrugs}
    setDrugSuggestions={setDrugSuggestions}
    clearDrugSuggestions={() => setDrugSuggestions([])}
    onDrugSuggestionSelect={onDrugSuggestionSelect}
  />;
};

export default observer(PrescriptionFillEditModalContainer);
