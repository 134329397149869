import React from 'react';
import { observer } from 'mobx-react';

import ContentHeader from 'components/drill-down-layout/components/content/components/content-header';
import PrescriptionManipulation from './components/prescription-status-manipulation';

const PrescriptionFillsContentHeader = ({
  activePrescriptionFill,
  groupedPrescriptionFillsCount,
  processMailOrder,
}) => {
  if (!activePrescriptionFill || !activePrescriptionFill.prescription) return null;

  const patientName = `${activePrescriptionFill.prescription.patient.firstName} ${activePrescriptionFill.prescription.patient.lastName}`;
  const drugNDC = activePrescriptionFill.prescription.drug.NDC;

  return <ContentHeader
    title={patientName}
    subtitle={drugNDC}
    subHeader={
      groupedPrescriptionFillsCount &&
        <PrescriptionManipulation
          processMailOrder={processMailOrder}
        />
    }
  />;
};

export default observer(PrescriptionFillsContentHeader);
