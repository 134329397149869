import { get as _get} from 'lodash';

// Constants
import { STATUS } from 'models/call-requests/constants/schema';
import { CALL_REQUESTS_TAB, CALL_REQUESTS_ROUTE, MAX_ENTRIES_PER_PAGE } from '../constants';

// Stores
import stores from 'bootstrap/store';
import { callRequestsRouteStore} from '../domain/store';
const callRequestsStore = stores.global.callRequests;
const { router } = stores;

export const STATUS_BY_TAB = {
  [CALL_REQUESTS_TAB.CONTACTED]: STATUS.CONTACTED,
  [CALL_REQUESTS_TAB.HANDLED]: STATUS.HANDLED,
  [CALL_REQUESTS_TAB.QUEUED]: STATUS.QUEUED,
};

const listCallRequests = () => {
  const { activeListTab, filterValue } = callRequestsRouteStore;
  const query = {
    offset: MAX_ENTRIES_PER_PAGE * (callRequestsRouteStore.currentPage - 1),
    limit: MAX_ENTRIES_PER_PAGE,
    status: STATUS_BY_TAB[activeListTab],
  };

  if (filterValue) {
    query.filter = {
      phoneNumber: filterValue,
      '$patientCallRequest.patient.firstName$': filterValue,
      '$patientCallRequest.patient.lastName$': filterValue,
    };
  }

  const config = { query };
  callRequestsRouteStore.incrementHttp('listingCallRequests');
  return callRequestsStore.listForPharmacist(config)
  .catch(() => router.history.push(CALL_REQUESTS_ROUTE))
  .then((response) => {
    const responseLength = _get(response, 'data.length', 0);
    if (responseLength > MAX_ENTRIES_PER_PAGE - 1) {
      callRequestsRouteStore.setValue('currentPage', callRequestsRouteStore.currentPage + 1);
    }
  })
  .finally(() => callRequestsRouteStore.decrementHttp('listingCallRequests'));
};

export default listCallRequests;
