import deepFreeze from 'deep-freeze';

export const MODEL_NAME = Object.freeze({
  SINGULAR: 'prescriptionFillPharmacistConsultationRequest',
  PLURAL: 'prescription-fill-pharmacist-consultation-requests',
});

export const FIELD_NAME = Object.freeze({
  ID: 'id',
  COMPUTED_ACTIVE: 'computed_active',
  PRESCRIPTION_FILL_ID: 'prescriptionFillId',
  PHARMACIST_ID: 'pharmacistId',
  CREATED_AT: 'createdAt',
});

export const SCHEMA = deepFreeze({
  title: MODEL_NAME.SINGULAR,
  type: 'object',
  properties: {
    [FIELD_NAME.ID]: {
      type: 'string',
    },
    [FIELD_NAME.COMPUTED_ACTIVE]: {
      type: 'boolean',
    },
    [FIELD_NAME.PRESCRIPTION_FILL_ID]: {
      type: 'string',
    },
    [FIELD_NAME.PHARMACIST_ID]: {
      type: 'string',
    },
    [FIELD_NAME.CREATED_AT]: {
      type: 'string',
    },
  },
  required: [
    FIELD_NAME.ID,
    FIELD_NAME.PRESCRIPTION_FILL_ID,
    FIELD_NAME.PHARMACIST_ID,
  ]
});
