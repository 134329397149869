export const MODULE_LAYOUT = {
  SILHOUETTE: {
    X: 0,
    Y: 0,
    WIDTH: 50,
    HEIGHT: 50,
    CORNER_RADIUS: 5,
    COLOR: '#375362'
  },
  SLOTS: {
    COLOR: '#f8f8f9',
    COLOR_ACTIVE: '#cce3ff'
  },
  DISPLAY_COEFFICIENT: 0.4
};