import React from 'react';
import { observer } from 'mobx-react';
import { get as _get } from 'lodash';

import CaptionValueInput from 'components/caption-value-input';
import ModalRow from 'components/modal/layout/row';

const Data = ({
  upsertModalRouteStore,
  activelyModifiedMedicalAssistant,
}) => (
  <React.Fragment>
    <ModalRow>
      <CaptionValueInput
        caption='First Name'
        value={activelyModifiedMedicalAssistant.firstName}
        onChange={(value) => upsertModalRouteStore.setValue('activelyModifiedMedicalAssistant.firstName', value)}
      />
      <CaptionValueInput
        caption='Last Name'
        value={activelyModifiedMedicalAssistant.lastName}
        onChange={(value) => upsertModalRouteStore.setValue('activelyModifiedMedicalAssistant.lastName', value)}
      />
    </ModalRow>
    {
      _get(upsertModalRouteStore, 'activelyModifiedMedicalAssistantUser.email') !== undefined &&
      <ModalRow>
        <CaptionValueInput
          inputFieldClassNames={['max-width']}
          caption='Email'
          value={upsertModalRouteStore.activelyModifiedMedicalAssistantUser.email}
          onChange={(value) => upsertModalRouteStore.setActivelyModifiedMedicalAssistantUser('email', value)}
        />
      </ModalRow>
    }
    {
      _get(upsertModalRouteStore, 'activelyModifiedMedicalAssistantUser.phoneNumber') !== undefined &&
      <ModalRow>
        <CaptionValueInput
          inputFieldClassNames={['max-width']}
          caption='Phone Number'
          value={upsertModalRouteStore.activelyModifiedMedicalAssistantUser.phoneNumber}
          onChange={(value) => upsertModalRouteStore.setActivelyModifiedMedicalAssistantUser('phoneNumber', value)}
        />
      </ModalRow>
    }
  </React.Fragment>
);

export default observer(Data);
