import {
  closePhysicianUpsertModal,
  listPhysicians,
} from './index';

import stores from 'bootstrap/store';
import { physicianUpsertModalRouteStore } from '../components/physician-upsert-modal/domain/store';
import {toJS} from 'mobx';
const physiciansStore = stores.global.physicians;

export default () => {
  return physiciansStore.createPhysician({
    body: toJS(physicianUpsertModalRouteStore.activelyModifiedPhysician),
  })
  .then(() => {
    closePhysicianUpsertModal();
    return listPhysicians();
  });
};
