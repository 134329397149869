import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';

// Views
import MedicalAssistantView from './medical-assistants-view';

// Helpers
import {
  determineActiveMedicalAssistant,
  listMedicalAssistants,
  setFilterValue,
} from './helpers';

// View Store
import { medicalAssistantsRouteStore } from './domain/store';

// Global Stores
import stores from 'bootstrap/store';
const medicalAssistantsStore = stores.global.medicalAssistants;


const MedicalAssistantContainer = ({ rootRoute }) => {
  const navigate = useNavigate();
  const { medicalAssistantId } = useParams();

  useEffect(() => {
    listMedicalAssistants();

    return () => {
      medicalAssistantsRouteStore.setInitialState();
      medicalAssistantsStore.setValue('dataForPharmacist', {});
    };
  }, []);

  useEffect(() => {
    if (!medicalAssistantsRouteStore.http.listingMedicalAssistants) {
      determineActiveMedicalAssistant(medicalAssistantId, navigate);
    }
  }, [medicalAssistantId, medicalAssistantsRouteStore.http.listingMedicalAssistants]);

  return (
    <MedicalAssistantView
      medicalAssistantsStore={medicalAssistantsStore}
      medicalAssistantsRouteStore={medicalAssistantsRouteStore}
      setFilterValue={setFilterValue}
      listMedicalAssistants={listMedicalAssistants}
      rootRoute={rootRoute}
    />
  );
};

export default observer(MedicalAssistantContainer);
