import { listCallRequestsDebounce } from './index';

import stores from 'bootstrap/store';
import { callRequestsRouteStore } from '../domain/store';
const callRequestsStore = stores.global.callRequests;

const setActiveListTab = (tab) => {
  callRequestsRouteStore.setValue('activeListTab', tab);
  callRequestsRouteStore.setValue('currentPage', 1);
  callRequestsStore.setValue('dataForPharmacist', {});
  callRequestsStore.setValue('activeCallRequest', {});

  return listCallRequestsDebounce();
};

export default setActiveListTab;
