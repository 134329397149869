import { types } from 'mobx-state-tree';
import { upperFirst as _upperFirst } from 'lodash';

import { PURPOSE } from 'models/video-conferences/constants';

import stores from 'bootstrap/store';
const videoConferencesStore = stores.global.videoConferences;

export const VideoConferencesRouteStore = types.model({
})
.actions(() => ({
}))
.views(self => ({
  get prescriptionFillsForDisplay() {
    const { activePendingConference } = videoConferencesStore;
    if (
      !activePendingConference ||
      !activePendingConference.payload ||
      !activePendingConference.purpose ||
      activePendingConference.purpose.name !== PURPOSE.PRESCRIPTION_CONSULTATION
    ) return [];

    const fulfillment = activePendingConference.payload.prescriptionFillsFulfillment;
    const isFulfillmentPaid = !(Number(fulfillment.computed_totalCopay) - Number(fulfillment.computed_receivedPayment));

    const { prescriptionFills = [] } = fulfillment;

    const patient = prescriptionFills[0].prescription.patient;
    const displayedPatient = {
      firstName: patient.firstName || '',
      lastName: patient.lastName || '',
      phoneNumber: patient.phoneNumber || 'N/A',
      emailAddress: patient.email || 'N/A',
      DOB: patient.DOB || 'N/A',
      gender: patient.gender ? _upperFirst(patient.gender.toLowerCase()) : 'N/A',
      allergies: patient.allergies || [],
      externalPharmacyId: patient.externalPharmacyId || 'N/A',
    };

    const prescriptionFillsForDisplay = prescriptionFills.map((fill) => {
      const { drug = {} } = fill.prescription;
      const displayedDrug = {
        name: drug.name || 'N/A',
        rxId: fill.prescription.rxId || 'N/A',
        dosage: drug.dosage || 'N/A',
        directions: fill.prescription.directions || 'None',
      };

      const relatedDispenseAttempt = fill.dispenseAttempts.find((dispenseAttempt) => {
        return !!(!dispenseAttempt.review || dispenseAttempt.review.approveContainer);
      });

      return {
        ...fill,
        dispenseAttempt: relatedDispenseAttempt,
        drug: displayedDrug,
      };
    });

    return {
      isFulfillmentPaid,
      patient: displayedPatient,
      prescriptionFills: prescriptionFillsForDisplay,
    };
  },
  allowEndingCall(purpose) {
    if (purpose === PURPOSE.PRESCRIPTION_CONSULTATION) {
      const { prescriptionFills } = self.prescriptionFillsForDisplay;
      if (!prescriptionFills.length) return true;
      // If consultation is requested and there are related dispense attempts pending review, forbid ending the call.
      return !prescriptionFills.some((prescriptionFill) => (
        prescriptionFill.consultReq &&
        prescriptionFill.dispenseAttempt &&
        !prescriptionFill.dispenseAttempt.review
      ));
    }

    return true;
  }
}));

export const videoConferencesRouteStore = VideoConferencesRouteStore.create({});
