import { toJS } from 'mobx';

import { pharmacyTechniciansRouteStore } from '../domain/store';
import { pharmacyTechnicianUpsertModalRouteStore } from '../domain/upsert-modal-store';

import { PHARMACY_TECHNICIAN_SKELETON } from '../constants';
import { TECHNICIAN_MANIPULATION_OPTIONS } from 'components/admin-technicians-view/constants';
const { CREATE, UPDATE } = TECHNICIAN_MANIPULATION_OPTIONS;

export default (e, purpose = UPDATE) => {
  switch (purpose) {
    case UPDATE:
      pharmacyTechniciansRouteStore.setValue('activeTechnicianId', e.content.id);
      pharmacyTechniciansRouteStore.setValue('showingUpsertModal', true);
      pharmacyTechnicianUpsertModalRouteStore.setValue('activelyModifiedTechnician', toJS(pharmacyTechniciansRouteStore.activeTechnician));
      break;
    case CREATE:
      pharmacyTechniciansRouteStore.setValue('showingUpsertModal', true);
      pharmacyTechnicianUpsertModalRouteStore.setValue('activelyModifiedTechnician', PHARMACY_TECHNICIAN_SKELETON);
  }
};
