export const APP_COMMAND_TYPE_BASE = {
  HEALTHCARE_SITES_UPSERT: 'healthcareSitesUpsert',
  COMPANIES_CREATE: 'companiesCreate',
  CONTAINERS_ADD_IMAGE_LABELLED: 'containersAddImageLabelled',
  CONTAINERS_ADD_IMAGE_PRE_LABELLED: 'containersAddImagePreLabelled',
  CONTAINERS_ASSIGN_AVAILABLE_POSITION: 'containersAssignAvailablePosition',
  CONTAINERS_CREATE: 'containersCreate',
  CONTAINERS_DISPENSE: 'containersDispense',
  CONTAINERS_LABELLED: 'containersLabelled',
  CONTAINERS_SET_MISPLACED: 'containersSetMisplaced',
  CONTAINERS_SET_NOT_STORED: 'containersSetNotStored',
  CONTAINERS_SET_REMOVED: 'containersSetRemoved',
  CONTAINERS_SET_STORED: 'containersSetStored',
  DISPENSE_ATTEMPTS_APPROVE: 'dispenseAttemptsApprove',
  DISPENSE_ATTEMPTS_CONFIRM_REJECTED: 'dispenseAttemptsConfirmRejected',
  DISPENSE_ATTEMPTS_CREATE: 'dispenseAttemptsCreate',
  DISPENSE_ATTEMPTS_DISMISS_REJECTED: 'dispenseAttemptsDismissRejected',
  DISPENSE_ATTEMPTS_DISPENSE: 'dispenseAttemptsDispense',
  DISPENSE_ATTEMPTS_DISPENSE_REMOTE: 'dispenseAttemptsDispenseRemote',
  DISPENSE_ATTEMPTS_REJECT: 'dispenseAttemptsReject',
  DRUGS_UPSERT: 'drugsUpsert',
  KEYCARDS_CREATE: 'keycardsCreate',
  KEYCARDS_DELETE: 'keycardsDelete',
  KEYCARDS_REQUEST_RESET_PIN: 'keycardsRequestResetPin',
  KEYCARDS_UPDATE: 'keycardsUpdate',
  LEGALESES_CREATE: 'legalesesCreate',
  LOADING_PLATE_SLOT_TEMPLATES_UPSERT: 'loadingPlateSlotTemplatesUpsert',
  LOADING_PLATE_TEMPLATES_UPSERT: 'loadingPlateTemplatesUpsert',
  LOCATIONS_UPSERT: 'locationsUpsert',
  MACHINE_TECHNICIANS_ADD_KIOSK: 'machineTechniciansAddKiosk',
  MACHINE_TECHNICIANS_CREATE: 'machineTechniciansCreate',
  MACHINE_TECHNICIANS_DELETE: 'machineTechniciansDelete',
  MACHINE_TECHNICIANS_REMOVE_KIOSK: 'machineTechniciansRemoveKiosk',
  MACHINE_TECHNICIANS_UPDATE: 'machineTechniciansUpdate',
  MEDICAL_ASSISTANTS_DELETE: 'medicalAssistantsDelete',
  MEDICAL_ASSISTANTS_UPDATE: 'medicalAssistantsUpdate',
  MODULE_SLOT_TEMPLATES_UPSERT: 'moduleSlotTemplatesUpsert',
  MODULE_TEMPLATES_UPDATE_LAYOUT: 'moduleTemplatesUpdateLayout',
  MODULE_TEMPLATES_UPSERT: 'moduleTemplatesUpsert',
  ORGANIZATION_INVITATIONS_CLAIM: 'organizationInvitationsClaim',
  ORGANIZATION_INVITATIONS_CREATE: 'organizationInvitationsCreate',
  ORGANIZATION_INVITATIONS_REJECT: 'organizationInvitationsReject',
  ORGANIZATIONS_CREATE: 'organizationsCreate',
  PATIENT_SIGNATURES_CREATE: 'patientSignaturesCreate',
  PATIENTS_CREATE: 'patientsCreate',
  PATIENTS_DELETE: 'patientsDelete',
  PATIENTS_UPDATE: 'patientsUpdate',
  PAYMENTS_COMPLETE_TRANSACTION: 'paymentsCompleteTransaction',
  PAYMENTS_CREATE_BY_PRESCRIPTION_FILLS_FULFILLMENT: 'paymentsCreateByPrescriptionFillsFulfillment',
  PAYMENTS_PREPARE_TRANSACTION: 'paymentsPrepareTransaction',
  PHARMACIES_ADD_PHARMACIST: 'pharmaciesAddPharmacist',
  PHARMACIES_REMOVE_PHARMACIST: 'pharmaciesRemovePharmacist',
  PHARMACIES_UPSERT: 'pharmaciesUpsert',
  PHARMACY_TECHNICIANS_ADD_PHARMACY: 'pharmacyTechniciansAddPharmacy',
  PHARMACY_TECHNICIANS_CREATE: 'pharmacyTechniciansCreate',
  PHARMACY_TECHNICIANS_DELETE: 'pharmacyTechniciansDelete',
  PHARMACY_TECHNICIANS_REMOVE_PHARMACY: 'pharmacyTechniciansRemovePharmacy',
  PHARMACY_TECHNICIANS_UPDATE: 'pharmacyTechniciansUpdate',
  PHYSICIANS_CREATE: 'physiciansCreate',
  PHYSICIANS_DELETE: 'physiciansDelete',
  PHYSICIANS_UPDATE: 'physiciansUpdate',
  PRESCRIPTION_FILL_PHARMACIST_CONSULTATION_REQUESTS_CREATE: 'prescriptionFillPharmacistConsultationRequestsCreate',
  PRESCRIPTION_FILL_KIOSK_ROUTINGS_CREATE: 'prescriptionFillKioskRoutingsCreate',
  PRESCRIPTION_FILLS_DECLINE: 'prescriptionFillsDecline',
  PRESCRIPTION_FILLS_UPDATE_FULFILLMENT_TYPES: 'prescriptionFillsUpdateFulfillmentTypes',
  PRESCRIPTION_FILLS_UPSERT_WITH_REFERENCES: 'prescriptionFillsUpsertWithReferences',
  PRESCRIPTION_FILLS_FULFILLMENTS_UPSERT: 'prescriptionFillsFulfillmentsUpsert',
  KIOSK_MODULE_SLOTS_SET_NOT_UNPLACEABLE: 'kioskModuleSlotsSetNotUnplaceable',
  KIOSK_MODULE_SLOTS_SET_NOT_UNPLUCKABLE: 'kioskModuleSlotsSetNotUnpluckable',
  KIOSK_MODULE_SLOTS_SET_UNPLACEABLE: 'kioskModuleSlotsSetUnplaceable',
  KIOSK_MODULE_SLOTS_SET_UNPLUCKABLE: 'kioskModuleSlotsSetUnpluckable',
  KIOSK_MODULES_UPSERT: 'kioskModulesUpsert',
  KIOSK_STATUS_CREATE: 'kioskStatusesCreate',
  KIOSKS_RECALCULATE_INVENTORY: 'kiosksRecalculateInventory',
  KIOSKS_UPDATE_UPS_STATUS: 'kiosksUpdateUpsStatus',
  KIOSKS_UPSERT_BY_PHARMACY: 'kiosksUpsertByPharmacy',
  USERS_CHANGE_PASSWORD: 'usersChangePassword',
  USERS_DELETE: 'usersDelete',
  USERS_LOGIN: 'usersLogin',
  USERS_LOGIN_KEYCARD: 'usersLoginKeycard',
  USERS_LOGOUT: 'usersLogout',
  USERS_SEND_RESET_PASSWORD_EMAIL: 'usersSendResetPasswordEmail',
  USERS_SIGN_UP: 'usersSignUp',
  USERS_SIGN_UP_KEYCARD: 'usersSignUpKeycard',
  USERS_UPDATE: 'usersUpdate',
  VIDEO_CONFERENCE_INVITATIONS_ACCEPT: 'videoConferenceInvitationsAccept',
  VIDEO_CONFERENCE_INVITATIONS_CREATE: 'videoConferenceInvitationsCreate',
  VIDEO_CONFERENCE_INVITATIONS_REJECT: 'videoConferenceInvitationsReject',
  VIDEO_CONFERENCE_PARTICIPANT_CONNECTIONS_PING: 'videoConferenceParticipantConnectionsPing',
  VIDEO_CONFERENCES_CONNECT: 'videoConferencesConnect',
  VIDEO_CONFERENCES_CREATE: 'videoConferencesCreate',
  VIDEO_CONFERENCES_DISCONNECT: 'videoConferencesDisconnect'
};

export const APP_COMMAND_TYPE_BASE_LIST = Object.values(APP_COMMAND_TYPE_BASE);

export const APP_COMMAND_TYPE = Object.keys(APP_COMMAND_TYPE_BASE).reduce((res, key) => {
  if (!key.includes('UPSERT')) res[key] = APP_COMMAND_TYPE_BASE[key];
  else {
    res[key.replace('UPSERT', 'CREATE')] = APP_COMMAND_TYPE_BASE[key].replace('Upsert', 'Create');
    res[key.replace('UPSERT', 'UPDATE')] = APP_COMMAND_TYPE_BASE[key].replace('Upsert', 'Update');
  }

  return res;
}, {});
