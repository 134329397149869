import React from 'react';
import { observer } from 'mobx-react';

// Components
import ContentScreen from 'components/content-screen';
import InfiniteScroll from 'components/infinite-scroll';

import './index.scss';

const List = ({
  header,
  children,
  isReady = true,
  onBottomThreshold,
  onTopThreshold,
}) => {
  return <div className='c-List'>
    {
      !!header && header
    }
    <InfiniteScroll
      className='list-body'
      onBottomThreshold={onBottomThreshold}
      onTopThreshold={onTopThreshold}
    >
      <ContentScreen active={!isReady} fadeDuration={500}/>
      {
        children
      }
    </InfiniteScroll>
  </div>;
};

export default observer(List);
