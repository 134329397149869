import React from 'react';
import { observer } from 'mobx-react';

import CaptionValueInput from 'components/caption-value-input';
import CaptionValueInputWithSelect from 'components/caption-value-input-with-select';
import LabelledCheckbox from 'components/labelled-checkbox';
import Modal from 'components/modal';
import ModalEditingAreas from 'components/modal/layout/editing-areas';
import ModalEditingArea from 'components/modal/layout/editing-areas/components/editing-area';
import ModalRow from 'components/modal/layout/row';
import ModalButton from 'components/modal/layout/button';

import './index.scss';

const KeycardUpsertModal = ({
  keycardUpsertModalRouteStore,
  keycardsRouteStore,
  createKeycard,
  updateKeycard,
  closeKeycardUpsertModal,
  resetPin,
  listUsers,
  listUsersDebounce,
  onSelectUser,
  listPharmacies,
  listPharmaciesDebounce,
}) => {
  const activeKeycard = keycardsRouteStore.activeKeycard;
  const activelyModifiedKeycard = keycardUpsertModalRouteStore.activelyModifiedKeycard || {};

  return (
    <Modal
      modalStyles={{ height: '54%', width: '50%' }}
      isOpen={keycardsRouteStore.showingKeycardUpsertModal}
      shouldCloseOnOverlayClick={true}
      close={closeKeycardUpsertModal}
      title={activeKeycard ? activeKeycard.value : 'New Keycard'}
    >
      <ModalEditingAreas>
        <ModalEditingArea>
          {
            !activeKeycard &&
            <ModalRow>
              <CaptionValueInput
                inputFieldClassNames={['max-width']}
                readOnly={true}
                placeholder='Generated After a User is Selected...'
                caption='Value'
                value={activelyModifiedKeycard.value}
                onChange={(value) => keycardUpsertModalRouteStore.setValue('activelyModifiedKeycard.value', value)}
              />
            </ModalRow>
          }
          <ModalRow>
            <LabelledCheckbox
              label='Enabled'
              isChecked={!activelyModifiedKeycard.disabled}
              onChange={(reversedValue) => keycardUpsertModalRouteStore.setValue('activelyModifiedKeycard.disabled', !reversedValue)}
            />
          </ModalRow>
          <ModalRow shouldBeSpaceBetween={true} shouldBoundToBottom={true}>
            <ModalButton
              isDisabled={!activeKeycard && !keycardUpsertModalRouteStore.readyForCreation}
              color='blue'
              text='Save'
              onClick={activeKeycard ? updateKeycard : createKeycard}
            />
            {
              activeKeycard &&
              <ModalButton
                color='red'
                text='Reset PIN'
                onClick={resetPin}
              />
            }
          </ModalRow>
        </ModalEditingArea>
        <ModalEditingArea>
          {
            !activeKeycard &&
            <ModalRow>
              <CaptionValueInputWithSelect
                items={keycardUpsertModalRouteStore.autocompleteUsers}
                inputFieldClassNames={['max-width']}
                caption='User'
                value={keycardUpsertModalRouteStore.userSelect}
                onChange={(data) => onSelectUser(data)}
                onInputChange={(data) => {
                  if (!data) return;
                  keycardUpsertModalRouteStore.setValue('userSelectFilter', data);
                  return listUsersDebounce();
                }}
                onFocus={() => listUsers()}
              />
            </ModalRow>
          }
          {
            !activeKeycard &&
            <ModalRow>
              <CaptionValueInputWithSelect
                items={keycardUpsertModalRouteStore.autocompletePharmacies}
                inputFieldClassNames={['max-width']}
                caption='Pharmacy'
                value={keycardUpsertModalRouteStore.pharmacySelect}
                onChange={(data) => {
                  if (!data) data = { label: '', value: '' };
                  keycardUpsertModalRouteStore.setValue('pharmacySelect', data);
                }}
                onInputChange={(data) => {
                  if (!data) return;
                  keycardUpsertModalRouteStore.setValue('pharmacySelectFilter', data);
                  return listPharmaciesDebounce();
                }}
                onFocus={() => listPharmacies()}
              />
            </ModalRow>
          }
          {
            activeKeycard && keycardsRouteStore.emailSent &&
            <ModalRow shouldBeSpaceBetween={true} shouldBoundToBottom={true} className='c-ResetPinEmailSent'>
              Email Sent
            </ModalRow>
          }
        </ModalEditingArea>
      </ModalEditingAreas>
    </Modal>
  );
};

export default observer(KeycardUpsertModal);
