import React from 'react';
import DatePicker from 'react-date-picker';
import { observer } from 'mobx-react';
import moment from 'moment';

import './period-picker-styles.scss';

const DatePeriodPicker = ({
  startDate,
  onStartDateChange,
  endDate,
  onEndDateChange,
}) => {
  return <div className='c-DatePeriodPicker'>
    <div className='date-range-picker start-date'>
      <DatePicker
        value={startDate}
        required={true}
        clearIcon={false}
        onChange={(date) => {
          if (!onStartDateChange) return;
          if (!date) return onStartDateChange(null);
          // Set the date to 12:00:00 AM (local time) so that the date range includes the entire day
          const startOf = moment(date).startOf('day').toDate();
          onStartDateChange(startOf);
        }}
      />
      <div className='filter-label'>
        Start Date
      </div>
    </div>
    <div className='date-range-picker end-date'>
      <DatePicker
        value={endDate}
        required={true}
        clearIcon={false}
        onChange={(date) => {
          if (!onEndDateChange) return;
          if (!date) return onEndDateChange(null);
          // Set the date to 11:59:59 PM (local time) so that the date range includes the entire day
          const endOf = moment(date).endOf('day').toDate();
          onEndDateChange(endOf);
        }}
      />
      <div className='filter-label'>
        End Date
      </div>
    </div>
  </div>;
};

export default observer(DatePeriodPicker);
