import stores from 'bootstrap/store';
// import { NOTIFICATION_TYPE } from '../../../constants';

// Dispense Attempts
import ContainerIcon from 'assets/icons/container.svg';
import ContainerActiveIcon from 'assets/icons/container-active.svg';

// Video Conferences
import VideoIcon from 'assets/icons/video.svg';
import VideoActiveIcon from 'assets/icons/video-active.svg';

export const NOTIFICATION_PREVIEW_INFO = {
  // TODO getting "Uncaught ReferenceError: Cannot access 'NOTIFICATION_TYPE' before initialization" when using [NOTIFICATION_TYPE.DISPENSE_ATTEMPTS]
  dispenseAttempts: {
    icon: ContainerIcon,
    activeIcon: ContainerActiveIcon,
    getNotificationsCount: () => stores.global.dispenseAttempts.pendingArray.length,
  },
  videoConferences: {
    icon: VideoIcon,
    activeIcon: VideoActiveIcon,
    getNotificationsCount: () => stores.global.videoConferences.pendingArray.length,
  },
};


export const NEW_NOTIFICATION_ANIMATION = {
  CLASS: 'new-notification-animation',
  DURATION: 4000,
};
