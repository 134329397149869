import React from 'react';
import { observer } from 'mobx-react';

// Components
import DrillDownLayout from 'components/drill-down-layout';
import List from './components/list';
import Content from './components/content';

// Styles
import './pharmacy-technicians-view.scss';

const PharmacyTechniciansView = ({
  pharmacyTechniciansStore,
  pharmacyTechniciansRouteStore,
  setFilterValue,
  listPharmacyTechnicians,
  rootRoute,
}) => (
  <div className='r-TechniciansView'>
    <DrillDownLayout>
      <List
        pharmacyTechniciansRouteStore={pharmacyTechniciansRouteStore}
        pharmacyTechniciansStore={pharmacyTechniciansStore}
        setFilterValue={setFilterValue}
        listPharmacyTechnicians={listPharmacyTechnicians}
        rootRoute={rootRoute}
      />
      <Content
        pharmacyTechniciansRouteStore={pharmacyTechniciansRouteStore}
      />
    </DrillDownLayout>
  </div>
);


export default observer(PharmacyTechniciansView);
