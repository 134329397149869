import React from 'react';
import { observer } from 'mobx-react';

import KeyValuePair from '../keyValuePair';

import '../../prescriptionInfoBlockStyles.scss';

const PatientInfoBlock = ({
  patient,
}) => (
  <div className='c-PrescriptionInfoBlock'>
    <div className='title'>
      Patient
    </div>
    <div className='data'>
      <KeyValuePair
        title='Name'
        data={patient}
        keys={['firstName', 'lastName']}
      />
      <KeyValuePair
        title='Email'
        data={patient}
        keys={['patientUser.user.email']}
      />
      <KeyValuePair
        title='Phone'
        data={patient}
        keys={['patientUser.user.phoneNumber']}
      />
    </div>
  </div>
);

export default observer(PatientInfoBlock);
