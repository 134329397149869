import React from 'react';
import Modal from 'react-modal';
import { observer } from 'mobx-react';

import CrossIcon from 'assets/icons/cross.svg';

import './modal-styles.scss';

Modal.setAppElement('#root');

const ModalWrapper = ({
  children,
  isOpen = false,
  close,
  shouldCloseOnOverlayClick = true,
  modalClassName = '',
  overlayClassName = '',
  modalStyles = {},
  overlayStyles = {},
  shouldOverflowHeader = true,
  title,
}) => (
  <Modal
    overlayClassName={`ModalOverlay ${shouldOverflowHeader ? '' : 'ModalOverlay-under-header'} ${overlayClassName}`}
    className={`c-Modal ${modalClassName}`}
    isOpen={isOpen}
    shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
    onRequestClose={close}
    style={{
      content: modalStyles,
      overlay: { zIndex: 1101, ...overlayStyles },
    }}
  >
    {
      !!title &&
          <div className='header'>
            <div className='title-container'>
              <div className='title'>
                {
                  title
                }
              </div>
            </div>
            <div className='close-modal-icon-container'>
              <img
                alt='Close Modal'
                className='close-modal-icon'
                src={CrossIcon}
                onClick={close}
              />
            </div>
          </div>
    }
    {
      children
    }
  </Modal>
);

export default observer(ModalWrapper);
