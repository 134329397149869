import { flow, types } from 'mobx-state-tree';
import {
  isEqual as _isEqual,
  get as _get,
  keyBy as _keyBy,
} from 'lodash';

import base from 'models/base';
import { MODEL_NAME, SCHEMA } from './constants/schema';


const MachineTechnicianModel = base({
  names: {
    singular: MODEL_NAME.SINGULAR,
    plural: MODEL_NAME.PLURAL
  },
  JSONSchema: SCHEMA,
  httpConfig: {
    root: MODEL_NAME.plural,
    methods: {}
  }
});

MachineTechnicianModel.configureStore((store) => {
  return store
  .props({
    machineTechnicians: types.optional(types.map(types.frozen()), {}),
    entriesCount: types.optional(types.number, 0),
    machineTechnicianStatistic: types.optional(types.frozen(), {}),
  })
  .actions(self => ({
    setValue: (prop, value) => self[prop] = value,
    listMachineTechnicians: flow(function* (config = {}) {
      const offset = _get(config, 'query.offset', 0);
      const limit = _get(config, 'query.limit', 0);
      if (!offset && self.machineTechnicians.size > limit) self.machineTechnicians = {};

      const response = yield self.list(config);
      const data = response.data || response || [];
      self.machineTechnicians.merge(_keyBy(data, 'id'));

      return response;
    }),
    listMachineTechniciansForAdmin: flow(function* (config = {}) {
      const offset = _get(config, 'query.offset', 0);
      const limit = _get(config, 'query.limit', 0);
      if (!offset && self.machineTechnicians.size > limit) self.machineTechnicians = {};

      const response = yield self.list(config);
      const data = response.data || response || [];

      // If data contains the same elements as dataForPharmacist but in reverse order,
      // mobx-state-tree's map treats it as the same set of elements, so views won't rerender.
      const sameData = _isEqual(self.dataAsArray.map(i => i.id).sort(), data.map(i => i.id).sort());
      if (sameData) self.machineTechnicians = {};
      self.machineTechnicians = _keyBy(data, 'id');

      return response;
    }),
    countMachineTechnicians: flow(function* (config = {}) {
      config.urlFragment = () => '/count';

      const response = yield self.list(config);
      self.entriesCount = response || 0;

      return response;
    }),
    createMachineTechnician: (config = {}) => {
      config.urlFragment = () => '';
      return self.post(config);
    },
    updateMachineTechnician: (config = {}) => {
      if (!_get(config, 'params.machineTechnicianId')) {
        return Promise.reject('machineTechnicianId is required in order to update machine technician');
      }

      config.urlFragment = (params) => `${params.machineTechnicianId}`;
      return self.put(config);
    },
    addMachine: (config = {}) => {
      if (!_get(config, 'params.machineTechnicianId')) {
        return Promise.reject('machineTechnicianId is required in order to add kiosk to machine technician');
      }
      if (!_get(config, 'params.kioskId')) {
        return Promise.reject('kioskId is required in order to add kiosk to machine technician');
      }

      config.urlFragment = (params) => `/${params.machineTechnicianId}/kiosks/${params.kioskId}`;
      return self.post(config);
    },
    deleteMachine: (config = {}) => {
      if (!_get(config, 'params.machineTechnicianId')) {
        return Promise.reject('machineTechnicianId is required in order to delete kiosk from machine technician');
      }
      if (!_get(config, 'params.kioskId')) {
        return Promise.reject('kioskId is required in order to delete kiosk to machine technician');
      }

      config.urlFragment = (params) => `/${params.machineTechnicianId}/kiosks/${params.kioskId}`;
      return self.delete(config);
    },
    deleteMachineTechnician: (config = {}) => {
      if (!_get(config, 'params.machineTechnicianId')) {
        return Promise.reject('machineTechnicianId is required in order to delete machine technician');
      }

      config.urlFragment = (params) => `/${params.machineTechnicianId}`;
      return self.delete(config);
    },
    fetchMachineTechnicianStatistic: flow(function* (config = {}) {
      config.urlFragment = (params) => `/${params.machineTechnicianId}/statistic`;

      const response = yield self.get(config);
      self.machineTechnicianStatistic = response.data || response || {};

      return response;
    }),
  }))
  .views(self => ({
    get dataAsArray() {
      return Array.from(self.machineTechnicians.values());
    },
  }))
  .views(self => ({
    get dataList() {
      return Array.from(self.machineTechnicians.values());
    }
  }));
});

// Can't use module.exports because there is a generator function in this file,
// and that causes module.exports to fail for some reason.
export default MachineTechnicianModel;
