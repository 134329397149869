import { types } from 'mobx-state-tree';
import { get as _get, set as _set } from 'lodash';

import stores from 'bootstrap/store';

export const MedicalAssistantsRouteStore = types.model({
})
.props({
  filterValue: types.optional(types.string, ''),
  activeMedicalAssistantId: types.maybeNull(types.string),
  sortingColumn: types.optional(types.model({
    fieldName: types.optional(types.string, 'name'),
    sortingOrder: types.optional(types.string, 'asc')
  }), {}),
  http: types.optional(types.model({
    listingMedicalAssistants: types.optional(types.number, 0),
    countingMedicalAssistants: types.optional(types.number, 0),
  }), {}),
  currentPage: types.optional(types.number, 1),
  showingUpsertModal: types.optional(types.boolean, false)
})
.actions(self => ({
  setValue: (path, value) => _set(self, path, value),
  incrementHttp: (http) => ++self.http[http],
  decrementHttp: (http) => --self.http[http],
}))
.views(self => ({
  get loading() {
    return !!(self.http.listingMedicalAssistants && self.http.countingMedicalAssistants);
  },
  get activeMedicalAssistant() {
    const medicalAssistantsStore = stores.global.medicalAssistants;
    return self.activeMedicalAssistantId && medicalAssistantsStore.medicalAssistants.get(self.activeMedicalAssistantId);
  },
  get forTable() {
    const medicalAssistantsStore = stores.global.medicalAssistants;
    return medicalAssistantsStore.dataAsArray.map((medicalAssistant) => ({
      id: medicalAssistant.id,
      name: `${medicalAssistant.firstName} ${medicalAssistant.lastName}`,
      email: _get(medicalAssistant, 'maUser.user.email') || 'N/A',
      phoneNumber: _get(medicalAssistant, 'maUser.user.phoneNumber') || 'N/A',
      healthcareSites: medicalAssistant.healthcareSites,
    }));
  },
}));

export const medicalAssistantsRouteStore = MedicalAssistantsRouteStore.create({});
