import React from 'react';
import { observer } from 'mobx-react';
import { map as _map, get as _get } from 'lodash';
import { Stage, Layer, Rect, Circle } from 'react-konva';

import * as CONSTANTS from './constants';

import './module-template-style.scss';

const ModuleTemplatesList = ({
  moduleTemplate,
  removeModuleTemplate= () => {},
}) => (
  <div
    className='c-ModuleTemplate'
  >
    <div className='module-template-data'>
      <div className='module-size-parameters'>
        <div className='modules-size'>
          <div className='param'>
            <div className='key'>H</div>
            <div className='value'>{`${Number(moduleTemplate.height)} mm`}</div>
          </div>
          <div className='param'>
            <div className='key'>W</div>
            <div className='value'>{`${Number(moduleTemplate.width)} mm`}</div>
          </div>
        </div>
      </div>
    </div>

    <div className='module-template-silhouette'>
      <div className='module-template-remove-button' onClick={evt=>removeModuleTemplate(evt, moduleTemplate)}>X</div>
      <Stage
        className='silhouette'
        width={moduleTemplate.width * CONSTANTS.MODULE_LAYOUT.DISPLAY_COEFFICIENT}
        height={moduleTemplate.height * CONSTANTS.MODULE_LAYOUT.DISPLAY_COEFFICIENT}
      >
        {/* KIOSK silhouette layer */}
        <Layer>
          {/* Silhouette */}
          <Rect
            x={CONSTANTS.MODULE_LAYOUT.SILHOUETTE.X}
            y={CONSTANTS.MODULE_LAYOUT.SILHOUETTE.Y}
            width={moduleTemplate.width * CONSTANTS.MODULE_LAYOUT.DISPLAY_COEFFICIENT}
            height={moduleTemplate.height * CONSTANTS.MODULE_LAYOUT.DISPLAY_COEFFICIENT}
            cornerRadius={CONSTANTS.MODULE_LAYOUT.SILHOUETTE.CORNER_RADIUS}
            fill={CONSTANTS.MODULE_LAYOUT.SILHOUETTE.COLOR}
          />
        </Layer>

        {/* KIOSK modules layer */}
        <Layer>
          {
            _map(_get(moduleTemplate, 'moduleSlotTemplates'), (slot, index) => {
              return slot.slot_template.containerConstraintRadiusMax ?
                <Circle
                  key={index}
                  x={Number(slot.x * CONSTANTS.MODULE_LAYOUT.DISPLAY_COEFFICIENT)}
                  y={Number(slot.y * CONSTANTS.MODULE_LAYOUT.DISPLAY_COEFFICIENT)}
                  radius={slot.slot_template.containerConstraintRadiusMax * CONSTANTS.MODULE_LAYOUT.DISPLAY_COEFFICIENT}
                  fill={CONSTANTS.MODULE_LAYOUT.SLOTS.COLOR}
                />
                : <Rect
                  key={index}
                  x={Number(slot.x * CONSTANTS.MODULE_LAYOUT.DISPLAY_COEFFICIENT)}
                  y={Number(slot.y * CONSTANTS.MODULE_LAYOUT.DISPLAY_COEFFICIENT)}
                  width={slot.slot_template.containerConstraintWidthMax * CONSTANTS.MODULE_LAYOUT.DISPLAY_COEFFICIENT}
                  height={slot.slot_template.containerConstraintHeightMax * CONSTANTS.MODULE_LAYOUT.DISPLAY_COEFFICIENT}
                  fill={CONSTANTS.MODULE_LAYOUT.SLOTS.COLOR}
                />;
            })
          }
        </Layer>
      </Stage>
    </div>
  </div>
);


export default observer(ModuleTemplatesList);
