import React from 'react';
import { observer } from 'mobx-react';

import PharmacyUpsertModal from './components/pharmacy-upsert-modal';

import AdminTableLayout from 'components/admin-table-layout';
import Title from 'components/admin-table-layout/components/title';
import TableHeader from 'components/admin-table-layout/components/table-header';
import EntriesCount from 'components/admin-table-layout/components/table-header/components/entries-count';
import Paginator from 'components/paginator';
import NewEntryButton from 'components/admin-table-layout/components/table-header/components/new-entry-button';
import ListSearch from 'components/drill-down-layout/components/list/components/list-header/components/list-search';
import TableContent from 'components/admin-table-layout/components/table-content';
import SmartTable from 'components/smart-table';

import {
  COLUMNS,
  PHARMACY_MANIPULATION_OPTIONS
} from './constants';
import { MAX_TABLE_ENTRIES_PER_PAGE } from 'models/pharmacies/constants';

import './pharmacies-style.scss';

const { CREATE, UPDATE } = PHARMACY_MANIPULATION_OPTIONS;

const PharmaciesView = ({
  pharmaciesStore,
  pharmaciesRouteStore,
  pharmacyUpsertModalRouteStore,
  pharmacyHealthcareSitesStore,
  healthcareSitesStore,
  locationsStore,
  sortPharmacies,
  changePaginationPage,
  onPharmaciesSearch,
  showPharmacyUpsertModal,
  closePharmacyUpsertModal,
  showPharmacyDeleteConfirmationModal,
  createPharmacy,
  updatePharmacy,
  deletePharmacy
}) => (
  <React.Fragment>
    <PharmacyUpsertModal
      pharmacyUpsertModalRouteStore={pharmacyUpsertModalRouteStore}
      pharmaciesRouteStore={pharmaciesRouteStore}
      locationsStore={locationsStore}
      pharmacyHealthcareSitesStore={pharmacyHealthcareSitesStore}
      healthcareSitesStore={healthcareSitesStore}
      createPharmacy={createPharmacy}
      updatePharmacy={updatePharmacy}
      deletePharmacy={deletePharmacy}
      closePharmacyUpsertModal={closePharmacyUpsertModal}
      showPharmacyDeleteConfirmationModal={showPharmacyDeleteConfirmationModal}
    />
    <AdminTableLayout>
      <Title
        text='Pharmacies'
      />
      <TableHeader>
        <div className='left-area'>
          <EntriesCount
            count={pharmaciesStore.entriesCount}
            caption='Pharmacies'
          />
          <Paginator
            currentPage={pharmaciesRouteStore.currentPage}
            entriesCount={pharmaciesStore.entriesCount}
            entriesPerPage={MAX_TABLE_ENTRIES_PER_PAGE}
            changePage={changePaginationPage}
          />
        </div>
        <div className='right-area'>
          <NewEntryButton
            caption='New Pharmacy'
            onClick={e => showPharmacyUpsertModal(e, CREATE)}
          />
          <ListSearch
            searchValue={pharmaciesRouteStore.filterValue}
            onSearch={onPharmaciesSearch}
          />
        </div>
      </TableHeader>
      <TableContent>
        <SmartTable
          sortingFieldName={pharmaciesRouteStore.sortingColumn.fieldName}
          sortingOrder={pharmaciesRouteStore.sortingColumn.sortingOrder}
          onSortIconClick={sortPharmacies}
          rows={pharmaciesRouteStore.forTable}
          onRowClick={content => showPharmacyUpsertModal(content, UPDATE)}
          columns={COLUMNS}
          loading={pharmaciesRouteStore.loading}
        />
      </TableContent>
    </AdminTableLayout>
  </React.Fragment>
);

export default observer(PharmaciesView);
