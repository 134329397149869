import { flow } from 'mobx-state-tree';
import { get as _get, isString as _isString } from 'lodash';

import base from 'models/base';
import { MODEL_NAME, SCHEMA } from './constants/schema';
import { ACTIONS } from './constants';

const CallRequestPharmacistActionsModel = base({
  names: {
    singular: MODEL_NAME.SINGULAR,
    plural: MODEL_NAME.PLURAL
  },
  JSONSchema: SCHEMA,
  httpConfig: {
    root: MODEL_NAME.plural,
    methods: {}
  }
});

CallRequestPharmacistActionsModel.configureStore((store) => {
  return store
  .props({
  })
  .actions(self => ({
    createPharmacistAction: flow(function* (config = {}) {
      const callRequestId = _get(config, 'body.callRequestId');
      if (!callRequestId || !_isString(callRequestId)) {
        return Promise.reject('"callRequestId" must be provided in body and be a string in order to create a pharmacist action.');
      }

      const action = _get(config, 'body.action');
      if (!action || !ACTIONS.includes(action)) {
        return Promise.reject(`"action" must be provided in body and be one of ${ACTIONS} in order to create a pharmacist action.`);
      }

      const response = yield self.post(config);
      return response;
    }),
  }))
  .views(() => ({
  }));
});

export default CallRequestPharmacistActionsModel;
