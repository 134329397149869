import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import 'yet-another-abortcontroller-polyfill';

import PrescriptionFillsView from './prescription-fills-view';

import useDebouncedValue from 'hooks/useDebouncedValue';
import usePrevious from 'hooks/usePrevious';
import useSocketEvent from 'hooks/useSocketEvent';

import * as helpers from './helpers';

import { prescriptionFillsRouteStore } from './domain/store';

import { EVENT } from 'bootstrap/api-websocket/constants';

const PrescriptionFillsContainer = () => {
  const queryClient = useQueryClient();
  
  const debouncedFilterValue = useDebouncedValue(prescriptionFillsRouteStore.filterValue, 400);
  const previousFilterValue = usePrevious(debouncedFilterValue);

  useEffect(() => {
    prescriptionFillsRouteStore.setInitialState();

    return () => {
      prescriptionFillsRouteStore.setInitialState();
    };
  }, []);

  useEffect(() => {
    helpers.listPrescriptionFills({
      refetch: Boolean(prescriptionFillsRouteStore.forTable.length),
      merge: false,
    });
  }, [prescriptionFillsRouteStore.dateRange.start, prescriptionFillsRouteStore.dateRange.end]);

  useSocketEvent(EVENT.RECEIVED.PRESCRIPTION_FILLS_SET_FULFILLMENT_TYPE, async ({ prescriptionFillId }) => {
    if (prescriptionFillsRouteStore.prescriptionFillsById.get(prescriptionFillId)) {
      helpers.listPrescriptionFills({ refetch: true });
    }
  });

  const onPrescriptionFillsSearch = useCallback((e) => {
    queryClient.cancelQueries({ queryKey: ['searchPrescriptionFills'] });
    prescriptionFillsRouteStore.setValue('filterValue', e.target.value);
  }, [queryClient]);

  useQuery({
    queryKey: ['searchPrescriptionFills', debouncedFilterValue],
    queryFn: ({ signal }) => {
      prescriptionFillsRouteStore.incrementHttp('listing');
      return helpers.listPrescriptionFills({
        filter: debouncedFilterValue,
        refetch: true,
        merge: false,
        signal,
        controlledAbort: true,
      })
      .finally(() => prescriptionFillsRouteStore.decrementHttp('listing'));
    },
    enabled: Boolean(debouncedFilterValue) || Boolean(!debouncedFilterValue && previousFilterValue),
  });

  const loadMorePrescriptionFills = useCallback(async () => {
    return helpers.listPrescriptionFills({
      filter: prescriptionFillsRouteStore.filterValue,
      refetch: false,
      merge: true,
    });
  }, [prescriptionFillsRouteStore.filterValue]);

  return (
    <PrescriptionFillsView
      prescriptionFillsRouteStore={prescriptionFillsRouteStore}
      onPrescriptionFillsSearch={onPrescriptionFillsSearch}
      showPrescriptionFillEditModal={helpers.showPrescriptionFillEditModal}
      closePrescriptionFillEditModal={helpers.closePrescriptionFillEditModal}
      showSendReceiptModal={helpers.showSendReceiptModal}
      closeSendReceiptModal={helpers.closeSendReceiptModal}
      listPrescriptionFills={helpers.listPrescriptionFills}
      cancelPrescriptionFill={helpers.cancelPrescriptionFill}
      loadMorePrescriptionFills={loadMorePrescriptionFills}
    />
  );
};

export default observer(PrescriptionFillsContainer);
