import deepFreeze from 'deep-freeze';

export const MODEL_NAME = Object.freeze({
  SINGULAR: 'kioskStatus',
  PLURAL: 'kiosk-statuses'
});

export const FIELD_NAME = Object.freeze({
  ID: 'id',
  CREATED_AT: 'createdAt',
  UPDATED_AT: 'updatedAt',
  KIOSK_ID: 'kioskId',
});

export const SCHEMA = deepFreeze({
  title: MODEL_NAME.SINGULAR,
  type: 'object',
  properties: {
    [FIELD_NAME.ID]: {
      type: 'string'
    },
    [FIELD_NAME.CREATED_AT]: {
      type: 'string'
    },
    [FIELD_NAME.UPDATED_AT]: {
      type: 'string',
    },
    [FIELD_NAME.KIOSK_ID]: {
      type: 'string',
    },
  },
  required: [
    FIELD_NAME.ID,
    FIELD_NAME.CREATED_AT,
  ]
});
