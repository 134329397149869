import { listKiosksDebounce } from './index';

import stores from 'bootstrap/store';
import { kiosksRouteStore } from '../domain/store';
const kiosksStore = stores.global.kiosks;

export default (e) => {
  kiosksRouteStore.setValue('filterValue', e.target.value);
  kiosksRouteStore.setValue('currentPage', 1);
  kiosksStore.setValue('dataForPharmacist', {});
  return listKiosksDebounce();
};
