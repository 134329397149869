import React from 'react';
import { observer } from 'mobx-react';
import _get from 'lodash/get';

import AdminTableLayout from 'components/admin-table-layout';
import Content from 'components/drill-down-layout/components/content';
import ContentTab from 'components/drill-down-layout/components/content/components/content-tab';
import DatePeriodPicker from 'components/date-period-picker';
import Title from 'components/admin-table-layout/components/title';
import StatsCategory from './components/stats-category';

import { PRESCRIPTION_FILLS } from 'constants/routes';

import './prescription-fills-stats-styles.scss';

const PrescriptionFillsStatsView = ({ prescriptionFillsStatsRouteStore }) => (
  <AdminTableLayout customClassName='c-PrescriptionFillsStats'>
    <div className='header-area'>
      <div className='title-area'>
        <Title text='Prescription Stats' />
      </div>
      <div className='filters-area'>
        <DatePeriodPicker
          startDate={prescriptionFillsStatsRouteStore.dateRange.start}
          onStartDateChange={(date) => prescriptionFillsStatsRouteStore.setStartDate(date)}
          endDate={prescriptionFillsStatsRouteStore.dateRange.end}
          onEndDateChange={(date) => prescriptionFillsStatsRouteStore.setEndDate(date)}
        />
        <div className='invert-btn-container'>
          <button className='invert-btn' onClick={() => prescriptionFillsStatsRouteStore.setValue('invert', !prescriptionFillsStatsRouteStore.invert)}>
            {
              prescriptionFillsStatsRouteStore.invert ? 'By Kiosk' : 'By Physician'
            }
          </button>
        </div>
      </div>
    </div>
    <Content isReady={Boolean(prescriptionFillsStatsRouteStore.unpacked)}>
      <ContentTab
        title={`${PRESCRIPTION_FILLS.SUB_ROUTE.STATS.CONTENT_TAB.FILLED.TITLE} (${_get(prescriptionFillsStatsRouteStore, 'unpacked.filled.count')})`}
        route={PRESCRIPTION_FILLS.SUB_ROUTE.STATS.CONTENT_TAB.FILLED.VALUE}
      >
        <StatsCategory
          type='filled'
          invert={prescriptionFillsStatsRouteStore.invert}
          unpacked={prescriptionFillsStatsRouteStore.unpacked}
        />
      </ContentTab>
      <ContentTab
        title={`${PRESCRIPTION_FILLS.SUB_ROUTE.STATS.CONTENT_TAB.DISPENSED.TITLE} (${_get(prescriptionFillsStatsRouteStore, 'unpacked.dispensed.count')})`}
        route={PRESCRIPTION_FILLS.SUB_ROUTE.STATS.CONTENT_TAB.DISPENSED.VALUE}
      >
        <StatsCategory
          type='dispensed'
          invert={prescriptionFillsStatsRouteStore.invert}
          unpacked={prescriptionFillsStatsRouteStore.unpacked}
        />
      </ContentTab>
      <ContentTab
        title={`${PRESCRIPTION_FILLS.SUB_ROUTE.STATS.CONTENT_TAB.MAIL_ORDERED.TITLE} (${_get(prescriptionFillsStatsRouteStore, 'unpacked.mailed.count')})`}
        route={PRESCRIPTION_FILLS.SUB_ROUTE.STATS.CONTENT_TAB.MAIL_ORDERED.VALUE}
      >
        <StatsCategory
          type='mailed'
          invert={prescriptionFillsStatsRouteStore.invert}
          unpacked={prescriptionFillsStatsRouteStore.unpacked}
        />
      </ContentTab>
      <ContentTab
        title={`${PRESCRIPTION_FILLS.SUB_ROUTE.STATS.CONTENT_TAB.DECLINED.TITLE} (${_get(prescriptionFillsStatsRouteStore, 'unpacked.declined.count')})`}
        route={PRESCRIPTION_FILLS.SUB_ROUTE.STATS.CONTENT_TAB.DECLINED.VALUE}
      >
        <StatsCategory
          type='declined'
          invert={prescriptionFillsStatsRouteStore.invert}
          unpacked={prescriptionFillsStatsRouteStore.unpacked}
        />
      </ContentTab>
    </Content>
  </AdminTableLayout>
);

export default observer(PrescriptionFillsStatsView);
