import deepFreeze from 'deep-freeze';

export const MODEL_NAME = Object.freeze({
  SINGULAR: 'dispenseAttempt',
  PLURAL: 'dispense-attempts',
});

export const FIELD_NAME = Object.freeze({
  ID: 'id',
  CONTAINER_ID: 'containerId',
  PRESCRIPTION_ID: 'prescriptionId',
  PRESCRIPTION_FILL: 'prescriptionFill',
  DISPENSED: 'dispensed',
  COMPUTED_OUTSTANDING_COPAY: 'computed_outstandingCopay',
  PRESCRIPTION: 'prescription',
  DRUG_NAME: 'drugName',
  CONTAINER: 'container',
  EXPIRATION_DATE: 'expirationDate',
  LOT: 'lot',
  NUMBER: 'number',
  APPROVED: 'approved',
  COPAY: 'copay',
  RX_ID: 'rxId',
  KIOSK_ID: 'kioskId',
  KIOSK: 'kiosk',
  NAME: 'name',
  CREATED_AT: 'createdAt',
  UPDATED_AT: 'updatedAt',
  NDC: 'NDC',
  LABEL_NDC: 'labelNDC',
  UPC: 'UPC',
  QUANTITY: 'quantity',
  DOB: 'DOB',
  LAST_NAME: 'lastName',
  FIRST_NAME: 'firstName',
  NPI: 'NPI',
  SPI: 'SPI',
  EXTERNAL_PHARMACY_ID: 'externalPharmacyId',
  STATE_LICENSE_NUMBER: 'stateLicenseNumber',
  DIRECTIONS: 'directions',
  DRUG: 'drug',
  PATIENT: 'patient',
  GENDER: 'gender',
  ALLERGIES: 'allergies',
  PHYSICIAN: 'physician',
  PATIENT_USER: 'patientUser',
  USER: 'user',
  PHONE_NUMBER: 'phoneNumber',
  PHYSICIAN_USER: 'physicianUser',
  HEALTHCARE_SITES: 'healthcareSites',
  LOCATION: 'location',
  STATE: 'state',
  CITY: 'city',
  STREET: 'street1',
  ZIP: 'zip',
  EMAIL: 'email',

  REVIEW: 'review',
  APPROVE_CONTAINER: 'approveContainer',
  APPROVE_PRESCRIPTION: 'approvePrescription',
  NOTES: 'notes',

  CONSULTATION_REQUEST: 'consultReq',
  COMPUTED_ACTIVE: 'computed_active',

  PRESCRIPTION_FILLS_FULFILLMENT: 'prescriptionFillsFulfillment',
  COMPUTED_CONSULTATION_REQUEST_PENDING: 'computed_consultationRequestPending'
});

export const SCHEMA = deepFreeze({
  title: MODEL_NAME.SINGULAR,
  type: 'object',
  properties: {
    [FIELD_NAME.ID]: {
      type: 'string'
    },

    [FIELD_NAME.CONTAINER_ID]: {
      type: 'string'
    },

    [FIELD_NAME.PRESCRIPTION_ID]: {
      type: 'string'
    },

    [FIELD_NAME.DISPENSED]: {
      type: 'boolean'
    },

    [FIELD_NAME.COMPUTED_OUTSTANDING_COPAY]: {
      type: 'string'
    },

    [FIELD_NAME.KIOSK_ID]: {
      type: 'string'
    },

    [FIELD_NAME.KIOSK]: {
      type: 'object',
      properties: {
        [FIELD_NAME.ID]: {
          type: 'string'
        },
        [FIELD_NAME.NAME]: {
          type: 'string'
        },
      },
      required: [FIELD_NAME.NAME],
    },

    [FIELD_NAME.UPDATED_AT]: {
      type: 'string',
    },

    [FIELD_NAME.CONTAINER]: {
      type: 'object',
      properties: {
        [FIELD_NAME.ID]: {
          type: 'string'
        },
        [FIELD_NAME.UPC]: {
          type: 'string'
        },
        [FIELD_NAME.LABEL_NDC]: {
          type: 'string'
        },
        [FIELD_NAME.QUANTITY]: {
          type: 'string'
        },
        [FIELD_NAME.EXPIRATION_DATE]: {
          type: 'string'
        },
        [FIELD_NAME.APPROVED]: {
          type: 'boolean'
        },
        [FIELD_NAME.CREATED_AT]: {
          type: 'string',
        },
        [FIELD_NAME.LOT]: {
          type: 'object',
          properties: {
            [FIELD_NAME.ID]: {
              type: 'string',
            },
            [FIELD_NAME.NUMBER]: {
              type: 'string',
            },
          },
        },
      }
    },

    [FIELD_NAME.PRESCRIPTION_FILL]: {
      type: 'object',
      properties: {
        [FIELD_NAME.ID]: {
          type: 'string'
        },
        [FIELD_NAME.PRESCRIPTION_ID]: {
          type: 'string'
        },
        [FIELD_NAME.PRESCRIPTION]: {
          type: 'object',
          properties: {
            [FIELD_NAME.ID]: {
              type: 'string'
            },
            [FIELD_NAME.DIRECTIONS]: {
              type: 'string'
            },
            [FIELD_NAME.COPAY]: {
              type: 'string'
            },
            [FIELD_NAME.RX_ID]: {
              type: 'string'
            },
            [FIELD_NAME.DRUG]: {
              type: 'object',
              properties: {
                [FIELD_NAME.ID]: {
                  type: 'string',
                },
                [FIELD_NAME.NAME]: {
                  type: 'string',
                },
                [FIELD_NAME.NDC]: {
                  type: 'string',
                },
                [FIELD_NAME.UPC]: {
                  type: 'string',
                },
              },
              required: [
                FIELD_NAME.ID,
                FIELD_NAME.NAME,
              ]
            },
            [FIELD_NAME.PATIENT]: {
              type: 'object',
              properties: {
                [FIELD_NAME.ID]: {
                  type: 'string',
                },
                [FIELD_NAME.FIRST_NAME]: {
                  type: 'string',
                },
                [FIELD_NAME.LAST_NAME]: {
                  type: 'string',
                },
                [FIELD_NAME.EXTERNAL_PHARMACY_ID]: {
                  type: 'string',
                },
                [FIELD_NAME.GENDER]: {
                  type: 'string',
                },
                [FIELD_NAME.DOB]: {
                  type: 'string',
                },
                [FIELD_NAME.EMAIL]: {
                  type: 'string',
                },
                [FIELD_NAME.PHONE_NUMBER]: {
                  type: 'string',
                },
                [FIELD_NAME.ALLERGIES]: {
                  type: 'array',
                  items: {
                    type: 'object',
                    properties: {
                      name: {
                        type: 'string'
                      }
                    }
                  }
                },
                [FIELD_NAME.PATIENT_USER]: {
                  type: 'object',
                  properties: {
                    [FIELD_NAME.USER]: {
                      type: 'object',
                      properties: {
                        [FIELD_NAME.PHONE_NUMBER]: {
                          type: 'string',
                        },
                        [FIELD_NAME.EMAIL]: {
                          type: 'string',
                        }
                      }
                    }
                  }
                }
              },
              required: [
                FIELD_NAME.ID,
                FIELD_NAME.LAST_NAME,
                FIELD_NAME.FIRST_NAME
              ]
            },
            [FIELD_NAME.PHYSICIAN]: {
              type: 'object',
              properties: {
                [FIELD_NAME.ID]: {
                  type: 'string',
                },
                [FIELD_NAME.NPI]: {
                  type: 'string',
                },
                [FIELD_NAME.SPI]: {
                  type: 'string',
                },
                [FIELD_NAME.FIRST_NAME]: {
                  type: 'string',
                },
                [FIELD_NAME.LAST_NAME]: {
                  type: 'string',
                },
                [FIELD_NAME.STATE_LICENSE_NUMBER]: {
                  type: 'string',
                },
                [FIELD_NAME.PHYSICIAN_USER]: {
                  type: 'object',
                  properties: {
                    [FIELD_NAME.USER]: {
                      type: 'object',
                      properties: {
                        [FIELD_NAME.PHONE_NUMBER]: {
                          type: 'string',
                        },
                        [FIELD_NAME.EMAIL]: {
                          type: 'string',
                        }
                      }
                    }
                  }
                },
                [FIELD_NAME.HEALTHCARE_SITES]: {
                  type: 'array',
                  items: {
                    type: 'object',
                    properties: {
                      [FIELD_NAME.NAME]: {
                        type: 'string'
                      },
                      [FIELD_NAME.LOCATION]: {
                        type: 'object',
                        properties: {
                          [FIELD_NAME.STREET]: {
                            type: 'string'
                          },
                          [FIELD_NAME.CITY]: {
                            type: 'string'
                          },
                          [FIELD_NAME.STATE]: {
                            type: 'string'
                          },
                          [FIELD_NAME.ZIP]: {
                            type: 'string'
                          }
                        }
                      }
                    }
                  }
                }
              },
              required: [
                // FIELD_NAME.ID,
                // FIELD_NAME.NPI,
                // FIELD_NAME.SPI,
                // FIELD_NAME.LAST_NAME,
                // FIELD_NAME.FIRST_NAME,
                // FIELD_NAME.STATE_LICENSE_NUMBER
              ]
            },
          },
          required: [
            FIELD_NAME.PRESCRIPTION,
            FIELD_NAME.RX_ID,
            FIELD_NAME.PHYSICIAN,
            FIELD_NAME.PATIENT,
            FIELD_NAME.DRUG,
          ]
        },
        [FIELD_NAME.CONSULTATION_REQUEST]: {
          type: 'object',
          properties: {
            [FIELD_NAME.ID]: {
              type: 'string',
            },
            [FIELD_NAME.COMPUTED_ACTIVE]: {
              type: 'boolean',
            },
          },
        },
      },
      [FIELD_NAME.PRESCRIPTION_FILLS_FULFILLMENT]: {
        type: 'object',
        properties: {
          [FIELD_NAME.COMPUTED_CONSULTATION_REQUEST_PENDING]: {
            type: 'boolean',
          },
        },
      },
    },
    [FIELD_NAME.REVIEW]: {
      type: 'object',
      properties: {
        [FIELD_NAME.ID]: {
          type: 'string',
        },
        [FIELD_NAME.APPROVE_CONTAINER]: {
          type: 'boolean',
        },
        [FIELD_NAME.APPROVE_PRESCRIPTION]: {
          type: 'boolean',
        },
        [FIELD_NAME.NOTES]: {
          type: 'string',
        },
      },
    },
  },
  required: [
    FIELD_NAME.ID,
    FIELD_NAME.KIOSK,
    FIELD_NAME.PRESCRIPTION,
    FIELD_NAME.UPDATED_AT,
  ]
});
