import React from 'react';
import { observer } from 'mobx-react';

import CaptionValueInput from 'components/caption-value-input';
import Modal from 'components/modal';
import ModalEditingArea from 'components/modal/layout/editing-areas/components/editing-area';
import ModalRow from 'components/modal/layout/row';
import ModalButton from 'components/modal/layout/button';

const DrugUpdateModal = ({
  drugUpdateModalRouteStore,
  drugsRouteStore,
  upsertDrug,
  closeDrugUpdateModal
}) => {
  const activeDrug = drugsRouteStore.activeDrug || {};
  const activelyModifiedDrug = drugUpdateModalRouteStore.activelyModifiedDrug || {};

  return (
    <Modal
      modalStyles={{ height: '35%', width: '40%' }}
      isOpen={drugsRouteStore.showingDrugUpdateModal}
      shouldCloseOnOverlayClick={true}
      close={closeDrugUpdateModal}
      title={activeDrug.name}
    >
      <ModalEditingArea>
        <CaptionValueInput
          inputFieldClassNames={['max-width']}
          caption='UPC'
          value={activelyModifiedDrug.UPC}
          onChange={(value) => drugUpdateModalRouteStore.setValue('activelyModifiedDrug.UPC', value)}
        />
      </ModalEditingArea>
      <ModalRow shouldBoundToBottom={true}>
        <ModalButton
          color='blue'
          text='Save'
          onClick={upsertDrug}
        />
      </ModalRow>
    </Modal>
  );
};

export default observer(DrugUpdateModal);
