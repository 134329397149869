// Constants
import { PATIENT_LIST_TAB } from '../constants';

// Stores
import { patientsListRouteStore } from '../domain/store';

export default () => {
  const { activePatient } = patientsListRouteStore;
  if (!activePatient) return;

  const activeListTab = activePatient.externalPharmacyId ? PATIENT_LIST_TAB.ACTIVE : PATIENT_LIST_TAB.NEW;
  if (patientsListRouteStore.activePatientListTab !== activeListTab) {
    patientsListRouteStore.setValue('activePatientListTab', activeListTab);
  }
};
