import React from 'react';

export const PHARMACY_TECHNICIAN_SKELETON = {
  firstName: '',
  lastName: '',
  pharmacies: []
};

const DISPLAYED_PHARMACIES_MAX_COUNT = 3;
export const COLUMNS = [
  {
    name: 'Name',
    fieldName: 'name',
    isSortable: true,
    width: '30%',
    render: ({ content }) => (
      <div title={content.id}>{content.name}</div>
    )
  },
  {
    name: 'Phone Number',
    fieldName: 'phoneNumber',
    width: '15%',
    render: ({ content }) => (
      <div title={content.id}>{content.phoneNumber}</div>
    )
  },
  {
    name: 'Email',
    fieldName: 'email',
    width: '15%',
    render: ({ content }) => (
      <div title={content.id}>{content.email}</div>
    )
  },
  {
    name: 'Pharmacies',
    width: '40%',
    render: ({ content }) => {
      const { pharmacies = [] } = content;
      const displayedPharmacies = pharmacies.slice(0, DISPLAYED_PHARMACIES_MAX_COUNT);
      const remainingPharmaciesCount = pharmacies.length > DISPLAYED_PHARMACIES_MAX_COUNT
        ? pharmacies.slice(DISPLAYED_PHARMACIES_MAX_COUNT).length
        : 0;

      return <div title={content.id} className='TechnicianSmartTableWorkPlaces'>
        {
          pharmacies.length
            ? <React.Fragment>
              {
                displayedPharmacies.map((pharmacy, index) => (
                  <div className='work-place' key={index}>
                    {
                      pharmacy.name
                    }
                  </div>
                ))
              }
              {
                !!remainingPharmaciesCount &&
                <div className='remaining-work-places-count'>
                  {
                    `+${remainingPharmaciesCount} more`
                  }
                </div>
              }
            </React.Fragment>
            : 'None'
        }
      </div>;
    }
  }
];
