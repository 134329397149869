import {
  get as _get,
  difference as _difference,
} from 'lodash';

import {
  closeMedicalAssistantUpsertModal,
  listMedicalAssistants,
} from './index';

import stores from 'bootstrap/store';
import { medicalAssistantsRouteStore } from '../domain/store';
import { medicalAssistantsUpsertModalRouteStore } from '../domain/upsert-modal-store';
const medicalAssistantsStore = stores.global.medicalAssistants;
const usersStore = stores.global.users;


export default () => {
  const { activelyModifiedMedicalAssistant } = medicalAssistantsUpsertModalRouteStore;
  const user = _get(activelyModifiedMedicalAssistant, 'maUser.user', {});

  return Promise.all([
    medicalAssistantsStore.updateMedicalAssistant({
      params: { medicalAssistantId: medicalAssistantsRouteStore.activeMedicalAssistantId },
      body: activelyModifiedMedicalAssistant,
    }),
    user.id && usersStore.updateUser({
      params: { id: user.id },
      body: user,
    }),
  ])
  .then(() => {
    const medicalAssistantId = activelyModifiedMedicalAssistant.id;

    const { activeMedicalAssistant } = medicalAssistantsRouteStore;
    const initialKioskIds = activeMedicalAssistant.healthcareSites.map((healthcareSite) => healthcareSite.id);
    const modifiedKioskIds = activelyModifiedMedicalAssistant.healthcareSites.map((healthcareSite) => healthcareSite.id);
    const addedIds = _difference(modifiedKioskIds, initialKioskIds);
    const removedIds = _difference(initialKioskIds, modifiedKioskIds);

    const promises = [];
    addedIds.forEach((healthcareSiteId) => promises.push(medicalAssistantsStore.addHealthcareSite({
      params: {
        medicalAssistantId,
        healthcareSiteId,
      },
    })));
    removedIds.forEach((healthcareSiteId) => promises.push(medicalAssistantsStore.deleteHealthcareSite({
      params: {
        medicalAssistantId,
        healthcareSiteId,
      },
    })));

    return Promise.all(promises);
  })
  .then(() => {
    closeMedicalAssistantUpsertModal();
    return listMedicalAssistants();
  });
};
