import deepFreeze from 'deep-freeze';

export const MODEL_NAME = Object.freeze({
  SINGULAR: 'app_command',
  PLURAL: 'app-commands'
});

export const FIELD_NAME = Object.freeze({
  CREATED_AT: 'createdAt',
  DATA: 'data',
  APP_COMMAND_METADATA: 'appCommandMetadata',
  ID: 'id',
  INDEXED: 'indexed',
  TYPE: 'type',
  RESOLUTION: 'resolution',

  // depending on command type
  COMPANY: 'company',
  CONTAINER: 'container',
  DISPENSE_ATTEMPT: 'dispenseAttempt',
  DRUG: 'drug',
  LOCATION: 'location',
  PHARMACY: 'pharmacy',
  PRESCRIPTION: 'prescription',
  KIOSK: 'kiosk',
  FROM_KIOSK: 'fromKiosk',
  TO_KIOSK: 'toKiosk',
  USER: 'user',
  SUB_USER: 'subUser',
  PATIENT: 'patient',
});

export const SCHEMA = deepFreeze({
  title: MODEL_NAME.SINGULAR,
  type: 'object',
  properties: {
    [FIELD_NAME.CREATED_AT]: {
      type: 'string'
    },
    // [FIELD_NAME.DATA]: {
    //   type: 'object'
    // },
    [FIELD_NAME.RESOLUTION]: {
      type: 'object',
      properties: {
        [FIELD_NAME.ID]: {
          type: 'string'
        },
        [FIELD_NAME.TYPE]: {
          type: 'string'
        },
        [FIELD_NAME.RESOLUTION]: {
          type: 'string'
        },
        // [FIELD_NAME.INDEXED]: {
        //   type: 'object'
        // },
        // [FIELD_NAME.DATA]: {
        //   type: 'object'
        // },
        [FIELD_NAME.CREATED_AT]: {
          type: 'string'
        },
      },
      required: [
        FIELD_NAME.ID,
        FIELD_NAME.TYPE,
        FIELD_NAME.RESOLUTION,
        FIELD_NAME.INDEXED,
        FIELD_NAME.DATA,
        FIELD_NAME.CREATED_AT,
      ],
    },
    [FIELD_NAME.ID]: {
      type: 'string'
    },
    // [FIELD_NAME.INDEXED]: {
    //   type: 'object'
    // },
    [FIELD_NAME.TYPE]: {
      type: 'string'
    },

    // [FIELD_NAME.COMPANY]: {
    //   type: 'object'
    // },
    // [FIELD_NAME.CONTAINER]: {
    //   type: 'object'
    // },
    // [FIELD_NAME.DISPENSE_ATTEMPT]: {
    //   type: 'object'
    // },
    // [FIELD_NAME.DRUG]: {
    //   type: 'object'
    // },
    // [FIELD_NAME.LOCATION]: {
    //   type: 'object'
    // },
    // [FIELD_NAME.PHARMACY]: {
    //   type: 'object'
    // },
    // [FIELD_NAME.PRESCRIPTION]: {
    //   type: 'object'
    // },
    // [FIELD_NAME.KIOSK]: {
    //   type: 'object'
    // },
    // [FIELD_NAME.FROM_KIOSK]: {
    //   type: 'object'
    // },
    // [FIELD_NAME.TO_KIOSK]: {
    //   type: 'object'
    // },
    // [FIELD_NAME.USER]: {
    //   type: 'object'
    // },
    // [FIELD_NAME.SUB_USER]: {
    //   type: 'object'
    // },
    // [FIELD_NAME.PATIENT]: {
    //   type: 'object'
    // },
  },
  required: [
    FIELD_NAME.CREATED_AT,
    // FIELD_NAME.DATA,
    FIELD_NAME.RESOLUTION,
    FIELD_NAME.ID,
    // FIELD_NAME.INDEXED,
    FIELD_NAME.TYPE,

    // FIELD_NAME.COMPANY,
    // FIELD_NAME.CONTAINER,
    // FIELD_NAME.DISPENSE_ATTEMPT,
    // FIELD_NAME.DRUG,
    // FIELD_NAME.LOCATION,
    // FIELD_NAME.PHARMACY,
    // FIELD_NAME.PRESCRIPTION,
    // FIELD_NAME.KIOSK,
    // FIELD_NAME.FROM_KIOSK,
    // FIELD_NAME.TO_KIOSK,
    // FIELD_NAME.USER,
    // FIELD_NAME.SUB_USER,
    // FIELD_NAME.PATIENT,
  ]
});
