// Helpers
import {
  listForPharmacistCautiously as listDispenseAttemptsForPharmacistCautiously,
} from 'models/dispense-attempts/helpers';

// Constants
import { DISPENSE_ATTEMPTS_TAB } from 'models/dispense-attempts/constants';

// Stores
import stores from 'bootstrap/store';
import { notificationsPaneRouteStore } from '../../../../../domain/store';
const dispenseAttemptsRouteStore = stores.route.dispenseAttempts.domain;
const history = stores.router;


export default (dispenseAttemptId) => {
  const currentTab = dispenseAttemptsRouteStore.dispenseAttemptsTab;
  if (currentTab === DISPENSE_ATTEMPTS_TAB.REVIEWED) {
    dispenseAttemptsRouteStore.setValue('dispenseAttemptsTab', DISPENSE_ATTEMPTS_TAB.PENDING);
  }
  listDispenseAttemptsForPharmacistCautiously({ shouldFilter: false, shouldPaginate: false })
  .then(() => history.push(`/dispense-attempts/${dispenseAttemptId}`));

  notificationsPaneRouteStore.close();
};
