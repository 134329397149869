import stores from 'bootstrap/store';

import { EVENT } from '../../constants';

const pharmacistsSetStatus = ({ apiWebSocket }) => {
  apiWebSocket.on(EVENT.RECEIVED.PHARMACISTS_SET_STATUS, ({ status }) => {
    stores.global.pharmacists.setValue('status', status);
  });
};

export default pharmacistsSetStatus;
