import React from 'react';
import { observer } from 'mobx-react';

import PatientIcon from 'assets/icons/patient-active.svg';

import './patient-style.scss';


const Patient = ({ patient = {} }) => (
  <div className='c-PatientInfo'>
    <div className='header'>
      <img alt='patient' src={PatientIcon} />
      <div className='text'>
        Patient
      </div>
    </div>
    <div className='data'>
      <div className='row'>
        <div className='key-value'>
          <div className='label'>
            NAME
          </div>
          <div className='value'>
            {
              `${patient.firstName} ${patient.lastName}`
            }
          </div>
        </div>
        <div className='key-value'>
          <div className='label'>
            GENDER
          </div>
          <div className='value'>
            {
              patient.gender
            }
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='key-value'>
          <div className='label'>
            DATE OF BIRTH
          </div>
          <div className='value'>
            {
              patient.DOB
            }
          </div>
        </div>
        <div className='key-value'>
          <div className='label'>
            EXTERNAL PHARMACY ID
          </div>
          <div className='value'>
            {
              patient.externalPharmacyId
            }
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='allergies'>
          <div className='label'>
            ALLERGIES
          </div>
          <div className='info'>
            {
              patient.allergies && patient.allergies.length > 0
                ? patient.allergies.map(allergy => <div className='allergy' key={allergy.name}>{allergy.name}</div>)
                : 'None'
            }
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='key-value'>
          <div className='label'>
            PHONE NUMBER
          </div>
          <div className='value'>
            {
              patient.phoneNumber
            }
          </div>
        </div>
        <div className='key-value'>
          <div className='label'>
            EMAIL ADDRESS
          </div>
          <div className='value'>
            {
              patient.emailAddress
            }
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default observer(Patient);
