import React from 'react';
import { observer } from 'mobx-react';

import ContentScreen from 'components/content-screen';
import Modal from 'components/modal';
import ModalRow from 'components/modal/layout/row';
import ModalButton from 'components/modal/layout/button';
import SimplifiedInventory from './components/simplified-inventory';

const ExportInventoryModalContainer = ({
  isOpen,
  closeModal,
  isBusy,
  drugs,
  kioskName,
  generatePDF,
}) => {
  return (
    <Modal
      modalStyles={{ height: '645px', width: '740px' }}
      isOpen={isOpen}
      shouldCloseOnOverlayClick={true}
      close={closeModal}
      title='Export Simplified Inventory'
    >
      <ContentScreen active={isBusy} />
      <SimplifiedInventory drugs={drugs} kioskName={kioskName} />
      <ModalRow shouldBoundToBottom={true} shouldBeCenteredHorizontally={true}>
        <ModalButton
          color='blue'
          text='Export as PDF'
          isDisabled={isBusy}
          onClick={generatePDF}
        />
      </ModalRow>
    </Modal>
  );
};

export default observer(ExportInventoryModalContainer);
