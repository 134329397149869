import React from 'react';
import { observer } from 'mobx-react';

import List from 'components/drill-down-layout/components/list';
import ListItem from 'components/drill-down-layout/components/list/components/list-item';
import Header from './components/header';

import ContainerIcon from 'assets/icons/container.svg';
import ContainerActiveIcon from 'assets/icons/container-active.svg';
import KioskIcon from 'assets/icons/kiosk.svg';
import KioskActiveIcon from 'assets/icons/kiosk-active.svg';

import './stocking-batches-list-styles.scss';

const StockingBatchesList = ({
  stockingBatchesRouteStore,
  listStockingBatches,
  rootRoute,
}) => (
  <List
    header={<Header />}
    isReady={!listStockingBatches.isBusy}
    onBottomThreshold={listStockingBatches}
  >
    {
      stockingBatchesRouteStore.stockingBatchesList.map((stockingBatch) => {
        const isActive = stockingBatchesRouteStore.activeStockingBatchId === stockingBatch.id;

        return <ListItem
          key={stockingBatch.id}
          title={new Date(stockingBatch.createdAt).toLocaleString()}
          isActive={isActive}
          icon={
            <img
              alt='Stocking Batch'
              src={isActive ? ContainerActiveIcon : ContainerIcon}
            />
          }
          url={`${rootRoute}/${stockingBatch.id}`}
        >
          <div className='c-StockingBatchListItemContent'>
            <div className='content-info-container'>
              <div className='stocking-user'>
                <div className='name'>
                  {`Stocked by: ${stockingBatch.stockingUser.firstName} ${stockingBatch.stockingUser.lastName}`}
                </div>
              </div>
            </div>
            <div className='content-info-container'>
              <div className={`content-info ${!isActive && 'content-info-inactive'}`}>
                <img
                  className='icon'
                  alt='Kiosk'
                  src={isActive ? KioskActiveIcon : KioskIcon}
                />
                <div className='info'>
                  {
                    stockingBatch.kiosk.name
                  }
                </div>
              </div>
            </div>
          </div>
        </ListItem>;
      })
    }
  </List>
);

export default observer(StockingBatchesList);
