import { debounce as _debounce } from 'lodash';

// Helpers
import listMachineTechnicians from './listMachineTechnicians';

// Stores
import stores from 'bootstrap/store';
const machineTechniciansStore = stores.global.machineTechnicians;

export default _debounce(() => {
  machineTechniciansStore.setValue('machineTechnicians', {});
  return listMachineTechnicians();
}, 350);
