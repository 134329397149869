import React from 'react';
import { observer } from 'mobx-react';
import { camelCase as _camelCase, get as _get, upperFirst as _upperFirst } from 'lodash';

import CalendarIcon from 'assets/icons/calendar-blue.svg';
import KioskIcon from 'assets/icons/kiosk-active.svg';
import PersonnelIcon from 'assets/icons/personnel-active.svg';
import ScanningIcon from 'assets/icons/scan-active.svg';

import './info-styles.scss';

const InfoView = ({ activeStockingBatch }) => {
  if (!activeStockingBatch) {
    return null;
  }

  const readableStockingUserRoles = _get(activeStockingBatch, 'stockingUser.roles', []).map((role) => {
    const camelCaseRole = _camelCase(role);
    const separatedRoleParts = camelCaseRole.split(/(?=[A-Z])/);
    return _upperFirst(separatedRoleParts.join(' '));
  }).join(', ');

  return (
    <div className='c-StockingBatchInfo'>
      <div className='data'>
        <div className='data-row'>
          <div className='name'>
            <img
              alt='Kiosk'
              className='icon'
              src={KioskIcon}
            />
            <div className='text'>
              Kiosk
            </div>
          </div>
          <div className='value'>
            {
              activeStockingBatch.kiosk.name
            }
          </div>
        </div>
        <div className='data-row'>
          <div className='name'>
            <img
              alt='Personnel'
              className='icon'
              src={PersonnelIcon}
            />
            <div className='text'>
              Stocked By
            </div>
          </div>
          <div className='value'>
            {
              `${activeStockingBatch.stockingUser.firstName} ${activeStockingBatch.stockingUser.lastName} (${readableStockingUserRoles})`
            }
          </div>
        </div>
        <div className='data-row'>
          <div className='name'>
            <img
              alt='Scanning Type'
              className='icon'
              src={ScanningIcon}
            />
            <div className='text'>
              Scanning Type
            </div>
          </div>
          <div className='value'>
            {
              activeStockingBatch.isScanningDeferred ? 'Deferred' : 'Basic'
            }
          </div>
        </div>
        <div className='data-row'>
          <div className='name'>
            <img
              alt='Creation Date'
              className='icon'
              src={CalendarIcon}
            />
            <div className='text'>
              Creation Date
            </div>
          </div>
          <div className='value'>
            {
              new Date(activeStockingBatch.createdAt).toLocaleString()
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(InfoView);
