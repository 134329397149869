import React from 'react';
import { observer } from 'mobx-react';

import NotificationWrapper from '../notification-wrapper';
import MailOrder from './components/mail-order';

import './mail-ordered-prescriptions-notifications-view-style.scss';


const MailOrderedPrescriptionsNotificationsView = ({
  mailOrders,
  onClick,
}) => (
  <div className='c-MailOrderedPrescriptionsNotifications'>
    {
      !mailOrders.length &&
      <div className='no-data-caption'>
        No Mail Orders
      </div>
    }
    {
      mailOrders.map((prescriptionFill) => <NotificationWrapper
        key={prescriptionFill.id}
        onClick={() => onClick(prescriptionFill.id)}
      >
        <MailOrder
          prescriptionFill={prescriptionFill}
        />
      </NotificationWrapper>)
    }
  </div>
);

export default observer(MailOrderedPrescriptionsNotificationsView);
