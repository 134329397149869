import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';

// Views
import PatientsListView from './patients-list-view';

// Helpers
import {
  determineActiveListTab,
  determineActivePatient,
  listPatients,
  setFilterValue,
  setActivePatientListTab,
} from './helpers';

// View Store
import { patientsListRouteStore } from './domain/store';

// Global Stores
import stores from 'bootstrap/store';
const patientsStore = stores.global.patients;


const PatientsListContainer = ({ rootRoute }) => {
  const navigate = useNavigate();
  const { patientId } = useParams();

  useEffect(() => {
    listPatients();

    return () => {
      patientsListRouteStore.setInitialState();
      patientsStore.setValue('dataForPharmacist', {});
    };
  }, []);

  useEffect(() => {
    if (!patientsListRouteStore.http.listingPatients) {
      determineActivePatient(patientId, navigate);
      determineActiveListTab();
    }
  }, [patientId, patientsListRouteStore.http.listingPatients]);

  return <PatientsListView
    patientsStore={patientsStore}
    patientsListRouteStore={patientsListRouteStore}
    setFilterValue={setFilterValue}
    setActivePatientListTab={setActivePatientListTab}
    listPatients={listPatients}
    rootRoute={rootRoute}
  />;
};

export default observer(PatientsListContainer);
