import React, { useCallback } from 'react';
import { observer } from 'mobx-react';

import HeaderView from './header-view';

import stores from 'bootstrap/store';

const HeaderContainer = () => {
  const setPharmacistStatus = useCallback((status) => {
    return stores.global.pharmacists.setStatus({
      body: {
        status,
      },
    });
  }, []);
  
  const logOut = useCallback(() => {
    return stores.global.users.logOut();
  }, []);

  const openCameraConfiguration = useCallback(() => {
    stores.global.cameras.setValue('showingCameraConfigurationModal', true);
  }, []);

  return <HeaderView
    isActiveUserPharmacist={stores.global.users.isActiveUserPharmacist}
    isSettingStatus={!!stores.global.pharmacists.http.settingStatus}
    setPharmacistStatus={setPharmacistStatus}
    pharmacistStatus={stores.global.pharmacists.status}
    logOut={logOut}
    openCameraConfiguration={openCameraConfiguration}
  />;
};

export default observer(HeaderContainer);
