import React, { useState } from 'react';
import { observer } from 'mobx-react';

// Components
import Content from 'components/drill-down-layout/components/content';
import ContentTab from 'components/drill-down-layout/components/content/components/content-tab';
import Header from './components/header';

// Routes
import ActivityRoute from '../../routes/activity';
import ContainersRoute from '../../routes/containers';
// import DebugRoute from '../../routes/debug';
import InventoryRoute from '../../routes/inventory';
// import LayoutRoute from '../../routes/layout';
// import RoutingRoute from '../../routes/routing';
import StatusesRoute from '../../routes/statuses';
import StorageRoute from '../../routes/storage';
import VideoFeedRoute from '../../routes/video-feed';

// Constants
import { KIOSKS } from 'constants/routes';


const KiosksContent = ({
  activeKiosk,
  activeTab,
}) => {
  const [isExportInventoryModalOpen, setIsExportInventoryModalOpen] = useState(false);

  return <Content
    header={<Header
      activeKiosk={activeKiosk}
      activeTab={activeTab}
      openExportInventoryModal={() => setIsExportInventoryModalOpen(true)}
    />}
    isReady={!!activeKiosk}
  >
    <ContentTab title={KIOSKS.CONTENT_TAB.ACTIVITY.TITLE} route={KIOSKS.CONTENT_TAB.ACTIVITY.VALUE}>
      <ActivityRoute activeKiosk={activeKiosk} />
    </ContentTab>
    <ContentTab title={KIOSKS.CONTENT_TAB.STATUSES.TITLE} route={KIOSKS.CONTENT_TAB.STATUSES.VALUE}>
      <StatusesRoute activeKiosk={activeKiosk} />
    </ContentTab>
    {/* <ContentTab title={KIOSKS.CONTENT_TAB.DEBUG.TITLE} route={KIOSKS.CONTENT_TAB.DEBUG.VALUE}>
      <DebugRoute activeKiosk={activeKiosk} />
    </ContentTab> */}
    <ContentTab title={KIOSKS.CONTENT_TAB.INVENTORY.TITLE} route={KIOSKS.CONTENT_TAB.INVENTORY.VALUE}>
      <InventoryRoute
        activeKiosk={activeKiosk}
        isExportInventoryModalOpen={isExportInventoryModalOpen}
        closeExportInventoryModal={() => setIsExportInventoryModalOpen(false)}
      />
    </ContentTab>
    <ContentTab title={KIOSKS.CONTENT_TAB.STORAGE.TITLE} route={KIOSKS.CONTENT_TAB.STORAGE.VALUE}>
      <StorageRoute activeKiosk={activeKiosk} />
    </ContentTab>
    <ContentTab title={KIOSKS.CONTENT_TAB.CONTAINERS.TITLE} route={KIOSKS.CONTENT_TAB.CONTAINERS.VALUE}>
      <ContainersRoute activeKiosk={activeKiosk} />
    </ContentTab>
    {/* <ContentTab title={KIOSKS.CONTENT_TAB.LAYOUT.TITLE} route={KIOSKS.CONTENT_TAB.LAYOUT.VALUE}>
      <LayoutRoute activeKiosk={activeKiosk} />
    </ContentTab>
    <ContentTab title={KIOSKS.CONTENT_TAB.ROUTING.TITLE} route={KIOSKS.CONTENT_TAB.ROUTING.VALUE}>
      <RoutingRoute activeKiosk={activeKiosk} />
    </ContentTab> */}
    <ContentTab title={KIOSKS.CONTENT_TAB.VIDEO_FEED.TITLE} route={KIOSKS.CONTENT_TAB.VIDEO_FEED.VALUE}>
      <VideoFeedRoute activeKiosk={activeKiosk} />
    </ContentTab>
  </Content>;
};

export default observer(KiosksContent);
