import React from 'react';
import { observer } from 'mobx-react';

import './index.scss';

const TableContent = ({
  children
}) => (
  <div className='c-TableContent'>
    {
      children
    }
  </div>
);

export default observer(TableContent);