import React from 'react';
import { observer } from 'mobx-react';

// Helpers
import {APP_COMMAND_ICONS, BLOCK_CONFIG, buildAppCommand} from './helpers';

// Constants
import { FILTER_TYPES_LIST } from './constants';

// Components
import Statistic from 'components/activity-tab/statistic';
import Filter from 'components/activity-tab/filter';
import MachineTechnicianLogs from 'components/activity-tab/appCommands';

// Styles
import './activity-style.scss';

const ActivityView = ({
  machineTechniciansRouteStore,
  setMachineTechnicianLogFilter,
  eventLogs,
  statistic,
  fetchMachineTechnicianAppCommands,
  activeFilter,
}) => (
  <div className='c-MachineTechnicianActivityView'>
    <Statistic
      statistic={statistic}
      blockConfig={BLOCK_CONFIG}
    />
    <Filter
      setFilter={setMachineTechnicianLogFilter}
      activeFilter={activeFilter}
      filterList={FILTER_TYPES_LIST}
    />
    <MachineTechnicianLogs
      appCommands={eventLogs}
      isAppCommandsLoadActive={machineTechniciansRouteStore.http.listingMachineTechnicianAppCommands}
      buildAppCommand={buildAppCommand}
      appCommandIcons={APP_COMMAND_ICONS}
      fetchMoreAppCommands={fetchMachineTechnicianAppCommands}
    />
  </div>
);

export default observer(ActivityView);
