import React from 'react';
import { observer } from 'mobx-react';

import './item-card-style.scss';

const PrescriptionItemInfoCard = ({
  mainIcon,
  title,
  data
}) => (
  <div className='c-InfoCard'>
    <div className='item'>
      <div className='key'>
        <img className='title' src={mainIcon} />
      </div>
      <div className='value-container'>
        <div className='value title'>
          {
            title
          }
        </div>
      </div>
    </div>
    {
      data.map((item, i) => (
        <div key={i} className='item'>
          <div className='key'>{item.key}</div>
          <div className='value-container'>
            {
              Array.isArray(item.value)
                ? item.value.map((subItem, i) => (
                  <div key={i} className='value'>
                    {
                      subItem
                    }
                  </div>
                ))
                : <div className={`value value-${item.color}`}>
                  {
                    item.value
                  }
                </div>
            }
          </div>
        </div>
      ))
    }
  </div>
);


export default observer(PrescriptionItemInfoCard);
