import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';

// Views
import LotsView from './lots-view';

// Helpers
import {
  determineActiveLot,
  listLots,
  setFilterValue,
} from './helpers';

// View Store
import { lotsRouteStore } from './domain/store';

// Global Stores
import stores from 'bootstrap/store';
const lotsStore = stores.global.lots;


const LotsContainer = ({ rootRoute }) => {
  const navigate = useNavigate();
  const { lotId } = useParams();

  useEffect(() => {
    listLots();

    return () => {
      lotsRouteStore.setInitialState();
      lotsStore.setValue('lots', {});
    };
  }, []);
  
  useEffect(() => {
    if (!lotsRouteStore.http.listingLots) {
      determineActiveLot(lotId, navigate);
    }
  }, [lotId, lotsRouteStore.http.listingLots]);

  return <LotsView
    lotsStore={lotsStore}
    lotsRouteStore={lotsRouteStore}
    setFilterValue={setFilterValue}
    listLots={listLots}
    rootRoute={rootRoute}
  />;
};

export default observer(LotsContainer);
