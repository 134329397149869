import React from 'react';
import { observer } from 'mobx-react';

import KeyValuePair from '../keyValuePair';

import '../../prescriptionInfoBlockStyles.scss';

const PrescriptionInfoBlock = ({
  prescription,
  container,
  kiosk,
}) => (
  <div className='c-PrescriptionInfoBlock'>
    <div className='title'>
      Prescription Info
    </div>
    <div className='data'>
      <KeyValuePair
        title='Directions'
        data={prescription}
        keys={['directions']}
      />
      <KeyValuePair
        title='Refills'
        data={prescription}
        keys={['refills']}
      />
      <KeyValuePair
        title='RxId'
        data={prescription}
        keys={['rxId']}
      />
      <KeyValuePair
        title='Container expiration'
        data={container}
        keys={['expirationDate']}
        format={([expiration]) => {
          return new Date(expiration).toLocaleDateString('en-EN', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          });
        }}
      />
      <KeyValuePair
        title='Kiosk name'
        data={kiosk}
        keys={['name']}
      />
    </div>
  </div>
);

export default observer(PrescriptionInfoBlock);
