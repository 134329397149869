import { isString as _isString } from 'lodash';

import { prescriptionFillsManualEntryRouteStore } from '../domain/store';

import listPatientsAutocomplete from './listPatientsAutocomplete';

const onPatientAutocompleteInputChange = async (field, value) => {
  prescriptionFillsManualEntryRouteStore.setValue(`patient.${field}`, value);

  const currentValue = prescriptionFillsManualEntryRouteStore.patient[field];
  if (_isString(currentValue) && currentValue.length > 2) {
    return listPatientsAutocomplete(field);
  } else {
    return prescriptionFillsManualEntryRouteStore.setValue(`suggestions.patient.${field}`, []);
  }
};

export default onPatientAutocompleteInputChange;
