import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import stores from 'bootstrap/store';

// View
import InfoView from './info-view';

const InfoContainer = ({ activePatient, patientAddress, patientImages, isEditMode, exitEditMode }) => {
  const [editableActivePatient, setEditableActivePatient] = useState(null);

  useEffect(() => {
    if (isEditMode && activePatient) {
      setEditableActivePatient(activePatient);
    } else {
      setEditableActivePatient(null);
    }
  }, [isEditMode]);

  const exitEditModePrivate = useCallback(() => {
    exitEditMode();
    setEditableActivePatient(null);
  }, [exitEditMode, setEditableActivePatient]);

  const updatePatient = useCallback(async () => {
    if (!editableActivePatient) {
      return;
    }

    let DOB = editableActivePatient.DOB;
    if (DOB && DOB.includes('/')) {
      const [month, day, year] = DOB.split('/');
      DOB = `${year}-${month}-${day}`;
    }

    let gender = editableActivePatient.gender;
    if (gender) {
      gender = gender.toUpperCase();
    }

    let phoneNumber = editableActivePatient.phoneNumber;
    if (phoneNumber) {
      phoneNumber = phoneNumber.replace(/[^0-9]/g, '');
    }

    await stores.global.patients.updatePatient({
      params: { id: editableActivePatient.id },
      body: { ...editableActivePatient, DOB, gender, phoneNumber },
    });

    return exitEditModePrivate();
  }, [editableActivePatient, exitEditModePrivate]);

  return <InfoView
    activePatient={isEditMode ? editableActivePatient : activePatient}
    patientAddress={patientAddress}
    patientImages={patientImages}
    isEditMode={isEditMode}
    setEditablePatient={(values) => setEditableActivePatient({ ...editableActivePatient, ...values })}
    updatePatient={updatePatient}
  />;
};

export default observer(InfoContainer);
