import { get as _get } from 'lodash';

// Constants
import { RE_UUID } from 'constants/regex';
import { PATIENTS_LIST_OVERVIEW_ROUTE } from '../constants';

// Stores
import stores from 'bootstrap/store';
import { patientsListRouteStore } from '../domain/store';
const patientsStore = stores.global.patients;

export default (patientId, navigate) => {
  const requestedPatientIsAvailable = (
    !!patientId &&
    patientId.match(RE_UUID) &&
    !!patientsStore.dataForPharmacist.get(patientId)
  );

  if (requestedPatientIsAvailable) {
    patientsListRouteStore.setValue('activePatientId', patientId);
  } else {
    // TODO fetch info of the requested entry and show it as active once user scrolls to it
    const firstId = _get(patientsStore, 'dataForPharmacistList[0].id');
    if (firstId) {
      navigate(`${PATIENTS_LIST_OVERVIEW_ROUTE}/${firstId}`);
    } else {
      patientsListRouteStore.setValue('activePatientId', null);
    }
  }
};
