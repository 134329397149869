import { onPatch } from 'mobx-state-tree';

import stores from 'bootstrap/store';

import { STATUS } from 'models/pharmacists/constants';
import { PING_INTERVAL } from 'models/user-client-pings/constants';

let pharmacistPingInterval;

export const bootstrap = () => {
  const {
    pharmacists,
    users
  } = stores.global;

  // Set pharmacist as available once active user's roles are defined.
  onPatch(users, (patch) => {
    // patch.path looks like "/activeUserRoles/0", since it's an array, so we should do .startsWith().
    if (patch.path.startsWith('/activeUserRoles') && users.isActiveUserPharmacist) {
      return pharmacists.setStatus({
        body: {
          status: STATUS.AVAILABLE,
        },
      });
    }
  });

  // Start pinging backend if active user is a pharmacist.
  onPatch(users, async (patch) => {
    // patch.path looks like "/activeUserRoles/0", since it's an array, so we should do .startsWith().
    if (!patch.path.startsWith('/activeUserRoles')) return;

    // Clear any existing ping interval. Doesn't matter if undefined.
    clearInterval(pharmacistPingInterval);

    // If active user is pharmacist, begin pinging client activity
    if (users.isActiveUserPharmacist) {
      await stores.global.userClientPings.createUserClientPing();
      pharmacistPingInterval = setInterval(() => {
        return stores.global.userClientPings.createUserClientPing();
      }, PING_INTERVAL);
    }
  });
};
