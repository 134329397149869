import { drugsRouteStore } from '../domain/store';

import listDrugs from './listDrugs';

export default async () => {
  drugsRouteStore.setValue('activeDrugId', null);
  
  await listDrugs();

  drugsRouteStore.setValue('showingDrugUpsertModal', false);
};
