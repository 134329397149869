import { kioskInventoryRouteStore } from '../domain/store';

import { upsertDrugThresholdDebounce } from './index';

import { TYPES } from 'models/kiosk-drug-thresholds/constants';

export default ({ drugId, type, threshold }) => {
  if (!TYPES.includes(type)) return;

  kioskInventoryRouteStore.setInventoryThreshold({ drugId, type, threshold });
  return upsertDrugThresholdDebounce(drugId, { [type]: threshold });
};
