import React from 'react';

import { PROP_TYPES } from '../../../../constants';

const ContentTypeNumber = ({column, content}) => (
  <div className="ContentTypeNumber">{content[column.name]}</div>
);  

export default ContentTypeNumber;

ContentTypeNumber.propTypes = {
  column: PROP_TYPES.COLUMN,
  content: PROP_TYPES.ROW
};
