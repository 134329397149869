import React from 'react';
import { observer } from 'mobx-react';
import { get as _get } from 'lodash';

// Components
import DispenseAttempt from './components/dispense-attempt';
import ListItem from 'components/drill-down-layout/components/list/components/list-item';

// Helpers
import { getReviewIcon } from './helpers';

// Constants
import { DISPENSE_ATTEMPTS_TAB } from 'models/dispense-attempts/constants';

// Icons
import PatientIcon from 'assets/icons/patient.svg';
import ActivePatientIcon from 'assets/icons/patient-active.svg';


const DispenseAttemptsListItem = ({
  dispenseAttemptsRouteStore,
  dispenseAttempt,
  rootRoute,
}) => {
  const itemKey = new Date(dispenseAttempt.updatedAt).toLocaleTimeString([], {
    hour: 'numeric',
    minute: 'numeric',
  });
  const patient = _get(dispenseAttempt, 'prescriptionFill.prescription.patient', {});
  const isDispenseAttemptActive = dispenseAttemptsRouteStore.activeDispenseAttemptId === dispenseAttempt.id;

  return <ListItem
    key={dispenseAttempt.id}
    itemKey={itemKey}
    title={`${patient.firstName} ${patient.lastName}`}
    isActive={isDispenseAttemptActive}
    icon={
      <img
        alt='Patient'
        src={isDispenseAttemptActive ? ActivePatientIcon : PatientIcon}
      />
    }
    subIcon={
      dispenseAttemptsRouteStore.dispenseAttemptsTab === DISPENSE_ATTEMPTS_TAB.REVIEWED &&
      <img
        alt='Status'
        src={getReviewIcon(dispenseAttempt)}
      />
    }
    url={`${rootRoute}/${dispenseAttempt?.id}`}
  >
    <DispenseAttempt
      data={dispenseAttempt}
      isSelected={
        dispenseAttemptsRouteStore.activeDispenseAttemptId &&
        dispenseAttemptsRouteStore.activeDispenseAttemptId === dispenseAttempt.id
      }
    />
  </ListItem>;
};

export default observer(DispenseAttemptsListItem);
