import React from 'react';
import { observer } from 'mobx-react';

// Components
import DrillDownLayout from 'components/drill-down-layout';
import List from './components/list';
import Content from './components/content';

// Styles
import './lots-view-styles.scss';

const LotsView = ({
  lotsStore,
  lotsRouteStore,
  setFilterValue,
  listLots,
  rootRoute,
}) => (
  <div className='r-LotsView'>
    <DrillDownLayout>
      <List
        lotsRouteStore={lotsRouteStore}
        lotsStore={lotsStore}
        setFilterValue={setFilterValue}
        listLots={listLots}
        rootRoute={rootRoute}
      />
      <Content
        activeLotId={lotsRouteStore.activeLotId}
        lotsRouteStore={lotsRouteStore}
      />
    </DrillDownLayout>
  </div>
);


export default observer(LotsView);
