import ReactSelect from 'react-select';
import './style.scss';

export default {
  component: ReactSelect,
  specialProps: (props = {}) => {
    const required = !!props.required && (props.value.value === undefined || props.value.value === null || props.value.value === '');

    return ({
      className: `${required ? 'c-ReactSelectRequired' : 'c-ReactSelect'} ${props.className || ''}`,
      onChange: (val) => {
        if (!val) return props.onChange({value: '', label: ''});

        props.onChange(val);
      },
      style: {
        borderColor: required? 'hsl(4, 55%, 58%)' : 'hsl(0,0%,80%)',
        boxShadow: 'none',
        borderWidth: '2px',
        borderRadius: '5px',
      },

      isClearable: true,
      onBlurResetsInput: false,
      onCloseResetsInput: false,
      onSelectResetsInput: false,
      scrollMenuIntoView: false,
    });
  },
};
