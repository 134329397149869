import { types } from 'mobx-state-tree';

import { keyBy as _keyBy, orderBy as _orderBy } from 'lodash';

export const ExportInventoryModalRouteStore = types.model({
  drugsById: types.optional(types.map(types.frozen()), {}),
  computedInventory: types.frozen(),
  http: types.optional(types.model({
    fetchingDrugs: types.optional(types.number, 0),
    fetchingComputedInventory: types.optional(types.number, 0),
  }), {}),
})
.actions(self => ({
  setValue: (prop, value) => self[prop] = value,
  incrementHttp: (http) => ++self.http[http],
  decrementHttp: (http) => --self.http[http],
  reset: () => {
    self.drugsById = {};
    self.computedInventory = null;
    self.http = {};
  },
}))
.views(self => ({
  get isBusy() {
    return Boolean(self.http.fetchingComputedInventory || self.http.fetchingDrugs);
  },
  get forTable() {
    if (!self.computedInventory || !self.computedInventory.kiosk || !self.drugsById) {
      return [];
    }

    const activeKiosk = self.computedInventory.kiosk;

    const drugIds = Object.keys(activeKiosk.computed_inventory.byQuantity.byDrugId);
    const drugsById = _keyBy(self.computedInventory.drugs, 'id');

    const forTable = drugIds.map((drugId) => {
      const drug = drugsById[drugId];
      const detailed = self.drugsById.get(drug.id) || {};

      const drugForTable = {
        id: drug.id,
        name: drug.name,
        NDC: detailed.NDC || 'N/A',
        quantityOptions: [],
      };

      const drugQuantityVariants = activeKiosk.computed_inventory.byQuantity.byDrugId[drugId];
      Object.keys(drugQuantityVariants).forEach((quantity) => {
        const drugQuantityVariant = drugQuantityVariants[quantity];
        const { count } = drugQuantityVariant;
        drugForTable.quantityOptions.push({
          quantity,
          totalCount: count.assignable,
        });
      });

      return drugForTable;
    });

    return _orderBy(forTable, ['name'], ['asc']);
  },
}));



export const exportInventoryModalRouteStore = ExportInventoryModalRouteStore.create({});
