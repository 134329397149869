// Constants
import { KIOSKS as KIOSKS_ROUTE } from 'constants/routes';

// Stores
import stores from 'bootstrap/store';
import { notificationsPaneRouteStore } from '../../../../../domain/store';
const history = stores.router;

const goToKioskStatusesTab = (kioskId) => {
  history.push(`${KIOSKS_ROUTE.VALUE}/${kioskId}${KIOSKS_ROUTE.CONTENT_TAB.STATUSES.VALUE}`);

  notificationsPaneRouteStore.close();
};

export default goToKioskStatusesTab;
