import { flow } from 'mobx-state-tree';

import base from 'models/base';
import { MODEL_NAME, SCHEMA } from './constants/schema';

const StockingBatchModel = base({
  names: {
    singular: MODEL_NAME.SINGULAR,
    plural: MODEL_NAME.PLURAL
  },
  JSONSchema: SCHEMA,
  httpConfig: {
    root: MODEL_NAME.plural,
    methods: {}
  }
});

StockingBatchModel.configureStore((store) => {
  return store
  .actions(self => ({
    listStockingBatches: flow(function* (config = {}) {
      config.urlFragment = () => '/';
      const response = yield self.list(config);

      return response;
    }),
    getStockingBatchById: flow(function* (stockingBatchId) {
      const response = yield self.get({
        urlFragment: () => `/${stockingBatchId}`,
      });

      return response;
    }),
  }));
});

// Can't use module.exports because there is a generator function in this file,
// and that causes module.exports to fail for some reason.
export default StockingBatchModel;
