import stores from 'bootstrap/store';

import { EVENT } from '../../constants';


export default ({ apiWebSocket }) => {
  const videoConferencesStore = stores.global.videoConferences;

  apiWebSocket.on(EVENT.RECEIVED.VIDEO_CONFERENCE_INVITATION_ACCEPT, () => {
    videoConferencesStore.listActive();
  });
};
