import stores from 'bootstrap/store';

import { EVENT, IDENTIFICATION_INTERVAL } from '../../constants';

let identifyInterval;

export default ({ apiWebSocket, apiWebSocketEmit }) => {
  const authStore = stores.global.auth;

  const identifyClient = () => apiWebSocketEmit(EVENT.EMITTED.IDENTIFY, { token: authStore.activeToken });

  apiWebSocket.on(EVENT.RECEIVED.CONNECT, () => {
    identifyClient();
    identifyInterval = setInterval(identifyClient, IDENTIFICATION_INTERVAL);
  });

  apiWebSocket.on(EVENT.RECEIVED.DISCONNECT, () => {
    clearInterval(identifyInterval);
  });
};
