// Constants
import {
  MAX_TABLE_ENTRIES_PER_PAGE,
  ORDER_OPTIONS,
} from 'components/admin-technicians-view/constants';

// Stores
import stores from 'bootstrap/store';
import { pharmacyTechniciansRouteStore } from '../domain/store';
const pharmacyTechniciansStore = stores.global.pharmacyTechnicians;


export default () => {
  const query = {
    offset: MAX_TABLE_ENTRIES_PER_PAGE * (pharmacyTechniciansRouteStore.currentPage - 1),
    limit: MAX_TABLE_ENTRIES_PER_PAGE,
  };

  if (pharmacyTechniciansRouteStore.filterValue) {
    query.filter = {
      firstName: pharmacyTechniciansRouteStore.filterValue,
      lastName: pharmacyTechniciansRouteStore.filterValue,
    };
  }

  if (pharmacyTechniciansRouteStore.sortingColumn.fieldName && pharmacyTechniciansRouteStore.sortingColumn.sortingOrder) {
    query.order = ORDER_OPTIONS[pharmacyTechniciansRouteStore.sortingColumn.fieldName](pharmacyTechniciansRouteStore.sortingColumn.sortingOrder);
  }

  const listTechnicians = () => {
    pharmacyTechniciansRouteStore.incrementHttp('listingTechnicians');
    pharmacyTechniciansStore.listPharmacyTechniciansForAdmin({ query })
    .then(() => pharmacyTechniciansRouteStore.decrementHttp('listingTechnicians'));
  };

  const countTechnicians = () => {
    pharmacyTechniciansRouteStore.incrementHttp('countingTechnicians');
    pharmacyTechniciansStore.countPharmacyTechnicians({ query })
    .then(() => pharmacyTechniciansRouteStore.decrementHttp('countingTechnicians'));
  };

  return Promise.all([
    listTechnicians(),
    countTechnicians(),
  ]);
};
