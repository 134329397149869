// Constants
import {
  MAX_TABLE_ENTRIES_PER_PAGE,
  ORDER_OPTIONS,
} from '../constants';

// Stores
import stores from 'bootstrap/store';
import { medicalAssistantsRouteStore } from '../domain/store';
const medicalAssistantsStore = stores.global.medicalAssistants;


export default () => {
  const query = {
    offset: MAX_TABLE_ENTRIES_PER_PAGE * (medicalAssistantsRouteStore.currentPage - 1),
    limit: MAX_TABLE_ENTRIES_PER_PAGE,
  };

  if (medicalAssistantsRouteStore.filterValue) {
    query.filter = {
      firstName: medicalAssistantsRouteStore.filterValue,
      lastName: medicalAssistantsRouteStore.filterValue,
    };
  }

  if (medicalAssistantsRouteStore.sortingColumn.fieldName && medicalAssistantsRouteStore.sortingColumn.sortingOrder) {
    query.order = ORDER_OPTIONS[medicalAssistantsRouteStore.sortingColumn.fieldName](medicalAssistantsRouteStore.sortingColumn.sortingOrder);
  }

  const listMedicalAssistants = () => {
    medicalAssistantsRouteStore.incrementHttp('listingMedicalAssistants');
    medicalAssistantsStore.listMedicalAssistants({ query, mergeResult: false })
    .then(() => medicalAssistantsRouteStore.decrementHttp('listingMedicalAssistants'));
  };

  const countMedicalAssistants = () => {
    medicalAssistantsRouteStore.incrementHttp('countingMedicalAssistants');
    medicalAssistantsStore.countMedicalAssistants({ query })
    .then(() => medicalAssistantsRouteStore.decrementHttp('countingMedicalAssistants'));
  };

  return Promise.all([
    listMedicalAssistants(),
    countMedicalAssistants(),
  ]);
};
