import push from 'push.js';
import stores from 'bootstrap/store';
import shortLogo from 'assets/icons/short-logo.png';

export default class NotificationService {
  async create(text = '', {toUri, historyState = {}, onClickCallbackMetadata, onClickCallback} = {}) {
    const router = stores.router;

    return push.create(text, {
      icon: shortLogo,
      onClick: async function() {
        this.close();
        if (toUri) router.history.push(toUri, historyState);
        if (onClickCallback) await onClickCallback(onClickCallbackMetadata);
      },
    });
  }
}
