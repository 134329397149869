import React from 'react';

export const COLUMNS = [
  {
    name: 'Name',
    fieldName: 'name',
    isSortable: true,
    width: '35%',
    render: ({ content }) => (
      <div title={content.name}>{content.name}</div>
    )
  },
  {
    name: 'NDC',
    fieldName: 'NDC',
    isSortable: true,
    width: '20%',
    render: ({ content }) => (
      <div title={content.NDC}>{content.NDC}</div>
    )
  },
  {
    name: 'UPC',
    fieldName: 'UPC',
    isSortable: true,
    width: '20%',
    render: ({ content }) => (
      <div title={content.UPC}>{content.UPC}</div>
    )
  },
  {
    name: 'Strength',
    fieldName: 'strength',
    isSortable: false,
    width: '15%',
    render: ({ content }) => (
      <div title={content.strength}>{content.strength}</div>
    )
  },
  {
    name: 'Quantity',
    fieldName: 'quantity',
    isSortable: false,
    width: '10%',
    render: ({ content }) => (
      <div title={content.quantity}>{content.quantity}</div>
    )
  },
];
