import stores from 'bootstrap/store';
import { kioskContainersRouteStore } from '../domain/store';

const fetchKioskInventory = async (activeKiosk) => {
  if (!activeKiosk) {
    return;
  }

  kioskContainersRouteStore.incrementHttp('fetchingInventory');

  const response = await stores.global.kiosks.getComputedInventory({
    query: {
      kioskId: activeKiosk.id,
    },
  });

  kioskContainersRouteStore.setValue('inventory', response);

  kioskContainersRouteStore.decrementHttp('fetchingInventory');

  return response;
};

export default fetchKioskInventory;
