import React from 'react';
import { observer } from 'mobx-react';
import { get as _get } from 'lodash';

// Components
import HealthcareSites from 'components/entity-healthcare-sites';

// Icons
import ContactIcon from 'assets/icons/contact.svg';
import HospitalIcon from 'assets/icons/hospital-active.svg';
import MailIcon from 'assets/icons/mail.svg';

// Styles
import './contact-style.scss';

const ContactView = ({
  activeMedicalAssistant = {},
}) => {
  const healthcareSites = activeMedicalAssistant.healthcareSites || [];
  const user = _get(activeMedicalAssistant, 'maUser.user', {});

  return <div className='c-MedicalAssistantContact'>
    <div className='data'>
      <div className='data-row'>
        <div className='name'>
          <img
            alt='Phone Number'
            className='icon'
            src={ContactIcon}
          />
          <div className='text'>
            Phone
          </div>
        </div>
        <div className='value'>
          {
            user.phoneNumber || 'N/A'
          }
        </div>
      </div>
      <div className='data-row'>
        <div className='name'>
          <img
            alt='Email'
            className='icon'
            src={MailIcon}
          />
          <div className='text'>
            Email
          </div>
        </div>
        <div className='value'>
          {
            user.email
          }
        </div>
      </div>
      {
        !!healthcareSites.length &&
        <div className='data-row'>
          <div className='name'>
            <img alt='HealthcareSite' src={HospitalIcon} className='icon'/>
            <div className='text'>Healthcare Sites</div>
          </div>
          <HealthcareSites
            healthcareSites={healthcareSites}
          />
        </div>
      }
    </div>
  </div>;
};

export default observer(ContactView);
