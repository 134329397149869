import { listMedicalAssistantsDebounce } from './index';

import stores from 'bootstrap/store';
import { medicalAssistantsRouteStore } from '../domain/store';
const medicalAssistantsStore = stores.global.medicalAssistants;

export default (e) => {
  medicalAssistantsRouteStore.setValue('filterValue', e.target.value);
  medicalAssistantsRouteStore.setValue('currentPage', 1);
  medicalAssistantsStore.setValue('medicalAssistants', {});

  listMedicalAssistantsDebounce();
};
