import { debounce as _debounce } from 'lodash';

import { listCallRequests } from './index';

import stores from 'bootstrap/store';
const callRequestsStore = stores.global.callRequests;

const listCallRequestsDebounce = _debounce(() => {
  callRequestsStore.setValue('dataForPharmacist', {});
  callRequestsStore.setValue('activeCallRequest', {});
  return listCallRequests();
}, 350);

export default listCallRequestsDebounce;
