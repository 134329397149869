import React from 'react';
import { observer } from 'mobx-react';

import searchIcon from 'assets/icons/search.svg';

import './index.scss';

const ListSearch = ({
  // Config
  placeholder = '',
  // Value
  searchValue,
  // Actions
  onSearch
}) => {
  return <div className='c-ListSearch'>
    <img className='search-icon' src={searchIcon} />
    <input
      placeholder={placeholder}
      value={searchValue}
      onChange={onSearch}
    />
  </div>;
};

export default observer(ListSearch);
