import deepFreeze from 'deep-freeze';

export const MODEL_NAME = Object.freeze({
  SINGULAR: 'patientInsurance',
  PLURAL: 'patient-insurances',
});

export const FIELD_NAME = Object.freeze({
  ID: 'id',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  INSURANCE_FRONT_SIDE_IMAGE_KEY: 'insuranceFrontSideImageKey',
  INSURANCE_FRONT_SIDE_IMAGE_URL: 'insuranceFrontSideImageUrl',
  INSURANCE_BACK_SIDE_IMAGE_KEY: 'insuranceBackSideImageKey',
  INSURANCE_BACK_SIDE_IMAGE_URL: 'insuranceBackSideImageUrl',
  SIGNATURE_IMAGE_KEY: 'signatureImageKey',
  SIGNATURE_IMAGE_URL: 'signatureImageUrl',
  PATIENT_PICTURE_IMAGE_KEY: 'patientPictureImageKey',
  PATIENT_PICTURE_IMAGE_URL: 'patientPictureImageUrl',
  FRONT_DRIVER_LICENSE_IMAGE_KEY: 'frontDriverLicenseImageKey',
  FRONT_DRIVER_LICENSE_IMAGE_URL: 'frontDriverLicenseImageUrl',
  BACK_DRIVER_LICENSE_IMAGE_KEY: 'backDriverLicenseImageKey',
  BACK_DRIVER_LICENSE_IMAGE_URL: 'backDriverLicenseImageUrl',
  ADDRESS: 'address',
  EMAIL: 'email',
  PHONE_NUMBER: 'phoneNumber',
  CREATED_AT: 'createdAt',

  HEALTHCARE_SITE: 'healthcareSite',
  NAME: 'name',

  LOCATION: 'location',
  STATE: 'state',
  CITY: 'city',
  STREET: 'street1',
  ZIP: 'zip',
});

export const SCHEMA = deepFreeze({
  title: MODEL_NAME.SINGULAR,
  type: 'object',
  properties: {
    [FIELD_NAME.ID]: {
      type: 'string',
    },
    [FIELD_NAME.FIRST_NAME]: {
      type: 'string',
    },
    [FIELD_NAME.LAST_NAME]: {
      type: 'string',
    },
    [FIELD_NAME.INSURANCE_FRONT_SIDE_IMAGE_KEY]: {
      type: 'string',
    },
    [FIELD_NAME.INSURANCE_FRONT_SIDE_IMAGE_URL]: {
      type: 'string',
    },
    [FIELD_NAME.INSURANCE_BACK_SIDE_IMAGE_KEY]: {
      type: 'string',
    },
    [FIELD_NAME.INSURANCE_BACK_SIDE_IMAGE_URL]: {
      type: 'string',
    },
    [FIELD_NAME.SIGNATURE_IMAGE_KEY]: {
      type: 'string',
    },
    [FIELD_NAME.SIGNATURE_IMAGE_URL]: {
      type: 'string',
    },

    [FIELD_NAME.PATIENT_PICTURE_IMAGE_KEY]: {
      type: 'string',
    },
    [FIELD_NAME.PATIENT_PICTURE_IMAGE_URL]: {
      type: 'string',
    },
    [FIELD_NAME.FRONT_DRIVER_LICENSE_IMAGE_KEY]: {
      type: 'string',
    },
    [FIELD_NAME.FRONT_DRIVER_LICENSE_IMAGE_URL]: {
      type: 'string',
    },
    [FIELD_NAME.BACK_DRIVER_LICENSE_IMAGE_KEY]: {
      type: 'string',
    },
    [FIELD_NAME.BACK_DRIVER_LICENSE_IMAGE_URL]: {
      type: 'string',
    },
    [FIELD_NAME.ADDRESS]: {
      type: 'string',
    },
    [FIELD_NAME.EMAIL]: {
      type: 'string',
    },
    [FIELD_NAME.PHONE_NUMBER]: {
      type: 'string',
    },
    [FIELD_NAME.CREATED_AT]: {
      type: 'string',
    },
    [FIELD_NAME.HEALTHCARE_SITE]: {
      type: 'object',
      properties: {
        [FIELD_NAME.ID]: {
          type: 'string',
        },
        [FIELD_NAME.NAME]: {
          type: 'string',
        },
        [FIELD_NAME.LOCATION]: {
          type: 'object',
          properties: {
            [FIELD_NAME.STREET_1]: {
              type: 'string',
            },
            [FIELD_NAME.CITY]: {
              type: 'string',
            },
            [FIELD_NAME.STATE]: {
              type: 'string',
            },
            [FIELD_NAME.ZIP]: {
              type: 'string',
            },
          },
        },
      },
    },
  },
});
