import deepFreeze from 'deep-freeze';

export const MODEL_NAME = Object.freeze({
  SINGULAR: 'userClientPing',
  PLURAL: 'user-client-pings',
});

export const FIELD_NAME = Object.freeze({
  ID: 'id',
  CLIENT_TYPE: 'clientType',
  CREATED_AT: 'createdAt',
});

export const SCHEMA = deepFreeze({
  title: MODEL_NAME.SINGULAR,
  type: 'object',
  properties: {
    [FIELD_NAME.ID]: {
      type: 'string'
    },
    [FIELD_NAME.CLIENT_TYPE]: {
      type: 'string'
    },
    [FIELD_NAME.CREATED_AT]: {
      type: 'string'
    },
  },
  required: [
    FIELD_NAME.ID,
    FIELD_NAME.CLIENT_TYPE,
  ]
});
