import {
  listForPharmacistCautiouslyDebounce as listDispenseAttemptsForPharmacistCautiouslyDebounce,
} from 'models/dispense-attempts/helpers';

import { DISPENSE_ATTEMPTS_TAB} from 'models/dispense-attempts/constants';

import stores from 'bootstrap/store';
import { dispenseAttemptsRouteStore } from '../domain/store';
const dispenseAttemptsStore = stores.global.dispenseAttempts;

export default (e) => {
  dispenseAttemptsRouteStore.setValue('filterValue', e.target.value);
  dispenseAttemptsStore.setValue('dataForPharmacist', {});

  const asc = dispenseAttemptsRouteStore.dispenseAttemptsTab === DISPENSE_ATTEMPTS_TAB.PENDING;

  return listDispenseAttemptsForPharmacistCautiouslyDebounce({ asc });
};
