import stores from 'bootstrap/store';
import { medicalAssistantsUpsertModalRouteStore } from '../domain/upsert-modal-store';

const healthcareSitesStore = stores.global.healthcareSites;

export default (e) => {
  medicalAssistantsUpsertModalRouteStore.setValue('workPlaceFilter', e.target.value);

  return healthcareSitesStore.listForAdmin({
    query: {
      filter: {
        name: medicalAssistantsUpsertModalRouteStore.workPlaceFilter,
      },
    },
  });
};
