import * as ROUTE from 'constants/routes';

export const PATIENTS_LIST_OVERVIEW_ROUTE = `${ROUTE.PATIENTS.VALUE}${ROUTE.PATIENTS.SUB_ROUTE.LIST_OVERVIEW.VALUE}`;

export const PATIENT_LIST_TAB = {
  ACTIVE: 'Active',
  // NEW: 'New',
};

export const PATIENT_LIST_TABS = Object.values(PATIENT_LIST_TAB);

export const MAX_ENTRIES_PER_PAGE = 10;
