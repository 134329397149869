import React from 'react';
import { observer } from 'mobx-react';

import InfoCards from './components/info-cards';

import './prescription-fill-summary-styles.scss';

const PrescriptionFillSummary = ({ activePrescriptionFill, getLocation, shouldDisplayPayment = true }) => {
  return <div className='c-PrescriptionFillSummary'>
    <InfoCards
      activePrescriptionFill={activePrescriptionFill}
      getLocation={getLocation}
      shouldDisplayPayment={shouldDisplayPayment}
    />
  </div>;
};

export default observer(PrescriptionFillSummary);
