import { toJS } from 'mobx';
import { diff } from 'deep-object-diff';

import { fetchLayoutInfo } from './index';

import stores from 'bootstrap/store';
import { kiosksRouteStore } from '../domain/store';
const kiosksStore = stores.global.kiosks;

export default (kiosk) => {
  const initialKiosk = toJS(kiosksRouteStore.activeKiosk);
  const modifiedKiosk = toJS(kiosksRouteStore.modifiedActiveKiosk);

  if (Object.keys(diff(initialKiosk, modifiedKiosk)).length === 0) {
    alert('No changes detected.');
    return;
  }

  for (let i = 0; i < modifiedKiosk.kioskStorageAreas.length; i++) {
    for (let j = 0; j < modifiedKiosk.kioskStorageAreas[i].kioskModules.length; j++) {
      if (modifiedKiosk.kioskStorageAreas[i].kioskModules[j].name === '') {
        alert('KIOSK module name cannot be empty.');
        return;
      }
    }
  }

  return kiosksStore.submitLayoutChanges({
    params: { kioskId: kiosk.id },
    body: {kiosk}
  })
  .then(() => fetchLayoutInfo({ kioskId: kiosksRouteStore.activeKiosk.id }));
};
