import { toJS } from 'mobx';
import { get as _get } from 'lodash';

import { pharmaciesRouteStore } from '../domain/store';
import { pharmacyUpsertModalRouteStore } from '../components/pharmacy-upsert-modal/domain/store';

import {
  PHARMACY_MANIPULATION_OPTIONS,
  PHARMACY_SKELETON,
} from '../constants';
const { CREATE, UPDATE } = PHARMACY_MANIPULATION_OPTIONS;

export default (e, purpose = UPDATE) => {
  if (purpose === UPDATE) {
    pharmaciesRouteStore.setValue('activePharmacyId', e.content.id);

    const activePharmacyCopy = toJS(pharmaciesRouteStore.activePharmacy);
    const locationLabel = `${_get(activePharmacyCopy, 'location.street1', '')} ${_get(activePharmacyCopy, 'location.city', '')} ${_get(activePharmacyCopy, 'location.state', '')}`;
    pharmacyUpsertModalRouteStore.setValue('activelyModifiedPharmacy', activePharmacyCopy);
    pharmacyUpsertModalRouteStore.setValue('locationSelector', {
      label: locationLabel,
      value: activePharmacyCopy.locationId,
    });
    pharmacyUpsertModalRouteStore.setValue('locationSelectorFilter', locationLabel);
    pharmaciesRouteStore.setValue('showingPharmacyUpsertModal', true);
  } else if (purpose === CREATE) {
    pharmaciesRouteStore.setValue('showingPharmacyUpsertModal', true);
    pharmacyUpsertModalRouteStore.setValue('activelyModifiedPharmacy', PHARMACY_SKELETON);
  }
};
