import { toJS } from 'mobx';

import { patientsRouteStore } from '../domain/store';
import { patientUpsertModalRouteStore } from '../domain/upsert-modal-store';

import {
  PATIENT_MANIPULATION_OPTIONS,
  PATIENT_SKELETON,
} from '../constants';
const { CREATE, UPDATE } = PATIENT_MANIPULATION_OPTIONS;


export default (e, purpose = UPDATE) => {
  switch (purpose) {
    case UPDATE:
      patientsRouteStore.setValue('activePatientId', e.content.id);
      patientsRouteStore.setValue('showingPatientUpsertModal', true);
      patientUpsertModalRouteStore.setModifiedPatient('', toJS(patientsRouteStore.activePatient));
      break;
    case CREATE:
      patientsRouteStore.setValue('showingPatientUpsertModal', true);
      patientUpsertModalRouteStore.setModifiedPatient('', PATIENT_SKELETON);
  }
};
