import { types } from 'mobx-state-tree';


export const PrescriptionInfoRouteStore = types.model({
  http: types.optional(types.model({
    listingContainerImages: types.optional(types.number, 0)
  }), {}),
  preLabelImages: types.optional(types.array(types.frozen()), []),
  postLabelImages: types.optional(types.array(types.frozen()), []),
  labelImage: types.optional(types.frozen(), null),
  isExpanded: types.optional(types.boolean, false),
})
.actions(self => ({
  setValue: (prop, value) => self[prop] = value,
  clearImages: () => {
    self.preLabelImages = [];
    self.postLabelImages = [];
    self.labelImage = null;
  },
  incrementHttp: (http) => ++self.http[http],
  decrementHttp: (http) => --self.http[http],
}))
.views(self => ({
  get preLabelGallery() {
    return self.preLabelImages.map(containerImage => ({ src: containerImage, thumbnail: containerImage }));
  },
  get postLabelGallery() {
    return self.postLabelImages.map(containerImage => ({ src: containerImage, thumbnail: containerImage }));
  },
  get labelImageGallery() {
    return [{src: self.labelImage, thumbnail: self.labelImage}];
  }
}));

export const prescriptionInfoRouteStore = PrescriptionInfoRouteStore.create({});
