import deepFreeze from 'deep-freeze';

export const MODEL_NAME = Object.freeze({
  SINGULAR: 'videoConferenceParticipantConnection',
  PLURAL: 'video-conference-participant-connections',
});

export const FIELD_NAME = Object.freeze({
  ID: 'id',
  LAST_PINGED_AT: 'lastPingedAt',
  DISCONNECTED_AT: 'disconnectedAt',
  TWILIO_TOKEN: 'twilioToken',
  USER_ID: 'userId',
  VIDEO_CONFERENCE_ID: 'videoConferenceId',
  CREATED_AT: 'createdAt',
  UPDATED_AT: 'updatedAt',
});

export const SCHEMA = deepFreeze({
  title: MODEL_NAME.SINGULAR,
  type: 'object',
  properties: {
    [FIELD_NAME.ID]: {
      type: 'string',
    },
    [FIELD_NAME.LAST_PINGED_AT]: {
      type: 'string',
    },
    [FIELD_NAME.DISCONNECTED_AT]: {
      type: 'string',
    },
    [FIELD_NAME.TWILIO_TOKEN]: {
      type: 'string',
    },
    [FIELD_NAME.USER_ID]: {
      type: 'string',
    },
    [FIELD_NAME.VIDEO_CONFERENCE_ID]: {
      type: 'string',
    },
    [FIELD_NAME.CREATED_AT]: {
      type: 'string',
    },
    [FIELD_NAME.UPDATED_AT]: {
      type: 'string',
    },
  },
  required: [],
});
