import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';

//Views
import DispenseAttemptView from './dispense-attempts-view';

// Helpers
import * as helpers from './helpers';

// Stores
import stores from 'bootstrap/store';
import { dispenseAttemptsRouteStore } from './domain/store';
const dispenseAttemptsStore = stores.global.dispenseAttempts;
const prescriptionInfoRouteStore = stores.route.dispenseAttempts.routes.prescriptionInfo.domain;

const DispenseAttemptsContainer = ({ rootRoute }) => {
  const navigate = useNavigate();
  const { dispenseAttemptId } = useParams();

  useEffect(() => {
    dispenseAttemptsRouteStore.setValue('isExpanded', false);

    helpers.fetchRequestedDispenseAttempt()
    .then((dispenseAttempt = {}) => {
      const requestedTab = helpers.getDispenseAttemptTab(dispenseAttempt);
      if (requestedTab) {
        dispenseAttemptsRouteStore.setValue('dispenseAttemptsTab', requestedTab);
      }

      return helpers.fetchDispenseAttempts();
    })
    .catch(() => {});

    return () => {
      dispenseAttemptsRouteStore.reset();
      dispenseAttemptsStore.setValue('dataForPharmacist', {});
    };
  }, []);

  useEffect(() => {
    if (!dispenseAttemptsRouteStore.http.listingForPharmacist) {
      helpers.determineActiveDispenseAttempt(dispenseAttemptId, navigate);
    }
  }, [dispenseAttemptId, dispenseAttemptsRouteStore.http.listingForPharmacist, dispenseAttemptsStore.dataForPharmacist.size]);

  return <DispenseAttemptView
    dispenseAttemptsStore={dispenseAttemptsStore}
    dispenseAttemptsRouteStore={dispenseAttemptsRouteStore}
    prescriptionInfoRouteStore={prescriptionInfoRouteStore}
    setFilterValue={helpers.setFilterValue}
    setActiveDispenseAttempt={helpers.setActiveDispenseAttempt}
    approveDispenseAttempt={helpers.approveDispenseAttempt}
    requestConsultation={helpers.requestConsultation}
    setActiveDispenseAttemptTab={helpers.setActiveDispenseAttemptTab}
    refreshDispenseAttemptsOnReviewConflict={helpers.refreshDispenseAttemptsOnReviewConflict}
    onListBottomThreshold={helpers.onListBottomThreshold}
    rootRoute={rootRoute}
  />;
};

export default observer(DispenseAttemptsContainer);
