import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react';

import NotificationPaneView from './notifications-pane-view';

import usePrevious from 'hooks/usePrevious';

import { notificationsPaneRouteStore } from './domain/store';

import {
  addNotificationEffects,
  fetchNotificationsRelatedData,
  getNotificationsCountByType,
} from './helpers';

import { NOTIFICATIONS_DATA } from './constants';


const NotificationPaneContainer = () => {
  const refetchIntervalsRef = useRef([]);

  const notificationsCountByType = getNotificationsCountByType();
  const previousNotificationsCountByType = usePrevious(notificationsCountByType);

  useEffect(() => {
    fetchNotificationsRelatedData();

    Object.values(NOTIFICATIONS_DATA).forEach(({ loadData, refetchInterval }) => {
      if (loadData && refetchInterval) {
        const interval = setInterval(() => loadData(), refetchInterval);
        refetchIntervalsRef.current.push(interval);
      }
    });

    return () => {
      refetchIntervalsRef.current.forEach((interval) => clearInterval(interval));
    };
  }, []);

  useEffect(() => {
    const currentNotificationsCountByType = getNotificationsCountByType();
    addNotificationEffects(previousNotificationsCountByType, currentNotificationsCountByType);
  }, [notificationsCountByType]);


  return <NotificationPaneView
    activeTab={notificationsPaneRouteStore.activeTab}
    setActiveTab={notificationsPaneRouteStore.setActiveTab}
  />;
};

export default observer(NotificationPaneContainer);
