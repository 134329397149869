import React from 'react';
import { observer } from 'mobx-react';

// Stores
import { lotsRouteStore } from '../../domain/store';

// Components
import Content from 'components/drill-down-layout/components/content';
import ContentTab from 'components/drill-down-layout/components/content/components/content-tab';
import Header from './components/header';

// Routes
import InfoRoute from '../../routes/info';
import PrescriptionFillsRoute from '../../routes/prescription-fills';

// Constants
import { LOTS } from 'constants/routes';

const LotsContent = ({ activeLotId }) => {
  return <Content
    header={<Header
      lotsRouteStore={lotsRouteStore}
    />}
    isReady={!!lotsRouteStore.activeLot && !lotsRouteStore.isBusy}
  >
    <ContentTab title={LOTS.CONTENT_TAB.INFO.TITLE} route={LOTS.CONTENT_TAB.INFO.VALUE}>
      <InfoRoute
        activeLotId={activeLotId}
      />
    </ContentTab>
    <ContentTab title={LOTS.CONTENT_TAB.PRESCRIPTIONS.TITLE} route={LOTS.CONTENT_TAB.PRESCRIPTIONS.VALUE}>
      <PrescriptionFillsRoute
        activeLotId={activeLotId}
      />
    </ContentTab>
  </Content>;
};

export default observer(LotsContent);
