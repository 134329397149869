import listPrescriptionFillsDebounce from './listPrescriptionFillsDebounce';

import stores from 'bootstrap/store';
import { lotPrescriptionFillsRouteStore } from '../domain/store';

const onPrescriptionFillSearch = (e, lotId) => {
  stores.global.prescriptionFills.setValue('dataForPharmacist', {});
  lotPrescriptionFillsRouteStore.setValue('filterValue', e.target.value);
  return listPrescriptionFillsDebounce(lotId);
};

export default onPrescriptionFillSearch;
