import React from 'react';
import { observer } from 'mobx-react';

import './status-manipulation-style.scss';

const PrescriptionStatusManipulation = ({
  processMailOrder
}) => (
  <div className='c-PrescriptionStatusManipulation'>
    <input
      // disabled={!routingHealthcareSitesSelected}
      className='button process'
      type='button'
      value='Process'
      onClick={processMailOrder}
    />
  </div>
);


export default observer(PrescriptionStatusManipulation);
