import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';

// Views
import PatientsIncompleteInfoView from './patients-incomplete-info-view';

// Helpers
import {
  determineActivePatient,
  listPatients,
  markActivePatientAsCaptured,
  requestActivePatientDataCapture,
  setFilterValue,
} from './helpers';

// View Store
import { patientsIncompleteInfoRouteStore } from './domain/store';

// Global Stores
import stores from 'bootstrap/store';
const patientsStore = stores.global.patients;


const PatientsIncompleteInfoContainer = ({ rootRoute }) => {
  const navigate = useNavigate();
  const { patientId } = useParams();

  useEffect(() => {
    listPatients();

    return () => {
      patientsIncompleteInfoRouteStore.setInitialState();
      patientsStore.setValue('dataForPharmacist', {});
    };
  }, []);

  useEffect(() => {
    if (!patientsIncompleteInfoRouteStore.http.listingPatients) {
      determineActivePatient(patientId, navigate);
    }
  }, [patientId, patientsIncompleteInfoRouteStore.http.listingPatients]);

  return (
    <PatientsIncompleteInfoView
      patientsStore={patientsStore}
      patientsIncompleteInfoRouteStore={patientsIncompleteInfoRouteStore}
      setFilterValue={setFilterValue}
      listPatients={listPatients}
      markActivePatientAsCaptured={markActivePatientAsCaptured}
      requestActivePatientDataCapture={requestActivePatientDataCapture}
      rootRoute={rootRoute}
    />
  );
};

export default observer(PatientsIncompleteInfoContainer);
