import { listPharmacies } from './index';

import { pharmaciesRouteStore } from '../domain/store';

export default (fieldName, sortingOrder) => {
  pharmaciesRouteStore.setValue('sortingColumn', {
    ...pharmaciesRouteStore.sortingColumn,
    fieldName,
    sortingOrder,
  });

  return listPharmacies();
};
