import { useCallback, useRef } from 'react';

import useClosureEscape from 'hooks/useClosureEscape';


export default (fn, timeout) => {
  const wrapped = useClosureEscape(fn);
  const timeoutRef = useRef();
  
  return useCallback((...args) => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => wrapped(...args), timeout);
  }, []);
};
