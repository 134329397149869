import { get as _get} from 'lodash';

// Constants
import * as ROUTE from 'constants/routes';
import { MAX_ENTRIES_PER_PAGE} from '../constants';

// Stores
import stores from 'bootstrap/store';
import { medicalAssistantsRouteStore} from '../domain/store';
const medicalAssistantsStore = stores.global.medicalAssistants;
const { router } = stores;

export default () => {
  if (medicalAssistantsRouteStore.http.listingMedicalAssistants) return;

  const query = {
    offset: MAX_ENTRIES_PER_PAGE * (medicalAssistantsRouteStore.currentPage - 1),
    limit: MAX_ENTRIES_PER_PAGE,
  };

  const filterValue = medicalAssistantsRouteStore.filterValue;
  if (filterValue) {
    query.filter = {
      firstName: filterValue,
      lastName: filterValue,
    };
  }

  const config = { query };
  medicalAssistantsRouteStore.incrementHttp('listingMedicalAssistants');
  return medicalAssistantsStore.listMedicalAssistantsForPharmacist(config)
  .catch(() => router.history.push(ROUTE.MEDICAL_ASSISTANTS.VALUE))
  .then((response) => {
    const responseLength = _get(response, 'data.length', 0);
    if (responseLength > MAX_ENTRIES_PER_PAGE - 1) {
      medicalAssistantsRouteStore.setValue('currentPage', medicalAssistantsRouteStore.currentPage + 1);
    }
  })
  .finally(() => medicalAssistantsRouteStore.decrementHttp('listingMedicalAssistants'));
};
