import stores from 'bootstrap/store';

import { isActiveRoute } from '../../../store/router';

import * as ROUTE from 'constants/routes';
import { EVENT } from '../../constants';
const PATIENTS_INCOMPLETE_INFO_ROUTE = `${ROUTE.PATIENTS.VALUE}${ROUTE.PATIENTS.SUB_ROUTE.INCOMPLETE.VALUE}`;

export default ({ apiWebSocket }) => {
  const patientsStore = stores.global.patients;
  const { router } = stores;

  apiWebSocket.on(EVENT.RECEIVED.PATIENTS_PHARMACY_DATA_CAPTURE, ({ patientId }) => {
    if (isActiveRoute(`${PATIENTS_INCOMPLETE_INFO_ROUTE}/${patientId}`)) {
      patientsStore.deletePatientFromMap('dataForPharmacist', patientId);

      const nextPatient = patientsStore.dataForPharmacistList.length && patientsStore.dataForPharmacistList[0];
      if (nextPatient) {
        alert('Current patient was marked as captured by another pharmacist. You will be redirected to the next patient on the list.');
        router.history.push(`${PATIENTS_INCOMPLETE_INFO_ROUTE}/${nextPatient.id}`);
      } else {
        alert('Current patient was marked as captured by another pharmacist.');
      }
    }
  });
};
