import React from 'react';
import { observer } from 'mobx-react';
import { isBoolean as _isBoolean } from 'lodash';

import { CONTENT_TYPE, DEFAULT, PROP_TYPES, EVENT } from '../../constants';

import ContentTypeNumber from './components/content-type-number';
import ContentTypeSring from './components/content-type-string';

import CheckboxCheckedIcon from 'assets/icons/checkbox-checked.svg';
import CheckboxUncheckedIcon from 'assets/icons/checkbox-unchecked.svg';

const CONTENT_TEMPLATE = {
  [CONTENT_TYPE.NUMBER]: ContentTypeNumber,
  [CONTENT_TYPE.STRING]: ContentTypeSring,
};

function setActiveClass(active) {
  return (active ? 'content-row content-row-active' : 'content-row');
}

const SmartTableContent = ({active, columns, content, on, before, after, onRowSelect, onRowDeselect, selectUnavailableCaption, hasClickable}) => {
  const isRowSelectable = _isBoolean(content.isSelectable) ? content.isSelectable : true;
  return (
    <div className='SmartTableContent'>
      {active && before && (<div className="content-before">{before({content, columns, on})}</div>)}
      <div className={`${hasClickable ? 'content-row--withClickable' : ''} ${setActiveClass(active)}`}
        onClick={(evt) => {
          on({type: EVENT.ROW_CLICK, evt, content});
          if (isRowSelectable && onRowSelect && onRowDeselect) {
            if (active) {
              onRowDeselect(content);
            } else {
              onRowSelect(content);
            }
          }
        }}>
        {
          columns.map(column => {
            const template = CONTENT_TEMPLATE[column.type] || CONTENT_TEMPLATE[CONTENT_TYPE.STRING];

            const childProps = {column, content};
            const contentComponent = column.render ? column.render(childProps) :
              React.createElement(template, childProps);

            return (
              <div className="content-cell" style={{width: column.width || DEFAULT.COLUMN_WIDTH}} key={column.name}>
                {contentComponent}
              </div>
            );
          })
        }
        {
          isRowSelectable
            ? onRowSelect && onRowDeselect
              ? <img
                className='checkbox'
                alt={active ? 'Deselect' : 'Select'}
                src={active ? CheckboxCheckedIcon : CheckboxUncheckedIcon}
              />
              : null
            : selectUnavailableCaption
              ? <div className='content-cell select-unavailable-caption'>{selectUnavailableCaption}</div>
              : null
        }
      </div>
      {active && after && (<div className="content-after">{after({content, columns, on})}</div>)}
    </div>
  );};  

export default observer(SmartTableContent);

SmartTableContent.propTypes = {
  columns: PROP_TYPES.COLUMNS,
  content: PROP_TYPES.ROW,
  on: PROP_TYPES.ON
};
