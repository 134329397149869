import stores from 'bootstrap/store';
import { medicalAssistantsRouteStore } from '../domain/store';
import { medicalAssistantsUpsertModalRouteStore } from '../domain/upsert-modal-store';
const healthcareSitesStore = stores.global.healthcareSites;

export default () => {
  medicalAssistantsUpsertModalRouteStore.setInitialState();
  medicalAssistantsRouteStore.setValue('activeMedicalAssistantId', null);
  medicalAssistantsRouteStore.setValue('showingUpsertModal', false);
  healthcareSitesStore.setValue('dataForAdmin', {});
};
