import { types } from 'mobx-state-tree';
import {
  difference as _difference,
  get as _get,
  set as _set,
} from 'lodash';

import PharmacyTechnicianModel from 'models/pharmacy-technicians';

import stores from 'bootstrap/store';
const pharmaciesStore = stores.global.pharmacies;
const pharmacyTechniciansRouteStore = stores.route.pharmacyTechniciansAdmin.domain;

export const PharmacyTechnicianUpsertModalRouteStore = types.model({
})
.props({
  activelyModifiedTechnician: types.maybeNull(types.frozen()),
  workPlaceFilter: types.optional(types.string, ''),
  showingDeleteConfirmationModal: types.optional(types.boolean, false)
})
.actions(self => ({
  setValue: (path, value) => _set(self, path, value),
  setInitialState() {
    self.activelyModifiedTechnician = null;
    self.workPlaceFilter = '';
    self.showingDeleteConfirmationModal = false;
  },
  setActivelyModifiedTechnicianUser: (path, value) => {
    if (!_get(self, 'activelyModifiedTechnician.ptUser.user')) return;
    self.setValue(`activelyModifiedTechnician.ptUser.user.${path}`, value);
  },
  addPharmacy: (pharmacy) => {
    if (!self.activelyModifiedTechnician) return;
    self.activelyModifiedTechnician.pharmacies = self.activelyModifiedTechnician.pharmacies || [];
    self.activelyModifiedTechnician.pharmacies.push(pharmacy);
  },
  deletePharmacy: (pharmacy) => {
    self.activelyModifiedTechnician.pharmacies = self.activelyModifiedTechnician.pharmacies || [];
    const removalIndex = self.activelyModifiedTechnician.pharmacies.findIndex((technicianPharmacy) => {
      return technicianPharmacy.id === pharmacy.id;
    });
    if (removalIndex !== -1) self.activelyModifiedTechnician.pharmacies.splice(removalIndex, 1);
  },
}))
.views(self => ({
  get readyForCreation() {
    const { activelyModifiedTechnician } = self;
    return (
      activelyModifiedTechnician &&
        activelyModifiedTechnician.firstName &&
        activelyModifiedTechnician.lastName
    );
  },
  get readyForUpdate() {
    const { activeTechnician } = pharmacyTechniciansRouteStore;
    const { activelyModifiedTechnician } = self;
    if (!activeTechnician || !activelyModifiedTechnician) return false;

    const pharmacyIds = (activeTechnician.pharmacies || []).map((pharmacy) => pharmacy.id);
    const modifiedPharmacyIds = (activelyModifiedTechnician.pharmacies || []).map((pharmacy) => pharmacy.id);
    return (
      activeTechnician.firstName !== activelyModifiedTechnician.firstName ||
        activeTechnician.lastName !== activelyModifiedTechnician.lastName ||
        _difference(pharmacyIds, modifiedPharmacyIds).length ||
        _difference(modifiedPharmacyIds, pharmacyIds).length ||
        _get(activeTechnician, 'ptUser.user.email') !== _get(activelyModifiedTechnician, 'ptUser.user.email') ||
        _get(activeTechnician, 'ptUser.user.phoneNumber') !== _get(activelyModifiedTechnician, 'ptUser.user.phoneNumber')
    );
  },
  get suggestedWorkPlaces() {
    const { activelyModifiedTechnician } = self;
    if (!activelyModifiedTechnician) return [];
    const { pharmacies } = activelyModifiedTechnician;
    const modifiedPharmacyIds = pharmacies.map((pharmacy) => pharmacy.id);

    const { forPharmacistAsArray } = pharmaciesStore;
    return forPharmacistAsArray.filter((pharmacy) => !modifiedPharmacyIds.includes(pharmacy.id));
  },
  get activelyModifiedTechnicianUser() {
    return _get(self, 'activelyModifiedTechnician.ptUser.user');
  },
  get activeTechnicianWorkPlaces() {
    const { activelyModifiedTechnician } = self;
    if (!activelyModifiedTechnician) return undefined;
    return activelyModifiedTechnician.pharmacies;
  },
}));

export const pharmacyTechnicianUpsertModalRouteStore = PharmacyTechnicianUpsertModalRouteStore.create({});
