import stores from 'bootstrap/store';
import { sendReceiptModalRouteStore } from '../domain/store';

const fetchPatientById = async (patientId) => {
  if (!patientId) {
    return;
  }

  sendReceiptModalRouteStore.incrementHttp('fetchingPatientById');
  return stores.global.patients.fetchById(patientId)
  .then((patient) => {
    sendReceiptModalRouteStore.setValue('patient', patient);
    sendReceiptModalRouteStore.setValue('email', patient.email || '');
    sendReceiptModalRouteStore.setValue('phoneNumber', patient.phoneNumber || '');
  })
  .finally(() => sendReceiptModalRouteStore.decrementHttp('fetchingPatientById'));
};

export default fetchPatientById;
