import { ORDER_OPTIONS } from '../constants';
import { MAX_TABLE_ENTRIES_PER_PAGE } from 'models/pharmacies/constants';

import stores from 'bootstrap/store';
import { pharmaciesRouteStore } from '../domain/store';
const pharmaciesStore = stores.global.pharmacies;

export default () => {
  const query = {
    offset: MAX_TABLE_ENTRIES_PER_PAGE * (pharmaciesRouteStore.currentPage - 1),
    limit: MAX_TABLE_ENTRIES_PER_PAGE,
  };

  if (pharmaciesRouteStore.filterValue) {
    query.filter = {
      name: pharmaciesRouteStore.filterValue,
      phoneNumber: pharmaciesRouteStore.filterValue,
    };
  }

  if (pharmaciesRouteStore.sortingColumn.fieldName && pharmaciesRouteStore.sortingColumn.sortingOrder) {
    query.order = ORDER_OPTIONS[pharmaciesRouteStore.sortingColumn.fieldName](pharmaciesRouteStore.sortingColumn.sortingOrder);
  }

  const listPharmacies = () => {
    pharmaciesRouteStore.incrementHttp('listingForAdmin');
    pharmaciesStore.listPharmacies({ query })
    .then(() => pharmaciesRouteStore.decrementHttp('listingForAdmin'));
  };

  const countPharmacies = () => {
    pharmaciesRouteStore.incrementHttp('countingForAdmin');
    pharmaciesStore.countPharmacies({ query })
    .then(() => pharmaciesRouteStore.decrementHttp('countingForAdmin'));
  };

  return Promise.all([
    listPharmacies(),
    countPharmacies(),
  ]);
};
