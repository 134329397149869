import { keyBy as _keyBy } from 'lodash';
import { flow, types } from 'mobx-state-tree';
import { values } from 'mobx';

import base from 'models/base';
import { MODEL_NAME, SCHEMA } from './constants/schema';

const LotModel = base({
  names: {
    singular: MODEL_NAME.SINGULAR,
    plural: MODEL_NAME.PLURAL
  },
  JSONSchema: SCHEMA,
  httpConfig: {
    root: MODEL_NAME.plural,
    methods: {}
  }
});

LotModel.configureStore((store) => {
  return store
  .props({
    dataForPharmacist: types.optional(types.map(types.frozen()), {}),
  })
  .actions(self => ({
    setValue: (prop, value) => self[prop] = value,
    listForPharmacist: flow(function* (config = {}) {
      config.urlFragment = () => '/';
      const response = yield self.list(config);
      const data = response.data || response || [];
      self.dataForPharmacist.merge(_keyBy(data, 'id'));

      return response;
    }),
    getById: flow(function* (lotId) {
      const response = yield self.get({
        urlFragment: () => `/${lotId}`,
      });

      return response;
    }),
  }))
  .views(self => ({
    get dataForPharmacistList() {
      return values(self.dataForPharmacist);
    },
  }));
});

// Can't use module.exports because there is a generator function in this file,
// and that causes module.exports to fail for some reason.
export default LotModel;
