import { flow, types } from 'mobx-state-tree';
import {
  isEqual as _isEqual,
  get as _get,
  keyBy as _keyBy,
} from 'lodash';

import base from 'models/base';
import { MODEL_NAME, SCHEMA } from './constants/schema';


const PharmacyTechnicianModel = base({
  names: {
    singular: MODEL_NAME.SINGULAR,
    plural: MODEL_NAME.PLURAL
  },
  JSONSchema: SCHEMA,
  httpConfig: {
    root: MODEL_NAME.plural,
    methods: {}
  }
});

PharmacyTechnicianModel.configureStore((store) => {
  return store
  .props({
    pharmacyTechnicians: types.optional(types.map(types.frozen()), {}),
    entriesCount: types.optional(types.number, 0),
    pharmacyTechnicianStatistic: types.optional(types.frozen(), {}),
  })
  .actions(self => ({
    setValue: (prop, value) => self[prop] = value,
    listPharmacyTechniciansForPharmacist: flow(function* (config = {}) {
      config.urlFragment = () => '/for-pharmacist';
    
      const offset = _get(config, 'query.offset', 0);
      const limit = _get(config, 'query.limit', 0);
      if (!offset && self.pharmacyTechnicians.size > limit) self.pharmacyTechnicians = {};

      const response = yield self.list(config);
      const data = response.data || response || [];
      self.pharmacyTechnicians.merge(_keyBy(data, 'id'));

      return response;
    }),
    listPharmacyTechniciansForAdmin: flow(function* (config = {}) {
      const offset = _get(config, 'query.offset', 0);
      const limit = _get(config, 'query.limit', 0);
      if (!offset && self.pharmacyTechnicians.size > limit) self.pharmacyTechnicians = {};

      const response = yield self.list(config);
      const data = response.data || response || [];

      // If data contains the same elements as dataForPharmacist but in reverse order,
      // mobx-state-tree's map treats it as the same set of elements, so views won't rerender.
      const sameData = _isEqual(self.dataAsArray.map(i => i.id).sort(), data.map(i => i.id).sort());
      if (sameData) self.pharmacyTechnicians = {};
      self.pharmacyTechnicians = _keyBy(data, 'id');

      return response;
    }),
    countPharmacyTechnicians: flow(function* (config = {}) {
      config.urlFragment = () => '/count';

      const response = yield self.list(config);
      self.entriesCount = response || 0;

      return response;
    }),
    createPharmacyTechnician: (config = {}) => {
      config.urlFragment = () => '';
      return self.post(config);
    },
    updatePharmacyTechnician: (config = {}) => {
      if (!_get(config, 'params.pharmacyTechnicianId')) {
        return Promise.reject('pharmacyTechnicianId is required in order to update pharmacy technician');
      }

      config.urlFragment = (params) => `${params.pharmacyTechnicianId}`;
      return self.put(config);
    },
    addPharmacy: (config = {}) => {
      if (!_get(config, 'params.pharmacyTechnicianId')) {
        return Promise.reject('pharmacyTechnicianId is required in order to add pharmacy to pharmacy technician');
      }
      if (!_get(config, 'params.pharmacyId')) {
        return Promise.reject('pharmacyId is required in order to add pharmacy to pharmacy technician');
      }

      config.urlFragment = (params) => `/${params.pharmacyTechnicianId}/pharmacies/${params.pharmacyId}`;
      return self.post(config);
    },
    deletePharmacy: (config = {}) => {
      if (!_get(config, 'params.pharmacyTechnicianId')) {
        return Promise.reject('pharmacyTechnicianId is required in order to delete pharmacy from pharmacy technician');
      }
      if (!_get(config, 'params.pharmacyId')) {
        return Promise.reject('pharmacyId is required in order to delete pharmacy to pharmacy technician');
      }

      config.urlFragment = (params) => `/${params.pharmacyTechnicianId}/pharmacies/${params.pharmacyId}`;
      return self.delete(config);
    },
    deletePharmacyTechnician: (config = {}) => {
      if (!_get(config, 'params.pharmacyTechnicianId')) {
        return Promise.reject('pharmacyTechnicianId is required in order to delete pharmacy technician');
      }

      config.urlFragment = (params) => `/${params.pharmacyTechnicianId}`;
      return self.delete(config);
    },
    fetchPharmacyTechnicianStatistic: flow(function* (config = {}) {
      config.urlFragment = (params) => `/${params.pharmacyTechnicianId}/statistic`;

      const response = yield self.get(config);
      self.pharmacyTechnicianStatistic = response.data || response || {};

      return response;
    }),
    addHealthcareSiteToPharmacyTechnician: flow(function* (config = {}) {
      config.urlFragment = (params) => `/${params.pharmacyTechnicianId}/healthcareSites/${params.healthcareSiteId}`;

      const response = yield self.post(config);

      return response;
    }),
    deleteHealthcareSiteFromPharmacyTechnician: flow(function* (config = {}) {
      config.urlFragment = (params) => `/${params.pharmacyTechnicianId}/healthcareSites/${params.healthcareSiteId}`;

      const response = yield self.delete(config);

      return response;
    }),
  }))
  .views(self => ({
    get dataAsArray() {
      return Array.from(self.pharmacyTechnicians.values());
    },
  }));
});

// Can't use module.exports because there is a generator function in this file,
// and that causes module.exports to fail for some reason.
export default PharmacyTechnicianModel;
