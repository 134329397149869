import { fetchComputedInventoryForModification } from 'models/kiosks/helpers';

import stores from 'bootstrap/store';
const kioskDrugThresholdsStore = stores.global.kioskDrugThresholds;
const kiosksRouteStore = stores.route.kiosks.domain;

export default (drugId, { lowContainersCount, highContainersCount }) => {
  const { activeKioskId } = kiosksRouteStore;
  if (!activeKioskId) return;

  return kioskDrugThresholdsStore.upsertThresholds({
    params: { drugId },
    body: {
      kioskId: activeKioskId,
      lowContainersCount,
      highContainersCount,
    },
  })
  .then(() => fetchComputedInventoryForModification());
};
