import { types } from 'mobx-state-tree';

import { RE_POSITIVE_FLOAT } from '../constants';

import doesValueMatchRegex from 'utils/doesValueMatchRegex';

export const PrescriptionFillEditModalRouteStore = types.model({
})
.props({
  drug: types.maybeNull(types.frozen()),
  quantity: types.maybeNull(types.string),
  copay: types.maybeNull(types.string),
  http: types.optional(types.model({
    updatingPrescriptionFill: types.optional(types.number, 0),
    listingDrugs: types.optional(types.number, 0),
  }), {}),
})
.actions(self => ({
  setValue: (prop, value) => self[prop] = value,
  incrementHttp: (http) => ++self.http[http],
  decrementHttp: (http) => --self.http[http],
  setInitialState: () => {
    self.drug = null;
    self.quantity = null;
    self.copay = null;
    self.http = {};
  },
}))
.views(self => ({
  get canBeSubmitted() {
    return (
      (self.drug ? Boolean(self.drug.id) : true) &&
      (self.quantity ? doesValueMatchRegex(RE_POSITIVE_FLOAT, self.quantity) : true) &&
      (self.copay ? doesValueMatchRegex(RE_POSITIVE_FLOAT, self.copay) : true)
    );
  },
  get isBusy() {
    return Boolean(self.http.updatingPrescriptionFill || self.http.listingDrugs);
  },
}));

export const prescriptionFillEditModalRouteStore = PrescriptionFillEditModalRouteStore.create({});
