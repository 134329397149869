// Constants
import { APP_COMMAND_TYPE } from 'models/app-commands/constants/constants';
import { BASE_BUNDLES, BASE_ITEMS} from './app-commands-config-base';

export default {
  [APP_COMMAND_TYPE.CONTAINERS_CREATE]: [
    ...BASE_BUNDLES.CONTAINER,
    BASE_ITEMS.EXPIRATION_DATE,
    BASE_ITEMS.USER,
  ],
  [APP_COMMAND_TYPE.CONTAINERS_SET_REMOVED]: [
    ...BASE_BUNDLES.CONTAINER,
    BASE_ITEMS.USER,
  ],
  [APP_COMMAND_TYPE.DISPENSE_ATTEMPTS_APPROVE]: BASE_BUNDLES.DISPENSE_ATTEMPT,
  [APP_COMMAND_TYPE.DISPENSE_ATTEMPTS_CREATE]: BASE_BUNDLES.DISPENSE_ATTEMPT,
  [APP_COMMAND_TYPE.DISPENSE_ATTEMPTS_DISMISS_REJECTED]: BASE_BUNDLES.DISPENSE_ATTEMPT,
  [APP_COMMAND_TYPE.DISPENSE_ATTEMPTS_DISPENSE]: BASE_BUNDLES.DISPENSE_ATTEMPT,
  [APP_COMMAND_TYPE.DISPENSE_ATTEMPTS_REJECT]: BASE_BUNDLES.DISPENSE_ATTEMPT,
  // [APP_COMMAND_TYPE.DISPENSE_ATTEMPT_SIGN]: BASE_BUNDLES.DISPENSE_ATTEMPT,
  // [APP_COMMAND_TYPE.KIOSK_UPDATE_LAYOUT]: [
  //   BASE_ITEMS.USER,
  // ],
  // [APP_COMMAND_TYPE.CONTAINER_REMOVE_REJECTED]: [
  //
  // ],
  [APP_COMMAND_TYPE.KIOSK_STATUS_CREATE]: [
    BASE_ITEMS.KIOSK_NOT_SHOW_WITHOUT_VALUE,
  ],
  [APP_COMMAND_TYPE.USERS_LOGIN_KEYCARD]: [
    BASE_ITEMS.CARD_NUMBER,
    BASE_ITEMS.SUB_USER,
    BASE_ITEMS.USER,
    BASE_ITEMS.KIOSK_NOT_SHOW_WITHOUT_VALUE,
  ],
  [APP_COMMAND_TYPE.CONTAINERS_ASSIGN_AVAILABLE_POSITION]: BASE_BUNDLES.CONTAINER,
  [APP_COMMAND_TYPE.CONTAINERS_ADD_IMAGE_LABELLED]: BASE_BUNDLES.CONTAINER,
  [APP_COMMAND_TYPE.CONTAINER_LABELLED]: BASE_BUNDLES.CONTAINER,
  [APP_COMMAND_TYPE.CONTAINERS_SET_NOT_STORED]: BASE_BUNDLES.CONTAINER,
  [APP_COMMAND_TYPE.CONTAINERS_SET_STORED]: BASE_BUNDLES.CONTAINER,
  [APP_COMMAND_TYPE.KIOSKS_CREATE_BY_PHARMACY]: [
    BASE_ITEMS.PHARMACY_NAME,
    BASE_ITEMS.LOCATION,
    BASE_ITEMS.USER,
    BASE_ITEMS.KIOSK_NOT_SHOW_WITHOUT_VALUE,
  ],
  [APP_COMMAND_TYPE.KIOSKS_UPDATE_BY_PHARMACY]: [
    BASE_ITEMS.USER,
    BASE_ITEMS.KIOSK_NOT_SHOW_WITHOUT_VALUE,
  ],
  [APP_COMMAND_TYPE.PATIENT_SIGNATURES_CREATE]: [
    BASE_ITEMS.PATIENT,
    BASE_ITEMS.TYPE,
    BASE_ITEMS.KIOSK_NOT_SHOW_WITHOUT_VALUE,
  ],
  [APP_COMMAND_TYPE.HEALTHCARE_SITES_CREATE]: [
    BASE_ITEMS.USER,
    BASE_ITEMS.PHONE,
  ],
  [APP_COMMAND_TYPE.HEALTHCARE_SITES_UPDATE]: [
    BASE_ITEMS.USER,
  ],
};
