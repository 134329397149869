import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import MachineTechniciansView from './machine-technicians-view';

// Helpers
import * as helpers from './helpers';

// Stores
import stores from 'bootstrap/store';
import { machineTechniciansRouteStore } from './domain/store';
import { machineTechnicianUpsertModalRouteStore } from './domain/upsert-modal-store';
const machineTechniciansStore = stores.global.machineTechnicians;


const MachineTechniciansContainer = () => {
  useEffect(() => {
    helpers.listMachineTechnicians();
  }, []);

  return (
    <MachineTechniciansView
      machineTechniciansStore={machineTechniciansStore}
      machineTechniciansRouteStore={machineTechniciansRouteStore}
      machineTechnicianUpsertModalRouteStore={machineTechnicianUpsertModalRouteStore}
      sortMachineTechnicians={helpers.sortMachineTechnicians}
      changePaginationPage={helpers.changePaginationPage}
      onMachineTechniciansSearch={helpers.onMachineTechniciansSearch}
      showMachineTechnicianUpsertModal={helpers.showMachineTechnicianUpsertModal}
      closeMachineTechnicianUpsertModal={helpers.closeMachineTechnicianUpsertModal}
      showMachineTechnicianDeleteConfirmationModal={helpers.showMachineTechnicianDeleteConfirmationModal}
      addKioskLocally={helpers.addKioskLocally}
      removeKioskLocally={helpers.removeKioskLocally}
      onKiosksSearch={helpers.onKiosksSearch}
      createMachineTechnician={helpers.createMachineTechnician}
      updateMachineTechnician={helpers.updateMachineTechnician}
      deleteMachineTechnician={helpers.deleteMachineTechnician}
    />
  );
};

export default observer(MachineTechniciansContainer);
