import React, { useCallback, useEffect } from 'react';
import queryString from 'qs';

// Views
import ChangePasswordView from './change-password-view';

// Store
import stores from 'bootstrap/store';
import { changePasswordRouteStore } from './domain/store';
const usersStore = stores.global.users;
const { router } = stores;


const ChangePasswordContainer = () => {
  useEffect(() => {
    return () => {
      changePasswordRouteStore.setInitialState();
    };
  }, []);

  const changePassword = useCallback(async (e) => {
    e.preventDefault();
    const parsed = queryString.parse((router.location.search || '').replace(/^\?/g, ''));

    if (!changePasswordRouteStore.password || changePasswordRouteStore.password !== changePasswordRouteStore.passwordConfirm) {
      return Promise.reject('Passwords are not equal or empty');
    }
    if (!parsed.resetToken || !parsed.email) {
      return Promise.reject('Link is invalid, please verify link and try again');
    }

    return usersStore.changePassword({
      body: {
        email: parsed.email,
        newPassword: changePasswordRouteStore.password,
        authResetPasswordToken: parsed.resetToken
      }
    })
    .then(() => router.history.push('/'))
    .catch((err) => changePasswordRouteStore.setValue('errorMessage', typeof error === 'string'? err : 'Could not change password'));
  }, []);

  return <ChangePasswordView
    changePasswordRouteStore={changePasswordRouteStore}
    changePassword={changePassword}
  />;
};

export default ChangePasswordContainer;
