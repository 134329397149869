import React from 'react';
import { observer } from 'mobx-react';

import ModalRow from 'components/modal/layout/row';
import ModalButton from 'components/modal/layout/button';

const Footer = ({
  upsertModalRouteStore,
  activeMedicalAssistant,
  createMedicalAssistant,
  updateMedicalAssistant,
  showDeleteConfirmationModal,
}) => {
  const isDisabled = activeMedicalAssistant
    ? !upsertModalRouteStore.readyForUpdate
    : !upsertModalRouteStore.readyForCreation;

  return <ModalRow shouldBoundToBottom={true} shouldBeSpaceBetween={true}>
    <ModalButton
      isDisabled={isDisabled}
      color='blue'
      text='Save'
      onClick={activeMedicalAssistant ? updateMedicalAssistant : createMedicalAssistant}
    />
    {
      activeMedicalAssistant &&
      <ModalButton
        color='red'
        text='Delete'
        onClick={() => showDeleteConfirmationModal()}
      />
    }
  </ModalRow>;
};

export default observer(Footer);
