import _ from 'lodash';

const TIME_PERIODS = {
  HOURS: 'hours',
  MINUTES: 'mins',
  SECONDS: 'secs',
};

const TIME_PERIODS_VALUES = {
  [TIME_PERIODS.HOURS]: 3600000,
  [TIME_PERIODS.MINUTES]: 60000,
  [TIME_PERIODS.SECONDS]: 1000,
};

const getDuration = (from ,to) => {
  let milliseconds = new Date(to).getTime() - new Date(from).getTime();
  let result = {};

  Object.values(TIME_PERIODS).forEach(period => {
    if (milliseconds === 0) return;

    let periodLength = Math.floor(milliseconds/TIME_PERIODS_VALUES[period]);

    if (periodLength > 0) {
      result[period] = periodLength;
      milliseconds = milliseconds - TIME_PERIODS_VALUES[period]*result[period];
    }
  });

  let resultDuration = Object.keys(result).reduce((res, key) => {
    if (result[key] === 0) return res;

    return `${res} ${result[key]} ${result[key] === 1? key.slice(0, -1): key} `;
  }, '');

  if (resultDuration === '') resultDuration = '0 secs';

  return resultDuration;
};

export const TABLE_COLUMNS = {
  TIME: 'Time',
  PATIENT: 'Patient',
  DRUG: 'Drug',
  KIOSK: 'KIOSK',
  DURATION: 'Duration',
};

export const TABLE_COLUMNS_NAMES = Object.values(TABLE_COLUMNS);

export const TABLE_COLUMNS_INFO = {
  [TABLE_COLUMNS.TIME]: {
    get: el => new Date(_.get(el, 'createdAt')).toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
    }),
    width: '15%',
    isSortable: true,
  },
  [TABLE_COLUMNS.PATIENT]: {
    get: el => _.get(el, 'prescriptionFillsFulfillmentVideoConference')
      ? `${
        _.get(el, 'prescriptionFillsFulfillmentVideoConference.prescriptionFillsFulfillment.prescriptionFills[0].prescription.patient.lastName')
      }, ${_.get(el, 'prescriptionFillsFulfillmentVideoConference.prescriptionFillsFulfillment.prescriptionFills[0].prescription.patient.firstName')}`
      : '',
    width: '20%',
    isSortable: true,
  },
  [TABLE_COLUMNS.DRUG]: {
    get: el => _.get(el, 'prescriptionFillsFulfillmentVideoConference')
      ? _.uniq(_.get(el, 'prescriptionFillsFulfillmentVideoConference.prescriptionFillsFulfillment.prescriptionFills', []).map(prescriptionFill => _.get(prescriptionFill, 'prescription.drug.name'), '')).join(', ')
      : '',
    width: '25%',
    isSortable: true,
  },
  [TABLE_COLUMNS.KIOSK]: {
    get: el => _.get(el, 'callerUser.kioskUser.kiosk.name', ''),
    width: '20%',
    isSortable: true,
  },
  [TABLE_COLUMNS.DURATION]: {
    get: el => getDuration(
      _.get(el, 'videoConferenceParticipantConnections[0].createdAt', new Date()),
      _.get(el, 'videoConferenceParticipantConnections[0].disconnectedAt', new Date()),
    ),
    width: '20%',
    isSortable: false,
  }
};

export const DIRECTIONS = {
  ASC: 'asc',
  DESC: 'desc',
};

export const PAGINATION = {
  LIMIT: 10,
  MAX_PAGES: 4,
};
