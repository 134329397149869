import {
  closePharmacyTechnicianUpsertModal,
  listPharmacyTechnicians,
} from './index';

import stores from 'bootstrap/store';
import { pharmacyTechnicianUpsertModalRouteStore } from '../domain/upsert-modal-store';
const pharmacyTechniciansStore = stores.global.pharmacyTechnicians;

export default () => {
  const { activelyModifiedTechnician } = pharmacyTechnicianUpsertModalRouteStore;
  return pharmacyTechniciansStore.createPharmacyTechnician({
    body: activelyModifiedTechnician,
  })
  .then((pharmacyTechnician = {}) => {
    const pharmacyTechnicianId = pharmacyTechnician.id;
    const { pharmacies = [] } = activelyModifiedTechnician;
    const addPharmacyPromises = pharmacies.map((pharmacy) => pharmacyTechniciansStore.addPharmacy({
      params: {
        pharmacyTechnicianId,
        pharmacyId: pharmacy.id,
      },
    }));

    return Promise.all(addPharmacyPromises);
  })
  .then(() => {
    closePharmacyTechnicianUpsertModal();
    return listPharmacyTechnicians();
  });
};
