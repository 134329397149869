import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import useForceUpdate from 'hooks/useForceUpdate';
import useIsMounted from 'hooks/useIsMounted';
import usePrevious from 'hooks/usePrevious';

import LoaderGif from './loader.gif';

const ContentScreen = ({ active = false, children, className, color, fadeDuration }) => {
  const forceUpdate = useForceUpdate();
  const isMounted = useIsMounted();
  const fadingOutTimeoutRef = useRef(null);
  const previousActive = usePrevious(active);

  const handleFade = useCallback(() => {
    clearTimeout(fadingOutTimeoutRef?.current);
    fadingOutTimeoutRef.current = setTimeout(() => {
      fadingOutTimeoutRef.current = null;
      if (isMounted) {
        forceUpdate();
      }
    }, fadeDuration);
  }, [fadeDuration, isMounted]);

  useEffect(() => {
    if (active) {
      handleFade();
    }
  }, []);

  useEffect(() => {
    if (active !== previousActive) {
      handleFade();
    }
  }, []);

  const style = useMemo(() => {
    if (!fadeDuration) {
      return {};
    }

    const transition = fadeDuration ? `opacity ${fadeDuration}ms` : undefined;
    return {
      transition: transition,
      WebkitTransition: transition,
      msTransition: transition,
      backgroundColor: color || '#FFFFFF',
    };
  }, [color, fadeDuration]);

  return (
    !!(active || fadingOutTimeoutRef.current) &&
    <div className={`c-content-screen ${className || ''}`}>
      {
        <div style={style} className={`c-spinner ${active ? 'active' : ''}`}>
          <img alt='Loading' style={{opacity: ~~active * 0.5}} src={LoaderGif} />
        </div>
      }
      {
        children || false
      }
    </div>
  );
};

export default ContentScreen;
