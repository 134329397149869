import stores from 'bootstrap/store';
import { keycardUpsertModalRouteStore } from '../components/keycard-upsert-modal/domain/store';
const usersStore = stores.global.users;

export default () => {
  const { userSelectFilter } = keycardUpsertModalRouteStore;
  let subQuery = {};
  if (userSelectFilter) {
    const wildcard = `${userSelectFilter}%`;
    subQuery = {
      $or: [
        { firstName: { $iLike: wildcard } },
        { lastName: { $iLike: userSelectFilter } },
      ],
    };
  }

  return usersStore.listForPharmacist({ query: { query: subQuery } });
};
