import React from 'react';
import { observer } from 'mobx-react';

// Utils
import parseDbDateOfBirth from 'utils/parseDbDateOfBirth';

import './patient-style.scss';


const Patient = ({ externalPharmacyId, createdAt = '', DOB = '' }) => {
  let detail;
  if (externalPharmacyId) {
    detail = `Ext. Pharmacy ID: ${externalPharmacyId}`;
  } else if (createdAt) {
    detail = `Creation Date: ${new Date(createdAt).toLocaleString()}`;
  }

  return <div className='c-PatientListItemDetails'>
    <div className='data-row'>
      <div className='label'>DOB:</div>
      <div>{ DOB ? parseDbDateOfBirth(DOB) : 'N/A' }</div>
    </div>
    {
      detail &&
      <div className='detail'>
        {
          detail
        }
      </div>
    }
  </div>;
};

export default observer(Patient);
