import { difference as _difference, get as _get, pickBy as _pickBy } from 'lodash';

import {
  closePatientUpsertModal,
  listPatients,
} from './index';

import stores from 'bootstrap/store';
import { patientsRouteStore } from '../domain/store';
import { patientUpsertModalRouteStore } from '../domain/upsert-modal-store';
const patientsStore = stores.global.patients;
const usersStore = stores.global.users;

export default () => {
  const { activelyModifiedPatient } = patientUpsertModalRouteStore;
  const user = _get(activelyModifiedPatient, 'patientUser.user', {});

  return Promise.all([
    patientsStore.updatePatient({
      params: { id: patientsRouteStore.activePatientId },
      body: _pickBy(activelyModifiedPatient),
    }),
    user.id && usersStore.updateUser({ params: { id: user.id }, body: user }),
  ])
  .then(() => {
    const patientId = activelyModifiedPatient.id;

    const { activePatient } = patientsRouteStore;
    const initialHealthcareSiteIds = (activePatient.healthcareSites || []).map((healthcareSite) => healthcareSite.id);
    const modifiedHealthcareSiteIds = (activelyModifiedPatient.healthcareSites || []).map((healthcareSite) => healthcareSite.id);
    const addedIds = _difference(modifiedHealthcareSiteIds, initialHealthcareSiteIds);
    const removedIds = _difference(initialHealthcareSiteIds, modifiedHealthcareSiteIds);

    const promises = [];
    addedIds.forEach((healthcareSiteId) => promises.push(patientsStore.addHealthcareSite({
      params: {
        patientId,
        healthcareSiteId,
      },
    })));
    removedIds.forEach((healthcareSiteId) => promises.push(patientsStore.deleteHealthcareSite({
      params: {
        patientId,
        healthcareSiteId,
      },
    })));

    return Promise.all(promises)
    .then(() => {
      closePatientUpsertModal();
      return listPatients();
    });
  });
};
