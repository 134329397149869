import React from 'react';
import { observer } from 'mobx-react';

import NotificationWrapper from '../notification-wrapper';
import PendingPrescription from './components/pending-prescription';

import './unrouted-prescriptions-notifications-view-style.scss';


const UnroutedPrescriptionsNotificationsView = ({
  pending,
  onClick,
}) => (
  <div className='c-VideoConferencesNotifications'>
    {
      !pending.length &&
      <div className='no-data-caption'>
        No Unrouted Prescriptions
      </div>
    }
    {
      pending.map((prescriptionFill) => <NotificationWrapper
        key={prescriptionFill.id}
        cursorPointer={true}
      >
        <PendingPrescription
          onClick={onClick}
          prescriptionFill={prescriptionFill}
        />
      </NotificationWrapper>)
    }
  </div>
);

export default observer(UnroutedPrescriptionsNotificationsView);
