import {
  closePatientUpsertModal,
  listPatients,
} from './index';

import stores from 'bootstrap/store';
import { patientUpsertModalRouteStore } from '../domain/upsert-modal-store';
const patientsStore = stores.global.patients;

export default () => {
  const { activelyModifiedPatient } = patientUpsertModalRouteStore;

  return patientsStore.createPatient({
    body: activelyModifiedPatient,
  })
  .then((patient) => {
    const patientId = patient.id;
    const { healthcareSites = [] } = activelyModifiedPatient;
    const addHealthcareSitePromises = healthcareSites.map((healthcareSite) => patientsStore.addHealthcareSite({
      params: {
        patientId,
        healthcareSiteId: healthcareSite.id,
      },
    }));

    return Promise.all(addHealthcareSitePromises)
    .then(() => {
      closePatientUpsertModal();
      return listPatients();
    });
  });
};
