import React from 'react';
import { observer } from 'mobx-react';
import { get as _get } from 'lodash';

import './prescription-info-style.scss';

const PrescriptionInfo = ({ dispenseAttempt }) => {
  const patient = _get(dispenseAttempt, 'prescriptionFill.prescription.patient', {});
  const drug = _get(dispenseAttempt, 'prescriptionFill.prescription.drug.name');

  return (
    <div className='c-PrescriptionInfo'>
      <div className='patient'>
        {
          `${patient.firstName} ${patient.lastName}`
        }
      </div>
      <div className='drug'>
        {
          drug
        }
      </div>
    </div>
  );
};

export default observer(PrescriptionInfo);
