import React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import medifriendRxIcon from 'assets/icons/medifriend-rx.svg';
import kioskImage from 'assets/images/kiosk.svg';

import './change-pin-view.scss';


const ChangePinView = ({
  changePinRouteStore,
  changePin
}) => (
  <div className='c-ChangePinView'>
    <div className='primary-panel'>
      <div className='sub-panel left-panel'>
        <img
          className='logo-icon'
          src={medifriendRxIcon}
        />
        <img
          className='kiosk-image'
          src={kioskImage}
        />
      </div>
      <form className='sub-panel right-panel' onSubmit={changePin}>
        <div className='back-to-sign-in'>
          <Link to='/' onClick={() => changePinRouteStore.setInitialState()}>
            Back to main page
          </Link>
        </div>
        <div className='greeting-text'>
          <h3>Change pin</h3>
        </div>
        <div className='input-fields'>
          <div className='input-field'>
            <div className='input-label'>
              New Pin
            </div>
            <div>
              <input value={changePinRouteStore.pin} onChange={evt => changePinRouteStore.setValue('pin', evt.target.value)} type='password' autoComplete="new-password" />
            </div>
          </div>
          <div className='input-field'>
            <div className='input-label'>
              Confirm New Pin
            </div>
            <div>
              <input value={changePinRouteStore.pinConfirm} onChange={evt => changePinRouteStore.setValue('pinConfirm', evt.target.value)} type='password' autoComplete="new-password" />
            </div>
          </div>
        </div>
        <div className='panel-footer'>
          <input
            className='sign-up-button'
            type='submit'
            value='Change pin'
          />
          {
            !!changePinRouteStore.errorMessage &&
            <div className='error-message'>
              {
                changePinRouteStore.errorMessage
              }
            </div>
          }
        </div>
      </form>
    </div>
  </div>
);

export default observer(ChangePinView);
