import Promise from 'bluebird';
import { differenceBy as _difference } from 'lodash';

import {
  closePharmacyUpsertModal,
  listPharmacies,
} from './index';

import stores from 'bootstrap/store';
import { pharmaciesRouteStore } from '../domain/store';
import { pharmacyUpsertModalRouteStore } from '../components/pharmacy-upsert-modal/domain/store';
const pharmaciesStore = stores.global.pharmacies;

export default () => {
  const { activelyModifiedPharmacy, locationSelector } = pharmacyUpsertModalRouteStore;
  const activePharmacy = pharmaciesRouteStore.activePharmacy;

  let body = {...activelyModifiedPharmacy};

  if (body.locationId !== locationSelector.value) {
    delete body.locationId;
    body.placeId = locationSelector.value;
  }

  const healthcareSitesToCreate = _difference(body.healthcareSites, activePharmacy.healthcareSites, 'id');
  const healthcareSitesToDelete = _difference(activePharmacy.healthcareSites, body.healthcareSites, 'id');

  pharmaciesRouteStore.incrementHttp('pharmacyUpsert');
  return pharmaciesStore.upsertPharmacy({
    body,
  })
  .then(() => {
    return Promise.all([
      Promise.map(healthcareSitesToCreate, healthcareSite => pharmaciesStore.addHealthcareSite({params: {
        healthcareSiteId: healthcareSite.id,
        pharmacyId: activePharmacy.id,
      }})),
      Promise.map(healthcareSitesToDelete, healthcareSite => pharmaciesStore.deleteHealthcareSite({params: {
        healthcareSiteId: healthcareSite.id,
        pharmacyId: activePharmacy.id,
      }})),
    ]);
  })
  .then(() => {
    closePharmacyUpsertModal();
    return listPharmacies();
  })
  .finally(() => {
    pharmaciesRouteStore.decrementHttp('pharmacyUpsert');
  });
};
