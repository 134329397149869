import React from 'react';
import { observer } from 'mobx-react';
import { get as _get } from 'lodash';

import './index.scss';

const CallerKioskName = ({ notificationPayload }) => (
  <div className='c-CallerKioskName'>
    {
      _get(notificationPayload, 'kiosk.name', 'N/A')
    }
  </div>
);


export default observer(CallerKioskName);
