import {
  closeUserUpsertModal,
  listUsers,
} from './index';

import stores from 'bootstrap/store';
import { userUpsertModalRouteStore } from '../components/user-upsert-modal/domain/store';
const usersStore = stores.global.users;

export default () => {
  const modifiedUser = userUpsertModalRouteStore.activelyModifiedUser;

  return usersStore.createUser({ body: modifiedUser })
  .then(({ user: createdUser }) => Promise.resolve(
    modifiedUser.isAdmin && usersStore.updateUser({
      params: { id: createdUser.id },
      body: { isAdmin: !!modifiedUser.isAdmin },
    })
  ))
  .then(() => {
    closeUserUpsertModal();
    return listUsers();
  });
};
