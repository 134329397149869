import React from 'react';
import { observer } from 'mobx-react';

// Components
import Content from 'components/drill-down-layout/components/content';
import ContentTab from 'components/drill-down-layout/components/content/components/content-tab';
import Header from './components/header';

// Routes
import ContactRoute from '../../routes/contact';
import ActivityRoute from '../../routes/activity';

// Constants
import { MACHINE_TECHNICIANS } from 'constants/routes';


const MachineTechniciansContent = ({
  machineTechniciansRouteStore,
}) => (
  <Content
    header={<Header
      machineTechniciansRouteStore={machineTechniciansRouteStore}
    />}
    isReady={!!machineTechniciansRouteStore.activeTechnician}
  >
    <ContentTab title={MACHINE_TECHNICIANS.CONTENT_TAB.CONTACT.TITLE} route={MACHINE_TECHNICIANS.CONTENT_TAB.CONTACT.VALUE}>
      <ContactRoute activeTechnician={machineTechniciansRouteStore.activeTechnician} />
    </ContentTab>
    <ContentTab title={MACHINE_TECHNICIANS.CONTENT_TAB.ACTIVITY.TITLE} route={MACHINE_TECHNICIANS.CONTENT_TAB.ACTIVITY.VALUE}>
      <ActivityRoute activeMachineTechnician={machineTechniciansRouteStore.activeTechnician} />
    </ContentTab>
  </Content>
);

export default observer(MachineTechniciansContent);
