import { toJS } from 'mobx';

import {
  closeDrugUpdateModal,
  listDrugs,
} from './index';

import stores from 'bootstrap/store';
import { drugUpdateModalRouteStore } from '../components/drug-update-modal/domain/store';
const drugsStore = stores.global.drugs;

export default () => {
  return drugsStore.upsertDrug({
    body: toJS(drugUpdateModalRouteStore.activelyModifiedDrug),
  })
  .then(() => {
    closeDrugUpdateModal();
    return listDrugs();
  });
};
