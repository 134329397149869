import { listPatientsDebounce } from './index';

import stores from 'bootstrap/store';
import { patientsListRouteStore } from '../domain/store';
const patientsStore = stores.global.patients;

export default (tab) => {
  patientsListRouteStore.setValue('activePatientListTab', tab);
  patientsListRouteStore.setValue('currentPage', 1);
  patientsStore.setValue('dataForPharmacist', {});

  return listPatientsDebounce();
};
