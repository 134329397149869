import React from 'react';

export const MACHINE_TECHNICIAN_SKELETON = {
  firstName: '',
  lastName: '',
  kiosks: []
};

const DISPLAYED_KIOSKS_MAX_COUNT = 3;
export const COLUMNS = [
  {
    name: 'Name',
    fieldName: 'name',
    isSortable: true,
    width: '30%',
    render: ({ content }) => (
      <div title={content.id}>{content.name}</div>
    )
  },
  {
    name: 'Phone Number',
    fieldName: 'phoneNumber',
    width: '15%',
    render: ({ content }) => (
      <div title={content.id}>{content.phoneNumber}</div>
    )
  },
  {
    name: 'Email',
    fieldName: 'email',
    width: '15%',
    render: ({ content }) => (
      <div title={content.id}>{content.email}</div>
    )
  },
  {
    name: 'Machines',
    width: '40%',
    render: ({ content }) => {
      const { kiosks = [] } = content;
      const displayedKiosks = kiosks.slice(0, DISPLAYED_KIOSKS_MAX_COUNT);
      const remainingKiosksCount = kiosks.length > DISPLAYED_KIOSKS_MAX_COUNT
        ? kiosks.slice(DISPLAYED_KIOSKS_MAX_COUNT).length
        : 0;

      return <div title={content.id} className='TechnicianSmartTableWorkPlaces'>
        {
          kiosks.length
            ? <React.Fragment>
              {
                displayedKiosks.map((pharmacy, index) => (
                  <div className='work-place' key={index}>
                    {
                      pharmacy.name
                    }
                  </div>
                ))
              }
              {
                !!remainingKiosksCount &&
                <div className='remaining-work-places-count'>
                  {
                    `+${remainingKiosksCount} more`
                  }
                </div>
              }
            </React.Fragment>
            : 'None'
        }
      </div>;
    }
  }
];
