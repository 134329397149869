import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// Bootstrap modules
import './bootstrap/store';
import './bootstrap/http';
import './bootstrap/store/router';
import './bootstrap/state-listeners';
import './bootstrap/window-listeners';
import './bootstrap/api-websocket';
import './bootstrap/cameras';

// Spin up React
import RootContainer from './app/root/root-container';
import './index.scss';

console.log('App Bootstrapping.');

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <QueryClientProvider client={queryClient}>
    <RootContainer />
  </QueryClientProvider>
);
