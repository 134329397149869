import React, { useCallback, useEffect } from 'react';

// Views
import LoginView from './login-view';

// Store
import stores from 'bootstrap/store';
import { loginRouteStore } from './domain/store';
const usersStore = stores.global.users;
const { router } = stores;


const LoginContainer = () => {
  useEffect(() => {
    return () => {
      loginRouteStore.setInitialState();
    };
  }, []);

  const logIn = useCallback((e) => {
    e.preventDefault();

    loginRouteStore.incrementHttp('loggingIn');
    return usersStore.login({
      body: {
        email: loginRouteStore.email,
        password: loginRouteStore.password
      }
    })
    .then((response) => {
      if (usersStore.activeToken) {
        return router.history.push('/');
      } else if (response.authMultifactorCodeToken) {
        loginRouteStore.setValue('authMultifactorCodeToken', response.authMultifactorCodeToken);
      }
    })
    .catch((err) => {
      let errorMessage = 'Could not log in';
      if (err.status === 429) {
        errorMessage = 'Too many attempts. Please, try again later';
      }
      loginRouteStore.setValue('errorMessage', errorMessage);
    })
    .finally(() => loginRouteStore.decrementHttp('loggingIn'));
  }, []);

  const verifyLoginCode = useCallback((code) => {
    loginRouteStore.incrementHttp('verifyingLoginCode');
    return usersStore.verifyLoginCode(loginRouteStore.authMultifactorCodeToken, code)
    .then((response) => {
      if (response?.token?.access) {
        usersStore.setValue('activeToken', response.token.access);
        return router.history.push('/');
      }
    })
    .catch((err) => {
      let errorMessage = 'Could not log in';
      if (err.status === 429) {
        errorMessage = 'Too many attempts. Please, try again later';
      }
      loginRouteStore.setValue('errorMessage', errorMessage);
    })
    .finally(() => loginRouteStore.decrementHttp('verifyingLoginCode'));
  }, []);

  const resetLoginCodeVerification = useCallback(() => {
    loginRouteStore.setValue('authMultifactorCodeToken', null);
    loginRouteStore.setValue('errorMessage', '');
    loginRouteStore.setValue('http', {});
  }, []);

  return <LoginView
    loginRouteStore={loginRouteStore}
    logIn={logIn}
    verifyLoginCode={verifyLoginCode}
    resetLoginCodeVerification={resetLoginCodeVerification}
  />;
};

export default LoginContainer;
