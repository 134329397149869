import React from 'react';
import { observer } from 'mobx-react';

import ContentHeader from 'components/drill-down-layout/components/content/components/content-header';

import PatientIcon from 'assets/icons/patient.svg';


const MachineTechniciansContentHeader = ({
  machineTechniciansRouteStore,
}) => {
  const {
    activeTechnician,
    activeTechnicianKiosksCaption,
  } = machineTechniciansRouteStore;
  if (!activeTechnician) return null;

  return <ContentHeader
    title={`${activeTechnician.firstName} ${activeTechnician.lastName}`}
    subtitle={`Technician for ${activeTechnicianKiosksCaption}`}
    leftLabel={
      <img
        alt='technician'
        className='technician-view-icon-preview-large'
        src={PatientIcon}
      />
    }
  />;
};

export default observer(MachineTechniciansContentHeader);
