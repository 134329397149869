import deepFreeze from 'deep-freeze';

export const MODEL_NAME = Object.freeze({
  SINGULAR: 'kioskDrugThreshold',
  PLURAL: 'kiosk-drug-thresholds',
});

export const FIELD_NAME = Object.freeze({
  ID: 'id',
  LOW_CONTAINERS_COUNT: 'lowContainersCount',
  HIGH_CONTAINERS_COUNT: 'highContainersCount',
  DRUG_ID: 'drugId',
  KIOSK_ID: 'kioskId',
  CREATED_AT: 'createdAt',
  UPDATED_AT: 'updatedAt',
});

export const SCHEMA = deepFreeze({
  title: MODEL_NAME.SINGULAR,
  type: 'object',
  properties: {
    [FIELD_NAME.ID]: {
      type: 'string'
    },
    [FIELD_NAME.LOW_CONTAINERS_COUNT]: {
      type: 'number'
    },
    [FIELD_NAME.HIGH_CONTAINERS_COUNT]: {
      type: 'number'
    },
    [FIELD_NAME.DRUG_ID]: {
      type: 'string'
    },
    [FIELD_NAME.KIOSK_ID]: {
      type: 'string'
    },
    [FIELD_NAME.CREATED_AT]: {
      type: 'string'
    },
    [FIELD_NAME.UPDATED_AT]: {
      type: 'string'
    },
  }
});
