import { toJS } from 'mobx';

import { machineTechniciansRouteStore } from '../domain/store';
import { machineTechnicianUpsertModalRouteStore } from '../domain/upsert-modal-store';

import { MACHINE_TECHNICIAN_SKELETON } from '../constants';
import { TECHNICIAN_MANIPULATION_OPTIONS } from 'components/admin-technicians-view/constants';
const { CREATE, UPDATE } = TECHNICIAN_MANIPULATION_OPTIONS;

export default (e, purpose = UPDATE) => {
  switch (purpose) {
    case UPDATE:
      machineTechniciansRouteStore.setValue('activeTechnicianId', e.content.id);
      machineTechniciansRouteStore.setValue('showingUpsertModal', true);
      machineTechnicianUpsertModalRouteStore.setValue('activelyModifiedTechnician', toJS(machineTechniciansRouteStore.activeTechnician));
      break;
    case CREATE:
      machineTechniciansRouteStore.setValue('showingUpsertModal', true);
      machineTechnicianUpsertModalRouteStore.setValue('activelyModifiedTechnician', MACHINE_TECHNICIAN_SKELETON);
  }
};
