import React from 'react';
import { observer } from 'mobx-react';

import NotificationWrapper from '../notification-wrapper';
import Transfer from './components/transfer';

import './transfer-prescriptions-notifications-view-style.scss';


const TransferPrescriptionsNotificationsView = ({
  transfers,
  onClick,
}) => (
  <div className='c-TransferPrescriptionsNotifications'>
    {
      !transfers.length &&
      <div className='no-data-caption'>
        No Transfer Requests
      </div>
    }
    {
      transfers.map((prescriptionFill) => <NotificationWrapper
        key={prescriptionFill.id}
        cursorPointer={true}
      >
        <Transfer
          onClick={onClick}
          prescriptionFill={prescriptionFill}
        />
      </NotificationWrapper>)
    }
  </div>
);

export default observer(TransferPrescriptionsNotificationsView);
