import { get as _get } from 'lodash';

// Constants
import { PATIENTS_INCOMPLETE_INFO_ROUTE } from '../constants';

// Stores
import stores from 'bootstrap/store';
import { patientsIncompleteInfoRouteStore } from '../domain/store';
const patientsStore = stores.global.patients;

export default (patientId, navigate) => {
  const requestedPatientIsAvailable = !!patientsStore.dataForPharmacist.get(patientId);
  if (requestedPatientIsAvailable) {
    patientsIncompleteInfoRouteStore.setValue('activePatientId', patientId);
  } else {
    // TODO fetch info of the requested entry and show it as active once user scrolls to it
    const firstId = _get(patientsStore, 'dataForPharmacistList[0].id');
    if (firstId) {
      navigate(`${PATIENTS_INCOMPLETE_INFO_ROUTE}/${firstId}`);
    } else {
      patientsIncompleteInfoRouteStore.setValue('activePatientId', null);
    }
  }
};
