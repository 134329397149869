import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import NewPatientsNotificationsView from './new-patients-notifications-view';

import { NOTIFICATION_TYPE } from '../../../../constants';

import { fetchNotificationsRelatedData } from '../../../../helpers';

import stores from 'bootstrap/store';
import { goToReview } from './helpers';

const patientsStore = stores.global.patients;


const NewPatientsNotificationsContainer = () => {
  useEffect(() => {
    fetchNotificationsRelatedData(NOTIFICATION_TYPE.NEW_PATIENTS);
  }, []);

  return <NewPatientsNotificationsView
    pending={patientsStore.dataPendingList}
    onClick={goToReview}
  />;
};

export default observer(NewPatientsNotificationsContainer);
