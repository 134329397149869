import { listCallRequestsDebounce } from './index';

import stores from 'bootstrap/store';
import { callRequestsRouteStore } from '../domain/store';
const callRequestsStore = stores.global.callRequests;

const setFilterValue = (e) => {
  callRequestsRouteStore.setValue('filterValue', e.target.value);
  callRequestsRouteStore.setValue('currentPage', 1);
  callRequestsStore.setValue('dataForPharmacist', {});

  return listCallRequestsDebounce();
};

export default setFilterValue;
