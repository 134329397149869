import deepFreeze from 'deep-freeze';

export const MODEL_NAME = Object.freeze({
  SINGULAR: 'user',
  PLURAL: 'users'
});

export const FIELD_NAME = Object.freeze({
  ID: 'id',
  AUTH_USER_ID: 'authUserId',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  EMAIL: 'email',
  PASSWORD: 'password',
  WORK_PHONE: 'workPhone',
  CELL_PHONE: 'cellPhone',
  IS_ADMIN: 'isAdmin',
  PRIMARY_ROLE: 'primaryRole'
});

export const SCHEMA = deepFreeze({
  title: MODEL_NAME.SINGULAR,
  type: 'object',
  properties: {
    [FIELD_NAME.ID]: {
      type: 'string'
    },
    [FIELD_NAME.AUTH_USER_ID]: {
      type: 'string'
    },
    [FIELD_NAME.FIRST_NAME]: {
      type: 'string'
    },
    [FIELD_NAME.LAST_NAME]: {
      type: 'string'
    },
    [FIELD_NAME.IS_ADMIN]: {
      type: 'boolean'
    },
    [FIELD_NAME.PRIMARY_ROLE]: {
      type: 'string',
      required: false
    },
    [FIELD_NAME.EMAIL]: {
      type: 'string',
      form: {
        type: 'email',
        placeholder: 'email@someplace.com'
      }
    },
    [FIELD_NAME.PASSWORD]: {
      type: 'string',
      form: {
        type: 'password',
        placeholder: 'Password'
      }
    },
    [FIELD_NAME.WORK_PHONE]: {
      type: 'string',
      form: {
        type: 'phone',
        placeholder: '(000) 000-0000'
      }
    },
    [FIELD_NAME.CELL_PHONE]: {
      type: 'string',
      form: {
        type: 'phone',
        placeholder: '(000) 000-0000'
      }
    }
  },
  required: [
    // FIELD_NAME.ID,
    FIELD_NAME.FIRST_NAME,
    FIELD_NAME.LAST_NAME,
    FIELD_NAME.EMAIL
  ]
});
