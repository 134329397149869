/* eslint-disable no-unused-expressions */
import { types } from 'mobx-state-tree';
import { get as _get, set as _set, some as _some } from 'lodash';

import doesValueMatchRegex from 'utils/doesValueMatchRegex';

import { PRESCRIPTION_FILL_PARAMS } from '../constants';

export const PrescriptionFillsManualEntryRouteStore = types.model({
  kiosks: types.optional(types.array(types.frozen()), []),
  kioskOption: types.maybeNull(types.model({
    label: types.maybeNull(types.string),
    value: types.maybeNull(types.string),
  })),
  prescriptionFill: types.optional(types.model({
    copay: types.optional(types.string, ''),
    fillNumber: types.maybeNull(types.number),
  }), {}),
  prescription: types.optional(types.model({
    rxId: types.optional(types.string, ''),
    refills: types.maybeNull(types.number),
    directions: types.maybeNull(types.string),
  }), {}),
  drug: types.optional(types.model({
    name: types.optional(types.string, ''),
    NDC: types.optional(types.string, ''),
    quantity: types.optional(types.string, ''),
  }), {}),
  patient: types.optional(types.model({
    firstName: types.optional(types.string, ''),
    lastName: types.optional(types.string, ''),
    DOB: types.optional(types.string, ''),
    email: types.maybeNull(types.string),
    phoneNumber: types.maybeNull(types.string),
    externalPharmacyId: types.optional(types.string, ''),
  }), {}),
  physician: types.optional(types.model({
    firstName: types.optional(types.string, ''),
    lastName: types.optional(types.string, ''),
    NPI: types.optional(types.string, ''),
    SPI: types.optional(types.string, ''),
    stateLicenseNumber: types.optional(types.string, ''),
    externalPharmacyId: types.optional(types.string, ''),
  }), {}),
  http: types.optional(types.model({
    creatingPrescriptionFill: types.optional(types.number, 0),
    listingKioskOptions: types.optional(types.number, 0),
  }), {}),
  httpError: types.maybeNull(types.string),
  wasManualOrderFulfilled: types.maybeNull(types.boolean),
  suggestions: types.optional(types.model({
    patient: types.optional(types.model({
      firstName: types.optional(types.array(types.frozen()), []),
      lastName: types.optional(types.array(types.frozen()), []),
      externalPharmacyId: types.optional(types.array(types.frozen()), []),
    }), {}),
    drug: types.optional(types.model({
      name: types.optional(types.array(types.frozen()), []),
      NDC: types.optional(types.array(types.frozen()), []),
    }), {}),
    physician: types.optional(types.model({
      firstName: types.optional(types.array(types.frozen()), []),
      lastName: types.optional(types.array(types.frozen()), []),
      NPI: types.optional(types.array(types.frozen()), []),
      SPI: types.optional(types.array(types.frozen()), []),
      stateLicenseNumber: types.optional(types.array(types.frozen()), []),
      externalPharmacyId: types.optional(types.array(types.frozen()), []),
    }), {}),
  }), {}),
})
.actions(self => ({
  setValue: (path, value) => _set(self, path, value),
  setInitialState: () => {
    self.kioskOption = null;
    self.prescriptionFill = {};
    self.prescription = {};
    self.drug = {};
    self.patient = {};
    self.physician = {};
    self.http = {};
    self.httpError = null;
    self.wasManualOrderFulfilled = null;
    self.suggestions = {};
  },
  incrementHttp: (http) => ++self.http[http],
  decrementHttp: (http) => --self.http[http],
}))
.views(self => ({
  get isBusy() {
    return Boolean(self.http.creatingPrescriptionFill || self.http.listingKioskOptions);
  },
  get doesPhysicianHaveIdentifier() {
    return _some([
      self.physician.NPI,
      self.physician.SPI,
      self.physician.stateLicenseNumber,
      self.physician.externalPharmacyId,
    ]);
  },
  get canBeSubmitted() {
    // Track the updates of the fields below, because they are nested and cannot be tracked otherwise.
    self.prescriptionFill.copay;
    self.prescriptionFill.fillNumber;
    self.prescription.rxId;
    self.prescription.refills;
    self.prescription.directions;
    self.drug.name;
    self.drug.NDC;
    self.drug.quantity;
    self.patient.firstName;
    self.patient.lastName;
    self.patient.DOB;
    self.patient.email;
    self.patient.phoneNumber;
    self.patient.externalPharmacyId;
    self.physician.firstName;
    self.physician.lastName;
    self.physician.NPI;
    self.physician.SPI;
    self.physician.stateLicenseNumber;
    self.physician.externalPharmacyId;

    const hasAllRequiredParams = Object.entries(PRESCRIPTION_FILL_PARAMS).every(([path, { required, regex }]) => {
      const value = _get(self, path);
      const satisfiesRegexConstraint = doesValueMatchRegex(regex, value);
      if (!satisfiesRegexConstraint) {
        return false;
      }

      if (required) {
        return Boolean(value);
      } else {
        return true;
      }
    });

    return hasAllRequiredParams && self.doesPhysicianHaveIdentifier && self.kioskOption;
  },
  constructKioskOption: (kiosk) => ({
    label: `${kiosk.name} (${kiosk.location.street1}, ${kiosk.location.city}, ${kiosk.location.state}, ${kiosk.location.zip})`,
    value: kiosk.id,
  }),
}))
.views((self) => ({
  get kioskOptions() {
    return self.kiosks.map((kiosk) => self.constructKioskOption(kiosk));
  },
}));

export const prescriptionFillsManualEntryRouteStore = PrescriptionFillsManualEntryRouteStore.create({});
