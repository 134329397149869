import { types } from 'mobx-state-tree';
import { set as _set } from 'lodash';

export const DrugsRouteStore = types.model({
})
.props({
  drugsById: types.optional(types.map(types.frozen()), {}),
  entriesCount: types.optional(types.number, 0),
  filterValue: types.optional(types.string, ''),
  activeDrugId: types.maybeNull(types.string),
  sortingColumn: types.optional(types.model({
    fieldName: types.optional(types.string, 'name'),
    sortingOrder: types.optional(types.string, 'asc')
  }), {}),
  http: types.optional(types.model({
    listingForPharmacist: types.optional(types.number, 0),
    countingForPharmacist: types.optional(types.number, 0),
  }), {}),
  currentPage: types.optional(types.number, 1),
  showingDrugUpsertModal: types.optional(types.boolean, false)
})
.actions(self => ({
  setValue: (path, value) => _set(self, path, value),
  incrementHttp: (http) => ++self.http[http],
  decrementHttp: (http) => --self.http[http],
  setInitialState: () => {
    self.drugsById = {};
    self.entriesCount = 0;
    self.filterValue = '';
    self.activeDrugId = null;
    self.sortingColumn = {};
    self.http = {};
    self.currentPage = 1;
    self.showingDrugUpsertModal = false;
  }
}))
.views(self => ({
  get drugsList() {
    return Array.from(self.drugsById.values());
  },
}))
.views(self => ({
  get loading() {
    return !!(self.http.listingForPharmacist || self.http.countingForPharmacist);
  },
  get activeDrug() {
    return self.activeDrugId && self.drugsById.get(self.activeDrugId);
  },
  get forTable() {
    return self.drugsList.map((drug) => ({
      id: drug.id,
      name: drug.name || 'N/A',
      NDC: drug.NDC || 'N/A',
      strength: drug.activeNumeratorStrength && drug.activeIngredientUnit ? `${drug.activeNumeratorStrength}${drug.activeIngredientUnit}` : 'N/A',
      quantity: drug.quantity || 'N/A'
    }));
  },
}));

export const drugsRouteStore = DrugsRouteStore.create({});
