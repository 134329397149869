import { kioskContainersRouteStore } from '../domain/store';

import fetchActiveContainersDebounce from './fetchActiveContainersDebounce';
import fetchKioskInventoryDebounce from './fetchKioskInventoryDebounce';

const onContainerSearch = async (activeKiosk, e) => {
  kioskContainersRouteStore.setValue('filterValue', e.target.value);
  fetchActiveContainersDebounce(activeKiosk);
  fetchKioskInventoryDebounce(activeKiosk);
};

export default onContainerSearch;
