import React from 'react';
import { observer } from 'mobx-react';
import { get as _get } from 'lodash';

import Header from './components/header';
import CallRequest from './components/call-request';
import List from 'components/drill-down-layout/components/list';
import ListItem from 'components/drill-down-layout/components/list/components/list-item';

import PatientIcon from 'assets/icons/patient.svg';

import './patient-icon.scss';


const CallRequestsList = ({
  callRequestsStore,
  callRequestsRouteStore,
  setFilterValue,
  setActiveListTab,
  listCallRequests,
  rootRoute,
}) => (
  <List
    header={<Header
      filterValue={callRequestsRouteStore.filterValue}
      setFilterValue={setFilterValue}
      activeListTab={callRequestsRouteStore.activeListTab}
      setActiveListTab={setActiveListTab}
    />}
    isReady={!callRequestsRouteStore.http.listingCallRequests}
    onBottomThreshold={listCallRequests}
  >
    {
      callRequestsStore.dataForPharmacistList.map((callRequest) => {
        const patient = _get(callRequest, 'patientCallRequest.patient');
        const callRequestedBy = patient
          ? `${patient.firstName} ${patient.lastName}`
          : 'N/A';

        return <ListItem
          key={callRequest.id}
          title={callRequestedBy}
          isActive={callRequestsRouteStore.activeCallRequestId === callRequest.id}
          iconStyle={{width: '50px', height: '50px'}}
          icon={
            <img
              alt='Callback Request'
              className='patient-icon-preview'
              src={PatientIcon}
            />
          }
          url={`${rootRoute}/${callRequest.id}`}
        >
          <CallRequest
            callRequest={callRequest}
          />
        </ListItem>;
      })
    }
  </List>
);

export default observer(CallRequestsList);
