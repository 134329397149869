import React, { useCallback, useEffect } from 'react';

// Views
import ResetPasswordView from './reset-password-view';

// Store
import stores from 'bootstrap/store';
import { resetPasswordRouteStore } from './domain/store';
const usersStore = stores.global.users;


const ResetPasswordContainer = () => {
  useEffect(() => {
    return () => {
      resetPasswordRouteStore.setInitialState();
    };
  }, []);

  const resetPassword = useCallback((e) => {
    e.preventDefault();

    return usersStore.resetPassword({
      body: {
        email: resetPasswordRouteStore.email,
      }
    })
    .then(() => resetPasswordRouteStore.setValue('isSuccess', true))
    .catch(() => resetPasswordRouteStore.setValue('errorMessage', 'Could not reset password, email is invalid.'));
  }, []);

  return <ResetPasswordView
    resetPasswordRouteStore={resetPasswordRouteStore}
    resetPassword={resetPassword}
  />;
};

export default ResetPasswordContainer;
