import { flow } from 'mobx-state-tree';

import base from 'models/base';

import { MODEL_NAME, SCHEMA } from './constants/schema';


const UserClientPingsModel = base({
  names: {
    singular: MODEL_NAME.SINGULAR,
    plural: MODEL_NAME.PLURAL
  },
  JSONSchema: SCHEMA,
  httpConfig: {
    root: MODEL_NAME.plural,
    methods: {}
  }
});

UserClientPingsModel.configureStore((store) => {
  return store
  .props({
  })
  .actions(self => ({
    createUserClientPing: flow(function* (config = {}) {
      config.urlFragment = () => '/';

      const response = yield self.post(config);

      return response;
    }),
  }));
});


export default UserClientPingsModel;
