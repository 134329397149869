import React from 'react';
import { observer } from 'mobx-react';

import './user-type-switch-styles.scss';

const UserTypeSwitch = ({
  activeTab,
  setActiveTab,
  activeUserAccessibleRoutes,
}) => {
  const accessibleTabs = Object.keys(activeUserAccessibleRoutes);
  if (accessibleTabs.length < 2) return null;

  return <div className='c-UserTypeSwitch'>
    {
      accessibleTabs.map((tab) => (
        <button
          key={tab}
          className={`switch-user-button ${activeTab === tab && 'active'}`}
          onClick={() => setActiveTab(tab)}
        >
          {
            tab
          }
        </button>
      ))
    }
  </div>;
};

export default observer(UserTypeSwitch);
