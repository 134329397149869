import ROUTE from '../../config/permissions/routes';
import { FULL, READ, WRITE } from '../../constants/permissionTypes';


export const hasRoutePermissions = (permission, routeName, activeUserRoles = []) => {
  const route = ROUTE[routeName];
  if (!route) return false;

  const allowedRoles = route[permission] || route[FULL];
  const satisfiesAnySingleRole = activeUserRoles.some((role) => allowedRoles.has(role));
  if (satisfiesAnySingleRole) return true;

  // If doesn't satisfy any single role, check complex ones.
  return Array.from(allowedRoles).some((role) => {
    if (!Array.isArray(role)) return false;
    return role.every((role) => activeUserRoles.indexOf(role) !== -1);
  });
};

export const hasRouteReadPermissions = (routeName, activeUserRoles) => {
  return hasRoutePermissions(READ, routeName, activeUserRoles);
};

export const hasRouteWritePermissions = (routeName, activeUserRoles) => {
  return hasRoutePermissions(WRITE, routeName, activeUserRoles);
};
