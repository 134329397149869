import { listDrugs } from './index';

import { drugsRouteStore } from '../domain/store';

export default (fieldName, sortingOrder) => {
  drugsRouteStore.setValue('sortingColumn', {
    ...drugsRouteStore.sortingColumn,
    fieldName,
    sortingOrder,
  });

  return listDrugs();
};
