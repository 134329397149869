import deepFreeze from 'deep-freeze';

export const MODEL_NAME = Object.freeze({
  SINGULAR: 'patient',
  PLURAL: 'patients',
});

export const FIELD_NAME = Object.freeze({
  ID: 'id',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  EXTERNAL_PHARMACY_ID: 'externalPharmacyId',
  ADDRESS: 'address',
  DOB: 'DOB',
  GENDER: 'gender',
  PHARMACY_DATA_CAPTURED: 'pharmacyDataCaptured',
  REQUEST_FOR_PATIENT_DATA_CAPTURE: 'requestForPatientDataCapture',
  ALLERGIES: 'allergies',
  CREATED_AT: 'createdAt',
  UPDATED_AT: 'updatedAt',

  PATIENT_USER: 'patientUser',
  USER_ID: 'userId',

  USER: 'user',
  EMAIL: 'email',
  PHONE_NUMBER: 'phoneNumber',

  HEALTHCARE_SITES: 'healthcareSites',
  NAME: 'name',
  LOCATION: 'location',
  STREET1: 'street1',
  CITY: 'city',
  STATE: 'state',
  ZIP: 'zip',

  IMAGE_URLS: 'imageUrls',
  TYPE: 'type',
  URL: 'url',

  PATIENT_ADDRESSES: 'patientsAddresses',
});

export const SCHEMA = deepFreeze({
  title: MODEL_NAME.SINGULAR,
  type: 'object',
  properties: {
    [FIELD_NAME.ID]: {
      type: 'string',
    },
    [FIELD_NAME.FIRST_NAME]: {
      type: 'string'
    },
    [FIELD_NAME.LAST_NAME]: {
      type: 'string'
    },
    [FIELD_NAME.EMAIL]: {
      type: 'string'
    },
    [FIELD_NAME.PHONE_NUMBER]: {
      type: 'string'
    },
    [FIELD_NAME.ADDRESS]: {
      type: 'string'
    },
    [FIELD_NAME.EXTERNAL_PHARMACY_ID]: {
      type: 'string'
    },
    [FIELD_NAME.DOB]: {
      type: 'string'
    },
    [FIELD_NAME.GENDER]: {
      type: 'string'
    },
    [FIELD_NAME.PHARMACY_DATA_CAPTURED]: {
      type: 'boolean'
    },
    [FIELD_NAME.REQUEST_FOR_PATIENT_DATA_CAPTURE]: {
      type: 'boolean'
    },
    [FIELD_NAME.ALLERGIES]: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          name: {
            type: 'string'
          }
        }
      }
    },
    [FIELD_NAME.CREATED_AT]: {
      type: 'string'
    },
    [FIELD_NAME.UPDATED_AT]: {
      type: 'string'
    },
    [FIELD_NAME.PATIENT_USER]: {
      type: 'object',
      properties: {
        [FIELD_NAME.USER_ID]: {
          type: 'string'
        },
        [FIELD_NAME.USER]: {
          type: 'object',
          properties: {
            [FIELD_NAME.ID]: {
              type: 'string'
            },
            [FIELD_NAME.FIRST_NAME]: {
              type: 'string'
            },
            [FIELD_NAME.LAST_NAME]: {
              type: 'string'
            },
            [FIELD_NAME.EMAIL]: {
              type: 'string'
            },
            [FIELD_NAME.PHONE_NUMBER]: {
              type: 'string'
            },
          }
        }
      }
    },
    [FIELD_NAME.HEALTHCARE_SITES]: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          [FIELD_NAME.ID]: {
            type: 'string'
          },
          [FIELD_NAME.NAME]: {
            type: 'string'
          },
          [FIELD_NAME.PHONE_NUMBER]: {
            type: 'string'
          },
          [FIELD_NAME.LOCATION]: {
            type: 'object',
            properties: {
              [FIELD_NAME.STREET1]: {
                type: 'string',
              },
              [FIELD_NAME.CITY]: {
                type: 'string',
              },
              [FIELD_NAME.STATE]: {
                type: 'string',
              },
              [FIELD_NAME.ZIP]: {
                type: 'string',
              },
            },
          },
        },
      },
    },
    [FIELD_NAME.IMAGE_URLS]: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          [FIELD_NAME.TYPE]: {
            type: 'string',
          },
          [FIELD_NAME.URL]: {
            type: 'string'
          },
        }
      }
    },
    [FIELD_NAME.PATIENT_ADDRESSES]: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          [FIELD_NAME.ID]: {
            type: 'string',
          },
          [FIELD_NAME.LOCATION]: {
            type: 'object',
            properties: {
              [FIELD_NAME.STREET1]: {
                type: 'string',
              },
              [FIELD_NAME.CITY]: {
                type: 'string',
              },
              [FIELD_NAME.STATE]: {
                type: 'string',
              },
              [FIELD_NAME.ZIP]: {
                type: 'string',
              },
            },
          },
        }
      }
    },
  },
});
