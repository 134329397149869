import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';

import SendReceiptModalView from './send-receipt-modal-view';

import { sendReceiptModalRouteStore } from './domain/store';

import fetchPatientById from './helpers/fetchPatientById';
import sendReceipt from './helpers/sendReceipt';

const SendReceiptModalContainer = ({
  isOpen,
  prescriptionFillsFulfillmentId,
  patientId,
  onClose = () => {},
}) => {
  useEffect(() => {
    if (isOpen) {
      fetchPatientById(patientId);
    } else {
      sendReceiptModalRouteStore.setValue('patient', null);
    }
  }, [isOpen, patientId]);

  const closeModal = useCallback(() => {
    sendReceiptModalRouteStore.setInitialState();

    if (onClose) {
      return onClose();
    }
  }, [onClose]);

  return <SendReceiptModalView
    sendReceiptModalRouteStore={sendReceiptModalRouteStore}
    isOpen={isOpen}
    title='Send Receipt'
    onClose={closeModal}
    sendReceipt={() => sendReceipt(prescriptionFillsFulfillmentId)}
  />;
};

export default observer(SendReceiptModalContainer);
