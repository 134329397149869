import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import PhysiciansView from './physicians-view';

import * as helpers from './helpers';

import stores from 'bootstrap/store';
import { physiciansRouteStore } from './domain/store';
import { physicianUpsertModalRouteStore } from './components/physician-upsert-modal/domain/store';
const physiciansStore = stores.global.physicians;

const PhysiciansContainer = () => {
  useEffect(() => {
    helpers.listPhysicians();
  }, []);

  return (
    <PhysiciansView
      physiciansStore={physiciansStore}
      physiciansRouteStore={physiciansRouteStore}
      physicianUpsertModalRouteStore={physicianUpsertModalRouteStore}
      sortPhysicians={helpers.sortPhysicians}
      changePaginationPage={helpers.changePaginationPage}
      onPhysiciansSearch={helpers.onPhysiciansSearch}
      showPhysicianUpsertModal={helpers.showPhysicianUpsertModal}
      closePhysicianUpsertModal={helpers.closePhysicianUpsertModal}
      showPhysicianDeleteConfirmationModal={helpers.showPhysicianDeleteConfirmationModal}
      createPhysician={helpers.createPhysician}
      updatePhysician={helpers.updatePhysician}
      deletePhysician={helpers.deletePhysician}
    />
  );
};

export default observer(PhysiciansContainer);
