import React from 'react';
import { observer } from 'mobx-react';

import { getCallerInfo } from './helpers';

import './call-info-style.scss';

const CallInfo = ({ videoConference }) => {
  const {
    caller,
    purpose,
    requestedConsultationsCount,
    source,
  } = getCallerInfo(videoConference) || {};

  return (
    <div className='c-CallInfo'>
      <div className='caller'>
        {
          caller
        }
      </div>
      <div className='purpose'>
        {
          purpose
        }
      </div>
      <div className='source'>
        {
          source
        }
      </div>
      {
        !!requestedConsultationsCount &&
        <div className='source'>
          {
            `Consultation requested for ${requestedConsultationsCount} drug${requestedConsultationsCount > 1 ? 's' : ''}`
          }
        </div>
      }
    </div>
  );
};

export default observer(CallInfo);
