import { types } from 'mobx-state-tree';
import { toJS } from 'mobx';
import {
  difference as _difference,
  get as _get,
  set as _set
} from 'lodash';

import stores from 'bootstrap/store';

import PatientModel from 'models/patients';

export const PatientUpsertModalRouteStore = types.model({
})
.props({
  activelyModifiedPatient: types.maybeNull(types.frozen()),
  healthcareSiteFilter: types.optional(types.string, ''),
  showingDeleteConfirmationModal: types.optional(types.boolean, false)
})
.actions(self => ({
  setValue: (path, value) => _set(self, path, value),
  setModifiedPatient: (path, value) => {
    const fullPath = path ? `activelyModifiedPatient.${path}` : 'activelyModifiedPatient';
    if (!self[fullPath] && self[fullPath] !== false && !value) self.setValue(fullPath, null);
    else self.setValue(fullPath, value);
  },
  setInitialState() {
    self.setModifiedPatient('', null);
    self.healthcareSiteFilter = '';
    self.setValue('showingDeleteConfirmationModal', false);
  },
  addAllergy: allergy => {
    if (!_get(self, 'activelyModifiedPatient.allergies')) return;
    self.activelyModifiedPatient.allergies.push({name: allergy.text});
  },
  deleteAllergy: index => {
    if (!_get(self, 'activelyModifiedPatient.allergies')) return;
    self.activelyModifiedPatient.allergies.splice(index, 1);
  },
  addHealthcareSite: (kiosk) => {
    if (!self.activelyModifiedPatient) return;
    self.activelyModifiedPatient.healthcareSites = self.activelyModifiedPatient.healthcareSites || [];
    self.activelyModifiedPatient.healthcareSites.push(kiosk);
  },
  deleteHealthcareSite: (kiosk) => {
    self.activelyModifiedPatient.healthcareSites = self.activelyModifiedPatient.healthcareSites || [];
    const removalIndex = self.activelyModifiedPatient.healthcareSites.findIndex((technicianKiosk) => {
      return technicianKiosk.id === kiosk.id;
    });
    if (removalIndex !== -1) self.activelyModifiedPatient.healthcareSites.splice(removalIndex, 1);
  },
}))
.views(self => ({
  get editableAllergiesToTags() {
    if (!self.activelyModifiedPatient || !self.activelyModifiedPatient.allergies) return [];
    return self.activelyModifiedPatient.allergies.map(({ name }) => ({ id: name, text: name }));
  },
  get readyForCreation() {
    return !!(
      _get(self, 'activelyModifiedPatient.firstName') &&
      _get(self, 'activelyModifiedPatient.lastName') &&
      _get(self, 'activelyModifiedPatient.externalPharmacyId')
    );
  },
  get readyForUpdate() {
    const patientsRouteStore = stores.route.patients.domain;
    const { activePatient } = patientsRouteStore;
    const { activelyModifiedPatient } = self;
    if (!activePatient || !activelyModifiedPatient) return false;

    const initialAllergies = (activePatient.allergies || []).map((allergy) => allergy.name);
    const modifiedAllergies = (toJS(activelyModifiedPatient.allergies || [])).map((allergy) => allergy.name);

    const initialHealthcareSites = (activePatient.healthcareSites || []).map((healthcareSite) => healthcareSite.id);
    const modifiedHealthcareSites = (toJS(activelyModifiedPatient.healthcareSites || [])).map((healthcareSite) => healthcareSite.id);

    return !!(
      activePatient.firstName !== activelyModifiedPatient.firstName ||
      activePatient.lastName !== activelyModifiedPatient.lastName ||
      activePatient.externalPharmacyId !== activelyModifiedPatient.externalPharmacyId ||
      activePatient.DOB !== activelyModifiedPatient.DOB ||
      _difference(initialAllergies, modifiedAllergies).length ||
      _difference(modifiedAllergies, initialAllergies).length ||
      _difference(initialHealthcareSites, modifiedHealthcareSites).length ||
      _difference(modifiedHealthcareSites, initialHealthcareSites).length
    );
  },
  get suggestedHealthcareSites() {
    const { activelyModifiedPatient } = self;
    if (!activelyModifiedPatient) return [];
    const { healthcareSites } = activelyModifiedPatient;
    const modifiedHealthcareSiteIds = (healthcareSites || []).map((healthcareSite) => healthcareSite.id);

    const { forAdminList } = stores.global.healthcareSites;
    return forAdminList.filter((healthcareSite) => !modifiedHealthcareSiteIds.includes(healthcareSite.id));
  },
  get activePatientHealthcareSites() {
    const { activelyModifiedPatient } = self;
    if (!activelyModifiedPatient) return undefined;
    return activelyModifiedPatient.healthcareSites;
  },
}));

export const patientUpsertModalRouteStore = PatientUpsertModalRouteStore.create({});
