import { get as _get } from 'lodash';

// Constants
import * as ROUTE from 'constants/routes';
import { LOT_STATUS } from 'models/lots/constants';
import { MAX_ENTRIES_PER_PAGE } from '../constants';

// Stores
import stores from 'bootstrap/store';
import { lotsRouteStore } from '../domain/store';
const lotsStore = stores.global.lots;
const router = stores.router;

const listLots = async () => {
  const query = {
    status: LOT_STATUS.ACTIVE,
  };
  
  const filter = lotsRouteStore.filterValue;
  if (filter) {
    query.number = filter;
  }

  const config = {
    query: {
      offset: MAX_ENTRIES_PER_PAGE * (lotsRouteStore.currentPage - 1),
      limit: MAX_ENTRIES_PER_PAGE,
      ...query
    }
  };

  lotsRouteStore.incrementHttp('listingLots');
  return lotsStore.listForPharmacist(config)
  .catch(() => router.history.push(ROUTE.LOTS.VALUE))
  .then((response) => {
    const responseLength = _get(response, 'data.length', 0);
    if (responseLength > MAX_ENTRIES_PER_PAGE - 1) {
      lotsRouteStore.setValue('currentPage', lotsRouteStore.currentPage + 1);
    }
  })
  .finally(() => lotsRouteStore.decrementHttp('listingLots'));
};

export default listLots;
