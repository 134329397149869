import Promise from 'bluebird';
import param from 'jquery-param';
import { isString as _isString } from 'lodash';
import 'whatwg-fetch'; // Polyfill for fetch
import 'yet-another-abortcontroller-polyfill'; // Polyfill for aborting requests


// This establishes a private namespace.
const namespace = new WeakMap();
function p(object) {
  if (!namespace.has(object)) namespace.set(object, {});
  return namespace.get(object);
}

// Use native browser implementation if it supports aborting.
const abortableFetch = ('signal' in new Request('')) ? window.fetch : fetch;

/**
 *
 */
class Http {
  constructor() {
    p(this).preRequests = new Set();
    p(this).postResponse = {
      successes: new Set(),
      errors: new Set()
    };
  }
  
  addPreRequest(func) {
    p(this).preRequests.add(func);
  }
  
  addPostResponse(func) {
    p(this).postResponse.successes.add(func);
  }
  
  addPostResponseError(func) {
    p(this).postResponse.errors.add(func);
  }
  
  generateQueryParams(req) {
    if (Object.keys(req.query || {}).length) {
      req.url += `?${param(req.query)}`;
    }
    return req.url;
  }
  
  get(req = {}) {
    req.method = 'GET';
    req.url = this.generateQueryParams(req);
    return this._wrap(req, () => abortableFetch(req.url, req));
  }
  
  post(req = {}) {
    req.method = 'POST';
    return this._wrap(req, () => abortableFetch(req.url, req));
  }
  
  put(req = {}) {
    req.method = 'PUT';
    return this._wrap(req, () => abortableFetch(req.url, req));
  }
  
  delete(req = {}) {
    req.method = 'DELETE';
    return this._wrap(req, () => abortableFetch(req.url, req));
  }
  
  _wrap(payload, func) {
    payload = payload || {};
    payload.headers = payload.headers || {};
    payload.headers['Content-Type'] = 'application/json';
    if (payload.body && !_isString(payload.body)) payload.body = JSON.stringify(payload.body);

    
    let chain = Promise.resolve(payload);
    // Call prerequests.
    p(this).preRequests.forEach(func => {
      chain = chain.then(result => func(result || payload));
    });
    
    
    let res;
    // Make actual HTTP call.
    chain = chain.then(result => func(result || payload))
    .then(response => {
      res = response;
      if (response.status >= 400) {
        let err = new Error(response.statusText || `Error response status code: ${res.status}`);
        err.status = response.status;
        err.res = res;
        return response.text()
        .then((text) => {
          if (!response.statusText && text) err.message = text;
          response.text = text;
          return Promise.reject(err);
        });
      }

      if (response.headers.get('Content-Type') === 'image/png') return response.arrayBuffer();

      return response.json()
      .then(json => {
        response.json = json;
        return payload.fullResponse ? response : json;
      });
    })
    .catch((e) => {
      if (e.name === 'AbortError' && payload.controlledAbort) {
        return console.log('Request was aborted by user.');
      }

      return Promise.reject(e);
    });
    
    // Call postrequests.
    chain = chain.then(response => {
      let chain = Promise.resolve(response);
      p(this).postResponse.successes.forEach(func => {
        chain = chain.then(result => func(result || response));
      });
      return chain;
    });
    chain = chain.catch(err => {
      let chain = Promise.reject(err);
      p(this).postResponse.errors.forEach(func => {
        chain = chain.catch(err => func(err));
      });
      return chain;
    });
    
    return Promise.resolve(chain);
  }
}

export default Http;
