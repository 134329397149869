import React from 'react';
import { observer } from 'mobx-react';

import Header from './components/header';
import Patient from './components/patient';
import List from 'components/drill-down-layout/components/list';
import ListItem from 'components/drill-down-layout/components/list/components/list-item';

import PatientIcon from 'assets/icons/patient.svg';

import './patient-icon.scss';


const PatientsList = ({
  patientsStore,
  patientsListRouteStore,
  setFilterValue,
  setActivePatientListTab,
  listPatients,
  rootRoute,
}) => (
  <List
    header={<Header
      filterValue={patientsListRouteStore.filterValue}
      setFilterValue={setFilterValue}
      activePatientListTab={patientsListRouteStore.activePatientListTab}
      setActivePatientListTab={setActivePatientListTab}
    />}
    isReady={!patientsListRouteStore.http.listingPatients || patientsStore.dataForPharmacist.size}
    onBottomThreshold={listPatients}
  >
    {
      patientsStore.dataForPharmacistList.map((patient) => (
        <ListItem
          key={patient.id}
          title={`${patient.firstName} ${patient.lastName}`}
          isActive={patientsListRouteStore.activePatientId === patient.id}
          iconStyle={{width: '50px', height: '50px'}}
          icon={
            <img
              alt='Patient'
              className='patient-icon-preview'
              src={PatientIcon}
            />
          }
          url={`${rootRoute}/${patient.id}`}
        >
          <Patient
            patient={patient}
          />
        </ListItem>
      ))
    }
  </List>
);

export default observer(PatientsList);
