import React from 'react';
import { observer } from 'mobx-react';
import { get as _get, map as _map } from 'lodash';
import { Stage, Layer, Rect, Circle } from 'react-konva';
import { Element } from 'react-scroll';
import { isAlive } from 'mobx-state-tree';

import ModuleInfo from './components/module-template-info';
import Slot from './components/slot';

import * as CONSTANTS from './constants';

import './modules-template.scss';

const ActiveModuleTemplate = ({
  moduleTemplatesRouteStore,
}) => {
  return (
    isAlive(moduleTemplatesRouteStore.activeModifiedModuleTemplate) &&
    <div className='active-module-template-view'>
      <div className='active-module-template-view-data'>
        <Stage
          className='module-template-silhouette'
          width={Number(moduleTemplatesRouteStore.activeModifiedModuleTemplate.width) * CONSTANTS.MODULE_LAYOUT.DISPLAY_COEFFICIENT}
          height={Number(moduleTemplatesRouteStore.activeModifiedModuleTemplate.height) * CONSTANTS.MODULE_LAYOUT.DISPLAY_COEFFICIENT}
        >
          {/* KIOSK silhouette layer */}
          <Layer>
            {/* Silhouette */}
            <Rect
              x={CONSTANTS.MODULE_LAYOUT.SILHOUETTE.X}
              y={CONSTANTS.MODULE_LAYOUT.SILHOUETTE.Y}
              width={Number(moduleTemplatesRouteStore.activeModifiedModuleTemplate.width) * CONSTANTS.MODULE_LAYOUT.DISPLAY_COEFFICIENT}
              height={Number(moduleTemplatesRouteStore.activeModifiedModuleTemplate.height) * CONSTANTS.MODULE_LAYOUT.DISPLAY_COEFFICIENT}
              cornerRadius={CONSTANTS.MODULE_LAYOUT.SILHOUETTE.CORNER_RADIUS}
              fill={CONSTANTS.MODULE_LAYOUT.SILHOUETTE.COLOR}
            />
          </Layer>

          {/* KIOSK modules layer */}
          <Layer>
            {
              _map(_get(moduleTemplatesRouteStore, 'activeModifiedModuleTemplate.moduleSlotTemplates'), (slot, index) => {
                return slot.slot_template.containerConstraintRadiusMax ?
                  <Circle
                    key={index}
                    draggable={true}
                    x={Number(slot.x * CONSTANTS.MODULE_LAYOUT.DISPLAY_COEFFICIENT)}
                    y={Number(slot.y * CONSTANTS.MODULE_LAYOUT.DISPLAY_COEFFICIENT)}
                    onDragStart={() => moduleTemplatesRouteStore.setActiveModifiedSlotIndex({slotIndex: index})}
                    onDragMove={event => moduleTemplatesRouteStore.setDraggedSlotPositionCircle({
                      slot,
                      coordinates: {
                        x: Number(event.target.attrs.x / CONSTANTS.MODULE_LAYOUT.DISPLAY_COEFFICIENT),
                        y: Number(event.target.attrs.y / CONSTANTS.MODULE_LAYOUT.DISPLAY_COEFFICIENT)
                      }
                    })}
                    radius={slot.slot_template.containerConstraintRadiusMax * CONSTANTS.MODULE_LAYOUT.DISPLAY_COEFFICIENT}
                    fill={index === moduleTemplatesRouteStore.modifiedSlotIndex
                      ? CONSTANTS.MODULE_LAYOUT.SLOTS.COLOR_ACTIVE
                      : CONSTANTS.MODULE_LAYOUT.SLOTS.COLOR
                    }
                    onClick={() => moduleTemplatesRouteStore.setActiveModifiedSlotIndex({slotIndex: index})}
                  />
                  : <Rect
                    key={index}
                    draggable={true}
                    x={Number(slot.x * CONSTANTS.MODULE_LAYOUT.DISPLAY_COEFFICIENT - slot.slot_template.containerConstraintWidthMax * CONSTANTS.MODULE_LAYOUT.DISPLAY_COEFFICIENT / 2)}
                    y={Number(slot.y * CONSTANTS.MODULE_LAYOUT.DISPLAY_COEFFICIENT - slot.slot_template.containerConstraintHeightMax * CONSTANTS.MODULE_LAYOUT.DISPLAY_COEFFICIENT / 2)}
                    onDragStart={() => moduleTemplatesRouteStore.setActiveModifiedSlotIndex({slotIndex: index})}
                    onDragMove={event => moduleTemplatesRouteStore.setDraggedSlotPositionRect({
                      slot,
                      coordinates: {
                        x: Number(event.target.attrs.x / CONSTANTS.MODULE_LAYOUT.DISPLAY_COEFFICIENT + slot.slot_template.containerConstraintWidthMax * CONSTANTS.MODULE_LAYOUT.DISPLAY_COEFFICIENT / 2),
                        y: Number(event.target.attrs.y / CONSTANTS.MODULE_LAYOUT.DISPLAY_COEFFICIENT + slot.slot_template.containerConstraintHeightMax * CONSTANTS.MODULE_LAYOUT.DISPLAY_COEFFICIENT / 2)
                      }
                    })}
                    width={slot.slot_template.containerConstraintWidthMax * CONSTANTS.MODULE_LAYOUT.DISPLAY_COEFFICIENT}
                    height={slot.slot_template.containerConstraintHeightMax * CONSTANTS.MODULE_LAYOUT.DISPLAY_COEFFICIENT}
                    fill={index === moduleTemplatesRouteStore.modifiedSlotIndex
                      ? CONSTANTS.MODULE_LAYOUT.SLOTS.COLOR_ACTIVE
                      : CONSTANTS.MODULE_LAYOUT.SLOTS.COLOR
                    }
                    onClick={() => moduleTemplatesRouteStore.setActiveModifiedSlotIndex({slotIndex: index})}
                  />;
              })
            }
          </Layer>
        </Stage>

        <div className='vertical-line' />

        <div className='modules-manipulation-bar'>
          <ModuleInfo
            moduleTemplatesRouteStore={moduleTemplatesRouteStore}
            moduleTemplate={moduleTemplatesRouteStore.activeModifiedModuleTemplate}
          />
          <div className='slots-header-container'>
            <div className='header'>{`Slots (${_get(moduleTemplatesRouteStore, 'activeModifiedModuleTemplate.moduleSlotTemplates.length')})`}</div>
            <div className='add-slot-btn' onClick={() => moduleTemplatesRouteStore.addSlot()}>
              <div className='plus-sign'>+</div>
              <div className='text'>Add Slot</div>
            </div>
          </div>
          <div className='horizontal-line'/>
          <div className='slots-list' id='slotsList'>
            {
              _map(_get(moduleTemplatesRouteStore, 'activeModifiedModuleTemplate.moduleSlotTemplates'), (slot, index) => (
                <React.Fragment key={index.toString()}>
                  <Element name={index.toString()} />
                  <Slot
                    moduleTemplatesRouteStore={moduleTemplatesRouteStore}
                    slot={slot}
                    index={index}
                  />
                </React.Fragment>
              ))
            }
          </div>
        </div>
      </div>
    </div>
  );
};


export default observer(ActiveModuleTemplate);
