import React from 'react';
import { observer } from 'mobx-react';
import { get as _get } from 'lodash';

import CaptionValueInput from 'components/caption-value-input';
import Modal from 'components/modal';
import ModalEditingAreas from 'components/modal/layout/editing-areas';
import ModalEditingArea from 'components/modal/layout/editing-areas/components/editing-area';
import ModalRow from 'components/modal/layout/row';
import ModalButton from 'components/modal/layout/button';

import { NDC_REG_EXP, NDC_11_DIGIT_BILLING } from 'models/drugs/constants';

import './drug-upsert-modal-view-styles.scss';

const DrugUpsertModalView = ({
  drugUsertModalRouteStore,
  isOpen,
  title,
  isUpdateMode,
  tryFormatNDC,
  upsertDrug,
  deleteDrug,
  onClose,
}) => {
  return (
    <Modal
      modalStyles={{ height: '50%', width: '50%' }}
      isOpen={isOpen}
      title={title}
      shouldCloseOnOverlayClick={true}
      close={onClose}
    >
      <ModalEditingAreas>
        <ModalEditingArea>
          <ModalRow>
            <CaptionValueInput
              inputFieldClassNames={['max-width']}
              caption='Name'
              value={_get(drugUsertModalRouteStore, 'activelyModifiedDrug.name')}
              onChange={(value) => drugUsertModalRouteStore.setActivelyModifiedDrug({ name: value })}
            />
          </ModalRow>
          {
            !isUpdateMode &&
            <ModalRow>
              <CaptionValueInput
                inputFieldClassNames={['max-width']}
                caption='NDC'
                value={_get(drugUsertModalRouteStore, 'activelyModifiedDrug.NDC')}
                validationRegex={[NDC_11_DIGIT_BILLING, NDC_REG_EXP]}
                validationError='xxxx(x)-xxx(x)-x(x) or xxxxxxxxxxx'
                onChange={(value) => drugUsertModalRouteStore.setActivelyModifiedDrug({ NDC: value })}
                onBlur={() => tryFormatNDC()}
              />
            </ModalRow>
          }
        </ModalEditingArea>
        {
          !isUpdateMode &&
          <ModalEditingArea>
            <ModalRow>
              <CaptionValueInput
                inputFieldClassNames={['max-width']}
                caption='Strength'
                value={_get(drugUsertModalRouteStore, 'activelyModifiedDrug.strength')}
                onChange={(value) => drugUsertModalRouteStore.setActivelyModifiedDrug({ strength: value })}
              />
            </ModalRow>
            <ModalRow>
              <CaptionValueInput
                inputFieldClassNames={['max-width']}
                caption='Quantity'
                value={_get(drugUsertModalRouteStore, 'activelyModifiedDrug.quantity')}
                onChange={(value) => drugUsertModalRouteStore.setActivelyModifiedDrug({ quantity: value })}
              />
            </ModalRow>
          </ModalEditingArea>
        }
      </ModalEditingAreas>
      <ModalRow shouldBoundToBottom={true}>
        <ModalButton
          color='blue'
          text='Save'
          isDisabled={!drugUsertModalRouteStore.canBeSubmitted(isUpdateMode)}
          onClick={upsertDrug}
        />
        {
          isUpdateMode &&
          <ModalButton
            color='red'
            text='Delete'
            onClick={deleteDrug}
          />
        }
        {
          drugUsertModalRouteStore.httpError &&
          <div className='drug-upsert-error'>
            {
              drugUsertModalRouteStore.httpError
            }
          </div>
        }
      </ModalRow>
    </Modal>
  );
};

export default observer(DrugUpsertModalView);
