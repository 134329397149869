import { toJS } from 'mobx';

import stores from 'bootstrap/store';
import { patientUpsertModalRouteStore } from '../domain/upsert-modal-store';
const healthcareSitesStore = stores.global.healthcareSites;

export default (healthcareSite) => {
  patientUpsertModalRouteStore.addHealthcareSite(toJS(healthcareSite));
  patientUpsertModalRouteStore.setValue('healthcareSiteFilter', '');
  healthcareSitesStore.setValue('dataForAdmin', {});
};
