// Constants
import * as ROUTES from 'constants/routes';

// Stores
import stores from 'bootstrap/store';
import { notificationsPaneRouteStore } from '../../../../../domain/store';
const patientsStore = stores.global.patients;
const history = stores.router;


export default (patientId) => {
  patientsStore.listForPharmacist({
    query: { externalPharmacyId: false },
  })
  .then(() => history.push(`${ROUTES.PATIENTS.VALUE}${ROUTES.PATIENTS.SUB_ROUTE.LIST_OVERVIEW.VALUE}/${patientId}`));

  notificationsPaneRouteStore.close();
};
