import deepFreeze from 'deep-freeze';

export const MODEL_NAME = Object.freeze({
  SINGULAR: 'prescriptionFillKioskRouting',
  PLURAL: 'prescription-fill-kiosk-routings',
});

export const FIELD_NAME = Object.freeze({
  ID: 'id',
  DISMISSED: 'dismissed',
  COMPUTED_ACTIVE: 'computed_active',
  PRESCRIPTION_FILL_ID: 'prescriptionFillId',
  LOCATION_ID: 'kioskId',
  CREATED_AT: 'createdAt',
  UPDATED_AT: 'updatedAt'
});

export const SCHEMA = deepFreeze({
  title: MODEL_NAME.SINGULAR,
  type: 'object',
  properties: {
    [FIELD_NAME.ID]: {
      type: 'string'
    },
    [FIELD_NAME.DISMISSED]: {
      type: 'boolean'
    },
    [FIELD_NAME.COMPUTED_ACTIVE]: {
      type: 'boolean'
    },
    [FIELD_NAME.PRESCRIPTION_FILL_ID]: {
      type: 'string'
    },
    [FIELD_NAME.LOCATION_ID]: {
      type: 'string'
    },
    [FIELD_NAME.CREATED_AT]: {
      type: 'string'
    },
    [FIELD_NAME.UPDATED_AT]: {
      type: 'string'
    }
  },
  required: [
    FIELD_NAME.ID,
    FIELD_NAME.DISMISSED,
    FIELD_NAME.COMPUTED_ACTIVE,
    FIELD_NAME.PRESCRIPTION_FILL_ID,
    FIELD_NAME.LOCATION_ID,
    FIELD_NAME.CREATED_AT,
    FIELD_NAME.UPDATED_AT
  ]
});
