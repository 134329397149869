import React from 'react';
import { observer } from 'mobx-react';

// Components
import Content from './components/content';
import DrillDownLayout from 'components/drill-down-layout';
import List from './components/list';
import UpsertModal from './components/healthcare-site-upsert-modal';

// Styles
import './healthcare-sites-view.scss';

const HealthcareSitesView  = ({
  healthcareSitesRouteStore,
  healthcareSitesStore,
  onListBottomThreshold,
  setFilterValue,
  locationsStore,
  createHealthcareSite,
  showHealthcareSiteUpsertModal,
  closeHealthcareSiteUpsertModal,
  pharmaciesStore,
  rootRoute,
}) => {
  return <div className='r-HealthcareSitesView'>
    <DrillDownLayout>
      <UpsertModal
        healthcareSitesRouteStore={healthcareSitesRouteStore}
        locationsStore={locationsStore}
        pharmaciesStore={pharmaciesStore}
        createHealthcareSite={createHealthcareSite}
        closeHealthcareSiteUpsertModal={closeHealthcareSiteUpsertModal}
      />
      <List
        healthcareSitesRouteStore={healthcareSitesRouteStore}
        groupedHealthcareSites={healthcareSitesStore.forPharmacistGrouped}
        setFilterValue={setFilterValue}
        onListBottomThreshold={onListBottomThreshold}
        showHealthcareSiteUpsertModal={showHealthcareSiteUpsertModal}
        rootRoute={rootRoute}
      />
      <Content
        activeHealthcareSite={healthcareSitesRouteStore.activeHealthcareSite}
      />
    </DrillDownLayout>
  </div>;
};


export default observer(HealthcareSitesView);
