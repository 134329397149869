import stores from 'bootstrap/store';

import { EVENT } from '../../constants';
import notifications from 'bootstrap/notifications';

import {notificationsPaneRouteStore} from '../../../../app/root/routes/app-frame/views/notifications-pane/domain/store';
import { NOTIFICATION_TYPE } from '../../../../app/root/routes/app-frame/views/notifications-pane/constants';

export default ({ apiWebSocket }) => {
  const videoConferencesStore = stores.global.videoConferences;

  apiWebSocket.on(EVENT.RECEIVED.VIDEO_CONFERENCE_INVITATION_CREATE, () => {

    videoConferencesStore.listActive()
    .then(() => {
      notifications.create('New video conference call', {onClickCallback: () => {
        if (notificationsPaneRouteStore.activeTab !== NOTIFICATION_TYPE.VIDEO_CONFERENCES)

          notificationsPaneRouteStore.setActiveTab(NOTIFICATION_TYPE.VIDEO_CONFERENCES);
      }});
    });
  });
};
