export const MAX_ENTRIES_PER_PAGE = 20;

export const CONSULTATION_REASONS = [
  { label: 'Custom', value: '' },
  { label: 'New drug', value: 'Some required drugs are not listed in the prescription.' },
  { label: 'New dose', value: 'Prescription has a wrong dose specified.' },
  { label: 'New sig', value: 'Physician\'s sig should be updated/rewritten.' },
];

export const FULFILLMENT_TYPE = Object.freeze({
  DECLINED: 'DECLINE',
  DISPENSE: 'DISPENSE',
  MAIL_ORDER: 'MAIL_ORDER',
  TRANSFER: 'TRANSFER',
});
