import React from 'react';
import moment from 'moment';
import { observer } from 'mobx-react';

import './simplified-inventory-styles.scss';

const SimplifiedInventory = ({ drugs, kioskName }) => {
  const currentDate = moment().format('MM/DD/YYYY');

  return <div id='simplified-inventory-report' className='c-SimplifiedInventory'>
    <div className='title-container'>
      <div className='title'>
        {
          `${kioskName?.toUpperCase()} ASSIGNABLE INVENTORY ${currentDate}`
        }
      </div>
    </div>
    <div className='inventory-container'>
      {
        drugs.map((drug) => (
          <div key={drug.id} className='drug-container'>
            {
              drug.quantityOptions.map(({ quantity, totalCount }, index) => {
                const rowKey = `${drug.id}-${quantity}`;
                const rowClassName = drug.quantityOptions.length > 1 ? 'row-multiple-quantities' : 'row-single-quantity';
                const quantityText = `${totalCount} BOTTLES OF ${quantity}`;

                if (index) {
                  return <div key={rowKey} className={rowClassName}>
                    <div className='name' />
                    <div className='NDC' />
                    <div className='quantity'>
                      {
                        quantityText
                      }
                    </div>
                  </div>;
                } else {
                  return <div key={rowKey} className={rowClassName}>
                    <div className='name'>
                      {
                        drug.name.toUpperCase()
                      }
                    </div>
                    <div className='NDC'>
                      {
                        `NDC:${drug.NDC}`
                      }
                    </div>
                    <div className='quantity'>
                      {
                        quantityText
                      }
                    </div>
                  </div>;
                }
              })
            }
          </div>
        ))
      }
    </div>
  </div>;
};

export default observer(SimplifiedInventory);
