import {
  closeMedicalAssistantUpsertModal,
  listMedicalAssistants,
} from './index';

import stores from 'bootstrap/store';
import { medicalAssistantsUpsertModalRouteStore } from '../domain/upsert-modal-store';
const medicalAssistantsStore = stores.global.medicalAssistants;

export default () => {
  const { activelyModifiedMedicalAssistant } = medicalAssistantsUpsertModalRouteStore;
  return medicalAssistantsStore.createMedicalAssistant({
    body: activelyModifiedMedicalAssistant,
  })
  .then((medicalAssistant = {}) => {
    const medicalAssistantId = medicalAssistant.id;
    const { healthcareSites = [] } = activelyModifiedMedicalAssistant;
    const addHealthcareSitePromises = healthcareSites.map((healthcareSite) => medicalAssistantsStore.addHealthcareSite({
      params: {
        medicalAssistantId,
        healthcareSiteId: healthcareSite.id,
      },
    }));

    return Promise.all(addHealthcareSitePromises);
  })
  .then(() => {
    closeMedicalAssistantUpsertModal();
    return listMedicalAssistants();
  });
};
