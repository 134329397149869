import { get as _get } from 'lodash';

import stores from 'bootstrap/store';

import { EVENT } from '../../constants';
import { PURPOSE } from 'models/video-conferences/constants';


export default ({ apiWebSocket }) => {
  const store = {
    dispenseAttempts: stores.global.dispenseAttempts,
    notifications: stores.global.notifications,
    videoConferences: stores.global.videoConferences,
    videoConferenceInvitations: stores.global.videoConferenceInvitations,
  };

  const forbidRemoteDispense = (payload) => {
    const { videoConferenceParticipantConnection } = payload;
    const disconnectedUserId = _get(videoConferenceParticipantConnection, 'user.id');

    const conference = store.videoConferences.activePendingConference;
    const callerUserId = _get(conference, 'videoConferenceInvitations[0].callerUser.id'); // caller is KIOSK

    // Forbid remote dispense if KIOSK left the call
    if (callerUserId === disconnectedUserId) {
      store.dispenseAttempts.setValue('remoteDispense.allow', false);
    }
  };

  const listActiveVideoConferences = (payload) => {
    const videoConferenceId = _get(payload, 'videoConference.id');
    const { activePendingVideoConferenceId } = store.videoConferences;
    // If someone disconnected from an active video conference, it is possible that current user is the only one left
    // in the conference, so all data about the call will be lost after listing active conferences.
    if (activePendingVideoConferenceId !== videoConferenceId) return store.videoConferences.listActive();
  };

  const HANDLER = {
    [PURPOSE.PRESCRIPTION_CONSULTATION]: (payload) => {
      forbidRemoteDispense(payload);
      listActiveVideoConferences(payload);
    },
    [PURPOSE.GENERAL_PURPOSE]: (payload) => {
      listActiveVideoConferences(payload);
    },
  };


  apiWebSocket.on(EVENT.RECEIVED.VIDEO_CONFERENCE_PARTICIPANT_CONNECTION_DISCONNECT, (payload = {}) => {
    const purpose = _get(payload, 'videoConference.purpose.name');
    const handler = HANDLER[purpose];
    if (!handler) return;
    return handler(payload);
  });
};
