import {
  closeHealthcareSiteUpsertModal,
  listHealthcareSites,
} from './index';

import stores from 'bootstrap/store';
import { healthcareSitesRouteStore } from '../domain/store';
import { healthcareSiteUpsertModalRouteStore } from '../components/healthcare-site-upsert-modal/domain/store';
import {toJS} from 'mobx';
const healthcareSitesStore = stores.global.healthcareSites;

export default () => {
  const body = {
    ...toJS(healthcareSiteUpsertModalRouteStore.activelyModifiedHealthcareSite),
    placeId: healthcareSiteUpsertModalRouteStore.locationSelector.value,
    pharmacyId: healthcareSiteUpsertModalRouteStore.pharmacySelector.value,
  };

  healthcareSitesRouteStore.incrementHttp('healthcareSiteUpsert');
  return healthcareSitesStore.upsertHealthcareSite({ body })
  .then(() => {
    closeHealthcareSiteUpsertModal();
    return listHealthcareSites();
  })
  .finally(() => {
    healthcareSitesRouteStore.decrementHttp('healthcareSiteUpsert');
  });
};
