import { SPECIAL_TYPE } from './constants';

import phone from './phone';
import date from './date';
import dateTime from './date-time';
import listInput from './list-input';
import select from './select';

export default {
  [SPECIAL_TYPE.PHONE]: phone,
  [SPECIAL_TYPE.DATE]: date,
  [SPECIAL_TYPE.DATE_TIME]: dateTime,
  [SPECIAL_TYPE.LIST_INPUT]: listInput,
  [SPECIAL_TYPE.SELECT]: select,
};
