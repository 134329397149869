import React from 'react';
import { observer } from 'mobx-react';

import KeycardUpsertModal from './components/keycard-upsert-modal';

import AdminTableLayout from 'components/admin-table-layout';
import Title from 'components/admin-table-layout/components/title';
import TableHeader from 'components/admin-table-layout/components/table-header';
import EntriesCount from 'components/admin-table-layout/components/table-header/components/entries-count';
import Paginator from 'components/paginator';
import NewEntryButton from 'components/admin-table-layout/components/table-header/components/new-entry-button';
import ListSearch from 'components/drill-down-layout/components/list/components/list-header/components/list-search';
import TableContent from 'components/admin-table-layout/components/table-content';
import SmartTable from 'components/smart-table';

import {
  COLUMNS,
  KEYCARD_MANIPULATION_OPTIONS
} from './constants';
import { MAX_TABLE_ENTRIES_PER_PAGE } from 'models/keycards/constants';

import './keycards-style.scss';

const { CREATE, UPDATE } = KEYCARD_MANIPULATION_OPTIONS;

const KeycardsView = ({
  keycardsStore,
  keycardsRouteStore,
  keycardUpsertModalRouteStore,
  sortKeycards,
  changePaginationPage,
  onKeycardsSearch,
  showKeycardUpsertModal,
  closeKeycardUpsertModal,
  createKeycard,
  updateKeycard,
  resetPin,
  listUsers,
  listUsersDebounce,
  onSelectUser,
  listPharmacies,
  listPharmaciesDebounce,
}) => (
  <React.Fragment>
    <KeycardUpsertModal
      keycardUpsertModalRouteStore={keycardUpsertModalRouteStore}
      keycardsRouteStore={keycardsRouteStore}
      createKeycard={createKeycard}
      updateKeycard={updateKeycard}
      closeKeycardUpsertModal={closeKeycardUpsertModal}
      resetPin={resetPin}
      listUsers={listUsers}
      listUsersDebounce={listUsersDebounce}
      onSelectUser={onSelectUser}
      listPharmacies={listPharmacies}
      listPharmaciesDebounce={listPharmaciesDebounce}
    />
    <AdminTableLayout customClassName='c-KeycardsView'>
      <Title
        customClassName='view-title'
        text='Keycards'
      />
      <TableHeader>
        <div className='left-area'>
          <EntriesCount
            count={keycardsStore.entriesCount}
            caption='Keycards'
          />
          <Paginator
            currentPage={keycardsRouteStore.currentPage}
            entriesCount={keycardsStore.entriesCount}
            entriesPerPage={MAX_TABLE_ENTRIES_PER_PAGE}
            changePage={changePaginationPage}
          />
        </div>
        <div className='right-area'>
          <NewEntryButton
            caption='New Keycard'
            onClick={() => showKeycardUpsertModal({}, CREATE)}
          />
          <ListSearch
            searchValue={keycardsRouteStore.filterValue}
            onSearch={onKeycardsSearch}
          />
        </div>
      </TableHeader>
      <TableContent>
        <SmartTable
          customClassName='keycards-smart-table'
          sortingFieldName={keycardsRouteStore.sortingColumn.fieldName}
          sortingOrder={keycardsRouteStore.sortingColumn.sortingOrder}
          onSortIconClick={sortKeycards}
          rows={keycardsRouteStore.forTable}
          onRowClick={content => showKeycardUpsertModal(content, UPDATE)}
          columns={COLUMNS}
          loading={keycardsRouteStore.loading}
        />
      </TableContent>
    </AdminTableLayout>
  </React.Fragment>
);

export default observer(KeycardsView);
