import { types } from 'mobx-state-tree';
import {
  set as _set,
  values as _values,
} from 'lodash';

import base from 'models/base';

import { MODEL_NAME, SCHEMA } from './constants/schema';
import { LOCAL_STORE_KEY_FOR_TYPES, CAMERA_TYPES } from './constants';

const CamerasModel = base({
  names: {
    singular: MODEL_NAME.SINGULAR,
    plural: MODEL_NAME.PLURAL
  },
  JSONSchema: SCHEMA,
  httpConfig: {
    root: MODEL_NAME.plural,
    methods: {
    }
  }
});

CamerasModel.configureStore((store) => {
  return store
  .props({
    deviceByTypes: types.optional(types.model({
      [CAMERA_TYPES.CONSULTATION]: types.string,
    }),
    {
      [CAMERA_TYPES.CONSULTATION]: '',
    }),
    machineWebCamDevices: types.optional(types.array(types.frozen()), []),
    showingCameraConfigurationModal: types.optional(types.boolean, false),
  })
  .actions(self => ({
    setValue: (path, value) => _set(self, path, value),
    setDeviceByType: (type, deviceId) => {
      if (deviceId) {
        self.deviceByTypes[type] = deviceId;
        localStorage.setItem(LOCAL_STORE_KEY_FOR_TYPES[type], deviceId);
      }
      else {
        self.deviceByTypes[type] = '';
        localStorage.removeItem(LOCAL_STORE_KEY_FOR_TYPES[type]);
      }
    },
    setDeviceByTypeAutomatically: () => {
      _values(CAMERA_TYPES).forEach((type) => {
        const currentDevice = self.machineWebCamDevices.find(device => device.deviceId ===  self.deviceByTypes[type]);
        if (currentDevice) return;

        self.setDeviceByType(type,
          self.machineWebCamDevices.length !== 0
            ? self.machineWebCamDevices[0].deviceId
            : null
        );
      });
    },
    setMachineWebCamDevices: (devices) => {
      self.machineWebCamDevices.replace(devices);
    },
    syncDevicesFromLocalStorage: () => {
      _values(CAMERA_TYPES).forEach((type) => {
        self.deviceByTypes[type] = localStorage.getItem(LOCAL_STORE_KEY_FOR_TYPES[type]) || '';
      });
    },
    reloadVideoDevices: () => {
      return navigator.mediaDevices.enumerateDevices().then((devices = []) => {
        const videoDevices = devices.reduce((result, device) => {
          if (device.kind === 'videoinput') result.push(device);

          return result;
        }, []);

        self.setMachineWebCamDevices(videoDevices);
      })
      .then(() => {
        self.setDeviceByTypeAutomatically();
      });
    }
  }))
  .views(self => ({
    isAvailableWebCam: (type) => {
      const deviceId = self.deviceByTypes[type];
      return !!self.machineWebCamDevices.find(device => device.deviceId === deviceId);
    }
  }));
});


export default CamerasModel;
