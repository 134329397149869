import React from 'react';

export const COLUMNS = [
  {
    name: 'Drug',
    fieldName: 'drugName',
    isSortable: false,
    width: '35%',
    render: ({ content }) => {
      return <div>
        <div title={content.drugName} className='drug-name'>
          { content.drugName }{ content.activeNumeratorStrength }
        </div>
        <div>
          <span className='NDC'>
            NDC:
          </span>
          {
            content.labelNDC
          }
          <span className='quantity'>
            QTY:
          </span>
          {
            content.quantity || 'N/A'
          }
        </div>
      </div>;
    }
  },
  {
    name: 'Module',
    fieldName: 'module',
    isSortable: false,
    width: '10%',
    render: ({ content }) => (
      <div title={content.module}>{content.module}</div>
    )
  },
  {
    name: 'Slot',
    fieldName: 'slot',
    isSortable: false,
    width: '6%',
    render: ({ content }) => (
      <div title={content.slot}>{content.slot}</div>
    )
  },
  {
    name: 'Slot Type',
    fieldName: 'slotType',
    isSortable: false,
    width: '10.5%',
    render: ({ content }) => (
      <div title={content.slotType}>{content.slotType}</div>
    )
  },
  {
    name: 'Labelled',
    fieldName: 'labelled',
    isSortable: false,
    width: '8%',
    render: ({ content }) => (
      <div>{content.labelled}</div>
    )
  },
  {
    name: 'Effective Expires At',
    fieldName: 'computed_effectiveExpiresAt',
    isSortable: false,
    width: '16%',
    render: ({ content }) => (
      <div>{content.expiresAt}</div>
    )
  },
];
