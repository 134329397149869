import stores from 'bootstrap/store';

import { prescriptionFillsMailOrderRouteStore } from '../domain/store';

import { listPrescriptionFills } from './index';

const prescriptionFillMailOrdersStore = stores.global.prescriptionFillMailOrders;
const prescriptionFillsStore = stores.global.prescriptionFills;


export default () => {
  return prescriptionFillMailOrdersStore.process({body: {prescriptionFillId: prescriptionFillsMailOrderRouteStore.activePrescriptionFillId}})
  .then(() => Promise.all([
    listPrescriptionFills({shouldMerge: false}),
    prescriptionFillsStore.listMailOrderedForNotificationsPane(),
  ]));
};
