import Promise from 'bluebird';
import { types, flow } from 'mobx-state-tree';
import {
  kebabCase as _kebabCase,
  keyBy as _keyBy,
  mapValues as _mapValues,
  merge as _merge,
} from 'lodash';
import JSONSchemaToMSTModule from 'jsonschema-to-mobx-state-tree';

import http from 'bootstrap/http';

const JSONSchemaToMST = JSONSchemaToMSTModule(types);


// This establishes a private namespace.
const namespace = new WeakMap();
function p(object) {
  if (!namespace.has(object)) namespace.set(object, {});
  return namespace.get(object);
}


const globalConfig = {};



/**
 *
 */
class Model {
  constructor({
    names: {singular, plural} = {},
    httpConfig = {},
    JSONSchema,
  }) {
    const modelName = {};
    setImmutable(modelName, 'singular', singular);
    setImmutable(modelName, 'plural', plural);
    setImmutable(this, 'name', modelName);

    setImmutable(this, 'schema', JSONSchema);
    setImmutable(this, 'MSTModel', createMSTModel(JSONSchema));

    p(this).httpConfig = constructHttpConfig(this, httpConfig);

    if (!p(this).httpConfig.root) throw new Error('No http root determined. Please specify in httpConfig, else plural model name.');

    const composedProps = types.compose(
      types.model(this.name.singular + 'Store', {
        data: types.optional(types.map(types.frozen()), {}),
      }),
      constructHttpProperties(p(this).httpConfig)
    );

    p(this).preConfigMSTStore = composedProps
    .actions(self => {
      const actions = {
        ...constructHttpActions(self, p(this).httpConfig),
        // listAll: flow(function* (config = {}) {
        //   const page = _.get(config, 'query.page', 0);

        //   if (!page) self.dataForKiosk.clear();

        //   config.urlFragment = () => '/for-kiosk';
        //   const response = yield self.listAll(config);
        //   const data = response.data || response || [];
        //   self.dataForKiosk.merge(_.keyBy(data, 'id'));

        //   return response;
        // }),



        // setValue: (key, value) => self[key] = value
      };

      return actions;
    })
    .views(self => ({
      get asList() {
        return Array.from(self.data.values());
      }
    }));
  }

  configureStore(configure) {
    setImmutable(this, 'MSTStore', configure(p(this).preConfigMSTStore));
  }

  createStore(props) {
    return this.MSTStore.create(props);
  }
}


/**
 *
 */
function setImmutable(obj, prop, value) {
  Object.defineProperty(obj, prop, {value});
}


/**
 *
 */
function createMSTModel(JSONSchema) {
  const Model = JSONSchema ? JSONSchemaToMST(JSONSchema, (ref, {meta}) => {
    if (meta.name === 'id') return types.identifier;
    return ref;
  }) : types.model({});

  Model.actions(self => ({
    setValue: (prop, value) => {
      self[prop] = value;
    }
  }));

  return Model;
}


/**
 * Takes provided httpConfig and merges it with default http methods.
 */
function constructHttpConfig(model, httpConfig) {
  return _merge({
    root: _kebabCase(model.name.plural),
    methods: {
      get: {
        method: 'GET',
        urlFragment: (params = {}) => `/${params.id}`,
        onSuccess: (self, {config = {}, response = {}}) => {
          if (!response.id) return;
          config.storeResult && self.data.set(response.id, _merge(self.data[response.id] || {}, response));
        }
      },
      list: {
        method: 'GET',
        urlFragment: () => '',
        onSuccess: (self, {config, response = {}}) => {
          const data = response.data || response || [];
          config.storeResult !== false && self.data.merge(_keyBy(data, 'id'));
        }
      },
      post: {
        method: 'POST',
        urlFragment: () => '',
        onSuccess: (self, {config, response = {}}) => {
          if (!response.id) return;
          config.storeResult && self.data.set(response.id, _merge(self.data[response.id] || {}, response));
        }
      },
      put: {
        method: 'PUT',
        urlFragment: (params = {}) => params.id && `/${params.id}`,
        onSuccess: (self, {config, response = {}}) => {
          if (!response.id) return;
          config.storeResult && self.data.set(response.id, _merge(self.data[response.id] || {}, response));
        }
      },
      delete: {
        method: 'DELETE',
        urlFragment: (params = {}) => `/${params.id}`,
        onSuccess: (self, {config}) => {
          delete self.data[config.params.id];
        }
      }
    }
  }, httpConfig);
}


/**
 *
 */
function constructHttpProperties(httpConfig) {
  return types.model({
    activity: types.optional(types.model({
      http: types.optional(types.model(_mapValues(httpConfig.methods, () => {
        return 0;
      })), {})
    }), {})
  });
}


/**
 *
 */
function constructHttpActions(self, httpConfig) {
  return _mapValues(httpConfig.methods, (methodConfig, methodName) => {
    return constructHttpAction(self, methodName, httpConfig);
  });
}


/**
 * Executes an http action from its http configuration.
 */
function constructHttpAction(self, methodName, httpConfig) {
  // Apparently `flow` doesn't return a promise, so we wrap it in a promise.
  return (config = {}) => new Promise((resolve, reject) => {
    (flow(function* (config = {}) {
      config = {...config};
      const methodConfig = httpConfig.methods[methodName];
      if (!methodConfig) throw new Error(`Cannot execute http method, no config specified for ${methodName}.`);

      config.method = config.method || methodConfig.method;
      if (!config.url) {
        let urlFragment = config.urlFragment != null ?
          config.urlFragment ?
            config.urlFragment(config.params) :
            '' :
          methodConfig.urlFragment != null ?
            methodConfig.urlFragment ?
              methodConfig.urlFragment(config.params) :
              '' :
            methodName ? _kebabCase(methodName) : '';
        urlFragment = `/${(urlFragment || '').replace(/^\/+/, '')}`;
        const rootRoute = config.rootRoute || methodConfig.rootRoute || httpConfig.root;
        const modelRoot = `/${rootRoute.replace(/^\/+/, '')}`;
        config.url = `${globalConfig.root || ''}${modelRoot || ''}${urlFragment || ''}`.replace(/\/$/, '');
      }

      

      let response;
      try {
        self.activity.http[methodName]++;
        response = yield http[config.method.toLowerCase()](config);
        self.activity.http[methodName]--;
        // onSuccess is allowed to be specified in httpConfig, but we dont' allow it to
        // be passed in via config.
        if (methodConfig.onSuccess) methodConfig.onSuccess(self, {config, response});
        return resolve(response);
      }
      catch(err) {
        console.log('Model Http Error', err);
        self.activity.http[methodName]--;
        // onError is allowed to be specified in httpConfig, but we dont' allow it to
        // be passed in via config.
        if (methodConfig.onError) methodConfig.onError(self, {config, error: err});
        return reject(err);
      }
    }))(config);
  });
}


export default (config) => new Model(config);

export const configureGlobal = (config = {}) => {
  _merge(globalConfig, config);
};
