import React from 'react';
import { observer } from 'mobx-react';

import NotificationWrapper from '../notification-wrapper';
import PendingPatient from './components/pending-patient';

import './new-patients-notifications-view-style.scss';


const NewPatientsNotificationsView = ({
  pending,
  onClick,
}) => (
  <div className='c-NewPatientsNotifications'>
    {
      !pending.length &&
      <div className='no-data-caption'>
        No New Patients
      </div>
    }
    {
      pending.map((patient) => <NotificationWrapper
        key={patient.id}
        cursorPointer={true}
      >
        <PendingPatient
          patient={patient}
          onClick={onClick}
        />
      </NotificationWrapper>)
    }
  </div>
);

export default observer(NewPatientsNotificationsView);
