import deepFreeze from 'deep-freeze';

export const MODEL_NAME = Object.freeze({
  SINGULAR: 'medical_assistant',
  PLURAL: 'medical-assistants',
});

export const FIELD_NAME = Object.freeze({
  ID: 'id',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  MEDICAL_ASSISTANT_USER: 'maUser',
  USER: 'user',
  EMAIL: 'email',
  PHONE_NUMBER: 'phoneNumber',
  HEALTHCARE_SITES: 'healthcareSites',
  NAME: 'name',
  LOCATION: 'location',
  STREET1: 'street1',
  CITY: 'city',
  STATE: 'state',
  ZIP: 'zip',
});

export const SCHEMA = deepFreeze({
  title: MODEL_NAME.SINGULAR,
  type: 'object',
  properties: {
    [FIELD_NAME.ID]: {
      type: 'string',
    },
    [FIELD_NAME.FIRST_NAME]: {
      type: 'string'
    },
    [FIELD_NAME.LAST_NAME]: {
      type: 'string'
    },
    [FIELD_NAME.HEALTHCARE_SITES]: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          [FIELD_NAME.ID]: {
            type: 'string'
          },
          [FIELD_NAME.NAME]: {
            type: 'string'
          },
          [FIELD_NAME.PHONE_NUMBER]: {
            type: 'string'
          },
          [FIELD_NAME.LOCATION]: {
            type: 'object',
            properties: {
              [FIELD_NAME.STREET1]: {
                type: 'string',
              },
              [FIELD_NAME.CITY]: {
                type: 'string',
              },
              [FIELD_NAME.STATE]: {
                type: 'string',
              },
              [FIELD_NAME.ZIP]: {
                type: 'string',
              },
            },
          },
        },
      },
    },
    [FIELD_NAME.MEDICAL_ASSISTANT_USER]: {
      type: 'object',
      properties: {
        [FIELD_NAME.USER]: {
          type: 'object',
          properties: {
            [FIELD_NAME.ID]: {
              type: 'string'
            },
            [FIELD_NAME.FIRST_NAME]: {
              type: 'string'
            },
            [FIELD_NAME.LAST_NAME]: {
              type: 'string'
            },
            [FIELD_NAME.EMAIL]: {
              type: 'string'
            },
            [FIELD_NAME.PHONE_NUMBER]: {
              type: 'string'
            },
          }
        }
      }
    },
    [FIELD_NAME.HEALTHCARE_SITES]: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          [FIELD_NAME.ID]: {
            type: 'string'
          },
          [FIELD_NAME.NAME]: {
            type:'string'
          },
          [FIELD_NAME.PHONE_NUMBER]: {
            type: 'string'
          },
          [FIELD_NAME.LOCATION]: {
            type: 'object',
            properties: {
              [FIELD_NAME.STREET1]: {
                type: 'string'
              },
              [FIELD_NAME.STATE]: {
                type: 'string'
              },
              [FIELD_NAME.ZIP]: {
                type: 'string'
              },
              [FIELD_NAME.CITY]: {
                type: 'string'
              }
            }
          }
        },
      }
    }
  }
});
