import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import DrugsView from './drugs-view';

import * as helpers from './helpers';

import stores from 'bootstrap/store';
import { drugsRouteStore } from './domain/store';
import { drugUpdateModalRouteStore } from './components/drug-update-modal/domain/store';
const drugsStore = stores.global.drugs;


const DrugsContainer = () => {
  useEffect(() => {
    helpers.listDrugs();

    return () => {
      drugsStore.setInitialState();
      drugsRouteStore.setInitialState();
      drugUpdateModalRouteStore.setInitialState();
    };
  }, []);

  return (
    <DrugsView
      drugsRouteStore={drugsRouteStore}
      drugUpdateModalRouteStore={drugUpdateModalRouteStore}
      listDrugs={helpers.listDrugs}
      sortDrugs={helpers.sortDrugs}
      changePaginationPage={helpers.changePaginationPage}
      onDrugsSearch={helpers.onDrugsSearch}
      showDrugUpdateModal={helpers.showDrugUpdateModal}
      closeDrugUpdateModal={helpers.closeDrugUpdateModal}
      upsertDrug={helpers.upsertDrug}
      entriesCount={drugsStore.entriesCount}
    />
  );
};

export default observer(DrugsContainer);
