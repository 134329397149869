import React from 'react';
import { observer } from 'mobx-react';

import UserUpsertModal from './components/user-upsert-modal';

import AdminTableLayout from 'components/admin-table-layout';
import Title from 'components/admin-table-layout/components/title';
import TableHeader from 'components/admin-table-layout/components/table-header';
import EntriesCount from 'components/admin-table-layout/components/table-header/components/entries-count';
import Paginator from 'components/paginator';
import NewEntryButton from 'components/admin-table-layout/components/table-header/components/new-entry-button';
import ListSearch from 'components/drill-down-layout/components/list/components/list-header/components/list-search';
import TableContent from 'components/admin-table-layout/components/table-content';
import SmartTable from 'components/smart-table';

import {
  COLUMNS,
  USER_MANIPULATION_OPTIONS
} from './constants';
import { MAX_TABLE_ENTRIES_PER_PAGE } from 'models/users/constants';

import './users-style.scss';

const { CREATE, UPDATE } = USER_MANIPULATION_OPTIONS;

const UsersView = ({
  usersStore,
  usersRouteStore,
  userUpsertModalRouteStore,
  sortUsers,
  changePaginationPage,
  onUsersSearch,
  showUserUpsertModal,
  closeUserUpsertModal,
  createUser,
  updateUser,
  deleteUser,
  showUserDeleteConfirmationModal
}) => (
  <React.Fragment>
    <UserUpsertModal
      userUpsertModalRouteStore={userUpsertModalRouteStore}
      usersRouteStore={usersRouteStore}
      createUser={createUser}
      updateUser={updateUser}
      deleteUser={deleteUser}
      closeUserUpsertModal={closeUserUpsertModal}
      showUserDeleteConfirmationModal={showUserDeleteConfirmationModal}
    />
    <AdminTableLayout>
      <Title
        text='Users'
      />
      <TableHeader>
        <div className='left-area'>
          <EntriesCount
            count={usersStore.entriesCount}
            caption='Users'
          />
          <Paginator
            currentPage={usersRouteStore.currentPage}
            entriesCount={usersStore.entriesCount}
            entriesPerPage={MAX_TABLE_ENTRIES_PER_PAGE}
            changePage={changePaginationPage}
          />
        </div>
        <div className='right-area'>
          <NewEntryButton
            caption='New User'
            onClick={e => showUserUpsertModal(e, CREATE)}
          />
          <ListSearch
            searchValue={usersRouteStore.filterValue}
            onSearch={onUsersSearch}
          />
        </div>
      </TableHeader>
      <TableContent>
        <SmartTable
          sortingFieldName={usersRouteStore.sortingColumn.fieldName}
          sortingOrder={usersRouteStore.sortingColumn.sortingOrder}
          onSortIconClick={sortUsers}
          rows={usersRouteStore.forTable}
          onRowClick={content => showUserUpsertModal(content, UPDATE)}
          columns={COLUMNS}
          loading={usersRouteStore.loading}
        />
      </TableContent>
    </AdminTableLayout>
  </React.Fragment>
);

export default observer(UsersView);
