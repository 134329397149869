import { useEffect } from 'react';

// Hooks
import useGlobalActivityPing from 'hooks/useGlobalActivityPing';
import useInterval from 'hooks/useInterval';
import useTimeout from 'hooks/useTimeout';


const bootTime = Date.now();

const MIN_TIME_SINCE_LAST_BOOT = 1000 * 60 * 60 * 2; // 2 hours
const MIN_TIME_SINCE_LAST_ACTIVITY = 1000 * 60 * 2; // 2 minutes
const TEST_INTERVAL = 1000 * 60 * 1; // 1 minute

export default function useRefreshOnInactivity() {
  const { ping, onPing, offPing, getSinceLatestPing } = useGlobalActivityPing('userInput');

  const startTimeout = useTimeout(handleRefresh, MIN_TIME_SINCE_LAST_ACTIVITY);
  useInterval(handleRefresh, TEST_INTERVAL);

  useEffect(() => {
    onPing('userInput', startTimeout);
    return () => offPing('userInput', startTimeout);
  }, [startTimeout]);

  function handleRefresh() {
    const timeSinceBoot = Date.now() - bootTime;
    if (timeSinceBoot < MIN_TIME_SINCE_LAST_BOOT) return;

    const sinceActivity = getSinceLatestPing();

    if (sinceActivity === null || (sinceActivity < MIN_TIME_SINCE_LAST_ACTIVITY)) return;

    window.location.reload();
  }

  return ping;
}