import deepFreeze from 'deep-freeze';

export const MODEL_NAME = Object.freeze({
  SINGULAR: 'callRequest',
  PLURAL: 'call-requests',
});

export const FIELD_NAME = Object.freeze({
  ID: 'id',
  PHONE_NUMBER: 'phoneNumber',
  PHARMACY_ID: 'pharmacyId',
  CREATED_AT: 'createdAt',

  PHARMACIST_ACTIONS: 'pharmacistActions',
  ACTION: 'action',
  
  PHARMACIST: 'pharmacist',
  
  PATIENT_CALL_REQUEST: 'patientCallRequest',

  PATIENT: 'patient',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  DOB: 'DOB',
  GENDER: 'gender',
  EMAIL: 'email',
  EXTERNAL_PHARMACY_ID: 'externalPharmacyId',

  PRESCRIPTIONS: 'prescriptions',
  DRUG: 'drug',
  NAME: 'name',
  NDC: 'NDC',
});

export const SCHEMA = deepFreeze({
  title: MODEL_NAME.SINGULAR,
  type: 'object',
  properties: {
    [FIELD_NAME.ID]: {
      type: 'string',
    },
    [FIELD_NAME.PHONE_NUMBER]: {
      type: 'string',
    },
    [FIELD_NAME.PHARMACY_ID]: {
      type: 'string',
    },
    [FIELD_NAME.CREATED_AT]: {
      type: 'string',
    },
    [FIELD_NAME.PHARMACIST_ACTIONS]: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          [FIELD_NAME.ID]: {
            type: 'string',
          },
          [FIELD_NAME.ACTION]: {
            type: 'string',
          },
          [FIELD_NAME.CREATED_AT]: {
            type: 'string',
          },
          [FIELD_NAME.PHARMACIST]: {
            type: 'object',
            properties: {
              [FIELD_NAME.FIRST_NAME]: {
                type: 'string',
              },
              [FIELD_NAME.LAST_NAME]: {
                type: 'string',
              },
            },
          },
        },
      },
    },
    [FIELD_NAME.PATIENT_CALL_REQUEST]: {
      type: 'object',
      properties: {
        [FIELD_NAME.ID]: {
          type: 'string',
        },
        [FIELD_NAME.PATIENT]: {
          type: 'object',
          properties: {
            [FIELD_NAME.FIRST_NAME]: {
              type: 'string',
            },
            [FIELD_NAME.LAST_NAME]: {
              type: 'string',
            },
            [FIELD_NAME.PHONE_NUMBER]: {
              type: 'string',
            },
            [FIELD_NAME.EMAIL]: {
              type: 'string',
            },
            [FIELD_NAME.DOB]: {
              type: 'string',
            },
            [FIELD_NAME.GENDER]: {
              type: 'string',
            },
            [FIELD_NAME.CREATED_AT]: {
              type: 'string',
            },
            [FIELD_NAME.PRESCRIPTIONS]: {
              type: 'array',
              items: {
                type: 'object',
                properties: {
                  [FIELD_NAME.CREATED_AT]: {
                    type: 'string',
                  },
                  [FIELD_NAME.DRUG]: {
                    type: 'object',
                    properties: {
                      [FIELD_NAME.NAME]: {
                        type: 'string',
                      },
                      [FIELD_NAME.NDC]: {
                        type: 'string',
                      },
                    },
                  },
                },
              },
            },
          }
        },
      },
    },
  },
});

export const STATUS = {
  CONTACTED: 'contacted',
  HANDLED: 'handled',
  QUEUED: 'queued',
};
