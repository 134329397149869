import React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import medifriendRxIcon from 'assets/icons/medifriend-rx.svg';
import kioskImage from 'assets/images/kiosk.svg';

import './signup-view.scss';


const SignUpView = ({
  signUpRouteStore,
  signUp
}) => (
  <div className='c-SignUpView'>
    <div className='primary-panel'>
      <div className='sub-panel left-panel'>
        <img
          className='logo-icon'
          src={medifriendRxIcon}
        />
        <img
          className='kiosk-image'
          src={kioskImage}
        />
      </div>
      <form className='sub-panel right-panel' onSubmit={signUp}>
        <div className='back-to-sign-in'>
          <Link to='/login' onClick={() => signUpRouteStore.setInitialState()}>
            Back to sign in
          </Link>
        </div>
        <div className='greeting-text'>
          <h3>Sign Up</h3>
        </div>
        <div className='input-fields'>
          <div className='input-field'>
            <div className='input-label'>
              First Name
            </div>
            <div>
              <input value={signUpRouteStore.firstName} onChange={evt => signUpRouteStore.setValue('firstName', evt.target.value)} autoComplete="off" />
            </div>
          </div>
          <div className='input-field'>
            <div className='input-label'>
              Last Name
            </div>
            <div>
              <input value={signUpRouteStore.lastName} onChange={evt => signUpRouteStore.setValue('lastName', evt.target.value)} autoComplete="off" />
            </div>
          </div>
          <div className='input-field'>
            <div className='input-label'>
              Email
            </div>
            <div>
              <input value={signUpRouteStore.email} onChange={evt => signUpRouteStore.setValue('email', evt.target.value)} autoComplete="off" />
            </div>
          </div>
          <div className='input-field'>
            <div className='input-label'>
              Password
            </div>
            <div>
              <input value={signUpRouteStore.password} onChange={evt => signUpRouteStore.setValue('password', evt.target.value)} type='password' />
            </div>
          </div>
        </div>
        <div className='panel-footer'>
          <input
            className='sign-up-button'
            type='submit'
            value='Sign Up'
          />
          {
            !!signUpRouteStore.errorMessage &&
            <div className='error-message'>
              {
                signUpRouteStore.errorMessage
              }
            </div>
          }
        </div>
      </form>
    </div>
  </div>
);

export default observer(SignUpView);
