import { get as _get } from 'lodash';

import base from 'models/base';
import { MODEL_NAME, SCHEMA } from './constants/schema';


const KioskDrugThresholdModel = base({
  names: {
    singular: MODEL_NAME.SINGULAR,
    plural: MODEL_NAME.PLURAL
  },
  JSONSchema: SCHEMA,
  httpConfig: {
    root: MODEL_NAME.plural,
    methods: {},
  },
});

KioskDrugThresholdModel.configureStore((store) => {
  return store
  .actions(self => ({
    upsertThresholds: (config = {}) => {
      if (!_get(config, 'params.drugId')) return Promise.reject('"drugId" must be passed in params in order to upsert a kioskDrugThreshold.');
      if (!_get(config, 'body.kioskId')) return Promise.reject('"kioskId" must be passed in body in order to upsert a kioskDrugThreshold.');

      config.urlFragment = (params) => `/drugs/${params.drugId}`;
      return self.put(config);
    },
  }));
});

// Can't use module.exports because there is a generator function in this file,
// and that causes module.exports to fail for some reason.
export default KioskDrugThresholdModel;
