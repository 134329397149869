import React from 'react';
import { observer } from 'mobx-react';

import KeyValue from 'components/drill-down-layout/components/list/components/list-item/components/key-value';

import KioskIcon from 'assets/icons/kiosk-active.svg';

import './dispense-attempt-style.scss';


const DispenseAttempt = ({ data }) => {
  return <div className='dispense-attempt'>
    <div className='patient-name-container'>
      <KeyValue label={'RX ID'} value={data.prescriptionFill.prescription.rxId} />
      <KeyValue label={'DRUG'} value={data.prescriptionFill.prescription.drug.name} />
      <KeyValue label={'DR'} value={`${data.prescriptionFill.prescription.physician.firstName} ${data.prescriptionFill.prescription.physician.lastName}`} />
      <div className='kiosk-name-container max-width'>
        <div className='kiosk-name'>
          <div className='icon-container'>
            <img className='icon' src={KioskIcon} />
          </div>
          <div className='name-text'>{data.kiosk.name}</div>
        </div>
      </div>
    </div>
  </div>;
};

export default observer(DispenseAttempt);
