import React from 'react';
import { observer } from 'mobx-react';

import ListHeader from 'components/drill-down-layout/components/list/components/list-header';

import { PATIENT_LIST_SEARCH_PLACEHOLDER } from './constants';
import { PATIENT_LIST_TABS } from '../../../../constants';

const PatientsListHeader = ({
  filterValue,
  setFilterValue,
  activePatientListTab,
  setActivePatientListTab,
}) => (
  <ListHeader
    title='Patients'
    // Search
    searchValue={filterValue}
    onSearch={(e) => setFilterValue(e)}
    searchPlaceholder={PATIENT_LIST_SEARCH_PLACEHOLDER[activePatientListTab]}
    // Tabs
    // tabValues={PATIENT_LIST_TABS}
    // activeTab={activePatientListTab}
    // onTabSelect={setActivePatientListTab}
  />
);

export default observer(PatientsListHeader);
