import React from 'react';

import ProgressBar from './components/progress-bar';

import './statistics-item-style.scss';

const StatisticsItem = ({
  caption,
  totalCount = 0,
  progressCount = 0,
}) => {
  return (
    <div className='c-StatisticsItem'>
      <div className='caption'>
        {
          caption
        }
      </div>
      <div className='progress-bar'>
        <ProgressBar
          totalCount={totalCount}
          progressCount={progressCount}
        />
      </div>
      <div className='numbers'>
        {
          `${progressCount}/${totalCount}`
        }
      </div>
    </div>
  );
};

export default StatisticsItem;
