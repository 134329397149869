import React from 'react';
import { observer } from 'mobx-react';

import ListSearch from 'components/drill-down-layout/components/list/components/list-header/components/list-search';
import SmartTable from 'components/smart-table';

import { COLUMNS } from './constants';

import './drugs-styles.scss';

const StockingBatchDrugsView = ({ filter, setFilter, drugsList = [] }) => (
  <div className='c-StockingBatchDrugs'>
    <div className='filter'>
      <ListSearch
        searchValue={filter}
        onSearch={(e) => setFilter(e.target.value)}
      />
    </div>
    {
      drugsList.length
        ? <SmartTable
          customClassName='StockingBatchDrugsTable'
          rows={drugsList}
          getRowId={(content) => `${content.drugName}-${content.labelNDC}-${content.quantity}`}
          columns={COLUMNS}
        />
        : <div className='no-drugs-text'>
          No drugs found.
        </div>
    }
  </div>
);

export default observer(StockingBatchDrugsView);
