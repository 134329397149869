import React from 'react';
import { observer } from 'mobx-react';

// Components
import Drugs from './components/drugs';
import Patient from './components/patient';

// Styles
import './fulfillment-info-style.scss';


const FulfillmentInfo = ({
  prescriptionFillsForDisplay = {},
  approveDispenseAttempt,
  rejectDispenseAttempt,
}) => (
  <div className='c-FulfillmentInfo'>
    <Patient
      patient={prescriptionFillsForDisplay.patient}
    />
    <Drugs
      isFulfillmentPaid={prescriptionFillsForDisplay.isFulfillmentPaid}
      prescriptionFills={prescriptionFillsForDisplay.prescriptionFills}
      approveDispenseAttempt={approveDispenseAttempt}
      rejectDispenseAttempt={rejectDispenseAttempt}
    />
  </div>
);

export default observer(FulfillmentInfo);
