import React from 'react';
import { observer } from 'mobx-react';

import { constructClassName } from './helpers';

import './modal-row-styles.scss';

const ModalRow = ({ children, shouldBeCenteredHorizontally, shouldBeSpaceBetween, shouldBoundToBottom }) => {
  return (
    <div className={constructClassName({ shouldBeCenteredHorizontally, shouldBeSpaceBetween, shouldBoundToBottom })}>
      {
        children
      }
    </div>
  );
};

export default observer(ModalRow);
