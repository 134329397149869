import { prescriptionFillsRouteStore } from '../domain/store';

export default (activePrescriptionFillId) => {
  const activePrescriptionFill = prescriptionFillsRouteStore.prescriptionFillsById.get(activePrescriptionFillId);
  if (!activePrescriptionFill || !activePrescriptionFill.computed_filled) {
    return;
  }

  prescriptionFillsRouteStore.setValue('activePrescriptionFillId', activePrescriptionFillId);
  prescriptionFillsRouteStore.setValue('showingSendReceiptModal', true);
};
