import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import PharmaciesView from './pharmacies-view';

import * as helpers from './helpers';

import stores from 'bootstrap/store';
import { pharmaciesRouteStore } from './domain/store';
import { pharmacyUpsertModalRouteStore } from './components/pharmacy-upsert-modal/domain/store';
const pharmaciesStore = stores.global.pharmacies;
const locationsStore = stores.global.locations;
const pharmacyHealthcareSitesStore = stores.global.pharmacyHealthcareSites;
const healthcareSitesStore = stores.global.healthcareSites;

const PharmaciesContainer = () => {
  useEffect(() => {
    helpers.listPharmacies();
  }, []);

  return (
    <PharmaciesView
      pharmaciesStore={pharmaciesStore}
      pharmaciesRouteStore={pharmaciesRouteStore}
      pharmacyUpsertModalRouteStore={pharmacyUpsertModalRouteStore}
      locationsStore={locationsStore}
      pharmacyHealthcareSitesStore={pharmacyHealthcareSitesStore}
      healthcareSitesStore={healthcareSitesStore}
      sortPharmacies={helpers.sortPharmacies}
      changePaginationPage={helpers.changePaginationPage}
      onPharmaciesSearch={helpers.onPharmaciesSearch}
      showPharmacyUpsertModal={helpers.showPharmacyUpsertModal}
      closePharmacyUpsertModal={helpers.closePharmacyUpsertModal}
      showPharmacyDeleteConfirmationModal={helpers.showPharmacyDeleteConfirmationModal}
      createPharmacy={helpers.createPharmacy}
      updatePharmacy={helpers.updatePharmacy}
      deletePharmacy={helpers.deletePharmacy}
    />
  );
};

export default observer(PharmaciesContainer);
