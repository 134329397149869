import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import UsersView from './users-view';

import * as helpers from './helpers';

import stores from 'bootstrap/store';
import { usersRouteStore } from './domain/store';
import { userUpsertModalRouteStore } from './components/user-upsert-modal/domain/store';
const usersStore = stores.global.users;


const UsersContainer = () => {
  useEffect(() => {
    helpers.listUsers();
  }, []);

  return (
    <UsersView
      usersStore={usersStore}
      usersRouteStore={usersRouteStore}
      userUpsertModalRouteStore={userUpsertModalRouteStore}
      sortUsers={helpers.sortUsers}
      changePaginationPage={helpers.changePaginationPage}
      onUsersSearch={helpers.onUsersSearch}
      showUserUpsertModal={helpers.showUserUpsertModal}
      closeUserUpsertModal={helpers.closeUserUpsertModal}
      showUserDeleteConfirmationModal={helpers.showUserDeleteConfirmationModal}
      createUser={helpers.createUser}
      updateUser={helpers.updateUser}
      deleteUser={helpers.deleteUser}
    />
  );
};

export default observer(UsersContainer);
