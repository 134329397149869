import { listPhysicians } from './index';

import { physiciansRouteStore } from '../domain/store';

export default (fieldName, sortingOrder) => {
  physiciansRouteStore.setValue('sortingColumn', {
    ...physiciansRouteStore.sortingColumn,
    fieldName,
    sortingOrder,
  });

  return listPhysicians();
};
