import React from 'react';
import { observer } from 'mobx-react';

// Components
import WorkPlaces from './components/work-places';
import MedicalAssistantData from './components/medical-asisstant-data';
import DeleteConfirmationModal from 'components/delete-confirmation-modal';
import Modal from 'components/modal';
import ModalEditingAreas from 'components/modal/layout/editing-areas';
import ModalEditingArea from 'components/modal/layout/editing-areas/components/editing-area';

const MedicalAssistantUpsertModal = ({
  upsertModalRouteStore,
  routeStore,
  addWorkPlaceLocally,
  removeWorkPlaceLocally,
  onWorkPlacesSearch,
  createMedicalAssistant,
  updateMedicalAssistant,
  deleteMedicalAssistant,
  closeUpsertModal,
  showDeleteConfirmationModal,
}) => {
  const { activeMedicalAssistant } = routeStore;
  const activelyModifiedMedicalAssistant = upsertModalRouteStore.activelyModifiedMedicalAssistant || {};

  return (
    <Modal
      modalStyles={{ height: '44%', width: '80%' }}
      isOpen={routeStore.showingUpsertModal}
      shouldCloseOnOverlayClick={true}
      close={closeUpsertModal}
      title={activeMedicalAssistant ? `${activeMedicalAssistant.firstName} ${activeMedicalAssistant.lastName}` : 'New Medical Assistant'}
    >
      {
        activeMedicalAssistant &&
        <DeleteConfirmationModal
          showing={upsertModalRouteStore.showingDeleteConfirmationModal}
          deletedItemName={`${activeMedicalAssistant.firstName} ${activeMedicalAssistant.lastName}`}
          deleteItem={deleteMedicalAssistant}
          closeModal={() => showDeleteConfirmationModal(false)}
        />
      }
      <ModalEditingAreas>
        <ModalEditingArea>
          <MedicalAssistantData
            upsertModalRouteStore={upsertModalRouteStore}
            activeMedicalAssistant={activeMedicalAssistant}
            activelyModifiedMedicalAssistant={activelyModifiedMedicalAssistant}
            createMedicalAssistant={createMedicalAssistant}
            updateMedicalAssistant={updateMedicalAssistant}
            showDeleteConfirmationModal={showDeleteConfirmationModal}
          />
        </ModalEditingArea>
        <ModalEditingArea>
          <WorkPlaces
            filter={upsertModalRouteStore.workPlaceFilter}
            onSearch={onWorkPlacesSearch}
            onWorkPlaceSuggestionClick={addWorkPlaceLocally}
            autosuggestedWorkPlaces={upsertModalRouteStore.suggestedWorkPlaces}
            modifiedWorkPlaces={upsertModalRouteStore.activeMedicalAssistantWorkPlaces}
            removeWorkPlace={removeWorkPlaceLocally}
          />
        </ModalEditingArea>
      </ModalEditingAreas>
    </Modal>
  );
};

export default observer(MedicalAssistantUpsertModal);
