import React from 'react';
import { observer } from 'mobx-react';

// Components
import ConsultationRequestModal from './components/consultation-request-modal';
import Content from './components/content';
import DrillDownLayout from 'components/drill-down-layout';
import ExpandedPrescriptionInfoModal from './components/expanded-prescription-info-modal';
import List from './components/list';
import ReviewConflictModal from './components/review-conflict-modal';

//Styles
import './dispense-attempts-style.scss';

const DispenseAttemptView = ({
  dispenseAttemptsStore,
  dispenseAttemptsRouteStore,
  prescriptionInfoRouteStore,
  approveDispenseAttempt,
  requestConsultation,
  setActiveDispenseAttemptTab,
  setFilterValue,
  onListBottomThreshold,
  refreshDispenseAttemptsOnReviewConflict,
  rootRoute,
}) => (
  <div className='r-DispenseAttemptsView'>
    <ReviewConflictModal
      conflictMessage={dispenseAttemptsStore.reviewConflictMessage}
      refreshDispenseAttempts={refreshDispenseAttemptsOnReviewConflict}
    />
    <ConsultationRequestModal
      showingConsultationRequestModal={dispenseAttemptsRouteStore.showingConsultationRequestModal}
      dispenseAttemptsRouteStore={dispenseAttemptsRouteStore}
      close={() => dispenseAttemptsRouteStore.resetConsultationRequestModal()}
      create={requestConsultation}
    />
    <ExpandedPrescriptionInfoModal
      close={() => dispenseAttemptsRouteStore.setValue('showingExpandedPrescriptionInfoModal', false)}
      isOpen={dispenseAttemptsRouteStore.showingExpandedPrescriptionInfoModal}
      dispenseAttemptsRouteStore={dispenseAttemptsRouteStore}
      prescriptionInfoRouteStore={prescriptionInfoRouteStore}
      containerImagesFetched={!prescriptionInfoRouteStore.http.listingContainerImages}
    />
    <DrillDownLayout>
      {
        !dispenseAttemptsRouteStore.isExpanded && <List
          dispenseAttemptsRouteStore={dispenseAttemptsRouteStore}
          groupedByDate={dispenseAttemptsRouteStore.dispenseAttemptsGroupedByDate}
          setFilterValue={setFilterValue}
          setActiveDispenseAttemptTab={setActiveDispenseAttemptTab}
          onListBottomThreshold={onListBottomThreshold}
          rootRoute={rootRoute}
        />
      }
      <Content
        dispenseAttemptsStore={dispenseAttemptsStore}
        dispenseAttemptsRouteStore={dispenseAttemptsRouteStore}
        approveDispenseAttempt={approveDispenseAttempt}
        isExpanded={dispenseAttemptsRouteStore.isExpanded}
        toggleExpanded={() => dispenseAttemptsRouteStore.setValue('isExpanded', !dispenseAttemptsRouteStore.isExpanded)}
      />
    </DrillDownLayout>
  </div>
);

export default observer(DispenseAttemptView);
