import React from 'react';
import { observer } from 'mobx-react';

// View
import InfoView from './info-view';

const InfoContainer = ({
  activePatient,
  activePatientAddress,
  activePatientImageGallery,
}) => {
  return <InfoView
    activePatient={activePatient}
    activePatientAddress={activePatientAddress}
    activePatientImageGallery={activePatientImageGallery}
  />;
};

export default observer(InfoContainer);
