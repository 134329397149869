import React from 'react';
import Gallery from 'react-grid-gallery';
import { observer } from 'mobx-react';

import './image-gallery-styles.scss';

const ImageGallery = ({ ...props }) => (
  <Gallery {...props} />
);

export default observer(ImageGallery);
