import React from 'react';
import { observer } from 'mobx-react';
import { get as _get } from 'lodash';

import HealthcareSites from './components/healthcareSites';
import CaptionValueInput from 'components/caption-value-input';
import DeleteConfirmationModal from 'components/delete-confirmation-modal';
import Modal from 'components/modal';
import ModalEditingAreas from 'components/modal/layout/editing-areas';
import ModalEditingArea from 'components/modal/layout/editing-areas/components/editing-area';
import ModalRow from 'components/modal/layout/row';
import ModalButton from 'components/modal/layout/button';

import './index.scss';

const PatientUpsertModal = ({
  patientUpsertModalRouteStore,
  patientsRouteStore,
  createPatient,
  updatePatient,
  deletePatient,
  closePatientUpsertModal,
  onHealthcareSitesSearch,
  addHealthcareSiteLocally,
  removeHealthcareSiteLocally,
  showPatientDeleteConfirmationModal
}) => {
  const { activePatient } = patientsRouteStore;
  const activelyModifiedPatient = patientUpsertModalRouteStore.activelyModifiedPatient || {};
  const canBeUpserted = activePatient
    ? patientUpsertModalRouteStore.readyForUpdate
    : patientUpsertModalRouteStore.readyForCreation;

  return (
    <Modal
      modalStyles={{ height: '500px', width: '800px' }}
      isOpen={patientsRouteStore.showingPatientUpsertModal}
      shouldCloseOnOverlayClick={true}
      close={closePatientUpsertModal}
      title={activePatient ? `${activePatient.firstName} ${activePatient.lastName}` : 'New Patient'}
    >
      {
        activePatient &&
      <DeleteConfirmationModal
        showing={patientUpsertModalRouteStore.showingDeleteConfirmationModal}
        deletedItemName={`${activePatient.firstName} ${activePatient.lastName}`}
        deleteItem={deletePatient}
        closeModal={() => showPatientDeleteConfirmationModal(false)}
      />
      }
      <ModalEditingAreas>
        <ModalEditingArea>
          <ModalRow>
            <CaptionValueInput
              caption='First Name'
              value={activelyModifiedPatient.firstName}
              onChange={(value) => patientUpsertModalRouteStore.setModifiedPatient('firstName', value)}
            />
            <CaptionValueInput
              caption='Last Name'
              value={activelyModifiedPatient.lastName}
              onChange={(value) => patientUpsertModalRouteStore.setModifiedPatient('lastName', value)}
            />
          </ModalRow>
          <ModalRow>
            <CaptionValueInput
              caption='External Pharmacy Id'
              value={activelyModifiedPatient.externalPharmacyId}
              onChange={(value) => patientUpsertModalRouteStore.setModifiedPatient('externalPharmacyId', value)}
            />
            <CaptionValueInput
              caption='Date of Birth'
              placeholder='YYYY-MM-DD'
              value={activelyModifiedPatient.DOB}
              onChange={(value) => patientUpsertModalRouteStore.setModifiedPatient('DOB', value)}
            />
          </ModalRow>
          {
            _get(activelyModifiedPatient, 'patientUser.user.phoneNumber') !== undefined &&
            <ModalRow>
              <CaptionValueInput
                inputFieldClassNames={['max-width']}
                caption='Email'
                value={activelyModifiedPatient.patientUser.user.email}
                onChange={(value) => patientUpsertModalRouteStore.setModifiedPatient('patientUser.user.email', value)}
              />
            </ModalRow>
          }
          {
            _get(activelyModifiedPatient, 'patientUser.user.phoneNumber') !== undefined &&
            <ModalRow>
              <CaptionValueInput
                inputFieldClassNames={['max-width']}
                caption='Phone Number'
                value={activelyModifiedPatient.patientUser.user.phoneNumber}
                onChange={(value) => patientUpsertModalRouteStore.setModifiedPatient('patientUser.user.phoneNumber', value)}
              />
            </ModalRow>
          }
          <ModalRow shouldBoundToBottom={true}>
            <ModalButton
              isDisabled={!canBeUpserted}
              color='blue'
              text='Save'
              onClick={activePatient ? updatePatient : createPatient}
            />
            {
              activePatient &&
              <ModalButton
                color='red'
                text='Delete'
                onClick={() => showPatientDeleteConfirmationModal()}
              />
            }
          </ModalRow>
        </ModalEditingArea>
        <ModalEditingArea>
          <HealthcareSites
            filter={patientUpsertModalRouteStore.healthcareSiteFilter}
            onSearch={onHealthcareSitesSearch}
            onHealthcareSiteSuggestionClick={addHealthcareSiteLocally}
            autosuggestedHealthcareSites={patientUpsertModalRouteStore.suggestedHealthcareSites}
            modifiedHealthcareSites={patientUpsertModalRouteStore.activePatientHealthcareSites}
            removeHealthcareSite={removeHealthcareSiteLocally}
          />
          <div className='c-PatientUpsertModalAllergiesContainer'>
            <div className='title'>
              Allergies
            </div>
            <div>
              N/A
            </div>
          </div>
        </ModalEditingArea>
      </ModalEditingAreas>
    </Modal>
  );
};

export default observer(PatientUpsertModal);
