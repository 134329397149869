import { get as _get } from 'lodash';

// Constants
import { PHARMACY_TECHNICIANS as PHARMACY_TECHNICIANS_ROUTE } from 'constants/routes';

// Stores
import stores from 'bootstrap/store';
import { pharmacyTechniciansRouteStore } from '../domain/store';
const pharmacyTechniciansStore = stores.global.pharmacyTechnicians;

export default (pharmacyTechnicianId, navigate) => {
  const isRequestedPharmacyTechnicianAvailable = !!pharmacyTechniciansStore.pharmacyTechnicians.get(pharmacyTechnicianId);

  if (isRequestedPharmacyTechnicianAvailable) {
    pharmacyTechniciansRouteStore.setValue('activeTechnicianId', pharmacyTechnicianId);
  } else {
    // TODO fetch info of the requested entry and show it as active once user scrolls to it
    const firstId = _get(pharmacyTechniciansStore, 'dataAsArray[0].id');
    if (firstId) {
      navigate(`${PHARMACY_TECHNICIANS_ROUTE.VALUE}/${firstId}`);
    } else {
      pharmacyTechniciansRouteStore.setValue('activeTechnicianId', null);
    }
  }
};
