import React from 'react';
import { observer } from 'mobx-react';

import CreatedAt from './components/created-at';
import Resolution from './components/resolution';

import './index.scss';


const AppCommand = ({
  createdAt,
  type,
  typeCaption,
  resolution,
  items = [],
  actionConfig = {},
}) => {
  typeCaption = typeCaption || actionConfig[type];

  return (
    <div className='c-AppCommand'>
      <CreatedAt createdAt={createdAt} />
      <div className='main-content'>
        <div className='main-items'>
          <div className='item type'>
            {
              typeCaption
            }
          </div>
          {
            items
          }
          <Resolution resolution={resolution} />
        </div>
      </div>
    </div>
  );
};

export default observer(AppCommand);
