import React from 'react';
import { observer } from 'mobx-react';

import ContentHeader from 'components/drill-down-layout/components/content/components/content-header';

const LotsContentHeader = ({
  lotsRouteStore,
}) => {
  const { activeLot } = lotsRouteStore;
  if (!activeLot) return null;

  return <ContentHeader
    title={activeLot.number}
  />;
};

export default observer(LotsContentHeader);
