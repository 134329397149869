import React from 'react';

export const ORDER_OPTIONS = {
  name: order => [['name', order]],
  phoneNumber: order => [['phoneNumber', order]],
  location: order => [['location', 'state', order], ['location', 'city', order], ['location', 'street1', order]]
};

export const PHARMACY_MANIPULATION_OPTIONS = {
  CREATE: 'create',
  UPDATE: 'update'
};

export const PHARMACY_SKELETON = {
  name: '',
  phoneNumber: '',
  location: {},
  healthcareSites: [],
};

export const COLUMNS = [
  {
    name: 'Name',
    fieldName: 'name',
    isSortable: true,
    width: '25%',
    render: ({ content }) => (
      <div title={content.name}>{content.name}</div>
    )
  },
  {
    name: 'Phone',
    fieldName: 'phoneNumber',
    isSortable: true,
    width: '15%',
    render: ({ content }) => (
      <div title={content.phoneNumber}>{content.phoneNumber}</div>
    )
  },
  {
    name: 'Location',
    fieldName: 'location',
    isSortable: true,
    width: '20%',
    render: ({ content }) => (
      <div title={content.address}>{content.address}</div>
    )
  },
  {
    name: 'Healthcare Sites',
    fieldName: 'healthcareSites',
    isSortable: false,
    width: '40%',
    render: ({ content }) => (
      <div className='c-HealthcareSitesColumn'>
        {
          content.healthcareSites.map(healthcareSite => (
            <div className='healthcareSite-name' key={healthcareSite.id}>
              {healthcareSite.name}
            </div>
          ))
        }
      </div>
    )
  },
];
