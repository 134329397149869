import React from 'react';
import { observer } from 'mobx-react';

import Search from 'components/drill-down-layout/components/list/components/list-header/components/list-search';

import CrossIcon from 'assets/icons/cross.svg';

import './healthcare-sites-styles.scss';

const HealthcareSites = ({
  filter,
  onSearch,
  onHealthcareSiteSuggestionClick,
  autosuggestedHealthcareSites = [],
  modifiedHealthcareSites = [],
  removeHealthcareSite,
}) => (
  <div className='c-PatientHealthcareSites'>
    <div className='search-container'>
      <Search
        placeholder='Healthcare Site...'
        searchValue={filter}
        onSearch={onSearch}
      />
      {
        !!autosuggestedHealthcareSites.length &&
        <div className='auto-suggest'>
          {
            autosuggestedHealthcareSites.map((healthcareSite) => (
              <div key={healthcareSite.id} className='healthcareSite' onClick={() => onHealthcareSiteSuggestionClick(healthcareSite)}>
                {
                  healthcareSite.name
                }
              </div>
            ))
          }
        </div>
      }
    </div>
    {
      !!modifiedHealthcareSites && !!modifiedHealthcareSites.length &&
        <div className='healthcareSites'>
          {
            modifiedHealthcareSites.map((healthcareSite) => (
              <div key={healthcareSite.id} className='healthcareSite'>
                <div className='name'>
                  {
                    healthcareSite.name
                  }
                </div>
                <img
                  alt='remove'
                  className='remove-icon'
                  src={CrossIcon}
                  onClick={() => removeHealthcareSite(healthcareSite)}
                />
              </div>
            ))
          }
        </div>
    }
  </div>
);

export default observer(HealthcareSites);
