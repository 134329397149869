import React from 'react';
import { observer } from 'mobx-react';
import { get as _get } from 'lodash';

// Components
import ContentHeader from 'components/drill-down-layout/components/content/components/content-header';
import DispenseAttemptStatusDisplay from './components/dispense-attempt-status-display';
import DispenseAttemptStatusManipulation from './components/dispense-attempt-status-manipulation';

// Constants
import { DISPENSE_ATTEMPTS_TAB } from 'models/dispense-attempts/constants';

const DispenseAttemptContentHeader = ({
  dispenseAttemptsStore,
  dispenseAttemptsRouteStore,
  approveDispenseAttempt,
}) => {
  const { activeDispenseAttempt, dispenseAttemptsTab } = dispenseAttemptsRouteStore;
  if (!activeDispenseAttempt) return null;

  const patient = _get(activeDispenseAttempt, 'prescriptionFill.prescription.patient', {});

  return <ContentHeader
    style={{maxHeight: '90px'}}
    title={`${patient.firstName} ${patient.lastName}`}
    subHeader={
      dispenseAttemptsTab === DISPENSE_ATTEMPTS_TAB.PENDING
        ? <DispenseAttemptStatusManipulation
          dispenseAttemptsRouteStore={dispenseAttemptsRouteStore}
          dispenseAttemptsStore={dispenseAttemptsStore}
          dispenseAttemptsAvailable={!!dispenseAttemptsStore.forPharmacistToArray.length}
          approveDispenseAttempt={approveDispenseAttempt}
          source={activeDispenseAttempt.prescriptionFillSource}
        />
        : dispenseAttemptsTab === DISPENSE_ATTEMPTS_TAB.REVIEWED
          ? <DispenseAttemptStatusDisplay
            dispenseAttemptsAvailable={!!dispenseAttemptsStore.forPharmacistToArray.length}
            activeDispenseAttempt={dispenseAttemptsRouteStore.activeDispenseAttempt}
            source={activeDispenseAttempt.prescriptionFillSource}
          />
          : null
    }
  />;
};

export default observer(DispenseAttemptContentHeader);
