import { debounce as _debounce } from 'lodash';

import { listUsers } from './index';

import stores from 'bootstrap/store';
const usersStore = stores.global.users;

export default _debounce(() => {
  usersStore.setValue('dataForPharmacist', {});
  return listUsers();
}, 350);
