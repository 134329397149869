export const SLOT_TYPE = Object.freeze({
  RADIAL: Object.freeze({
    SMALL: 'RS',
    MEDIUM: 'RM',
    LARGE: 'RL',
  }),
  BOX: Object.freeze({
    SMALL: 'BSS',
    MEDIUM: 'BSL',
    LARGE: 'BLS',
    X_LARGE: 'BLL',
  }),
});

export const SLOT_TYPE_LIST = Object.freeze([
  SLOT_TYPE.RADIAL.SMALL,
  SLOT_TYPE.RADIAL.MEDIUM,
  SLOT_TYPE.RADIAL.LARGE,
  SLOT_TYPE.BOX.SMALL,
  SLOT_TYPE.BOX.MEDIUM,
  SLOT_TYPE.BOX.LARGE,
  SLOT_TYPE.BOX.X_LARGE,
]);

export const SLOT_TYPE_READABLE = Object.freeze({
  [SLOT_TYPE.RADIAL.SMALL]: 'Bottle Small',
  [SLOT_TYPE.RADIAL.MEDIUM]: 'Bottle Medium',
  [SLOT_TYPE.RADIAL.LARGE]: 'Bottle Large',
  [SLOT_TYPE.BOX.SMALL]: 'Box Small',
  [SLOT_TYPE.BOX.MEDIUM]: 'Box Medium',
  [SLOT_TYPE.BOX.LARGE]: 'Box Large',
  [SLOT_TYPE.BOX.X_LARGE]: 'Box X-Large',
});
