export const MAX_ENTRIES_PER_PAGE = 10;

export const DISPENSE_ATTEMPTS_TAB = {
  PENDING: 'Pending',
  REVIEWED: 'Reviewed',
};

export const REJECTION_REASONS = [
  { label: 'Custom', value: '' },
  { label: 'Unidentifiable label', value: 'Unable to identify container label.' },
  { label: 'Inappropriate drug', value: 'Inappropriate drug in prescription.' }
];

export const CONTAINER_DISPENSING_STAGE = {
  DISPENSING: 'dispensing',
  DISPENSED: 'dispensed'
};
