import React from 'react';
import { observer } from 'mobx-react';

import './style.scss';

const HealthcareSite = ({
  healthcareSite,
  onClickDelete,
}) => (
  <div className='c-HealthcareSite'>
    {healthcareSite.name}
    <div className='delete-button' onClick={() => onClickDelete(healthcareSite)}>
      x
    </div>
  </div>
);

export default observer(HealthcareSite);
