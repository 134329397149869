import { listHealthcareSitesDebounce } from './index';

import stores from 'bootstrap/store';
import { healthcareSitesRouteStore } from '../domain/store';
const healthcareSitesStore = stores.global.healthcareSites;

export default (e) => {
  healthcareSitesRouteStore.setValue('filterValue', e.target.value);
  healthcareSitesRouteStore.setValue('currentPage', 1);
  healthcareSitesStore.setValue('dataForPharmacist', {});
  return listHealthcareSitesDebounce();
};
