import React from 'react';
import { observer } from 'mobx-react';

// Components
import ListSearch from './components/list-search';
import ListTabs from './components/list-tabs';

import './index.scss';

const ListHeader = ({
  title,
  // Search
  searchValue,
  onSearch,
  searchPlaceholder,
  // Tabs
  tabValues,
  activeTab,
  isActiveTab,
  onTabSelect
}) => {
  return <div className='c-ListHeader'>
    <div className='title-container'>
      <div className='title'>
        {
          title
        }
      </div>
    </div>
    {
      !!onSearch &&
      <div className='search-container'>
        <ListSearch
          searchValue={searchValue}
          onSearch={onSearch}
          placeholder={searchPlaceholder}
        />
      </div>
    }
    {
      !!tabValues &&
      <ListTabs tabValues={tabValues}
        activeTab={activeTab}
        isActiveTab={isActiveTab}
        onTabSelect={onTabSelect} />
    }
  </div>;
};

export default observer(ListHeader);
