import moment from 'moment';

import { OFFLINE_TIMEOUT } from '../constants';

const isKioskOnline = (latestStatusDate) => {
  if (!latestStatusDate) {
    return false;
  }

  const considerOfflineDate = moment().subtract(OFFLINE_TIMEOUT, 'ms');
  return moment(latestStatusDate).isAfter(considerOfflineDate);
};

export default isKioskOnline;
