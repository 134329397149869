import { flow, types } from 'mobx-state-tree';
import {
  debounce as _debounce,
} from 'lodash';
import base from 'models/base';

import { MODEL_NAME, SCHEMA } from './constants/schema';


const LocationModel = base({
  names: {
    singular: MODEL_NAME.SINGULAR,
    plural: MODEL_NAME.PLURAL
  },
  JSONSchema: SCHEMA,
  httpConfig: {
    root: MODEL_NAME.plural,
  }
});

LocationModel.configureStore((store) => {
  return store
  .props({
    autoCompleteData: types.optional(types.array(types.model({
      description: types.optional(types.string, ''),
      id: types.optional(types.string, ''),
      place_id: types.optional(types.string, ''),
    })), [])
  })
  .actions(self => ({
    setValue: (prop, value) => self[prop] = value,
    autocompleteLocation: flow(function* (config = {}) {
      config.urlFragment = () => '/autocomplete';
      config.storeResult = false;

      const response = yield self.list(config);

      self.autoCompleteData = response.data || response || [];

      return response;
    }),
  }))
  .actions(self => ({
    autocompleteLocationDebounce: _debounce(self.autocompleteLocation, 350)
  }))
  .views(self => ({
    get autoCompleteDataAsArray() {
      return self.autoCompleteData;
    },
    get autoCompleteDataForSelect() {
      return self.autoCompleteDataAsArray.map((location) => ({
        label: location.description,
        value: location.place_id,
      }));
    },
  }));
});


export default LocationModel;
