import stores from 'bootstrap/store';
import { patientUpsertModalRouteStore } from '../domain/upsert-modal-store';

const healthcareSitesStore = stores.global.healthcareSites;

export default (e) => {
  patientUpsertModalRouteStore.setValue('healthcareSiteFilter', e.target.value);

  return healthcareSitesStore.listForAdmin({
    query: {
      filter: {
        name: patientUpsertModalRouteStore.healthcareSiteFilter,
      },
    },
  });
};
