import { flow, types } from 'mobx-state-tree';
import {
  get as _get,
  isNil as _isNil,
  keyBy as _keyBy,
  omitBy as _omitBy,
  orderBy as _orderBy,
  reduce as _reduce,
} from 'lodash';

import base from 'models/base';
import { MODEL_NAME, SCHEMA } from './constants/schema';


const PrescriptionFillModel = base({
  names: {
    singular: MODEL_NAME.SINGULAR,
    plural: MODEL_NAME.PLURAL
  },
  JSONSchema: SCHEMA,
  httpConfig: {
    root: MODEL_NAME.plural,
    methods: {}
  }
});

PrescriptionFillModel.configureStore((store) => {
  return store
  .props({
    data: types.optional(types.map(types.frozen()), {}),
    dataForPharmacist: types.optional(types.map(types.frozen()), {}),
    mailOrdered: types.optional(types.map(types.frozen()), {}),
    transfers: types.optional(types.map(types.frozen()), {}),
    pending: types.optional(types.map(types.frozen()), {}),
    mailOrderedForNotificationsPane: types.optional(types.map(types.frozen()), {}),
    transfersForNotificationsPane: types.optional(types.map(types.frozen()), {}),
    entriesCount: types.optional(types.number, 0)
  })
  .actions(self => ({
    setValue: (prop, value) => self[prop] = value,
    setInitialState: () => {
      self.dataForPharmacist = {};
    },
    listPrescriptionFills: flow(function* (config = {}) {
      const response = yield self.list(config);
      return response;
    }),
    count: flow((function* (config = {}) {
      config.urlFragment = () => '/count';
      const response = yield self.get(config);
      return response;
    })),
    listForPharmacist: flow(function* (config = {}, { shouldMerge = true } = {}) {
      const offset = _get(config, 'query.offset', 0);
      const limit = _get(config, 'query.limit', 0);
      if (!offset && self.dataForPharmacist.size > limit) self.dataForPharmacist = {};

      config.urlFragment = () => '/for-pharmacist';
      const response = yield self.list(config);
      const data = response.data || response || [];

      if (shouldMerge) self.dataForPharmacist.merge(_keyBy(data, 'id'));
      else self.dataForPharmacist.replace(_keyBy(data, 'id'));

      return response;
    }),
    listByLotForPharmacist: flow(function* (config = {}) {
      const lotId = _get(config, 'params.lotId');
      if (!lotId) {
        return Promise.reject('lotId must be provided in order to list prescription fills by lot.');
      }

      config.urlFragment = () => `/lots/${lotId}`;
      const response = yield self.list(config);
      const data = response.data || response || [];

      self.dataForPharmacist.replace(_keyBy(data, 'id'));

      return response;
    }),
    listMailOrderedForPharmacist: flow(function* (config = {}, { shouldMerge = true } = {}) {
      const offset = _get(config, 'query.offset', 0);
      const limit = _get(config, 'query.limit', 0);
      if (!offset && self.mailOrdered.size > limit) self.mailOrdered = {};

      config.urlFragment = () => '/mail-order/for-pharmacist';
      const response = yield self.list(config);
      const data = response.data || response || [];

      if (shouldMerge) self.mailOrdered.merge(_keyBy(data, 'id'));
      else self.mailOrdered.replace(_keyBy(data, 'id'));

      return response;
    }),
    listTransfersForPharmacist: flow(function* (config = {}, { shouldMerge = true } = {}) {
      const offset = _get(config, 'query.offset', 0);
      const limit = _get(config, 'query.limit', 0);
      if (!offset && self.transfers.size > limit) self.transfers = {};

      config.urlFragment = () => '/transfer/for-pharmacist';
      const response = yield self.list(config);
      const data = response.data || response || [];

      if (shouldMerge) self.transfers.merge(_keyBy(data, 'id'));
      else self.transfers.replace(_keyBy(data, 'id'));

      return response;
    }),
    countForPharmacist: flow(function* (config = {}) {
      config.urlFragment = () => '/for-pharmacist/count';

      const response = yield self.list(config);
      self.entriesCount = response || 0;

      return response;
    }),
    listUnroutedForPharmacist: flow(function* (config = {}, { shouldMerge = true } = {}) {
      const offset = _get(config, 'query.offset', 0);
      const limit = _get(config, 'query.limit', 0);
      if (!offset && self.dataForPharmacist.size > limit) self.dataForPharmacist = {};

      config.urlFragment = () => '/pending-review/for-pharmacist';
      const response = yield self.list(config);
      const data = response.data || response || [];

      if (shouldMerge) self.dataForPharmacist.merge(_keyBy(data, 'id'));
      else self.dataForPharmacist.replace(_keyBy(data, 'id'));

      return response;
    }),
    listPendingForNotificationsPane: flow(function* (config = {}) {
      config.urlFragment = () => '/pending-review/for-pharmacist';
      const response = yield self.list(config);
      const data = response.data || response || [];

      self.pending = _keyBy(data, 'id');

      return response;
    }),
    listMailOrderedForNotificationsPane: flow(function* (config = {}) {
      config.urlFragment = () => '/mail-order/for-pharmacist';
      const response = yield self.list(config);
      const data = response.data || response || [];

      self.mailOrderedForNotificationsPane = _keyBy(data, 'id');

      return response;
    }),
    listTransfersForNotificationsPane: flow(function* (config = {}) {
      config.urlFragment = () => '/transfer/for-pharmacist';
      const response = yield self.list(config);
      const data = response.data || response || [];

      self.transfersForNotificationsPane = _keyBy(data, 'id');

      return response;
    }),
    createWithReferences: flow(function* (config = {}) {
      config.urlFragment = () => '/with-references';

      const response = yield self.put(config);

      return response;
    }),
    updatePrescriptionFill: flow(function* (prescriptionFillId, { drugId, quantity, copay }) {
      const response = yield self.put({
        urlFragment: () => `/${prescriptionFillId}`,
        body: _omitBy({ drugId, quantity, copay }, _isNil),
      });

      return response;
    })
  }))
  .views(self => ({
    get groupedByDate() {
      const forPharmacist = Array.from(self.dataForPharmacist.values());
      const dateFormat = { year: 'numeric', month: 'long', day: 'numeric'};

      return _reduce(forPharmacist, (groupedByDate, prescriptionFill) => {
        const date = (new Date(prescriptionFill.updatedAt)).toLocaleDateString('en-EN', dateFormat);
        if (!groupedByDate.find(item => item.title === date)) {
          groupedByDate.push({
            id: prescriptionFill.id,
            title: date,
            prescriptionFills: []
          });
          groupedByDate[groupedByDate.length - 1].prescriptionFills.push(prescriptionFill);
        } else {
          const byDateIndex = groupedByDate.findIndex(item => item.title === date);
          groupedByDate[byDateIndex].prescriptionFills.push(prescriptionFill);
        }

        return _orderBy(groupedByDate, ['title'], ['desc']);
      }, []);
    },
    get forPharmacistByDate() {
      const list = Array.from(self.dataForPharmacist.values());

      const byDate = [];
      const grouped = {};
      list.forEach(item => {
        const dateFormat = { year: 'numeric', month: 'long', day: 'numeric'};
        const date = (new Date(item.updatedAt)).toLocaleDateString('en-EN', dateFormat);
        if (!grouped[date]) {
          grouped[date] = {date, data: []};
          byDate.push(grouped[date]);
        }
        grouped[date].data.push(item);
      });

      return _orderBy(byDate, ['date'], ['desc']);
    },
    get mailOrderedByDate() {
      const list = Array.from(self.mailOrdered.values());

      const byDate = [];
      const grouped = {};
      list.forEach(item => {
        const dateFormat = { year: 'numeric', month: 'long', day: 'numeric'};
        const date = (new Date(item.updatedAt)).toLocaleDateString('en-EN', dateFormat);
        if (!grouped[date]) {
          grouped[date] = {date, data: []};
          byDate.push(grouped[date]);
        }
        grouped[date].data.push(item);
      });

      return _orderBy(byDate, ['date'], ['desc']);
    },
    get transfersByDate() {
      const list = Array.from(self.transfers.values());

      const byDate = [];
      const grouped = {};
      list.forEach(item => {
        const dateFormat = { year: 'numeric', month: 'long', day: 'numeric'};
        const date = (new Date(item.updatedAt)).toLocaleDateString('en-EN', dateFormat);
        if (!grouped[date]) {
          grouped[date] = {date, data: []};
          byDate.push(grouped[date]);
        }
        grouped[date].data.push(item);
      });

      return _orderBy(byDate, ['date'], ['desc']);
    },
    get groupedMailOrderByDate() {
      const forPharmacist = Array.from(self.mailOrdered.values());
      const dateFormat = { year: 'numeric', month: 'long', day: 'numeric'};

      return _reduce(forPharmacist, (groupedByDate, prescriptionFill) => {
        const date = (new Date(prescriptionFill.updatedAt)).toLocaleDateString('en-EN', dateFormat);
        if (!groupedByDate.find(item => item.title === date)) {
          groupedByDate.push({
            id: prescriptionFill.id,
            title: date,
            prescriptionFills: []
          });
          groupedByDate[groupedByDate.length - 1].prescriptionFills.push(prescriptionFill);
        } else {
          const byDateIndex = groupedByDate.findIndex(item => item.title === date);
          groupedByDate[byDateIndex].prescriptionFills.push(prescriptionFill);
        }

        return _orderBy(groupedByDate, ['title'], ['desc']);
      }, []);
    },
    get groupedTransfersByDate() {
      const forPharmacist = Array.from(self.transfers.values());
      const dateFormat = { year: 'numeric', month: 'long', day: 'numeric'};

      return _reduce(forPharmacist, (groupedByDate, prescriptionFill) => {
        const date = (new Date(prescriptionFill.updatedAt)).toLocaleDateString('en-EN', dateFormat);
        if (!groupedByDate.find(item => item.title === date)) {
          groupedByDate.push({
            id: prescriptionFill.id,
            title: date,
            prescriptionFills: []
          });
          groupedByDate[groupedByDate.length - 1].prescriptionFills.push(prescriptionFill);
        } else {
          const byDateIndex = groupedByDate.findIndex(item => item.title === date);
          groupedByDate[byDateIndex].prescriptionFills.push(prescriptionFill);
        }

        return _orderBy(groupedByDate, ['title'], ['desc']);
      }, []);
    },
    get forPharmacistToArray() {
      return Array.from(self.dataForPharmacist.values());
    },
    get pendingArray() {
      return Array.from(self.pending.values());
    },
    get mailOrderedForNotificationsPaneArray() {
      return Array.from(self.mailOrderedForNotificationsPane.values());
    },
    get transfersForNotificationsPaneArray() {
      return Array.from(self.transfersForNotificationsPane.values());
    },
  }));
});

// Can't use module.exports because there is a generator function in this file,
// and that causes module.exports to fail for some reason.
export default PrescriptionFillModel;
