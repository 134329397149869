import { types } from 'mobx-state-tree';

export const StockingBatchesRouteStore = types.model({
  stockingBatchesById: types.optional(types.map(types.frozen()), {}),
  activeStockingBatchId: types.maybeNull(types.string),
  activeStockingBatch: types.frozen(),
  currentPage: types.optional(types.number, 1),
  http: types.optional(types.model({
    listingStockingBatches: types.optional(types.number, 0),
    fetchingStockingBatchById: types.optional(types.number, 0),
  }), {}),
})
.actions(self => ({
  setValue: (prop, value) => self[prop] = value,
  setInitialState: () => {
    self.stockingBatchesById = {};
    self.activeStockingBatchId = null;
    self.activeStockingBatch = null;
    self.currentPage = 1;
    self.http = {
      listingStockingBatches: 0,
      fetchingStockingBatchById: 0,
    };
  },
  incrementHttp: (http) => ++self.http[http],
  decrementHttp: (http) => --self.http[http],
  mergeStockingBatchesById: (stockingBatchesById = {}) => self.stockingBatchesById.merge(stockingBatchesById),
}))
.views(self => ({
  get stockingBatchesList() {
    return Array.from(self.stockingBatchesById.values());
  },
  get isBusy() {
    return Boolean(self.http.fetchingStockingBatchById || self.http.listingStockingBatches);
  },
}));

export const stockingBatchesRouteStore = StockingBatchesRouteStore.create({});
