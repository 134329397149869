import React from 'react';
import { observer } from 'mobx-react';

// Components
import Content from 'components/drill-down-layout/components/content';
import ContentTab from 'components/drill-down-layout/components/content/components/content-tab';
import Header from './components/header';

// Routes
import ActivityRoute from '../../routes/activity';
import InventoryRoute from '../../routes/inventory';

// Constants
import { HEALTHCARE_SITES } from 'constants/routes';


const HealthcareSitesContent = ({
  activeHealthcareSite,
}) => (
  <Content
    header={
      <Header
        activeHealthcareSite={activeHealthcareSite}
      />
    }
    isReady={!!activeHealthcareSite}
  >
    <ContentTab title={HEALTHCARE_SITES.CONTENT_TAB.ACTIVITY.TITLE} route={HEALTHCARE_SITES.CONTENT_TAB.ACTIVITY.VALUE}>
      <ActivityRoute activeHealthcareSite={activeHealthcareSite} />
    </ContentTab>
    <ContentTab title={HEALTHCARE_SITES.CONTENT_TAB.INVENTORY.TITLE} route={HEALTHCARE_SITES.CONTENT_TAB.INVENTORY.VALUE}>
      <InventoryRoute activeHealthcareSite={activeHealthcareSite} />
    </ContentTab>
  </Content>
);

export default observer(HealthcareSitesContent);
