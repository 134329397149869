import _ from 'lodash';
import { onAction, onSnapshot } from 'mobx-state-tree';

import stores from 'bootstrap/store';

export const bootstrap = () => {
  onAction(stores, (action) => {
    console.log(`%c ${action.path} ${action.name}`,'color: brown;', ...action.args || [], action);
  });

  // onPatch(rootStore, (...args) => {
  //   console.log('Patch', args[0]);
  // });

  onSnapshot(stores, _.debounce((snapshot) => {
    console.log('Snapshot', snapshot);
  }, 5000, {maxWait: 10000}));
};
