import React from 'react';
import { observer } from 'mobx-react';

import CaptionValueInput from 'components/caption-value-input';
import ContentScreen from 'components/content-screen';
import LabelledCheckbox from 'components/labelled-checkbox';
import Modal from 'components/modal';
import ModalRow from 'components/modal/layout/row';
import ModalButton from 'components/modal/layout/button';

import { EMAIL_RE, PHONE_NUMBER_RE } from './constants';
import { SPECIAL_TYPE as INPUT_TYPE } from 'components/caption-value-input/specialComponents/constants';

import './send-receipt-modal-view.scss';


const SendReceiptModalView = ({
  sendReceiptModalRouteStore,
  isOpen,
  title,
  onClose,
  sendReceipt,
}) => {
  return (
    <Modal
      modalClassName='c-SendReceiptModalView'
      modalStyles={{ height: '50%', width: '50%' }}
      isOpen={isOpen}
      title={title}
      shouldCloseOnOverlayClick={true}
      close={onClose}
    >
      <ContentScreen active={sendReceiptModalRouteStore.isBusy} />
      <ModalRow>
        <CaptionValueInput
          inputFieldClassNames={['max-width']}
          caption='Email'
          value={sendReceiptModalRouteStore.email}
          validationRegex={EMAIL_RE}
          onChange={(value) => sendReceiptModalRouteStore.setValue('email', value)}
        />
      </ModalRow>
      {/* <ModalRow>
        <CaptionValueInput
          type={INPUT_TYPE.PHONE}
          caption='Phone Number'
          value={sendReceiptModalRouteStore.phoneNumber}
          validationRegex={PHONE_NUMBER_RE}
          onChange={(value) => sendReceiptModalRouteStore.setValue('phoneNumber', value)}
        />
      </ModalRow> */}
      <ModalRow>
        <div className='receipt-checkbox'>
          <LabelledCheckbox
            isChecked={sendReceiptModalRouteStore.shouldEmail}
            label='Email Receipt'
            onChange={(value) => sendReceiptModalRouteStore.setValue('shouldEmail', value)}
          />
        </div>
        <div className='receipt-checkbox'>
          <LabelledCheckbox
            isChecked={sendReceiptModalRouteStore.shouldSaveEmail}
            label="Save as Patient's Email"
            onChange={(value) => sendReceiptModalRouteStore.setValue('shouldSaveEmail', value)}
          />
        </div>
      </ModalRow>
      {/* <ModalRow>
        <div className='receipt-checkbox'>
          <LabelledCheckbox
            isChecked={sendReceiptModalRouteStore.shouldText}
            label='Text Receipt'
            onChange={(value) => sendReceiptModalRouteStore.setValue('shouldText', value)}
          />
        </div>
        <div className='receipt-checkbox'>
          <LabelledCheckbox
            isChecked={sendReceiptModalRouteStore.shouldSavePhoneNumber}
            label="Save as Patient's Phone Number"
            onChange={(value) => sendReceiptModalRouteStore.setValue('shouldSavePhoneNumber', value)}
          />
        </div>
      </ModalRow> */}
      <ModalRow shouldBoundToBottom={true}>
        <ModalButton
          color='blue'
          text='Send Receipt'
          isDisabled={!sendReceiptModalRouteStore.canBeSubmitted}
          onClick={() => {
            sendReceipt()
            .then(() => onClose());
          }}
        />
      </ModalRow>
    </Modal>
  );
};

export default observer(SendReceiptModalView);
