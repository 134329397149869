import { get as _get } from 'lodash';

// Constants
import * as ROUTE from 'constants/routes';
import { MAX_ENTRIES_PER_PAGE } from '../constants';

// Stores
import stores from 'bootstrap/store';
import { pharmacyTechniciansRouteStore } from '../domain/store';
const pharmacyTechniciansStore = stores.global.pharmacyTechnicians;
const router = stores.router;

export default () => {
  const query = {
    offset: MAX_ENTRIES_PER_PAGE * (pharmacyTechniciansRouteStore.currentPage - 1),
    limit: MAX_ENTRIES_PER_PAGE,
  };

  const { filterValue } = pharmacyTechniciansRouteStore;
  if (filterValue) {
    query.filter = {
      firstName: filterValue,
      lastName: filterValue,
    };
  }

  const config = { query };

  pharmacyTechniciansRouteStore.incrementHttp('listingTechnicians');
  return pharmacyTechniciansStore.listPharmacyTechniciansForPharmacist(config)
  .catch(() => router.history.push(ROUTE.PHARMACY_TECHNICIANS.VALUE))
  .then((response) => {
    const responseLength = _get(response, 'data.length', 0);
    if (responseLength > MAX_ENTRIES_PER_PAGE - 1) {
      pharmacyTechniciansRouteStore.setValue('currentPage', pharmacyTechniciansRouteStore.currentPage + 1);
    }
  })
  .finally(() => pharmacyTechniciansRouteStore.decrementHttp('listingTechnicians'));
};
