// Celcius
export const MAX_SAFE_TEMPERATURE = 45;
export const MIN_SAFE_TEMPERATURE = 10;

// Percentage
export const MAX_SAFE_CPU_USAGE = 95;
export const MAX_SAFE_MEMORY_USAGE = 95;

export const MAX_SAFE_HUMIDITY = 66;

export const OFFLINE_TIMEOUT = 1000 * 60 * 10;
