import React from 'react';
import { observer } from 'mobx-react';

import DrugUpsertModal from './components/drug-upsert-modal';

import AdminTableLayout from 'components/admin-table-layout';
import Title from 'components/admin-table-layout/components/title';
import TableHeader from 'components/admin-table-layout/components/table-header';
import EntriesCount from 'components/admin-table-layout/components/table-header/components/entries-count';
import NewEntryButton from 'components/admin-table-layout/components/table-header/components/new-entry-button';
import Paginator from 'components/paginator';
import ListSearch from 'components/drill-down-layout/components/list/components/list-header/components/list-search';
import TableContent from 'components/admin-table-layout/components/table-content';
import SmartTable from 'components/smart-table';

import { COLUMNS } from './constants';
import { MAX_TABLE_ENTRIES_PER_PAGE } from 'models/drugs/constants';

import './drugs-styles.scss';

const DrugsView = ({
  drugsRouteStore,
  sortDrugs,
  changePaginationPage,
  onDrugsSearch,
  showDrugUpsertModal,
  closeDrugUpsertModal,
}) => (
  <React.Fragment>
    <DrugUpsertModal
      isOpen={drugsRouteStore.showingDrugUpsertModal}
      activeDrug={drugsRouteStore.activeDrug}
      onClose={() => closeDrugUpsertModal()}
    />
    <AdminTableLayout customClassName={'c-DrugsTable'}>
      <Title
        text='Drugs'
      />
      <TableHeader>
        <div className='left-area'>
          <EntriesCount
            count={drugsRouteStore.entriesCount}
            caption='Drugs'
          />
          <Paginator
            currentPage={drugsRouteStore.currentPage}
            entriesCount={drugsRouteStore.entriesCount}
            entriesPerPage={MAX_TABLE_ENTRIES_PER_PAGE}
            changePage={changePaginationPage}
          />
        </div>
        <div className='right-area'>
          <NewEntryButton
            caption='New Drug'
            onClick={() => showDrugUpsertModal()}
          />
          <ListSearch
            searchValue={drugsRouteStore.filterValue}
            onSearch={onDrugsSearch}
          />
        </div>
      </TableHeader>
      <TableContent>
        <SmartTable
          loading={drugsRouteStore.loading}
          sortingFieldName={drugsRouteStore.sortingColumn.fieldName}
          sortingOrder={drugsRouteStore.sortingColumn.sortingOrder}
          onSortIconClick={sortDrugs}
          rows={drugsRouteStore.forTable}
          onRowClick={showDrugUpsertModal}
          columns={COLUMNS}
        />
      </TableContent>
    </AdminTableLayout>
  </React.Fragment>
);

export default observer(DrugsView);
