import React from 'react';
import { observer } from 'mobx-react';

import AdminTechnicians from 'components/admin-technicians-view';

import { COLUMNS } from './constants';

const PharmacyTechniciansView = ({
  pharmacyTechniciansStore,
  pharmacyTechniciansRouteStore,
  pharmacyTechnicianUpsertModalRouteStore,
  sortPharmacyTechnicians,
  changePaginationPage,
  onPharmacyTechniciansSearch,
  showPharmacyTechnicianUpsertModal,
  addPharmacyLocally,
  removePharmacyLocally,
  onPharmaciesSearch,
  closePharmacyTechnicianUpsertModal,
  createPharmacyTechnician,
  updatePharmacyTechnician,
  deletePharmacyTechnician,
  showPharmacyTechnicianDeleteConfirmationModal,
}) => (
  <AdminTechnicians
    type='Pharmacy'
    store={pharmacyTechniciansStore}
    routeStore={pharmacyTechniciansRouteStore}
    upsertModalRouteStore={pharmacyTechnicianUpsertModalRouteStore}
    smartTableColumns={COLUMNS}
    sortTechnicians={sortPharmacyTechnicians}
    changePaginationPage={changePaginationPage}
    onTechniciansSearch={onPharmacyTechniciansSearch}
    showUpsertModal={showPharmacyTechnicianUpsertModal}
    closeUpsertModal={closePharmacyTechnicianUpsertModal}
    addWorkPlaceLocally={addPharmacyLocally}
    removeWorkPlaceLocally={removePharmacyLocally}
    onWorkPlacesSearch={onPharmaciesSearch}
    createTechnician={createPharmacyTechnician}
    updateTechnician={updatePharmacyTechnician}
    deleteTechnician={deletePharmacyTechnician}
    showDeleteConfirmationModal={showPharmacyTechnicianDeleteConfirmationModal}
  />
);

export default observer(PharmacyTechniciansView);
