import React from 'react';
import { observer } from 'mobx-react';

import { getFormattedDataFromObject } from './helpers';

import './style.scss';

const KeyValuePair = ({
  title,
  keys,
  data,
  format,
}) => (
  <div className='c-KeyValuePair'>
    <div className='title'>
      {
        title
      }
    </div>
    <div className='value'>
      {
        getFormattedDataFromObject(data, keys, format)
      }
    </div>
  </div>
);


export default observer(KeyValuePair);
