import { isNumber as _isNumber } from 'lodash';

import { MAX_SAFE_TEMPERATURE, MIN_SAFE_TEMPERATURE } from '../constants';

const isTemperatureSafe = (temperature) => {
  if (!_isNumber(temperature)) {
    return true;
  }

  if (temperature > MIN_SAFE_TEMPERATURE && temperature < MAX_SAFE_TEMPERATURE) {
    return true;
  } else {
    return false;
  }
};

export default isTemperatureSafe;
