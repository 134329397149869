import React, { useCallback, useEffect } from 'react';
import queryString from 'qs';

// Views
import ChangePinView from './change-pin-view';

// Store
import stores from 'bootstrap/store';
import { changePinRouteStore } from './domain/store';
const usersStore = stores.global.users;
const { router } = stores;


const ChangePinContainer = () => {
  useEffect(() => {
    return () => {
      changePinRouteStore.setInitialState();
    };
  }, []);

  const changePin = useCallback(async (e) => {
    e.preventDefault();
    const parsed = queryString.parse((router.location.search || '').replace(/^\?/g, ''));

    return Promise.resolve()
    .then(() => {
      if (!changePinRouteStore.pin || changePinRouteStore.pin !== changePinRouteStore.pinConfirm) return Promise.reject('Pins are not equal or empty');
      if (!parsed.resetToken || !parsed.email) return Promise.reject('Link is invalid, please verify link and try again');

      return usersStore.changePassword({
        body: {
          email: parsed.email,
          newPassword: changePinRouteStore.pin,
          authResetPasswordToken: parsed.resetToken,
        }
      });
    })
    .then(() => router.history.push('/'))
    .catch((err) => changePinRouteStore.setValue('errorMessage', typeof error === 'string'? err : 'Could not change pin'));
  }, [changePinRouteStore.pin, changePinRouteStore.pinConfirm]);

  return <ChangePinView
    changePinRouteStore={changePinRouteStore}
    changePin={changePin}
  />;
};

export default ChangePinContainer;
