import React from 'react';
import { isNumber } from 'lodash';

import './progress-bar-style.scss';

const ProgressBar = ({
  totalCount,
  progressCount,
}) => {
  if (!isNumber(totalCount) || !isNumber(progressCount)) return null;

  const progressPercentage = (progressCount / totalCount) * 100;
  const remainingPercentage = 100 - progressPercentage;

  return (
    <div className='c-ProgressBar'>
      <div style={{ width: `${progressPercentage}%` }} className='progress'/>
      <div style={{ width: `${remainingPercentage}%` }} className='remaining'/>
    </div>
  );
};

export default ProgressBar;
