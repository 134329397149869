import stores from 'bootstrap/store';

import { NOTIFICATION_TYPE } from '../constants';

const getNotificationsCountByType = () => ({
  [NOTIFICATION_TYPE.DISPENSE_ATTEMPTS]: stores.global.dispenseAttempts.pendingArray.length,
  [NOTIFICATION_TYPE.VIDEO_CONFERENCES]: stores.global.videoConferences.pendingArray.length,
  [NOTIFICATION_TYPE.CALL_REQUESTS]: stores.global.callRequests.queuedList.length,
  [NOTIFICATION_TYPE.ERRORED_KIOSKS]: stores.global.kiosks.erroredList.length,
});

export default getNotificationsCountByType;
