import { get as _get } from 'lodash';

// Constants
import * as ROUTE from 'constants/routes';

// Stores
import stores from 'bootstrap/store';
import { medicalAssistantsRouteStore } from '../domain/store';
const medicalAssistantsStore = stores.global.medicalAssistants;

export default (medicalAssistantId, navigate) => {
  const requestedMedicalAssistantIsAvailable = !!medicalAssistantsStore.medicalAssistants.get(medicalAssistantId);
  if (requestedMedicalAssistantIsAvailable) {
    medicalAssistantsRouteStore.setValue('activeMedicalAssistantId', medicalAssistantId);
  } else {
    // TODO fetch info of the requested entry and show it as active once user scrolls to it
    const firstId = _get(medicalAssistantsStore, 'dataAsArray[0].id');
    if (firstId) {
      navigate(`${ROUTE.MEDICAL_ASSISTANTS.VALUE}/${firstId}`);
    } else {
      medicalAssistantsRouteStore.setValue('activeMedicalAssistantId', null);
    }
  }
};
