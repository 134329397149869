import { toJS } from 'mobx';

import { drugsRouteStore } from '../domain/store';
import { drugUpdateModalRouteStore } from '../components/drug-update-modal/domain/store';

export default (e) => {
  drugsRouteStore.setValue('activeDrugId', e.content.id);
  drugsRouteStore.setValue('showingDrugUpdateModal', true);
  drugUpdateModalRouteStore.setValue('activelyModifiedDrug', toJS(drugsRouteStore.activeDrug));
};
