import React from 'react';
import { observer } from 'mobx-react';

import ListHeader from 'components/drill-down-layout/components/list/components/list-header';

const LotsListHeader = ({
  lotsRouteStore,
  setFilterValue,
}) => (
  <ListHeader
    title='Lots'
    // Search
    searchValue={lotsRouteStore.filterValue}
    onSearch={(e) => setFilterValue(e)}
    searchPlaceholder='Lot Number...'
  />
);

export default observer(LotsListHeader);
