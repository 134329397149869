import React from 'react';
import { observer } from 'mobx-react';

import ContentScreen from 'components/content-screen';
import Search from 'components/drill-down-layout/components/list/components/list-header/components/list-search';
import SmartTable from 'components/smart-table';

import { COLUMNS } from './constants';

import './kiosk-containers-view-styles.scss';

const KioskContainersView = ({
  kioskContainersRouteStore,
  invalidateSelectedContainers,
  onContainerSearch,
}) => {
  const { http, isHttpActive } = kioskContainersRouteStore;

  return <div className='c-KioskContainersView'>
    <ContentScreen active={isHttpActive} />
    <div className='header'>
      <div className='search-container'>
        <Search
          searchValue={kioskContainersRouteStore.filterValue}
          onSearch={(e) => onContainerSearch(e)}
        />
      </div>
      <div className='buttons-container'>
        <button
          className={`button ${!kioskContainersRouteStore.selectedContainers.size && 'button-disabled'}`}
          onClick={() => invalidateSelectedContainers()}
        >
          Invalidate
        </button>
      </div>
    </div>
    <div className='content'>
      <SmartTable
        activeRows={Object.fromEntries(kioskContainersRouteStore.selectedContainers)}
        loading={Boolean(http.fetchingContainers)}
        rows={kioskContainersRouteStore.forTable}
        onRowClick={() => {}}
        columns={COLUMNS}
        onRowSelect={(content) => {
          if (!kioskContainersRouteStore.isContainerPurgeable(content.id)) {
            kioskContainersRouteStore.setSelectedContainer(content.id, content);
          }
        }}
        onRowDeselect={(content) => kioskContainersRouteStore.deleteSelectedContainer(content.id)}
        selectUnavailableCaption='Purgeable'
      />
    </div>
  </div>;
};

export default observer(KioskContainersView);
