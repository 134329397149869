import React from 'react';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';

import Content from 'components/drill-down-layout/components/content';
import Header from './components/header';
import Summary from 'components/prescription-fill-summary';

const PrescriptionFillsContent = ({
  prescriptionFillsMailOrderRouteStore,
  groupedByDate,
  processMailOrder,
}) => {
  return <Content
    header={<Header
      activePrescriptionFill={prescriptionFillsMailOrderRouteStore.activePrescriptionFill}
      groupedPrescriptionFillsCount={groupedByDate.length}
      routingKiosks={toJS(prescriptionFillsMailOrderRouteStore.routingKiosks)}
      processMailOrder={processMailOrder}
    />}
    isReady={!!prescriptionFillsMailOrderRouteStore.activePrescriptionFill}
  >
    {
      prescriptionFillsMailOrderRouteStore.activePrescriptionFill &&
      prescriptionFillsMailOrderRouteStore.activePrescriptionFill.prescription &&
      <div className='r-PrescriptionsView'>
        <Summary
          activePrescriptionFill={prescriptionFillsMailOrderRouteStore.activePrescriptionFill}
          getLocation={(prescriptionFill) => prescriptionFill.mailOrder.patientAddress.location}
          shouldDisplayPayment={true}
        />
      </div>
    }
  </Content>;
};

export default observer(PrescriptionFillsContent);
