import { flow, types } from 'mobx-state-tree';

import base from 'models/base';

import { STATUS, STATUSES } from './constants';
import { MODEL_NAME, SCHEMA } from './constants/schema';


const PharmacistsModel = base({
  names: {
    singular: MODEL_NAME.SINGULAR,
    plural: MODEL_NAME.PLURAL
  },
  JSONSchema: SCHEMA,
  httpConfig: {
    root: MODEL_NAME.plural,
    methods: {}
  }
});

PharmacistsModel.configureStore((store) => {
  return store
  .props({
    status: types.optional(types.enumeration(STATUSES), STATUS.AVAILABLE),
    http: types.optional(types.model({
      settingStatus: types.optional(types.number, 0),
    }), {}),
  })
  .actions(self => ({
    setValue: (prop, value) => self[prop] = value,
    incrementHttp: (http) => ++self.http[http],
    decrementHttp: (http) => --self.http[http],
    setStatus: flow(function* (config = {}) {
      config.urlFragment = () => '/status';

      self.incrementHttp('settingStatus');
      const response = yield self.put(config);

      if (response.status) {
        self.setValue('status', response.status);
      }
      
      self.decrementHttp('settingStatus');
      return response;
    }),
  }));
});


export default PharmacistsModel;
