import { MODAL_ROW_CLASSNAME, CLASSNAME_BY_PROP } from '../constants';

export default (props) => {
  const propNames = Object.keys(props);
  const classNames = propNames.reduce((classNames, propName) => {
    const className = CLASSNAME_BY_PROP[propName];
    if (!className || !props[propName]) return classNames;

    classNames.push(`${MODAL_ROW_CLASSNAME}--${className}`);
    return classNames;
  }, [MODAL_ROW_CLASSNAME]);

  return classNames.join(' ');
};
