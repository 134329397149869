import { types } from 'mobx-state-tree';
import { get as _get, set as _set } from 'lodash';

import stores from 'bootstrap/store';

export const PharmacyTechniciansRouteStore = types.model({
})
.props({
  filterValue: types.optional(types.string, ''),
  activeTechnicianId: types.maybeNull(types.string),
  sortingColumn: types.optional(types.model({
    fieldName: types.optional(types.string, 'name'),
    sortingOrder: types.optional(types.string, 'asc')
  }), {}),
  http: types.optional(types.model({
    listingTechnicians: types.optional(types.number, 0),
    countingTechnicians: types.optional(types.number, 0),
  }), {}),
  currentPage: types.optional(types.number, 1),
  showingUpsertModal: types.optional(types.boolean, false)
})
.actions(self => ({
  setValue: (path, value) => _set(self, path, value),
  incrementHttp: (http) => ++self.http[http],
  decrementHttp: (http) => --self.http[http],
}))
.views(self => ({
  get loading() {
    return !!(self.http.listingTechnicians && self.http.countingTechnicians);
  },
  get activeTechnician() {
    const pharmacyTechniciansStore = stores.global.pharmacyTechnicians;
    return self.activeTechnicianId && pharmacyTechniciansStore.pharmacyTechnicians.get(self.activeTechnicianId);
  },
  get forTable() {
    const pharmacyTechniciansStore = stores.global.pharmacyTechnicians;
    return pharmacyTechniciansStore.dataAsArray.map((technician) => ({
      id: technician.id,
      name: `${technician.firstName} ${technician.lastName}`,
      email: _get(technician, 'ptUser.user.email') || 'N/A',
      phoneNumber: _get(technician, 'ptUser.user.phoneNumber') || 'N/A',
      pharmacies: technician.pharmacies,
    }));
  }
}));

export const pharmacyTechniciansRouteStore = PharmacyTechniciansRouteStore.create({});
