import { flow, types } from 'mobx-state-tree';
import { values } from 'mobx';
import { keyBy as _keyBy, orderBy as _orderBy } from 'lodash';

import base from 'models/base';
import { MODEL_NAME, SCHEMA } from './constants/schema';

const PatientInsuranceModel = base({
  names: {
    singular: MODEL_NAME.SINGULAR,
    plural: MODEL_NAME.PLURAL
  },
  JSONSchema: SCHEMA,
  httpConfig: {
    root: MODEL_NAME.plural,
    methods: {}
  }
});

PatientInsuranceModel.configureStore((store) => {
  return store
  .props({
    data: types.optional(types.map(types.frozen()), {}),
  })
  .actions(self => ({
    setValue: (prop, value) => self[prop] = value,
    listPatientInsurances: flow(function* (config = {}) {
      const response = yield self.list(config);
      const data = response.data || response || [];
      self.data.merge(_keyBy(data, 'id'));

      return response;
    }),
  }))
  .views(self => ({
    get dataList() {
      return values(self.data);
    },
    get dataByHealthcareSiteId() {
      return self.dataList.reduce((byHealthcareSite, insurance) => {
        const { healthcareSite } = insurance;
        byHealthcareSite[healthcareSite.id] = byHealthcareSite[healthcareSite.id] || [];
        byHealthcareSite[healthcareSite.id].push(insurance);

        return byHealthcareSite;
      }, {});
    },
    getHealthcareSiteById(healthcareSiteId) {
      const insurances = self.dataByHealthcareSiteId[healthcareSiteId];
      if (!insurances) return;
      return insurances[0].healthcareSite;
    },
    get dataGroupedByHealthcareSiteList() {
      const healthcareSiteIds = Object.keys(self.dataByHealthcareSiteId);
      const dataByHealthcareSite = healthcareSiteIds.map((healthcareSiteId) => {
        const healthcareSite = self.getHealthcareSiteById(healthcareSiteId);
        return {
          healthcareSite,
          patientInsurances: self.dataByHealthcareSiteId[healthcareSiteId],
        };
      });
      return _orderBy(dataByHealthcareSite, ({ healthcareSite }) => healthcareSite.name);
    },
  }));
});

export default PatientInsuranceModel;
