import React from 'react';
import Gallery from 'components/image-gallery';
import { observer } from 'mobx-react';

import ContentGate from 'components/content-gate';

import '../../prescriptionInfoBlockStyles.scss';

const ContainerLabelInfoBlock = ({
  prescriptionInfoRouteStore,
}) => (
  <div className='c-PrescriptionInfoBlock'>
    <div className='title'>
      Container&apos;s Label
    </div>
    <ContentGate
      active={!prescriptionInfoRouteStore.labelImage}
      fadeDuration={500}
      color='none'
    >
      <Gallery
        enableImageSelection={false}
        images={prescriptionInfoRouteStore.labelImageGallery}
      />
    </ContentGate>
  </div>
);

export default observer(ContainerLabelInfoBlock);
