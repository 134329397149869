import React from 'react';
import Select from 'react-select';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';

import Modal from 'components/modal';
import ModalRow from 'components/modal/layout/row';
import ModalButton from 'components/modal/layout/button';

import { CONSULTATION_REASONS } from 'models/prescription-fills/constants';

import './consultation-request-modal-style.scss';

const ConsultationRequestModal = ({
  showingConsultationRequestModal,
  dispenseAttemptsRouteStore,
  close,
  create,
}) => (
  <Modal
    modalClassName='c-ConsultationRequestModal'
    modalStyles={{ height: '60%', width: '50%' }}
    isOpen={showingConsultationRequestModal}
    shouldCloseOnOverlayClick={true}
    close={close}
    title='Please, specify consultation purpose'
  >
    <Select
      className='reason-select'
      value={toJS(dispenseAttemptsRouteStore.consultationReason)}
      clearable={false}
      searchable={false}
      options={CONSULTATION_REASONS}
      onChange={selectedOption => dispenseAttemptsRouteStore.setValue('consultationReason', selectedOption)}
    />
    <textarea
      className='text-input'
      placeholder='Reason for requesting a consultation...'
      value={dispenseAttemptsRouteStore.consultationReason.value}
      autoFocus={true}
      onChange={(e) => dispenseAttemptsRouteStore.setValue('consultationReason.value', e.target.value)}
    />
    <ModalRow shouldBoundToBottom={true} shouldBeCenteredHorizontally={true}>
      <ModalButton
        isDisabled={!dispenseAttemptsRouteStore.readyToRequestConsultation}
        color='blue'
        text='Request'
        onClick={() => create()}
      />
    </ModalRow>
  </Modal>
);


export default observer(ConsultationRequestModal);
