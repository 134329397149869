import React from 'react';
import { observer } from 'mobx-react';
import { transform as _transform } from 'lodash';
import {
  Routes,
  Route,
  Navigate,
  Link,
  useLocation,
} from 'react-router-dom';

// Components
import ContentGate from 'components/content-gate';
import ContentTab from './components/content-tab';

import './index.scss';


function DrillDownLayout (props) {
  let {
    header,
    isReady = false,
  } = props;

  const children = React.Children.toArray(props.children);

  const tabs = _transform(children, (tabState, child) => {
    if (child.type !== ContentTab) return;
    const tab = child;
    if (!tab.props.title) throw new Error('ContentTab cannot be provided to Content without a title.');
    tabState.order.push(tab.props.title);
    tabState.routes.push(tab.props.route);
    tabState.details[tab.props.title] = {
      title: tab.props.title,
      component: tab,
      route: tab.props.route
    };
  }, {order: [], details: {}, routes: []});

  const location = useLocation();
  const activeTabRoute = tabs.routes.find(((tabRoute) => location.pathname.includes(tabRoute)));
  const pathnameWithoutActiveTab = activeTabRoute ? location.pathname.replace(activeTabRoute, '') : location.pathname;

  return <div className='c-Content'>
    {
      !!header && header
    }
    <ContentGate active={!isReady}
      fadeDuration={500}>
      <div className='content-body'>
        {
          tabs && tabs.order.length
            ? <div className='tabs-container'>
              <div className='tabs-selectors-container'>
                {
                  tabs.order.map(tab => {
                    const details = tabs.details[tab];
                    return <Routes key={details.title} path={pathnameWithoutActiveTab}>
                      <Route
                        path={details.route}
                        element={
                          <Link className='tab-selector active'
                            to={pathnameWithoutActiveTab + details.route}>
                            <div className='tab-text'>{details.title}</div>
                          </Link>
                        }
                      />
                      <Route
                        path='*'
                        element={
                          <Link className='tab-selector not-active'
                            to={pathnameWithoutActiveTab + details.route}>
                            <div className='tab-text'>{details.title}</div>
                          </Link>
                        }
                      />
                    </Routes>;
                  })
                }
              </div>
              <div className='tab-content-container'>
                <Routes path={pathnameWithoutActiveTab}>
                  {
                    tabs.order.map(tab => {
                      return <Route
                        key={tab}
                        path={tabs.details[tab].route}
                        element={tabs.details[tab].component}
                      />;
                    })
                  }
                  <Route
                    path='*'
                    element={<Navigate to={pathnameWithoutActiveTab + tabs.details[tabs.order[0]].route} />}
                  />
                </Routes>
              </div>
            </div>
            : <div className='tabs-container'>
              {
                props.children
              }
            </div>
        }
      </div>
    </ContentGate>
  </div>;
}

export default observer(DrillDownLayout);
