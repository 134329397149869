import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import { get as _get } from 'lodash';

// Views
import VideoConferenceView from './video-conference-view';

// Helpers
import {
  approveDispenseAttempt,
  connect,
  rejectDispenseAttempt,
} from './helpers';

// Stores
import stores from 'bootstrap/store';
import { videoConferencesRouteStore } from './domain/store';
const dispenseAttemptsStore = stores.global.dispenseAttempts;
const videoConferencesStore = stores.global.videoConferences;

const VideoConferenceModalContainer = () => {
  useEffect(() => {
    const videoConference = videoConferencesStore.activePendingConference || {};
    connect(videoConference);

    return () => {
      dispenseAttemptsStore.setValue('remoteDispense', {});
    };
  }, []);

  const leaveRoom = useCallback(async () => {
    const purpose = _get(videoConferencesStore.activePendingConference, 'purpose.name');
    await videoConferencesStore.leaveTotally({ key: purpose });
    videoConferencesStore.setValue('showingVideoConferenceModal', false);
  }, []);


  const purpose = _get(videoConferencesStore.activePendingConference, 'purpose.name');
  const conferenceStatus = videoConferencesStore.conferenceStatuses.get(purpose);
  const twilio = videoConferencesStore.getTwilioConnection(purpose) || {};
  const allowEndingCall = videoConferencesRouteStore.allowEndingCall(purpose);

  return <VideoConferenceView
    showingVideoConferenceModal={videoConferencesStore.showingVideoConferenceModal}
    allowEndingCall={allowEndingCall}
    videoConferencePurpose={purpose}
    remoteTracks={twilio.remoteTracksByParticipant}
    conferenceStatus={conferenceStatus}
    connectingToTwilio={videoConferencesStore.isSettingUpConference(purpose)}
    notificationPayload={_get(videoConferencesStore, 'activePendingConference.payload', {})}
    prescriptionFillsForDisplay={videoConferencesRouteStore.prescriptionFillsForDisplay}
    leaveRoom={leaveRoom}
    approveDispenseAttempt={approveDispenseAttempt}
    rejectDispenseAttempt={rejectDispenseAttempt}
  />;
};


export default observer(VideoConferenceModalContainer);
