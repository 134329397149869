import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

// Views
import PrescriptionFillsManualEntryView from './prescription-fills-manual-entry-view';

// Helpers
import * as helpers from './helpers';

// Stores
import stores from 'bootstrap/store';
import { prescriptionFillsManualEntryRouteStore } from './domain/store';


const PrescriptionFillsManualEntryContainer = () => {
  useEffect(() => {
    helpers.listKioskOptions();

    return () => {
      prescriptionFillsManualEntryRouteStore.setInitialState();
      stores.global.patients.setValue('dataForPharmacist', {});
    };
  }, []);

  useEffect(() => {
    const { NDC, quantity } = prescriptionFillsManualEntryRouteStore.drug;
    if (NDC) {
      stores.global.prescriptions.fetchDirections(NDC, quantity)
      .then((directions) => prescriptionFillsManualEntryRouteStore.setValue('prescription.directions', directions));
    }
  }, [prescriptionFillsManualEntryRouteStore.drug.NDC, prescriptionFillsManualEntryRouteStore.drug.quantity]);

  return (
    <PrescriptionFillsManualEntryView
      prescriptionFillsManualEntryRouteStore={prescriptionFillsManualEntryRouteStore}
      onPatientAutocompleteInputChange={helpers.onPatientAutocompleteInputChange}
      onPatientSuggestionClick={helpers.onPatientSuggestionClick}
      onDrugAutocompleteInputChange={helpers.onDrugAutocompleteInputChange}
      onDrugSuggestionClick={helpers.onDrugSuggestionClick}
      onPhysicianAutocompleteInputChange={helpers.onPhysicianAutocompleteInputChange}
      onPhysicianSuggestionClick={helpers.onPhysicianSuggestionClick}
      upsertPrescriptionFillWithReferences={helpers.upsertPrescriptionFillWithReferences}
    />
  );
};


export default observer(PrescriptionFillsManualEntryContainer);
