import { RouterStore } from '@superwf/mobx-react-router';
import { get as _get } from 'lodash';
import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();
const routingStore = new RouterStore(history);

export const isActiveRoute = (route) => {
  const pathname = _get(routingStore, 'location.pathname');
  if (!pathname) return false;

  return pathname.startsWith(route);
};

export const isActivePathname = (pathname) => {
  const activePathname = _get(routingStore, 'location.pathname');
  return pathname === activePathname;
};

export default routingStore;
