import React from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';

import { isActiveRoute } from 'bootstrap/store/router';

import Route from '../route';

import './multiple-routes-link-styles.scss';

const MultipleRoutesLink = ({
  routeStore,
  isRootRouteActive,
  route,
  constructFullRoute,
}) => {
  const isShowingRouteDropdown = !!routeStore.routeDropdown.get(route.VALUE);
  return <div className='c-MultipleRoutesLink'>
    <Route
      isActiveRoute={isRootRouteActive}
      route={route}
      hasSubRoutes={true}
      isShowingRouteDropdown={isShowingRouteDropdown}
      onClick={(route) => routeStore.showRouteDropdown(route.VALUE, !isShowingRouteDropdown)}
    />
    {
      isShowingRouteDropdown &&
      <div className='sub-routes'>
        {
          Object.values(route.SUB_ROUTE).map((subRoute) => {
            const fullRoute = constructFullRoute(route.VALUE, subRoute.VALUE);
            const isFullRouteActive = isActiveRoute(fullRoute);
            return (
              <Link key={fullRoute} to={fullRoute}>
                <div className={`sub-route ${isFullRouteActive && 'sub-route-active'}`}>
                  <div className={`name ${isFullRouteActive && 'name-active'}`}>
                    {
                      subRoute.NAME
                    }
                  </div>
                </div>
              </Link>
            );
          })
        }
      </div>
    }
  </div>;
};

export default observer(MultipleRoutesLink);
