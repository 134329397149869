// Constants
import * as ROUTE from 'constants/routes';
import { STATUS } from 'models/call-requests/constants/schema';
import { CALL_REQUESTS_TAB } from '../../../../../../../routes/user/patients/routes/call-requests/constants';

// Stores
import stores from 'bootstrap/store';
import { notificationsPaneRouteStore } from '../../../../../domain/store';
const callRequestsStore = stores.global.callRequests;
const callRequestsRouteStore = stores.route.callRequests.domain;
const history = stores.router;

const goToReview = (callRequestId) => {
  callRequestsStore.setValue('dataForPharmacist', {});
  callRequestsRouteStore.setValue('activeListTab', CALL_REQUESTS_TAB.QUEUED);
  callRequestsStore.listForPharmacist({
    query: {
      status: STATUS.QUEUED,
    },
  })
  .then(() => history.push(`${ROUTE.PATIENTS.VALUE}${ROUTE.PATIENTS.SUB_ROUTE.CALL_REQUESTS.VALUE}/${callRequestId}`));

  notificationsPaneRouteStore.close();
};

export default goToReview;
