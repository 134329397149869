import { ORDER_OPTIONS } from '../constants';
import { MAX_TABLE_ENTRIES_PER_PAGE } from 'models/patients/constants';

import stores from 'bootstrap/store';
import { patientsRouteStore } from '../domain/store';
const patientsStore = stores.global.patients;

export default () => {
  const query = {
    offset: MAX_TABLE_ENTRIES_PER_PAGE * (patientsRouteStore.currentPage - 1),
    limit: MAX_TABLE_ENTRIES_PER_PAGE,
  };

  if (patientsRouteStore.filterValue) {
    query.filter = {
      firstName: patientsRouteStore.filterValue,
      lastName: patientsRouteStore.filterValue,
      externalPharmacyId: patientsRouteStore.filterValue,
    };
  }

  if (patientsRouteStore.sortingColumn.fieldName && patientsRouteStore.sortingColumn.sortingOrder) {
    query.order = ORDER_OPTIONS[patientsRouteStore.sortingColumn.fieldName](patientsRouteStore.sortingColumn.sortingOrder);
  }

  const listPatients = () => {
    patientsRouteStore.incrementHttp('listingPatients');
    patientsStore.listForAdmin({ query })
    .then(() => patientsRouteStore.decrementHttp('listingPatients'));
  };

  const countPatients = () => {
    patientsRouteStore.incrementHttp('countingPatients');
    patientsStore.countPatients({ query })
    .then(() => patientsRouteStore.decrementHttp('countingPatients'));
  };

  return Promise.all([
    listPatients(),
    countPatients(),
  ]);
};
