import { flow, types } from 'mobx-state-tree';
import _ from 'lodash';

import base from 'models/base';
import { MODEL_NAME, SCHEMA } from './constants/schema';


const PhysicianModel = base({
  names: {
    singular: MODEL_NAME.SINGULAR,
    plural: MODEL_NAME.PLURAL
  },
  JSONSchema: SCHEMA,
  httpConfig: {
    root: MODEL_NAME.plural,
    methods: {}
  }
});

PhysicianModel.configureStore((store) => {
  return store
  .props({
    dataForPharmacist: types.optional(types.map(types.frozen()), {}),
    entriesCount: types.optional(types.number, 0)
  })
  .actions(self => ({
    setValue: (prop, value) => self[prop] = value,
    listPhysicians: flow(function* (config = {}) {
      const response = yield self.list(config);
      const data = response.data || response || [];

      // If data contains the same elements as dataForPharmacist but in reverse order,
      // mobx-state-tree's map treats it as the same set of elements, so views won't rerender.
      const sameData = _.isEqual(self.dataAsArray.map(i => i.id).sort(), data.map(i => i.id).sort());
      if (sameData) self.dataForPharmacist = {};
      self.dataForPharmacist = _.keyBy(data, 'id');

      return response;
    }),
    listForPharmacist: flow(function* (config = {}) {
      config.urlFragment = () => '/for-pharmacist';

      const response = yield self.list(config);

      return response;
    }),
    countPhysicians: flow(function* (config = {}) {
      config.urlFragment = () => '/count';

      const response = yield self.list(config);
      self.entriesCount = response || 0;

      return response;
    }),
    createPhysician: (config = {}) => {
      return self.post(config);
    },
    updatePhysician: (config = {}) => {
      return self.put(config);
    },
    deletePhysician: (config = {}) => {
      return self.delete(config);
    }
  }))
  .views(self => ({
    get dataAsArray() {
      return Array.from(self.dataForPharmacist.values());
    },
  }));
});

// Can't use module.exports because there is a generator function in this file,
// and that causes module.exports to fail for some reason.
export default PhysicianModel;
