import { listPrescriptionFillsDebounce } from './index';

import stores from 'bootstrap/store';
import { prescriptionFillsMailOrderRouteStore } from '../domain/store';
const prescriptionFillsStore = stores.global.prescriptionFills;

export default (e) => {
  prescriptionFillsMailOrderRouteStore.setValue('prescriptionFillsFilterValue', e.target.value);
  prescriptionFillsMailOrderRouteStore.setValue('currentPage.prescriptionFills', 1);
  prescriptionFillsStore.setValue('mailOrdered', {});

  listPrescriptionFillsDebounce();
};
