import React from 'react';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import _ from 'lodash';
import stores from 'bootstrap/store';
import { diff } from 'deep-object-diff';

// Views
import ModuleTemplatesView from './module-templates-view';

// Constants
import * as ROUTE from 'constants/routes';

// View Store
import { moduleTemplatesRouteStore } from './domain/store';

// Root Module Stores
const moduleTemplatesStore = stores.global.moduleTemplates;
const router = stores.router;

class ModuleTemplatesContainer extends React.Component {
  constructor(props) {
    super(props);
    this.setFilterValue = this.setFilterValue.bind(this);
    this.setActiveModuleTemplate = this.setActiveModuleTemplate.bind(this);
    this.fetchModuleTemplates = this.fetchModuleTemplates.bind(this);
    this.fetchModuleTemplatesWithDebounce = _.debounce(this.fetchModuleTemplates, 350, { leading: true });
    this.upsertModuleTemplate = this.upsertModuleTemplate.bind(this);
    this.removeModuleTemplate = this.removeModuleTemplate.bind(this);
    this.determineActiveModuleTemplate = this.determineActiveModuleTemplate.bind(this);
  }

  componentDidMount() {
    return this.fetchModuleTemplates()
    .then(() => this.determineActiveModuleTemplate());
  }

  setFilterValue(event) {
    moduleTemplatesRouteStore.setValue('filterValue', event.target.value);
    return this.fetchModuleTemplatesWithDebounce()
    .then(this.determineActiveModuleTemplate);
  }

  setActiveModuleTemplate(moduleTemplateId) {
    if (moduleTemplateId && (moduleTemplatesRouteStore.activeModuleTemplateId !== moduleTemplateId)) {
      moduleTemplatesRouteStore.setValue('activeModuleTemplateId', moduleTemplateId);
      moduleTemplatesRouteStore.setValue('modifiedSlotIndex', -1);

      // scroller.scrollTo(0, {
      //   duration: 1,
      //   offset: -10,
      //   containerId: 'slotsList'
      // });
      //
      // scroller.scrollTo(moduleTemplatesRouteStore.modifiedActiveModuleTemplate.id, {
      //   duration: 400,
      //   offset: -10,
      //   smooth: true,
      //   containerId: 'module-templates-wrapper'
      // });
    } else if(!moduleTemplateId) {
      moduleTemplatesRouteStore.setValue('activeModuleTemplateId', null);
      moduleTemplatesRouteStore.setValue('modifiedSlotIndex', -1);
      router.history.push(ROUTE.ADMIN_MODULE_TEMPLATES.VALUE);
    }
  }

  fetchModuleTemplates () {
    const query = {};
    const { filterValue } = moduleTemplatesRouteStore;

    if (filterValue) {
      query.filter = {
        id: filterValue,
      };
    }

    return moduleTemplatesStore.fetch({ query })
    .then(() => {
      moduleTemplatesRouteStore.setValue('modifiedModuleTemplates', moduleTemplatesStore.dataAsArray().map(toJS));
      this.determineActiveModuleTemplate();
    });
  }

  upsertModuleTemplate() {
    if (
      !_.isNil(moduleTemplatesStore.data.get(moduleTemplatesRouteStore.activeModifiedModuleTemplate.id)) &&
      Object.keys(diff(toJS(moduleTemplatesRouteStore.activeModifiedModuleTemplate), toJS(moduleTemplatesRouteStore.activeModuleTemplate))).length === 0
    ) {
      alert('No changes detected.');
      return;
    }

    return moduleTemplatesStore.upsert({
      body: moduleTemplatesRouteStore.activeModifiedModuleTemplate,
    })
    .then(() => this.fetchModuleTemplates());
  }

  removeModuleTemplate(evt, moduleTemplate) {
    evt.stopPropagation();

    if (window.confirm && window.confirm('Are you sure you want to remove this module template?')) {
      if (moduleTemplatesStore.data.has(moduleTemplate.id))
        return moduleTemplatesStore.delete({params: {id: moduleTemplate.id}})
        .then(() => this.fetchModuleTemplates());
      else {
        const index = moduleTemplate.id === moduleTemplatesRouteStore.activeModifiedModuleTemplate.id ? moduleTemplatesRouteStore.modifiedModuleTemplates.findIndex(item => item === moduleTemplate) - 1: null;
        moduleTemplatesRouteStore.removeModuleTemplate(moduleTemplate);
        if (index) this.setActiveModuleTemplate({moduleTemplate: _.get(moduleTemplatesRouteStore.modifiedModuleTemplates, `[${index}]`, null)});
      }
    }
  }

  determineActiveModuleTemplate() {
    const { match: {params} } = router;
    if (params && params.moduleId && _.find(moduleTemplatesStore.dataAsArray(), {id: params.moduleId})) {
      this.setActiveModuleTemplate(params.moduleId);
    } else {
      const firstId = _.get(moduleTemplatesStore.dataAsArray(), '[0].id');
      if (firstId) {
        router.history.push(`${ROUTE.ADMIN_MODULE_TEMPLATES.VALUE}/${firstId}`);
        this.setActiveModuleTemplate(firstId);
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.determineActiveModuleTemplate(nextProps);
  }

  render() {
    return (
      <ModuleTemplatesView
        moduleTemplatesRouteStore={moduleTemplatesRouteStore}
        moduleTemplatesStore={moduleTemplatesStore}
        setFilterValue={this.setFilterValue}
        setActiveModuleTemplate={this.setActiveModuleTemplate}
        upsertModuleTemplate={this.upsertModuleTemplate}
        removeModuleTemplate={this.removeModuleTemplate}
        rootRoute={this.props.rootRoute}
      />
    );
  }
}


export default observer(ModuleTemplatesContainer);
