import { appFrameRouteStore } from '../domain/store';

import { isActiveRoute } from 'bootstrap/store/router';


export default () => {
  const { activeUserAccessibleRouteValues } = appFrameRouteStore;
  const accessibleTabs = Object.keys(activeUserAccessibleRouteValues);
  if (!accessibleTabs.length) return;

  const requestedTab = accessibleTabs.find((tab) => {
    const routes = activeUserAccessibleRouteValues[tab];
    return !!routes.find((route) => isActiveRoute(route));
  });

  if (requestedTab) appFrameRouteStore.setValue('activeUserTypeTab', requestedTab);
  else appFrameRouteStore.setValue('activeUserTypeTab', accessibleTabs[0]);
};
