import React from 'react';
import { observer } from 'mobx-react';

import './modal-button-styles.scss';

const ModalButton = ({
  isDisabled,
  color = 'blue',
  text,
  onClick,
}) => (
  <button
    disabled={isDisabled}
    className={`c-ModalButton c-ModalButton--${color}`}
    onClick={onClick}
  >
    {
      text
    }
  </button>
);

export default observer(ModalButton);
