import {
  closeUserUpsertModal,
  listUsers,
} from './index';

import stores from 'bootstrap/store';
import { usersRouteStore } from '../domain/store';
const usersStore = stores.global.users;

export default () => {
  return usersStore.deleteUser({
    params: {
      id: usersRouteStore.activeUserId,
    },
  })
  .then(() => {
    closeUserUpsertModal();
    return listUsers();
  });
};
