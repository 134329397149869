import {
  get as _get,
  difference as _difference,
} from 'lodash';

import {
  closeMachineTechnicianUpsertModal,
  listMachineTechnicians,
} from './index';

import stores from 'bootstrap/store';
import { machineTechniciansRouteStore } from '../domain/store';
import { machineTechnicianUpsertModalRouteStore } from '../domain/upsert-modal-store';
const machineTechniciansStore = stores.global.machineTechnicians;
const usersStore = stores.global.users;


export default () => {
  const { activelyModifiedTechnician } = machineTechnicianUpsertModalRouteStore;
  const user = _get(activelyModifiedTechnician, 'mtUser.user', {});

  return Promise.all([
    machineTechniciansStore.updateMachineTechnician({
      params: { machineTechnicianId: machineTechniciansRouteStore.activeTechnicianId },
      body: activelyModifiedTechnician,
    }),
    user.id && usersStore.updateUser({
      params: { id: user.id },
      body: user,
    }),
  ])
  .then(() => {
    const machineTechnicianId = activelyModifiedTechnician.id;

    const { activeTechnician } = machineTechniciansRouteStore;
    const initialKioskIds = activeTechnician.kiosks.map((kiosk) => kiosk.id);
    const modifiedKioskIds = activelyModifiedTechnician.kiosks.map((kiosk) => kiosk.id);
    const addedIds = _difference(modifiedKioskIds, initialKioskIds);
    const removedIds = _difference(initialKioskIds, modifiedKioskIds);

    const promises = [];
    addedIds.forEach((kioskId) => promises.push(machineTechniciansStore.addMachine({
      params: {
        machineTechnicianId,
        kioskId,
      },
    })));
    removedIds.forEach((kioskId) => promises.push(machineTechniciansStore.deleteMachine({
      params: {
        machineTechnicianId,
        kioskId,
      },
    })));

    return Promise.all(promises);
  })
  .then(() => {
    closeMachineTechnicianUpsertModal();
    return listMachineTechnicians();
  });
};
