import React from 'react';
import { observer } from 'mobx-react';

import './index.scss';

const KeyValue = ({
  label,
  value,
}) => {
  return (
    <div className='c-KeyValue'>
      <div className='label'>{label}</div>
      <div className='value'>{value}</div>
    </div>
  );
};

export default observer(KeyValue);
