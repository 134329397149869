import React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import medifriendRxIcon from 'assets/icons/medifriend-rx.svg';
import kioskImage from 'assets/images/kiosk.svg';

import './change-password-view.scss';


const ChangePasswordView = ({
  changePasswordRouteStore,
  changePassword
}) => (
  <div className='c-ChangePasswordView'>
    <div className='primary-panel'>
      <div className='sub-panel left-panel'>
        <img
          className='logo-icon'
          src={medifriendRxIcon}
        />
        <img
          className='kiosk-image'
          src={kioskImage}
        />
      </div>
      <form className='sub-panel right-panel' onSubmit={changePassword}>
        <div className='back-to-sign-in'>
          <Link to='/' onClick={() => changePasswordRouteStore.setInitialState()}>
            Back to main page
          </Link>
        </div>
        <div className='greeting-text'>
          <h3>Change password</h3>
        </div>
        <div className='input-fields'>
          <div className='input-field'>
            <div className='input-label'>
              New Password
            </div>
            <div>
              <input value={changePasswordRouteStore.password} onChange={evt => changePasswordRouteStore.setValue('password', evt.target.value)} type='password' autoComplete="new-password" />
            </div>
          </div>
          <div className='input-field'>
            <div className='input-label'>
              Confirm New Password
            </div>
            <div>
              <input value={changePasswordRouteStore.passwordConfirm} onChange={evt => changePasswordRouteStore.setValue('passwordConfirm', evt.target.value)} type='password' autoComplete="new-password" />
            </div>
          </div>
        </div>
        <div className='panel-footer'>
          <input
            className='sign-up-button'
            type='submit'
            value='Change password'
          />
          {
            !!changePasswordRouteStore.errorMessage &&
            <div className='error-message'>
              {
                changePasswordRouteStore.errorMessage
              }
            </div>
          }
        </div>
      </form>
    </div>
  </div>
);

export default observer(ChangePasswordView);
