export const APP_COMMAND_TYPES = {
  DISPENSE_ATTEMPT_APPROVE: 'dispenseAttemptsApprove',
};

export const FILTER_TYPES = {
  all: {
    KEY: 'all',
    NAME: 'All',
  },
  // [APP_COMMAND_TYPES.DISPENSE_ATTEMPT_APPROVE]: {
  //   KEY: APP_COMMAND_TYPES.DISPENSE_ATTEMPT_APPROVE,
  //   NAME: 'Dispense Approved',
  // },
};

export const FILTER_TYPES_LIST = Object.values(FILTER_TYPES);

export const ALL_TYPES_FILTER = FILTER_TYPES.all;
