import { get as _get } from 'lodash';

import stores from 'bootstrap/store';
const drugsStore = stores.global.drugs;
const kiosksRouteStore = stores.route.kiosks.domain;

const fetchDrugsForActiveKiosk = async () => {
  const byDrugId = _get(kiosksRouteStore, 'activeKiosk.computed_inventory.byDrugId');
  if (!byDrugId) return;

  const drugIds = Object.keys(byDrugId);
  if (!drugIds.length) return drugsStore.setValue('drugs', {});

  return drugsStore.listForPharmacistByKiosk({
    params: {
      kioskId: kiosksRouteStore.activeKiosk.id,
    },
  });
};

export default fetchDrugsForActiveKiosk;
