import React from 'react';
import { observer } from 'mobx-react';
import { get as _get } from 'lodash';

import HealthcareSite from './components/healthcareSite';
import CaptionValueSelect from 'components/caption-value-input-with-select';

import './style.scss';

const HealthcareSites = ({
  onChange,
  onClickDelete,
  healthcareSitesStore,
  pharmacyUpsertModalRouteStore,
}) => (
  <div className='c-HealthcareSites'>
    <CaptionValueSelect
      items={healthcareSitesStore.forAutocomplete(_get(pharmacyUpsertModalRouteStore, 'activelyModifiedPharmacy.healthcareSites', []))}
      onChange={onChange}
      onInputChange={val => healthcareSitesStore.listForAutocomplete({
        query: {
          filter: {
            name: val,
          },
        },
      })}
      onFocus={() => healthcareSitesStore.listForAutocomplete()}
      caption='Healthcare Sites'
    />
    <div className='healthcareSites'>
      {
        _get(pharmacyUpsertModalRouteStore, 'activelyModifiedPharmacy.healthcareSites', []).map(healthcareSite => (
          <HealthcareSite key={healthcareSite.name} healthcareSite={healthcareSite} onClickDelete={onClickDelete} />
        ))
      }
    </div>
  </div>
);

export default observer(HealthcareSites);
