import { onPatch } from 'mobx-state-tree';

import stores from 'bootstrap/store';

import { apiWebSocketEmit } from '../../services/api-websocket';

import { EVENT } from '../../bootstrap/api-websocket/constants';
const { EMITTED: { IDENTIFY } } = EVENT;


export const bootstrap = () => {
  const {
    auth,
    users
  } = stores.global;

  // Watch for changes to auth token on users.
  onPatch(users, (patch) => {
    if (patch.path === '/activeToken') {
      auth.setValue('activeToken', patch.value);
      localStorage.setItem('activeToken', patch.value);
    }
  });

  onPatch(auth, (patch) => {
    if (patch.path === '/activeToken') setTimeout(() => {
      apiWebSocketEmit(IDENTIFY, { token: auth.activeToken });

      users.getCurrent();
    });
  });



  // Get Token from local storage.
  const localStorageAuthToken = localStorage.getItem('activeToken');
  if (localStorageAuthToken) auth.setValue('activeToken', localStorageAuthToken);
  setTimeout(() => auth.setValue('hasCheckedLocalStorage', true), 400);
};
