// Constants
import { DISPENSE_ATTEMPTS_TAB} from 'models/dispense-attempts/constants';

// Helpers
import {
  listForPharmacistCautiously as listDispenseAttemptsForPharmacistCautiously,
} from 'models/dispense-attempts/helpers';

// Stores
import stores from 'bootstrap/store';
import { dispenseAttemptsRouteStore } from '../domain/store';
const dispenseAttemptsStore = stores.global.dispenseAttempts;


export default (clear = true) => {
  if (clear) {
    dispenseAttemptsStore.setValue('dataForPharmacist', {});
  }

  const asc = dispenseAttemptsRouteStore.dispenseAttemptsTab === DISPENSE_ATTEMPTS_TAB.PENDING;

  return listDispenseAttemptsForPharmacistCautiously({ asc });
};
