import { types } from 'mobx-state-tree';
import { set as _set, cloneDeep as _cloneDeep } from 'lodash';
import moment from 'moment';

// Helpers
import { unpack } from '../helpers';

export const PrescriptionFillsStatsRouteStore = types.model({
})
.props({
  http: types.optional(types.model({
    count: types.optional(types.number, 0),
  }), {}),
  dateRange: types.optional(types.model({
    start: types.optional(types.Date, new Date()),
    end: types.optional(types.Date, new Date()),
  }), {}),
  invert: types.optional(types.boolean, false),

  reference: types.maybeNull(types.frozen()),
  count: types.maybeNull(types.frozen()),
})
.actions(self => ({
  setValue: (path, value) => _set(self, path, value),
  setStartDate: (date) => {
    if (date === null) return;
    self.dateRange.start = date;
  },
  setEndDate: (date) => {
    if (date === null) return;
    self.dateRange.end = date;
  },
  incrementHttp: (http) => ++self.http[http],
  decrementHttp: (http) => --self.http[http],
  setInitialState: () => {
    // Default to one month ago today.
    self.setStartDate(moment().startOf('month').toDate());
    self.setEndDate();
    self.invert = false;
  }
}))
.views(self => ({
  get prescriptionFillsList() {
    return Array.from(self.prescriptionFillsById.values());
  },
  prescriptionFillHasDispenseAttempt: (prescriptionFill) => {
    return Boolean(prescriptionFill?.dispenseAttempt);
  },
}))
.views(self => ({
  get loading() {
    return Boolean(self.http.count);
  },
  get unpacked() {
    if (!self.count) return null;
    
    return {
      filled: unpack(self.count, self.reference, 'filled', self.invert),
      dispensed: unpack(self.count, self.reference, 'dispensed', self.invert),
      mailed: unpack(self.count, self.reference, 'mailed', self.invert),
      declined: unpack(self.count, self.reference, 'declined', self.invert),
    };
  }
}));

export const prescriptionFillsStatsRouteStore = PrescriptionFillsStatsRouteStore.create({});
