import React from 'react';
import { observer } from 'mobx-react';
import { get as _get } from 'lodash';

import ContentHeader from 'components/drill-down-layout/components/content/components/content-header';
import Actions from './components/actions';

import formatPhoneNumber from 'utils/formatPhoneNumber';

import PatientIcon from 'assets/icons/patient.svg';

import './patient-icon.scss';


const PatientCallRequestsContentHeader = ({
  callRequestsRouteStore,
  activeCallRequest = {},
  isReady,
  createPharmacistAction,
}) => {
  if (!isReady) return null;

  // Available right away. No need to fetch it.
  const patient = _get(callRequestsRouteStore, 'activeCallRequest.patientCallRequest.patient');
  const phoneNumber = _get(callRequestsRouteStore, 'activeCallRequest.phoneNumber', 'N/A');

  return <ContentHeader
    title={patient ? `${patient.firstName} ${patient.lastName}` : 'N/A'}
    subtitle={formatPhoneNumber(phoneNumber)}
    subHeader={
      <Actions
        callRequestsRouteStore={callRequestsRouteStore}
        activeCallRequest={activeCallRequest}
        createPharmacistAction={createPharmacistAction}
      />
    }
    leftLabel={
      <img
        alt='Patient'
        src={PatientIcon}
        className='patient-icon-preview-large'
      />
    }
  />;
};

export default observer(PatientCallRequestsContentHeader);
