import { pharmacyTechniciansRouteStore } from '../domain/store';

import { listPharmacyTechnicians } from './index';


export default (fieldName, sortingOrder) => {
  pharmacyTechniciansRouteStore.setValue('sortingColumn', {
    ...pharmacyTechniciansRouteStore.sortingColumn,
    fieldName,
    sortingOrder
  });

  return listPharmacyTechnicians();
};
