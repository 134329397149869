// Icons
import ApprovedIcon from 'assets/icons/online.svg';
import RejectedIcon from 'assets/icons/offline.svg';
import VideoIcon from 'assets/icons/video.svg';

export const STATUS = {
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  CONSULTATION_REQUESTED: 'CONSULTATION_REQUESTED',
  UNIDENTIFIABLE: 'UNIDENTIFIABLE',
};

export const PROPS = {
  [STATUS.APPROVED]: {
    title: 'Approved',
    className: 'approved',
    icon: ApprovedIcon,
  },
  [STATUS.REJECTED]: {
    title: 'Rejected',
    className: 'rejected',
    icon: RejectedIcon,
  },
  [STATUS.CONSULTATION_REQUESTED]: {
    title: 'Consultation Requested',
    className: 'consultation-requested',
    icon: VideoIcon,
  },
  [STATUS.UNIDENTIFIABLE]: {
    title: 'Unidentifiable',
    className: 'unidentifiable',
    icon: null,
  },
};
