import deepFreeze from 'deep-freeze';

export const MODEL_NAME = Object.freeze({
  SINGULAR: 'keycard',
  PLURAL: 'keycards'
});

export const FIELD_NAME = Object.freeze({
  ID: 'id',
  VALUE: 'value',
  USER_ID: 'userId',
  PHARMACY_ID: 'pharmacyId',
  DISABLED: 'disabled',
  USER: 'user',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  PIN: 'pin'
});

export const SCHEMA = deepFreeze({
  title: MODEL_NAME.SINGULAR,
  type: 'object',
  properties: {
    [FIELD_NAME.ID]: {
      type: 'string'
    },
    [FIELD_NAME.VALUE]: {
      type: 'string'
    },
    [FIELD_NAME.PIN]: {
      type: 'string'
    },
    [FIELD_NAME.USER_ID]: {
      type: 'string'
    },
    [FIELD_NAME.PHARMACY_ID]: {
      type: 'string'
    },
    [FIELD_NAME.DISABLED]: {
      type: 'boolean'
    },
    [FIELD_NAME.USER]: {
      type: 'object',
      properties: {
        [FIELD_NAME.ID]: {
          type: 'string'
        },
        [FIELD_NAME.FIRST_NAME]: {
          type: 'string'
        },
        [FIELD_NAME.LAST_NAME]: {
          type: 'string'
        },
      }
    }
  },
  required: [
    FIELD_NAME.VALUE,
    FIELD_NAME.USER_ID,
  ]
});
