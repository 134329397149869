import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { get as _get, orderBy as _orderBy } from 'lodash';

import StockingBatchDrugsView from './drugs-view';

const StockingBatchDrugsContainer = ({ activeStockingBatch }) => {
  const [filter, setFilter] = useState('');

  const drugsCount = useMemo(() => {
    const grouped = {};

    (activeStockingBatch?.containers || []).forEach((container) => {
      const drugName = _get(container, 'drug.name', 'N/A');
      const labelNDC = container.labelNDC || 'N/A';
      const quantity = container.quantity || 'N/A';

      grouped[labelNDC] = grouped[labelNDC] || {};
      grouped[labelNDC][quantity] = grouped[labelNDC][quantity] || {};
      grouped[labelNDC][quantity][drugName] = grouped[labelNDC][quantity][drugName] || 0;
      grouped[labelNDC][quantity][drugName] = grouped[labelNDC][quantity][drugName] + 1;
    });

    return grouped;
  }, [activeStockingBatch]);

  const drugsList = useMemo(() => {
    const list = [];

    Object.entries(drugsCount).forEach(([labelNDC, drugNameByQuantity]) => {
      Object.entries(drugNameByQuantity).forEach(([quantity, countByDrugName]) => {
        Object.entries(countByDrugName).forEach(([drugName, count]) => {
          const drug = {
            drugName,
            labelNDC,
            quantity,
            count,
          };
    
          if (filter) {
            const filterRegex = new RegExp(filter, 'i');
            if (filterRegex.test(drugName) || filterRegex.test(labelNDC) || filterRegex.test(quantity)) {
              list.push(drug);
            }
          } else {
            list.push(drug);
          }
        });
      });
    });

    return _orderBy(list, ['drugName', 'asc']);
  }, [drugsCount, filter]);

  return <StockingBatchDrugsView
    filter={filter}
    setFilter={setFilter}
    drugsList={drugsList}
  />;
};

export default observer(StockingBatchDrugsContainer);
