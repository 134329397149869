import { listKeycards } from './index';

import { keycardsRouteStore } from '../domain/store';

export default (fieldName, sortingOrder) => {
  keycardsRouteStore.setValue('sortingColumn', {
    ...keycardsRouteStore.sortingColumn,
    fieldName,
    sortingOrder,
  });

  return listKeycards();
};
