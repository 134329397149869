import React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import './index.scss';

const ListItem = ({
  url,
  itemKey,
  title,
  icon,
  subIcon,
  children,
  iconStyle,
  // State
  isActive = false,
  onSelect = () => {}
}) => {
  return <div className='c-ListItem'>
    {
      itemKey &&
      <div className={`item-key-container ${isActive ? 'active' : ''}`}>
        <div className={`item-key ${isActive ? 'active' : ''}`}>
          {
            itemKey
          }
        </div>
      </div>
    }
    <Link
      className={`list-item ${isActive ? 'active' : 'not-active'}`}
      onClick={() => onSelect()}
      to={url}
    >
      <div className='icon-container'>
        <div className='icon' style={iconStyle}>
          {
            icon
          }
        </div>
        <div className='icon'>
          {
            !!subIcon && subIcon
          }
        </div>
      </div>
      <div className='content-container'>
        <div className='title-container'>
          {
            typeof(title) === 'string'
              ? <div className='title'>
                {
                  title
                }
              </div>
              : title
          }
        </div>
        {
          !!children &&
          <div className='body-container'>
            {
              children
            }
          </div>
        }
      </div>
    </Link>
  </div>;
};

export default observer(ListItem);
