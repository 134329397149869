import React from 'react';
import { observer } from 'mobx-react';

// Components
import SmartTable from 'components/smart-table';

// Styles
import './slots-table-styles.scss';


const KioskSlotsTable = ({
  rows = [],
}) => {
  return <SmartTable
    customClassName='c-KioskSlotsTable'
    rows={rows}
    columns={[{
      name: 'Container Size',
      fieldName: 'containerSize',
      isSortable: false,
      width: '40%',
      render: ({ content }) => (
        <div key={content.containerSize} title={content.containerSize}>
          {
            content.containerSize
          }
        </div>
      )
    },
    {
      name: 'Vacant Slots',
      fieldName: 'vacantSlotsCount',
      isSortable: false,
      width: '20%',
      render: ({ content }) => (
        <div key={content.vacantSlotsCount} title={content.vacantSlotsCount}>
          {
            content.vacantSlotsCount
          }
        </div>
      )
    },
    {
      name: 'Purgeable Slots',
      fieldName: 'purgeableSlotsCount',
      isSortable: false,
      width: '20%',
      render: ({ content }) => (
        <div key={content.purgeableSlotsCount} title={content.purgeableSlotsCount}>
          {
            content.purgeableSlotsCount
          }
        </div>
      )
    },
    {
      name: 'Total Slots',
      fieldName: 'totalSlotsCount',
      isSortable: false,
      width: '20%',
      render: ({ content }) => (
        <div key={content.totalSlotsCount} title={content.totalSlotsCount}>
          {
            content.totalSlotsCount
          }
        </div>
      )
    }]}
  />;
};

export default observer(KioskSlotsTable);
