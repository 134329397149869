import React from 'react';

export const ORDER_OPTIONS = {
  name: order => [['firstName', order], ['lastName', order ]],
  NPI: order => [['NPI', order]],
  SPI: order => [['SPI', order]]
};

export const PHYSICIAN_MANIPULATION_OPTIONS = {
  CREATE: 'create',
  UPDATE: 'update'
};

export const PHYSICIAN_SKELETON = {
  firstName: '',
  lastName: '',
  NPI: '',
  SPI: '',
  stateLicenseNumber: ''
};

export const COLUMNS = [
  {
    name: 'Name',
    fieldName: 'name',
    isSortable: true,
    width: '30%',
    render: ({ content }) => (
      <div title={content.id}>{content.name}</div>
    )
  },
  {
    name: 'NPI',
    fieldName: 'NPI',
    isSortable: true,
    width: '15%',
    render: ({ content }) => (
      <div title={content.id}>{content.NPI}</div>
    )
  },
  {
    name: 'SPI',
    fieldName: 'SPI',
    isSortable: true,
    width: '15%',
    render: ({ content }) => (
      <div title={content.id}>{content.SPI}</div>
    )
  },
  {
    name: 'State License',
    fieldName: 'stateLicenseNumber',
    width: '20%',
    render: ({ content }) => (
      <div title={content.id}>{content.stateLicenseNumber}</div>
    )
  },
  {
    name: 'Primary Phone',
    fieldName: 'phoneNumber',
    width: '20%',
    render: ({ content }) => (
      <div title={content.id}>{content.phoneNumber}</div>
    )
  }
];