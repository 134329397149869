import React from 'react';
import { observer } from 'mobx-react';

import ContentHeader from 'components/drill-down-layout/components/content/components/content-header';

const StockingBatchesContentHeader = ({
  activeStockingBatch,
}) => {
  if (!activeStockingBatch) {
    return null;
  }

  return <ContentHeader
    title={new Date(activeStockingBatch.createdAt).toLocaleString()}
    subtitle={activeStockingBatch.kiosk.name}
  />;
};

export default observer(StockingBatchesContentHeader);
