import React from 'react';
import { observer } from 'mobx-react';

const CreatedAt = ({ createdAt }) => (
  <div className='item created-at'>
    {
      new Date(createdAt).toLocaleTimeString([], { hour: 'numeric', minute: 'numeric' })
    }
  </div>
);

export default observer(CreatedAt);
