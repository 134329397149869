import { get as _get } from 'lodash';

// Constants
import * as ROUTE from 'constants/routes';

// Stores
import stores from 'bootstrap/store';
import { machineTechniciansRouteStore } from '../domain/store';
const machineTechniciansStore = stores.global.machineTechnicians;

const determineActiveMachineTechnician = (machineTechnicianId, navigate) => {
  const requestedMachineTechnicianIsAvailable = !!machineTechniciansStore.machineTechnicians.get(machineTechnicianId);
  if (requestedMachineTechnicianIsAvailable) {
    machineTechniciansRouteStore.setValue('activeTechnicianId', machineTechnicianId);
  } else {
    // TODO fetch info of the requested entry and show it as active once user scrolls to it
    const firstId = _get(machineTechniciansStore, 'dataList[0].id');
    if (firstId) {
      navigate(`${ROUTE.MACHINE_TECHNICIANS.VALUE}/${firstId}`);
    } else {
      machineTechniciansRouteStore.setValue('activeTechnicianId', null);
    }
  }
};

export default determineActiveMachineTechnician;
