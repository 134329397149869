import React from 'react';
import { observer } from 'mobx-react';

import { orderBy as _orderBy, get as _get } from 'lodash';


import KioskIcon from 'assets/icons/kiosk-active.svg';

import './prescription-style.scss';


function PrescriptionFill ({ prescriptionFill }) {
  const routing =
    _get(
      _orderBy(prescriptionFill.prescriptionFillKioskRoutings, ['createdAt'], ['desc']),
      '[0]'
    );

  return <div className='c-MailOrderedPrescriptionListItem'>
    <div className='prescription-data'>
      <div className='flex-row'>
        <div className='label'>RX ID</div>
        <div className='value'>{prescriptionFill.prescription.rxId}</div>
      </div>
      <div className='flex-row'>
        <div className='label'>DRUG</div>
        <div className='value'>{prescriptionFill.prescription.drug.name}</div>
      </div>
      <div className='flex-row'>
        <div className='label'>DR</div>
        <div className='value'>{prescriptionFill.prescription.physician.firstName} {prescriptionFill.prescription.physician.lastName}</div>
      </div>
      <div className='kiosk-name-container'>
        <div className='kiosk-name'>
          <div className='icon-container'>
            <img className='icon' src={KioskIcon} />
          </div>
          <div className='name-text'>{routing.kiosk.name}</div>
        </div>
      </div>
    </div>
  </div>;
}

export default observer(PrescriptionFill);
