import { get as _get, map as _map } from 'lodash';
import { toJS } from 'mobx';
import { types } from 'mobx-state-tree';

import { SLOT_TYPE_READABLE } from 'models/module-slot-templates/constants';

export const KioskContainersRouteStore = types.model({
  containers: types.optional(types.array(types.frozen()), []),
  inventory: types.frozen(),
  selectedContainers: types.optional(types.map(types.frozen()), {}),
  filterValue: types.optional(types.string, ''),
  http: types.optional(types.model({
    fetchingContainers: types.optional(types.number, 0),
    fetchingInventory: types.optional(types.number, 0),
    invalidatingContainers: types.optional(types.number, 0),
  }), {}),
})
.actions((self) => ({
  setValue: (prop, value) => self[prop] = value,
  setSelectedContainer: (containerId, container) => self.selectedContainers.set(containerId, container),
  deleteSelectedContainer: (containerId) => self.selectedContainers.delete(containerId),
  incrementHttp: (http) => ++self.http[http],
  decrementHttp: (http) => --self.http[http],
  reset: () => {
    self.containers = [];
    self.inventory = null;
    self.selectedContainers = {};
    self.filterValue = '';
    self.http = {};
  },
}))
.views((self) => ({
  get containersById() {
    const containersById = {};
    self.containers.forEach((container) => {
      containersById[container.id] = container;
    });
    return containersById;
  },
  get computedInventory() {
    if (!self.inventory) {
      return null;
    }

    return _get(self, 'inventory.kiosk.computed_inventory', null);
  },
  get selectedContainerIds() {
    return Array.from(self.selectedContainers.keys());
  },
  get isHttpActive() {
    return Object.values(toJS(self.http)).some((httpValue) => Boolean(httpValue));
  },
  get purgeableSlotIds() {
    const bySlotType = _get(self, 'computedInventory.bySlotType', {});
    const slotTypes = Object.keys(bySlotType);

    return slotTypes.reduce((agg, slotType) => {
      const slots = _get(bySlotType, `${slotType}.slots.purgeable`, []);
      const ids = _map(slots, 'kioskModuleSlotId');
      agg[slotType] = new Set(ids);
      return agg;
    }, {});
  },
}))
.views((self) => ({
  isContainerPurgeable: (containerId) => {
    const container = self.containersById[containerId];
    
    const slotType = container?.kioskModuleSlot?.reference_slotTemplateId;
    if (!slotType || !self.purgeableSlotIds[slotType]) return false;

    return self.purgeableSlotIds[slotType].has(container.kioskModuleSlot?.id);
  },
}))
.views((self) => ({
  get forTable() {
    return self.containers.map((container) => {
      let expiresAt;
      if (container.computed_effectiveExpiresAt) {
        const effectiveExpiresAtDate = new Date(container.computed_effectiveExpiresAt);
        expiresAt = `${effectiveExpiresAtDate.getMonth() + 1}/${effectiveExpiresAtDate.getDate()}/${effectiveExpiresAtDate.getFullYear()}`;
      } else {
        expiresAt = 'N/A';
      }

      let slotTypeFormatted;
      let slotType = _get(container, 'kioskModuleSlot.reference_slotTemplateId');
      if (slotType) {
        slotTypeFormatted = `${slotType} (${SLOT_TYPE_READABLE[slotType]})`;
      } else {
        slotTypeFormatted = 'N/A';
      }

      return {
        id: container.id,
        labelNDC: container.labelNDC || 'N/A',
        quantity: container.quantity || 'N/A',
        drugName: container?.drug?.name || 'N/A',
        drugNDC: container?.drug?.NDC || 'N/A',
        slot: container.kioskModuleSlot.reference_slotName || 'N/A',
        module: container.kioskModuleSlot.reference_kioskModuleName || 'N/A',
        slotType: slotTypeFormatted,
        labelled: container.labelled ? 'Yes' : 'No',
        expiresAt,
        isSelectable: !self.isContainerPurgeable(container.id),
      };
    });
  },
}));

export const kioskContainersRouteStore = KioskContainersRouteStore.create({});
