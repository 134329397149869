import React from 'react';
import { observer } from 'mobx-react';

import './edit-mode-control-styles.scss';

const EditModeControls = ({ isEditMode, enterEditMode, exitEditMode }) => {
  if (isEditMode) {
    return <button className='c-EditModeControl c-EditModeControl-red' onClick={() => exitEditMode()}>
      Cancel
    </button>;
  } else {
    return <button className='c-EditModeControl c-EditModeControl-blue' onClick={() => enterEditMode()}>
      Edit
    </button>;
  }
};

export default observer(EditModeControls);
