import React from 'react';
import { observer } from 'mobx-react';

import ContentHeader from 'components/drill-down-layout/components/content/components/content-header';

import { KIOSKS } from 'constants/routes';

import './header-styles.scss';

const KiosksContentHeader = ({
  activeKiosk,
  activeTab,
  openExportInventoryModal,
}) => {
  if (!activeKiosk) return null;

  const location = activeKiosk.location
    ? `${activeKiosk.location.street1}, ${activeKiosk.location.city}, ${activeKiosk.location.state} ${activeKiosk.location.zip}`
    : 'N/A';

  return <ContentHeader
    title={activeKiosk.name}
    subtitle={location}
    subHeader={
      activeTab === KIOSKS.CONTENT_TAB.INVENTORY.TITLE &&
      <button className='export-simplified-inventory-btn' onClick={openExportInventoryModal}>
        Export Simplified Inventory
      </button>
    }
  />;
};

export default observer(KiosksContentHeader);
