import { useCallback } from 'react';

const pingTimestamps = {};

const listeners = {};


export default function useGlobalActivityPing(
  activityType,
) {
  const getLatestPing = useCallback(() => pingTimestamps[activityType], [activityType]);
  const getSinceLatestPing = useCallback(() => {
    const latest = getLatestPing();
    if (!latest) return null;
    return Date.now() - latest;
  }, [getLatestPing]);

  const ping = () => {
    const latest = pingTimestamps[activityType] = Date.now();
    if (listeners[activityType]) {
      listeners[activityType].forEach((fn) => fn(getLatestPing()));
    }

    return latest;
  };

  return {
    ping,
    getLatestPing,
    getSinceLatestPing,
    onPing: (activityType, fn) => {
      listeners[activityType] = listeners[activityType] || new Set();
      listeners[activityType].add(fn);
    },
    offPing: (activityType, fn) => {
      if (listeners[activityType]) listeners[activityType].delete(fn);
    },
  };
}
