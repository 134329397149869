import { types } from 'mobx-state-tree';

import stores from 'bootstrap/store';

import { CHART_TITLE, SUPPORTED_CHARTS } from '../constants';

export const KioskStatusesRouteStore = types.model({
  http: types.optional(types.model({
    fetchingKioskStatuses: types.optional(types.number, 0),
  }), {}),
})
.actions((self) => ({
  incrementHttp: (http) => self.http[http] += 1,
  decrementHttp: (http) => self.http[http] -= 1,
  reset: () => {
    self.http = {};
  },
}))
.views(() => ({
  get chartDataByKioskStatusField() {
    const chartDataByKioskStatusField = {};

    SUPPORTED_CHARTS.forEach((kioskStatusField) => {
      const chartData = {
        labels: [],
        datasets: [
          {
            label: '',
            data: [],
            borderColor: '#59A3CF',
            backgroundColor: '#59A3CF',
          },
        ],
      };
      const chartTitle = CHART_TITLE[kioskStatusField];
  
      if (!chartTitle) {
        chartData.datasets[0].label = 'Unknown Chart Type';
        chartDataByKioskStatusField[kioskStatusField] = chartData;
        return;
      }
      chartData.datasets[0].label = chartTitle;
  
      if (!stores.global.kiosks.activeKioskStatuses?.kioskStatuses?.length) {
        chartDataByKioskStatusField[kioskStatusField] = chartData;
        return;
      }
  
      const { kioskStatuses } = stores.global.kiosks.activeKioskStatuses;
      // Kiosk statuses are sorted descending by creation date.
      for (let i = kioskStatuses.length; i > 0; i--) {
        const kioskStatus = kioskStatuses[i - 1];
        
        const label = `${new Date(kioskStatus.createdAt).toLocaleDateString()} ${new Date(kioskStatus.createdAt).toLocaleTimeString()}`;
        chartData.labels.push(label);
  
        const value = kioskStatus[kioskStatusField];
        chartData.datasets[0].data.push(value);
      }

      chartDataByKioskStatusField[kioskStatusField] = chartData;
    });

    return chartDataByKioskStatusField;
  },
}));

export const kioskStatusesRouteStore = KioskStatusesRouteStore.create({});
