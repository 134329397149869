import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import PharmacyTechniciansView from './pharmacy-technicians-view';

// Helpers
import * as helpers from './helpers';

// Stores
import stores from 'bootstrap/store';
import { pharmacyTechniciansRouteStore } from './domain/store';
import { pharmacyTechnicianUpsertModalRouteStore } from './domain/upsert-modal-store';
const pharmacyTechniciansStore = stores.global.pharmacyTechnicians;


const PharmacyTechniciansContainer = () => {
  useEffect(() => {
    helpers.listPharmacyTechnicians();
  }, []);

  return (
    <PharmacyTechniciansView
      pharmacyTechniciansStore={pharmacyTechniciansStore}
      pharmacyTechniciansRouteStore={pharmacyTechniciansRouteStore}
      pharmacyTechnicianUpsertModalRouteStore={pharmacyTechnicianUpsertModalRouteStore}
      sortPharmacyTechnicians={helpers.sortPharmacyTechnicians}
      changePaginationPage={helpers.changePaginationPage}
      onPharmacyTechniciansSearch={helpers.onPharmacyTechniciansSearch}
      showPharmacyTechnicianUpsertModal={helpers.showPharmacyTechnicianUpsertModal}
      closePharmacyTechnicianUpsertModal={helpers.closePharmacyTechnicianUpsertModal}
      showPharmacyTechnicianDeleteConfirmationModal={helpers.showPharmacyTechnicianDeleteConfirmationModal}
      addPharmacyLocally={helpers.addPharmacyLocally}
      removePharmacyLocally={helpers.removePharmacyLocally}
      onPharmaciesSearch={helpers.onPharmaciesSearch}
      createPharmacyTechnician={helpers.createPharmacyTechnician}
      updatePharmacyTechnician={helpers.updatePharmacyTechnician}
      deletePharmacyTechnician={helpers.deletePharmacyTechnician}
    />
  );
};

export default observer(PharmacyTechniciansContainer);
