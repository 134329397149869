import { flow, types } from 'mobx-state-tree';
import _ from 'lodash';

import base from 'models/base';
import { MODEL_NAME, SCHEMA } from './constants/schema';


const PrescriptionModel = base({
  names: {
    singular: MODEL_NAME.SINGULAR,
    plural: MODEL_NAME.PLURAL
  },
  JSONSchema: SCHEMA,
  httpConfig: {
    root: MODEL_NAME.plural,
    methods: {}
  }
});

PrescriptionModel.configureStore((store) => {
  return store
  .props({
    dataForPharmacist: types.optional(types.map(types.frozen()), {})
  })
  .actions(self => ({
    setValue: (prop, value) => self[prop] = value,
    listForPharmacist: flow(function* (config = {}) {
      const page = _.get(config, 'query.page', 0);
      // If we are beginning pagination fresh, clear previous results.
      if (!page || page === 1) self.dataForPharmacist = {};

      config.urlFragment = () => '/for-pharmacist';
      const response = yield self.list(config);
      const data = response.data || response || [];
      self.dataForPharmacist.merge(_.keyBy(data, 'id'));

      return response;
    }),
    fetchPrescriptionLabelImage: flow(function* (config = {}) {
      config.urlFragment = () => `/${config.params.prescriptionId}/as-image`;

      const image = yield self.get(config);

      return image;
    }),
    fetchDirections: flow(function* (NDC, quantity) {
      if (!NDC) {
        return Promise.reject('NDC must be provided in query in order to fetch prescription\'s directions.');
      }
      const query = {
        NDC,
      };

      if (quantity) {
        query.quantity = quantity;
      }

      const response = yield self.get({
        urlFragment: () => '/directions',
        query,
      });

      return response.directions;
    }),
  }))
  .views(self => ({
    get groupedByDate() {
      const forPharmacist = Array.from(self.dataForPharmacist.values());
      const dateFormat = { year: 'numeric', month: 'long', day: 'numeric'};

      return _.reduce(forPharmacist, (groupedByDate, prescription) => {
        const date = (new Date(prescription.updatedAt)).toLocaleDateString('en-EN', dateFormat);
        if (!groupedByDate.find(item => item.title === date)) {
          groupedByDate.push({
            id: prescription.id,
            title: date,
            prescriptions: []
          });
          groupedByDate[groupedByDate.length - 1].prescriptions.push(prescription);
        } else {
          const byDateIndex = groupedByDate.findIndex(item => item.title === date);
          groupedByDate[byDateIndex].prescriptions.push(prescription);
        }

        return _.orderBy(groupedByDate, ['title'], ['desc']);
      }, []);
    },
    get forPharmacistByDate() {
      const list = Array.from(self.dataForPharmacist.values());

      const byDate = [];
      const grouped = {};
      list.forEach(item => {
        const dateFormat = { year: 'numeric', month: 'long', day: 'numeric'};
        const date = (new Date(item.updatedAt)).toLocaleDateString('en-EN', dateFormat);
        if (!grouped[date]) {
          grouped[date] = {date, data: []};
          byDate.push(grouped[date]);
        }
        grouped[date].data.push(item);
      });

      return _.orderBy(byDate, ['date'], ['desc']);
    },
    get forPharmacistToArray() {
      return Array.from(self.dataForPharmacist.values());
    }
  }));
});

// Can't use module.exports because there is a generator function in this file,
// and that causes module.exports to fail for some reason.
export default PrescriptionModel;
