import deepFreeze from 'deep-freeze';

export const MODEL_NAME = Object.freeze({
  SINGULAR: 'pharmacy',
  PLURAL: 'pharmacies'
});

export const FIELD_NAME = Object.freeze({
  ID: 'id',
  NAME: 'name',
  PHONE_NUMBER: 'phoneNumber',
  LOCATION_ID: 'locationId',
  LOCATION: 'location',
  STREET_1: 'street1',
  CITY: 'city',
  STATE: 'state',
  ZIP: 'zip',
  HEALTHCARE_SITES: 'healthcareSites',
  DRUG_STOCK: 'drugStock',
  CREATED_AT: 'createdAt',
  UPDATED_AT: 'updatedAt',
  PHARMACY_HEALTHCARE_SITE: 'pharmacy_healthcareSite',
});

export const SCHEMA = deepFreeze({
  title: MODEL_NAME.SINGULAR,
  type: 'object',
  properties: {
    [FIELD_NAME.ID]: {
      type: 'string'
    },
    [FIELD_NAME.NAME]: {
      type: 'string'
    },
    [FIELD_NAME.PHONE_NUMBER]: {
      type: 'string'
    },
    [FIELD_NAME.DRUG_STOCK]: {
      type: 'number'
    },
    [FIELD_NAME.LOCATION_ID]: {
      type: 'string'
    },
    [FIELD_NAME.LOCATION]: {
      type: 'object',
      properties: {
        [FIELD_NAME.STREET_1]: {
          type: 'string'
        },
        [FIELD_NAME.CITY]: {
          type: 'string'
        },
        [FIELD_NAME.STATE]: {
          type: 'string'
        },
        [FIELD_NAME.ZIP]: {
          type: 'string'
        }
      }
    },
    [FIELD_NAME.CREATED_AT]: {
      type: 'string'
    },
    [FIELD_NAME.UPDATED_AT]: {
      type: 'string'
    },
    [FIELD_NAME.HEALTHCARE_SITES]: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          [FIELD_NAME.ID]: {
            type: 'string'
          },
          [FIELD_NAME.NAME]: {
            type: 'string'
          },
          // [FIELD_NAME.PHARMACY_HEALTHCARE_SITE]: {
          //   type: 'object'
          // }
        },
        required: [
          // FIELD_NAME.PHARMACY_HEALTHCARE_SITE,
        ]
      }
    }
  },
  required: [
    FIELD_NAME.NAME
  ]
});
