import stores from 'bootstrap/store';

navigator.getUserMedia =
  navigator.getUserMedia ||
  navigator.webkitGetUserMedia ||
  navigator.mozGetUserMedia ||
  navigator.msGetUserMedia;

setTimeout(() => {
  stores.global.cameras.syncDevicesFromLocalStorage();
  stores.global.cameras.reloadVideoDevices();

  navigator.mediaDevices.addEventListener('devicechange', stores.global.cameras.reloadVideoDevices);
});
