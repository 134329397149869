import stores from 'bootstrap/store';
import { kioskStatusesRouteStore } from '../domain/store';

const fetchActiveKioskStatuses = async (activeKiosk) => {
  if (!activeKiosk) {
    return;
  }

  kioskStatusesRouteStore.incrementHttp('fetchingKioskStatuses');
  await stores.global.kiosks.getStatuses({
    params: {
      kioskId: activeKiosk.id,
    },
  });
  kioskStatusesRouteStore.decrementHttp('fetchingKioskStatuses');
};

export default fetchActiveKioskStatuses;
