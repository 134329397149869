import { get as _get } from 'lodash';

// Constants
import * as ROUTE from 'constants/routes';
import { MAX_ENTRIES_PER_PAGE } from '../constants';

// Helpers
import determineActiveMachineTechnician from './determineActiveMachineTechnician';

// Stores
import stores from 'bootstrap/store';
import { machineTechniciansRouteStore } from '../domain/store';
const machineTechniciansStore = stores.global.machineTechnicians;
const router = stores.router;

export default () => {
  const subQuery = {};
  const filter = machineTechniciansRouteStore.filterValue;

  if (filter) {
    const wildcard = `${filter}%`;
    subQuery.$or = [
      {'firstName': {'$iLike': wildcard}},
      {'lastName': {'$iLike': wildcard}}
    ];
  }

  const config = {
    query: {
      offset: MAX_ENTRIES_PER_PAGE * (machineTechniciansRouteStore.currentPage - 1),
      limit: MAX_ENTRIES_PER_PAGE,
      query: subQuery,
    }
  };

  machineTechniciansRouteStore.incrementHttp('listingTechnicians');
  return machineTechniciansStore.listMachineTechnicians(config)
  .catch(() => router.history.push(ROUTE.MACHINE_TECHNICIANS.VALUE))
  .then((response) => {
    const responseLength = _get(response, 'data.length', 0);
    if (responseLength > MAX_ENTRIES_PER_PAGE - 1) {
      machineTechniciansRouteStore.setValue('currentPage', machineTechniciansRouteStore.currentPage + 1);
    }
    determineActiveMachineTechnician();
  })
  .finally(() => machineTechniciansRouteStore.decrementHttp('listingTechnicians'));
};
