import React from 'react';

import searchIcon from 'assets/icons/search.svg';

import './autocomplete-styles.scss';

function AutocompleteView({
  term,
  onChange,
  suggestions,
  getSuggestionKey,
  renderSuggestion,
  onSuggestionSelect,
  placeholder,
}) {
  return <div className='c-AutocompleteView'>
    <div className='search-area'>
      <div className='input-container'>
        <img className='search-icon' src={searchIcon} />
        <input
          className='search-input'
          type='text'
          value={term}
          onChange={(event) => onChange(event.target.value)}
          placeholder={placeholder}
        />
      </div>
      <div className='suggestions-wrapper'>
        <div className='suggestions-container'>
          {
            suggestions.map((suggestion) => {
              return <div
                className='suggestion-container'
                key={getSuggestionKey(suggestion)}
                onClick={() => onSuggestionSelect(suggestion)}
              >
                { renderSuggestion(suggestion) }
              </div>;
            })
          }
        </div>
      </div>
    </div>
  </div>;
}

export default AutocompleteView;
