import React, { useCallback } from 'react';
import { observer } from 'mobx-react';

import NotificationsView from './notifications-view';

import { NOTIFICATIONS_DATA } from '../../constants';

import { notificationsPaneRouteStore } from '../../domain/store';


const NotificationsContainer = () => {
  const buildContent = useCallback(() => {
    const activeTab = notificationsPaneRouteStore.activeTab;
    const data = NOTIFICATIONS_DATA[activeTab] || {};
    const NotificationsContentComponent = data.component;
    if (!NotificationsContentComponent) {
      return null;
    }

    return <NotificationsContentComponent />;
  }, [notificationsPaneRouteStore.activeTab]);

  return <NotificationsView
    buildContent={buildContent}
    isOpen={notificationsPaneRouteStore.isOpen}
  />;
};

export default observer(NotificationsContainer);
