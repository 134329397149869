import { toJS } from 'mobx';
import { get as _get } from 'lodash';

import stores from 'bootstrap/store';
import { kiosksRouteStore } from '../domain/store';
const kiosksStore = stores.global.kiosks;
const moduleTemplatesStore = stores.global.moduleTemplates;

export default ({ kioskId }) => {
  return Promise.all([
    kiosksStore.getLayoutInfo({ params: { kioskId } }),
    moduleTemplatesStore.fetch(),
  ])
  .then((results) => {
    kiosksRouteStore.setValue('activeStorageAreaName', _get(results[0], 'kioskStorageAreas[0].name'));
    kiosksRouteStore.setValue('modifiedActiveKiosk', toJS(kiosksRouteStore.activeKiosk));
    kiosksRouteStore.setValue('modifiedKioskModuleId', '');
  });
};
