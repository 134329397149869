import deepFreeze from 'deep-freeze';

export const MODEL_NAME = Object.freeze({
  SINGULAR: 'pharmacist',
  PLURAL: 'pharmacists'
});

export const FIELD_NAME = Object.freeze({
  ID: 'id',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  STATUS: 'status',
  COMPUTED_AVAILABLE: 'computed_available',
  CREATED_AT: 'createdAt',
  UPDATED_AT: 'updatedAt',
});

export const SCHEMA = deepFreeze({
  title: MODEL_NAME.SINGULAR,
  type: 'object',
  properties: {
    [FIELD_NAME.ID]: {
      type: 'string'
    },
    [FIELD_NAME.FIRST_NAME]: {
      type: 'string'
    },
    [FIELD_NAME.LAST_NAME]: {
      type: 'string'
    },
    [FIELD_NAME.STATUS]: {
      type: 'string'
    },
    [FIELD_NAME.COMPUTED_AVAILABLE]: {
      type: 'boolean'
    },
    [FIELD_NAME.CREATED_AT]: {
      type: 'string'
    },
    [FIELD_NAME.UPDATED_AT]: {
      type: 'string'
    },
  },
  required: [
    FIELD_NAME.ID,
    FIELD_NAME.FIRST_NAME,
    FIELD_NAME.LAST_NAME,
  ]
});
