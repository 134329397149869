import React from 'react';
import { observer } from 'mobx-react';

import Links from './components/links';
import UserTypeSwitch from './components/user-type-switch';

import './navbar-view.scss';


const NavbarView = ({
  routeStore,
  activeUser,
  activeUserAccessibleRoutes,
  activeUserTypeTab,
  setActiveUserTypeTab,
  constructFullRoute,
}) => {
  if (!activeUser) return null;
  const routes = activeUserAccessibleRoutes[activeUserTypeTab];
  if (!routes) return null;

  return <div className='v-NavbarView'>
    <Links
      routeStore={routeStore}
      routes={routes}
      constructFullRoute={constructFullRoute}
    />
    <UserTypeSwitch
      activeTab={activeUserTypeTab}
      setActiveTab={setActiveUserTypeTab}
      activeUserAccessibleRoutes={activeUserAccessibleRoutes}
    />
  </div>;
};

export default observer(NavbarView);


