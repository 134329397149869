import React from 'react';
import { observer } from 'mobx-react';
import { isNumber as _isNumber } from 'lodash';

import constructStockingPeriod from './helpers/constructStockingPeriod';

import ContentGate from 'components/content-gate';

import CalendarIcon from 'assets/icons/calendar-blue.svg';
import ContainerIcon from 'assets/icons/container-active.svg';
import ContainerRedIcon from 'assets/icons/container-red.svg';
import HashtagIcon from 'assets/icons/hashtag-active.svg';

import './info-styles.scss';

const InfoView = ({
  lotInfoRouteStore,
}) => {
  const {
    activeLot,
    drugsList,
    unrecalledDrugsCount,
    http,
  
    drugsByLotById,
    drugIdsToRecallList,
    addDrugIdsToBeRecalled,
    addAllDrugIdsToBeRecalled,
    clearDrugIdsToBeRecalled,
    executeDrugRecalls,
  } = lotInfoRouteStore;

  return (
    <div className='c-LotInfo'>
      <ContentGate
        className='lot-info-container'
        active={Boolean(http.fetchingLotById)}
        fadeDuration={500}
      >
        <div className='data'>
          <div className='data-row'>
            <div className='name'>
              <img
                alt='Lot Number'
                className='icon'
                src={HashtagIcon}
              />
              <div className='text'>
                Number
              </div>
            </div>
            <div className='value'>
              {
                activeLot?.number || 'N/A'
              }
            </div>
          </div>
          <div className='data-row'>
            <div className='name'>
              <img
                alt='Container'
                className='icon'
                src={ContainerIcon}
              />
              <div className='text'>
                Total Containers #
              </div>
            </div>
            <div className='value'>
              {
                _isNumber(activeLot?.totalContainersCount) ? activeLot?.totalContainersCount : 'N/A'
              }
            </div>
          </div>
          <div className='data-row'>
            <div className='name'>
              <img
                alt='Container'
                className='icon'
                src={activeLot?.dispensedContainersCount ? ContainerRedIcon : ContainerIcon}
              />
              <div className={`text ${activeLot?.dispensedContainersCount && 'text-red'}`}>
                Dispensed Containers #
              </div>
            </div>
            <div className={`value ${activeLot?.dispensedContainersCount && 'value-red'}`}>
              {
                _isNumber(activeLot?.dispensedContainersCount) ? activeLot?.dispensedContainersCount : 'N/A'
              }
            </div>
          </div>
          <div className='data-row'>
            <div className='name'>
              <img
                alt='Calendar'
                className='icon'
                src={CalendarIcon}
              />
              <div className='text'>
                Stocking Period
              </div>
            </div>
            <div className='value'>
              {
                activeLot ? constructStockingPeriod(activeLot) : 'N/A'
              }
            </div>
          </div>
        </div>
      </ContentGate>
      <ContentGate
        className='drug-recalls-container'
        active={(http.listingDrugsByLot || http.creatingLotDrugRecall)}
        fadeDuration={500}
      >
        {
          !!drugIdsToRecallList.length &&
          <div className='recall-prompt'>
            <div className='header'>
              Confirm recall of the following drugs in Lot <span className='lot-number'>{ activeLot.number }</span>
            </div>
            <ul className='recall-drug-list'>
              {
                drugIdsToRecallList.map((drugId) => (
                  <li
                    className='recall-drug'
                    key={drugId}
                  >
                    { drugsByLotById.get(drugId).name }
                  </li>
                ))
              }
            </ul>
            <div className='recall-options'>
              <button
                className='btn blue'
                onClick={() => executeDrugRecalls()}
              >
                Yes, Recall
              </button>
              <button
                className='btn red'
                onClick={() => clearDrugIdsToBeRecalled()}
              >
                Cancel
              </button>
            </div>
          </div>
        }
        <div className='drug-list-container'>
          <div
            className='header'
          >
            <div
              className='title'
            >
              Drug Types Found In Lot
            </div>
            <div
              className='header-actions'
            >
              {
                (unrecalledDrugsCount > 1) &&
                <button
                  className='header-action btn red'
                  onClick={() => addAllDrugIdsToBeRecalled()}
                >
                  Recall All
                </button>
              }
            </div>
          </div>
          <div className='drugs-list'>
            {
              drugsList.map(drug => (
                <div 
                  key={drug.id}
                  className='drug-option-container'
                >
                  <div
                    className='drug-info'
                  >
                    <div className='drug-name'>{ drug.name }</div>
                    <div className='drug-NDC'>{ drug.NDC }</div>
                  </div>
                  <div
                    className='drug-actions'
                  >
                    <button
                      className='drug-action btn red'
                      disabled={ !!drug.lotDrugRecall }
                      onClick={ () => addDrugIdsToBeRecalled(drug.id) }
                    >
                      Recall
                    </button>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </ContentGate>
    </div>
  );
};

export default observer(InfoView);
