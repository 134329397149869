import { get as _get } from 'lodash';

import stores from 'bootstrap/store';

import getIsPendingDispenseAttemptBeingDisplayed from '../../helpers/getIsPendingDispenseAttemptBeingDisplayed';

import { EVENT } from '../../constants';

const dispenseAttemptReviewsCreateHandler = ({ apiWebSocket }) => {
  apiWebSocket.on(EVENT.RECEIVED.DISPENSE_ATTEMPT_REVIEW_CREATE, (payload) => {
    stores.global.dispenseAttempts.listPendingForNotificationsPane();
    
    const dispenseAttemptId = _get(payload, 'dispenseAttemptReview.dispenseAttemptId');
    if (!dispenseAttemptId) {
      return;
    }

    const isPendingDispenseAttemptBeingDisplayed = getIsPendingDispenseAttemptBeingDisplayed(dispenseAttemptId);
    if (isPendingDispenseAttemptBeingDisplayed) {
      stores.global.dispenseAttempts.deleteDispenseAttempt(dispenseAttemptId);
    }
  });
};

export default dispenseAttemptReviewsCreateHandler;
