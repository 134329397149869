import React from 'react';
import { get as _get } from 'lodash';
import { FILTER_TYPES } from '../constants';


export const APP_COMMAND_ICONS = {
  // [APP_COMMAND_TYPES.DISPENSE_ATTEMPT_APPROVE]: require('assets/icons/approved.svg'),
};

const APP_COMMAND_CONFIG = {
  // [APP_COMMAND_TYPES.DISPENSE_ATTEMPT_APPROVE]: (appCommand) => ([
  //   {
  //     data: `${appCommand.dispenseAttempt.prescriptionFill.prescription.patient.firstName} ${appCommand.dispenseAttempt.prescriptionFill.prescription.patient.lastName}`,
  //     title: 'PATIENT',
  //   },
  //   {
  //     data: `${appCommand.dispenseAttempt.prescriptionFill.prescription.drug.name}`,
  //     title: 'DRUG',
  //   },
  //   {
  //     data: `${appCommand.dispenseAttempt.kiosk.name}`,
  //     title: 'KIOSK',
  //   },
  // ]),
};


export const buildAppCommand = (appCommand) => {
  if (!APP_COMMAND_CONFIG[appCommand.type]) return (<React.fragment></React.fragment>);
  const config = APP_COMMAND_CONFIG[appCommand.type](appCommand);

  return (
    <div className='data'>
      <div className='type'>
        {FILTER_TYPES[appCommand.type].NAME}
      </div>
      {
        config.map(block => (
          <div key={block.title} className='key-value-block'>
            <div className='value'>
              {
                block.data
              }
            </div>
            <div className='key'>
              {
                block.title
              }
            </div>
          </div>
        ))
      }
    </div>
  );
};

export const BLOCK_CONFIG = {
  blocks: [
    {
      key: 'lastActivityDate',
      getData: (data) => _get(data, 'lastActivityDate', 'No last activity'),
      subText: 'DATE',
    },
    // {
    //   key: 'countApproved',
    //   subText: 'APPROVED REQUESTS',
    //   getData: (data) => _get(data, 'countApproved', 0),
    //   header: {
    //     getText: (data) => `AVG. ${_get(data, 'averageApprove', 0)}/DAY`,
    //     style: HEADER_STYLES.GREEN
    //   }
    // },
  ]
};
