import React from 'react';
import { observer } from 'mobx-react';

import NotificationWrapper from '../notification-wrapper';
import CallRequest from './components/call-request';

import './call-requests-notifications-view-style.scss';


const CallRequestsNotificationsView = ({
  callRequests,
  onClick,
}) => (
  <div className='c-CallRequestsNotifications'>
    {
      !callRequests.length &&
      <div className='no-data-caption'>
        No Call Requests
      </div>
    }
    {
      callRequests.map((callRequest) => <NotificationWrapper
        key={callRequest.id}
        cursorPointer={true}
        onClick={onClick}
      >
        <CallRequest
          callRequest={callRequest}
          onClick={onClick}
        />
      </NotificationWrapper>)
    }
  </div>
);

export default observer(CallRequestsNotificationsView);
