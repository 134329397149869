import React, { useCallback, useState } from 'react';

import DropDownMenuView from './drop-down-menu-view';

const DropDownMenuContainer = ({ menuIcon, items = [] }) => {
  const [showMenu, setShowMenu] = useState(false);

  const reverseMenuState = useCallback(() => {
    setShowMenu(!showMenu);
  }, [showMenu]);

  return <DropDownMenuView
    showMenu={showMenu}
    reverseMenuState={reverseMenuState}
    menuIcon={menuIcon}
    items={items}
  />;
};

export default DropDownMenuContainer;
