import React from 'react';
import { observer } from 'mobx-react';
import { get as _get } from 'lodash';

// Components
import ContainerLabel from './components/container-label';
import ContainerPostLabelimages from './components/container-post-label-images';
import ContainerPreLabelimages from './components/container-pre-label-images';
import Drug from './components/drug';
import Patient from './components/patient';
import Physician from './components/physician';
import Prescription from './components/prescription';
import Modal from 'components/modal';

// Icons
import CrossIcon from 'assets/icons/cross.svg';

// Styles
import './style.scss';

const ExpandedPrescriptionInfoModal = ({
  isOpen,
  close,
  dispenseAttemptsRouteStore,
  prescriptionInfoRouteStore,
  containerImagesFetched,
}) => {
  const dispenseAttempt = dispenseAttemptsRouteStore.activeDispenseAttempt;
  const patient = _get(dispenseAttempt, 'prescriptionFill.prescription.patient', {});
  const physician = _get(dispenseAttempt, 'prescriptionFill.prescription.physician', {});
  const drug = _get(dispenseAttempt, 'prescriptionFill.prescription.drug', {});
  const prescription = _get(dispenseAttempt, 'prescriptionFill.prescription', {});
  const container = _get(dispenseAttempt, 'container', {});
  const kiosk = _get(dispenseAttempt, 'kiosk', {});

  return (
    <Modal
      modalClassName='c-ExpandedPrescriptionInfoModal'
      modalStyles={{ height: '100%', width: '100%', flexDirection: 'row' }}
      isOpen={isOpen}
      shouldCloseOnOverlayClick={false}
      close={close}
    >
      <img
        className='close-modal-icon'
        alt='cross'
        src={CrossIcon}
        onClick={close}
      />
      <div className='left-side'>
        <Patient patient={patient} />
        <Physician physician={physician} />
        <Drug drug={drug} />
      </div>
      <div className='right-side'>
        <div className='top-block'>
          <Prescription
            prescription={prescription}
            container={container}
            kiosk={kiosk}
          />
        </div>
        <div className='bottom-block'>
          <ContainerLabel prescriptionInfoRouteStore={prescriptionInfoRouteStore} />
          <ContainerPreLabelimages
            containerImagesFetched={containerImagesFetched}
            preLabelGallery={prescriptionInfoRouteStore.preLabelGallery}
          />
          <ContainerPostLabelimages
            containerImagesFetched={containerImagesFetched}
            postLabelGallery={prescriptionInfoRouteStore.postLabelGallery}
          />
        </div>
      </div>
    </Modal>
  );
};

export default observer(ExpandedPrescriptionInfoModal);
