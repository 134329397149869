import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';

// Views
import PharmacyTechnicianView from './pharmacy-technicians-view';

// Helpers
import {
  determineActivePharmacyTechnician,
  listPharmacyTechnicians,
  setFilterValue,
} from './helpers';

// View Store
import { pharmacyTechniciansRouteStore } from './domain/store';

// Global Stores
import stores from 'bootstrap/store';
const pharmacyTechniciansStore = stores.global.pharmacyTechnicians;


const PharmacyTechniciansContainer = ({ rootRoute }) => {
  const navigate = useNavigate();
  const { technicianId } = useParams();

  useEffect(() => {
    listPharmacyTechnicians();

    return () => {
      pharmacyTechniciansRouteStore.setInitialState();
      pharmacyTechniciansStore.setValue('pharmacyTechnicians', {});
    };
  }, []);

  useEffect(() => {
    if (!pharmacyTechniciansRouteStore.http.listingTechnicians) {
      determineActivePharmacyTechnician(technicianId, navigate);
    }
  }, [technicianId, pharmacyTechniciansRouteStore.http.listingTechnicians]);

  return <PharmacyTechnicianView
    pharmacyTechniciansStore={pharmacyTechniciansStore}
    pharmacyTechniciansRouteStore={pharmacyTechniciansRouteStore}
    setFilterValue={setFilterValue}
    listPharmacyTechnicians={listPharmacyTechnicians}
    rootRoute={rootRoute}
  />;
};

export default observer(PharmacyTechniciansContainer);
