import React from 'react';
import { observer } from 'mobx-react';

import config from './constants';

import './webCamPreviewStyle.scss';

class WebCamPreview extends React.Component {
  constructor(props) {
    super(props);

    this.stream = null;
    this.currentDeviceId = null;
    this.videoRef = React.createRef();

    this.loadVideoForDevice = this.loadVideoForDevice.bind(this);
    this.clearActiveStream = this.clearActiveStream.bind(this);
  }

  clearActiveStream() {
    if (!this.stream) return;

    this.stream.getTracks().forEach(track => track.stop());
    this.stream = null;
    this.currentDeviceId = null;
  }

  loadVideoForDevice(deviceId) {
    if (!deviceId || !this.videoRef.current || deviceId === this.currentDeviceId) return;

    this.clearActiveStream();

    navigator.mediaDevices.getUserMedia({
      video: {
        ...config,
        deviceId,
      }
    }).then(stream => {
      this.videoRef.current.srcObject = stream;
      this.stream = stream;
      this.currentDeviceId = deviceId;
    });
  }

  componentDidMount() {
    const { deviceId } = this.props;

    this.loadVideoForDevice(deviceId);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.deviceId !== this.props.deviceId) this.loadVideoForDevice(nextProps.deviceId);
  }

  componentWillUnmount() {
    this.clearActiveStream();
  }

  render() {
    return (
      <div className='c-webCamPreview'>
        <video className='video' autoPlay ref={this.videoRef} />
      </div>
    );
  }
}

export default observer(WebCamPreview);
