import { APP_COMMAND_TYPE } from '../../../../../../../../models/app-commands/constants/constants';

export const MAX_ENTRIES_PER_PAGE = 15;

export const DEFAULT_VALUES = {
  x: '0',
  y: '0',
  name: '',
};

export const ACTION_NAME = {
  [APP_COMMAND_TYPE.CONTAINERS_CREATE]: 'Container Created',
  // [APP_COMMAND_TYPE.CONTAINER_MOVE]: 'Container Moved',
  [APP_COMMAND_TYPE.CONTAINERS_SET_REMOVED]: 'Container Removed',
  [APP_COMMAND_TYPE.DISPENSE_ATTEMPTS_APPROVE]: 'Dispense Attempt Approved',
  [APP_COMMAND_TYPE.DISPENSE_ATTEMPTS_CREATE]: 'Dispense Attempt Created',
  [APP_COMMAND_TYPE.DISPENSE_ATTEMPTS_DISMISS_REJECTED]: 'Dispense Attempt Dismissed',
  [APP_COMMAND_TYPE.DISPENSE_ATTEMPTS_DISPENSE]: 'Dispense Attempt Dispensed',
  [APP_COMMAND_TYPE.DISPENSE_ATTEMPTS_REJECT]: 'Dispense Attempt Rejected',
  [APP_COMMAND_TYPE.KIOSK_STATUS_CREATE]: 'Kiosk Status Created',
  [APP_COMMAND_TYPE.USERS_LOGIN_KEYCARD]: 'Card Swipe',
  [APP_COMMAND_TYPE.CONTAINERS_ASSIGN_AVAILABLE_POSITION]: 'Container Assign Available Position',
  [APP_COMMAND_TYPE.CONTAINERS_ADD_IMAGE_LABELLED]: 'Container Add Image Labelled',
  [APP_COMMAND_TYPE.CONTAINER_LABELLED]: 'Container Labelled',
  [APP_COMMAND_TYPE.CONTAINERS_SET_NOT_STORED]: 'Container Set Not Stored',
  [APP_COMMAND_TYPE.CONTAINERS_SET_STORED]: 'Container Set Stored',
  [APP_COMMAND_TYPE.DISPENSE_ATTEMPTS_DISPENSE]: 'Dispense Attempt Dispense',
  [APP_COMMAND_TYPE.KIOSKS_CREATE_BY_PHARMACY]: 'Kiosk Create',
  [APP_COMMAND_TYPE.KIOSKS_UPDATE_BY_PHARMACY]: 'Kiosk Update',
  [APP_COMMAND_TYPE.PATIENT_SIGNATURES_CREATE]: 'Patient Signature Create',
};
