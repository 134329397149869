import KeycardModel from 'models/keycards';
import { types } from 'mobx-state-tree';
import {
  get as _get,
  set as _set
} from 'lodash';

import stores from 'bootstrap/store';

const Select = types.optional(types.model({
  label: types.optional(types.string, ''),
  value: types.optional(types.string, ''),
}), {label: '', value: ''});

export const KeycardUpsertModalRouteStore = types.model({
})
.props({
  activelyModifiedKeycard: types.maybeNull(types.frozen()),
  userSelect: Select,
  userSelectFilter: types.optional(types.string, ''),
  pharmacySelect: Select,
  pharmacySelectFilter: types.optional(types.string, ''),
  showingDeleteConfirmationModal: types.optional(types.boolean, false)
})
.actions(self => ({
  setValue: (path, value) => _set(self, path, value),
  setInitialState() {
    self.activelyModifiedKeycard = null;
    self.userSelect = { label: '', value: '' };
    self.userSelectFilter = '';
    self.pharmacySelect = { label: '', value: '' };
    self.pharmacySelectFilter = '';
    self.showingDeleteConfirmationModal = false;
  }
}))
.views(self => ({
  get readyForCreation() {
    return !!(
      _get(self, 'activelyModifiedKeycard.value') &&
      self.pharmacySelect.value &&
      self.userSelect.value
    );
  },
  get autocompleteUsers() {
    const usersStore = stores.global.users;
    return usersStore.dataAsArray.map((user) => ({
      label: `${user.firstName} ${user.lastName}`,
      value: user.id,
    }));
  },
  get autocompletePharmacies() {
    return stores.global.pharmacies.autoCompleteDataForSelect;
  },
}));

export const keycardUpsertModalRouteStore = KeycardUpsertModalRouteStore.create({});
