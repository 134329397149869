import { DateTime } from 'luxon';

export const PRESCRIPTION_FILL_CONFIG = [
  {
    modelTitle: 'Prescription Fill',
    modelName: 'prescriptionFill',
    fields: [
      {
        name: 'Copay',
        path: 'copay',
        required: true,
        type: 'number',
      },
      {
        name: 'Adjudicated',
        path: 'adjudicated',
        required: true,
        type: 'number',
      },
      {
        name: 'Fill Number',
        path: 'fillNumber',
        required: true,
        type: 'number',
        wrap: val => Number.parseFloat(val),
      },
    ]
  },
  {
    modelTitle: 'Prescription',
    modelName: 'prescription',
    fields: [
      {
        name: 'RxId',
        path: 'rxId',
        required: true,
      },
      {
        name: 'Directions',
        path: 'directions',
        required: true,
      },
      {
        name: 'Received Time',
        path: 'receivedTime',
        type: 'date-time',
        specProps: {
          maxDate: new Date(Date.now()),
          minDate: new Date('01-01-2000'),
        }
      },
      {
        name: 'Written Time',
        path: 'writtenTime',
        type: 'date-time',
        specProps: {
          maxDate: new Date(Date.now()),
          minDate: new Date('01-01-2000'),
        }
      },
      {
        name: 'Days Supply',
        path: 'daysSupply',
        type: 'number',
        wrap: val => val && Number.parseInt(val),
      },
      {
        name: 'Potency Description',
        path: 'potencyDescription',
      },
      {
        name: 'Next Refill',
        path: 'nextRefill',
        type: 'date-time',
        specProps: {
          minDate: new Date(Date.now()),
        }
      },
      {
        name: 'Physician Notes',
        path: 'physicianNotes',
      },
    ]
  },
  {
    modelTitle: 'Drug',
    modelName: 'drug',
    fields: [
      {
        name: 'NDC',
        path: 'NDC',
        required: true,
      },
      {
        name: 'UPC',
        path: 'UPC',
      },
      {
        name: 'Name',
        path: 'name',
      },
      {
        name: 'Generic Name',
        path: 'genericName',
      },
      {
        name: 'Quantity',
        path: 'quantity',
      },
      {
        name: 'Strength',
        path: 'strength',
      },
      {
        name: 'Manufacturer',
        path: 'manufacturer',
      },
      {
        name: 'Administration Type',
        path: 'administrationType',
      },
      {
        name: 'Form',
        path: 'form',
      },
      {
        name: 'Imprint',
        path: 'imprint',
      },
      {
        name: 'Color',
        path: 'color',
      },
      {
        name: 'Flavor',
        path: 'flavor',
      },
      {
        name: 'Shape',
        path: 'shape',
      },
      {
        name: 'Description',
        path: 'description',
      },
    ]
  },
  {
    modelTitle: 'Patient',
    modelName: 'patient',
    fields: [
      {
        name: 'First Name',
        path: 'firstName',
        required: true,
      },
      {
        name: 'Last Name',
        path: 'lastName',
        required: true,
      },
      {
        name: 'DOB',
        path: 'DOB',
        required: true,
        type: 'date',
        wrap: val => val && DateTime.fromJSDate(val).toFormat('yyyy-LL-dd'),
        specProps: {
          maxDate: new Date(Date.now()),
          minDate: new Date('01-01-2000'),
        }
      },
      {
        name: 'Gender',
        path: 'gender',
        required: true,
        type: 'select',
        specProps: {
          options: [{value: 'male', label: 'male'}, {value: 'female', label: 'female'}],
          searchable: false,
        },
        wrap: data => data.value,
      },
      {
        name: 'Allergies',
        path: 'allergies',
        type: 'list-input',
        wrap: data => data.map((allergy) => ({name: allergy })),
      },
    ]
  },
  {
    modelTitle: 'Physician',
    modelName: 'physician',
    fields: [
      {
        name: 'First Name',
        path: 'firstName',
        required: true,
      },
      {
        name: 'Last Name',
        path: 'lastName',
        required: true,
      },
      {
        name: 'NPI',
        path: 'NPI',
        required: true,
      },
      {
        name: 'SPI',
        path: 'SPI',
        required: true,
      },
      {
        name: 'State License Number',
        path: 'stateLicenseNumber',
        required: true,
      },
    ]
  }
];

export const RESULT_TYPES = {
  SUCCESSFUL: 'SUCCESSFUL',
  ERROR: 'ERROR',
};

export const RESULT_CLASS_NAMES = {
  [RESULT_TYPES.SUCCESSFUL]: 'status-success',
  [RESULT_TYPES.ERROR]: 'status-error',
};

export const RESULT_MESSAGES = {
  [RESULT_TYPES.SUCCESSFUL]: 'Created successful',
  [RESULT_TYPES.ERROR]: 'Error occurred',
};
