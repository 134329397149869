import React from 'react';
import { observer } from 'mobx-react';

import './medical-assistant-style.scss';


const MedicalAssistant = ({ pharmaciesCaption }) => (
  <div className='c-MedicalAssistantsListMA'>
    <div className='facility'>
      {
        pharmaciesCaption
      }
    </div>
  </div>
);

export default observer(MedicalAssistant);
