import { useEffect } from 'react';

import { apiWebSocket } from 'services/api-websocket';

export default (eventName, fn, watched = []) => {
  useEffect(() => {
    apiWebSocket.on(eventName, fn);
    return () => apiWebSocket.off(eventName, fn);
  }, watched);
};
