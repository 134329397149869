import React from 'react';
import { observer } from 'mobx-react';

import './index.scss';

const EntriesCount = ({ count, caption }) => (
  <div className='c-EntriesCount'>
    <div className='count'>
      {
        count
      }
    </div>
    <div className='caption'>
      {
        caption
      }
    </div>
  </div>
);

export default observer(EntriesCount);