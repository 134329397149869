import React from 'react';
import { observer } from 'mobx-react';

// Components
import DrillDownLayout from 'components/drill-down-layout';
import List from './components/list';
import Content from './components/content';

// Styles
import './machine-technicians-view.scss';

const MachineTechniciansView = ({
  machineTechniciansStore,
  machineTechniciansRouteStore,
  setFilterValue,
  listMachineTechnicians,
  rootRoute,
}) => (
  <div className='r-TechniciansView'>
    <DrillDownLayout>
      <List
        machineTechniciansRouteStore={machineTechniciansRouteStore}
        machineTechniciansStore={machineTechniciansStore}
        setFilterValue={setFilterValue}
        listMachineTechnicians={listMachineTechnicians}
        rootRoute={rootRoute}
      />
      <Content
        machineTechniciansRouteStore={machineTechniciansRouteStore}
      />
    </DrillDownLayout>
  </div>
);


export default observer(MachineTechniciansView);
