import deepFreeze from 'deep-freeze';

export const MODEL_NAME = Object.freeze({
  SINGULAR: 'lotDrugRecall',
  PLURAL: 'lot-drug-recalls',
});

export const FIELD_NAME = Object.freeze({
  ID: 'id',
  LOT_ID: 'lotId',
  PHARMACIST_ID: 'pharmacistId',
  UPDATED_AT: 'updatedAt',
});

export const SCHEMA = deepFreeze({
  title: MODEL_NAME.SINGULAR,
  type: 'object',
  properties: {
    [FIELD_NAME.ID]: {
      type: 'string',
    },
    [FIELD_NAME.LOT_ID]: {
      type: 'string'
    },
    [FIELD_NAME.PHARMACIST_ID]: {
      type: 'string'
    },
    [FIELD_NAME.CREATED_AT]: {
      type: 'string'
    },
  },
});
