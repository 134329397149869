import { get as _get } from 'lodash';

// Helpers
import {
  listForPharmacistCautiously as listDispenseAttemptsForPharmacistCautiously,
} from 'models/dispense-attempts/helpers';

// Stores
import stores from 'bootstrap/store';
import { dispenseAttemptsRouteStore } from '../domain/store';
const dispenseAttemptsStore = stores.global.dispenseAttempts;
const prescriptionFillPharmacistConsultationRequestsStore = stores.global.prescriptionFillPharmacistConsultationRequests;


export default () => {
  const { activeDispenseAttempt } = dispenseAttemptsRouteStore;
  if (!activeDispenseAttempt) return;
  const prescriptionFillId = _get(activeDispenseAttempt, 'prescriptionFill.id');
  if (!prescriptionFillId) return;

  const dispenseAttemptId = activeDispenseAttempt.id;
  return prescriptionFillPharmacistConsultationRequestsStore.create({
    params: { prescriptionFillId },
    body: { notes: dispenseAttemptsRouteStore.consultationReason.value.trim() },
  })
  .then(() => {
    dispenseAttemptsRouteStore.resetConsultationRequestModal();
    // TODO come up with a better solution
    dispenseAttemptsStore.deleteOneForPharmacist(dispenseAttemptId);
    return listDispenseAttemptsForPharmacistCautiously();
  });
};
