import React from 'react';
import { observer } from 'mobx-react';
import Gallery from 'components/image-gallery';
import { get as _get } from 'lodash';

import ContentGate from 'components/content-gate';
import PrescriptionElement from './components/prescription-element';

import ActivePatientIcon from 'assets/icons/patient-active.svg';
import PhysicianIcon from 'assets/icons/physician-active.svg';
import ContactIcon from 'assets/icons/contact.svg';
import DrugIcon from 'assets/icons/drug-active.svg';
import ContainerIcon from 'assets/icons/container-active.svg';
import ImageIcon from 'assets/icons/image.svg';

import './prescription-info-view.scss';


const PrescriptionInfoView = ({
  activeDispenseAttempt,
  prescriptionInfoRouteStore,
  containerImagesFetched,
  doesPhysicianHaveFacility,
  isExpanded,
  toggleExpanded,
}) => {
  const {
    rxId,
    directions,
    dosage,
    drug,
    quantity,
    patient,
    physician,
    refills,
  } = activeDispenseAttempt.prescriptionFill.prescription;

  return (
    <div className='prescription-wrapper'>
      <div className='row-right'>
        <button
          className='button-expand'
          onClick={toggleExpanded}
        >
          { isExpanded ? 'Contract' : 'Expand' }
        </button>
      </div>
      <div className={`prescription-info-wrapper ${isExpanded && 'expanded'}`}>
        <div className='prescription-model-info'>
          <div className='data-row'>
            <PrescriptionElement className='w100 container-label' header='Container Label' icon={ImageIcon}>
              <ContentGate
                active={!containerImagesFetched}
                fadeDuration={500}
              >
                <Gallery
                  enableImageSelection={false}
                  images={prescriptionInfoRouteStore.labelImageGallery}
                />
              </ContentGate>
            </PrescriptionElement>
          </div>
          <div className='data-row container-images'>
            <PrescriptionElement className='w100' header='Post-label Images' icon={ImageIcon}>
              <ContentGate
                active={!containerImagesFetched}
                fadeDuration={500}
              >
                <Gallery
                  enableImageSelection={false}
                  images={
                    // Doing this concat for the sake of demoing styling things
                    prescriptionInfoRouteStore.postLabelGallery
                  }
                />
              </ContentGate>
            </PrescriptionElement>
          </div>
          <div className='horizontal-line' />
          <div className='data-row container-images'>
            <PrescriptionElement className='w100' header='Pre-label Images' icon={ImageIcon}>
              <ContentGate
                active={!containerImagesFetched}
                fadeDuration={500}
              >
                <Gallery
                  enableImageSelection={false}
                  images={prescriptionInfoRouteStore.preLabelGallery}
                />
              </ContentGate>
            </PrescriptionElement>
          </div>
          <div className='horizontal-line' />
          <div className='data-row'>
            <PrescriptionElement header='Drug Information' className='w50' icon={DrugIcon}>
              <div className='key-value-container'>
                <div className='key'>NAME</div>
                <div className='value'>{drug.name}</div>
              </div>

              <div className='key-value-container'>
                <div className='key'>Rx ID</div>
                <div className='value'>{rxId}</div>
              </div>

              <div className='flex-row'>
                <div className='w50'>
                  <div className='key-value-container'>
                    <div className='key'>NDC</div>
                    <div className='value'>{drug.NDC}</div>
                  </div>
                </div>
              </div>

              <div className='flex-row'>
                <div className='w50'>
                  <div className='key-value-container'>
                    <div className='key'>Manufacturer</div>
                    <div className='value'>{drug.manufacturer || 'N/A'}</div>
                  </div>
                </div>
              </div>
            </PrescriptionElement>

            <div className='w50'>
              <div className='table table-3cols'>
                <div className='table-cell border-top-left border-top-left-round'>
                  REFILLS LEFT
                </div>
                <div className='table-cell border-left-top-right'>
                  QUANTITY
                </div>
                <div className='table-cell border-right-top border-top-right-round'>
                  DOSAGE
                </div>

                <div className='table-cell border-top-left'>
                  <div className='value'>{refills || refills === 0 ? refills : 'N/A'}</div>
                </div>
                <div className='table-cell border-left-top-right'>
                  <div className='value'>{quantity || quantity === 0 ? quantity : 'N/A'}</div>
                </div>
                <div className='table-cell border-right-top'>
                  <div className='value'>{dosage ? dosage : 'N/A'}</div>
                </div>
              </div>
              <div className='table-cell border-left-top-right'>DIRECTIONS</div>
              <div className='table-cell border-solid border-bottom-left-round border-bottom-right-round'>
                <div className='value'>{directions ? directions : 'N/A'}</div>
              </div>
            </div>
          </div>
          <div className='horizontal-line' />
          <div className='data-row'>
            <PrescriptionElement className='container-section' header='Container Information' icon={ContainerIcon}>
              <div className='columns'>
                <div className='column'>
                  <div className='key-value-container'>
                    <div className='key'>KIOSK</div>
                    <div className='value'>{_get(activeDispenseAttempt, 'kiosk.name') || 'N/A'}</div>
                  </div>
                  {
                    _get(activeDispenseAttempt, 'container.labelNDC') &&
                    <div className='key-value-container'>
                      <div className='key'>NDC</div>
                      <div className='value'>
                        {
                          activeDispenseAttempt.container.labelNDC
                        }
                      </div>
                    </div>
                  }
                  <div className='key-value-container'>
                    <div className='key'>QUANTITY</div>
                    <div className='value'>{_get(activeDispenseAttempt, 'container.quantity') || 'N/A'}</div>
                  </div>
                  {
                    _get(activeDispenseAttempt, 'container.UPC') &&
                    <div className='key-value-container'>
                      <div className='key'>UPC</div>
                      <div className='value'>
                        {
                          activeDispenseAttempt.container.UPC
                        }
                      </div>
                    </div>
                  }
                </div>
                <div className='column'>
                  <div className='key-value-container'>
                    <div className='key'>STOCKING DATE</div>
                    <div className='value'>
                      {
                        `${new Date(_get(activeDispenseAttempt, 'container.createdAt')).toLocaleDateString('en-EN', { year: 'numeric', month: 'long', day: 'numeric'})}, ${new Date(_get(activeDispenseAttempt, 'container.createdAt')).toLocaleTimeString()}`
                      }
                    </div>
                  </div>
                  <div className='key-value-container'>
                    <div className='key'>EXPIRATION DATE</div>
                    <div className='value'>
                      {
                        new Date(_get(activeDispenseAttempt, 'container.expirationDate'))
                        .toLocaleDateString('en-EN', { year: 'numeric', month: 'long', day: 'numeric'})
                      }
                    </div>
                  </div>
                  <div className='key-value-container'>
                    <div className='key'>LOT NUMBER</div>
                    <div className='value'>
                      { _get(activeDispenseAttempt, 'container.lot.number') || 'N/A' }
                    </div>
                  </div>
                </div>
              </div>
              
              
            </PrescriptionElement>
          </div>
          <div className='horizontal-line' />
          <div className='data-row'>
            <PrescriptionElement className='w50' header='Patient Information' icon={ActivePatientIcon}>
              <div className='patient'>
                <div className='key-value-container'>
                  <div className='key'>NAME</div>
                  <div className='value'>{`${patient.firstName} ${patient.lastName}`}</div>
                </div>
                <div className='flex-row'>
                  <div className='w50'>
                    <div className='key-value-container'>
                      <div className='key'>GENDER</div>
                      <div className='value'>{patient.gender || 'N/A'}</div>
                    </div>
                  </div>
                  <div className='w50'>
                    <div className='key-value-container'>
                      <div className='key'>DATE OF BIRTH</div>
                      <div className='value'>{patient.DOB || 'N/A'}</div>
                    </div>
                  </div>
                </div>
                <div className='key-value-container'>
                  <div className='key'>ALLERGIES</div>
                  <div className='value'>
                    {
                      patient.allergies && patient.allergies.length
                        ? patient.allergies.map(({ name }) => name).join(', ')
                        : 'None'
                    }
                  </div>
                </div>
                <div className='key-value-container'>
                  <div className='key'>EXTERNAL PHARMACY ID</div>
                  <div className='value'>{patient.externalPharmacyId || 'N/A'}</div>
                </div>
              </div>
            </PrescriptionElement>
            <PrescriptionElement className='contact w50' header='Contact' icon={ContactIcon}>
              <div className='data-row-no-padding'>
                <div className='key-value-container'>
                  <div className='key'>PHONE</div>
                  <div className='value'>
                    {patient.phoneNumber || 'N/A'}
                  </div>
                </div>
              </div>
              <div className='data-row-no-padding'>
                <div className='key-value-container'>
                  <div className='key'>EMAIL</div>
                  <div className='value'>
                    {patient.email || 'N/A'}
                  </div>
                </div>
              </div>
            </PrescriptionElement>
          </div>
          <div className='horizontal-line' />
          <div className='data-row'>
            <PrescriptionElement className='w50' header='Physician Information' icon={PhysicianIcon}>
              <div className='physician'>
                <div className='key-value-container'>
                  <div className='key'>NAME</div>
                  <div className='value'>{`${physician.firstName} ${physician.lastName}`}</div>
                </div>

                <div className='key-value-container'>
                  <div className='key'>STATE LICENSE</div>
                  <div className='value'>{physician.stateLicenseNumber}</div>
                </div>
                <div className='flex-row'>
                  <div className='w50'>
                    <div className='key-value-container'>
                      <div className='key'>NPI</div>
                      <div className='value'>{physician.NPI}</div>
                    </div>
                  </div>
                  <div className='w50'>
                    <div className='key-value-container'>
                      <div className='key'>SPI</div>
                      <div className='value'>{physician.SPI}</div>
                    </div>
                  </div>
                </div>
              </div>
            </PrescriptionElement>

            <PrescriptionElement header='Contact' className='contact w50' icon={ContactIcon}>
              <div className='data-row-no-padding'>
                <div className='physician w50'>
                  <div className='key-value-container'>
                    <div className='key'>PHONE</div>
                    <div className='value'>
                      {_get(physician, 'physicianUser.user.phoneNumber') ? physician.physicianUser.user.phoneNumber : 'N/A'}
                    </div>
                  </div>
                </div>
                <div className='physician w50'>
                  <div className='key-value-container'>
                    <div className='key'>EMAIL</div>
                    <div className='value'>
                      {_get(physician, 'physicianUser.user.email') ? physician.physicianUser.user.email : 'N/A'}
                    </div>
                  </div>
                </div>
              </div>
              <div className='key-value-container'>
                <div className='key'>FACILITY</div>
                {
                  doesPhysicianHaveFacility(physician)
                    ? <React.Fragment>
                      <div className='value'>{_get(physician, 'healthcareSites[0].name')}</div>
                      <div className='value'>{`${_get(physician, 'healthcareSites[0].location.street1')}`}</div>
                      <div className='value'>{`${_get(physician, 'healthcareSites[0].location.city')}, ${_get(physician, 'healthcareSites[0].location.state')} ${_get(physician, 'healthcareSites[0].location.zip')}`}</div>
                    </React.Fragment>
                    : <div className='value'>N/A</div>
                }
              </div>
            </PrescriptionElement>
          </div>
          <div className='horizontal-line' />
        </div>
        {/* <div className='prescription-image-info'>
          <div className='data-row'>
            <PrescriptionElement className='w100' header='Pre-label Images' icon={ImageIcon}>
              <ContentGate
                active={!containerImagesFetched}
                fadeDuration={500}
              >
                <Gallery
                  enableImageSelection={false}
                  images={prescriptionInfoRouteStore.preLabelGallery}
                />
              </ContentGate>
            </PrescriptionElement>
          </div>
          <div className='horizontal-line' />
          <div className='data-row'>
            <PrescriptionElement className='w100' header='Post-label Images' icon={ImageIcon}>
              <ContentGate
                active={!containerImagesFetched}
                fadeDuration={500}
              >
                <Gallery
                  enableImageSelection={false}
                  images={prescriptionInfoRouteStore.postLabelGallery}
                />
              </ContentGate>
            </PrescriptionElement>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default observer(PrescriptionInfoView);
