import React from 'react';
import { observer } from 'mobx-react';

import Search from 'components/drill-down-layout/components/list/components/list-header/components/list-search';

import CrossIcon from 'assets/icons/cross.svg';

import './work-places-styles.scss';

const WorkPlaces = ({
  filter,
  onSearch,
  onWorkPlaceSuggestionClick,
  autosuggestedWorkPlaces = [],
  modifiedWorkPlaces = [],
  removeWorkPlace,
}) => (
  <div className='c-MedicalAssistantWorkPlaces'>
    <div className='search-container'>
      <Search
        placeholder='Healthcare Site...'
        searchValue={filter}
        onSearch={onSearch}
      />
      {
        !!autosuggestedWorkPlaces.length &&
        <div className='auto-suggest'>
          {
            autosuggestedWorkPlaces.map((workPlace) => (
              <div key={workPlace.id} className='work-place' onClick={() => onWorkPlaceSuggestionClick(workPlace)}>
                {
                  workPlace.name
                }
              </div>
            ))
          }
        </div>
      }
    </div>
    {
      !!modifiedWorkPlaces.length &&
        <div className='work-places'>
          {
            modifiedWorkPlaces.map((workPlace) => (
              <div key={workPlace.id} className='work-place'>
                <div className='name'>
                  {
                    workPlace.name
                  }
                </div>
                <img
                  alt='remove'
                  className='remove-icon'
                  src={CrossIcon}
                  onClick={() => removeWorkPlace(workPlace)}
                />
              </div>
            ))
          }
        </div>
    }
  </div>
);

export default observer(WorkPlaces);
