import { debounce as _debounce } from 'lodash';

// Helpers
import listLots from './listLots';

// Stores
import stores from 'bootstrap/store';
const lotsStore = stores.global.lots;

export default _debounce(() => {
  lotsStore.setValue('dataForPharmacist', {});
  return listLots();
}, 350);
