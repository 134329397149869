import deepFreeze from 'deep-freeze';

export const MODEL_NAME = Object.freeze({
  SINGULAR: 'prescriptionFillMailOrder',
  PLURAL: 'prescription-fill-mail-orders',
});

export const FIELD_NAME = Object.freeze({
  ID: 'id',
  PROCESSED: 'processed',
  PATIENT_ADDRESS_ID: 'patientAddressId',
  PRESCRIPTION_FILL_ID: 'prescriptionFillId',
});

export const SCHEMA = deepFreeze({
  title: MODEL_NAME.SINGULAR,
  type: 'object',
  properties: {
    [FIELD_NAME.ID]: {
      type: 'string',
    },
    [FIELD_NAME.PROCESSED]: {
      type: 'boolean',
    },
    [FIELD_NAME.PATIENT_ADDRESS_ID]: {
      type: 'string',
    },
    [FIELD_NAME.PRESCRIPTION_FILL_ID]: {
      type: 'string',
    },
  },
  required: [
    FIELD_NAME.ID,
  ]
});
