import React from 'react';
import { observer } from 'mobx-react';

import ListHeader from 'components/drill-down-layout/components/list/components/list-header';

import { CALL_REQUESTS_TABS } from '../../../../constants';

const PatientCallRequestsListHeader = ({
  filterValue,
  setFilterValue,
  activeListTab,
  setActiveListTab,
}) => (
  <ListHeader
    title='Call Requests'
    // Search
    searchValue={filterValue}
    onSearch={(e) => setFilterValue(e)}
    searchPlaceholder='Patient Name or Phone Number (numbers only)'
    // Tabs
    tabValues={CALL_REQUESTS_TABS}
    activeTab={activeListTab}
    onTabSelect={setActiveListTab}
  />
);

export default observer(PatientCallRequestsListHeader);
