import { flow, types } from 'mobx-state-tree';
import {
  isEqual as _isEqual,
  get as _get,
  keyBy as _keyBy,
} from 'lodash';

import base from 'models/base';
import { MODEL_NAME, SCHEMA } from './constants/schema';


const MedicalAssistantModel = base({
  names: {
    singular: MODEL_NAME.SINGULAR,
    plural: MODEL_NAME.PLURAL
  },
  JSONSchema: SCHEMA,
  httpConfig: {
    root: MODEL_NAME.plural,
    methods: {}
  }
});

MedicalAssistantModel.configureStore((store) => {
  return store
  .props({
    medicalAssistants: types.optional(types.map(types.frozen()), {}),
    entriesCount: types.optional(types.number, 0),
  })
  .actions(self => ({
    setValue: (prop, value) => self[prop] = value,
    listMedicalAssistants: flow(function* (config = {}) {
      const offset = _get(config, 'query.offset', 0);
      const limit = _get(config, 'query.limit', 0);
      if (!offset && self.medicalAssistants.size > limit) self.medicalAssistants = {};

      const response = yield self.list(config);
      const data = response.data || response || [];

      config.mergeResult = config.mergeResult !== false;
      if (config.mergeResult) self.medicalAssistants.merge(_keyBy(data, 'id'));
      else {
        // If data contains the same elements as "medicalAssistants" but in reverse order,
        // mobx-state-tree's map treats it as the same set of elements, so views won't rerender.
        const sameData = _isEqual(self.dataAsArray.map(i => i.id).sort(), data.map(i => i.id).sort());
        if (sameData) self.medicalAssistants = {};
        self.medicalAssistants = _keyBy(data, 'id');
      }

      return response;
    }),
    listMedicalAssistantsForPharmacist: flow(function* (config = {}) {
      const offset = _get(config, 'query.offset', 0);
      const limit = _get(config, 'query.limit', 0);
      if (!offset && self.medicalAssistants.size > limit) self.medicalAssistants = {};

      config.urlFragment = () => '/for-pharmacist';
      const response = yield self.list(config);
      const data = response.data || response || [];
      self.medicalAssistants.merge(_keyBy(data, 'id'));

      return response;
    }),
    countMedicalAssistants: flow(function* (config = {}) {
      config.urlFragment = () => '/count';

      const response = yield self.list(config);
      self.entriesCount = response || 0;

      return response;
    }),
    createMedicalAssistant: (config = {}) => {
      config.urlFragment = () => '';
      return self.post(config);
    },
    updateMedicalAssistant: (config = {}) => {
      if (!_get(config, 'params.medicalAssistantId')) {
        return Promise.reject('medicalAssistantId is required in order to update medical assistant.');
      }

      config.urlFragment = (params) => `/${params.medicalAssistantId}`;
      return self.put(config);
    },
    addHealthcareSite: (config = {}) => {
      if (!_get(config, 'params.medicalAssistantId')) {
        return Promise.reject('medicalAssistantId is required in order to add kiosk to medical assistant.');
      }
      if (!_get(config, 'params.healthcareSiteId')) {
        return Promise.reject('healthcareSiteId is required in order to add kiosk to medical assistant.');
      }

      config.urlFragment = (params) => `/${params.medicalAssistantId}/healthcare-sites/${params.healthcareSiteId}`;
      return self.post(config);
    },
    deleteHealthcareSite: (config = {}) => {
      if (!_get(config, 'params.medicalAssistantId')) {
        return Promise.reject('medicalAssistantId is required in order to delete kiosk from medical assistant.');
      }
      if (!_get(config, 'params.healthcareSiteId')) {
        return Promise.reject('healthcareSiteId is required in order to delete kiosk to medical assistant.');
      }

      config.urlFragment = (params) => `/${params.medicalAssistantId}/healthcare-sites/${params.healthcareSiteId}`;
      return self.delete(config);
    },
    deleteMedicalAssistant: (config = {}) => {
      if (!_get(config, 'params.medicalAssistantId')) {
        return Promise.reject('medicalAssistantId is required in order to delete medical assistant.');
      }

      config.urlFragment = (params) => `/${params.medicalAssistantId}`;
      return self.delete(config);
    },
  }))
  .views(self => ({
    get dataAsArray() {
      return Array.from(self.medicalAssistants.values());
    },
  }));
});

// Can't use module.exports because there is a generator function in this file,
// and that causes module.exports to fail for some reason.
export default MedicalAssistantModel;
