import stores from 'bootstrap/store';

import { EVENT } from '../../constants';

const containersAddLabelledImageHandler = ({ apiWebSocket }) => {
  apiWebSocket.on(EVENT.RECEIVED.CONTAINER_ADD_LABELLED_IMAGE, async () => {
    return stores.global.dispenseAttempts.listPendingForNotificationsPane();
  });
};

export default containersAddLabelledImageHandler;
