import React from 'react';
import { observer } from 'mobx-react';

import './index.scss';



const ContentTab = ({ children }) => {
  return <div className='c-ContentTab'>
    {
      children
    }
  </div>;
};


export default observer(ContentTab);
