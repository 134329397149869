import stores from 'bootstrap/store';

// TODO ???? why ????
import { kioskInventoryRouteStore } from 'app/root/routes/app-frame/routes/user/kiosks/routes/inventory/domain/store';
const kiosksStore = stores.global.kiosks;
const kiosksRouteStore = stores.route.kiosks.domain;

export default () => {
  const { activeKioskId } = kiosksRouteStore;
  if (!activeKioskId) return;

  return kiosksStore.getComputedInventory({
    query: { kioskId: activeKioskId },
  })
  .then(() => kioskInventoryRouteStore.setValue('modifiedInventory', kiosksStore.computedInventory));
};
