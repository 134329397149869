import { types } from 'mobx-state-tree';
import {
  get as _get,
  set as _set,
  xorBy as _xorBy,
} from 'lodash';

import PharmacyModel from 'models/pharmacies';
import { pharmaciesRouteStore } from '../../../domain/store';

export const PharmacyUpsertModalRouteStore = types.model({
})
.props({
  activelyModifiedPharmacy: types.maybeNull(types.frozen()),
  showingDeleteConfirmationModal: types.optional(types.boolean, false),

  locationSelector: types.optional(types.model({
    label: types.optional(types.string, ''),
    value: types.optional(types.string, ''),
  }), {label: '', value: ''}),
  locationSelectorFilter: types.optional(types.string, ''),

  healthcareSiteSelectorFilter: types.optional(types.string, ''),
})
.actions(self => ({
  setValue: (path, value) => _set(self, path, value),
  setInitialState() {
    self.setValue('activelyModifiedPharmacy', null);
    self.setValue('showingDeleteConfirmationModal', false);
    self.setValue('locationSelector', {label: '', value: ''});
    self.setValue('locationSelectorFilter', '');
    self.setValue('healthcareSiteSelector', {label: '', value: ''});
    self.setValue('healthcareSiteSelectorFilter', '');
  },
  addHealthcareSiteToPharmacy: ({value, label}) => {
    if (!self.activelyModifiedPharmacy) return;
    if (!self.activelyModifiedPharmacy.healthcareSites) self.activelyModifiedPharmacy.healthcareSites = [];

    self.activelyModifiedPharmacy.healthcareSites.push({id: value, name: label});
  },
  deleteHealthcareSiteFromPharmacy: (healthcareSite) => {
    if (!self.activelyModifiedPharmacy) return;
    if (!self.activelyModifiedPharmacy.healthcareSites) return;

    self.activelyModifiedPharmacy.healthcareSites.remove(healthcareSite);
  }
}))
.views(self => ({
  get readyForCreation() {
    return !!(
      _get(self, 'activelyModifiedPharmacy.name') &&
      _get(self, 'activelyModifiedPharmacy.phoneNumber') &&
      _get(self, 'locationSelector.value')
    );
  },
  get readyForUpdate() {
    const { activePharmacy } = pharmaciesRouteStore;
    const { activelyModifiedPharmacy, locationSelector } = self;
    if (!activePharmacy || !activelyModifiedPharmacy) return false;

    const healthcareSiteIds = (activePharmacy.healthcareSites || []).map((healthcareSite) => healthcareSite.id);
    const modifiedHealthcareSiteIds = (activelyModifiedPharmacy.healthcareSites || []).map((healthcareSite) => healthcareSite.id);

    return (
      activePharmacy.name !== activelyModifiedPharmacy.name ||
      _xorBy(healthcareSiteIds, modifiedHealthcareSiteIds).length ||
      _get(activePharmacy, 'phoneNumber') !== _get(activelyModifiedPharmacy, 'phoneNumber') ||
      _get(locationSelector, 'value') !== _get(activePharmacy, 'locationId')
    );
  }
}));

export const pharmacyUpsertModalRouteStore = PharmacyUpsertModalRouteStore.create({});
