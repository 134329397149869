import React from 'react';
import { observer } from 'mobx-react';

import AppCommands from 'components/app-commands';

import { ACTION_NAME } from '../../constants';

// Styles
import './healthcare-site-activity-view.scss';

const HealthcareSiteActivityView  = ({
  healthcareSiteActivityRouteStore,
  appCommandsStore,
  setFilter,
  listAppCommands,
}) => {
  return <div className='r-HealthcareSiteActivityView'>
    <AppCommands
      appCommandsByDate={appCommandsStore.appCommandsByDateByTypeForHealthcareSite({
        type: healthcareSiteActivityRouteStore.appCommandFilter,
        displayableOnly: true,
      })}
      setFilter={setFilter}
      listAppCommands={listAppCommands}
      isListingAppCommands={!!healthcareSiteActivityRouteStore.http.listingAppCommands}
      appCommandFilter={healthcareSiteActivityRouteStore.appCommandFilter}
      actionConfig={ACTION_NAME}
    />
  </div>;
};


export default observer(HealthcareSiteActivityView);
