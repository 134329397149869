import React from 'react';
import { observer } from 'mobx-react';

import CaptionValueInput from 'components/caption-value-input';
import DeleteConfirmationModal from 'components/delete-confirmation-modal';
import LabelledCheckbox from 'components/labelled-checkbox';
import Modal from 'components/modal';
import ModalEditingAreas from 'components/modal/layout/editing-areas';
import ModalEditingArea from 'components/modal/layout/editing-areas/components/editing-area';
import ModalRow from 'components/modal/layout/row';
import ModalButton from 'components/modal/layout/button';

const UserUpsertModal = ({
  userUpsertModalRouteStore,
  usersRouteStore,
  createUser,
  updateUser,
  deleteUser,
  closeUserUpsertModal,
  showUserDeleteConfirmationModal
}) => {
  const activeUser = usersRouteStore.activeUser;
  const activelyModifiedUser = userUpsertModalRouteStore.activelyModifiedUser || {};

  return (
    <Modal
      modalStyles={{ height: '54%', width: '50%' }}
      isOpen={usersRouteStore.showingUserUpsertModal}
      shouldCloseOnOverlayClick={true}
      close={closeUserUpsertModal}
      title={activeUser ? `${activeUser.firstName} ${activeUser.lastName}` : 'New User'}
    >
      {
        activeUser &&
        <DeleteConfirmationModal
          showing={userUpsertModalRouteStore.showingDeleteConfirmationModal}
          deletedItemName={`${activeUser.firstName} ${activeUser.lastName}`}
          deleteItem={deleteUser}
          closeModal={() => showUserDeleteConfirmationModal(false)}
        />
      }
      <ModalEditingAreas>
        <ModalEditingArea>
          <ModalRow>
            <CaptionValueInput
              inputFieldClassNames={['max-width']}
              caption='First Name'
              value={activelyModifiedUser.firstName}
              onChange={(value) => userUpsertModalRouteStore.setValue('activelyModifiedUser.firstName', value)}
            />
          </ModalRow>
          <ModalRow className='row'>
            <CaptionValueInput
              inputFieldClassNames={['max-width']}
              caption='Email'
              value={activelyModifiedUser.email}
              onChange={(value) => userUpsertModalRouteStore.setValue('activelyModifiedUser.email', value)}
            />
          </ModalRow>
          <ModalRow className='row'>
            <LabelledCheckbox
              label='Admin'
              isChecked={activelyModifiedUser.isAdmin}
              onChange={(value) => userUpsertModalRouteStore.setValue('activelyModifiedUser.isAdmin', value)}
            />
          </ModalRow>
          <ModalRow shouldBeSpaceBetween={true} shouldBoundToBottom={true}>
            <ModalButton
              isDisabled={!activeUser && !userUpsertModalRouteStore.readyForCreation}
              color='blue'
              text='Save'
              onClick={activeUser ? updateUser : createUser}
            />
            {
              activeUser &&
              <ModalButton
                color='red'
                text='Delete'
                onClick={() => showUserDeleteConfirmationModal()}
              />
            }
          </ModalRow>
        </ModalEditingArea>
        <ModalEditingArea>
          <ModalRow className='row'>
            <CaptionValueInput
              inputFieldClassNames={['max-width']}
              caption='Last Name'
              value={activelyModifiedUser.lastName}
              onChange={(value) => userUpsertModalRouteStore.setValue('activelyModifiedUser.lastName', value)}
            />
          </ModalRow>
          {
            !activeUser &&
            <ModalRow className='row'>
              <CaptionValueInput
                inputFieldClassNames={['max-width']}
                type='password'
                caption='Password'
                value={activelyModifiedUser.password}
                onChange={(value) => userUpsertModalRouteStore.setValue('activelyModifiedUser.password', value)}
              />
            </ModalRow>
          }
        </ModalEditingArea>
      </ModalEditingAreas>
    </Modal>
  );
};

export default observer(UserUpsertModal);
