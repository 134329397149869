import { useEffect } from 'react';

import useClosureEscape from 'hooks/useClosureEscape';


export default (fn, interval) => {
  const wrapped = useClosureEscape(fn);
  
  useEffect(() => {
    const intervalId = setInterval(wrapped, interval);
    return () => clearInterval(intervalId);
  }, []);
};
