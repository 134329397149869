import { prescriptionFillsRouteStore } from '../domain/store';

export default (activePrescriptionFillId) => {
  const activePrescriptionFill = prescriptionFillsRouteStore.prescriptionFillsById.get(activePrescriptionFillId);
  const isFulfillmentLocked = Boolean(activePrescriptionFill?.prescriptionFillsFulfillment?.computed_locked);
  const hasDispenseAttempt = prescriptionFillsRouteStore.prescriptionFillHasDispenseAttempt(activePrescriptionFill);

  if (activePrescriptionFill && (!isFulfillmentLocked || !hasDispenseAttempt)) {
    prescriptionFillsRouteStore.setValue('activePrescriptionFillId', activePrescriptionFillId);
    prescriptionFillsRouteStore.setValue('showingPrescriptionFillEditModal', true);
  }
};
