import React from 'react';
import { observer } from 'mobx-react';

import ContentHeader from 'components/drill-down-layout/components/content/components/content-header';

import PatientIcon from 'assets/icons/patient.svg';

import './medical-assistant-icon.scss';


const MedicalAssistantsContentHeader = ({
  medicalAssistantsRouteStore,
  isReady,
}) => {
  if (!isReady) return null;

  const { activeMedicalAssistant } = medicalAssistantsRouteStore;

  return <ContentHeader
    title={activeMedicalAssistant && `${activeMedicalAssistant.firstName} ${activeMedicalAssistant.lastName}`}
    subtitle={`Medical Assistant for ${medicalAssistantsRouteStore.activeMedicalAssistantHealthcareSitesCountCaption}`}
    leftLabel={
      <img
        alt='Medical Assistant'
        src={PatientIcon}
        className='medical-assistant-icon-preview-large'
      />
    }
  />;
};

export default observer(MedicalAssistantsContentHeader);
