import React from 'react';
import { observer } from 'mobx-react';

import ContentScreen from 'components/content-screen';
import CallerKioskName from './components/caller-kiosk-name';
import CallerInfo from './components/caller-info';
import Modal from 'components/modal';

import { getConferenceStatusCaption } from './helpers';

import EndCallPhone from 'assets/icons/end-call-phone.svg';

import './video-conference-style.scss';

const VideoConferenceView = ({
  showingVideoConferenceModal,
  allowEndingCall,
  videoConferencePurpose,
  remoteTracks = {},
  conferenceStatus,
  connectingToTwilio,
  notificationPayload,
  prescriptionFillsForDisplay,
  leaveRoom,
  approveDispenseAttempt,
  rejectDispenseAttempt,
}) => {
  const conferenceStatusCaption = getConferenceStatusCaption(conferenceStatus);

  let filteredRemoteTracks = [];
  if (remoteTracks && remoteTracks.size > 0) filteredRemoteTracks = Array.from(remoteTracks.values())[0];
  filteredRemoteTracks = Array.from(filteredRemoteTracks.values());

  return (
    <Modal
      modalClassName='c-VideoConferenceModal'
      modalStyles={{ height: '500px', width: '1000px' }}
      isOpen={showingVideoConferenceModal}
      shouldCloseOnOverlayClick={false}
    >
      <div className='video-conference-wrapper'>
        <div className='video-conference'>
          {
            conferenceStatusCaption
              ? <div className='connection-status'>
                {
                  conferenceStatusCaption
                }
              </div>
              : <div className='remote-media' ref={(el) => {
                if (!el) return;
                filteredRemoteTracks.forEach((track) => {
                  el.appendChild(track.htmlRef);
                  track.htmlRef.play();
                });
              }}>
                <CallerKioskName
                  notificationPayload={notificationPayload}
                />
                <ContentScreen active={connectingToTwilio} />
              </div>
          }
          <button
            className='end-call-button-container'
            disabled={!allowEndingCall}
            onClick={leaveRoom}
          >
            <img
              alt='end-call'
              className='phone-icon'
              src={EndCallPhone}
            />
            <div className='text'>
              End
            </div>
          </button>
        </div>
        <div className='call-info'>
          <CallerInfo
            videoConferencePurpose={videoConferencePurpose}
            notificationPayload={notificationPayload}
            prescriptionFillsForDisplay={prescriptionFillsForDisplay}
            connectingToTwilio={connectingToTwilio}
            approveDispenseAttempt={approveDispenseAttempt}
            rejectDispenseAttempt={rejectDispenseAttempt}
          />
        </div>
      </div>
    </Modal>
  );
};

export default observer(VideoConferenceView);
