import React from 'react';
import { observer } from 'mobx-react';

import AdminTechnicians from 'components/admin-technicians-view';

import { COLUMNS } from './constants';

const MachineTechniciansView = ({
  machineTechniciansStore,
  machineTechniciansRouteStore,
  machineTechnicianUpsertModalRouteStore,
  sortMachineTechnicians,
  changePaginationPage,
  onMachineTechniciansSearch,
  showMachineTechnicianUpsertModal,
  addKioskLocally,
  removeKioskLocally,
  onKiosksSearch,
  closeMachineTechnicianUpsertModal,
  createMachineTechnician,
  updateMachineTechnician,
  deleteMachineTechnician,
  showMachineTechnicianDeleteConfirmationModal,
}) => (
  <AdminTechnicians
    type='Machine'
    store={machineTechniciansStore}
    routeStore={machineTechniciansRouteStore}
    upsertModalRouteStore={machineTechnicianUpsertModalRouteStore}
    smartTableColumns={COLUMNS}
    sortTechnicians={sortMachineTechnicians}
    changePaginationPage={changePaginationPage}
    onTechniciansSearch={onMachineTechniciansSearch}
    showUpsertModal={showMachineTechnicianUpsertModal}
    closeUpsertModal={closeMachineTechnicianUpsertModal}
    addWorkPlaceLocally={addKioskLocally}
    removeWorkPlaceLocally={removeKioskLocally}
    onWorkPlacesSearch={onKiosksSearch}
    createTechnician={createMachineTechnician}
    updateTechnician={updateMachineTechnician}
    deleteTechnician={deleteMachineTechnician}
    showDeleteConfirmationModal={showMachineTechnicianDeleteConfirmationModal}
  />
);

export default observer(MachineTechniciansView);
