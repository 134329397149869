import { listPatientsDebounce } from './index';

import stores from 'bootstrap/store';
import { patientsIncompleteInfoRouteStore } from '../domain/store';
const patientsStore = stores.global.patients;

export default (e) => {
  patientsIncompleteInfoRouteStore.setValue('filterValue', e.target.value);
  patientsIncompleteInfoRouteStore.setValue('currentPage', 1);
  patientsStore.setValue('dataForPharmacist', {});

  return listPatientsDebounce();
};
