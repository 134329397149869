import stores from 'bootstrap/store';
import { lotInfoRouteStore } from '../domain/store';

const fetchLotById = async (lotId) => {
  if (!lotId) {
    return;
  }

  lotInfoRouteStore.incrementHttp('fetchingLotById');
  return stores.global.lots.getById(lotId)
  .then((lot) => lotInfoRouteStore.setValue('activeLot', lot))
  .finally(() => lotInfoRouteStore.decrementHttp('fetchingLotById'));
};

export default fetchLotById;
