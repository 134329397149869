import { types } from 'mobx-state-tree';

import { NOTIFICATION_TYPES } from '../constants';
import { openNotifications, closeNotifications } from '../helpers';

export const NotificationsPaneRouteStore = types.model({
  activeTab: types.maybeNull(types.enumeration('Notification Tabs', NOTIFICATION_TYPES)),
  isOpen: types.optional(types.boolean, false),
})
.actions(self => ({
  setValue: (prop, value) => self[prop] = value,
  setActiveTab: (value) => {
    if (!value || self.activeTab === value) closeNotifications(self);
    else openNotifications(self, value);
  },
  close: () => closeNotifications(self),
}));

export const notificationsPaneRouteStore = NotificationsPaneRouteStore.create({});

