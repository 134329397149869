import React from 'react';
import { observer } from 'mobx-react';

import './index.scss';

const TableHeader = ({
  children
}) => (
  <div className='c-TableHeader'>
    {
      children
    }
  </div>
);

export default observer(TableHeader);