import React from 'react';
import { observer } from 'mobx-react';
import { isBoolean as _isBoolean, isNumber as _isNumber } from 'lodash';

// Components
import AutocompleteCaptionValueInput from './components/autocomplete-caption-value-input';
import ClearSectionButton from './components/clear-section-button';
import CaptionValueInput from 'components/caption-value-input';
import ContentScreen from 'components/content-screen';
import Select from 'components/caption-value-input-with-select';

// Utils
import formatNDC from 'utils/formatNDC';

// Constants
import { PRESCRIPTION_FILL_PARAMS } from './constants';
import { TYPE } from './components/autocomplete-caption-value-input/constants';
import { SPECIAL_TYPE as INPUT_TYPE } from 'components/caption-value-input/specialComponents/constants';

// Icons
import DrugIcon from 'assets/icons/drug-active.svg';
import PatientIcon from 'assets/icons/patient-active.svg';
import PhysicianIcon from 'assets/icons/physician-active.svg';
import PrescriptionIcon from 'assets/icons/prescriptions-active.svg';

// Styles
import './prescription-fills-manual-entry-styles.scss';

const PrescriptionFillsManualOrderView = ({
  prescriptionFillsManualEntryRouteStore,
  onPatientAutocompleteInputChange,
  onPatientSuggestionClick,
  onDrugAutocompleteInputChange,
  onDrugSuggestionClick,
  onPhysicianAutocompleteInputChange,
  onPhysicianSuggestionClick,
  upsertPrescriptionFillWithReferences,
}) => {
  return <div className='r-PrescriptionFillsManualEntryView'>
    <ContentScreen
      active={prescriptionFillsManualEntryRouteStore.isBusy}
    />
    <div className='header-container'>
      <div className='title'>
        Prescription Manual Entry
      </div>
      <div className={`kiosk-select ${prescriptionFillsManualEntryRouteStore.kioskOption || 'warning'}`}>
        <Select
          caption='Kiosk'
          placeholder='Select Kiosk to Route To'
          items={prescriptionFillsManualEntryRouteStore.kioskOptions}
          value={prescriptionFillsManualEntryRouteStore.kioskOption}
          onChange={(option) => prescriptionFillsManualEntryRouteStore.setValue('kioskOption', option)}
        />
      </div>
    </div>
    <div className='data-input-container'>
      <div className='entity-container'>
        <div className='header'>
          <div className='title-container'>
            <img
              className='icon'
              alt='Patient'
              src={PatientIcon}
            />
            <div className='title'>
              Patient
            </div>
          </div>
          <ClearSectionButton
            onClick={() => prescriptionFillsManualEntryRouteStore.setValue('patient', {})}
          />
        </div>
        <div className='inputs'>
          <div className='row'>
            <AutocompleteCaptionValueInput
              type={TYPE.PATIENT}
              caption='First Name'
              value={prescriptionFillsManualEntryRouteStore.patient.firstName}
              onInputChange={(value) => onPatientAutocompleteInputChange('firstName', value)}
              required={PRESCRIPTION_FILL_PARAMS['patient.firstName'].required}
              suggestions={prescriptionFillsManualEntryRouteStore.suggestions.patient.firstName}
              onSuggestionClick={(patient) => onPatientSuggestionClick(patient, 'firstName')}
              clearSuggestions={() => prescriptionFillsManualEntryRouteStore.setValue('suggestions.patient.firstName', [])}
            />
            <AutocompleteCaptionValueInput
              type={TYPE.PATIENT}
              caption='Last Name'
              value={prescriptionFillsManualEntryRouteStore.patient.lastName}
              onInputChange={(value) => onPatientAutocompleteInputChange('lastName', value)}
              required={PRESCRIPTION_FILL_PARAMS['patient.lastName'].required}
              suggestions={prescriptionFillsManualEntryRouteStore.suggestions.patient.lastName}
              onSuggestionClick={(patient) => onPatientSuggestionClick(patient, 'lastName')}
              clearSuggestions={() => prescriptionFillsManualEntryRouteStore.setValue('suggestions.patient.lastName', [])}
            />
          </div>
          <div className='row'>
            <CaptionValueInput
              className='input-with-margin'
              inputFieldClassNames={['max-width']}
              caption='Date Of Birth'
              // placeholder='YYYY-MM-DD'
              placeholder='MM/DD/YYYY'
              value={prescriptionFillsManualEntryRouteStore.patient.DOB}
              onChange={(value) => prescriptionFillsManualEntryRouteStore.setValue('patient.DOB', value)}
              validationRegex={PRESCRIPTION_FILL_PARAMS['patient.DOB'].regex}
              required={PRESCRIPTION_FILL_PARAMS['patient.DOB'].required}
            />
          </div>
          <div className='row'>
            <AutocompleteCaptionValueInput
              type={TYPE.PATIENT}
              caption='Unique Identifier'
              value={prescriptionFillsManualEntryRouteStore.patient.externalPharmacyId}
              validationRegex={PRESCRIPTION_FILL_PARAMS['patient.externalPharmacyId'].regex}
              onInputChange={(value) => onPatientAutocompleteInputChange('externalPharmacyId', value)}
              required={PRESCRIPTION_FILL_PARAMS['patient.externalPharmacyId'].required}
              suggestions={prescriptionFillsManualEntryRouteStore.suggestions.patient.externalPharmacyId}
              onSuggestionClick={(patient) => onPatientSuggestionClick(patient, 'externalPharmacyId')}
              clearSuggestions={() => prescriptionFillsManualEntryRouteStore.setValue('suggestions.patient.externalPharmacyId', [])}
            />
          </div>
          <div className='row'>
            <CaptionValueInput
              className='input-with-margin'
              inputFieldClassNames={['max-width']}
              caption='Email Address'
              value={prescriptionFillsManualEntryRouteStore.patient.email}
              onChange={(value) => prescriptionFillsManualEntryRouteStore.setValue('patient.email', value || null)}
              validationRegex={PRESCRIPTION_FILL_PARAMS['patient.email'].regex}
              required={PRESCRIPTION_FILL_PARAMS['patient.email'].required}
            />
            <CaptionValueInput
              className='input-with-margin'
              type={INPUT_TYPE.PHONE}
              caption='Phone Number'
              value={prescriptionFillsManualEntryRouteStore.patient.phoneNumber}
              onChange={(value) => prescriptionFillsManualEntryRouteStore.setValue('patient.phoneNumber', value || null)}
              validationRegex={PRESCRIPTION_FILL_PARAMS['patient.phoneNumber'].regex}
              required={PRESCRIPTION_FILL_PARAMS['patient.phoneNumber'].required}
            />
          </div>
        </div>
      </div>
      <div className='entity-container'>
        <div className='header'>
          <div className='title-container'>
            <img
              className='icon'
              alt='Drug'
              src={DrugIcon}
            />
            <div className='title'>
              Drug
            </div>
          </div>
          <ClearSectionButton
            onClick={() => prescriptionFillsManualEntryRouteStore.setValue('drug', {})}
          />
        </div>
        <div className='inputs'>
          <div className='row'>
            <AutocompleteCaptionValueInput
              type={TYPE.DRUG}
              caption='Name'
              value={prescriptionFillsManualEntryRouteStore.drug.name}
              onInputChange={(value) => onDrugAutocompleteInputChange('name', value)}
              required={PRESCRIPTION_FILL_PARAMS['drug.name'].required}
              suggestions={prescriptionFillsManualEntryRouteStore.suggestions.drug.name}
              onSuggestionClick={(drug) => onDrugSuggestionClick(drug, 'name')}
              clearSuggestions={() => prescriptionFillsManualEntryRouteStore.setValue('suggestions.drug.name', [])}
            />
            <AutocompleteCaptionValueInput
              type={TYPE.DRUG}
              caption='NDC'
              value={prescriptionFillsManualEntryRouteStore.drug.NDC}
              onInputChange={(value) => onDrugAutocompleteInputChange('NDC', value)}
              onBlur={() => {
                const formattedNDC = formatNDC(prescriptionFillsManualEntryRouteStore.drug.NDC);
                if (formattedNDC) {
                  prescriptionFillsManualEntryRouteStore.setValue('drug.NDC', formattedNDC);
                }
              }}
              validationRegex={PRESCRIPTION_FILL_PARAMS['drug.NDC'].regex}
              validationError='xxxx(x)-xxx(x)-x(x) or xxxxxxxxxxx'
              required={PRESCRIPTION_FILL_PARAMS['drug.NDC'].required}
              suggestions={prescriptionFillsManualEntryRouteStore.suggestions.drug.NDC}
              onSuggestionClick={(drug) => onDrugSuggestionClick(drug, 'NDC')}
              clearSuggestions={() => prescriptionFillsManualEntryRouteStore.setValue('suggestions.drug.NDC', [])}
            />
            <CaptionValueInput
              className='input-with-margin'
              inputFieldClassNames={['max-width']}
              caption='Quantity'
              value={prescriptionFillsManualEntryRouteStore.drug.quantity}
              onChange={(value) => prescriptionFillsManualEntryRouteStore.setValue('drug.quantity', value)}
              validationRegex={PRESCRIPTION_FILL_PARAMS['drug.quantity'].regex}
              required={PRESCRIPTION_FILL_PARAMS['drug.quantity'].required}
            />
          </div>
        </div>
      </div>
      <div className='entity-container'>
        <div className='header'>
          <div className='title-container'>
            <img
              className='icon'
              alt='Physician'
              src={PhysicianIcon}
            />
            <div className='title'>
              Physician
            </div>
          </div>
          <ClearSectionButton
            onClick={() => prescriptionFillsManualEntryRouteStore.setValue('physician', {})}
          />
        </div>
        <div className='inputs'>
          <div className='row'>
            <AutocompleteCaptionValueInput
              type={TYPE.PHYSICIAN}
              caption='First Name'
              value={prescriptionFillsManualEntryRouteStore.physician.firstName}
              onInputChange={(value) => onPhysicianAutocompleteInputChange('firstName', value)}
              required={PRESCRIPTION_FILL_PARAMS['physician.firstName'].required}
              suggestions={prescriptionFillsManualEntryRouteStore.suggestions.physician.firstName}
              onSuggestionClick={(physician) => onPhysicianSuggestionClick(physician, 'firstName')}
              clearSuggestions={() => prescriptionFillsManualEntryRouteStore.setValue('suggestions.physician.firstName', [])}
            />
            <AutocompleteCaptionValueInput
              type={TYPE.PHYSICIAN}
              caption='Last Name'
              value={prescriptionFillsManualEntryRouteStore.physician.lastName}
              onInputChange={(value) => onPhysicianAutocompleteInputChange('lastName', value)}
              required={PRESCRIPTION_FILL_PARAMS['physician.lastName'].required}
              suggestions={prescriptionFillsManualEntryRouteStore.suggestions.physician.lastName}
              onSuggestionClick={(physician) => onPhysicianSuggestionClick(physician, 'lastName')}
              clearSuggestions={() => prescriptionFillsManualEntryRouteStore.setValue('suggestions.physician.lastName', [])}
            />
          </div>
          <div className={`identifiers-grouping ${prescriptionFillsManualEntryRouteStore.doesPhysicianHaveIdentifier || 'warning'}`}>
            <div className='row'>
              <AutocompleteCaptionValueInput
                type={TYPE.PHYSICIAN}
                caption='NPI'
                value={prescriptionFillsManualEntryRouteStore.physician.NPI}
                onInputChange={(value) => onPhysicianAutocompleteInputChange('NPI', value)}
                validationRegex={PRESCRIPTION_FILL_PARAMS['physician.NPI'].regex}
                required={PRESCRIPTION_FILL_PARAMS['physician.NPI'].required}
                suggestions={prescriptionFillsManualEntryRouteStore.suggestions.physician.NPI}
                onSuggestionClick={(physician) => onPhysicianSuggestionClick(physician, 'NPI')}
                clearSuggestions={() => prescriptionFillsManualEntryRouteStore.setValue('suggestions.physician.NPI', [])}
              />
              <AutocompleteCaptionValueInput
                type={TYPE.PHYSICIAN}
                caption='SPI'
                value={prescriptionFillsManualEntryRouteStore.physician.SPI}
                onInputChange={(value) => onPhysicianAutocompleteInputChange('SPI', value)}
                validationRegex={PRESCRIPTION_FILL_PARAMS['physician.SPI'].regex}
                required={PRESCRIPTION_FILL_PARAMS['physician.SPI'].required}
                suggestions={prescriptionFillsManualEntryRouteStore.suggestions.physician.SPI}
                onSuggestionClick={(physician) => onPhysicianSuggestionClick(physician, 'SPI')}
                clearSuggestions={() => prescriptionFillsManualEntryRouteStore.setValue('suggestions.physician.SPI', [])}
              />
            </div>
            <div className='row'>
              <AutocompleteCaptionValueInput
                type={TYPE.PHYSICIAN}
                caption='State License #'
                value={prescriptionFillsManualEntryRouteStore.physician.stateLicenseNumber}
                onInputChange={(value) => onPhysicianAutocompleteInputChange('stateLicenseNumber', value)}
                validationRegex={PRESCRIPTION_FILL_PARAMS['physician.stateLicenseNumber'].regex}
                required={PRESCRIPTION_FILL_PARAMS['physician.stateLicenseNumber'].required}
                suggestions={prescriptionFillsManualEntryRouteStore.suggestions.physician.stateLicenseNumber}
                onSuggestionClick={(physician) => onPhysicianSuggestionClick(physician, 'stateLicenseNumber')}
                clearSuggestions={() => prescriptionFillsManualEntryRouteStore.setValue('suggestions.physician.stateLicenseNumber', [])}
              />
              <AutocompleteCaptionValueInput
                type={TYPE.PHYSICIAN}
                caption='Unique Identifier'
                value={prescriptionFillsManualEntryRouteStore.physician.externalPharmacyId}
                onInputChange={(value) => onPhysicianAutocompleteInputChange('externalPharmacyId', value)}
                validationRegex={PRESCRIPTION_FILL_PARAMS['physician.externalPharmacyId'].regex}
                required={PRESCRIPTION_FILL_PARAMS['physician.externalPharmacyId'].required}
                suggestions={prescriptionFillsManualEntryRouteStore.suggestions.physician.externalPharmacyId}
                onSuggestionClick={(physician) => onPhysicianSuggestionClick(physician, 'externalPharmacyId')}
                clearSuggestions={() => prescriptionFillsManualEntryRouteStore.setValue('suggestions.physician.externalPharmacyId', [])}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='entity-container'>
        <div className='header'>
          <div className='title-container'>
            <img
              className='icon'
              alt='Prescription'
              src={PrescriptionIcon}
            />
            <div className='title'>
              Prescription
            </div>
          </div>
          <ClearSectionButton
            onClick={() => {
              prescriptionFillsManualEntryRouteStore.setValue('prescription', {});
              prescriptionFillsManualEntryRouteStore.setValue('prescriptionFill', {});
            }}
          />
        </div>
        <div className='inputs'>
          <div className='row'>
            <CaptionValueInput
              className='input-with-margin'
              inputFieldClassNames={['max-width']}
              caption='Rx ID'
              value={prescriptionFillsManualEntryRouteStore.prescription.rxId}
              onChange={(value) => prescriptionFillsManualEntryRouteStore.setValue('prescription.rxId', value)}
              validationRegex={PRESCRIPTION_FILL_PARAMS['prescription.rxId'].regex}
              required={PRESCRIPTION_FILL_PARAMS['prescription.rxId'].required}
            />
            <CaptionValueInput
              className='input-with-margin'
              inputFieldClassNames={['max-width']}
              caption='Copay $'
              value={prescriptionFillsManualEntryRouteStore.prescriptionFill.copay}
              onChange={(value) => prescriptionFillsManualEntryRouteStore.setValue('prescriptionFill.copay', value)}
              validationRegex={PRESCRIPTION_FILL_PARAMS['prescriptionFill.copay'].regex}
              required={PRESCRIPTION_FILL_PARAMS['prescriptionFill.copay'].required}
            />

          </div>
          <div className='row'>
            <CaptionValueInput
              className='input-with-margin'
              inputFieldClassNames={['max-width']}
              caption='Fill #'
              value={prescriptionFillsManualEntryRouteStore.prescriptionFill.fillNumber || ''}
              onChange={(value) => prescriptionFillsManualEntryRouteStore.setValue('prescriptionFill.fillNumber', _isNumber(Number(value)) ? Number(value) : null)}
              validationRegex={PRESCRIPTION_FILL_PARAMS['prescriptionFill.fillNumber'].regex}
              required={PRESCRIPTION_FILL_PARAMS['prescriptionFill.fillNumber'].required}
            />
            <CaptionValueInput
              className='input-with-margin'
              inputFieldClassNames={['max-width']}
              caption='Refills #'
              value={prescriptionFillsManualEntryRouteStore.prescription.refills || ''}
              onChange={(value) => prescriptionFillsManualEntryRouteStore.setValue('prescription.refills', _isNumber(Number(value)) ? Number(value) : null)}
              validationRegex={PRESCRIPTION_FILL_PARAMS['prescription.refills'].regex}
              required={PRESCRIPTION_FILL_PARAMS['prescription.refills'].required}
            />
          </div>
          <div className='row'>
            <CaptionValueInput
              className='input-with-margin'
              inputFieldClassNames={['max-width']}
              caption='Directions'
              value={prescriptionFillsManualEntryRouteStore.prescription.directions}
              onChange={(value) => prescriptionFillsManualEntryRouteStore.setValue('prescription.directions', value || null)}
              required={PRESCRIPTION_FILL_PARAMS['prescription.directions'].required}
            />
          </div>
        </div>
      </div>
    </div>
    <div className='footer'>
      <div className='buttons-container'>
        {
          !prescriptionFillsManualEntryRouteStore.wasManualOrderFulfilled &&
          <button
            className='btn btn-blue'
            disabled={!prescriptionFillsManualEntryRouteStore.canBeSubmitted}
            onClick={() => upsertPrescriptionFillWithReferences()}
          >
            Submit
          </button>
        }
        <button className='btn btn-red' onClick={() => prescriptionFillsManualEntryRouteStore.setInitialState()}>
          Clear All
        </button>
      </div>
      {
        _isBoolean(prescriptionFillsManualEntryRouteStore.wasManualOrderFulfilled) &&
        <div className={`result-message ${prescriptionFillsManualEntryRouteStore.wasManualOrderFulfilled ? 'success' : 'error'}`}>
          {
            prescriptionFillsManualEntryRouteStore.wasManualOrderFulfilled
              ? 'Manual prescription entry has successfully been fulfilled'
              : prescriptionFillsManualEntryRouteStore.httpError || 'Error occurred when fulfilling a manual prescription entry'
          }
        </div>
      }
    </div>
  </div>;
};

export default observer(PrescriptionFillsManualOrderView);
