import { types } from 'mobx-state-tree';


export const ResetPasswordRouteStore = types.model({
  email: types.optional(types.string, ''),
  errorMessage: types.optional(types.string, ''),
  isSuccess: types.optional(types.boolean, false),
})
.actions(self => ({
  setValue: (prop, value) => {
    if (self.errorMessage) self.errorMessage = '';
    if (self.isSuccess) self.isSuccess = false;
    self[prop] = value;
  },
  setInitialState: () => {
    self.email = '';
    self.errorMessage = '';
    self.isSuccess = false;
  }
}));

export const resetPasswordRouteStore = ResetPasswordRouteStore.create({});
