// User Icons
import containerIcon from 'assets/icons/container.svg';
import containerActiveIcon from 'assets/icons/container-active.svg';
import dispenseAttemptsIcon from 'assets/icons/dispense-attempts.svg';
import dispenseAttemptsActiveIcon from 'assets/icons/dispense-attempts-active.svg';
import prescriptionsIcon from 'assets/icons/prescriptions.svg';
import prescriptionsActiveIcon from 'assets/icons/prescriptions-active.svg';
import personnelIcon from 'assets/icons/personnel.svg';
import personnelActiveIcon from 'assets/icons/personnel-active.svg';
import keycardIcon from 'assets/icons/keycard.svg';
import keycardActiveIcon from 'assets/icons/keycard-active.svg';
import technicianIcon from 'assets/icons/technician.svg';
import technicianActiveIcon from 'assets/icons/technician-active.svg';
import lnActiveIcon from 'assets/icons/ln-active.svg';
import lnIcon from 'assets/icons/ln.svg';
import healthcareSiteActiveIcon from 'assets/icons/healthcare-site-active.svg';
import healthcareSiteIcon from 'assets/icons/healthcare-site.svg';
import unActivePhoneIcon from 'assets/icons/phone-unactive.svg';
import bluePhoneIcon from 'assets/icons/blue-phone.svg';

// Admin Icons
import drugIcon from 'assets/icons/drug.svg';
import drugActiveIcon from 'assets/icons/drug-active.svg';
import patientIcon from 'assets/icons/patient.svg';
import patientActiveIcon from 'assets/icons/patient-active.svg';
import physicianIcon from 'assets/icons/physician.svg';
import physicianActiveIcon from 'assets/icons/physician-active.svg';
import kioskIcon from 'assets/icons/kiosk.svg';
import kioskActiveIcon from 'assets/icons/kiosk-active.svg';
import kioskModuleIcon from 'assets/icons/kiosk-module.svg';
import kioskModuleActiveIcon from 'assets/icons/kiosk-module-active.svg';
import pharmacyActiveIcon from 'assets/icons/pharmacy-active.svg';
import pharmacyIcon from 'assets/icons/pharmacy.svg';

export const DISPENSE_ATTEMPTS = {
  NAME: 'Dispense Attempts',
  VALUE: '/dispense-attempts',
  PARAM: 'dispenseAttemptId',
  ICON: {
    ACTIVE: dispenseAttemptsActiveIcon,
    DISABLED: dispenseAttemptsIcon,
  },
  CONTENT_TAB: {
    PRESCRIPTION_INFO: {
      TITLE: 'Prescription Info',
      VALUE: '/prescription-info',
    },
  },
};

export const DRUGS = {
  NAME: 'Drugs',
  VALUE: '/drugs',
  PARAM: 'drugId',
  ICON: {
    ACTIVE: drugActiveIcon,
    DISABLED: drugIcon,
  },
};

export const MACHINE_TECHNICIANS = {
  NAME: 'Machine Technicians',
  VALUE: '/machine-assistants',
  PARAM: 'technicianId',
  ICON: {
    ACTIVE: personnelActiveIcon,
    DISABLED: personnelIcon,
  },
  CONTENT_TAB: {
    ACTIVITY: {
      TITLE: 'Activity',
      VALUE: '/activity',
    },
    CONTACT: {
      TITLE: 'Contact',
      VALUE: '/contact',
    },
  },
};

export const MEDICAL_ASSISTANTS = {
  NAME: 'Medical Assistants',
  VALUE: '/medical-assistants',
  PARAM: 'medicalAssistantId',
  ICON: {
    ACTIVE: personnelActiveIcon,
    DISABLED: personnelIcon,
  },
  CONTENT_TAB: {
    CONTACT: {
      TITLE: 'Contact',
      VALUE: '/contact',
    },
  },
};

export const PATIENTS = {
  NAME: 'Patients',
  VALUE: '/patients',
  ICON: {
    ACTIVE: patientActiveIcon,
    DISABLED: patientIcon,
  },
  SUB_ROUTE: {
    LIST_OVERVIEW: {
      NAME: 'List Overview',
      VALUE: '/list',
      PARAM: 'patientId',
      CONTENT_TAB: {
        INFO: {
          TITLE: 'Info',
          VALUE: '/info',
        },
      },
    },
    CALL_REQUESTS: {
      NAME: 'Call Requests',
      VALUE: '/call-requests',
      PARAM: 'callRequestId',
      CONTENT_TAB: {
        ACTIONS: {
          TITLE: 'Pharmacist Actions History',
          VALUE: '/pharmacist-actions-history',
        },
        PRESCRIPTION_HISTORY: {
          TITLE: 'Patient Prescriptions History',
          VALUE: '/patient-prescriptions-history',
        },
      },
    },
    INCOMPLETE: {
      NAME: 'New Patients',
      VALUE: '/incomplete',
      PARAM: 'patientId',
      CONTENT_TAB: {
        INFO: {
          TITLE: 'Info',
          VALUE: '/info',
        },
        MERGE: {
          TITLE: 'Merge',
          VALUE: '/merge',
        },
      },
    },
  },
};

export const PRESCRIPTION_FILLS = {
  NAME: 'Prescriptions',
  VALUE: '/prescription-fills',
  ICON: {
    ACTIVE: prescriptionsActiveIcon,
    DISABLED: prescriptionsIcon,
  },
  SUB_ROUTE: {
    MANAGE: {
      NAME: 'Manage',
      VALUE: '/manage'
    },
    STATS: {
      NAME: 'Stats',
      VALUE: '/stats',
      CONTENT_TAB: {
        FILLED: {
          TITLE: 'Filled',
          VALUE: '/filled',
        },
        DISPENSED: {
          TITLE: 'Dispensed',
          VALUE: '/dispensed',
        },
        MAIL_ORDERED: {
          TITLE: 'Mail Ordered',
          VALUE: '/mail-ordered',
        },
        DECLINED: {
          TITLE: 'Declined',
          VALUE: '/declined',
        },
      },
    },
    // LIST_OVERVIEW: {
    //   NAME: 'List Overview',
    //   VALUE: '/list',
    // },
    // TABLE_OVERVIEW: {
    //   NAME: 'Table Overview',
    //   VALUE: '/table',
    // },
    MAIL_ORDERED: {
      NAME: 'Mail Ordered',
      VALUE: '/mail-ordered',
    },
    MANUAL_ORDER: {
      NAME: 'Manual Entry',
      VALUE: '/manual-entry',
    },
    // TRANSFERS: {
    //   NAME: 'Transfers',
    //   VALUE: '/transfers',
    // },
    // UNROUTED: {
    //   NAME: 'Unrouted',
    //   VALUE: '/unrouted',
    // },
  },
};

export const PHARMACY_TECHNICIANS = {
  NAME: 'Pharmacy Technicians',
  VALUE: '/pharmacy-technicians',
  PARAM: 'technicianId',
  ICON: {
    ACTIVE: technicianActiveIcon,
    DISABLED: technicianIcon,
  },
  CONTENT_TAB: {
    ACTIVITY: {
      TITLE: 'Activity',
      VALUE: '/activity',
    },
    CONTACT: {
      TITLE: 'Contact',
      VALUE: '/contact',
    },
    HEALTHCARE_SITES: {
      TITLE: 'Healthcare Sites',
      VALUE: '/healthcare-sites',
    },
  },
};

export const KEYCARDS = {
  NAME: 'Keycards',
  VALUE: '/keycards',
  PARAM: 'keycardId',
  ICON: {
    ACTIVE: keycardActiveIcon,
    DISABLED: keycardIcon,
  },
};

export const KIOSKS = {
  NAME: 'Kiosks',
  VALUE: '/kiosks',
  PARAM: 'kioskId',
  ICON: {
    ACTIVE: kioskActiveIcon,
    DISABLED: kioskIcon,
  },
  CONTENT_TAB: {
    ACTIVITY: {
      TITLE: 'Activity',
      VALUE: '/activity',
    },
    CONTAINERS: {
      TITLE: 'Containers',
      VALUE: '/containers',
    },
    DEBUG: {
      TITLE: 'Debug',
      VALUE: '/debug',
    },
    INVENTORY: {
      TITLE: 'Inventory',
      VALUE: '/inventory',
    },
    LAYOUT: {
      TITLE: 'Layout',
      VALUE: '/layout',
    },
    ROUTING: {
      TITLE: 'Routing',
      VALUE: '/routing',
    },
    STATUSES: {
      TITLE: 'Status',
      VALUE: '/statuses',
    },
    STORAGE: {
      TITLE: 'Storage',
      VALUE: '/storage',
    },
    VIDEO_FEED: {
      TITLE: 'Video Feed',
      VALUE: '/video-feed',
    },
  },
};

export const PRESCRIPTION_FILL_UPSERT = {
  NAME: 'Prescription Create',
  VALUE: '/prescription-create',
  ICON: {
    ACTIVE: prescriptionsActiveIcon,
    DISABLED: prescriptionsIcon,
  },
};

export const HEALTHCARE_SITES = {
  NAME: 'Healthcare Sites',
  VALUE: '/healthcareSites',
  PARAM: 'healthcareSiteId',
  ICON: {
    ACTIVE: healthcareSiteActiveIcon,
    DISABLED: healthcareSiteIcon,
  },
  CONTENT_TAB: {
    ACTIVITY: {
      TITLE: 'Activity',
      VALUE: '/activity',
    },
    INVENTORY: {
      TITLE: 'Inventory',
      VALUE: '/inventory',
    },
  },
};

export const VIDEO_CONFERENCES = {
  NAME: 'Video Conferences',
  VALUE: '/video-conferences',
  ICON: {
    ACTIVE: bluePhoneIcon,
    DISABLED: unActivePhoneIcon,
  }
};

export const LOTS = {
  NAME: 'Lots',
  VALUE: '/lots',
  PARAM: 'lotId',
  ICON: {
    ACTIVE: lnActiveIcon,
    DISABLED: lnIcon,
  },
  CONTENT_TAB: {
    INFO: {
      TITLE: 'Info',
      VALUE: '/info',
    },
    PRESCRIPTIONS: {
      TITLE: 'Prescriptions',
      VALUE: '/prescriptions',
    },
  },
};

export const STOCKING_BATCHES = {
  NAME: 'Stocking Batches',
  VALUE: '/stocking-batches',
  PARAM: 'stockingBatchId',
  ICON: {
    ACTIVE: containerActiveIcon,
    DISABLED: containerIcon,
  },
  CONTENT_TAB: {
    INFO: {
      TITLE: 'Info',
      VALUE: '/info',
    },
    DRUGS: {
      TITLE: 'Drugs',
      VALUE: '/drugs',
    },
  },
};

export const ADMIN_DRUGS = {
  NAME: 'Drugs',
  VALUE: '/admin/drugs',
  PARAM: 'drugId',
  ICON: {
    ACTIVE: drugActiveIcon,
    DISABLED: drugIcon,
  },
};

export const ADMIN_MACHINE_TECHNICIANS = {
  NAME: 'Machine Technicians',
  VALUE: '/admin/machine-technicians',
  ICON: {
    ACTIVE: technicianActiveIcon,
    DISABLED: technicianIcon,
  },
};

export const ADMIN_MEDICAL_ASSISTANTS = {
  NAME: 'Medical Assistants',
  VALUE: '/admin/medical-assistants',
  ICON: {
    ACTIVE: personnelActiveIcon,
    DISABLED: personnelIcon,
  },
};

export const ADMIN_MODULE_TEMPLATES = {
  NAME: 'Modules',
  VALUE: '/admin/modules',
  PARAM: 'moduleId',
  ICON: {
    ACTIVE: kioskModuleActiveIcon,
    DISABLED: kioskModuleIcon,
  },
};

export const ADMIN_PATIENTS = {
  NAME: 'Patients',
  VALUE: '/admin/patients',
  ICON: {
    ACTIVE: patientActiveIcon,
    DISABLED: patientIcon,
  },
};

export const ADMIN_PHARMACY_TECHNICIANS = {
  NAME: 'Pharmacy Technicians',
  VALUE: '/admin/pharmacy-technicians',
  ICON: {
    ACTIVE: technicianActiveIcon,
    DISABLED: technicianIcon,
  },
};

export const ADMIN_PHYSICIANS = {
  NAME: 'Physicians',
  VALUE: '/admin/physicians',
  ICON: {
    ACTIVE: physicianActiveIcon,
    DISABLED: physicianIcon,
  },
};

export const ADMIN_USERS = {
  NAME: 'Users',
  VALUE: '/admin/users',
  ICON: {
    ACTIVE: patientActiveIcon,
    DISABLED: patientIcon,
  },
};

export const ADMIN_PHARMACIES = {
  NAME: 'Pharmacies',
  VALUE: '/admin/pharmacies',
  ICON: {
    ACTIVE: pharmacyActiveIcon,
    DISABLED: pharmacyIcon,
  },
};
