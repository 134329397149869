// Constants
import * as ROUTE from 'constants/routes';

// Stores
import stores from 'bootstrap/store';
import { notificationsPaneRouteStore } from '../../../../../domain/store';
const prescriptionFillsStore = stores.global.prescriptionFills;
const history = stores.router;


export default (prescriptionFillId) => {
  prescriptionFillsStore.setValue('mailOrdered', {});
  prescriptionFillsStore.listMailOrderedForPharmacist()
  .then(() => history.push(`${ROUTE.PRESCRIPTION_FILLS.VALUE}${ROUTE.PRESCRIPTION_FILLS.SUB_ROUTE.MAIL_ORDERED.VALUE}/${prescriptionFillId}`));

  notificationsPaneRouteStore.close();
};
