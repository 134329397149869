import React from 'react';
import { observer } from 'mobx-react';

import { SLIDING_ANIMATION } from './constants/animations';

import './notifications-view-style.scss';
import './notifications-view-animations.scss';

const NotificationView = ({ buildContent, isOpen }) => (
  <div className={`c-Notifications ${isOpen ? SLIDING_ANIMATION.IN.CLASS : SLIDING_ANIMATION.OUT.CLASS}`}>
    {
      buildContent()
    }
  </div>
);

export default observer(NotificationView);
