export const SLIDING_ANIMATION = {
  IN: {
    CLASS: 'notifications-slide-in-animation',
    DURATION: 400,
  },
  OUT: {
    CLASS: 'notifications-slide-out-animation',
    DURATION: 400,
  },
};
