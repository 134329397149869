import React from 'react';
import { observer } from 'mobx-react';

// Components
import Header from './components/header';
import List from 'components/drill-down-layout/components/list';
import ListItemGroup from 'components/drill-down-layout/components/list/components/list-item-group';
import ListItem from './components/list-item';


const DispenseAttemptsList = ({
  dispenseAttemptsRouteStore,
  groupedByDate,
  setFilterValue,
  setActiveDispenseAttemptTab,
  onListBottomThreshold,
  rootRoute,
}) => (
  <List
    header={<Header
      dispenseAttemptsRouteStore={dispenseAttemptsRouteStore}
      setFilterValue={setFilterValue}
      setActiveDispenseAttemptTab={setActiveDispenseAttemptTab}
    />}
    isReady={!dispenseAttemptsRouteStore.http.listingForPharmacist}
    onBottomThreshold={onListBottomThreshold}
  >
    {
      groupedByDate.map((group) => (
        <ListItemGroup key={group.id} title={group.title}>
          {
            group.dispenseAttempts.map((dispenseAttempt) => (
              <ListItem
                key={dispenseAttempt.id}
                dispenseAttemptsRouteStore={dispenseAttemptsRouteStore}
                dispenseAttempt={dispenseAttempt}
                rootRoute={rootRoute}
              />
            ))
          }
        </ListItemGroup>
      ))
    }
  </List>
);

export default observer(DispenseAttemptsList);
