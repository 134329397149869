import React from 'react';
import { observer } from 'mobx-react';

import ContentScreen from 'components/content-screen';
import AwayModeModal from './components/away-mode-modal';
import DropDownMenu from './components/drop-down-menu';

import { STATUS } from 'models/pharmacists/constants';

// Style
import './header-view.scss';


const HeaderView = ({
  isActiveUserPharmacist,
  isSettingStatus,
  setPharmacistStatus,
  pharmacistStatus,
  logOut,
  openCameraConfiguration
}) => (
  <div className='c-HeaderView'>
    <ContentScreen active={isSettingStatus} />
    <AwayModeModal
      isPharmacistAway={pharmacistStatus === STATUS.AWAY}
      isSettingStatus={isSettingStatus}
      setAvailableStatus={() => setPharmacistStatus(STATUS.AVAILABLE)}
    />
    <div className='left-section'>
      <div className='medifriendrx-logo'>
        medifriend<span>Rx</span>
      </div>
    </div>
    <div className='mid-section'>
    </div>
    <div className='right-section'>
      {
        isActiveUserPharmacist &&
        <button className='enter-away-mode-btn' onClick={() => setPharmacistStatus(STATUS.AWAY)}>
          Enter &quot;Away Mode&quot;
        </button>
      }
      <DropDownMenu
        items={[
          // eslint-disable-next-line react/jsx-key
          <div onClick={openCameraConfiguration}>Camera configuration</div>,
          // eslint-disable-next-line react/jsx-key
          <div onClick={logOut}>Log Out</div>,
        ]}
      />
    </div>
  </div>
);


export default observer(HeaderView);
