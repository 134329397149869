import { useRef, useCallback, useEffect } from 'react';

export default function useDebounce(
  fn,
  {
    wait = 250,
    maxWait = Infinity,
  },
  changeListeners = [],
) {
  const timeoutId = useRef(null);
  const invokeBy = useRef(null);


  // Establish cleanup in order to prevent debounce from firing after a component is exited.
  useEffect(() => {
    return () => {
      timeoutId.current && clearTimeout(timeoutId.current);
    };
  }, []);

  // This is the function returned to the user of the hook in order to invoke the debounce.
  const debounced = useCallback(
    (value) => {
      timeoutId.current && clearTimeout(timeoutId.current);

      // If this isn't specified, this means this is a fresh invocation
      if (!invokeBy.current) invokeBy.current = Date.now() + maxWait;

      // If the current time is closer to the invokeBy time, disregard options.wait and instead go with this value.
      const remainingTimeout = Math.max(Math.min(wait, invokeBy.current - Date.now()), 0);

      // If remaining timeout is infinite, do not establish timeout at all. setTimeout(..., Infinity) actually invokes immediately
      // which is undesirable behavior.
      if (remainingTimeout === Infinity) return;

      timeoutId.current = setTimeout(() => {
        timeoutId.current = null;
        invokeBy.current = null;

        fn(value);
      }, remainingTimeout);
    },
    [fn, wait, maxWait, timeoutId.current, ...changeListeners]
  );

  return debounced;
}
