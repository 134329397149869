import {
  get as _get,
  startCase as _startCase,
} from 'lodash';

// Constants
import { ALLOWED_PURPOSES } from 'models/video-conferences/constants';

// Helpers
import generateCallerName from './generateCallerName';


export default (videoConference = {}) => {
  const purpose = _get(videoConference, 'purpose.name');
  if (!ALLOWED_PURPOSES.includes(purpose)) return;

  const kiosk = _get(videoConference, 'payload.kiosk.name', 'N/A');

  const prescriptionFills = _get(videoConference, 'payload.prescriptionFillsFulfillment.prescriptionFills', []);
  const requestedConsultationsCount = prescriptionFills.filter((fill) => fill.consultReq).length;

  return {
    caller: generateCallerName(videoConference) || 'KIOSK',
    purpose: _startCase(purpose),
    source: kiosk,
    requestedConsultationsCount,
  };
};
