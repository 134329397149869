import { flow, types } from 'mobx-state-tree';
import {
  keyBy as _keyBy,
  isEqual as _isEqual,
} from 'lodash';
import base from 'models/base';

import { MODEL_NAME, SCHEMA } from './constants/schema';


const UserModel = base({
  names: {
    singular: MODEL_NAME.SINGULAR,
    plural: MODEL_NAME.PLURAL
  },
  JSONSchema: SCHEMA,
  httpConfig: {
    root: MODEL_NAME.plural,
    methods: {
      resetPin: {
        method: 'POST',
        urlFragment: () => '/send-reset-pin-email'
      },
    }
  }
});

UserModel.configureStore((store) => {
  return store
  .props({
    dataForPharmacist: types.optional(types.map(types.frozen()), {}),
    entriesCount: types.optional(types.number, 0)
  })
  .actions(self => ({
    setValue: (prop, value) => self[prop] = value,
    listKeycards: flow(function* (config = {}) {
      const response = yield self.list(config);
      const data = response.data || response || [];

      // If data contains the same elements as dataForPharmacist but in reverse order,
      // mobx-state-tree's map treats it as the same set of elements, so views won't rerender.
      const sameData = _isEqual(self.dataAsArray.map(i => i.id).sort(), data.map(i => i.id).sort());
      if (sameData) self.dataForPharmacist = {};
      self.dataForPharmacist = _keyBy(data, 'id');

      return response;
    }),
    countKeycards: flow(function* (config = {}) {
      config.urlFragment = () => '/count';

      const response = yield self.list(config);
      self.entriesCount = response || 0;

      return response;
    }),
    getUniqueValue: () => {
      return self.list({
        urlFragment: () => '/value',
        storeResult: false,
      });
    },
    createKeycard: (config = {}) => {
      return self.post(config);
    },
    updateKeycard: (config = {}) => {
      return self.put(config);
    },
    resetKeycardPin: (config = {}) => {
      config.urlFragment = (params) => `/${params.keycardId}/request-reset-pin`;

      return self.put(config);
    }
  }))
  .views(self => ({
    get dataAsArray() {
      return Array.from(self.dataForPharmacist.values());
    },
  }));
});


export default UserModel;
