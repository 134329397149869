import React from 'react';

export const ORDER_OPTIONS = {
  name: order => [['firstName', order], ['lastName', order ]],
  externalPharmacyId: order => [['externalPharmacyId', order]]
};

export const PATIENT_MANIPULATION_OPTIONS = {
  CREATE: 'create',
  UPDATE: 'update'
};

export const PATIENT_SKELETON = {
  firstName: '',
  lastName: '',
  externalPharmacyId: '',
  allergies: [],
  healthcareSites: [],
};

export const COLUMNS = [
  {
    name: 'Name',
    fieldName: 'name',
    isSortable: true,
    width: '30%',
    render: ({ content }) => (
      <div title={content.id}>{content.name}</div>
    )
  },
  {
    name: 'External Pharmacy ID',
    fieldName: 'externalPharmacyId',
    isSortable: true,
    width: '15%',
    render: ({ content }) => (
      <div title={content.id}>{content.externalPharmacyId}</div>
    )
  },
  {
    name: 'Phone Number',
    fieldName: 'phoneNumber',
    width: '15%',
    render: ({ content }) => (
      <div title={content.id}>{content.phoneNumber}</div>
    )
  },
  {
    name: 'Allergies',
    width: '40%',
    render: ({ content }) => (
      <div title={content.id} className='allergies'>
        {
          content.allergies && content.allergies.length
            ? <React.Fragment>
              {
                content.allergies.slice(0, 3).map((allergy, index) => (
                  <div className='allergy' key={index}>
                    {
                      allergy.name
                    }
                  </div>
                ))
              }
              {
                content.allergies.length > 2 && content.allergies.slice(3).length > 0 &&
              <div className='remaining-allergies-count'>
                {
                  `+${content.allergies.slice(3).length} more`
                }
              </div>
              }
            </React.Fragment>
            : 'None'
        }
      </div>
    )
  }
];
