import React from 'react';
import { observer } from 'mobx-react';

// Components
import Header from './components/header';
import ListItemTitle from './components/list-item-title';
import List from 'components/drill-down-layout/components/list';
import ListItemGroup from 'components/drill-down-layout/components/list/components/list-item-group';
import ListItem from 'components/drill-down-layout/components/list/components/list-item';
import HealthcareSite from './components/healthcareSite';

// Icons
import ActiveHealthcareSiteIcon from 'assets/icons/healthcare-site-active.svg';
import HealthcareSiteIcon from 'assets/icons/healthcare-site.svg';

const HealthcareSitesList = ({
  healthcareSitesRouteStore,
  groupedHealthcareSites,
  setFilterValue,
  onListBottomThreshold,
  showHealthcareSiteUpsertModal,
  rootRoute,
}) => (
  <List
    header={<Header
      filter={healthcareSitesRouteStore.filterValue}
      setFilter={(e) => setFilterValue(e)}
      showHealthcareSiteUpsertModal={showHealthcareSiteUpsertModal}
    />}
    isReady={!healthcareSitesRouteStore.http.listingHealthcareSites}
    onBottomThreshold={onListBottomThreshold}
  >
    {
      groupedHealthcareSites.map((group) => (
        <ListItemGroup key={group.id}
          title={group.title}>
          {
            group.healthcareSites.map((healthcareSite) => {
              const isActive = healthcareSitesRouteStore.activeHealthcareSiteId === healthcareSite.id;
              return <ListItem
                key={healthcareSite.id}
                title={<ListItemTitle healthcareSite={healthcareSite}/>}
                isActive={isActive}
                icon={
                  <img
                    alt='HEALTHCARE_SITE'
                    src={isActive ? ActiveHealthcareSiteIcon : HealthcareSiteIcon}
                  />
                }
                url={`${rootRoute}/${healthcareSite.id}`} >
                <HealthcareSite
                  healthcareSite={healthcareSite}
                />
              </ListItem>;
            })
          }
        </ListItemGroup>
      ))
    }
  </List>
);

export default observer(HealthcareSitesList);
