import React from 'react';
import { observer } from 'mobx-react';

import './pending-patient-style.scss';


const PendingPatientNotification = ({ patient, onClick }) => (
  <div key={patient.id} className='c-PendingPatientNotification' onClick={() => onClick(patient.id)}>
    <div className='patient-name'>
      {`${patient.firstName} ${patient.lastName}`}
    </div>
  </div>
);

export default observer(PendingPatientNotification);
