import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import TransferPrescriptionsNotificationsView from './transfer-prescriptions-notifications-view';

import { NOTIFICATION_TYPE } from '../../../../constants';

import { fetchNotificationsRelatedData } from '../../../../helpers';

import stores from 'bootstrap/store';
import { goToReview } from './helpers';

const prescriptionFillsStore = stores.global.prescriptionFills;


const TransferPrescriptionsNotificationsContainer = () => {
  useEffect(() => {
    fetchNotificationsRelatedData(NOTIFICATION_TYPE.TRANSFER_PRESCRIPTIONS);
  }, []);

  return <TransferPrescriptionsNotificationsView
    transfers={prescriptionFillsStore.transfersForNotificationsPaneArray}
    onClick={goToReview}
  />;
};

export default observer(TransferPrescriptionsNotificationsContainer);
