import stores from 'bootstrap/store';

import notifications from 'bootstrap/notifications';

import { notificationsPaneRouteStore } from '../../../../app/root/routes/app-frame/views/notifications-pane/domain/store';

import { EVENT } from '../../constants';
import { NOTIFICATION_TYPE } from '../../../../app/root/routes/app-frame/views/notifications-pane/constants';


export default ({ apiWebSocket }) => {
  const patientsStore = stores.global.patients;

  apiWebSocket.on(EVENT.RECEIVED.PATIENTS_CREATE, ({ firstName, lastName }) => {
    patientsStore.listPendingForNotificationsPane()
    .then(() => {
      notifications.create(`New Patient Added to Healthcare Site: ${firstName} ${lastName}.`, {
        onClickCallback: () => {
          if (notificationsPaneRouteStore.activeTab !== NOTIFICATION_TYPE.NEW_PATIENTS) {
            notificationsPaneRouteStore.setActiveTab(NOTIFICATION_TYPE.NEW_PATIENTS);
          }
        },
      });
    });
  });
};
