import React from 'react';
import { observer } from 'mobx-react';


const Slot = ({ moduleTemplatesRouteStore, slot, index }) => {
  return (
    <div
      className={index === moduleTemplatesRouteStore.modifiedSlotIndex ? 'active-module-info' : 'module-info'}
      onClick={() => moduleTemplatesRouteStore.setActiveModifiedSlotIndex({slotIndex: index})}
    >
      <div className='header'>
        <div className={index === moduleTemplatesRouteStore.modifiedSlotIndex ? 'active-number' : 'number'}>{index+1}</div>
        <div
          className='delete-btn'
          onClick={() => {
            return moduleTemplatesRouteStore.deleteSlot({slot});
          }}
        >
          x
        </div>
      </div>
      <input
        className='name-input'
        value={slot.name}
        onChange={evt => moduleTemplatesRouteStore.setModifiedSlotName({slot, name: evt.target.value})}
      />
      <div className='horizontal-line'/>
      <div className='position'>
        {
          slot.slot_template.containerConstraintRadiusMax ?
            <React.Fragment>
              <div className='coordinate'>
                <input
                  className='value-input'
                  type='number'
                  min='0'
                  value={parseInt(slot.slot_template.containerConstraintRadiusMax, 10) || 0}
                  onChange={event => {
                    event.target.value = Number(event.target.value.replace(/^0+/, ''));
                    moduleTemplatesRouteStore.setModifiedSlotRadius({
                      slot,
                      value: Number(event.target.value)
                    });
                  }}
                />
                <div className='label'>Radius</div>
              </div>
            </React.Fragment>
            : <React.Fragment>
              <div className='coordinate'>
                <input
                  className='value-input'
                  type='number'
                  min='0'
                  value={parseInt(slot.slot_template.containerConstraintWidthMax, 10) || 0}
                  onChange={event => {
                    event.target.value = Number(event.target.value.replace(/^0+/, ''));
                    moduleTemplatesRouteStore.setModifiedSlotWidth({
                      slot,
                      value: Number(event.target.value)
                    });
                  }}
                />
                <div className='label'>width</div>
              </div>
              <div className='coordinate'>
                <input
                  className='value-input'
                  type='number'
                  min='0'
                  value={parseInt(slot.slot_template.containerConstraintHeightMax, 10) || 0}
                  onChange={event => {
                    event.target.value = Number(event.target.value.replace(/^0+/, ''));
                    moduleTemplatesRouteStore.setModifiedSlotHeight({
                      slot,
                      value: Number(event.target.value)
                    });
                  }}
                />
                <div className='label'>height</div>
              </div>
            </React.Fragment>
        }
        <div className='coordinate'>
          <input
            className='value-input'
            type='number'
            min='0'
            value={parseInt(slot.x, 10) || 0}
            onChange={event => {
              event.target.value = Number(event.target.value.replace(/^0+/, ''));
              moduleTemplatesRouteStore.setModifiedSlotPosition({
                slot,
                coordinate: 'x',
                value: Number(event.target.value)
              });
            }}
          />
          <div className='label'>X Value</div>
        </div>
        <div className='coordinate'>
          <input
            className='value-input'
            type='number'
            min='0'
            value={parseInt(slot.y, 10) || 0}
            onChange={event => {
              event.target.value = Number(event.target.value.replace(/^0+/, ''));
              moduleTemplatesRouteStore.setModifiedSlotPosition({
                slot,
                coordinate: 'y',
                value: Number(event.target.value)
              });
            }}
          />
          <div className='label'>Y Value</div>
        </div>
      </div>
    </div>
  );
};


export default observer(Slot);