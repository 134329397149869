import React, { useCallback, useEffect } from 'react';

// Views
import SignUpView from './signup-view';

// Store
import stores from 'bootstrap/store';
import { signUpRouteStore } from './domain/store';
const usersStore = stores.global.users;
const { router } = stores;


const SignUpContainer = () => {
  useEffect(() => {
    return () => {
      signUpRouteStore.setInitialState();
    };
  }, []);

  const signUp = useCallback((e) => {
    e.preventDefault();

    return usersStore.signUp({
      body: {
        email: signUpRouteStore.email,
        password: signUpRouteStore.password,
        firstName: signUpRouteStore.firstName,
        lastName: signUpRouteStore.lastName,
      }
    })
    .then(() => router.history.push('/'))
    .catch(() => signUpRouteStore.setValue('errorMessage', 'Could not sign up'));
  }, []);

  return <SignUpView
    signUpRouteStore={signUpRouteStore}
    signUp={signUp}
  />;
};

export default SignUpContainer;