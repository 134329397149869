import React from 'react';
import { observer } from 'mobx-react';
import { get as _get } from 'lodash';

import formatPhoneNumber from 'utils/formatPhoneNumber';

import './call-request-style.scss';


const CallRequestNotification = ({ callRequest }) => {
  const patient = _get(callRequest, 'patientCallRequest.patient');
  const callRequestedBy = patient
    ? `${patient.firstName} ${patient.lastName}`
    : 'N/A';

  return <div key={callRequest.id} className='c-CallRequestNotification'>
    <div className='patient-name'>
      {
        callRequestedBy
      }
    </div>
    <div className='phone-number'>
      {
        formatPhoneNumber(callRequest.phoneNumber)
      }
    </div>
  </div>;
};

export default observer(CallRequestNotification);
