import { types } from 'mobx-state-tree';
import { set as _set } from 'lodash';

import stores from 'bootstrap/store';

export const KeycardsRouteStore = types.model({
})
.props({
  filterValue: types.optional(types.string, ''),
  activeKeycardId: types.maybeNull(types.string),
  sortingColumn: types.optional(types.model({
    fieldName: types.optional(types.string, 'value'),
    sortingOrder: types.optional(types.string, 'asc')
  }), {}),
  http: types.optional(types.model({
    listingForKeycard: types.optional(types.number, 0),
    countingForKeycard: types.optional(types.number, 0),
  }), {}),
  currentPage: types.optional(types.number, 1),
  showingKeycardUpsertModal: types.optional(types.boolean, false),
  emailSent: types.optional(types.boolean, false),
})
.actions(self => ({
  setValue: (path, value) => _set(self, path, value),
  incrementHttp: (http) => ++self.http[http],
  decrementHttp: (http) => --self.http[http],
  setInitialState: () => {
    self.filterValue = '';
    self.activeKeycardId = null;
    self.sortingColumn = {};
    self.http = {};
    self.currentPage = 1;
    self.showingKeycardUpsertModal = false;
    self.emailSent = false;
  },
}))
.views(self => ({
  get loading() {
    return !!(self.http.listingForKeycard && self.http.countingForKeycard);
  },
  get activeKeycard() {
    const keycardsStore = stores.global.keycards;
    return self.activeKeycardId && keycardsStore.dataForPharmacist.get(self.activeKeycardId);
  },
  get forTable() {
    const keycardsStore = stores.global.keycards;
    return keycardsStore.dataAsArray.map((keycard) => ({
      id: keycard.id,
      value: keycard.value,
      userName: `${keycard.user.firstName} ${keycard.user.lastName}`,
      disabled: keycard.disabled,
    }));
  },
}));

export const keycardsRouteStore = KeycardsRouteStore.create({});
