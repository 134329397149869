import {
  closeMachineTechnicianUpsertModal,
  listMachineTechnicians,
} from './index';

import stores from 'bootstrap/store';
import { machineTechnicianUpsertModalRouteStore } from '../domain/upsert-modal-store';
const machineTechniciansStore = stores.global.machineTechnicians;

export default () => {
  const { activelyModifiedTechnician } = machineTechnicianUpsertModalRouteStore;
  return machineTechniciansStore.createMachineTechnician({
    body: activelyModifiedTechnician,
  })
  .then((machineTechnician = {}) => {
    const machineTechnicianId = machineTechnician.id;
    const { kiosks = [] } = activelyModifiedTechnician;
    const addKioskPromises = kiosks.map((kiosk) => machineTechniciansStore.addMachine({
      params: {
        machineTechnicianId,
        kioskId: kiosk.id,
      },
    }));

    return Promise.all(addKioskPromises);
  })
  .then(() => {
    closeMachineTechnicianUpsertModal();
    return listMachineTechnicians();
  });
};
