import {
  get as _get,
  difference as _difference,
} from 'lodash';

import {
  closePharmacyTechnicianUpsertModal,
  listPharmacyTechnicians,
} from './index';

import stores from 'bootstrap/store';
import { pharmacyTechniciansRouteStore } from '../domain/store';
import { pharmacyTechnicianUpsertModalRouteStore } from '../domain/upsert-modal-store';
const pharmacyTechniciansStore = stores.global.pharmacyTechnicians;
const usersStore = stores.global.users;


export default () => {
  const { activelyModifiedTechnician } = pharmacyTechnicianUpsertModalRouteStore;
  const user = _get(activelyModifiedTechnician, 'ptUser.user', {});

  return Promise.all([
    pharmacyTechniciansStore.updatePharmacyTechnician({
      params: { pharmacyTechnicianId: pharmacyTechniciansRouteStore.activeTechnicianId },
      body: activelyModifiedTechnician,
    }),
    user.id && usersStore.updateUser({
      params: { id: user.id },
      body: user,
    }),
  ])
  .then(() => {
    const pharmacyTechnicianId = activelyModifiedTechnician.id;

    const { activeTechnician } = pharmacyTechniciansRouteStore;
    const initialPharmacyIds = activeTechnician.pharmacies.map((pharmacy) => pharmacy.id);
    const modifiedPharmacyIds = activelyModifiedTechnician.pharmacies.map((pharmacy) => pharmacy.id);
    const addedIds = _difference(modifiedPharmacyIds, initialPharmacyIds);
    const removedIds = _difference(initialPharmacyIds, modifiedPharmacyIds);

    const promises = [];
    addedIds.forEach((pharmacyId) => promises.push(pharmacyTechniciansStore.addPharmacy({
      params: {
        pharmacyTechnicianId,
        pharmacyId,
      },
    })));
    removedIds.forEach((pharmacyId) => promises.push(pharmacyTechniciansStore.deletePharmacy({
      params: {
        pharmacyTechnicianId,
        pharmacyId,
      },
    })));

    return Promise.all(promises);
  })
  .then(() => {
    closePharmacyTechnicianUpsertModal();
    return listPharmacyTechnicians();
  });
};
