import React from 'react';
import Modal from 'react-modal';
import { observer } from 'mobx-react';

import ContentScreen from 'components/content-screen';

import './away-mode-modal-styles.scss';

const AwayModeModal = ({
  isPharmacistAway,
  isSettingStatus,
  setAvailableStatus,
}) => (
  <Modal
    overlayClassName='ModalOverlay'
    className='c-AwayModeModal'
    isOpen={isPharmacistAway}
    shouldCloseOnOverlayClick={false}
    style={{ overlay: { zIndex: 1101 } }}
  >
    <ContentScreen active={isSettingStatus} />
    <div className='content'>
      <div className='title-container'>
        <div className='title'>
          &quot;Away Mode&quot;
        </div>
        <div className='subtitle'>
          medifriend
          <span className='bold'>Rx</span>
        </div>
      </div>
      <div className='exit-away-mode' onClick={() => setAvailableStatus()}>
        Click Here To Exit
      </div>
    </div>
  </Modal>
);

export default observer(AwayModeModal);
