import {
  closeMedicalAssistantUpsertModal,
  listMedicalAssistants,
} from './index';

import stores from 'bootstrap/store';
import { medicalAssistantsRouteStore } from '../domain/store';
const medicalAssistantsStore = stores.global.medicalAssistants;

export default () => {
  return medicalAssistantsStore.deleteMedicalAssistant({
    params: {
      medicalAssistantId: medicalAssistantsRouteStore.activeMedicalAssistantId,
    },
  })
  .then(() => {
    closeMedicalAssistantUpsertModal();
    return listMedicalAssistants();
  });
};
