import React from 'react';
import { observer } from 'mobx-react';

import './healthcare-site-style.scss';

const HealthcareSites = ({ healthcareSite }) => (
  <div className='c-HealthcareSiteInfo'>
    <div className='healthcareSite-data'>
      <div className='pharmacy-name'>{healthcareSite.name}</div>
      <div className='location'>{`${healthcareSite.location.street1}, ${healthcareSite.location.city}, ${healthcareSite.location.state}`}</div>
    </div>
  </div>
);

export default observer(HealthcareSites);
