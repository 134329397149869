import React from 'react';

export const MEDICAL_ASSISTANT_SKELETON = {
  firstName: '',
  lastName: '',
  healthcareSites: []
};

export const ORDER_OPTIONS = {
  name: order => [['firstName', order], ['lastName', order ]],
};

export const MEDICAL_ASSISTANT_MANIPULATION_OPTIONS = {
  CREATE: 'create',
  UPDATE: 'update'
};

export const MAX_TABLE_ENTRIES_PER_PAGE = 10;


const DISPLAYED_HEALTHCARE_SITES_MAX_COUNT = 3;
export const COLUMNS = [
  {
    name: 'Name',
    fieldName: 'name',
    isSortable: true,
    width: '30%',
    render: ({ content }) => (
      <div title={content.id}>{content.name}</div>
    )
  },
  {
    name: 'Phone Number',
    fieldName: 'phoneNumber',
    width: '10%',
    render: ({ content }) => (
      <div title={content.id}>{content.phoneNumber}</div>
    )
  },
  {
    name: 'Email',
    fieldName: 'email',
    width: '20%',
    render: ({ content }) => (
      <div title={content.id}>{content.email}</div>
    )
  },
  {
    name: 'Healthcare Sites',
    width: '40%',
    render: ({ content }) => {
      const { healthcareSites = [] } = content;
      const displayedHealthcareSites = healthcareSites.slice(0, DISPLAYED_HEALTHCARE_SITES_MAX_COUNT);
      const remainingHealthcareSitesCount = healthcareSites.length > DISPLAYED_HEALTHCARE_SITES_MAX_COUNT
        ? healthcareSites.slice(DISPLAYED_HEALTHCARE_SITES_MAX_COUNT).length
        : 0;

      return <div title={content.id} className='MedicalAssistantSmartTableWorkPlaces'>
        {
          healthcareSites.length
            ? <React.Fragment>
              {
                displayedHealthcareSites.map((healthcareSite, index) => (
                  <div className='work-place' key={index}>
                    {
                      healthcareSite.name
                    }
                  </div>
                ))
              }
              {
                !!remainingHealthcareSitesCount &&
                <div className='remaining-work-places-count'>
                  {
                    `+${remainingHealthcareSitesCount} more`
                  }
                </div>
              }
            </React.Fragment>
            : 'None'
        }
      </div>;
    }
  }
];
