import React from 'react';
import { observer } from 'mobx-react';

import CaptionValueInput from 'components/caption-value-input';
import ContentScreen from 'components/content-screen';

import { SUGGESTION_COMPONENT } from './constants';

import SearchIcon from 'assets/icons/search.svg';

import './autocomplete-caption-value-input-styles.scss';

const AutocompleteCaptionValueInput = ({
  type,
  caption,
  validationRegex,
  validationError,
  placeholder,
  value,
  onInputChange,
  onBlur,
  required,
  isListingSuggestions,
  suggestions,
  onSuggestionClick,
  clearSuggestions,
}) => {
  return <div className='c-AutocompleteCaptionValueInput'>
    <ContentScreen active={isListingSuggestions} />
    <div className='autocomplete-caption-value-input-container'>
      <div className='input-container'>
        <CaptionValueInput
          inputFieldClassNames={['max-width']}
          caption={caption}
          validationRegex={validationRegex}
          validationError={validationError}
          placeholder={placeholder}
          value={value}
          onChange={(value) => onInputChange(value)}
          required={required}
          onBlur={() => {
            clearSuggestions();
            onBlur && onBlur();
          }}
        />
        <img
          className='icon'
          alt='Searchable Field'
          title='Searchable field. Enter at least 3 characters to list suggestions.'
          src={SearchIcon}
        />
      </div>
      {
        suggestions && !!suggestions.length &&
        <div className='suggestions'>
          {
            suggestions.map((suggestion) => {
              const Suggestion = SUGGESTION_COMPONENT[type];
              return <div
                className='suggestion'
                key={suggestion.id}
                onMouseDown={(e) => e.preventDefault()} // prevent onBlur event firing before onClick
                onClick={() => onSuggestionClick(suggestion)}
              >
                <Suggestion
                  suggestion={suggestion}
                />
              </div>;
            })
          }
        </div>
      }
    </div>
  </div>;
};

export default observer(AutocompleteCaptionValueInput);
