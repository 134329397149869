import { types } from 'mobx-state-tree';

export const HealthcareSiteInventoryRouteStore = types.model({
})
.actions(self => ({
  setValue: (prop, value) => self[prop] = value,
}))
.views(() => ({
}));



export const healthcareSiteInventoryRouteStore = HealthcareSiteInventoryRouteStore.create({});
