import deepFreeze from 'deep-freeze';

export const MODEL_NAME = Object.freeze({
  SINGULAR: 'moduleTemplate',
  PLURAL: 'module-templates'
});

export const FIELD_NAME = Object.freeze({
  ID: 'id',
  NAME: 'name',
  WIDTH: 'width',
  HEIGHT: 'height',
  MODULE_SLOT_TEMPLATES: 'moduleSlotTemplates',
  RADIUS: 'radius',
  POSITION: 'position',
  X: 'x',
  Y: 'y',
  SLOT_TEMPLATE: 'slot_template',
  DESCRIPTION: 'description',
  CONTAINER_CONSTRAINT_HEIGHT_MAX: 'containerConstraintHeightMax',
  CONTAINER_CONSTRAINT_HEIGHT_MIN: 'containerConstraintHeightMin',
  CONTAINER_CONSTRAINT_LENGTH_MAX: 'containerConstraintLengthMax',
  CONTAINER_CONSTRAINT_LENGTH_MIN: 'containerConstraintLengthMin',
  CONTAINER_CONSTRAINT_RADIUS_MAX: 'containerConstraintRadiusMax',
  CONTAINER_CONSTRAINT_RADIUS_MIN: 'containerConstraintRadiusMin',
  CONTAINER_CONSTRAINT_WIDTH_MAX: 'containerConstraintWidthMax',
  CONTAINER_CONSTRAINT_WIDTH_MIN: 'containerConstraintWidthMin',
});

export const SCHEMA = deepFreeze({
  title: MODEL_NAME.SINGULAR,
  type: 'object',
  properties: {
    [FIELD_NAME.ID]: {
      type: 'string'
    },
    [FIELD_NAME.WIDTH]: {
      type: 'string'
    },
    [FIELD_NAME.HEIGHT]: {
      type: 'string'
    },
    [FIELD_NAME.MODULE_SLOT_TEMPLATES]: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          [FIELD_NAME.ID]: {
            type: 'string'
          },
          [FIELD_NAME.NAME]: {
            type: 'string'
          },
          [FIELD_NAME.X]: {
            type: 'string'
          },
          [FIELD_NAME.Y]: {
            type: 'string'
          },
          [FIELD_NAME.SLOT_TEMPLATE]: {
            type: 'object',
            properties: {
              [FIELD_NAME.ID]: {
                type: 'string'
              },
              [FIELD_NAME.DESCRIPTION]: {
                type: 'string'
              },
              [FIELD_NAME.CONTAINER_CONSTRAINT_HEIGHT_MAX]: {
                type: 'string'
              },
              [FIELD_NAME.CONTAINER_CONSTRAINT_HEIGHT_MIN]: {
                type: 'string'
              },
              [FIELD_NAME.CONTAINER_CONSTRAINT_LENGTH_MAX]: {
                type: 'string'
              },
              [FIELD_NAME.CONTAINER_CONSTRAINT_LENGTH_MIN]: {
                type: 'string'
              },
              [FIELD_NAME.CONTAINER_CONSTRAINT_RADIUS_MAX]: {
                type: 'string'
              },
              [FIELD_NAME.CONTAINER_CONSTRAINT_RADIUS_MIN]: {
                type: 'string'
              },
              [FIELD_NAME.CONTAINER_CONSTRAINT_WIDTH_MAX]: {
                type: 'string'
              },
              [FIELD_NAME.CONTAINER_CONSTRAINT_WIDTH_MIN]: {
                type: 'string'
              }
            }
          }
        }
      }
    }
  },
  required: [
    FIELD_NAME.ID,
    FIELD_NAME.WIDTH,
    FIELD_NAME.HEIGHT,
  ]
});
