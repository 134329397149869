import stores from 'bootstrap/store';
import { dispenseAttemptsRouteStore } from '../domain/store';
const dispenseAttemptsStore = stores.global.dispenseAttempts;

export default () => {
  const { activeDispenseAttempt } = dispenseAttemptsRouteStore;
  if (!activeDispenseAttempt) return;

  return dispenseAttemptsStore.approve({
    params: { dispenseAttemptId: activeDispenseAttempt.id },
  });
};
