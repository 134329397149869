import React from 'react';
import VerificationCodeInput from 'react-input-verification-code';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import ContentScreen from 'components/content-screen';

import MedifriendRxIcon from 'assets/icons/medifriend-rx.svg';
import KioskImage from 'assets/images/kiosk.svg';

import './login-view.scss';


const LoginView = ({
  loginRouteStore,
  logIn,
  verifyLoginCode,
  resetLoginCodeVerification,
}) => {
  const isVerificationMode = Boolean(loginRouteStore.authMultifactorCodeToken);

  return <div className='c-LoginView'>
    <div className='primary-panel'>
      <div className='sub-panel left-panel'>
        <img
          className='logo-icon'
          src={MedifriendRxIcon}
        />
        <img
          className='kiosk-image'
          src={KioskImage}
        />
      </div>
      <form className='sub-panel right-panel' onSubmit={logIn}>
        <ContentScreen active={loginRouteStore.isBusy} />
        {
          isVerificationMode &&
          <div className='back-to-sign-in' onClick={() => resetLoginCodeVerification()}>
            Back to sign in
          </div>
        }
        <div className='greeting-text'>
          <h3>
            {
              isVerificationMode
                ? 'Enter Verification Code Below'
                : 'Sign In'
            }
          </h3>
        </div>
        {
          isVerificationMode
            ? <>
              <div className='verification-code-input-container'>
                <VerificationCodeInput
                  length={6}
                  autoFocus={true}
                  onChange={() => loginRouteStore.setValue('errorMessage', '')}
                  onCompleted={(code) => {
                    if (Number(code)) {
                      return verifyLoginCode(code);
                    }
                  }}
                />
              </div>
              <div className='panel-footer'>
                {
                  !!loginRouteStore.errorMessage &&
                <div className='error-message'>
                  {
                    loginRouteStore.errorMessage
                  }
                </div>
                }
              </div>
            </>
            : <>
              <div className='input-fields'>
                <div className='input-field'>
                  <div className='input-label'>
                    Email
                  </div>
                  <div className='input-email'>
                    <input
                      autoComplete='off'
                      value={loginRouteStore.email}
                      onChange={e => loginRouteStore.setValue('email', e.target.value)}
                    />
                  </div>
                </div>
                <div className='input-field'>
                  <div className='input-label'>
                    Password
                  </div>
                  <div className='input-email'>
                    <input
                      type='password'
                      value={loginRouteStore.password}
                      onChange={e => loginRouteStore.setValue('password', e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className='panel-footer'>
                <input
                  className='login-button'
                  type='submit'
                  value='Log In'
                />
                {
                  !!loginRouteStore.errorMessage &&
                <div className='error-message'>
                  {
                    loginRouteStore.errorMessage
                  }
                </div>
                }
                <div className='sign-up-button'>
                  <Link to='/signup'>
                    Sign Up
                  </Link>
                </div>
                <div className='forgot-password-button'>
                  <Link to='/reset-password'>
                    Forgot email or password?
                  </Link>
                </div>
              </div>
            </>
        }
      </form>
    </div>
  </div>;
};

export default observer(LoginView);
