import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import useForceUpdate from 'hooks/useForceUpdate';
import useIsMounted from 'hooks/useIsMounted';
import usePrevious from 'hooks/usePrevious';

import LoaderGif from './loader.gif';

const ContentGate = ({ active = false, children, className, color, fadeDuration, ...props }) => {
  const isMounted = useIsMounted();
  const forceUpdate = useForceUpdate();
  const fadingOutTimeoutRef = useRef(null);
  const previousActive = usePrevious(active);

  const handleFade = useCallback(() => {
    clearTimeout(fadingOutTimeoutRef?.current);
    fadingOutTimeoutRef.current = setTimeout(() => {
      fadingOutTimeoutRef.current = null;
      if (isMounted) {
        forceUpdate();
      }
    }, fadeDuration);
  }, [isMounted, fadeDuration]);

  useEffect(() => {
    if (active) {
      handleFade();
    }

    return () => {
      clearTimeout(fadingOutTimeoutRef?.current);
    };
  }, []);

  useEffect(() => {
    if (active !== previousActive) {
      handleFade();
    }
  }, [active]);

  const style = useMemo(() => {
    if (!fadeDuration) {
      return {};
    }

    const transition = fadeDuration ? `opacity ${fadeDuration}ms` : undefined;
    return {
      transition: transition,
      WebkitTransition: transition,
      msTransition: transition,
      backgroundColor: color || '#FFFFFF'
    };
  }, [color, fadeDuration]);

  return <div className={`c-content-gate ${className || ''}`} {...props}>
    {
      (active || fadingOutTimeoutRef.current) &&
      <div style={style} className={`c-spinner ${active ? 'active' : ''}`}>
        <img alt='Loading' style={{opacity: ~~active}} src={LoaderGif} />
      </div>
    }
    {
      !active &&
      (children || false)
    }
  </div>;
};

export default ContentGate;
