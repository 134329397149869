import React from 'react';
import { observer } from 'mobx-react';

import './preview-style.scss';

const NotificationPreview = ({
  type,
  isActiveTab,
  icon,
  activeIcon,
  notificationsCount = 0,
  displayNotificationsCount = true,
  onClick,
}) => (
  <div id={type} className='c-NotificationPreview' onClick={onClick}>
    <img src={isActiveTab ? activeIcon : icon} />
    {
      notificationsCount > 0 &&
      <>
        {
          displayNotificationsCount
            ? <div className='counter'>
              {
                notificationsCount
              }
            </div>
            : <div className='counter red-circle'>

            </div>
        }
      </>
    }
  </div>
);

export default observer(NotificationPreview);
