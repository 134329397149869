import { types } from 'mobx-state-tree';
import { get as _get, set as _set } from 'lodash';

import stores from 'bootstrap/store';

export const PharmaciesRouteStore = types.model({
})
.props({
  filterValue: types.optional(types.string, ''),
  activePharmacyId: types.maybeNull(types.string),
  sortingColumn: types.optional(types.model({
    fieldName: types.optional(types.string, 'name'),
    sortingOrder: types.optional(types.string, 'asc')
  }), {}),
  http: types.optional(types.model({
    listingForAdmin: types.optional(types.number, 0),
    countingForAdmin: types.optional(types.number, 0),
    pharmacyUpsert: types.optional(types.number, 0),
  }), {}),
  currentPage: types.optional(types.number, 1),
  showingPharmacyUpsertModal: types.optional(types.boolean, false)
})
.actions(self => ({
  setValue: (path, value) => _set(self, path, value),
  incrementHttp: (http) => ++self.http[http],
  decrementHttp: (http) => --self.http[http],
}))
.views(self => ({
  get loading() {
    return !!(self.http.listingForPharmacist && self.http.countingForAdmin);
  },
  get activePharmacy() {
    const pharmaciesStore = stores.global.pharmacies;
    return self.activePharmacyId && pharmaciesStore.data.get(self.activePharmacyId);
  },
  get forTable() {
    const pharmaciesStore = stores.global.pharmacies;
    return pharmaciesStore.forPharmacistAsArray.map((pharmacy) => {
      const location = _get(pharmacy, 'location', {});
      return {
        id: pharmacy.id,
        phoneNumber: pharmacy.phoneNumber,
        name: pharmacy.name,
        address: `${location.street1} ${location.city} ${location.state}`,
        drugStock: pharmacy.drugStock,
        healthcareSites: pharmacy.healthcareSites || [],
      };
    });
  },
}));

export const pharmaciesRouteStore = PharmaciesRouteStore.create({});
