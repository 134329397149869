import DrugSuggestion from './components/drug-suggestion';
import PatientSuggestion from './components/patient-suggestion';
import PhysicianSuggestion from './components/physician-suggestion';

export const TYPE = {
  DRUG: 'drug',
  PATIENT: 'patient',
  PHYSICIAN: 'physician',
};

export const SUGGESTION_COMPONENT = {
  [TYPE.DRUG]: DrugSuggestion,
  [TYPE.PATIENT]: PatientSuggestion,
  [TYPE.PHYSICIAN]: PhysicianSuggestion,
};
