import { get as _get } from 'lodash';

// Constants
import { DISPENSE_ATTEMPTS as DISPENSE_ATTEMPTS_ROUTE } from 'constants/routes';

// Stores
import stores from 'bootstrap/store';
import { dispenseAttemptsRouteStore } from '../domain/store';
const dispenseAttemptsStore = stores.global.dispenseAttempts;

export default (dispenseAttemptId, navigate) => {
  const requestedDispenseAttemptIsAvailable = !!dispenseAttemptsStore.dataForPharmacist.get(dispenseAttemptId);
  if (requestedDispenseAttemptIsAvailable) {
    dispenseAttemptsRouteStore.setValue('activeDispenseAttemptId', dispenseAttemptId);
  } else {
    const firstId = _get(dispenseAttemptsStore, 'forPharmacistByDate[0].data[0].id');
    if (firstId) {
      navigate(`${DISPENSE_ATTEMPTS_ROUTE.VALUE}/${firstId}`);
    } else {
      dispenseAttemptsRouteStore.setValue('activeDispenseAttemptId', null);
    }
  }
};
