import { get as _get } from 'lodash';

// Constants
import * as ROUTES from 'constants/routes';
import { MAX_ENTRIES_PER_PAGE } from '../constants';

// Stores
import stores from 'bootstrap/store';
import { history } from 'bootstrap/store/router';

const store = {
  global: {
    prescriptionFills: stores.global.prescriptionFills,
  },
  route: {
    prescriptionFillsTransfers: stores.route.prescriptionFillsTransfers.domain,
  },
  history,
};


// List prescription fills considering all dependencies.
export default ({ filterQuery = true, paginate = true, shouldMerge = true } = {}) => {
  const query = {};

  const currentPage = store.route.prescriptionFillsTransfers.currentPage.prescriptionFills;
  if (paginate) {
    query.offset = MAX_ENTRIES_PER_PAGE * (currentPage - 1);
    query.limit = MAX_ENTRIES_PER_PAGE;
  }
  
  const filterValue = store.route.prescriptionFillsTransfers.prescriptionFillsFilterValue;
  if (filterQuery && filterValue) {
    if (store.global.prescriptionFills.transfers.size > MAX_ENTRIES_PER_PAGE - 1) {
      store.global.prescriptionFills.setValue('transfers', {});
    }

    query.filter = {
      '$prescription.patient.firstName$': filterValue,
      '$prescription.patient.lastName$': filterValue,
    };
  }

  const config = { query };

  store.route.prescriptionFillsTransfers.incrementHttp('listingTransfersPrescriptionFills');
  return store.global.prescriptionFills.listTransfersForPharmacist(config, {shouldMerge})
  .then((response) => {
    const responseLength = _get(response, 'data.length', 0);
    if (paginate && responseLength > MAX_ENTRIES_PER_PAGE - 1) {
      store.route.prescriptionFillsTransfers.setValue('currentPage.prescriptionFills', currentPage + 1);
    }

    if (!store.route.prescriptionFillsTransfers.activePrescriptionFillId) {
      const firstId = _get(store.route.prescriptionFills, 'transfersByDate[0].data[0].id');
      store.route.prescriptionFillsTransfers.setValue('activePrescriptionFillId', firstId);
    }
  })
  .catch(() => store.history.push(`${ROUTES.PRESCRIPTION_FILLS.VALUE}${ROUTES.PRESCRIPTION_FILLS.SUB_ROUTE.TRANSFERS.VALUE}`))
  .finally(() => store.route.prescriptionFillsTransfers.decrementHttp('listingTransfersPrescriptionFills'));
};
