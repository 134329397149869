import { ORDER_OPTIONS } from '../constants';
import { MAX_TABLE_ENTRIES_PER_PAGE } from 'models/physicians/constants';

import stores from 'bootstrap/store';
import { physiciansRouteStore } from '../domain/store';
const physiciansStore = stores.global.physicians;

export default () => {
  const query = {
    offset: MAX_TABLE_ENTRIES_PER_PAGE * (physiciansRouteStore.currentPage - 1),
    limit: MAX_TABLE_ENTRIES_PER_PAGE,
  };

  if (physiciansRouteStore.filterValue) {
    query.filter = {
      firstName: physiciansRouteStore.filterValue,
      lastName: physiciansRouteStore.filterValue,
      NPI: physiciansRouteStore.filterValue,
      SPI: physiciansRouteStore.filterValue,
    };
  }

  if (physiciansRouteStore.sortingColumn.fieldName && physiciansRouteStore.sortingColumn.sortingOrder) {
    query.order = ORDER_OPTIONS[physiciansRouteStore.sortingColumn.fieldName](physiciansRouteStore.sortingColumn.sortingOrder);
  }

  const listPhysicians = () => {
    physiciansRouteStore.incrementHttp('listingForPharmacist');
    physiciansStore.listPhysicians({ query })
    .then(() => physiciansRouteStore.decrementHttp('listingForPharmacist'));
  };

  const countPhysicians = () => {
    physiciansRouteStore.incrementHttp('countingForPharmacist');
    physiciansStore.countPhysicians({ query })
    .then(() => physiciansRouteStore.decrementHttp('countingForPharmacist'));
  };

  return Promise.all([
    listPhysicians(),
    countPhysicians(),
  ]);
};
