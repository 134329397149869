import {
  closeMachineTechnicianUpsertModal,
  listMachineTechnicians,
} from './index';

import stores from 'bootstrap/store';
import { machineTechniciansRouteStore } from '../domain/store';
const machineTechniciansStore = stores.global.machineTechnicians;

export default () => {
  return machineTechniciansStore.deleteMachineTechnician({
    params: {
      machineTechnicianId: machineTechniciansRouteStore.activeTechnicianId,
    },
  })
  .then(() => {
    closeMachineTechnicianUpsertModal();
    return listMachineTechnicians();
  });
};
