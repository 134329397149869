import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

// Views
import KioskContainersView from './kiosk-containers-view';

// Helpers
import fetchActiveContainers from './helpers/fetchActiveContainers';
import fetchKioskInventory from './helpers/fetchKioskInventory';
import invalidateSelectedContainers from './helpers/invalidateSelectedContainers';
import onContainerSearch from './helpers/onContainerSearch';

// Route store
import { kioskContainersRouteStore } from './domain/store';


const KioskContainersContainer = ({ activeKiosk }) => {
  useEffect(() => {
    fetchActiveContainers(activeKiosk);
    fetchKioskInventory(activeKiosk);

    return () => {
      kioskContainersRouteStore.reset();
    };
  }, []);

  useEffect(() => {
    const { containersById, selectedContainerIds } = kioskContainersRouteStore;
    selectedContainerIds.forEach((selectedContainerId) => {
      if (!containersById[selectedContainerId]) {
        kioskContainersRouteStore.deleteSelectedContainer(selectedContainerId);
      }
    });
  }, [kioskContainersRouteStore.containers.length]);


  return <KioskContainersView
    kioskContainersRouteStore={kioskContainersRouteStore}
    invalidateSelectedContainers={() => invalidateSelectedContainers(activeKiosk)}
    onContainerSearch={(e) => onContainerSearch(activeKiosk, e)}
  />;
};

export default observer(KioskContainersContainer);
