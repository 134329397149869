import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';

// Views
import PrescriptionFillsListView from './prescriptions-fills-mail-ordered-view';

// Helpers
import * as helpers from './helpers';

// Stores
import stores from 'bootstrap/store';
import { prescriptionFillsMailOrderRouteStore } from './domain/store';

const prescriptionFillsStore = stores.global.prescriptionFills;


const PrescriptionsFillsMailOrderedContainer = ({ rootRoute }) => {
  const navigate = useNavigate();
  const { prescriptionFillId } = useParams();

  useEffect(() => {
    prescriptionFillsMailOrderRouteStore.setValue('activePrescriptionFillId', null);
    helpers.listPrescriptionFills();

    return () => {
      prescriptionFillsStore.setInitialState();
      prescriptionFillsMailOrderRouteStore.setInitialState();
    };
  }, []);

  useEffect(() => {
    if (!prescriptionFillsMailOrderRouteStore.http.listingMailOrderedPrescriptionFills) {
      helpers.determineActivePrescriptionFill(prescriptionFillId, navigate);
    }
  }, [prescriptionFillId, prescriptionFillsMailOrderRouteStore.http.listingMailOrderedPrescriptionFills]);

  return <PrescriptionFillsListView
    prescriptionFillsStore={prescriptionFillsStore}
    prescriptionFillsMailOrderRouteStore={prescriptionFillsMailOrderRouteStore}
    listPrescriptionFills={helpers.listPrescriptionFills}
    onPrescriptionFillsSearch={helpers.onPrescriptionFillsSearch}
    processMailOrder={helpers.processMailOrder}
    rootRoute={rootRoute}
  />;
};


export default observer(PrescriptionsFillsMailOrderedContainer);
