import {
  closePharmacyUpsertModal,
  listPharmacies,
} from './index';

import Promise from 'bluebird';
import stores from 'bootstrap/store';
import { pharmaciesRouteStore } from '../domain/store';
import { pharmacyUpsertModalRouteStore } from '../components/pharmacy-upsert-modal/domain/store';
import {toJS} from 'mobx';
const pharmaciesStore = stores.global.pharmacies;

export default () => {
  const body = {
    ...toJS(pharmacyUpsertModalRouteStore.activelyModifiedPharmacy),
    placeId: pharmacyUpsertModalRouteStore.locationSelector.value
  };

  pharmaciesRouteStore.incrementHttp('pharmacyUpsert');
  return pharmaciesStore.upsertPharmacy({ body })
  .then((pharmacy) => {
    return Promise.map(body.healthcareSites, healthcareSite => pharmaciesStore.addHealthcareSite({params: {
      healthcareSiteId: healthcareSite.id,
      pharmacyId: pharmacy.id,
    }}));
  })
  .then(() => {
    closePharmacyUpsertModal();
    return listPharmacies();
  })
  .finally(() => {
    pharmaciesRouteStore.decrementHttp('pharmacyUpsert');
  });
};
