import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

// Views
import HealthcareSiteInventoryView from './healthcare-site-inventory-view';

// Route store
import { healthcareSiteInventoryRouteStore } from './domain/store';

// Global Stores
import stores from 'bootstrap/store';
const healthcareSitesStore = stores.global.healthcareSites;


const HealthcareSiteInventory = ({ activeHealthcareSite }) => {
  useEffect(() => {
    healthcareSitesStore.listHealthcareSiteInventory({
      params: {
        healthcareSiteId: activeHealthcareSite.id,
      },
    });

    return () => {
      healthcareSitesStore.setValue('computedInventory', null);
    };
  }, []);

  return <HealthcareSiteInventoryView
    healthcareSiteInventoryRouteStore={healthcareSiteInventoryRouteStore}
    activeHealthcareSite={activeHealthcareSite}
    healthcareSiteInventory={healthcareSitesStore.healthcareSiteInventory}
  />;
};

export default observer(HealthcareSiteInventory);
