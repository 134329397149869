import React from 'react';
import { observer } from 'mobx-react';

// Components
import InventoryCountStatistics from './components/inventory-count-statistics';

// Styles
import './healthcare-site-inventory-view.scss';

const HealthcareSiteInventoryView = ({
  healthcareSiteInventory,
}) => (
  <div className='c-HealthcareSiteInventory'>
    <div className='count-statistics'>
      <InventoryCountStatistics
        inventory={{count: healthcareSiteInventory || {}}}
      />
    </div>
  </div>
);

export default observer(HealthcareSiteInventoryView);
