import React from 'react';

export const COLUMNS = [
  {
    name: 'NDC',
    fieldName: 'NDC',
    isSortable: true,
    width: '160px',
    render: ({ content }) => (
      <div title={content.NDC}>{content.NDC}</div>
    )
  },
  {
    name: 'Name',
    fieldName: 'name',
    isSortable: true,
    width: '420px',
    render: ({ content }) => (
      <div className='drug-name'>{content.name}</div>
    )
  },
  {
    name: 'Strength',
    fieldName: 'strength',
    isSortable: false,
    width: '120px',
    render: ({ content }) => (
      <div className='drug-strength'>{content.strength}</div>
    )
  },
  {
    name: 'Quantity',
    fieldName: 'quantity',
    isSortable: false,
    width: '15%',
    render: ({ content }) => (
      <div title={content.quantity}>{content.quantity}</div>
    )
  },
];
