import {
  closePatientUpsertModal,
  listPatients,
} from './index';

import stores from 'bootstrap/store';
import { patientsRouteStore } from '../domain/store';
const patientsStore = stores.global.patients;

export default () => {
  return patientsStore.deletePatient({
    params: {
      id: patientsRouteStore.activePatientId,
    },
  })
  .then(() => {
    closePatientUpsertModal();
    return listPatients();
  });
};
