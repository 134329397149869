import React from 'react';
import { observer } from 'mobx-react';

import DrugUpdateModal from './components/drug-update-modal';

import AdminTableLayout from 'components/admin-table-layout';
import Title from 'components/admin-table-layout/components/title';
import TableHeader from 'components/admin-table-layout/components/table-header';
import EntriesCount from 'components/admin-table-layout/components/table-header/components/entries-count';
import Paginator from 'components/paginator';
import ListSearch from 'components/drill-down-layout/components/list/components/list-header/components/list-search';
import TableContent from 'components/admin-table-layout/components/table-content';
import SmartTable from 'components/smart-table';

import { COLUMNS } from './constants';
import { MAX_TABLE_ENTRIES_PER_PAGE } from 'models/drugs/constants';

import './drugs-style.scss';


const DrugsView = ({
  drugsRouteStore,
  drugUpdateModalRouteStore,
  sortDrugs,
  changePaginationPage,
  onDrugsSearch,
  showDrugUpdateModal,
  closeDrugUpdateModal,
  upsertDrug,
  entriesCount,
}) => (
  <React.Fragment>
    <DrugUpdateModal
      drugUpdateModalRouteStore={drugUpdateModalRouteStore}
      drugsRouteStore={drugsRouteStore}
      upsertDrug={upsertDrug}
      closeDrugUpdateModal={closeDrugUpdateModal}
    />
    <AdminTableLayout>
      <Title
        text='Drugs'
      />
      <TableHeader>
        <div className='left-area'>
          <EntriesCount
            count={entriesCount}
            caption='Drugs'
          />
          <Paginator
            currentPage={drugsRouteStore.currentPage}
            entriesCount={entriesCount}
            entriesPerPage={MAX_TABLE_ENTRIES_PER_PAGE}
            changePage={changePaginationPage}
          />
        </div>
        <div className='right-area'>
          <ListSearch
            searchValue={drugsRouteStore.filterValue}
            onSearch={onDrugsSearch}
          />
        </div>
      </TableHeader>
      <TableContent>
        <SmartTable
          loading={drugsRouteStore.loading}
          sortingFieldName={drugsRouteStore.sortingColumn.fieldName}
          sortingOrder={drugsRouteStore.sortingColumn.sortingOrder}
          onSortIconClick={sortDrugs}
          rows={drugsRouteStore.forTable}
          onRowClick={showDrugUpdateModal}
          columns={COLUMNS}
        />
      </TableContent>
    </AdminTableLayout>
  </React.Fragment>
);

export default observer(DrugsView);
