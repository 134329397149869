import { types } from 'mobx-state-tree';
import {
  get as _get,
  set as _set,
} from 'lodash';

import HealthcareSiteModel from 'models/healthcare-sites';

export const HealthcareSiteUpsertModalRouteStore = types.model({
})
.props({
  activelyModifiedHealthcareSite: types.maybeNull(types.frozen()),
  showingDeleteConfirmationModal: types.optional(types.boolean, false),

  locationSelector: types.optional(types.model({
    label: types.optional(types.string, ''),
    value: types.optional(types.string, ''),
  }), {label: '', value: ''}),
  locationSelectorFilter: types.optional(types.string, ''),

  pharmacySelector: types.optional(types.model({
    label: types.optional(types.string, ''),
    value: types.optional(types.string, ''),
  }), {label: '', value: ''}),
  pharmacySelectorFilter: types.optional(types.string, ''),
})
.actions(self => ({
  setValue: (path, value) => _set(self, path, value),
  setInitialState() {
    self.setValue('activelyModifiedHealthcareSite', null);
    self.setValue('showingDeleteConfirmationModal', false);
    self.setValue('locationSelector', {label: '', value: ''});
    self.setValue('locationSelectorFilter', '');
    self.setValue('pharmacySelector', {label: '', value: ''});
    self.setValue('pharmacySelectorFilter', '');
  },
}))
.views(self => ({
  get readyForCreation() {
    return !!(
      _get(self, 'activelyModifiedHealthcareSite.name') &&
      _get(self, 'activelyModifiedHealthcareSite.phoneNumber') &&
      _get(self, 'locationSelector.value') &&
      _get(self, 'pharmacySelector.value')
    );
  },
}));

export const healthcareSiteUpsertModalRouteStore = HealthcareSiteUpsertModalRouteStore.create({});
