import React from 'react';
import { observer } from 'mobx-react';

import ListHeader from 'components/drill-down-layout/components/list/components/list-header';

const MedicalAssistantsListHeader = ({
  filterValue,
  setFilterValue,
}) => (
  <ListHeader
    title='Medical Assistants'
    searchValue={filterValue}
    onSearch={(e) => setFilterValue(e)}
    searchPlaceholder='Medical Assistant...'
  />
);

export default observer(MedicalAssistantsListHeader);
