import React from 'react';
import { observer } from 'mobx-react';

import NotificationWrapper from '../notification-wrapper';
import ActiveVideoConference from './components/active-video-conference';
import PendingVideoConference from './components/pending-video-conference';

import './video-conferences-notifications-view-style.scss';


const VideoConferencesNotificationsView = ({
  active,
  pending,
  allPendingConferences,
  accept,
  reject,
}) => (
  <div className='c-VideoConferencesNotifications'>
    {
      !allPendingConferences.length &&
      <div className='no-data-caption'>
        No Pending Video Conferences
      </div>
    }
    {
      active.map((videoConference) => <NotificationWrapper
        key={videoConference.id}
        onClick={() => accept(videoConference)}
      >
        <ActiveVideoConference
          videoConference={videoConference}
        />
      </NotificationWrapper>
      )
    }
    {
      pending.map((videoConference) => <NotificationWrapper
        key={videoConference.id}
        cursorPointer={false}
      >
        <PendingVideoConference
          videoConference={videoConference}
          accept={accept}
          reject={reject}
        />
      </NotificationWrapper>)
    }
  </div>
);

export default observer(VideoConferencesNotificationsView);
