import React from 'react';
import { observer } from 'mobx-react';

import ListHeader from 'components/drill-down-layout/components/list/components/list-header';

const PrescriptionFillsListHeader = ({
  filter,
  onPrescriptionFillsSearch,
}) => (
  <ListHeader
    title='Mail Ordered Prescriptions'
    searchValue={filter}
    onSearch={onPrescriptionFillsSearch}
  />
);

export default observer(PrescriptionFillsListHeader);
