import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';

import AppFrameView from './app-frame-view';

import stores from 'bootstrap/store';
import { appFrameRouteStore } from './domain/store';

import { detectActiveTab } from './helpers';

const AppFrameContainer = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    detectActiveTab();
  }, [pathname]);

  const isActiveUserAvailable = Boolean(
    stores.global.auth.hasCheckedLocalStorage &&
    stores.global.auth.activeToken &&
    stores.global.users.activeUser
  );

  return <AppFrameView
    isActiveUserAvailable={isActiveUserAvailable}
    activeUserAccessibleRoutes={appFrameRouteStore.activeUserAccessibleRoutes}
  />;
};

export default observer(AppFrameContainer);
