import stores from 'bootstrap/store';

import { prescriptionFillsListRouteStore } from '../../domain/store';
import { PRESCRIPTION_FILL_CONFIG, RESULT_TYPES } from '../../constants';

const prescriptionFillsStore = stores.global.prescriptionFills;

export default () => {
  const data = PRESCRIPTION_FILL_CONFIG.reduce((res, model) => {
    res[model.modelName] = model.fields.reduce((res, field) => {
      let value = prescriptionFillsListRouteStore[model.modelName][field.path];
      value = field.wrap ? field.wrap(value) : value;
      if (!value) return res;

      res[field.path] = value;

      return res;
    }, {});

    return res;
  }, {});

  prescriptionFillsListRouteStore.incrementHttp('upsertPrescriptionFill');
  return prescriptionFillsStore.createWithReferences({
    body: data
  })
  .then(() => {
    prescriptionFillsListRouteStore.setResultStatus(RESULT_TYPES.SUCCESSFUL);
  })
  .catch(() => {
    prescriptionFillsListRouteStore.setResultStatus(RESULT_TYPES.ERROR);
  })
  .finally(() => {
    prescriptionFillsListRouteStore.decrementHttp('upsertPrescriptionFill');
  });
};
