import { toJS } from 'mobx';

import { KEYCARD_MANIPULATION_OPTIONS, KEYCARD_SKELETON } from '../constants';

import { keycardsRouteStore } from '../domain/store';
import { keycardUpsertModalRouteStore } from '../components/keycard-upsert-modal/domain/store';

export default (e, purpose = KEYCARD_MANIPULATION_OPTIONS.UPDATE) => {
  if (purpose === KEYCARD_MANIPULATION_OPTIONS.UPDATE) {
    keycardsRouteStore.setValue('activeKeycardId', e.content.id);
    keycardsRouteStore.setValue('showingKeycardUpsertModal', true);
    keycardUpsertModalRouteStore.setValue('activelyModifiedKeycard', toJS(keycardsRouteStore.activeKeycard));
  } else if (purpose === KEYCARD_MANIPULATION_OPTIONS.CREATE) {
    keycardsRouteStore.setValue('showingKeycardUpsertModal', true);
    keycardUpsertModalRouteStore.setValue('activelyModifiedKeycard', KEYCARD_SKELETON);
  }
};
