import React from 'react';
import { observer } from 'mobx-react';

// Components
import Content from 'components/drill-down-layout/components/content';
import ContentTab from 'components/drill-down-layout/components/content/components/content-tab';
import Header from './components/header';

// Routes
import PrescriptionInfo from '../../routes/prescription-info';

// Constants
import { DISPENSE_ATTEMPTS } from 'constants/routes';


const DispenseAttemptContent = ({
  dispenseAttemptsStore,
  dispenseAttemptsRouteStore,
  approveDispenseAttempt,
  isExpanded,
  toggleExpanded,
}) => (
  <Content
    header={<Header
      dispenseAttemptsStore={dispenseAttemptsStore}
      dispenseAttemptsRouteStore={dispenseAttemptsRouteStore}
      approveDispenseAttempt={approveDispenseAttempt}
    />}
    isReady={!!dispenseAttemptsRouteStore.activeDispenseAttempt}
  >
    <ContentTab title={DISPENSE_ATTEMPTS.CONTENT_TAB.PRESCRIPTION_INFO.TITLE} route={DISPENSE_ATTEMPTS.CONTENT_TAB.PRESCRIPTION_INFO.VALUE}>
      <PrescriptionInfo
        activeDispenseAttemptId={dispenseAttemptsRouteStore.activeDispenseAttemptId}
        activeDispenseAttempt={dispenseAttemptsRouteStore.activeDispenseAttempt}
        isExpanded={isExpanded}
        toggleExpanded={toggleExpanded}
      />
    </ContentTab>
  </Content>
);

export default observer(DispenseAttemptContent);
