export const CAMERA_TYPES = {
  CONSULTATION: 'CONSULTATION',
};

export const CAMERA_HEADERS = {
  [CAMERA_TYPES.CONSULTATION]: 'consultation',
};

export const LOCAL_STORE_KEY_FOR_TYPES = {
  [CAMERA_TYPES.CONSULTATION]: 'consultationDeviceId',
};
