import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import PrescriptionFillUpsertView from './prescription-fill-upsert-view';

import { prescriptionFillsListRouteStore } from './domain/store';

import { upsertPrescriptionFill } from './helpers';

const PrescriptionFillUpsertComponent = () => {
  useEffect(() => {
    return () => {
      prescriptionFillsListRouteStore.setInitialState();
    };
  }, []);

  return (
    <PrescriptionFillUpsertView
      prescriptionFillsListRouteStore={prescriptionFillsListRouteStore}
      upsertPrescriptionFill={upsertPrescriptionFill}
    />
  );
};

export default observer(PrescriptionFillUpsertComponent);
