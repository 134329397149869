import React from 'react';
import { observer } from 'mobx-react';
import { isString as _isString } from 'lodash';

import './drug-suggestion-styles.scss';

const DrugSuggestion = ({ suggestion }) => {
  const { name, NDC, quantity } = suggestion;

  return <div className='c-DrugSuggestion'>
    <div className='name'>
      {
        name
      }
    </div>
    <div className='detail'>
      <span className='key'>
        NDC:
      </span>
      {
        NDC
      }
    </div>
    <div className='detail'>
      <span className='key'>
        Quantity
      </span>
      {
        _isString(quantity) ? quantity : 'N/A'
      }
    </div>
  </div>;
};

export default observer(DrugSuggestion);
