import React from 'react';
import { observer } from 'mobx-react';

import './index.scss';

const ListItemGroup = ({
  title,
  children
}) => {
  return <div className='c-ListItemGroup'>
    <div className='title-container'>
      <div className='title'>
        {
          title
        }
      </div>
      <div className='horizontal-rule-container'>
        <hr />
      </div>
    </div>
    <div className='body-container'>
      {
        React.Children.toArray(children)
      }
    </div>
  </div>;
};

export default observer(ListItemGroup);
