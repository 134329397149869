import React from 'react';
import { observer } from 'mobx-react';

// import AutocompleteCaptionValueInput from './components/autocomplete-caption-value-input';
import Autocomplete from 'components/autocomplete';
import CaptionValueInput from 'components/caption-value-input';
import ContentScreen from 'components/content-screen';
import Modal from 'components/modal';
import ModalEditingAreas from 'components/modal/layout/editing-areas';
import ModalEditingArea from 'components/modal/layout/editing-areas/components/editing-area';
import ModalRow from 'components/modal/layout/row';
import ModalButton from 'components/modal/layout/button';

import { RE_POSITIVE_FLOAT } from './constants';

import './prescription-fills-edit-modal-view.scss';


const PrescriptionFillEditModalView = ({
  prescriptionFillEditModalRouteStore,
  isOpen,
  isFulfillmentLocked,
  isPrescriptionFillActiveWithoutDispenseAttempt,
  title,
  editPrescriptionFill,
  onClose,
  drugSuggestions,
  searchDrugs,
  clearDrugSuggestions,
  onDrugSuggestionSelect,
}) => {

  const { drug } = prescriptionFillEditModalRouteStore;

  if (isPrescriptionFillActiveWithoutDispenseAttempt && !drug) return <></>;

  return (
    <Modal
      modalClassName='c-PrescriptionFillEditModalView'
      modalStyles={{ height: '50%', width: '50%' }}
      isOpen={isOpen}
      title={title}
      shouldCloseOnOverlayClick={true}
      close={onClose}
    >
      <ContentScreen active={prescriptionFillEditModalRouteStore.isBusy}/>
      <ModalEditingAreas>
        <div className='left-area'>
          {
            isPrescriptionFillActiveWithoutDispenseAttempt &&
            <ModalRow>
              <div className='drug'>
                <div>
                  <div className='drug-name'>{drug.name}</div>
                  <div>NDC: {drug.NDC}</div>
                  <div></div>
                </div>
                <div className='drugs-autocomplete'>
                  <Autocomplete
                    onSearch={searchDrugs}
                    onChange={(term) => !term && clearDrugSuggestions()}
                    onSuggestionSelect={onDrugSuggestionSelect}
                    suggestions={drugSuggestions}
                    renderSuggestion={
                      (suggestion) => {
                        return <div className='suggestion'>
                          <div className='drug-name'>{suggestion.name}</div>
                          <div>NDC: {suggestion.NDC}</div>
                        </div>;
                      }
                    }
                    placeholder={'Search Different Drug'}
                  />
                  {/* <AutocompleteCaptionValueInput
                type={'Select Different Drug'}
                caption='Select Different Drug'
                value={drug.NDC}
                onInputChange={(value) => listDrugsAutocomplete(value)}
                suggestions={drugSuggestions}
                onSuggestionClick={() => {}}
                clearDrugSuggestions={() => clearDrugSuggestions()}
              /> */}
                </div>
              </div>
            </ModalRow>
          }
          {
            !isFulfillmentLocked &&
            <ModalRow>
              <CaptionValueInput
                inputFieldClassNames={['max-width']}
                caption='Copay'
                value={prescriptionFillEditModalRouteStore.copay}
                validationRegex={[RE_POSITIVE_FLOAT]}
                onChange={(value) => prescriptionFillEditModalRouteStore.setValue('copay', value || null)}
              />
            </ModalRow>
          }
        </div>
        <ModalEditingArea>
          {
            isPrescriptionFillActiveWithoutDispenseAttempt &&
            <ModalRow>
              <CaptionValueInput
                inputFieldClassNames={['max-width']}
                caption='Drug Quantity'
                value={prescriptionFillEditModalRouteStore.quantity}
                validationRegex={[RE_POSITIVE_FLOAT]}
                onChange={(value) => prescriptionFillEditModalRouteStore.setValue('quantity', value || null)}
              />
            </ModalRow>
          }
        </ModalEditingArea>
      </ModalEditingAreas>
      <ModalRow shouldBoundToBottom={true}>
        <ModalButton
          color='blue'
          text='Save'
          isDisabled={!prescriptionFillEditModalRouteStore.canBeSubmitted}
          onClick={editPrescriptionFill}
        />
      </ModalRow>
    </Modal>
  );
};

export default observer(PrescriptionFillEditModalView);
