import { isString as _isString } from 'lodash';

import { prescriptionFillsManualEntryRouteStore } from '../domain/store';

import listPhysiciansAutocomplete from './listPhysiciansAutocomplete';

const onPhysicianAutocompleteInputChange = async (field, value) => {
  prescriptionFillsManualEntryRouteStore.setValue(`physician.${field}`, value);

  const currentValue = prescriptionFillsManualEntryRouteStore.physician[field];
  if (_isString(currentValue) && currentValue.length > 2) {
    return listPhysiciansAutocomplete(field);
  } else {
    return prescriptionFillsManualEntryRouteStore.setValue(`suggestions.physician.${field}`, []);
  }
};

export default onPhysicianAutocompleteInputChange;
