import React from 'react';
import { observer } from 'mobx-react';

import './index.scss';

const Title = ({ text, customClassName }) => (
  <div className={`c-TitleContainer ${customClassName || ''}`}>
    <div className='title'>
      {
        text
      }
    </div>
  </div>
);

export default observer(Title);
