import { get as _get } from 'lodash';

import {
  closePhysicianUpsertModal,
  listPhysicians,
} from './index';

import stores from 'bootstrap/store';
import { physiciansRouteStore } from '../domain/store';
import { physicianUpsertModalRouteStore } from '../components/physician-upsert-modal/domain/store';
const physiciansStore = stores.global.physicians;
const usersStore = stores.global.users;

export default () => {

  const { activelyModifiedPhysician } = physicianUpsertModalRouteStore;
  const user  = _get(activelyModifiedPhysician, 'physicianUser.user', {});

  return Promise.all([
    physiciansStore.updatePhysician({
      params: {
        id: physiciansRouteStore.activePhysicianId,
      },
      body: physicianUpsertModalRouteStore.activelyModifiedPhysician,
    }),
    user.id && usersStore.updateUser({ params: { id: user.id }, body: user }),
  ])
  .then(() => {
    closePhysicianUpsertModal();
    return listPhysicians();
  });
};
