import React from 'react';
import { observer } from 'mobx-react';

import StatisticsItem from './components/statistics-item';

import './inventory-count-statistics-style.scss';

const InventoryCountStatistics = ({
  inventory,
}) => {
  const { count } = inventory;

  return (
    <div className='c-InventoryCountStatistics'>
      <StatisticsItem
        caption='Available Inventory'
        totalCount={count.total}
        progressCount={count.available}
      />
      <StatisticsItem
        caption='Assignable Inventory'
        totalCount={count.total}
        progressCount={count.assignable}
      />
      <StatisticsItem
        caption='Unidentifiable Inventory'
        totalCount={count.total}
        progressCount={count.unidentifiable}
      />
      <StatisticsItem
        caption='Labelled Inventory'
        totalCount={count.total}
        progressCount={count.labelled}
      />
      <StatisticsItem
        caption='Rejected Inventory'
        totalCount={count.total}
        progressCount={count.rejected}
      />
      <StatisticsItem
        caption='Declined Inventory'
        totalCount={count.total}
        progressCount={count.declined}
      />
    </div>
  );
};

export default observer(InventoryCountStatistics);
