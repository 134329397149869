import React from 'react';
import { observer } from 'mobx-react';

import './index.scss';

const ContentHeader = ({
  style = {},
  title,
  subtitle,
  subHeader,
  leftLabel,
}) => {
  return <div style={style} className='c-ContentHeader'>
    <div className='header-container'>
      {
        !!leftLabel &&
        <div className='left-label'>
          {leftLabel}
        </div>
      }
      <div className='title-container'>
        <div className='title'>
          {
            title
          }
        </div>
        {
          !!subtitle &&
          <div className='subtitle'>
            {
              subtitle
            }
          </div>
        }
      </div>
      {
        !!subHeader && subHeader
      }
    </div>
  </div>;
};

export default observer(ContentHeader);
