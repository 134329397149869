const parseDbDateOfBirth = (DOB) => {
  if (!DOB || ! DOB.includes('-')) {
    return;
  }
  
  const [year, month, day] = DOB.split('-');
  return `${month}/${day}/${year}`;
};

export default parseDbDateOfBirth;
