import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

// Views
import DispenseAttemptsNotificationsView from './dispense-attempts-notifications-view';

// Constants
import { NOTIFICATION_TYPE } from '../../../../constants';

// Helpers
import { fetchNotificationsRelatedData } from '../../../../helpers';
import { goToReview } from './helpers';

// Stores
import stores from 'bootstrap/store';
const dispenseAttemptsStore = stores.global.dispenseAttempts;


const DispenseAttemptsNotificationsContainer = () => {
  useEffect(() => {
    fetchNotificationsRelatedData(NOTIFICATION_TYPE.DISPENSE_ATTEMPTS);
  }, []);

  return <DispenseAttemptsNotificationsView
    pending={dispenseAttemptsStore.pendingArray}
    goToReview={goToReview}
  />;
};

export default observer(DispenseAttemptsNotificationsContainer);
