import stores from 'bootstrap/store';
import { kioskContainersRouteStore } from '../domain/store';

const fetchActiveContainers = async (activeKiosk) => {
  if (!activeKiosk) {
    return;
  }

  kioskContainersRouteStore.incrementHttp('fetchingContainers');

  const query = {};
  if (kioskContainersRouteStore.filterValue) {
    query.filter = {
      'labelNDC': kioskContainersRouteStore.filterValue,
      'quantity': kioskContainersRouteStore.filterValue,
      '$drug.name$': kioskContainersRouteStore.filterValue,
      '$drug.NDC$': kioskContainersRouteStore.filterValue,
      '$kioskModuleSlot.reference_kioskModuleName$': kioskContainersRouteStore.filterValue,
      '$kioskModuleSlot.reference_slotTemplateId$': kioskContainersRouteStore.filterValue,
      '$kioskModuleSlot.reference_slotName$': kioskContainersRouteStore.filterValue,
    };
  }
  const response = await stores.global.containers.listActiveForPharmacistByKiosk({
    params: { kioskId: activeKiosk.id },
    query,
  });

  kioskContainersRouteStore.setValue('containers', response.data);

  kioskContainersRouteStore.decrementHttp('fetchingContainers');

  return response;
};

export default fetchActiveContainers;
