import base from 'models/base';
import { MODEL_NAME, SCHEMA } from './constants/schema';


const VideoConferenceParticipantConnectionModel = base({
  names: {
    singular: MODEL_NAME.SINGULAR,
    plural: MODEL_NAME.PLURAL,
  },
  JSONSchema: SCHEMA,
  httpConfig: {
    root: MODEL_NAME.plural,
    methods: {},
  },
});


VideoConferenceParticipantConnectionModel.configureStore((store) => {
  return store
  .actions(self => ({
    ping: (config = {}) => {
      config.urlFragment = (params) => `/${params.videoConferenceParticipantConnectionId}/ping`;
      return self.put(config);
    },
  }));
});


export default VideoConferenceParticipantConnectionModel;
