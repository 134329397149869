import { flow } from 'mobx-state-tree';

import base from 'models/base';

import { MODEL_NAME, SCHEMA } from './constants/schema';


const UserContactEventsModel = base({
  names: {
    singular: MODEL_NAME.SINGULAR,
    plural: MODEL_NAME.PLURAL
  },
  JSONSchema: SCHEMA,
  httpConfig: {
    root: MODEL_NAME.plural,
    methods: {}
  }
});

UserContactEventsModel.configureStore((store) => {
  return store
  .props({
  })
  .actions(self => ({
    sendReceipt: flow(function* (config = {}) {
      if (!config?.params?.prescriptionFillsFulfillmentId) {
        return Promise.reject('prescriptionFillsFulfillmentId is required in order to send a receipt.');
      }

      config.urlFragment = () => `/prescription-fills-fulfillments/${config.params.prescriptionFillsFulfillmentId}`;

      const response = yield self.post(config);

      return response;
    }),
  }));
});


export default UserContactEventsModel;
