import { types } from 'mobx-state-tree';


export const LoginRouteStore = types.model({
  email: types.optional(types.string, ''),
  password: types.optional(types.string, ''),
  errorMessage: types.optional(types.string, ''),
  authMultifactorCodeToken: types.maybeNull(types.string),
  http: types.optional(types.model({
    loggingIn: types.optional(types.number, 0),
    verifyingLoginCode: types.optional(types.number, 0),
  }), {}),
})
.actions(self => ({
  setValue: (prop, value) => {
    if (self.errorMessage) self.errorMessage = '';
    self[prop] = value;
  },
  incrementHttp: (http) => ++self.http[http],
  decrementHttp: (http) => --self.http[http],
  setInitialState: () => {
    self.email = '';
    self.password = '';
    self.errorMessage = '';
    self.authMultifactorCodeToken = null;
    self.http = {};
  }
}))
.views(self => ({
  get isBusy() {
    return Boolean(self.http.loggingIn || self.http.verifyingLoginCode);
  },
}));

export const loginRouteStore = LoginRouteStore.create({});
