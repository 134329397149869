import { types } from 'mobx-state-tree';

import stores from 'bootstrap/store';

export const MedicalAssistantsRouteStore = types.model({
  activeMedicalAssistantId: types.maybeNull(types.string),
  filterValue: types.optional(types.string, ''),
  currentPage: types.optional(types.number, 1),
  http: types.optional(types.model({
    listingMedicalAssistants: types.optional(types.number, 0)
  }), {}),
})
.actions(self => ({
  setValue: (prop, value) => self[prop] = value,
  setInitialState: () => {
    self.activeMedicalAssistantId = null;
    self.filterValue = '';
    self.currentPage = 1;
    self.http = {
      listingMedicalAssistants: 0,
    };
  },
  incrementHttp: (http) => ++self.http[http],
  decrementHttp: (http) => --self.http[http]
}))
.views(self => ({
  get activeMedicalAssistant() {
    return self.activeMedicalAssistantId && stores.global.medicalAssistants.medicalAssistants.get(self.activeMedicalAssistantId);
  },
  getMedicalAssistantHealthcareSitesCountCaption(medicalAssistant) {
    const healthcareSites = (medicalAssistant || {}).healthcareSites || [];
    const healthcareSitesCount = healthcareSites.length;
    return healthcareSitesCount !== 1 ? `${healthcareSitesCount} Healthcare Sites` : '1 Healthcare Site';
  },
  get activeMedicalAssistantHealthcareSitesCountCaption() {
    return self.getMedicalAssistantHealthcareSitesCountCaption(self.activeMedicalAssistant);
  },
}));

export const medicalAssistantsRouteStore = MedicalAssistantsRouteStore.create({});
