import React from 'react';
import { observer } from 'mobx-react';

import chevronRightIcon from './icons/chevron-right.svg';
import chevronRightDisabledIcon from './icons/chevron-right-disabled.svg';

import './index.scss';

const ArrowForward = ({ currentPage, pagesCount, changePage }) => {
  const enabled = currentPage !== pagesCount;

  return (
    <img
      className={`${enabled ? 'c-ArrowForward' : 'c-ArrowForwardDisabled'}`}
      src={enabled ? chevronRightIcon : chevronRightDisabledIcon}
      onClick={() => changePage(currentPage + 1)}
    />
  );
};

export default observer(ArrowForward);