import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

// Views
import KeycardsView from './keycards-view';

// Helpers
import * as helpers from './helpers';

// Stores
import stores from 'bootstrap/store';
import { keycardsRouteStore } from './domain/store';
import { keycardUpsertModalRouteStore } from './components/keycard-upsert-modal/domain/store';
const keycardsStore = stores.global.keycards;

const KeycardsContainer = () => {
  useEffect(() => {
    helpers.listKeycards();

    return () => {
      keycardsRouteStore.setInitialState();
      keycardsStore.setValue('dataForPharmacist', {});
      keycardsStore.setValue('entriesCount', 0);
    };
  }, []);

  return (
    <KeycardsView
      keycardsStore={keycardsStore}
      keycardsRouteStore={keycardsRouteStore}
      keycardUpsertModalRouteStore={keycardUpsertModalRouteStore}
      sortKeycards={helpers.sortKeycards}
      changePaginationPage={helpers.changePaginationPage}
      onKeycardsSearch={helpers.onKeycardsSearch}
      showKeycardUpsertModal={helpers.showKeycardUpsertModal}
      closeKeycardUpsertModal={helpers.closeKeycardUpsertModal}
      createKeycard={helpers.createKeycard}
      updateKeycard={helpers.updateKeycard}
      resetPin={helpers.resetPin}
      listUsers={helpers.listUsers}
      listUsersDebounce={helpers.listUsersDebounce}
      onSelectUser={helpers.onSelectUser}
      listPharmacies={helpers.listPharmacies}
      listPharmaciesDebounce={helpers.listPharmaciesDebounce}
    />
  );
};

export default observer(KeycardsContainer);
