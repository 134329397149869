import React from 'react';
import { observer } from 'mobx-react';
import { range as _range } from 'lodash';

const InterruptedSequence = ({
  rangeFrom,
  rangeTo,
  currentPage,
  pagesCount,
  changePage
}) => (
  <React.Fragment>
    {
      _range(rangeFrom, rangeTo).map(pageNumber => (
        <div
          key={pageNumber}
          className={pageNumber === currentPage ? 'number-active' : 'number'}
          onClick={() => changePage(pageNumber)}
        >
          {
            pageNumber
          }
        </div>
      ))
    }
    <div className='dots'>
      ...
    </div>
    <div
      key={pagesCount}
      className={pagesCount === currentPage ? 'number-active' : 'number'}
      onClick={() => changePage(pagesCount)}
    >
      {
        pagesCount
      }
    </div>
  </React.Fragment>
);

export default observer(InterruptedSequence);