import { get as _get } from 'lodash';

// Constants
import * as ROUTE from 'constants/routes';
import { DISPENSE_ATTEMPTS_TAB, MAX_ENTRIES_PER_PAGE } from '../constants';

// Stores
import stores from 'bootstrap/store';
const dispenseAttemptsStore = stores.global.dispenseAttempts;
const dispenseAttemptsRouteStore = stores.route.dispenseAttempts.domain;
const { history } = stores;


const getFetcher = (activeTab) => {
  const dispenseAttemptsStore = stores.global.dispenseAttempts;
  const FETCHER = {
    [DISPENSE_ATTEMPTS_TAB.PENDING]: dispenseAttemptsStore.listPendingReviewForPharmacist,
    [DISPENSE_ATTEMPTS_TAB.REVIEWED]: dispenseAttemptsStore.listReviewedForPharmacist,
  };

  return FETCHER[activeTab];
};


/**
 * List dispense attempts considering all dependencies.
 * @param shouldFilter: boolean - should filters be included in the query
 * @param shouldPaginate - should the query include "offset" and "limit" parameters
 * @returns {Promise<never>|Promise<unknown>}
 */
export default ({ shouldFilter = true, shouldPaginate = true, asc = true } = {}) => {
  const list = getFetcher(dispenseAttemptsRouteStore.dispenseAttemptsTab);
  if (!list) return Promise.reject('Could not get dispense attempts fetcher function.');

  const query = {
    order: [['updatedAt', asc ? 'asc' : 'desc']],
  };
  const filterValue = dispenseAttemptsRouteStore.filterValue;
  if (shouldFilter && filterValue) {
    query.filter = {
      '$prescriptionFill.prescription.patient.firstName$': filterValue,
      '$prescriptionFill.prescription.patient.lastName$': filterValue,
      '$prescriptionFill.prescription.physician.firstName$': filterValue,
      '$prescriptionFill.prescription.physician.lastName$': filterValue,
      '$prescriptionFill.prescription.drug.name$': filterValue,
    };
  }

  const config = { query };

  if (shouldPaginate) {
    config.query = {
      ...config.query,
      offset: dispenseAttemptsStore.dataForPharmacist.size,
      limit: MAX_ENTRIES_PER_PAGE,
    };
  }

  dispenseAttemptsRouteStore.incrementHttp('listingForPharmacist');
  return list(config)
  .then(() => {
    const firstId = _get(dispenseAttemptsStore, 'forPharmacistByDate[0].data[0].id');
    dispenseAttemptsRouteStore.setValue('activeDispenseAttemptId', firstId);
  })
  .catch(() => history.push(ROUTE.DISPENSE_ATTEMPTS.VALUE))
  .finally(() => dispenseAttemptsRouteStore.decrementHttp('listingForPharmacist'));
};
