import React from 'react';

export const COLUMNS = [
  {
    name: 'Drug',
    fieldName: 'drug.name',
    isSortable: false,
    width: '35%',
    render: ({ content }) => (
      <div title={content.drug.name}>
        {
          content.drug.name
        }
        <div>
          <span className='internal-label'>RX: </span>
          {content.prescription.rxId}
        </div>
        <div>
          <span className='internal-label'>NDC: </span>
          {content.drug.NDC}
        </div>
      </div>
    )
  },
  {
    name: 'Creation Date',
    fieldName: 'createdAt',
    isSortable: false,
    width: '15%',
    render: ({ content }) => (
      <div title={content.creationDate}>
        {
          content.creationDate
        }  
      </div>
    )
  },
  {
    name: 'Patient',
    fieldName: 'patient.name',
    isSortable: false,
    width: '35%',
    render: ({ content }) => (
      <div className='patient'>
        <div className='name' title={content.name}>
          {
            content.name
          }
        </div>
        <div>
          <span className='internal-label'>Pharmacy ID: </span>
          {content.externalPharmacyId}
        </div>
      </div>
    )
  },
  {
    name: 'Phone #',
    fieldName: 'patientPhoneNumber',
    isSortable: false,
    width: '15%',
    render: ({ content }) => (
      <div title={content.phoneNumber}>
        {
          content.phoneNumber
        }  
      </div>
    )
  },
];
