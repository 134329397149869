import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import { get as _get, uniq as _uniq } from 'lodash';

import stores from 'bootstrap/store';

// Views
import KioskActivityView from './kiosk-activity-view';

// Constants
import { MAX_ENTRIES_PER_PAGE } from 'components/app-commands/constants';
import { APP_COMMAND_TYPE_BASE_LIST, APP_COMMAND_TYPE } from 'models/app-commands/constants/constants';

// Stores
import { kioskActivityRouteStore } from './domain/store';
const appCommandsStore = stores.global.appCommands;
const kioskStatusesStore = stores.global.kioskStatuses;


const BASELINE_FILTER = [
  APP_COMMAND_TYPE.USERS_LOGIN_KEYCARD,
  APP_COMMAND_TYPE.DISPENSE_ATTEMPTS_DISPENSE,
];


const KioskActivityContainer = ({ activeKiosk }) => {
  const listAppCommands = useCallback(async (config = {}) => {
    if (kioskActivityRouteStore.http.listingAppCommands) {
      return;
    }

    const { currentPage } = kioskActivityRouteStore;
    config = {
      ...config,
      params: {
        kioskId: activeKiosk.id
      },
      query: {
        ...config.query,
        order: [['updatedAt', 'desc']],
        offset: MAX_ENTRIES_PER_PAGE * (currentPage - 1),
        limit: MAX_ENTRIES_PER_PAGE,
      },
    };

    const { appCommandFilter } = kioskActivityRouteStore;
    if (appCommandFilter) {
      const replacedFilter = appCommandFilter.replace(/(Create)|(Update)/, 'Upsert');
      if (
        (appCommandFilter.includes('Create') || appCommandFilter.includes('Update'))
        && APP_COMMAND_TYPE_BASE_LIST.includes(replacedFilter)
      ) {
        config.query.query = {
          type: replacedFilter
        };

        config.query.isNew = appCommandFilter.includes('Create');
      }
      else {
        config.query.query = {
          type: appCommandFilter,
        };
      }
    }

    if (!config.query.query) config.query.query = {};
    if (config.query.query && config.query.query.type) {
      config.query.query.type = Array.isArray(config.query.query.type) ? config.query.query.type : [config.query.query.type];
    }
    config.query.query.type = _uniq([...(config.query.query.type || []), ...BASELINE_FILTER]);

    kioskActivityRouteStore.incrementHttp('listingAppCommands');
    return appCommandsStore.listForKiosk(config)
    .then((response) => {
      const responseLength = _get(response, 'data.length', 0);
      if (responseLength > MAX_ENTRIES_PER_PAGE - 1) {
        kioskActivityRouteStore.setValue('currentPage', kioskActivityRouteStore.currentPage + 1);
      }
    })
    .finally(() => kioskActivityRouteStore.decrementHttp('listingAppCommands'));
  }, [activeKiosk]);

  const setFilter = useCallback(async (data) => {
    const value = data ? data.value : '';

    kioskActivityRouteStore.setValue('currentPage', 1);
    kioskActivityRouteStore.setValue('appCommandFilter', value);
    return listAppCommands({ resetData: true });
  }, [listAppCommands]);

  useEffect(() => {
    listAppCommands();
    kioskStatusesStore.listForKiosk({ params: { kioskId: activeKiosk.id } });

    return () => {
      kioskActivityRouteStore.setInitialState();
      kioskStatusesStore.setValue('dataForPharmacist', {});
    };
  }, []);

  return (
    <KioskActivityView
      kioskActivityRouteStore={kioskActivityRouteStore}
      kioskStatusesStore={kioskStatusesStore}
      appCommandsStore={appCommandsStore}
      activeKiosk={activeKiosk}
      setFilter={setFilter}
      listAppCommands={listAppCommands}
    />
  );
};

export default observer(KioskActivityContainer);
