import React from 'react';
import { observer } from 'mobx-react';

// Components
import ContentScreen from 'components/content-screen';
import InfiniteScroll from 'components/infinite-scroll';
// import Select from 'react-select';

// Icons
// import filterImage from 'assets/icons/filter.svg';

// Helpers
import { buildAppCommand } from './helpers/app-command-builder';

// Styles
import './index.scss';


const AppCommands = ({
  appCommandsByDate,
  isListingAppCommands,
  appCommandFilter,
  // setFilter,
  listAppCommands,
  actionConfig = {},
}) => {
  return <div className='c-AppCommandsTable'>
    {/* <div className='filter-wrapper'>
      <img className='filter-image' src={filterImage}/>
      <Select
        className='filter-select'
        onChange={setFilter}
        value={appCommandFilter}
        options={[
          {label: 'All', value: ''},
          ...Object.keys(actionConfig).map(key => ({label: actionConfig[key], value: key}))
        ]}
      />
    </div>
    <div className='horizontal-line'/> */}
    <InfiniteScroll onBottomThreshold={listAppCommands}>
      <ContentScreen
        active={isListingAppCommands}
        fadeDuration={500}
      >
      </ContentScreen>
      <div className='app-commands-by-date'>
        {
          appCommandsByDate.length > 0
            ? appCommandsByDate.map((appCommands) => (
              <div key={appCommands.date} className='by-particular-date'>
                <div className='date-container'>
                  <div className='date'>{appCommands.date}</div>
                  <hr className='separator'/>
                </div>
                {
                  appCommands.data.map(appCommand => buildAppCommand(appCommand, actionConfig))
                }
              </div>
            ))
            : !isListingAppCommands
              ? <div className='no-data'>
                {
                  appCommandFilter
                    ? 'No activity of selected type has been registered yet.'
                    : 'No activity has been registered yet.'
                }
              </div>
              : null
        }
      </div>
    </InfiniteScroll>
  </div>;
};


export default observer(AppCommands);
