import stores from 'bootstrap/store';
const dispenseAttemptsStore = stores.global.dispenseAttempts;


export default (dispenseAttempt) => {
  if (!dispenseAttempt) return;

  dispenseAttemptsStore.setValue('rejectionModal.dispenseAttemptId', dispenseAttempt.id);
  dispenseAttemptsStore.setValue('rejectionModal.showing', true);
};
