import { get as _get } from 'lodash';

// Constants
import { KIOSKS as KIOSKS_ROUTE } from 'constants/routes';

// Stores
import { kiosksRouteStore } from '../domain/store';

export default (kioskId, navigate) => {
  const isRequestedKioskAvailable = !!kiosksRouteStore.kiosks.get(kioskId);
  if (isRequestedKioskAvailable) {
    kiosksRouteStore.setValue('activeKioskId', kioskId);
  } else {
    const firstId = _get(kiosksRouteStore, 'groupedKiosksList[0].kiosks[0].id');
    if (firstId) {
      navigate(`${KIOSKS_ROUTE.VALUE}/${firstId}`);
    } else {
      kiosksRouteStore.setValue('activeKioskId', null);
    }
  }
};
