import React from 'react';
import { observer } from 'mobx-react';

import './notification-wrapper-style.scss';

const NotificationWrapper = ({
  cursorPointer = true,
  onClick,
  children,
}) => (
  <div
    className={`c-NotificationWrapper ${onClick && 'c-NotificationWrapper-hoverable'}`}
    style={{'cursor': cursorPointer ? 'pointer' : 'auto'}}
    onClick={onClick}
  >
    <div className='notification'>
      {
        children
      }
    </div>
  </div>
);

export default observer(NotificationWrapper);
