import React from 'react';
import { observer } from 'mobx-react';
import { get as _get } from 'lodash';

import './status-manipulation-style.scss';

const DispenseAttemptStatusManipulation = ({
  dispenseAttemptsRouteStore,
  dispenseAttemptsStore,
  dispenseAttemptsAvailable,
  approveDispenseAttempt,
  source,
}) => {
  const { activeDispenseAttempt } = dispenseAttemptsRouteStore;
  if (!dispenseAttemptsAvailable || !activeDispenseAttempt) return null;

  // const consultationCanBeRequested = !activeDispenseAttempt.review && !activeDispenseAttempt.prescriptionFill.consultReq;
  // const consultationTookPlace = (
  //   activeDispenseAttempt.prescriptionFill.consultReq &&
  //   _get(activeDispenseAttempt, 'prescriptionFill.prescriptionFillsFulfillment.computed_consultationRequestPending') === false // TODO gets truncated by postgres's 63 char limit
  // );

  return <div className='c-DispenseAttemptStatusManipulation'>
    <div className='row'>
      <input
        className='button approve'
        type='button'
        value='Approve'
        onClick={approveDispenseAttempt}
      />
      <input
        className='button reject'
        type='button'
        value='Reject'
        onClick={() => {
          dispenseAttemptsStore.setValue('rejectionModal.dispenseAttemptId', _get(dispenseAttemptsRouteStore, 'activeDispenseAttempt.id'));
          dispenseAttemptsStore.setValue('rejectionModal.showing', true);
        }}
      />
    </div>
    {
      source &&
      <div className='row'>
        <div className='source'>
          {
            `${source.role}: ${source.name}`
          }
        </div>
      </div>
    }
    {/* {
      consultationCanBeRequested
        ? <div className='row'>
          <input
            className='button consultation-request'
            type='button'
            value='Request Consultation'
            onClick={() => dispenseAttemptsRouteStore.setValue('showingConsultationRequestModal', true)}
          />
        </div>
        : consultationTookPlace
          ? <div className='consultation-took-place'>
            Consultation has already taken place. Please, provide a review.
          </div>
          : null
    } */}
  </div>;
};


export default observer(DispenseAttemptStatusManipulation);
