import deepFreeze from 'deep-freeze';

export const MODEL_NAME = Object.freeze({
  SINGULAR: 'location',
  PLURAL: 'locations'
});


export const FIELD_NAME = Object.freeze({
  ID: 'id',
  TITLE: 'title',
  STREET_1: 'street1',
  CITY: 'city',
  STATE: 'state',
  COUNTRY: 'country',
  ZIP: 'zip',
  DESCRIPTION: 'description',
  CREATED_AT: 'createdAt',
  UPDATED_AT: 'updatedAt',
});

export const SCHEMA = deepFreeze({
  title: MODEL_NAME.SINGULAR,
  type: 'object',
  properties: {
    [FIELD_NAME.ID]: {
      type: 'string'
    },
    [FIELD_NAME.TITLE]: {
      type: 'string'
    },
    [FIELD_NAME.STREET_1]: {
      type: 'string'
    },
    [FIELD_NAME.CITY]: {
      type: 'string'
    },
    [FIELD_NAME.STATE]: {
      type: 'string'
    },
    [FIELD_NAME.COUNTRY]: {
      type: 'string'
    },
    [FIELD_NAME.ZIP]: {
      type: 'string'
    },
    [FIELD_NAME.DESCRIPTION]: {
      type: 'string'
    },
    [FIELD_NAME.CREATED_AT]: {
      type: 'string'
    },
    [FIELD_NAME.UPDATED_AT]: {
      type: 'string'
    },
  },
  required: [
    FIELD_NAME.TITLE,
  ]
});
