import stores from 'bootstrap/store';

import notifications from 'bootstrap/notifications';

import { notificationsPaneRouteStore } from '../../../../app/root/routes/app-frame/views/notifications-pane/domain/store';

import { EVENT } from '../../constants';
import { CALL_REQUESTS_TAB } from 'app/root/routes/app-frame/routes/user/patients/routes/call-requests/constants';
import { NOTIFICATION_TYPE } from '../../../../app/root/routes/app-frame/views/notifications-pane/constants';

import { listCallRequests } from 'app/root/routes/app-frame/routes/user/patients/routes/call-requests/helpers';

const callRequestsCreateHandler = ({ apiWebSocket }) => {
  const callRequestsStore = stores.global.callRequests;
  const callRequestsRouteStore = stores.route.callRequests.domain;

  apiWebSocket.on(EVENT.RECEIVED.CALL_REQUESTS_CREATE, ({ phoneNumber }) => {
    if (callRequestsRouteStore.activeListTab === CALL_REQUESTS_TAB.QUEUED) {
      listCallRequests();
    }

    callRequestsStore.listForNotificationsPane()
    .then(() => {
      notifications.create(`New Call Request: ${phoneNumber}.`, {
        onClickCallback: () => {
          if (notificationsPaneRouteStore.activeTab !== NOTIFICATION_TYPE.CALL_REQUESTS) {
            notificationsPaneRouteStore.setActiveTab(NOTIFICATION_TYPE.CALL_REQUESTS);
          }
        },
      });
    });
  });
};

export default callRequestsCreateHandler;
