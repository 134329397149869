import React from 'react';
import ApprovedIcon from 'assets/icons/approved.svg';
import NoneIcon from 'assets/icons/none.svg';

export const ORDER_OPTIONS = {
  userName: order => [['user', 'firstName', order], ['user', 'lastName', order ]],
  value: order => [['value', order]],
  enabled: order => [['disabled', order]]
};

export const KEYCARD_MANIPULATION_OPTIONS = {
  CREATE: 'create',
  UPDATE: 'update'
};

export const KEYCARD_SKELETON = {
  value: '',
  disabled: false,
  userId: '',
  pin: '',
  user: {}
};

export const COLUMNS = [
  {
    name: 'Value',
    fieldName: 'value',
    isSortable: true,
    width: '50%',
    render: ({ content }) => (
      <div title={content.value}>{content.value}</div>
    )
  },
  {
    name: 'User',
    fieldName: 'userName',
    isSortable: true,
    width: '40%',
    render: ({ content }) => (
      <div title={content.userName}>{content.userName}</div>
    )
  },
  {
    name: 'Enabled',
    fieldName: 'enabled',
    isSortable: true,
    width: '10%',
    render: ({ content }) => (
      <div title={content.disabled? 'Disabled': 'Enabled'}>
        <img
          src={content.disabled ? NoneIcon : ApprovedIcon}
        />
      </div>
    )
  }
];
