import { get as _get } from 'lodash';

// Constants
import * as ROUTES from 'constants/routes';
import { MAX_ENTRIES_PER_PAGE } from '../constants';

// Stores
import stores from 'bootstrap/store';
import { history } from 'bootstrap/store/router';

const store = {
  global: {
    prescriptionFills: stores.global.prescriptionFills,
  },
  route: {
    prescriptionFillsList: stores.route.prescriptionFillsList.domain,
  },
  history,
};


// List prescription fills considering all dependencies.
export default ({ filterQuery = true, paginate = true } = {}) => {
  const query = {};
  const filterValue = store.route.prescriptionFillsList.prescriptionFillsFilterValue;
  if (filterQuery && filterValue) {
    if (store.global.prescriptionFills.dataForPharmacist.size > MAX_ENTRIES_PER_PAGE - 1) {
      store.global.prescriptionFills.setValue('dataForPharmacist', {});
    }

    query.filter = {
      '$prescription.patient.firstName$': filterValue,
      '$prescription.patient.lastName$': filterValue,
    };
  }

  const config = { query };

  const currentPage = store.route.prescriptionFillsList.currentPage.prescriptionFills;
  if (paginate) {
    config.query = {
      ...config.query,
      offset: MAX_ENTRIES_PER_PAGE * (currentPage - 1),
      limit: MAX_ENTRIES_PER_PAGE,
    };
  }

  store.route.prescriptionFillsList.incrementHttp('listingPrescriptionFillsForPharmacist');
  return store.global.prescriptionFills.listForPharmacist(config)
  .then((response) => {
    const responseLength = _get(response, 'data.length', 0);
    if (paginate && responseLength > MAX_ENTRIES_PER_PAGE - 1) {
      store.route.prescriptionFillsList.setValue('currentPage.prescriptionFills', currentPage + 1);
    }

    if (!store.route.prescriptionFillsList.activePrescriptionFillId) {
      const firstId = _get(store.route.prescriptionFills, 'forPharmacistByDate[0].data[0].id');
      store.route.prescriptionFillsList.setValue('activePrescriptionFillId', firstId);
    }
  })
  .catch(() => store.history.push(`${ROUTES.PRESCRIPTION_FILLS.VALUE}${ROUTES.PRESCRIPTION_FILLS.SUB_ROUTE.LIST_OVERVIEW.VALUE}`))
  .finally(() => store.route.prescriptionFillsList.decrementHttp('listingPrescriptionFillsForPharmacist'));
};
