import base from 'models/base';
import { MODEL_NAME, SCHEMA } from './constants/schema';

const lotDrugRecallModel = base({
  names: {
    singular: MODEL_NAME.SINGULAR,
    plural: MODEL_NAME.PLURAL
  },
  JSONSchema: SCHEMA,
  httpConfig: {
    root: MODEL_NAME.plural,
    methods: {}
  }
});

lotDrugRecallModel.configureStore((store) => {
  return store
  .actions((self) => ({
    createLotDrugRecall: (config = {}) => {
      return self.post(config);
    },
  }));
});

// Can't use module.exports because there is a generator function in this file,
// and that causes module.exports to fail for some reason.
export default lotDrugRecallModel;
