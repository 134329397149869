import React from 'react';
import { observer } from 'mobx-react';

import CaptionValueInput from 'components/caption-value-input';
import CaptionValueSelect from 'components/caption-value-input-with-select';
import Modal from 'components/modal';
import ModalEditingAreas from 'components/modal/layout/editing-areas';
import ModalEditingArea from 'components/modal/layout/editing-areas/components/editing-area';
import ModalRow from 'components/modal/layout/row';
import ModalButton from 'components/modal/layout/button';

import { healthcareSiteUpsertModalRouteStore } from './domain/store';

const HealthcareSiteUpsertModal = ({
  healthcareSitesRouteStore,
  locationsStore,
  createHealthcareSite,
  closeHealthcareSiteUpsertModal,
  pharmaciesStore,
}) => {
  const activelyModifiedHealthcareSite = healthcareSiteUpsertModalRouteStore.activelyModifiedHealthcareSite || {};
  const canBeCreated = healthcareSiteUpsertModalRouteStore.readyForCreation;

  return (
    <Modal
      modalStyles={{ height: '50%', width: '80%' }}
      isOpen={healthcareSitesRouteStore.showingHealthcareSiteUpsertModal}
      shouldCloseOnOverlayClick={true}
      close={closeHealthcareSiteUpsertModal}
      title={'New Healthcare Site'}
    >
      <ModalEditingAreas>
        <ModalEditingArea>
          <ModalRow>
            <CaptionValueInput
              inputFieldClassNames={['max-width']}
              caption='Name'
              value={activelyModifiedHealthcareSite.name}
              onChange={(value) => healthcareSiteUpsertModalRouteStore.setValue('activelyModifiedHealthcareSite.name', value)}
            />
          </ModalRow>
          <ModalRow>
            <CaptionValueSelect
              items={locationsStore.autoCompleteDataForSelect}
              inputFieldClassNames={['max-width']}
              caption='Location'
              value={healthcareSiteUpsertModalRouteStore.locationSelector}
              onChange={data => {
                if (!data) data = {label: '', value: ''};
                healthcareSiteUpsertModalRouteStore.setValue('locationSelector', data);
              }}
              onInputChange={data => {
                if (!data) return;
                healthcareSiteUpsertModalRouteStore.setValue('locationSelectorFilter', data);
                locationsStore.autocompleteLocationDebounce({query: {title: data}});
              }}
            />
          </ModalRow>
          <ModalRow shouldBeSpaceBetween={true} shouldBoundToBottom={true}>
            <ModalButton
              color='blue'
              isDisabled={!canBeCreated}
              text='Save'
              onClick={createHealthcareSite}
            />
          </ModalRow>
        </ModalEditingArea>
        <ModalEditingArea>
          <ModalRow>
            <CaptionValueInput
              inputFieldClassNames={['max-width']}
              type='phone'
              caption='Phone number'
              value={activelyModifiedHealthcareSite.phoneNumber}
              onChange={(value) => healthcareSiteUpsertModalRouteStore.setValue('activelyModifiedHealthcareSite.phoneNumber', value)}
            />
          </ModalRow>
          <ModalRow>
            <CaptionValueSelect
              items={pharmaciesStore.autoCompleteDataForSelect}
              inputFieldClassNames={['max-width']}
              caption='Pharmacy'
              value={healthcareSiteUpsertModalRouteStore.pharmacySelector}
              onChange={data => {
                if (!data) data = {label: '', value: ''};
                healthcareSiteUpsertModalRouteStore.setValue('pharmacySelector', data);
              }}
              onInputChange={data => {
                healthcareSiteUpsertModalRouteStore.setValue('pharmacySelectorFilter', data);
                pharmaciesStore.autocompleteForPharmacistDebounce({query: {query: {name: {$iLike: `%${data}%`}}}});
              }}
              onFocus={() => pharmaciesStore.autocompleteForPharmacistDebounce()}
            />
          </ModalRow>
        </ModalEditingArea>
      </ModalEditingAreas>
    </Modal>
  );
};

export default observer(HealthcareSiteUpsertModal);
