import { ORDER_OPTIONS } from '../constants';
import { MAX_TABLE_ENTRIES_PER_PAGE } from 'models/users/constants';

import stores from 'bootstrap/store';
import { usersRouteStore}  from '../domain/store';
const usersStore = stores.global.users;

export default () => {
  const query = {
    offset: MAX_TABLE_ENTRIES_PER_PAGE * (usersRouteStore.currentPage - 1),
    limit: MAX_TABLE_ENTRIES_PER_PAGE + 1,
  };

  if (usersRouteStore.filterValue) {
    query.filter = {
      firstName: usersRouteStore.filterValue,
      lastName: usersRouteStore.filterValue,
    };
  }

  if (usersRouteStore.sortingColumn.fieldName && usersRouteStore.sortingColumn.sortingOrder) {
    query.order = ORDER_OPTIONS[usersRouteStore.sortingColumn.fieldName](usersRouteStore.sortingColumn.sortingOrder);
  }

  const list = () => {
    usersRouteStore.incrementHttp('listingForPharmacist');
    usersStore.listUsers({ query })
    .then(() => usersRouteStore.decrementHttp('listingForPharmacist'));
  };

  const count = () => {
    usersRouteStore.incrementHttp('countingForPharmacist');
    usersStore.countUsers({ query })
    .then(() => usersRouteStore.decrementHttp('countingForPharmacist'));
  };

  return Promise.all([
    list(),
    count(),
  ]);
};
