import React from 'react';
import { observer } from 'mobx-react';

import { ACTION } from 'models/call-request-pharmacist-actions/constants';
import { CALL_REQUESTS_TAB } from '../../../../../../constants';

import './actions-style.scss';

const Actions = ({
  callRequestsRouteStore,
  activeCallRequest,
  createPharmacistAction,
}) => {
  const { activeListTab } = callRequestsRouteStore;
  if (!activeCallRequest) {
    return null;
  }
  const { pharmacistActions = [] } = activeCallRequest;
  const latestAction = pharmacistActions[0];
  const isResolved = latestAction && latestAction.action === ACTION.RESOLVED;

  return <div className='c-Actions'>
    {
      activeListTab === CALL_REQUESTS_TAB.QUEUED &&
      <div className='actions-container'>
        <button className='btn btn-blue' onClick={() => createPharmacistAction(ACTION.CONTACT_ATTEMPTED)}>
          Contact
        </button>
        <button className='btn btn-red' onClick={() => createPharmacistAction(ACTION.DISMISSED)}>
          Dismiss
        </button>
      </div>
    }
    {
      activeListTab === CALL_REQUESTS_TAB.CONTACTED &&
      <div className='actions-container'>
        <button className='btn btn-green' onClick={() => createPharmacistAction(ACTION.RESOLVED)}>
          Resolve
        </button>
        <button className='btn btn-red' onClick={() => createPharmacistAction(ACTION.DISMISSED)}>
          Dismiss
        </button>
      </div>
    }
    {
      activeListTab === CALL_REQUESTS_TAB.HANDLED &&
      <div className={`status ${isResolved ? 'status-resolved' : 'status-dismissed'}`}>
        {
          isResolved ? 'Resolved' : 'Dismissed'
        }
      </div>
    }
  </div>;
};

export default observer(Actions);
