import { flow, types } from 'mobx-state-tree';
import { values, toJS } from 'mobx';
import { keyBy as _keyBy } from 'lodash';

import base from 'models/base';
import { MODEL_NAME, SCHEMA, STATUS } from './constants/schema';

const CallRequestsModel = base({
  names: {
    singular: MODEL_NAME.SINGULAR,
    plural: MODEL_NAME.PLURAL
  },
  JSONSchema: SCHEMA,
  httpConfig: {
    root: MODEL_NAME.plural,
    methods: {}
  }
});

CallRequestsModel.configureStore((store) => {
  return store
  .props({
    activeCallRequest: types.frozen(),
    dataForPharmacist: types.optional(types.map(types.frozen()), {}),
    queued: types.optional(types.array(types.frozen()), []),
  })
  .actions(self => ({
    setValue: (prop, value) => self[prop] = value,
    fetchById: flow(function* (config = {}) {
      if (!config.params || !config.params.callRequestId) {
        return Promise.reject('"callRequestId" must be provided and be a string in order to fetch a call request by id');
      }

      config.urlFragment = () => `/${config.params.callRequestId}`;
      const response = yield self.get(config);

      self.activeCallRequest = response;

      return response;
    }),
    listForPharmacist: flow(function* (config = {}) {
      config.urlFragment = () => '/for-pharmacist';
      const response = yield self.list(config);
      const data = response.data || response || [];
      self.dataForPharmacist.merge(_keyBy(data, 'id'));

      return response;
    }),
    listForNotificationsPane: flow(function* (config = {}) {
      config.urlFragment = () => '/for-pharmacist';
      config.query = {
        status: STATUS.QUEUED,
      };
      const response = yield self.list(config);
      self.queued = response.data || response || [];

      return response;
    }),
  }))
  .views(self => ({
    get dataForPharmacistList() {
      return values(self.dataForPharmacist);
    },
    // Seems redundant, but for whatever reason componentDidUpdate registers array changes only from mobx views, not props.
    get queuedList() {
      return toJS(self.queued);
    },
  }));
});

export default CallRequestsModel;
