import stores from 'bootstrap/store';
import { pharmacyTechnicianUpsertModalRouteStore } from '../domain/upsert-modal-store';

const pharmaciesStore = stores.global.pharmacies;

export default (e) => {
  pharmacyTechnicianUpsertModalRouteStore.setValue('workPlaceFilter', e.target.value);

  return pharmaciesStore.listPharmacies({
    query: {
      filter: {
        name: pharmacyTechnicianUpsertModalRouteStore.workPlaceFilter,
      },
    },
  });
};
