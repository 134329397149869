import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';

// Views
import MachineTechniciansView from './machine-technicians-view';

// Helpers
import {
  determineActiveMachineTechnician,
  listMachineTechnicians,
  setFilterValue,
} from './helpers';

// View Store
import { machineTechniciansRouteStore } from './domain/store';

// Global Stores
import stores from 'bootstrap/store';
const machineTechniciansStore = stores.global.machineTechnicians;


const MachineTechniciansContainer = ({ rootRoute }) => {
  const navigate = useNavigate();
  const { technicianId } = useParams();

  useEffect(() => {
    listMachineTechnicians();

    return () => {
      machineTechniciansRouteStore.setInitialState();
      machineTechniciansStore.setValue('machineTechnicians', {});
    };
  }, []);

  useEffect(() => {
    if (!machineTechniciansRouteStore.http.listingTechnicians) {
      determineActiveMachineTechnician(technicianId, navigate);
    }
  }, [technicianId, machineTechniciansRouteStore.http.listingTechnicians]);

  return (
    <MachineTechniciansView
      machineTechniciansStore={machineTechniciansStore}
      machineTechniciansRouteStore={machineTechniciansRouteStore}
      setFilterValue={setFilterValue}
      listMachineTechnicians={listMachineTechnicians}
      rootRoute={rootRoute}
    />
  );
};

export default observer(MachineTechniciansContainer);
