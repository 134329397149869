import React from 'react';
import { observer } from 'mobx-react';

// Components
import PatientInfo from '../../../../components/patient-info';

// Styles
import './info-style.scss';

const InfoView = ({
  activePatient = {},
  activePatientAddress = null,
  activePatientImageGallery = [],
}) => (
  <div className='c-PatientDetails'>
    <div className='content'>
      <PatientInfo
        patient={activePatient}
        patientAddress={activePatientAddress}
        patientImages={activePatientImageGallery}
      />
    </div>
  </div>
);

export default observer(InfoView);
