import { debounce as _debounce } from 'lodash';

import stores from 'bootstrap/store';
import { prescriptionFillsManualEntryRouteStore } from '../domain/store';

const listPatientsAutocomplete = async (field) => {
  const suggestionsKey = `suggestions.patient.${field}`;

  prescriptionFillsManualEntryRouteStore.setValue(suggestionsKey, []);

  const currentValue = prescriptionFillsManualEntryRouteStore.patient[field];
  const response = await stores.global.patients.listForPharmacist({
    query: {
      filter: {
        [field]: currentValue,
      },
    },
  });

  prescriptionFillsManualEntryRouteStore.setValue(suggestionsKey, response.data || []);
};

export default _debounce(listPatientsAutocomplete, 300);
