import React from 'react';
import { observer } from 'mobx-react';

// Components
import DrillDownLayout from 'components/drill-down-layout';
import List from './components/list';
import Content from './components/content';

//Styles
import './medical-assistants-view.scss';


const MedicalAssistantsView = ({
  medicalAssistantsStore,
  medicalAssistantsRouteStore,
  setFilterValue,
  listMedicalAssistants,
  rootRoute,
}) => (
  <div className='r-MedicalAssistantsView'>
    <DrillDownLayout>
      <List
        medicalAssistantsStore={medicalAssistantsStore}
        medicalAssistantsRouteStore={medicalAssistantsRouteStore}
        setFilterValue={setFilterValue}
        listMedicalAssistants={listMedicalAssistants}
        rootRoute={rootRoute}
      />
      <Content
        medicalAssistantsRouteStore={medicalAssistantsRouteStore}
      />
    </DrillDownLayout>
  </div>
);


export default observer(MedicalAssistantsView);
