import { debounce as _debounce } from 'lodash';

import { listMedicalAssistants } from './index';

import stores from 'bootstrap/store';
const medicalAssistantsStore = stores.global.medicalAssistants;

export default _debounce(() => {
  medicalAssistantsStore.setValue('medicalAssistants', {});
  return listMedicalAssistants();
}, 350);
