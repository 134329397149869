import React from 'react';
import { observer } from 'mobx-react';

import Header from './components/header';
import Content from 'components/drill-down-layout/components/content';
import ContentTab from 'components/drill-down-layout/components/content/components/content-tab';

import PrescriptionsHistoryRoute from '../../routes/prescriptions-history';
import ActionsHistoryRoute from '../../routes/actions-history';

import { PATIENTS } from 'constants/routes';

const PatientCallRequestsContent = ({
  callRequestsStore,
  callRequestsRouteStore,
  createPharmacistAction,
}) => (
  <Content
    header={<Header
      callRequestsRouteStore={callRequestsRouteStore}
      activeCallRequest={callRequestsStore.activeCallRequest}
      isReady={!!callRequestsRouteStore.activeCallRequest && !callRequestsRouteStore.http.creatingPharmacistAction}
      createPharmacistAction={createPharmacistAction}
    />}
    isReady={!!callRequestsRouteStore.activeCallRequest}
  >
    {
      callRequestsRouteStore.activeCallRequest && callRequestsRouteStore.activeCallRequest.patientCallRequest &&
      <ContentTab title={PATIENTS.SUB_ROUTE.CALL_REQUESTS.CONTENT_TAB.PRESCRIPTION_HISTORY.TITLE} route={PATIENTS.SUB_ROUTE.CALL_REQUESTS.CONTENT_TAB.PRESCRIPTION_HISTORY.VALUE}>
        <PrescriptionsHistoryRoute
          activeCallRequest={callRequestsStore.activeCallRequest}
          fetchingActiveCallRequest={!!callRequestsRouteStore.http.fetchingCallRequestById}
        />
      </ContentTab>
    }
    <ContentTab title={PATIENTS.SUB_ROUTE.CALL_REQUESTS.CONTENT_TAB.ACTIONS.TITLE} route={PATIENTS.SUB_ROUTE.CALL_REQUESTS.CONTENT_TAB.ACTIONS.VALUE}>
      <ActionsHistoryRoute
        activeCallRequest={callRequestsStore.activeCallRequest}
        fetchingActiveCallRequest={!!callRequestsRouteStore.http.fetchingCallRequestById}
      />
    </ContentTab>
  </Content>
);

export default observer(PatientCallRequestsContent);
