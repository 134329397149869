import { get as _get, orderBy as _orderBy } from 'lodash';

export default (data, {
  preOrder,
  path,
  formatKey,
  keyName = 'key',
  preValidate,
}) => {
  let list = data;
  if (preOrder) list = _orderBy(list, preOrder.field, preOrder.order);

  const byPath = [];
  const grouped = {};
  list.forEach(item => {
    let keyValue = _get(item, path);
    if (formatKey) keyValue = formatKey(keyValue);

    if (preValidate && !preValidate(item)) return;

    if (!grouped[keyValue]) {
      grouped[keyValue] = {[keyName]: keyValue, data: []};
      byPath.push(grouped[keyValue]);
    }
    grouped[keyValue].data.push(item);
  });

  return byPath;
};
