const constructDateTimeString = (timestamp) => {
  return `${new Date(timestamp).toLocaleDateString()} ${new Date(timestamp).toLocaleTimeString()}`;
};

const constructStockingPeriod = (lot) => {
  if (!lot.firstStockingDate || !lot.latestStockingDate) {
    return 'N/A';
  }

  const firstStockingDateReadable = constructDateTimeString(lot.firstStockingDate);
  const latestStockingDateReadable = constructDateTimeString(lot.latestStockingDate);

  return `${firstStockingDateReadable} - ${latestStockingDateReadable}`;
};

export default constructStockingPeriod;
