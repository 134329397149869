import { isString as _isString } from 'lodash';

import { prescriptionFillsManualEntryRouteStore } from '../domain/store';

const onDrugAutocompleteInputChange = (drug, filteredField) => {
  const { name, NDC, quantity } = drug;

  if (drug[filteredField]) {
    prescriptionFillsManualEntryRouteStore.setValue(`drug.${filteredField}`, drug[filteredField]);
  }

  if (!prescriptionFillsManualEntryRouteStore.drug.name) {
    prescriptionFillsManualEntryRouteStore.setValue('drug.name', name);
  }

  if (!prescriptionFillsManualEntryRouteStore.drug.NDC) {
    prescriptionFillsManualEntryRouteStore.setValue('drug.NDC', NDC);
  }

  if (!prescriptionFillsManualEntryRouteStore.drug.quantity && _isString(quantity)) {
    prescriptionFillsManualEntryRouteStore.setValue('drug.quantity', quantity);
  }

  prescriptionFillsManualEntryRouteStore.setValue('suggestions.drug', {});
};

export default onDrugAutocompleteInputChange;
