import { types } from 'mobx-state-tree';
import { startCase as _startCase } from 'lodash';

import stores from 'bootstrap/store';

export const PatientsIncompleteInfoRouteStore = types.model({
  activePatientId: types.maybeNull(types.string),
  filterValue: types.optional(types.string, ''),
  currentPage: types.optional(types.number, 1),
  http: types.optional(types.model({
    listingPatients: types.optional(types.number, 0)
  }), {}),
})
.actions(self => ({
  setValue: (prop, value) => self[prop] = value,
  setInitialState: () => {
    self.activePatientId = null;
    self.filterValue = '';
    self.currentPage = 1;
    self.http = {
      listingPatientInsurances: 0,
    };
  },
  incrementHttp: (http) => ++self.http[http],
  decrementHttp: (http) => --self.http[http]
}))
.views(self => ({
  get activePatient() {
    return self.activePatientId && stores.global.patients.dataForPharmacist.get(self.activePatientId);
  },
}))
.views((self) => ({
  get patientPrimaryAddress() {
    if (!self.activePatient || !self.activePatient.patientsAddresses || !self.activePatient.patientsAddresses.length) {
      return null;
    }

    const { location } = self.activePatient.patientsAddresses[0];
    return `${location.street1}, ${location.city}, ${location.state}, ${location.zip}`;
  },
  get activePatientImageGallery() {
    if (!self.activePatient || !self.activePatient.imageUrls || !self.activePatient.imageUrls.length) {
      return [];
    }

    return self.activePatient.imageUrls.map(({ type, url }) => ({
      src: url,
      thumbnail: url,
      caption: _startCase(type.replace('_', ' ').toLowerCase()),
      thumbnailWidth: 20,
      thumbnailHeight: 20,
    }));
  },
}));

export const patientsIncompleteInfoRouteStore = PatientsIncompleteInfoRouteStore.create({});
