import React from 'react';
import { observer } from 'mobx-react';

// Components
import DrillDownLayout from 'components/drill-down-layout';
import List from './components/list';
import Content from './components/content';

//Styles
import './patients-incomplete-info-view.scss';


const PatientsIncompleteInfoView = ({
  patientsStore,
  patientsIncompleteInfoRouteStore,
  setFilterValue,
  listPatients,
  markActivePatientAsCaptured,
  requestActivePatientDataCapture,
  rootRoute,
}) => (
  <div className='r-PatientIncompleteInfoView'>
    <DrillDownLayout>
      <List
        patientsStore={patientsStore}
        patientsIncompleteInfoRouteStore={patientsIncompleteInfoRouteStore}
        setFilterValue={setFilterValue}
        listPatients={listPatients}
        rootRoute={rootRoute}
      />
      <Content
        patientsIncompleteInfoRouteStore={patientsIncompleteInfoRouteStore}
        markActivePatientAsCaptured={markActivePatientAsCaptured}
        requestActivePatientDataCapture={requestActivePatientDataCapture}
        listPatients={listPatients}
      />
    </DrillDownLayout>
  </div>
);


export default observer(PatientsIncompleteInfoView);
