import { types } from 'mobx-state-tree';

import { NAVBAR_ROUTES } from '../constants';

const SUB_ROUTE_DROPDOWN = Object.values(NAVBAR_ROUTES).reduce((routesWithSubRoutesByValue, routes) => {
  const routesWithSubRoutes = routes.filter((route) => !!Object.values(route.SUB_ROUTE || {}).length);
  routesWithSubRoutes.forEach((route) => {
    routesWithSubRoutesByValue[route.VALUE] = false;
  });

  return routesWithSubRoutesByValue;
}, {});

export const NavbarRouteStore = types.model({
  routeDropdown: types.optional(types.map(types.frozen()), SUB_ROUTE_DROPDOWN),
})
.actions(self => ({
  showRouteDropdown: (route, shouldShow = true) => {
    for (const [routeValue] of self.routeDropdown) {
      if (routeValue !== route) {
        self.routeDropdown.set(routeValue, false);
      }
    }

    self.routeDropdown.set(route, shouldShow);
  },
  setInitialState: () => {
    self.routeDropdown = SUB_ROUTE_DROPDOWN;
  },
}));

export const navbarRouteStore = NavbarRouteStore.create({});
